import { AxiosResponse } from 'axios';
import { call, fork, put, takeLatest } from 'redux-saga/effects';

import { DataKiMksTransitiveGraphData } from '../domain/dataKiMksTransitiveGraph';

import { DataKiDaiActionCreators } from '../redux/server/dataKiDai';
import { DataKiGraphActionCreators } from '../redux/server/dataKiGraph';
import { DataKiKasidamaActionCreators } from '../redux/server/dataKiKasidama';
import type { FetchDataKiMksTransitiveGraphRequestAction } from '../redux/server/dataKiMksTransitiveGraph';
import {
  DataKiMksTransitiveGraphActionCreators,
  DataKiMksTransitiveGraphActionTypes,
} from '../redux/server/dataKiMksTransitiveGraph';
import { Api, buildConfig } from '../utils/api';
import { handleErrorSaga } from './errorSaga';

/**
 * パラメータを元にグラフを取得する
 * @param api AxiosInstace
 * @param action
 */
function* fetchDataKiMksTransitiveGraphSaga(
  api: Api,
  action: FetchDataKiMksTransitiveGraphRequestAction
) {
  try {
    const response: AxiosResponse<DataKiMksTransitiveGraphData> = yield call(
      api.get,
      '/data/ki/marketAreaGraph',
      buildConfig(action.payload)
    );

    yield put(
      DataKiMksTransitiveGraphActionCreators.fetchDataKiMksTransitiveGraphSuccessAction(
        response.data
      )
    );

    // FIXME:
    // FETCHのアクションが直接読み込まれてしまっているため、ここで閉じる
    yield put(DataKiGraphActionCreators.hideDataKiGraphAction());
    yield put(DataKiDaiActionCreators.hideDataKiDai());
    yield put(DataKiKasidamaActionCreators.hideDataKiKasidama());
  } catch (error: unknown) {
    yield put(
      DataKiMksTransitiveGraphActionCreators.renewDataKiMksTransitiveGraphAction()
    );
    yield fork(handleErrorSaga, error);
  }
}

function* handleFetchDataKiMksTransitiveGraphSaga(api: Api) {
  yield takeLatest(
    DataKiMksTransitiveGraphActionTypes.FETCH_DATA_KI_MKS_TRANSITIVE_GRAPH_REQUEST,
    fetchDataKiMksTransitiveGraphSaga,
    api
  );
}

// 推移グラフ（店舗比較）に関するタスクを実行
export function* dataKiMksTransitiveGraphSagas(context: { api: Api }) {
  yield fork(handleFetchDataKiMksTransitiveGraphSaga, context.api);
}
