import { AxiosResponse } from 'axios';
import { call, fork, put, takeEvery } from 'redux-saga/effects';

import { SettingsOptionsPlan } from '../domain/settingsOptionsPlan';

import {
  SettingsOptionsPlanActionCreators,
  SettingsOptionsPlanActionTypes,
} from '../redux/server/settingsOptionsPlan';
import { Api, buildConfig } from '../utils/api';
import { handleErrorSaga } from './errorSaga';

export function* fetchSettingsOptionsPlanSaga(api: Api) {
  try {
    yield put(
      SettingsOptionsPlanActionCreators.fetchSettingsOptionsPlanRequestAction()
    );
    const response: AxiosResponse<SettingsOptionsPlan> = yield call(
      api.get,
      '/settings/options/plan',
      buildConfig()
    );
    const settingsOptionsPlan = response.data;
    yield put(
      SettingsOptionsPlanActionCreators.fetchSettingsOptionsPlanSuccessAction(
        settingsOptionsPlan
      )
    );
  } catch (error: unknown) {
    yield put(
      SettingsOptionsPlanActionCreators.renewSettingsOptionsPlanAction()
    );
    yield fork(handleErrorSaga, error);
  }
}

function* handleFetchSettingsOptionsPlanSaga(api: Api) {
  yield takeEvery(
    SettingsOptionsPlanActionTypes.FETCH_SETTINGS_OPTIONS_PLAN,
    fetchSettingsOptionsPlanSaga,
    api
  );
}

export function* settingsOptionsPlanSagas(context: { api: Api }) {
  yield fork(handleFetchSettingsOptionsPlanSaga, context.api);
}
