import {
  Dispatch,
  FC,
  PropsWithChildren,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from 'react';

import { ModelError } from '../domain/error';

import { useInterceptError } from '../hooks/useInterceptError';

type ApiErrorState =
  | {
      isError: false;
      data?: unknown;
    }
  | {
      isError: true;
      data: ModelError;
    };

const initialState: ApiErrorState = {
  isError: false,
};

const ApiErrorStateContext = createContext<ApiErrorState>(initialState);
const SetApiErrorStateContext = createContext<
  Dispatch<SetStateAction<ApiErrorState>>
>(() => {
  // initial value
});

export const useApiErrorState = () => {
  return useContext(ApiErrorStateContext);
};

export const useSetApiErrorState = () => {
  return useContext(SetApiErrorStateContext);
};

/**
 * Axiosのinterceptorsを使用してAPIで発生したエラーの処理をする
 */
const InterceptError: FC<PropsWithChildren> = (props) => {
  const { children } = props;
  useInterceptError();

  return <>{children}</>;
};

export const ErrorContextProvider = (props: { children?: ReactNode }) => {
  const [state, setState] = useState<ApiErrorState>(initialState);

  return (
    <ApiErrorStateContext.Provider value={state}>
      <SetApiErrorStateContext.Provider value={setState}>
        <InterceptError>{props.children}</InterceptError>
      </SetApiErrorStateContext.Provider>
    </ApiErrorStateContext.Provider>
  );
};
