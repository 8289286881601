import { AxiosResponse } from 'axios';
import { call, fork, put, takeEvery } from 'redux-saga/effects';

import { SettingsOptionsPpmShare } from '../domain/ppmShare/types';

import {
  SettingsOptionsPpmShareActionCreators,
  SettingsOptionsPpmShareActionTypes,
} from '../redux/server/settingsOptionsPpmShare';
import { Api, buildConfig } from '../utils/api';
import { handleErrorSaga } from './errorSaga';

export function* fetchSettingsOptionsPpmShareSaga(api: Api) {
  try {
    yield put(
      SettingsOptionsPpmShareActionCreators.fetchSettingsOptionsPpmShareRequestAction()
    );
    const response: AxiosResponse<SettingsOptionsPpmShare> = yield call(
      api.get,
      '/settings/options/ppmShare',
      buildConfig()
    );

    yield put(
      SettingsOptionsPpmShareActionCreators.fetchSettingsOptionsPpmShareSuccessAction(
        response.data
      )
    );
  } catch (error: unknown) {
    yield put(
      SettingsOptionsPpmShareActionCreators.renewSettingsOptionsPpmShareAction()
    );
    yield fork(handleErrorSaga, error);
  }
}

function* handleFetchSettingsOptionsPpmShareSaga(api: Api) {
  yield takeEvery(
    SettingsOptionsPpmShareActionTypes.FETCH_SETTINGS_OPTIONS_PPM_SHARE,
    fetchSettingsOptionsPpmShareSaga,
    api
  );
}

export function* settingsOptionsPpmShareSagas(context: { api: Api }) {
  yield fork(handleFetchSettingsOptionsPpmShareSaga, context.api);
}
