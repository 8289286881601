import { AxiosResponse } from 'axios';
import { call, fork, put, select, takeEvery } from 'redux-saga/effects';

import { DataKadoGraph, DataKadoGraphParams } from '../domain/dataKadoGraph';
import { HallReportsFormConditions } from '../domain/hallReportsFormConditions';
import { HallReportsSettingDateRangeParams } from '../domain/hallReportsSettingDateRangeParams';
import { KadoFieldType } from '../domain/schemas';
import { ShuOption } from '../domain/shu';

import { dataHallShuKadoSelectedKadoFieldTypeSelector } from '../redux/server/dataHallShuKadoTable';
import {
  DataHallShuKadoTableGraphActionCreators,
  DataHallShuKadoTableGraphActionTypes,
  FetchDataHallShuKadoTableGraphAction,
  SearchDataHallShuKadoTableGraphAction,
  SearchDataHallShuKadoTableGraphKadoFieldTypeAction,
  SearchDataHallShuKadoTableGraphShuAction,
  dataHallShuKadoTableGraphIsExistSelector,
  dataHallShuKadoTableGraphLoadingSelector,
  dataHallShuKadoTableGraphParamsSelector,
  dataHallShuKadoTableGraphSelectedShuSelector,
} from '../redux/server/dataHallShuKadoTableGraph';
import {
  hallReportsComparativeSectionSelector,
  hallReportsSearchConditionSelector,
  hallReportsSelectedDateRangeParamsSelector,
} from '../redux/ui/hallReportsSetting';
import { Api, buildConfig } from '../utils/api';
import { ComparativeSection } from '../utils/comparativeSectionUtil';
import { handleErrorSaga } from './errorSaga';
import { searchConditionToDateRangeParams } from './hallReportsSettingSaga';

// 検索条件を指定して時間帯別稼動数（率）稼動テーブルグラフデータを取得する
export function* fetchDataHallShuKadoTableGraphSaga(
  api: Api,
  action: FetchDataHallShuKadoTableGraphAction
) {
  try {
    // リクエストを開始
    yield put(
      DataHallShuKadoTableGraphActionCreators.fetchDataHallShuKadoTableGraphRequestAction(
        action.payload.params
      )
    );
    const response: AxiosResponse<DataKadoGraph> = yield call(
      api.get,
      '/data/hall/shu/kado/graph',
      buildConfig({
        ...action.payload.params,
      })
    );

    yield put(
      DataHallShuKadoTableGraphActionCreators.fetchDataHallShuKadoTableGraphSuccessAction(
        response.data
      )
    );
  } catch (error: unknown) {
    yield put(
      DataHallShuKadoTableGraphActionCreators.renewDataHallShuKadoTableGraphAction()
    );
    yield fork(handleErrorSaga, error);
  }
}

// FETCH_DATA_HALL_SHU_KADO_TABLE_GRAPHがdispatchされたタイミングで時間帯別稼動数（率）グラフデータを取得
function* handleFetchDataHallShuKadoTableGraphSaga(api: Api) {
  yield takeEvery(
    DataHallShuKadoTableGraphActionTypes.FETCH_DATA_HALL_SHU_KADO_TABLE_GRAPH,
    fetchDataHallShuKadoTableGraphSaga,
    api
  );
}

// 時間帯別稼働数グラフを初回取得する
function* initDataHallShuKadoTableGraphSaga() {
  // 初回ロードするかどうか判定する
  const isLoading: boolean = yield select(
    dataHallShuKadoTableGraphLoadingSelector
  ); // ローディング状態
  const isExist: boolean = yield select(
    dataHallShuKadoTableGraphIsExistSelector
  ); // データが存在するか

  // 他でデータを取得中か、既にデータ取得済みか、取得したがエラーの時は初回取得しない
  if (isLoading || isExist) return;

  // 現在の検索条件
  const searchCondition: HallReportsFormConditions = yield select(
    hallReportsSearchConditionSelector
  );

  // 現在の稼働フィールドタイプ（稼働数・稼働率）
  const kadoFieldType: KadoFieldType = yield select(
    dataHallShuKadoSelectedKadoFieldTypeSelector
  );

  // 現在選択されている種別
  const selectedShu: ShuOption = yield select(
    dataHallShuKadoTableGraphSelectedShuSelector
  );

  // 店舗全体（codeが空）のときはshuListを空にする
  const shuList =
    selectedShu.type === 'shuList' && selectedShu.code !== ''
      ? [selectedShu.code]
      : [];
  const shuCodes = selectedShu.type === 'shuCodes' ? [selectedShu.code] : [];

  // 現在の検索フォームの検索条件
  const searchFormCondition: HallReportsFormConditions = yield select(
    hallReportsSearchConditionSelector
  );
  // 現在選択中の期間指定を取得
  const dateRangeParams: HallReportsSettingDateRangeParams = yield select(
    hallReportsSelectedDateRangeParamsSelector
  );
  // 現在選択中の比較項目を取得
  const comparativeSection: ComparativeSection = yield select(
    hallReportsComparativeSectionSelector
  );
  const params: DataKadoGraphParams = {
    shuList,
    shuCodes,
    halls: searchCondition.halls,
    excludeToday: searchCondition.excludeToday,
    // 以下の項目は稼動テーブルの検索条件(kadotableSearchCondition)から混ぜる
    kadoFieldType,
    // MEMO: 検索フォームの期間を混ぜる
    ...searchConditionToDateRangeParams(
      dateRangeParams.dateRange,
      dateRangeParams.dateUnit,
      dateRangeParams.isComparison,
      searchFormCondition,
      comparativeSection
    ),
  };

  // データを取得
  yield put(
    DataHallShuKadoTableGraphActionCreators.fetchDataHallShuKadoTableGraphAction(
      params
    )
  );
}

// 検索実行時検索フォームの内容で時間帯別稼働数グラフを再取得する
function* searchDataHallShuKadoTableGraphSaga(
  action: SearchDataHallShuKadoTableGraphAction
) {
  // 現在の検索条件
  const searchCondition: DataKadoGraphParams = yield select(
    dataHallShuKadoTableGraphParamsSelector
  );

  const params: DataKadoGraphParams = {
    ...searchCondition,
    ...action.payload.params,
    // MEMO: 検索実行時にパチンコ全体・スロット全体と選択中の種別があるか判定できないため店舗全体に戻す
    shuCodes: [],
    shuList: [],
  };
  // データを再取得
  yield put(
    DataHallShuKadoTableGraphActionCreators.fetchDataHallShuKadoTableGraphAction(
      params
    )
  );
}

// 指定した種別の稼働数グラフを取得する
function* searchDataHallShuKadoTableGraphShuSaga(
  action: SearchDataHallShuKadoTableGraphShuAction
) {
  // 現在の検索条件
  const searchCondition: DataKadoGraphParams = yield select(
    dataHallShuKadoTableGraphParamsSelector
  );

  // 店舗全体（codeが空）のときはshuListを空にする
  const shuList =
    action.payload.shu.type === 'shuList' && action.payload.shu.code !== ''
      ? [action.payload.shu.code]
      : [];
  const shuCodes =
    action.payload.shu.type === 'shuCodes' ? [action.payload.shu.code] : [];

  // データを取得
  yield put(
    DataHallShuKadoTableGraphActionCreators.fetchDataHallShuKadoTableGraphAction(
      {
        ...searchCondition,
        shuList,
        shuCodes,
      }
    )
  );
}

// 指定した稼働フィールドタイプ（稼働数・稼働率）の稼働数グラフを取得する
function* searchDataHallShuKadoTableGraphKadoFieldTypeSaga(
  action: SearchDataHallShuKadoTableGraphKadoFieldTypeAction
) {
  // 現在の検索条件
  const searchCondition: DataKadoGraphParams = yield select(
    dataHallShuKadoTableGraphParamsSelector
  );

  // データを取得
  yield put(
    DataHallShuKadoTableGraphActionCreators.fetchDataHallShuKadoTableGraphAction(
      {
        ...searchCondition,
        kadoFieldType: action.payload.kadoFieldType,
      }
    )
  );
}

// INIT_DATA_HALL_SHU_KADO_TABLE_GRAPHがdispatchされた時の処理
function* handleInitDataHallShuKadoTableGraphSaga() {
  yield takeEvery(
    DataHallShuKadoTableGraphActionTypes.INIT_DATA_HALL_SHU_KADO_TABLE_GRAPH,
    initDataHallShuKadoTableGraphSaga
  );
}

// SEARCH_DATA_HALL_SHU_KADO_TABLE_GRAPHがdispatchされた時の処理
function* handleSearchDataHallShuKadoTableGraphSaga() {
  yield takeEvery(
    DataHallShuKadoTableGraphActionTypes.SEARCH_DATA_HALL_SHU_KADO_TABLE_GRAPH,
    searchDataHallShuKadoTableGraphSaga
  );
}

// SEARCH_DATA_HALL_SHU_KADO_TABLE_GRAPH_SHUがdispatchされた時の処理
function* handleSearchDataHallShuKadoTableGraphShuSaga() {
  yield takeEvery(
    DataHallShuKadoTableGraphActionTypes.SEARCH_DATA_HALL_SHU_KADO_TABLE_GRAPH_SHU,
    searchDataHallShuKadoTableGraphShuSaga
  );
}

// SEARCH_DATA_HALL_SHU_KADO_TABLE_GRAPH_SHUがdispatchされた時の処理
function* handleSearchDataHallShuKadoTableGraphKadoFieldTypeSaga() {
  yield takeEvery(
    DataHallShuKadoTableGraphActionTypes.SEARCH_DATA_HALL_SHU_KADO_TABLE_GRAPH_KADO_FIELD_TYPE,
    searchDataHallShuKadoTableGraphKadoFieldTypeSaga
  );
}

// 時間帯別稼動数（率）グラフに関するタスクを実行
export function* dataHallShuKadoTableGraphSagas(context: { api: Api }) {
  yield fork(handleFetchDataHallShuKadoTableGraphSaga, context.api);
  yield fork(handleInitDataHallShuKadoTableGraphSaga);
  yield fork(handleSearchDataHallShuKadoTableGraphSaga);
  yield fork(handleSearchDataHallShuKadoTableGraphShuSaga);
  yield fork(handleSearchDataHallShuKadoTableGraphKadoFieldTypeSaga);
}
