import {
  MAIN_FIELD_TYPE,
  MainFieldType,
  QueryParameter,
} from '../domain/schemas';

/**
 * 推移グラフを表示するために必要なパラメータを作成
 *
 * 推移グラフを表示するために、検索時に詳細条件で指定した「機種,SISタイプ,メーカー」は支持率系項目の計算に必要な情報な為、パラメータとしてそれぞれ「kiListForSijiritu,sisTypesForSijiritu,makersForSijiritu」を付与する必要がある。
 * また、選択された行によって「kiList,sisTypes,makers」を付与する必要がある。
 * 仕様：https://www.notion.so/Forsijiritu-e432054f16b343e6934364a27d1cc410
 */
export const dataKiGraphSearchConditionParams = <
  T extends {
    mainField?: MainFieldType;
    kiList?: string[];
    sisTypes?: string[];
    makers?: string[];
    kiListForSijiritu?: string[];
    sisTypesForSijiritu?: string[];
    makersForSijiritu?: string[];
  }
>(
  queryParams: QueryParameter,
  requestParams:
    | {
        hallId: string;
        mainFieldId: string;
      }
    | undefined,
  searchParams: T,
  onlyOneKiTagAverageTagIds?: string[],
  kiListFromTags?: string[]
) => {
  // 詳細条件で設定された機種やSISタイプ、メーカーの支持率計算のために必要なパラメータ
  const searchConditionForTypes = {
    kiList: searchParams.kiList,
    makers: searchParams.makers,
    sisTypes: searchParams.sisTypes,
    kiListForSijiritu: searchParams.kiListForSijiritu,
    sisTypesForSijiritu: searchParams.sisTypesForSijiritu,
    makersForSijiritu: searchParams.makersForSijiritu,
  };

  // queryParams.name(kiList / sisTypes / makers) を指定されたもののみに変更する
  // 平均合計行の場合はフィルターした検索条件を分子として渡す
  let selectedRowMainFieldType = {};
  if (queryParams.value === 'null') {
    selectedRowMainFieldType = {
      kiList: searchParams.kiList,
      sisTypes: searchParams.sisTypes,
      makers: searchParams.makers,
    };
  }
  // 機種タグ平均合計行、もしくは各機種タグ平均合計行の推移グラフが選択されたときに、機種タグに紐づいた機種一覧を設定
  else if (
    queryParams.value === 'kiTagAverage' ||
    queryParams.value ===
      `onlyOneKiTagAverage_${onlyOneKiTagAverageTagIds?.at(0)?.toString()}`
  ) {
    selectedRowMainFieldType = {
      kiList: kiListFromTags,
    };
  } else {
    selectedRowMainFieldType = {
      [queryParams.name]: [queryParams.value],
    };
  }

  // 機種行で推移グラフを選択されたときにどの出力タイプ（SISタイプ・メーカー別）で表示されたものなのかを判断するため、パラメータ設定
  // @param requestParams.mainFieldId: 出力項目（機種別/SISタイプ別/メーカー別）
  // @param requestParams: タイプ・メーカー出力の機種行展開で、選択されたホールや選択されたときのタイプ・メーカー情報
  // @param mainFieldId: 推移グラフで利用するセレクタで利用
  // @param hallId: 推移グラフで利用するホールセレクタで利用
  let setMainFieldTypeParams = {};
  if (requestParams != null) {
    if (searchParams.mainField === MAIN_FIELD_TYPE.TYPE) {
      setMainFieldTypeParams = {
        sisTypes: [requestParams.mainFieldId],
        mainFieldId: [requestParams.mainFieldId],
        hallId: [requestParams.hallId],
      };
    } else {
      setMainFieldTypeParams = {
        makers: [requestParams.mainFieldId],
        mainFieldId: [requestParams.mainFieldId],
        hallId: [requestParams.hallId],
      };
    }
  }

  return {
    ...searchConditionForTypes,
    ...selectedRowMainFieldType,
    ...setMainFieldTypeParams,
  };
};
