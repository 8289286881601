import { createSelector } from 'reselect';

import { DkSisSearchParams } from '../../domain/dkSis';
import { SharedUser } from '../../domain/favorites';
import {
  defaultDateRangeParams,
  generateInitialResponse,
} from '../../domain/ki/defaultValues';
import {
  KiComparativeSection,
  KiDateRange,
  KiReportsSettingDateRangeParams,
} from '../../domain/ki/types';
import { MarkingOption } from '../../domain/marking';
import { ModelReportsFormConditions } from '../../domain/modelReportsFormConditions';
import { Option, OrderType } from '../../domain/schemas';
import { ShuOption } from '../../domain/shu';

import { RootState } from '../../store';
import { getYmdList } from '../../utils/date';
import { favoritesSelector } from './settingsFavorites';

/**
 * Action Types
 *
 * プレフィックスの意味
 * SEARCH: ReducerでStateの変更はしないがデータを取得する（検索ボタン等Sagaを発火させたい）
 * CHANGE: Stateを変更してデータも取得する
 * SELECT: Stateを変更する（データ取得は行わない）
 * RESET: Stateを初期状態に戻す（データ取得は行わない）
 */
const INIT_MODEL_REPORTS = 'INIT_MODEL_REPORTS' as const;
const SEARCH_MODEL_REPORTS = 'SEARCH_MODEL_REPORTS' as const;
const SEARCH_RESET_MODEL_REPORTS = 'SEARCH_RESET_MODEL_REPORTS' as const;
const SEARCH_MODEL_REPORTS_SORT = 'SEARCH_MODEL_REPORTS_SORT' as const;
const SEARCH_MODEL_REPORTS_MARKING = 'SEARCH_MODEL_REPORTS_MARKING' as const;
const SEARCH_MODEL_REPORTS_FIELDS = 'SEARCH_MODEL_REPORTS_FIELDS' as const;
const SEARCH_MODEL_REPORTS_DKSIS_ON = 'SEARCH_MODEL_REPORTS_DKSIS_ON' as const;
const SEARCH_MODEL_REPORTS_DKSIS_OFF = 'SEARCH_MODEL_REPORTS_DKSIS_OFF' as const;
const SEARCH_MODEL_REPORTS_DKSIS = 'SEARCH_MODEL_REPORTS_DKSIS' as const;

const CHANGE_MODEL_REPORTS_FAVORITE = 'CHANGE_MODEL_REPORTS_FAVORITE' as const;
const CHANGE_MODEL_REPORTS_CURRENT_SHU = 'CHANGE_MODEL_REPORTS_CURRENT_SHU' as const;

const SELECT_MODEL_REPORTS_SEARCH_CONDITION = 'SELECT_MODEL_REPORTS_SEARCH_CONDITION' as const;
const SELECT_MODEL_REPORTS_SHU = 'SELECT_MODEL_REPORTS_SHU' as const;
const SELECT_MODEL_REPORTS_CURRENT_SHU = 'SELECT_MODEL_REPORTS_CURRENT_SHU' as const;
const SELECT_MODEL_REPORTS_CURRENT_HALLS = 'SELECT_MODEL_REPORTS_CURRENT_HALLS' as const;
const SELECT_MODEL_REPORTS_DATE_RANGE_PARAMS = 'SELECT_MODEL_REPORTS_DATE_RANGE_PARAMS' as const;
const SELECT_MODEL_REPORTS_DAI_COLUMNS_ORDER = 'SELECT_MODEL_REPORTS_DAI_COLUMNS_ORDER' as const;
const SELECT_MODEL_REPORTS_KASIDAMA_COLUMNS_ORDER = 'SELECT_MODEL_REPORTS_KASIDAMA_COLUMNS_ORDER' as const;
const SELECT_MODEL_REPORTS_IS_DK_SIS = 'SELECT_MODEL_REPORTS_IS_DK_SIS' as const;
const SELECT_MODEL_REPORTS_DKSIS = 'SELECT_MODEL_REPORTS_DKSIS' as const;
const SELECT_MODEL_REPORTS_DKSIS_IS_AUTO = 'SELECT_MODEL_REPORTS_DKSIS_IS_AUTO' as const;

const RESET_MODEL_REPORTS_SEARCH_CONDITION = 'RESET_MODEL_REPORTS_SEARCH_CONDITION' as const;
const RESET_MODEL_REPORTS_DATE_RANGE_PARAMS = 'RESET_MODEL_REPORTS_DATE_RANGE_PARAMS' as const;
const RESET_MODEL_REPORTS_SHU = 'RESET_MODEL_REPORTS_SHU' as const;

const SAVE_MODEL_REPORTS_FAVORITE = 'SAVE_MODEL_REPORTS_FAVORITE' as const;
const SAVE_AS_MODEL_REPORTS_FAVORITE = 'SAVE_AS_MODEL_REPORTS_FAVORITE' as const;

const ADD_MODEL_REPORTS_TABLE_FILTER = 'ADD_MODEL_REPORTS_TABLE_FILTER' as const;
const REMOVE_MODEL_REPORTS_TABLE_FILTER = 'REMOVE_MODEL_REPORTS_TABLE_FILTER' as const;
const APPLY_MODEL_REPORTS_TABLE_FILTER = 'APPLY_MODEL_REPORTS_TABLE_FILTER' as const;
const RESET_MODEL_REPORTS_TABLE_FILTER = 'RESET_MODEL_REPORTS_TABLE_FILTER' as const;

const SELECT_MODEL_REPORTS_MODEL_NAME_FILTER = 'SELECT_MODEL_REPORTS_MODEL_NAME_FILTER' as const;

const SELECT_MODEL_REPORTS_SUB_MENU_NUMBER_FILTER = 'SELECT_MODEL_REPORTS_SUB_MENU_NUMBER_FILTER' as const;
const RESET_MODEL_REPORTS_SUB_MENU_NUMBER_FILTER = 'RESET_MODEL_REPORTS_SUB_MENU_NUMBER_FILTER' as const;

const SEARCH_MODEL_REPORTS_DATE_RANGE_SLIDE = 'SEARCH_MODEL_REPORTS_DATE_RANGE_SLIDE' as const;

const RESET_MODEL_REPORTS_FAVORITE_TO_DEFAULT = 'RESET_MODEL_REPORTS_FAVORITE_TO_DEFAULT' as const;

const SEARCH_MODEL_REPORTS_SELECTED_KI_GRAPH_DK_DIS_CONDITION = 'SEARCH_MODEL_REPORTS_SELECTED_KI_GRAPH_DK_DIS_CONDITION' as const;
const SELECT_MODEL_REPORTS_GRAPH_SHOW_GRAPH_NUMBER_LABEL = 'SELECT_MODEL_REPORTS_GRAPH_SHOW_GRAPH_NUMBER_LABEL' as const;

const SELECT_MODEL_REPORTS_SELECTED_HALLS = 'SELECT_MODEL_REPORTS_SELECTED_HALLS' as const;
const CHANGE_MODEL_REPORTS_CURRENT_HALLS = 'CHANGE_MODEL_REPORTS_CURRENT_HALLS' as const;

const SEARCH_MODEL_REPORTS_SELECTED_KI_KASIDAMA_DK_DIS_CONDITION = 'SEARCH_MODEL_REPORTS_SELECTED_KI_KASIDAMA_DK_DIS_CONDITION' as const;

const SELECT_MODEL_REPORTS_MKS_TRANSITIVE_GRAPH_SHOW_GRAPH_NUMBER_LABEL = 'SELECT_MODEL_REPORTS_MKS_TRANSITIVE_GRAPH_SHOW_GRAPH_NUMBER_LABEL' as const;
const SELECT_MODEL_REPORTS_MKS_TRANSITIVE_GRAPH_SELECTED_MKS_HALL_CODES = 'SELECT_MODEL_REPORTS_MKS_TRANSITIVE_GRAPH_SELECTED_MKS_HALL_CODES' as const;

const CHANGE_SINGLE_DATA_KI_EXPANDED_ROW = 'CHANGE_SINGLE_DATA_KI_EXPANDED_ROW' as const;

const RESET_MODEL_REPORTS_DERIVED_SCREEN = 'RESET_MODEL_REPORTS_DERIVED_SCREEN' as const;
const SELECT_KI_TAG_LABEL = 'SELECT_KI_TAG_LABEL' as const;
const SELECT_MODEL_REPORTS_IS_KI_TAG_AVERAGE_GRAPH = 'SELECT_MODEL_REPORTS_IS_KI_TAG_AVERAGE_GRAPH' as const;
const SELECT_MODEL_REPORTS_IS_ONLY_ONE_KI_TAG_AVERAGE_GRAPH = 'SELECT_MODEL_REPORTS_IS_ONLY_ONE_KI_TAG_AVERAGE_GRAPH' as const;
const CREATE_SHORTENED_URL = 'CREATE_SHORTENED_URL' as const;

export const ModelReportsSettingActionTypes = {
  INIT_MODEL_REPORTS,
  SEARCH_MODEL_REPORTS,
  SEARCH_RESET_MODEL_REPORTS,
  SEARCH_MODEL_REPORTS_SORT,
  SEARCH_MODEL_REPORTS_MARKING,
  SEARCH_MODEL_REPORTS_FIELDS,
  SEARCH_MODEL_REPORTS_DKSIS_ON,
  SEARCH_MODEL_REPORTS_DKSIS_OFF,
  SEARCH_MODEL_REPORTS_DKSIS,
  CHANGE_MODEL_REPORTS_CURRENT_SHU,
  CHANGE_MODEL_REPORTS_FAVORITE,
  SELECT_MODEL_REPORTS_SEARCH_CONDITION,
  SELECT_MODEL_REPORTS_SHU,
  SELECT_MODEL_REPORTS_CURRENT_SHU,
  SELECT_MODEL_REPORTS_CURRENT_HALLS,
  SELECT_MODEL_REPORTS_DATE_RANGE_PARAMS,
  SELECT_MODEL_REPORTS_DAI_COLUMNS_ORDER,
  SELECT_MODEL_REPORTS_KASIDAMA_COLUMNS_ORDER,
  SELECT_MODEL_REPORTS_IS_DK_SIS,
  SELECT_MODEL_REPORTS_DKSIS,
  SELECT_MODEL_REPORTS_DKSIS_IS_AUTO,
  RESET_MODEL_REPORTS_SEARCH_CONDITION,
  RESET_MODEL_REPORTS_DATE_RANGE_PARAMS,
  RESET_MODEL_REPORTS_SHU,
  RESET_MODEL_REPORTS_SUB_MENU_NUMBER_FILTER,
  SAVE_MODEL_REPORTS_FAVORITE,
  SAVE_AS_MODEL_REPORTS_FAVORITE,
  ADD_MODEL_REPORTS_TABLE_FILTER,
  REMOVE_MODEL_REPORTS_TABLE_FILTER,
  APPLY_MODEL_REPORTS_TABLE_FILTER,
  RESET_MODEL_REPORTS_TABLE_FILTER,
  SELECT_MODEL_REPORTS_MODEL_NAME_FILTER,
  SELECT_MODEL_REPORTS_SUB_MENU_NUMBER_FILTER,
  SEARCH_MODEL_REPORTS_DATE_RANGE_SLIDE,
  RESET_MODEL_REPORTS_FAVORITE_TO_DEFAULT,
  SEARCH_MODEL_REPORTS_SELECTED_KI_GRAPH_DK_DIS_CONDITION,
  SEARCH_MODEL_REPORTS_SELECTED_KI_KASIDAMA_DK_DIS_CONDITION,
  SELECT_MODEL_REPORTS_GRAPH_SHOW_GRAPH_NUMBER_LABEL,
  SELECT_MODEL_REPORTS_SELECTED_HALLS,
  CHANGE_MODEL_REPORTS_CURRENT_HALLS,
  SELECT_MODEL_REPORTS_MKS_TRANSITIVE_GRAPH_SHOW_GRAPH_NUMBER_LABEL,
  SELECT_MODEL_REPORTS_MKS_TRANSITIVE_GRAPH_SELECTED_MKS_HALL_CODES,
  CHANGE_SINGLE_DATA_KI_EXPANDED_ROW,
  RESET_MODEL_REPORTS_DERIVED_SCREEN,
  SELECT_KI_TAG_LABEL,
  SELECT_MODEL_REPORTS_IS_KI_TAG_AVERAGE_GRAPH,
  SELECT_MODEL_REPORTS_IS_ONLY_ONE_KI_TAG_AVERAGE_GRAPH,
  CREATE_SHORTENED_URL,
};

/**
 * Action Creators
 */

/**
 * 初回表示時のデータ取得
 */
function initModelReportsAction() {
  return {
    type: INIT_MODEL_REPORTS,
  };
}

/**
 * 指定した検索条件でデータを取得する（検索ボタン押下時）
 */
export function searchModelReportsAction(params: {
  params: ModelReportsFormConditions;
  selectedShu: ShuOption[];
  dateRangeParams: KiReportsSettingDateRangeParams;
  currentHalls: string[] | undefined;
}) {
  return {
    type: SEARCH_MODEL_REPORTS,
    payload: { params },
  };
}

/**
 * 派生画面表示のリセット処理
 */
function resetModelReportsDerivedScreenAction() {
  return {
    type: RESET_MODEL_REPORTS_DERIVED_SCREEN,
  };
}

/**
 * 初期条件でデータを取得する（リセットボタン押下時）
 */
function searchResetModelReportsAction() {
  return {
    type: SEARCH_RESET_MODEL_REPORTS,
  };
}

/**
 * 指定したソート条件でデータを再取得する
 * @param sort ソートする条件
 * @param order 昇順・降順
 */
function searchModelReportsSortAction(sort: string, order: OrderType) {
  return {
    type: SEARCH_MODEL_REPORTS_SORT,
    payload: { sort, order },
  };
}

/**
 * 指定したマーキング条件でデータを再取得する
 * @param markingOption マーキング項目
 * @param isFiltering 該当行のみ表示するか
 */
function searchModelReportsMarkingAction(
  markingOption: MarkingOption,
  isFiltering: boolean
) {
  return {
    type: SEARCH_MODEL_REPORTS_MARKING,
    payload: { markingOption, isFiltering },
  };
}

/**
 * 指定した表示項目に絞ってデータを再取得する
 * @param fields 表示項目
 */
function searchModelReportsFieldAction(fields: Option[]) {
  return {
    type: SEARCH_MODEL_REPORTS_FIELDS,
    payload: { fields },
  };
}

/**
 * DK-SISを有効にする
 */
function searchModelReportsDkSisOnAction() {
  return {
    type: SEARCH_MODEL_REPORTS_DKSIS_ON,
  };
}

/**
 * DK-SISを無効にする
 */
function searchModelReportsDkSisOffAction() {
  return {
    type: SEARCH_MODEL_REPORTS_DKSIS_OFF,
  };
}

/**
 * 指定したDK-SISの検索条件で再取得する
 */
function searchModelReportsDkSisAction(params: DkSisSearchParams) {
  return {
    type: SEARCH_MODEL_REPORTS_DKSIS,
    payload: { params },
  };
}

/**
 * DK-SISの内部保持値を変更
 */
function selectSearchModelReportsDkSisAction(
  params: ModelReportsSettingState['dkSis']
) {
  return {
    type: SELECT_MODEL_REPORTS_DKSIS,
    payload: { params },
  };
}

/**
 * DK-SISのisAutoを変更
 */
function selectSearchModelReportsDkSisIsAutoAction(isAuto: boolean) {
  return {
    type: SELECT_MODEL_REPORTS_DKSIS_IS_AUTO,
    payload: { isAuto },
  };
}

/**
 * 選択した種別のデータを取得する
 *
 * MEMO: 初回取得時とテーブル上部の種別選択で変更時に使用する
 * @param shu 絞り込む種別
 */
function changeModelReportsCurrentShuAction(shu?: ShuOption) {
  return {
    type: CHANGE_MODEL_REPORTS_CURRENT_SHU,
    payload: { shu },
  };
}

/**
 * 選択したお気に入りでデータを取得する
 *
 * @param favorite 変更するお気に入りID（undefined時は選択なし）
 */
function changeModelReportsFavoriteAction(favorite?: number) {
  return {
    type: CHANGE_MODEL_REPORTS_FAVORITE,
    payload: { favorite },
  };
}

/**
 * データを取得せずにお気に入りをデフォルトに戻す
 */
export function resetModelReportsFavoriteToDefaultAction() {
  return {
    type: RESET_MODEL_REPORTS_FAVORITE_TO_DEFAULT,
    payload: {},
  };
}

/**
 * 検索条件を変更
 */
function selectModelReportsSearchConditionAction(
  params: ModelReportsFormConditions
) {
  return {
    type: SELECT_MODEL_REPORTS_SEARCH_CONDITION,
    payload: { params },
  };
}

/**
 * 種別一覧を変更
 * @param shus 種別一覧
 */
export function selectModelReportsShuAction(shus: ShuOption[]) {
  return {
    type: SELECT_MODEL_REPORTS_SHU,
    payload: { shus },
  };
}

/**
 * 絞り込む種別を変更
 *
 * MEMO: 検索時やお気に入り適用時に使用する（全体的なStateだけ変更して最終的にFetchしたいケース）
 * @param shu 絞り込む種別
 */
export function selectModelReportsCurrentShuAction(shu?: ShuOption) {
  return {
    type: SELECT_MODEL_REPORTS_CURRENT_SHU,
    payload: { shu },
  };
}

/**
 * 選択中の店舗を変更
 *
 * MEMO: 検索時やお気に入り適用時に使用する（全体的なStateだけ変更して最終的にFetchしたいケース）
 * @param halls 選択中の店舗
 */
function selectModelReportsCurrentHallsAction(halls: string[]) {
  return {
    type: SELECT_MODEL_REPORTS_CURRENT_HALLS,
    payload: { halls },
  };
}

/**
 * 機種行への機種タグ表示機能の表示状態を変更
 * @param {boolean} flag 表示フラグ
 */
function selectShowKiTagAction(flag: boolean) {
  return {
    type: SELECT_KI_TAG_LABEL,
    payload: {
      flag,
    },
  };
}

/**
 * 選択中の期間単位を変更
 */
function selectModelReportsDateRangeParamsAction(
  params: Omit<KiReportsSettingDateRangeParams, 'comparisonSection'> & {
    comparisonSection: KiComparativeSection;
  }
) {
  return {
    type: SELECT_MODEL_REPORTS_DATE_RANGE_PARAMS,
    payload: { params },
  };
}

export function selectModelReportsDaiColumnsOrderAction(
  daiColumnsOrder: string[]
) {
  return {
    type: SELECT_MODEL_REPORTS_DAI_COLUMNS_ORDER,
    payload: { daiColumnsOrder },
  };
}

export function selectModelReportsKasidamaColumnsOrderAction(
  kasidamaColumnsOrder: string[]
) {
  return {
    type: SELECT_MODEL_REPORTS_KASIDAMA_COLUMNS_ORDER,
    payload: { kasidamaColumnsOrder },
  };
}

/**
 * 選択中の期間をデフォルトに戻す
 */
function resetModelReportsDateRangeParamsAction() {
  return {
    type: RESET_MODEL_REPORTS_DATE_RANGE_PARAMS,
  };
}

/**
 * 検索条件をデフォルトに戻す
 */
function resetModelReportsSearchConditionAction() {
  return {
    type: RESET_MODEL_REPORTS_SEARCH_CONDITION,
  };
}

/**
 * 選択中の種別一覧をデフォルトに戻す
 */
function resetModelReportsShuAction() {
  return {
    type: RESET_MODEL_REPORTS_SHU,
  };
}

/**
 * 現在の検索条件でお気に入りを上書き保存する
 * @param name お気に入り名
 * @param isShared 共有設定
 * @param memo メモ
 * @param sharedUser 共有ユーザ
 * @param mode メモ更新モード
 */
function saveModelReportsFavoriteAction(
  name: string,
  isShared: boolean,
  memo: string,
  sharedUser: SharedUser[],
  mode?: 'standard' | 'memo'
) {
  return {
    type: SAVE_MODEL_REPORTS_FAVORITE,
    payload: { name, isShared, memo, sharedUser, mode },
  };
}

/**
 * 現在の検索条件でお気に入りを新規保存する
 * @param name お気に入り名
 * @param isShared 共有設定
 * @param memo メモ
 * @param sharedUser 共有ユーザ
 */
function saveAsModelReportsFavoriteAction(
  name: string,
  isShared: boolean,
  memo: string,
  sharedUser: SharedUser[]
) {
  return {
    type: SAVE_AS_MODEL_REPORTS_FAVORITE,
    payload: { name, isShared, memo, sharedUser },
  };
}

/**
 * テーブルの非表示項目を追加する
 * @param columnCode カラム名
 */
function addModelReportsTableFilterAction(columnCode: string) {
  return {
    type: ADD_MODEL_REPORTS_TABLE_FILTER,
    payload: { columnCode },
  };
}

/**
 * テーブルの表示項目を削除する
 * @param columnCode カラム名
 */
function removeModelReportsTableFilterAction(columnCode: string) {
  return {
    type: REMOVE_MODEL_REPORTS_TABLE_FILTER,
    payload: { columnCode },
  };
}

/**
 * テーブルの非表示項目を反映する
 * @param tableFilterItems テーブルの非表示項目
 */
function applyModelReportsTableFilterAction(tableFilterItems: string[]) {
  return {
    type: APPLY_MODEL_REPORTS_TABLE_FILTER,
    payload: { tableFilterItems },
  };
}

/**
 * テーブルの非表示項目をリセットする
 */
function resetModelReportsTableFilterAction() {
  return {
    type: RESET_MODEL_REPORTS_TABLE_FILTER,
  };
}

/**
 * 機種名フィルタを変更
 * @param filterString 検索する文字列
 */
export function selectModelReportsModelNameFilterAction(filterString: string) {
  return {
    type: SELECT_MODEL_REPORTS_MODEL_NAME_FILTER,
    payload: { filterString },
  };
}

/**
 * サブメニュー内数値フィルタを変更
 * @param filterNumbers 検索する数値フィルタ条件
 */
export function selectModelReportsSubMenuNumberFilterAction(filterNumbers: {
  [field: string]: {
    minimumNumber: number | undefined;
    maximumNumber: number | undefined;
  };
}) {
  return {
    type: SELECT_MODEL_REPORTS_SUB_MENU_NUMBER_FILTER,
    payload: { filterNumbers },
  };
}

/**
 * 数値フィルタを全てリセットする
 */
export function resetModelReportsSubMenuNumberFilterAction() {
  return {
    type: RESET_MODEL_REPORTS_SUB_MENU_NUMBER_FILTER,
  };
}

/**
 * 期間スライドコンポーネントがクリックされた時に、期間を変更して検索を実行する
 * @param {KiDateRange} newDateRange   期間単位
 * @param {string} newStartDate           検索期間 開始日
 * @param {string} newEndDate             検索期間 終了日
 * @param {string} newStartComparisonDate 比較期間 開始日
 * @param {string} newEndComparisonDate   比較期間 終了日
 */
export function searchModelReportsDateRangeSlideAction(
  newDateRange: KiDateRange,
  newStartDate: string,
  newEndDate: string,
  newStartComparisonDate: string | undefined,
  newEndComparisonDate: string | undefined
) {
  return {
    type: SEARCH_MODEL_REPORTS_DATE_RANGE_SLIDE,
    payload: {
      dateRange: newDateRange,
      ymdList: getYmdList(newStartDate, newEndDate),
      ymdComparisonList:
        newStartComparisonDate !== undefined &&
        newEndComparisonDate !== undefined
          ? getYmdList(newStartComparisonDate, newEndComparisonDate)
          : undefined,
    },
  };
}

/**
 * グラフのDK-SIS条件を設定する
 */
export const searchModelReportsSelectedKiGraphDkSisCondition = (
  selectedValue: string
) => {
  return {
    type: SEARCH_MODEL_REPORTS_SELECTED_KI_GRAPH_DK_DIS_CONDITION,
    payload: selectedValue,
  };
};

/**
 * チェーン店一覧のDK-SIS条件を設定する
 */
export const searchModelReportsSelectedKiKasidamaDkSisCondition = (
  selectedValue: string
) => {
  return {
    type: SEARCH_MODEL_REPORTS_SELECTED_KI_KASIDAMA_DK_DIS_CONDITION,
    payload: selectedValue,
  };
};

/**
 * 推移グラフに数値を表示するかどうかのフラグをセットする
 * @param {boolean} flag 表示フラグ
 */
function selectModelReportsGraphShowNumberLabelAction(flag: boolean) {
  return {
    type: SELECT_MODEL_REPORTS_GRAPH_SHOW_GRAPH_NUMBER_LABEL,
    payload: {
      flag,
    },
  };
}

/**
 * 店舗選択スライダーで選択可能な店舗一覧を設定する
 */
function selectModelReportsSelectedHallsAction(halls: string[]) {
  return {
    type: SELECT_MODEL_REPORTS_SELECTED_HALLS,
    payload: { halls },
  };
}

/**
 * 店舗選択スライダーで選択した店舗のデータを取得する
 *
 * MEMO: 初回取得時とテーブル上部の店舗選択で変更時に使用する
 * @param halls 絞り込む店舗
 */
function changeModelReportsCurrentHallsAction(halls: string[]) {
  return {
    type: CHANGE_MODEL_REPORTS_CURRENT_HALLS,
    payload: { halls },
  };
}

/**
 * 商圏推移グラフの数値表示状態を取得する
 */
export const selectModelReportsMksTransitiveGraphShowGraphNumberLabel = (
  x: boolean
) => ({
  type: SELECT_MODEL_REPORTS_MKS_TRANSITIVE_GRAPH_SHOW_GRAPH_NUMBER_LABEL,
  payload: x,
});

/**
 * 商圏推移グラフの店舗選択で選ばれている商圏店舗コードを取得する
 */
export const selectModelReportsMksTransitiveGraphSelectedMksHallCodes = (
  hallCodes: string[]
) => ({
  type: SELECT_MODEL_REPORTS_MKS_TRANSITIVE_GRAPH_SELECTED_MKS_HALL_CODES,
  payload: { hallCodes },
});

/**
 * 展開行
 * @param mainFieldId
 * @param hallId
 * @param selectedRow mainFieldのkiListが、機種別行のkiListなのかSISタイプ・メーカー別行の機種行のkiListなのか判定できないため
 * @returns
 */
function changeSelectedDataKiExpandedRowAction(
  mainFieldId: string,
  hallId: string,
  selectedRow: '1stRow' | '2ndRow' | '3rdRow'
) {
  return {
    type: CHANGE_SINGLE_DATA_KI_EXPANDED_ROW,
    payload: { mainFieldId, hallId, selectedRow },
  };
}

function selectModelReportsIsDkSisAction(isDkSisOn: boolean) {
  return {
    type: SELECT_MODEL_REPORTS_IS_DK_SIS,
    payload: { isDkSisOn },
  };
}

/**
 * 機種タグ平均合計行で推移グラフの表示フラグを変更する
 */
function selectModelReportsIsKiTagAverageGraphAction(
  isKiTagAverageGraphDisplay: boolean
) {
  return {
    type: SELECT_MODEL_REPORTS_IS_KI_TAG_AVERAGE_GRAPH,
    payload: { isKiTagAverageGraphDisplay },
  };
}

/**
 * 各機種タグ平均合計行で推移グラフの表示フラグを変更する
 */
function selectModelReportsIsOnlyOneKiTagAverageGraphAction(
  isOnlyOneKiTagAverageGraphDisplay: boolean
) {
  return {
    type: SELECT_MODEL_REPORTS_IS_ONLY_ONE_KI_TAG_AVERAGE_GRAPH,
    payload: { isOnlyOneKiTagAverageGraphDisplay },
  };
}

/**
 * 画面共有用の短縮URLを作成する
 */
function createShortenedUrlAction(pageName: string, locationUrl: string) {
  return {
    type: CREATE_SHORTENED_URL,
    payload: { pageName, locationUrl },
  };
}

export const ModelReportsSettingActionCreators = {
  initModelReportsAction,
  searchModelReportsAction,
  searchResetModelReportsAction,
  searchModelReportsSortAction,
  searchModelReportsMarkingAction,
  searchModelReportsFieldAction,
  searchModelReportsDkSisOnAction,
  searchModelReportsDkSisOffAction,
  searchModelReportsDkSisAction,
  changeModelReportsFavoriteAction,
  changeModelReportsCurrentShuAction,
  selectModelReportsSearchConditionAction,
  selectModelReportsShuAction,
  selectModelReportsCurrentShuAction,
  selectModelReportsCurrentHallsAction,
  selectModelReportsDateRangeParamsAction,
  selectModelReportsDaiColumnsOrderAction,
  selectModelReportsKasidamaColumnsOrderAction,
  selectModelReportsIsDkSisAction,
  selectSearchModelReportsDkSisAction,
  selectSearchModelReportsDkSisIsAutoAction,
  resetModelReportsDateRangeParamsAction,
  resetModelReportsSearchConditionAction,
  resetModelReportsShuAction,
  saveModelReportsFavoriteAction,
  saveAsModelReportsFavoriteAction,
  addModelReportsTableFilterAction,
  removeModelReportsTableFilterAction,
  applyModelReportsTableFilterAction,
  resetModelReportsTableFilterAction,
  selectModelReportsModelNameFilterAction,
  resetModelReportsSubMenuNumberFilterAction,
  selectModelReportsSubMenuNumberFilterAction,
  searchModelReportsDateRangeSlideAction,
  resetModelReportsFavoriteToDefaultAction,
  searchModelReportsSelectedKiGraphDkSisCondition,
  searchModelReportsSelectedKiKasidamaDkSisCondition,
  selectModelReportsGraphShowNumberLabelAction,
  selectModelReportsSelectedHallsAction,
  changeModelReportsCurrentHallsAction,
  selectModelReportsMksTransitiveGraphShowGraphNumberLabel,
  selectModelReportsMksTransitiveGraphSelectedMksHallCodes,
  changeSelectedDataKiExpandedRowAction,
  resetModelReportsDerivedScreenAction,
  selectShowKiTagAction,
  selectModelReportsIsKiTagAverageGraphAction,
  selectModelReportsIsOnlyOneKiTagAverageGraphAction,
  createShortenedUrlAction,
};

/**
 * Actions
 */

export type InitModelReportsAction = ReturnType<typeof initModelReportsAction>;
export type SearchModelReportsAction = ReturnType<
  typeof searchModelReportsAction
>;
type SearchResetModelReportsAction = ReturnType<
  typeof searchResetModelReportsAction
>;
export type SearchModelReportsSortAction = ReturnType<
  typeof searchModelReportsSortAction
>;
export type SearchModelReportsMarkingAction = ReturnType<
  typeof searchModelReportsMarkingAction
>;
export type SearchModelReportsFieldAction = ReturnType<
  typeof searchModelReportsFieldAction
>;
type SearchModelReportsDkSisOnAction = ReturnType<
  typeof searchModelReportsDkSisOnAction
>;
type SearchModelReportsDkSisOffAction = ReturnType<
  typeof searchModelReportsDkSisOffAction
>;
export type SearchModelReportsDkSisAction = ReturnType<
  typeof searchModelReportsDkSisAction
>;
type SelectSearchModelReportsDkSisAction = ReturnType<
  typeof selectSearchModelReportsDkSisAction
>;
type SelectSearchModelReportsDkSisIsAutoAction = ReturnType<
  typeof selectSearchModelReportsDkSisIsAutoAction
>;
export type ChangeModelReportsFavoriteAction = ReturnType<
  typeof changeModelReportsFavoriteAction
>;
export type ChangeModelReportsCurrentShuAction = ReturnType<
  typeof changeModelReportsCurrentShuAction
>;
type SelectKiShuAction = ReturnType<typeof selectModelReportsShuAction>;
type SelectModelReportsSearchConditionAction = ReturnType<
  typeof selectModelReportsSearchConditionAction
>;
type SelectKiCurrentShuAction = ReturnType<
  typeof selectModelReportsCurrentShuAction
>;
type SelectKiCurrentHallsAction = ReturnType<
  typeof selectModelReportsCurrentHallsAction
>;
type SelectKiDateRangeParamsAction = ReturnType<
  typeof selectModelReportsDateRangeParamsAction
>;
type ResetKiDateRangeParamsAction = ReturnType<
  typeof resetModelReportsDateRangeParamsAction
>;
type SelectKiDaiColmunsOrderAction = ReturnType<
  typeof selectModelReportsDaiColumnsOrderAction
>;
type SelectKiKasidamaColmunsOrderAction = ReturnType<
  typeof selectModelReportsKasidamaColumnsOrderAction
>;
type ResetModelReportsSearchConditionAction = ReturnType<
  typeof resetModelReportsSearchConditionAction
>;
type ResetKiShuAction = ReturnType<typeof resetModelReportsShuAction>;
export type SaveModelReportsFavoriteAction = ReturnType<
  typeof saveModelReportsFavoriteAction
>;
export type SaveAsModelReportsFavoriteAction = ReturnType<
  typeof saveAsModelReportsFavoriteAction
>;
type AddModelReportsTableFilterAction = ReturnType<
  typeof addModelReportsTableFilterAction
>;
type RemoveModelReportsTableFilterAction = ReturnType<
  typeof removeModelReportsTableFilterAction
>;
type ApplyModelReportsTableFilterAction = ReturnType<
  typeof applyModelReportsTableFilterAction
>;
type ResetModelReportsTableFilterAction = ReturnType<
  typeof resetModelReportsTableFilterAction
>;
type SelectModelReportsModelNameFilterAction = ReturnType<
  typeof selectModelReportsModelNameFilterAction
>;
type ResetModelReportsSubMenuNumberFilterAction = ReturnType<
  typeof resetModelReportsSubMenuNumberFilterAction
>;
type SelectModelReportsSubMenuNumberFilterAction = ReturnType<
  typeof selectModelReportsSubMenuNumberFilterAction
>;
export type SearchModelReportsDateRangeSlideAction = ReturnType<
  typeof searchModelReportsDateRangeSlideAction
>;
type ResetModelReportsFavoriteToDefaultAction = ReturnType<
  typeof resetModelReportsFavoriteToDefaultAction
>;
type SearchModelReportsSelectedKiGraphDkSisCondition = ReturnType<
  typeof searchModelReportsSelectedKiGraphDkSisCondition
>;

type SearchModelReportsSelectedKiKasidamaDkSisCondition = ReturnType<
  typeof searchModelReportsSelectedKiKasidamaDkSisCondition
>;

type SelectModelReportsGraphShowNumberLabelAction = ReturnType<
  typeof selectModelReportsGraphShowNumberLabelAction
>;
type SelectModelReportsSelectedHallsAction = ReturnType<
  typeof selectModelReportsSelectedHallsAction
>;
export type ChangeModelReportsCurrentHallsAction = ReturnType<
  typeof changeModelReportsCurrentHallsAction
>;
type ChangeSelectedDataKiExpandedRowAction = ReturnType<
  typeof changeSelectedDataKiExpandedRowAction
>;

type SelectModelReportsMksTransitiveGraphShowGraphNumberLabel = ReturnType<
  typeof selectModelReportsMksTransitiveGraphShowGraphNumberLabel
>;
type SelectModelReportsMksTransitiveGraphSelectedMksHallCodes = ReturnType<
  typeof selectModelReportsMksTransitiveGraphSelectedMksHallCodes
>;
type SelectModelReportsDkSisToggleAction = ReturnType<
  typeof selectModelReportsIsDkSisAction
>;
type ResetModelReportsDerivedScreenAction = ReturnType<
  typeof resetModelReportsDerivedScreenAction
>;
type SelectShowKiTagAction = ReturnType<typeof selectShowKiTagAction>;
type SelectModelReportsIsKiTagAverageGraphAction = ReturnType<
  typeof selectModelReportsIsKiTagAverageGraphAction
>;
type SelectModelReportsIsOnlyOneKiTagAverageGraphAction = ReturnType<
  typeof selectModelReportsIsOnlyOneKiTagAverageGraphAction
>;

export type CreateShortenedUrlAction = ReturnType<
  typeof createShortenedUrlAction
>;

type ModelReportsSettingAction =
  | InitModelReportsAction
  | SearchModelReportsAction
  | SearchResetModelReportsAction
  | SearchModelReportsSortAction
  | SearchModelReportsMarkingAction
  | SearchModelReportsFieldAction
  | SearchModelReportsDkSisOnAction
  | SearchModelReportsDkSisOffAction
  | SearchModelReportsDkSisAction
  | SelectSearchModelReportsDkSisAction
  | SelectSearchModelReportsDkSisIsAutoAction
  | ChangeModelReportsFavoriteAction
  | ChangeModelReportsCurrentShuAction
  | SelectModelReportsSearchConditionAction
  | SelectKiShuAction
  | SelectKiCurrentShuAction
  | SelectKiCurrentHallsAction
  | SelectKiDateRangeParamsAction
  | SelectKiDaiColmunsOrderAction
  | SelectKiKasidamaColmunsOrderAction
  | ResetKiDateRangeParamsAction
  | ResetModelReportsSearchConditionAction
  | ResetKiShuAction
  | SaveModelReportsFavoriteAction
  | SaveAsModelReportsFavoriteAction
  | AddModelReportsTableFilterAction
  | RemoveModelReportsTableFilterAction
  | ApplyModelReportsTableFilterAction
  | ResetModelReportsTableFilterAction
  | SelectModelReportsModelNameFilterAction
  | ResetModelReportsSubMenuNumberFilterAction
  | SelectModelReportsSubMenuNumberFilterAction
  | SearchModelReportsDateRangeSlideAction
  | ResetModelReportsFavoriteToDefaultAction
  | SearchModelReportsSelectedKiGraphDkSisCondition
  | SearchModelReportsSelectedKiKasidamaDkSisCondition
  | SelectModelReportsGraphShowNumberLabelAction
  | SelectModelReportsSelectedHallsAction
  | ChangeModelReportsCurrentHallsAction
  | SelectModelReportsMksTransitiveGraphShowGraphNumberLabel
  | SelectModelReportsMksTransitiveGraphSelectedMksHallCodes
  | ChangeSelectedDataKiExpandedRowAction
  | SelectModelReportsDkSisToggleAction
  | ResetModelReportsDerivedScreenAction
  | SelectShowKiTagAction
  | SelectModelReportsIsKiTagAverageGraphAction
  | SelectModelReportsIsOnlyOneKiTagAverageGraphAction
  | CreateShortenedUrlAction;

/**
 * State
 */

export type ModelReportsSettingState = {
  /**
   * 現在の検索条件
   */
  searchCondition: ModelReportsFormConditions;
  /**
   * 現在検索フォームで選択中の店舗（店舗選択スライダーで選択可能な店舗コード一覧）
   */
  selectedHalls: string[];
  /**
   * 店舗選択スライダーで現在選択中の店舗（テーブルに表示されている店舗の店舗コード）
   */
  currentHalls: string[];
  /**
   * 現在検索フォームで選択中の種別・種別グループ（選択可能な種別・種別グループ一覧）
   */
  selectedShu: ShuOption[];
  /**
   * 現在選択中の種別・種別グループ（テーブルに表示されている種別・種別グループ）
   */
  currentShu?: ShuOption;
  /**
   * 現在選択中の期間指定
   */
  selectedDateRangeParams: KiReportsSettingDateRangeParams;
  /**
   * 選択中のお気に入りID
   */
  selectedFavoriteId?: number;
  /**
   * 台別テーブルのカラムの並び順
   */
  daiColumnsOrder?: string[];
  /**
   * 貸玉テーブルのカラムの並び順
   */
  kasidamaColumnsOrder?: string[];
  /**
   * 機種名フィルタ
   */
  modelNameFilter: string;
  /**
   * サブメニュー内数値フィルタ
   */
  subMenuNumberFilter: {
    [field: string]: {
      minimumNumber: number | undefined;
      maximumNumber: number | undefined;
    };
  };
  /**
   * テーブルの非表示項目一覧
   */
  tableFilterItems: string[];
  /**
   * DK-SIS表示のオン・オフ状態
   */
  isDkSisOn: boolean;
  /**
   * DK-SISの内部保持値
   */
  dkSis: {
    isAuto?: boolean; // DK-SIS自動手動切替スイッチ用途
  };
  /**
   * グラフのDK-SIS条件
   *   - テーブルのDK-SIS条件の ありなし・パチンコ・パチスロ・エリア・ランク をひとまとめにした特殊な値
   *   - graphConditionSelectorUtils.ts の convertSisOptionsToGraphOptions で得られる
   */
  selectedKiGraphDkSisCondition: string;

  /**
   * チェーン店一覧のDK-SIS条件
   *   - テーブルのDK-SIS条件の ありなし・パチンコ・パチスロ・エリア・ランク をひとまとめにした特殊な値
   */
  selectedKiKasidamaDkSisCondition: string;

  /**
   * 推移グラフに数値を表示するかどうかのフラグ
   */
  showGraphNumberLabel: boolean;
  /**
   * 商圏推移グラフ
   */
  mksTransitiveGraph: {
    // グラフに数値を表示するかどうかのフラグ
    showGraphNumberLabel: boolean;
    // 商圏内他社店舗
    selectedMksHallCodes: string[];
  };
  /**
   * SISタイプ・メーカー別検索で、推移グラフや台別データの機種リストを保持する（台別データ）
   */
  selectedDataKiExpandedRow?: {
    mainFieldId: string;
    hallId: string;
    selectedRow: '1stRow' | '2ndRow' | '3rdRow';
  };
  /**
   * 機種行への機種タグ表示機能のフラグ
   */
  showKiTag: boolean;
  /**
   * 機種タグ平均合計行でのグラフ表示フラグ
   */
  isKiTagAverageGraphDisplay: boolean;
  /**
   * 各機種タグ平均合計行でのグラフ表示フラグ
   */
  isOnlyOneKiTagAverageGraphDisplay: boolean;
};

// Stateの初期値
export const initialState: ModelReportsSettingState = {
  searchCondition: generateInitialResponse().setting,
  selectedHalls: [],
  currentHalls: [],
  selectedShu: [],
  currentShu: undefined,
  selectedDateRangeParams: defaultDateRangeParams,
  selectedFavoriteId: undefined,
  daiColumnsOrder: [],
  kasidamaColumnsOrder: [],
  modelNameFilter: '',
  subMenuNumberFilter: {},
  tableFilterItems: [],
  isDkSisOn: false,
  dkSis: {
    isAuto: true,
  },
  selectedKiGraphDkSisCondition: '',
  selectedKiKasidamaDkSisCondition: '',
  showGraphNumberLabel: true,
  mksTransitiveGraph: {
    showGraphNumberLabel: true,
    selectedMksHallCodes: [],
  },
  selectedDataKiExpandedRow: undefined,
  showKiTag: false,
  isKiTagAverageGraphDisplay: false,
  isOnlyOneKiTagAverageGraphDisplay: false,
};

/**
 * Selector
 */

/**
 * 機種集計設定全てを取得する
 * @returns 全設定データ
 */
export function modelReportsSettingSelector(rootState: {
  modelReportsSetting: ModelReportsSettingState;
}) {
  return rootState.modelReportsSetting;
}

/**
 * 機種集計の現在選択されている検索条件を取得する
 * @returns 現在選択されている検索条件
 */
export function modelReportsSearchConditionSelector(rootState: {
  modelReportsSetting: ModelReportsSettingState;
}) {
  return rootState.modelReportsSetting.searchCondition;
}

/**
 * 機種集計の検索フォームで選択中の種別・種別グループを取得する（選択可能な種別・種別グループ一覧）
 * @returns 選択可能な種別一覧
 */
export function modelReportsSelectedShuSelector(rootState: {
  modelReportsSetting: ModelReportsSettingState;
}) {
  return rootState.modelReportsSetting.selectedShu;
}

/**
 * 機種集計で選択中の種別・種別グループを取得する（テーブルに表示されている種別・種別グループ）
 * @returns 現在選択されている種別（undefined時未選択）
 */
export function modelReportsSelectedCurrentShuSelector(rootState: {
  modelReportsSetting: ModelReportsSettingState;
}) {
  return rootState.modelReportsSetting.currentShu;
}

/**
 * 機種集計で選択中の期間指定を取得する
 * @returns 選択中の機期間情報
 */
export function modelReportsSelectedDateRangeParamsSelector(rootState: {
  modelReportsSetting: ModelReportsSettingState;
}) {
  return rootState.modelReportsSetting.selectedDateRangeParams;
}

/**
 * 機種集計の台別テーブルで列の並び順を取得する
 * @returns 機種集計の台別テーブルの列の並び順
 */
export const modelReportsSelectedDaiColumnsOrderSelector = (
  state: RootState
) => {
  return state.modelReportsSetting.daiColumnsOrder;
};

/**
 * 機種集計の貸玉テーブルで列の並び順を取得する
 * @returns 機種集計の貸玉テーブルの列の並び順
 */
export const modelReportsSelectedKasidamaColumnsOrderSelector = (
  state: RootState
) => {
  return state.modelReportsSetting.kasidamaColumnsOrder;
};

/**
 * 機種集計で選択中のお気に入りIDを取得する
 * @returns 現在選択中のお気に入りID（undefined時未選択）
 */
export const modelReportsSelectedFavoriteSelector = (state: RootState) => {
  return state.modelReportsSetting.selectedFavoriteId;
};

/**
 * 機種集計で現在選択中のお気に入りデータを取得する
 * @returns 現在選択中のお気に入りデータ（undefined時未選択）
 */
export const modelReportsSelectedFavoriteDataSelector = createSelector(
  [modelReportsSelectedFavoriteSelector, favoritesSelector],
  (favoriteId, favorites) => {
    if (favoriteId === undefined) return;

    return favorites?.favorites?.find((favorite) => favorite.id === favoriteId);
  }
);

/**
 * 機種集計で選択中のお気に入りデータを取得する
 * @returns 現在選択中のお気に入りデータ（undefined時未選択）
 */
export const modelReportsSelectedFavoriteSettingSelector = createSelector(
  [modelReportsSelectedFavoriteSelector, favoritesSelector],
  (favoriteId, favorites) => {
    if (favoriteId === undefined) return;

    const favoriteItem = favorites?.favorites?.find(
      (favorite) => favorite.id === favoriteId
    );

    return favoriteItem?.pageSetting?.modelReports;
  }
);

/**
 * 機種集計で入力されている機種名フィルタを返す
 */
export const modelReportsModelNameFilterSelector = (rootState: {
  modelReportsSetting: ModelReportsSettingState;
}) => rootState.modelReportsSetting.modelNameFilter;

/**
 * 機種集計で入力されているサブメニュー内の数値フィルタを返す
 */
export const modelReportsSubMenuNumberFilterSelector = (rootState: {
  modelReportsSetting: ModelReportsSettingState;
}) => rootState.modelReportsSetting.subMenuNumberFilter;

/**
 * 非表示項目一覧を取得する
 * @returns 非表示項目一覧
 */
export const modelReportsSelectedTableFilterSelector = (state: RootState) => {
  return state.modelReportsSetting.tableFilterItems;
};

// 推移グラフで数値ラベルを表示するかどうか
export const modelReportsShowNumberLabelSelector = createSelector(
  modelReportsSettingSelector,
  (setting) => setting.showGraphNumberLabel
);

/**
 * DK-SIS 有効/無効 状態の取得
 */
export const modelReportsSettingisDkSisOnSelector = createSelector(
  modelReportsSettingSelector,
  (settings) => settings.isDkSisOn
);

/**
 * 機種タグ平均合計行でのグラフ表示フラグを取得
 */
export const modelReportsIsKiTagAverageGraphDisplaySelector = createSelector(
  modelReportsSettingSelector,
  (setting) => setting.isKiTagAverageGraphDisplay
);

/**
 * 各機種タグ平均合計行でのグラフ表示フラグを取得
 */
export const modelReportsIsOnlyOneKiTagAverageGraphDisplaySelector = createSelector(
  modelReportsSettingSelector,
  (setting) => setting.isOnlyOneKiTagAverageGraphDisplay
);

/**
 * DK-SIS 内部保持値 の取得
 */
export const modelReportsSettingDkSisSearchParamsSelector = createSelector(
  modelReportsSettingSelector,
  (settings) => settings.dkSis
);

/**
 * グラフDK-SIS条件の選択値を取得
 */
export const modelReportsSettingSelectedKiGraphDkSisCondition = createSelector(
  modelReportsSettingSelector,
  (settings) => settings.selectedKiGraphDkSisCondition
);

/**
 * チェーンDK-SIS条件の選択値を取得
 */
export const modelReportsSettingSelectedKiKasidamaDkSisCondition = createSelector(
  modelReportsSettingSelector,
  (settings) => settings.selectedKiKasidamaDkSisCondition
);

/**
 * 店舗選択スライダーで選択可能な店舗一覧を取得
 */
export const modelReportsSettingSelectedHallsSelector = createSelector(
  modelReportsSettingSelector,
  ({ selectedHalls }) => selectedHalls
);

/**
 * 店舗選択スライダーで現在選択中の店舗を取得する（テーブルに表示されている店舗）
 * @returns 現在選択されている店舗
 */
export const modelReportsSettingCurrentHallsSelector = createSelector(
  modelReportsSettingSelector,
  ({ currentHalls }) => currentHalls
);

/**
 * 商圏推移グラフの表示項目を取得
 */
const modelReportsMksTransitiveGraphSelector = createSelector(
  modelReportsSettingSelector,
  ({ mksTransitiveGraph }) => mksTransitiveGraph
);

/**
 * 商圏推移グラフで選択された商圏内他社店舗のコード一覧
 */
export const modelReportsMksTransitiveGraphSelectedMksHallCodesSelector = createSelector(
  modelReportsMksTransitiveGraphSelector,
  ({ selectedMksHallCodes }) => selectedMksHallCodes
);

/**
 * 商圏推移グラフで数値ラベルを表示するかどうかのフラグ
 */
export const modelReportsMksTransitiveGraphShowGraphNumberLabelSelector = createSelector(
  modelReportsMksTransitiveGraphSelector,
  ({ showGraphNumberLabel }) => showGraphNumberLabel
);

/**
 * グラフの機種セレクタリストを取得
 * @param state
 * @returns
 */
export function selectedDataKiExpandedRowSelector(rootState: RootState) {
  return rootState.modelReportsSetting.selectedDataKiExpandedRow ?? undefined;
}

// 機種行への機種タグ表示機能を表示するかどうか
export const modelReportsShowKiTagSelector = createSelector(
  modelReportsSettingSelector,
  (setting) => setting.showKiTag
);

/**
 * Reducer
 */

export function modelReportsSettingReducer(
  state = initialState,
  action: ModelReportsSettingAction
): ModelReportsSettingState {
  switch (action.type) {
    case SELECT_MODEL_REPORTS_SEARCH_CONDITION:
      return {
        ...state,
        searchCondition: action.payload.params,
      };
    case RESET_MODEL_REPORTS_SEARCH_CONDITION:
      return {
        ...state,
        searchCondition: initialState.searchCondition,
      };
    case SELECT_MODEL_REPORTS_SHU:
      return {
        ...state,
        selectedShu: action.payload.shus,
      };
    case SELECT_MODEL_REPORTS_CURRENT_SHU:
    case CHANGE_MODEL_REPORTS_CURRENT_SHU:
      return {
        ...state,
        currentShu: action.payload.shu,
      };
    case RESET_MODEL_REPORTS_SHU:
      return {
        ...state,
        selectedShu: [],
      };
    case SELECT_MODEL_REPORTS_DATE_RANGE_PARAMS: {
      return {
        ...state,
        selectedDateRangeParams: action.payload.params,
      };
    }
    case SELECT_MODEL_REPORTS_DAI_COLUMNS_ORDER:
      return {
        ...state,
        daiColumnsOrder: action.payload.daiColumnsOrder,
      };
    case SELECT_MODEL_REPORTS_KASIDAMA_COLUMNS_ORDER:
      return {
        ...state,
        kasidamaColumnsOrder: action.payload.kasidamaColumnsOrder,
      };
    case SELECT_MODEL_REPORTS_MODEL_NAME_FILTER:
      return {
        ...state,
        modelNameFilter: action.payload.filterString,
      };
    case SELECT_MODEL_REPORTS_SUB_MENU_NUMBER_FILTER:
      return {
        ...state,
        subMenuNumberFilter: {
          ...state.subMenuNumberFilter,
          ...action.payload.filterNumbers,
        },
      };
    case RESET_MODEL_REPORTS_SUB_MENU_NUMBER_FILTER:
      return {
        ...state,
        subMenuNumberFilter: {},
      };

    case RESET_MODEL_REPORTS_DATE_RANGE_PARAMS:
      return {
        ...state,
        selectedDateRangeParams: initialState.selectedDateRangeParams,
      };
    case CHANGE_MODEL_REPORTS_FAVORITE:
      return {
        ...state,
        selectedFavoriteId: action.payload.favorite,
      };
    case ADD_MODEL_REPORTS_TABLE_FILTER:
      return {
        ...state,
        tableFilterItems: [
          ...state.tableFilterItems,
          action.payload.columnCode,
        ],
      };
    case REMOVE_MODEL_REPORTS_TABLE_FILTER:
      return {
        ...state,
        tableFilterItems: state.tableFilterItems.filter(
          (columnCode) => columnCode !== action.payload.columnCode
        ),
      };
    case APPLY_MODEL_REPORTS_TABLE_FILTER:
      return {
        ...state,
        tableFilterItems: action.payload.tableFilterItems,
      };
    case RESET_MODEL_REPORTS_TABLE_FILTER:
      return {
        ...state,
        tableFilterItems: [],
      };
    case RESET_MODEL_REPORTS_FAVORITE_TO_DEFAULT:
      return {
        ...state,
        selectedFavoriteId: undefined,
      };
    case SELECT_MODEL_REPORTS_IS_DK_SIS:
      return {
        ...state,
        isDkSisOn: action.payload.isDkSisOn,
      };
    case SELECT_MODEL_REPORTS_IS_KI_TAG_AVERAGE_GRAPH:
      return {
        ...state,
        isKiTagAverageGraphDisplay: action.payload.isKiTagAverageGraphDisplay,
      };
    case SELECT_MODEL_REPORTS_IS_ONLY_ONE_KI_TAG_AVERAGE_GRAPH:
      return {
        ...state,
        isOnlyOneKiTagAverageGraphDisplay:
          action.payload.isOnlyOneKiTagAverageGraphDisplay,
      };
    case SEARCH_MODEL_REPORTS_DKSIS_ON:
      return {
        ...state,
        isDkSisOn: true,
      };
    case SEARCH_MODEL_REPORTS_DKSIS_OFF:
      return {
        ...state,
        isDkSisOn: false,
        dkSis: {
          ...state.dkSis,
          isAuto: true,
        },
      };
    case SEARCH_MODEL_REPORTS_DKSIS:
      return {
        ...state,
        isDkSisOn: true,
      };
    case SELECT_MODEL_REPORTS_DKSIS:
      return {
        ...state,
        dkSis: {
          ...state.dkSis,
          ...action.payload.params,
        },
      };
    case SELECT_MODEL_REPORTS_DKSIS_IS_AUTO:
      return {
        ...state,
        dkSis: {
          ...state.dkSis,
          isAuto: action.payload.isAuto,
        },
      };
    case SEARCH_MODEL_REPORTS_SELECTED_KI_GRAPH_DK_DIS_CONDITION:
      return {
        ...state,
        selectedKiGraphDkSisCondition: action.payload,
      };
    case SEARCH_MODEL_REPORTS_SELECTED_KI_KASIDAMA_DK_DIS_CONDITION:
      return {
        ...state,
        selectedKiKasidamaDkSisCondition: action.payload,
      };
    case SELECT_MODEL_REPORTS_GRAPH_SHOW_GRAPH_NUMBER_LABEL: {
      return {
        ...state,
        showGraphNumberLabel: action.payload.flag,
      };
    }
    case SELECT_MODEL_REPORTS_SELECTED_HALLS: {
      return {
        ...state,
        selectedHalls: action.payload.halls,
      };
    }
    case SELECT_MODEL_REPORTS_CURRENT_HALLS:
    case CHANGE_MODEL_REPORTS_CURRENT_HALLS:
      return {
        ...state,
        currentHalls: action.payload.halls,
      };
    case SELECT_MODEL_REPORTS_MKS_TRANSITIVE_GRAPH_SELECTED_MKS_HALL_CODES: {
      return {
        ...state,
        mksTransitiveGraph: {
          ...state.mksTransitiveGraph,
          selectedMksHallCodes: action.payload.hallCodes,
        },
      };
    }
    case CHANGE_SINGLE_DATA_KI_EXPANDED_ROW:
      return {
        ...state,
        selectedDataKiExpandedRow: {
          hallId: action.payload.hallId,
          mainFieldId: action.payload.mainFieldId,
          selectedRow: action.payload.selectedRow,
        },
      };
    case SELECT_MODEL_REPORTS_MKS_TRANSITIVE_GRAPH_SHOW_GRAPH_NUMBER_LABEL: {
      return {
        ...state,
        mksTransitiveGraph: {
          ...state.mksTransitiveGraph,
          showGraphNumberLabel: action.payload,
        },
      };
    }
    case SELECT_KI_TAG_LABEL: {
      return {
        ...state,
        showKiTag: action.payload.flag,
      };
    }
    default:
      return state;
  }
}
