import { AxiosResponse } from 'axios';
import { call, fork, put, select, take, takeEvery } from 'redux-saga/effects';

import {
  DataPpmShare,
  DataPpmShareData,
  DataPpmShareParams,
  SettingsOptionsPpmShare,
} from '../domain/ppmShare/types';
import { Column, LoadingState } from '../domain/schemas';
import { ShuOption } from '../domain/shu';

import {
  ChangeDataPpmShareColumnsOrderAction,
  DataPpmShareActionCreators,
  DataPpmShareActionTypes,
  FetchDataPpmShareAction,
  SearchDataPpmShareFieldAction,
  dataPpmShareColumnsOrderSelector,
  dataPpmShareDataColumnsSelector,
  dataPpmShareOrderedDataSelector,
  dataPpmShareParamsSelector,
} from '../redux/server/dataPpmShare';
import {
  SettingsOptionsPpmShareActionTypes,
  settingsOptionsPpmShareLoadingStateSelector,
  settingsOptionsPpmShareSelector,
} from '../redux/server/settingsOptionsPpmShare';
import {
  changePpmShareReportsCurrentShuAction,
  ppmShareReportsSelectedCurrentShuSelector,
  ppmShareReportsSelectedShuSelector,
  selectPpmShareReportsShuAction,
} from '../redux/ui/ppmShareReportsSetting';
import { Api, buildConfig } from '../utils/api';
import { recalcColumnOrder } from '../utils/orderedCell';
import {
  convertShuOption,
  existShuOption,
  selectShu2SearchCondition,
} from '../utils/shu';
import { handleErrorSaga } from './errorSaga';

// dataPpmShareのデータを取得する
export function* fetchDataPpmShareSaga(
  api: Api,
  action: FetchDataPpmShareAction
) {
  try {
    yield put(DataPpmShareActionCreators.fetchDataPpmShareRequestAction());
    const response: AxiosResponse<DataPpmShare> = yield call(
      api.get,
      '/data/ppmShare',
      buildConfig({
        ...action.payload.params, // 変更後の検索条件
        ...selectShu2SearchCondition(action.payload.currentShu), // 変更後の種別・種別グループ
      })
    );
    const dataPpmShare = response.data;

    yield put(
      DataPpmShareActionCreators.fetchDataPpmShareSuccessAction(dataPpmShare)
    );
  } catch (error: unknown) {
    yield put(DataPpmShareActionCreators.renewDataPpmShareAction());
    yield fork(handleErrorSaga, error);
  }
}

// TODO 並べ替え処理を固定列を考慮したものにする
/**
 * 表示項目が変更されている場合並び替えに反映する
 */
function* columnsOrderCheckSaga() {
  const fields: Column[] = yield select(dataPpmShareDataColumnsSelector);

  const fieldCodes = fields.map((field) => field.code);

  // テーブル列の並び順
  const nowColumnsOrder: string[] | undefined = yield select(
    dataPpmShareColumnsOrderSelector
  );

  // 並び替え未設定時はデフォルトを使用
  const columnsOrder =
    !nowColumnsOrder || nowColumnsOrder.length === 0
      ? fieldCodes
      : nowColumnsOrder;

  const sorted = [...fieldCodes].sort((a, b) => {
    // 機種名は常に先頭
    if (b === fieldCodes[0]) {
      return 1;
    }

    return columnsOrder.indexOf(b) > columnsOrder.indexOf(a) ? -1 : 1;
  });

  yield put(
    DataPpmShareActionCreators.selectDataPpmShareColumnsOrderAction(sorted)
  );
}

/**
 * ドラッグ＆ドロップしたセルのIDを元に並び替え情報を登録する
 */
function* changeDataPpmShareColumnsOrderSaga(
  action: ChangeDataPpmShareColumnsOrderAction
) {
  const tableData: DataPpmShareData = yield select(
    dataPpmShareOrderedDataSelector
  );

  // 先頭から4項目(店舗名、種別、日数、台数)と、
  // カラムが入れ子になっている列は固定なので、フィルタする
  // それ以外の列を並べ替える
  const columnsToChangeOrder = tableData.columns.filter(
    (x, index) => index > 4 && !x.columns
  ) as Column[]; // PpmShareColumnはフィルタ済みなので型アサーションする

  const ordered = recalcColumnOrder(
    columnsToChangeOrder,
    action.payload.draggedId,
    action.payload.droppedId
  );

  yield put(
    DataPpmShareActionCreators.selectDataPpmShareColumnsOrderAction(ordered)
  );
}

/**
 * 指定した表示項目のPPMシェアテーブルデータを取得する
 * @param action Action
 */
function* searchDataPpmShareFieldTypeSaga(
  action: SearchDataPpmShareFieldAction
) {
  // 現在の検索条件
  const searchCondition: DataPpmShareParams = yield select(
    dataPpmShareParamsSelector
  );

  // PPMシェアテーブルを再取得
  yield put(
    DataPpmShareActionCreators.fetchDataPpmShareAction(
      {
        ...searchCondition,
        fields: action.payload.fields.map((field) => field.code),
      },
      action.payload.shuOption
    )
  );
}

/**
 * リクエストした種別のデータとレスポンスの種別データが不一致だったときに、レスポンスの値で上書きする
 *
 * 種別の不一致は種別が削除されたとき、あるいは共有グループをまたいだお気に入りで発生する
 */
function* validateShuSaga() {
  const currentShu: ShuOption = yield select(
    ppmShareReportsSelectedCurrentShuSelector
  );
  // currentShuが存在しない場合には初期リクエストになるため何もしない
  if (currentShu == null) {
    return;
  }

  const setting: DataPpmShareParams = yield select(dataPpmShareParamsSelector);
  const exist = existShuOption(setting, currentShu);
  if (exist) {
    return;
  }

  const settingsOptionsLoadingState: LoadingState = yield select(
    settingsOptionsPpmShareLoadingStateSelector
  );
  if (settingsOptionsLoadingState !== 'loaded') {
    yield take(
      SettingsOptionsPpmShareActionTypes.FETCH_SETTINGS_OPTIONS_PPM_SHARE_SUCCESS
    );
  }
  // FETCH_SETTINGS_OPTIONS_KI_SUCCESSを待つのでundefinedにならない
  const settingsOptions: SettingsOptionsPpmShare = yield select(
    settingsOptionsPpmShareSelector
  );

  const shuOption = convertShuOption(
    setting,
    settingsOptions.searchCondition.shuGroupList
  );
  if (shuOption == null) {
    throw new Error('適切な種別がsettingsOptionsで見つかりませんでした');
  }

  yield put(changePpmShareReportsCurrentShuAction(shuOption));

  const selectedShu: ShuOption[] = yield select(
    ppmShareReportsSelectedShuSelector
  );

  // selectedShuの変更
  if (selectedShu.length > 0) {
    const validatedSelectedShu = [
      shuOption,
      ...selectedShu.filter((items) => items.code !== currentShu.code),
    ];
    yield put(selectPpmShareReportsShuAction(validatedSelectedShu));
  }
}

function* handleFetchDataPpmShareSaga(api: Api) {
  yield takeEvery(
    DataPpmShareActionTypes.FETCH_DATA_PPM_SHARE,
    fetchDataPpmShareSaga,
    api
  );
  // 表示項目と並び替えのチェック
  yield takeEvery(
    DataPpmShareActionTypes.FETCH_DATA_PPM_SHARE_SUCCESS,
    columnsOrderCheckSaga
  );

  yield takeEvery(
    DataPpmShareActionTypes.FETCH_DATA_PPM_SHARE_SUCCESS,
    validateShuSaga
  );
}

function* handleChangeColumnsOrderSaga() {
  // 並び替え時
  yield takeEvery(
    DataPpmShareActionTypes.CHANGE_DATA_PPM_SHARE_COLUMNS_ORDER,
    changeDataPpmShareColumnsOrderSaga
  );
}

/**
 * 表示項目が変更されたとき、データを再取得する
 */
function* handleSearchDataPpmShareFieldTypeSaga() {
  yield takeEvery(
    DataPpmShareActionTypes.SEARCH_DATA_PPM_SHARE_FIELD_TYPE,
    searchDataPpmShareFieldTypeSaga
  );
}

export function* dataPpmShareSagas(context: { api: Api }) {
  yield fork(handleFetchDataPpmShareSaga, context.api);
  yield fork(handleChangeColumnsOrderSaga);
  yield fork(handleSearchDataPpmShareFieldTypeSaga);
}
