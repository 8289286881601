/**
 * Action Types
 *
 * プレフィックスの意味
 * INIT: 初回のデータを取得する（最初に画面を開いたときだけ取得）
 * SEARCH: ReducerでStateの変更はしないがデータを取得する（検索ボタン等Sagaを発火させたい）
 * CHANGE: Stateを変更してデータも取得する
 * SELECT: Stateを変更する（データ取得は行わない）
 * RESET: Stateを初期状態に戻す（データ取得は行わない）
 * SAVE: 上書き保存する（お気に入り用）
 * SAVE_AS: 新規保存する（お気に入り用）
 */
import { format, subMonths } from 'date-fns';
import { createSelector } from 'reselect';

import { OrderType } from '../../domain/schemas';

const SELECT_KITAGS_KILIST = 'SELECT_KITAGS_KILIST' as const;
const SELECT_KITAGS_MODEL_NAME_FILTER = 'SELECT_KITAGS_MODEL_NAME_FILTER' as const;
const SELECT_KITAGS_PG_NAME_FILTER = 'SELECT_KITAGS_PG_NAME_FILTER' as const;
const SELECT_KITAGS_TYPE_NAME_FILTER = 'SELECT_KITAGS_TYPE_NAME_FILTER' as const;
const SELECT_KITAGS_MAKER_NAME_FILTER = 'SELECT_KITAGS_MAKER_NAME_FILTER' as const;
const SELECT_KITAGS_TAG_NAME_FILTER = 'SELECT_KITAGS_TAG_NAME_FILTER' as const;
const SELECT_KITAGS_CHECKED_KI = 'SELECT_KITAGS_CHECKED_KI' as const;
const SELECT_KITAGS_SORT = 'SELECT_KITAGS_SORT' as const;
const SELECT_KITAGS_DATE_RANGE = 'SELECT_KITAGS_DATE_RANGE' as const;

/**
 * 絞り込む機種リストを変更
 *
 * @param kiList 絞り込む機種リスト
 */
function selectKiTagsKiListAction(kiList: string[]) {
  return {
    type: SELECT_KITAGS_KILIST,
    payload: { kiList },
  };
}

/**
 * 機種名フィルタを変更
 * @param filterString 検索する文字列
 */
export function selectKiTagsModelNameFilterAction(filterString: string) {
  return {
    type: SELECT_KITAGS_MODEL_NAME_FILTER,
    payload: { filterString },
  };
}

/**
 * pgフィルタを変更
 * @param filterString 検索する文字列
 */
function selectKiTagsPgNameFilterAction(filterString: string) {
  return {
    type: SELECT_KITAGS_PG_NAME_FILTER,
    payload: { filterString },
  };
}

/**
 * タイプフィルタを変更
 * @param filter 検索する文字列
 */
function selectKiTagsTypeNameFilterAction(filter: string[]) {
  return {
    type: SELECT_KITAGS_TYPE_NAME_FILTER,
    payload: { filter },
  };
}

/**
 * メーカーフィルタを変更
 * @param filterString 検索する文字列
 */
function selectKiTagsMakerNameFilterAction(filterString: string) {
  return {
    type: SELECT_KITAGS_MAKER_NAME_FILTER,
    payload: { filterString },
  };
}

/**
 * タグフィルタを変更
 * @param filterNumberArray 検索する数字の配列
 */
function selectKiTagsTagNameFilterAction(filterNumberArray: number[]) {
  return {
    type: SELECT_KITAGS_TAG_NAME_FILTER,
    payload: { filterNumberArray },
  };
}

/**
 * テーブルでチェックされた機種を変更
 */
export function selectKiTagsCheckedKiAction(checkedKiList: string[]) {
  return {
    type: SELECT_KITAGS_CHECKED_KI,
    payload: checkedKiList,
  };
}

/**
 * テーブルでソートされたカラムを変更
 */
function selectKiTagsSortAction(sort: string, order: OrderType) {
  return {
    type: SELECT_KITAGS_SORT,
    payload: { sort, order },
  };
}

/**
 * 検索期間を変更
 */
function selectKiTagsDateRangeAction(startDate: string, endDate: string) {
  return {
    type: SELECT_KITAGS_DATE_RANGE,
    payload: {
      startDate,
      endDate,
    },
  };
}

export const SettingsKiTagsActionCreators = {
  selectKiTagsKiListAction,
  selectKiTagsModelNameFilterAction,
  selectKiTagsPgNameFilterAction,
  selectKiTagsTypeNameFilterAction,
  selectKiTagsMakerNameFilterAction,
  selectKiTagsTagNameFilterAction,
  selectKiTagsCheckedKiAction,
  selectKiTagsSortAction,
  selectKiTagsDateRangeAction,
};

/**
 * Actions
 */
type SelectKiTagsKiListAction = ReturnType<typeof selectKiTagsKiListAction>;
type SelectKiTagsModelNameFilterAction = ReturnType<
  typeof selectKiTagsModelNameFilterAction
>;
type SelectKiTagsPgNameFilterAction = ReturnType<
  typeof selectKiTagsPgNameFilterAction
>;
type SelectKiTagsTypeNameFilterAction = ReturnType<
  typeof selectKiTagsTypeNameFilterAction
>;
type SelectKiTagsMakerNameFilterAction = ReturnType<
  typeof selectKiTagsMakerNameFilterAction
>;
type SelectKiTagsTagNameFilterAction = ReturnType<
  typeof selectKiTagsTagNameFilterAction
>;
type SelectKiTagsCheckedKiAction = ReturnType<
  typeof selectKiTagsCheckedKiAction
>;
type SelectKiTagsSortAction = ReturnType<typeof selectKiTagsSortAction>;
type SelectKiTagsDateRangeAction = ReturnType<
  typeof selectKiTagsDateRangeAction
>;

type SettingsKiTagsAction =
  | SelectKiTagsKiListAction
  | SelectKiTagsModelNameFilterAction
  | SelectKiTagsPgNameFilterAction
  | SelectKiTagsTypeNameFilterAction
  | SelectKiTagsMakerNameFilterAction
  | SelectKiTagsTagNameFilterAction
  | SelectKiTagsCheckedKiAction
  | SelectKiTagsSortAction
  | SelectKiTagsDateRangeAction;

/**
 * State
 */

type SettingsKiTagsState = {
  /**
   * 機種一覧
   */
  kiList?: string[];
  /**
   * 機種名フィルタ
   */
  modelNameFilter: string;
  /**
   * PGフィルタ
   */
  pgNameFilter: string;
  /**
   * タイプフィルタ
   */
  typeNameFilter: string[];
  /**
   * メーカーフィルタ
   */
  makerNameFilter: string;
  /**
   * タグフィルタ(tagId)
   */
  tagNameFilter: number[];
  /**
   * テーブルでチェックされた機種
   */
  checkedKiList: string[];
  /**
   * ソートのカラム
   */
  sort: string;
  /**
   * ソート順
   */
  order: OrderType;
  /**
   * 検索期間の開始日
   */
  startDate: string;
  /**
   * 検索期間の終了日
   */
  endDate: string;
};

// Stateの初期値
const initialState: SettingsKiTagsState = {
  kiList: [],
  modelNameFilter: '',
  pgNameFilter: '',
  typeNameFilter: [],
  makerNameFilter: '',
  tagNameFilter: [],
  checkedKiList: [],
  sort: 'hatubaiDay',
  order: 'desc',
  startDate: format(subMonths(new Date(), 36), 'yyyy-MM-dd'),
  endDate: format(new Date(), 'yyyy-MM-dd'),
};

/**
 * Selector
 */

function kiTagsSelector(rootState: { settingsKiTags: SettingsKiTagsState }) {
  return rootState.settingsKiTags;
}

/**
 *選択中の機種を返す
 * @returns 現在選択されている機種
 */
export function kiTagsSelectedKiSelector(rootState: {
  settingsKiTags: SettingsKiTagsState;
}) {
  return rootState.settingsKiTags.kiList;
}

/**
 * 入力されている機種名フィルタを返す
 */
export const kiTagsModelNameFilterSelector = (rootState: {
  settingsKiTags: SettingsKiTagsState;
}) => rootState.settingsKiTags.modelNameFilter;

/**
 * 入力されているPGフィルタを返す
 */
export const kiTagsPgNameFilterSelector = (rootState: {
  settingsKiTags: SettingsKiTagsState;
}) => rootState.settingsKiTags.pgNameFilter;

/**
 * 入力されているタイプフィルタを返す
 */
export const kiTagsTypeNameFilterSelector = (rootState: {
  settingsKiTags: SettingsKiTagsState;
}) => rootState.settingsKiTags.typeNameFilter;

/**
 * 入力されているメーカーフィルタを返す
 */
export const kiTagsMakerNameFilterSelector = (rootState: {
  settingsKiTags: SettingsKiTagsState;
}) => rootState.settingsKiTags.makerNameFilter;

/**
 * 入力されているタグフィルタを返す
 */
export const kiTagsTagNameFilterSelector = (rootState: {
  settingsKiTags: SettingsKiTagsState;
}) => rootState.settingsKiTags.tagNameFilter;

/**
 * テーブルでチェックされた機種を取得する
 */
export const kiTagsCheckedKiListSelector = (rootState: {
  settingsKiTags: SettingsKiTagsState;
}) => {
  return rootState.settingsKiTags.checkedKiList;
};

/**
 * 現在のソートのカラムを取得する
 */
export const kiTagsSortSelector = (rootState: {
  settingsKiTags: SettingsKiTagsState;
}) => {
  return rootState.settingsKiTags.sort;
};

/**
 * 現在のソート順を取得する
 */
export const kiTagsOrderSelector = (rootState: {
  settingsKiTags: SettingsKiTagsState;
}) => {
  return rootState.settingsKiTags.order;
};

/**
 * 検索期間の取得
 */
export const kiTagsDateRangeSelector = createSelector(
  kiTagsSelector,
  ({ startDate, endDate }) => ({
    startDate,
    endDate,
  })
);

/**
 * Reducer
 */

export function SettingsKiTagsReducer(
  state = initialState,
  action: SettingsKiTagsAction
): SettingsKiTagsState {
  switch (action.type) {
    case SELECT_KITAGS_KILIST:
      return {
        ...state,
        kiList: action.payload.kiList,
      };
    case SELECT_KITAGS_MODEL_NAME_FILTER:
      return {
        ...state,
        modelNameFilter: action.payload.filterString,
      };
    case SELECT_KITAGS_PG_NAME_FILTER:
      return {
        ...state,
        pgNameFilter: action.payload.filterString,
      };
    case SELECT_KITAGS_TYPE_NAME_FILTER:
      return {
        ...state,
        typeNameFilter: action.payload.filter,
      };
    case SELECT_KITAGS_MAKER_NAME_FILTER:
      return {
        ...state,
        makerNameFilter: action.payload.filterString,
      };
    case SELECT_KITAGS_TAG_NAME_FILTER:
      return {
        ...state,
        tagNameFilter: action.payload.filterNumberArray,
      };
    case SELECT_KITAGS_CHECKED_KI:
      return {
        ...state,
        checkedKiList: action.payload,
      };
    case SELECT_KITAGS_SORT:
      return {
        ...state,
        sort: action.payload.sort,
        order: action.payload.order,
      };
    case SELECT_KITAGS_DATE_RANGE: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
}
