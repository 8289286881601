import { AxiosResponse } from 'axios';
import { call, fork, put, select, take, takeEvery } from 'redux-saga/effects';

import {
  DataModelTransition,
  DataModelTransitionParams,
} from '../domain/dataModelTransition';
import { LoadingState } from '../domain/schemas';
import { SettingsOptionsModelTransition } from '../domain/settingsOptionsModelTransition';
import { ShuOption } from '../domain/shu';

import {
  DataModelTransitionActionCreators,
  DataModelTransitionActionTypes,
  FetchDataModelTransitionAction,
  dataModelTransitionSettingsSelector,
} from '../redux/server/dataModelTransition';
import {
  SettingsOptionsModelTransitionActionTypes,
  settingsOptionsModelTransitionLoadingStateSelector,
  settingsOptionsModelTransitionSelector,
} from '../redux/server/settingsOptionsModelTransition';
import {
  modelTransitionReportsSelectedCurrentShuSelector,
  modelTransitionReportsSelectedShuSelector,
  selectModelTransitionReportsCurrentShuAction,
  selectModelTransitionReportsShuAction,
} from '../redux/ui/modelTransitionReportsSetting';
import { Api, buildConfig } from '../utils/api';
import {
  convertShuOption,
  existShuOption,
  getShuOption,
  selectShus2HallReportSearchCondition,
} from '../utils/shu';
import { handleErrorSaga } from './errorSaga';
import { changeColumnsOrderSaga } from './modelTransitionReportsSagas';

export function* fetchDataModelTransitionSaga(
  api: Api,
  action: FetchDataModelTransitionAction
) {
  try {
    yield put(
      DataModelTransitionActionCreators.fetchDataModelTransitionRequestAction()
    );

    const params: DataModelTransitionParams = {
      ...action.payload.params,
      ...selectShus2HallReportSearchCondition([action.payload.shuOption]),
    };

    // 非推移項目が何も選択されなかった場合、機種名を明示的に追加する
    // これがなければデフォルトの非推移項目が表示されてしまうため
    if (params.nonTransitiveFields && params.nonTransitiveFields.length === 0) {
      params.nonTransitiveFields = ['kiTushoMei'];
    }

    const response: AxiosResponse<DataModelTransition> = yield call(
      api.get,
      '/data/kiTransition',
      buildConfig(params)
    );

    yield put(
      DataModelTransitionActionCreators.fetchDataModelTransitionSuccessAction(
        response.data
      )
    );

    yield fork(changeColumnsOrderSaga);
  } catch (error: unknown) {
    yield put(
      DataModelTransitionActionCreators.renewDataModelTransitionAction()
    );
    yield fork(handleErrorSaga, error);
  }
}

/**
 * リクエストした種別のデータとレスポンスの種別データが不一致だったときに、レスポンスの値で上書きする
 *
 * 種別の不一致は種別が削除されたとき、あるいは共有グループをまたいだお気に入りで発生する
 */
function* validateShuSaga() {
  const currentShu: ShuOption = yield select(
    modelTransitionReportsSelectedCurrentShuSelector
  );
  // currentShuが存在しない場合には初期リクエストになるため何もしない
  if (currentShu == null) {
    return;
  }

  const setting: DataModelTransitionParams = yield select(
    dataModelTransitionSettingsSelector
  );
  const exist = existShuOption(setting, currentShu);
  if (exist) {
    return;
  }

  const settingsOptionsLoadingState: LoadingState = yield select(
    settingsOptionsModelTransitionLoadingStateSelector
  );
  if (settingsOptionsLoadingState !== 'loaded') {
    yield take(
      SettingsOptionsModelTransitionActionTypes.FETCH_SETTINGS_OPTIONS_MODEL_TRANSITION_SUCCESS
    );
  }
  // FETCH_SETTINGS_OPTIONS_KI_SUCCESSを待つのでundefinedにならない
  const settingsOptions: SettingsOptionsModelTransition = yield select(
    settingsOptionsModelTransitionSelector
  );

  const shuOption =
    convertShuOption(setting, settingsOptions.searchCondition.shuGroupList) ??
    // 画面リンクを利用して存在しない場合は最初の値を選択する
    getShuOption(settingsOptions.searchCondition).at(0);

  if (shuOption == null) {
    throw new Error('適切な種別グループまたは種別が見つかりませんでした');
  }

  yield put(selectModelTransitionReportsCurrentShuAction(shuOption));

  const selectedShu: ShuOption[] = yield select(
    modelTransitionReportsSelectedShuSelector
  );

  // selectedShuの変更
  if (selectedShu.length > 0) {
    const validatedSelectedShu = [
      shuOption,
      ...selectedShu.filter((items) => items.code !== currentShu.code),
    ];
    // TODO: 変換後の重複の削除をする
    yield put(selectModelTransitionReportsShuAction(validatedSelectedShu));
  }
}

function* handleFetchDataModelTransitionSaga(api: Api) {
  yield takeEvery(
    DataModelTransitionActionTypes.FETCH_DATA_MODEL_TRANSITION,
    fetchDataModelTransitionSaga,
    api
  );

  yield takeEvery(
    DataModelTransitionActionTypes.FETCH_DATA_MODEL_TRANSITION_SUCCESS,
    validateShuSaga
  );
}

export function* dataModelTransitionSagas(context: { api: Api }) {
  yield fork(handleFetchDataModelTransitionSaga, context.api);
}
