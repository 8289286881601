import { createSelector } from 'reselect';

import {
  ModeDataKiList,
  ModeKiListSearchParams,
} from '../../domain/mode/types';
import { LoadingState } from '../../domain/schemas';

import { RootState } from '../../store';

/* ---------------------------------------------------------------
 * Action Types
 */

const FETCH_DATA_MODE_KILIST = 'FETCH_DATA_MODE_KILIST' as const;
const FETCH_DATA_MODE_KILIST_REQUEST = 'FETCH_DATA_MODE_KILIST_REQUEST' as const;
const FETCH_DATA_MODE_KILIST_SUCCESS = 'FETCH_DATA_MODE_KILIST_SUCCESS' as const;
const RENEW_DATA_MODE_KILIST = 'RENEW_DATA_MODE_KILIST' as const;

export const DataModeKiListActionTypes = {
  FETCH_DATA_MODE_KILIST,
  FETCH_DATA_MODE_KILIST_REQUEST,
  FETCH_DATA_MODE_KILIST_SUCCESS,
  RENEW_DATA_MODE_KILIST,
};

/* ---------------------------------------------------------------
 * Action Creators
 */

function fetchDataModeKiListAction(params: ModeKiListSearchParams | undefined) {
  return {
    type: FETCH_DATA_MODE_KILIST,
    payload: { params },
  };
}

function fetchDataModeKiListRequestAction() {
  return {
    type: FETCH_DATA_MODE_KILIST_REQUEST,
  };
}

function fetchDataModeKiListSuccessAction(dataModeKiList: ModeDataKiList) {
  return {
    type: FETCH_DATA_MODE_KILIST_SUCCESS,
    payload: { dataModeKiList },
  };
}

function renewDataModeKiListAction() {
  return {
    type: RENEW_DATA_MODE_KILIST,
  };
}

export const DataModeKiListActionCreators = {
  fetchDataModeKiListAction,
  fetchDataModeKiListRequestAction,
  fetchDataModeKiListSuccessAction,
  renewDataModeKiListAction,
};

/* ---------------------------------------------------------------
 * Actions
 */

export type FetchDataModeKiListAction = ReturnType<
  typeof fetchDataModeKiListAction
>;

type DataModeKiListAction =
  | FetchDataModeKiListAction
  | ReturnType<typeof fetchDataModeKiListRequestAction>
  | ReturnType<typeof fetchDataModeKiListSuccessAction>
  | ReturnType<typeof renewDataModeKiListAction>;

/* ---------------------------------------------------------------
 * State
 */

type DataModeKiListState = {
  loadingState: LoadingState;
  dataModeKiList: ModeDataKiList | undefined;
};

const initialState: DataModeKiListState = {
  loadingState: 'prepare',
  dataModeKiList: undefined,
};

const dataModeKiListAllDataSelector = (state: RootState) => {
  return state.dataModeKiList;
};

export const dataModeKiListDataSelector = createSelector(
  dataModeKiListAllDataSelector,
  (data) => data.dataModeKiList
);

export const dataModeKiListLoadingSelector = createSelector(
  dataModeKiListAllDataSelector,
  (data) => data.loadingState
);

/* ---------------------------------------------------------------
 * Reducer
 */

export function dataModeKiListReducer(
  state = initialState,
  action: DataModeKiListAction
): DataModeKiListState {
  switch (action.type) {
    case FETCH_DATA_MODE_KILIST_REQUEST:
      return {
        ...state,
        loadingState: 'loading',
      };
    case FETCH_DATA_MODE_KILIST_SUCCESS:
      return {
        ...state,
        loadingState: 'loaded',
        dataModeKiList: action.payload.dataModeKiList,
      };
    case RENEW_DATA_MODE_KILIST:
      return initialState;
    default:
      return state;
  }
}
