import { ShuGroupList } from '../schemas';
import { ShuOption } from '../shu';

/**
 * 選択されている種別グループや種別がsettingsOptionsに含まれる場合、該当する選択されていた種別グループや種別を返却
 * 種別ごとに複数のテーブルが存在しているパターンで、選択されている種別グループが削除されていた場合にsettingsOptionsに含まれる種別グループを返却するため
 */
export const filteredShuOptions = (
  selectedShu: ShuOption[],
  settingsOptions: {
    shuGroupList: ShuGroupList[];
    shuList: string[];
  }
): ShuOption[] => {
  const filtered = selectedShu.filter((shuOption) => {
    // 種別(P_4.00みたいなもの)指定時については考慮しないため、フィルターせずそのままshuListを返却
    // https://www.notion.so/df6a835f568d449b86695d28e1194b68?pvs=4#198dfb6047a6476496a8286486e1fd39
    if (shuOption.type === 'shuList') {
      return true;
    }
    return settingsOptions.shuGroupList.some(
      (group) => group.id === shuOption.code
    );
  });
  return filtered;
};
