import { AxiosResponse } from 'axios';
import { call, fork, put, select, takeEvery } from 'redux-saga/effects';

import { DataKiParams } from '../domain/dataKi';
import { DataKiKasidama, DataKiKasidamaParams } from '../domain/dataKiKasidama';

import { dataKiParamsSelector } from '../redux/server/dataKi';
import { DataKiDaiActionCreators } from '../redux/server/dataKiDai';
import { DataKiGraphActionCreators } from '../redux/server/dataKiGraph';
import {
  ChangeDataKiKasidamaDkSisSearchParamsAction,
  DataKiKasidamaActionCreators,
  DataKiKasidamaActionTypes,
  FetchDataKiKasidamaAction,
  SearchDataKiKasidamaAction,
  SearchDataKiKasidamaFieldAction,
  SearchDataKiKasidamaKiAction,
  SearchDataKiKasidamaKiShuAction,
  SearchDataKiKasidamaMarkingAction,
  SearchDataKiKasidamaSortAction,
  dataKiKasidamaParamsSelector,
} from '../redux/server/dataKiKasidama';
import { DataKiMksTransitiveGraphActionCreators } from '../redux/server/dataKiMksTransitiveGraph';
import { ModelReportsSettingActionCreators } from '../redux/ui/modelReportsSetting';
import { Api, buildConfig } from '../utils/api';
import { dataKiKasidamaSearchConditionParams } from '../utils/dataKiKasidamaSearchConditionParams';
import { validateQuery } from '../utils/validateQuery';
import { handleErrorSaga } from './errorSaga';
import { changeKasidamaColumnsOrderSaga } from './modelReportsSettingSaga';

/**
 * 指定した検索条件で貸玉データをAPIから取得する
 * @param api API
 * @param action FetchDataKiKasidamaAction
 */
export function* fetchDataKiKasidamaSagas(
  api: Api,
  action: FetchDataKiKasidamaAction
) {
  try {
    yield put(DataKiKasidamaActionCreators.fetchDataKiKasidamaRequestAction());

    // 他のデータがあれば、同時に表示することがないためクリアする
    yield put(DataKiGraphActionCreators.hideDataKiGraphAction());
    yield put(DataKiDaiActionCreators.hideDataKiDai());
    yield put(
      DataKiMksTransitiveGraphActionCreators.hideDataKiMksTransitiveGraphAction()
    );

    validateQuery(action.payload.params);

    // 貸玉データ取得
    const response: AxiosResponse<DataKiKasidama> = yield call(
      api.get,
      '/data/ki/kasidama',
      buildConfig(action.payload.params)
    );
    const dataKiKasidama = response.data;

    // 貸玉データを登録
    yield put(
      DataKiKasidamaActionCreators.fetchDataKiKasidamaSuccessAction(
        dataKiKasidama
      )
    );

    yield fork(changeKasidamaColumnsOrderSaga);
  } catch (error: unknown) {
    yield put(DataKiKasidamaActionCreators.renewDataKiKasidamaAction());
    yield fork(handleErrorSaga, error);
  }
}

/**
 * FETCH_DATA_KI_KASIDAMAをDispatch時の処理
 * @param api API
 */
function* handlFetchDataKiKasidamaSagas(api: Api) {
  yield takeEvery(
    DataKiKasidamaActionTypes.FETCH_DATA_KI_KASIDAMA,
    fetchDataKiKasidamaSagas,
    api
  );
}

/**
 * 選択した機種で貸玉データを再取得
 */
function* searchDataKiKasidama(action: SearchDataKiKasidamaAction) {
  // 現在の検索条件
  const kasidamaSearchCondition:
    | DataKiKasidamaParams
    | undefined = yield select(dataKiKasidamaParamsSelector);

  const searchCondition: DataKiParams = yield select(dataKiParamsSelector);

  // メインテーブル固有のパラメータ（表示項目やマーキング、オーダーなど）以外をマージする
  // shuGroupIdsとshuListは貸玉一覧では無視する
  // 出力を変更した際に出力の固有（kiList,sisTypes,makers）のパラメータを付与してしまう為記入しない
  const mergedParams: DataKiKasidamaParams = {
    ...kasidamaSearchCondition,
    dateSuffixes: searchCondition.dateSuffixes,
    dates: searchCondition.dates,
    dayType: searchCondition.dayType,
    days: searchCondition.days,
    excludeToday: searchCondition.excludeToday,
    ymdList: searchCondition.ymdList,
    ymdComparisonList: searchCondition.ymdComparisonList,
    startDate: searchCondition.startDate,
    endDate: searchCondition.endDate,
    startComparisonDate: searchCondition.startComparisonDate,
    endComparisonDate: searchCondition.endComparisonDate,
    shuList: searchCondition.shuList,
    shuGroupIds: [],
    //sisセレクタ
    sisDateRangeType: searchCondition.sisDateRangeType,
    sisStartDate: searchCondition.sisStartDate,
    sisEndDate: searchCondition.sisEndDate,
    sisBreakEvenForPachinko: searchCondition.sisBreakEvenForPachinko,
    sisBreakEvenForPachislo: searchCondition.sisBreakEvenForPachislo,
    sisRank: searchCondition.sisRank,
    sisArea: searchCondition.sisArea,
    containsSisAverage: searchCondition.containsSisAverage,
  };

  const fetchedDataKiKasidamaSearchCondition = dataKiKasidamaSearchConditionParams(
    action.payload.mainField,
    action.payload.dataKi1stRow,
    searchCondition
  );

  // 選択中の機種行がどの「メーカーID・ホールID」を持つのかを判断するために登録
  if (action.payload.requestParams) {
    yield put(
      ModelReportsSettingActionCreators.changeSelectedDataKiExpandedRowAction(
        action.payload.requestParams.mainFieldId,
        action.payload.requestParams.hallId,
        '3rdRow'
      )
    );
  } else {
    yield put(
      ModelReportsSettingActionCreators.changeSelectedDataKiExpandedRowAction(
        '',
        '',
        '1stRow'
      )
    );
  }

  // 変更後の機種で再取得
  yield put(
    DataKiKasidamaActionCreators.fetchDataKiKasidamaAction({
      ...mergedParams,
      ...fetchedDataKiKasidamaSearchCondition,
      ...action.payload.dkSisSearchParams,
    })
  );
}

/**
 * 選択した種別で貸玉データを再取得
 */
function* searchDataKiKasidamaKiShuSagas(
  action: SearchDataKiKasidamaKiShuAction
) {
  // 現在の検索条件
  const searchCondition: DataKiKasidamaParams | undefined = yield select(
    dataKiKasidamaParamsSelector
  );
  // 変更後の種別で再取得
  yield put(
    DataKiKasidamaActionCreators.fetchDataKiKasidamaAction({
      ...searchCondition,
      shuGroupIds: action.payload.shuGroup,
      shuList: action.payload.shuList,
    })
  );
}

/**
 * 選択した機種で貸玉データを再取得
 */
function* searchDataKiKasidamaKiSagas(action: SearchDataKiKasidamaKiAction) {
  // 現在の検索条件
  const searchCondition: DataKiKasidamaParams | undefined = yield select(
    dataKiKasidamaParamsSelector
  );

  // 変更後の機種で再取得
  yield put(
    DataKiKasidamaActionCreators.fetchDataKiKasidamaAction({
      ...searchCondition,
      [action.payload.mainField.name]: [action.payload.mainField.value],
    })
  );
}

// 指定されたDK-SIS条件で再取得
function* changeDkSisKasidamaSearchParamsSaga(
  action: ChangeDataKiKasidamaDkSisSearchParamsAction
) {
  // 現在の検索条件
  const searchCondition: DataKiKasidamaParams | undefined = yield select(
    dataKiKasidamaParamsSelector
  );

  // 変更後のDK-SIS条件で再取得
  yield put(
    DataKiKasidamaActionCreators.fetchDataKiKasidamaAction({
      ...searchCondition,
      ...action.payload.params,
    })
  );
}

/**
 * 選択したソート条件で貸玉データを再取得
 */
function* searchDataKiKasidamaSortSagas(
  action: SearchDataKiKasidamaSortAction
) {
  // 現在の検索条件
  const searchCondition: DataKiKasidamaParams | undefined = yield select(
    dataKiKasidamaParamsSelector
  );

  // 変更後のソート条件で再取得
  yield put(
    DataKiKasidamaActionCreators.fetchDataKiKasidamaAction({
      ...searchCondition,
      ...action.payload,
    })
  );
}

/**
 * 選択したマーキング条件で貸玉データを再取得
 */
function* searchDataKiKasidamaMarkingSagas(
  action: SearchDataKiKasidamaMarkingAction
) {
  // 現在の検索条件
  const searchCondition: DataKiKasidamaParams | undefined = yield select(
    dataKiKasidamaParamsSelector
  );

  // 変更後のマーキング条件で再取得
  yield put(
    DataKiKasidamaActionCreators.fetchDataKiKasidamaAction({
      ...searchCondition,
      ...action.payload,
    })
  );
}

/**
 * 選択した表示項目で貸玉データを再取得
 */
function* searchDataKiKasidamaFieldSagas(
  action: SearchDataKiKasidamaFieldAction
) {
  // 現在の検索条件
  const searchCondition: DataKiKasidamaParams | undefined = yield select(
    dataKiKasidamaParamsSelector
  );

  // 変更後の表示項目で再取得
  yield put(
    DataKiKasidamaActionCreators.fetchDataKiKasidamaAction({
      ...searchCondition,
      fields: action.payload.fields.map((item) => item.code),
    })
  );
}

/**
 * SEARCH_DATA_KI_KASIDAMAをDispatch時の処理
 */
function* handlSearchDataKiKasidamaSagas() {
  yield takeEvery(
    DataKiKasidamaActionTypes.SEARCH_DATA_KI_KASIDAMA,
    searchDataKiKasidama
  );
}

/**
 * テーブル固有の検索条件変更検知時の処理
 */
function* handleTableSearchSaga() {
  // 機種変更時
  yield takeEvery(
    DataKiKasidamaActionTypes.SEARCH_DATA_KI_KASIDAMA_KI,
    searchDataKiKasidamaKiSagas
  );

  //種別変更時
  yield takeEvery(
    DataKiKasidamaActionTypes.SEARCH_DATA_KI_KASIDAMA_KI_SHU,
    searchDataKiKasidamaKiShuSagas
  );

  // ソート実行時
  yield takeEvery(
    DataKiKasidamaActionTypes.SEARCH_DATA_KI_KASIDAMA_SORT,
    searchDataKiKasidamaSortSagas
  );

  // マーキング実行時
  yield takeEvery(
    DataKiKasidamaActionTypes.SEARCH_DATA_KI_KASIDAMA_MARKING,
    searchDataKiKasidamaMarkingSagas
  );

  // 表示項目変更時
  yield takeEvery(
    DataKiKasidamaActionTypes.SEARCH_DATA_KI_KASIDAMA_FIELD,
    searchDataKiKasidamaFieldSagas
  );

  // SIS条件変更時
  yield takeEvery(
    DataKiKasidamaActionTypes.CHANGE_DATA_KI_KASIDAMA_DK_SIS_SEARCH_PARAMS,
    changeDkSisKasidamaSearchParamsSaga
  );
}

/**
 * 貸玉データに関するタスクを実行する
 * @param context APIに関するデータ
 */
export function* dataKiKasidamaSagas(context: { api: Api }) {
  yield fork(handlFetchDataKiKasidamaSagas, context.api);
  yield fork(handlSearchDataKiKasidamaSagas);
  yield fork(handleTableSearchSaga);
}
