import isSameDay from 'date-fns/isSameDay';
import isWeekend from 'date-fns/isWeekend';

/**
 * Dateの配列から土日祝を除外する
 */
export const excludeHoliday = (dates: Date[], holidays: string[]) => {
  // 土日を除外
  const weekends = dates.filter((date) => !isWeekend(date));

  // 祝日を除外
  const nonHolidayWeekends = weekends.filter(
    (date) => !holidays.some((holiday) => isSameDay(date, new Date(holiday)))
  );

  return nonHolidayWeekends;
};
