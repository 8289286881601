import { filterModelName } from '../../utils/filterModelName';
import { DataDepreciationResponse } from './types';

export const dataDepreciationFilteredByName = (
  dataDepreciation: DataDepreciationResponse,
  name: string | undefined
): DataDepreciationResponse => {
  if (name == null) {
    return dataDepreciation;
  }

  const predicate = filterModelName(name ?? '');

  const rows = dataDepreciation.data.rows.filter(
    (row) => row.data.length > 0 && predicate(row.data.at(0)?.value ?? '')
  );

  return {
    ...dataDepreciation,
    data: {
      ...dataDepreciation.data,
      rows,
    },
  };
};
