import { createSelector } from 'reselect';

import { LoadingState } from '../../domain/schemas';
import { SettingsOptionsKi } from '../../domain/settingsOptionsKi';

import { RootState } from '../../store';
import { settingsOptionsKiDefaultValue } from '../../utils/settingsOptionsKiDefaultValue';
import { dataKiSettingAreasSelector } from './dataKi';

// Action Types

const FETCH_SETTINGS_OPTIONS_KI = 'FETCH_SETTINGS_OPTIONS_KI' as const;
const FETCH_SETTINGS_OPTIONS_KI_REQUEST = 'FETCH_SETTINGS_OPTIONS_KI_REQUEST' as const;
const FETCH_SETTINGS_OPTIONS_KI_SUCCESS = 'FETCH_SETTINGS_OPTIONS_KI_SUCCESS' as const;
const RENEW_SETTINGS_OPTIONS_KI = 'RENEW_SETTINGS_OPTIONS_KI' as const;
const CLEAR_SETTINGS_OPTIONS_KI = 'CLEAR_SETTINGS_OPTIONS_KI' as const;

export const SettingsOptionsKiActionTypes = {
  FETCH_SETTINGS_OPTIONS_KI,
  FETCH_SETTINGS_OPTIONS_KI_REQUEST,
  FETCH_SETTINGS_OPTIONS_KI_SUCCESS,
  RENEW_SETTINGS_OPTIONS_KI,
  CLEAR_SETTINGS_OPTIONS_KI,
};

// Action Creators

function fetchSettingsOptionsKiAction() {
  return {
    type: FETCH_SETTINGS_OPTIONS_KI,
  };
}

function fetchSettingsOptionsKiRequestAction() {
  return {
    type: FETCH_SETTINGS_OPTIONS_KI_REQUEST,
  };
}

function fetchSettingsOptionsKiSuccessAction(
  settingsOptionsKi: SettingsOptionsKi
) {
  return {
    type: FETCH_SETTINGS_OPTIONS_KI_SUCCESS,
    payload: { settingsOptionsKi },
  };
}

function renewSettingsOptionsKiAction() {
  return {
    type: RENEW_SETTINGS_OPTIONS_KI,
  };
}

function clearSettingsOptionsKiAction() {
  return {
    type: CLEAR_SETTINGS_OPTIONS_KI,
  };
}

export const SettingsOptionsKiActionCreators = {
  fetchSettingsOptionsKiAction,
  fetchSettingsOptionsKiRequestAction,
  fetchSettingsOptionsKiSuccessAction,
  renewSettingsOptionsKiAction,
  clearSettingsOptionsKiAction,
};

// Actions

type FetchSettingsOptionsKiAction = ReturnType<
  typeof fetchSettingsOptionsKiAction
>;

type ClearSettingsOptionsKiAction = ReturnType<
  typeof clearSettingsOptionsKiAction
>;

type SettingsOptionsKiAction =
  | FetchSettingsOptionsKiAction
  | ReturnType<typeof fetchSettingsOptionsKiRequestAction>
  | ReturnType<typeof fetchSettingsOptionsKiSuccessAction>
  | ReturnType<typeof renewSettingsOptionsKiAction>
  | ClearSettingsOptionsKiAction;

// State

type SettingsOptionsKiState = {
  loadingState: LoadingState;
  settingsOptionsKi?: SettingsOptionsKi;
};

export const initialState: SettingsOptionsKiState = {
  loadingState: 'prepare',
  settingsOptionsKi: undefined,
};

// Selector

// settingsOptionsを全て取得する
export const settingsOptionsKiSelector = (state: RootState) => {
  return state.settingsOptionsKi.settingsOptionsKi;
};

// 現在のローディング状態を取得
export const settingsOptionsKiLoadingSelector = (state: RootState) => {
  return state.settingsOptionsKi.loadingState;
};

// SearchConditionだけ取得する
export const settingsOptionsKiSearchConditionSelector = createSelector(
  settingsOptionsKiSelector,
  (settingsOptionsKi) => {
    // MEMO: コンポーネント側がundefined許容しないため、空の場合空データを返す
    if (settingsOptionsKi === undefined)
      return settingsOptionsKiDefaultValue.searchCondition;

    return settingsOptionsKi.searchCondition;
  }
);

// kiListだけ取得する
export const settingsOptionsKiKiListSelector = createSelector(
  settingsOptionsKiSearchConditionSelector,
  ({ kiList }) => kiList
);

// 店舗一覧のマスターを取得
export const settingsOptionsKiSearchConditionHallsSelector = createSelector(
  settingsOptionsKiSearchConditionSelector,
  ({ halls }) => halls
);

// 機種のFieldsだけ取得する
export const settingsOptionsKiFieldsKiSelector = createSelector(
  settingsOptionsKiSelector,
  (settingsOptionsKi) => {
    // MEMO: コンポーネント側がundefined許容しないため、空の場合空データを返す
    if (settingsOptionsKi === undefined)
      return settingsOptionsKiDefaultValue.fields.ki;

    return settingsOptionsKi.fields.ki;
  }
);

// 機種集計表示項目の機種のFieldsのisNewがtrueのものがあるかどうかを取得する
export const settingsOptionsKiFieldsKiIsNewSelector = createSelector(
  settingsOptionsKiFieldsKiSelector,
  (fieldsKi) => {
    return fieldsKi.some((item) => item.isNew);
  }
);

// 台別データのFieldsだけ取得する
export const settingsOptionsKiFieldsDaiSelector = createSelector(
  settingsOptionsKiSelector,
  (settingsOptionsKi) => {
    // MEMO: コンポーネント側がundefined許容しないため、空の場合空データを返す
    if (settingsOptionsKi === undefined)
      return settingsOptionsKiDefaultValue.fields.dai;

    return settingsOptionsKi.fields.dai;
  }
);

// 貸玉データのFieldsだけ取得する
export const settingsOptionsKiFieldsKasidamaSelector = createSelector(
  settingsOptionsKiSelector,
  (settingsOptionsKi) => {
    // MEMO: コンポーネント側がundefined許容しないため、空の場合空データを返す
    if (settingsOptionsKi === undefined)
      return settingsOptionsKiDefaultValue.fields.kasidama;

    return settingsOptionsKi.fields.kasidama;
  }
);

// 機種集計貸玉データの貸玉データのFieldsのisNewがtrueのものがあるかどうかを取得する
export const settingsOptionsKiFieldsKasidamaIsNewSelector = createSelector(
  settingsOptionsKiFieldsKasidamaSelector,
  (fieldsKashidama) => {
    return fieldsKashidama.some((item) => item.isNew);
  }
);

// 機種集計台別データのFieldsのisNewがtrueのものがあるかどうかを取得する
export const settingsOptionsKiFieldsDaiIsNewSelector = createSelector(
  settingsOptionsKiFieldsDaiSelector,
  (fieldsDai) => {
    return fieldsDai.some((item) => item.isNew);
  }
);

// 機種フィルター用のジャンル一覧を取得する
export const settingsOptionsKiGenresSelector = createSelector(
  settingsOptionsKiSelector,
  (settingsOptionsKi) => {
    // MEMO: コンポーネント側がundefined許容しないため、空の場合空データを返す
    if (settingsOptionsKi === undefined)
      return settingsOptionsKiDefaultValue.genres.ki;

    return settingsOptionsKi.genres.ki;
  }
);

// 台別フィルター用のジャンル一覧を取得する
export const settingsOptionsKiDaiGenresSelector = createSelector(
  settingsOptionsKiSelector,
  (settingsOptionsKi) => {
    // MEMO: コンポーネント側がundefined許容しないため、空の場合空データを返す
    if (settingsOptionsKi === undefined)
      return settingsOptionsKiDefaultValue.genres.dai;

    return settingsOptionsKi.genres.dai;
  }
);

// 貸玉フィルター用のジャンル一覧を取得する
export const settingsOptionsKiKasidamaGenresSelector = createSelector(
  settingsOptionsKiSelector,
  (settingsOptionsKi) => {
    // MEMO: コンポーネント側がundefined許容しないため、空の場合空データを返す
    if (settingsOptionsKi === undefined)
      return settingsOptionsKiDefaultValue.genres.kasidama;

    return settingsOptionsKi.genres.kasidama;
  }
);

// 機種コードからP-Sensorの機種コードに変換するテーブルを取得する
export const settingsOptionsKiModelNumberConversionTableSelector = createSelector(
  settingsOptionsKiSelector,
  (settingsOptionsKi) => settingsOptionsKi?.kiCodeToPSensorKiCode || {}
);

/**
 * DK-SISの週の最新日を取得する
 * @return DK-SISの週の最新日
 */
export const settingsOptionsKiLatestBaseDateForWeeklySisDataSelector = createSelector(
  settingsOptionsKiSearchConditionSelector,
  (settingsOptionsKi) => settingsOptionsKi.latestBaseDateForWeeklySisData
);

/**
 * DK-SISの月の最新日を取得する
 * @return DK-SISの月の最新日
 */
export const settingsOptionsKiLatestBaseDateForMonthlySisDataSelector = createSelector(
  settingsOptionsKiSearchConditionSelector,
  (settingsOptionsKi) => settingsOptionsKi.latestBaseDateForMonthlySisData
);

const settingsOptionsKiFieldsSelector = createSelector(
  settingsOptionsKiSelector,
  (options) => (options ? options.fields : undefined)
);

/**
 *  商圏推移グラフの項目を取得する
 */
export const settingsOptionsKiMksFieldsSelector = createSelector(
  settingsOptionsKiFieldsSelector,
  (fields) => (fields?.marketAreaGraph ? fields.marketAreaGraph : [])
);

/**
 * 設定済みの機種タグ一覧を取得
 */
export const settingsOptionsKiTagListSelector = createSelector(
  settingsOptionsKiSearchConditionSelector,
  (searchCondition) => {
    if (searchCondition.kiTagList == null) {
      return [];
    }
    return [...searchCondition.kiTagList].sort(
      (a, b) => a.viewOrder - b.viewOrder
    );
  }
);

/**
 * 検索されたエリアのホール一覧を取得する
 */
export const settingsOptionsModelReportsSearchConditionHallsBySearchedAreaSelector = createSelector(
  [settingsOptionsKiSearchConditionSelector, dataKiSettingAreasSelector],
  ({ halls, areas }, selectedAreaIds) => {
    if (!selectedAreaIds) {
      return halls ?? [];
    }
    const hallCodesInAreas = areas
      .filter((x) => selectedAreaIds.includes(x.id))
      .flatMap((x) => x.halls);

    return halls.filter((x) => hallCodesInAreas.includes(x.code));
  }
);

// Reducer

export function settingsOptionsKiReducer(
  state = initialState,
  action: SettingsOptionsKiAction
): SettingsOptionsKiState {
  switch (action.type) {
    case FETCH_SETTINGS_OPTIONS_KI_REQUEST:
      return {
        ...state,
        loadingState: 'loading',
      };
    case FETCH_SETTINGS_OPTIONS_KI_SUCCESS:
      return {
        ...state,
        loadingState: 'loaded',
        settingsOptionsKi: action.payload.settingsOptionsKi,
      };
    case RENEW_SETTINGS_OPTIONS_KI:
      return initialState;
    case CLEAR_SETTINGS_OPTIONS_KI:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
