import { ClarisApiError } from '../../domain/error';
import { LoadingState } from '../../domain/schemas';
import { SettingsHallsParams } from '../../domain/settingsHalls';
import { SettingsPlans } from '../../domain/settingsPlans';

import {
  PatchSettingsHallsFailureAction,
  PatchSettingsHallsRequestAction,
  PatchSettingsHallsSuccessAction,
  SettingsHallsActionTypes,
} from '../server/settingsHalls';

/**
 * TODO:
 * ホール設定のActionに依存しているので修正が必要
 * https://github.com/DKClaris/claris-general/issues/3698
 */

// Action Types

const FETCH_SETTINGS_PLANS = 'FETCH_SETTINGS_PLANS' as const;
const FETCH_SETTINGS_PLANS_REQUEST = 'FETCH_SETTINGS_PLANS_REQUEST' as const;
const FETCH_SETTINGS_PLANS_SUCCESS = 'FETCH_SETTINGS_PLANS_SUCCESS' as const;
const FETCH_SETTINGS_PLANS_FAILURE = 'FETCH_SETTINGS_PLANS_FAILURE' as const;
const SET_SETTINGS_PLANS_DIALOG_OPEN = 'SET_SETTINGS_PLANS_DIALOG_OPEN' as const;
const SET_SETTINGS_PLANS_DIALOG_CLOSE = 'SET_SETTINGS_PLANS_DIALOG_CLOSE' as const;

export const SettingsPlansActionTypes = {
  FETCH_SETTINGS_PLANS,
  FETCH_SETTINGS_PLANS_REQUEST,
  FETCH_SETTINGS_PLANS_SUCCESS,
  FETCH_SETTINGS_PLANS_FAILURE,
  SET_SETTINGS_PLANS_DIALOG_OPEN,
  SET_SETTINGS_PLANS_DIALOG_CLOSE,
};

// Action Creators

function fetchSettingsPlansAction(query: Partial<SettingsPlans['setting']>) {
  return {
    type: FETCH_SETTINGS_PLANS,
    payload: { query },
  };
}

function fetchSettingsPlansRequestAction() {
  return {
    type: FETCH_SETTINGS_PLANS_REQUEST,
  };
}

function fetchSettingsPlansSuccessAction(settingsPlans: SettingsPlans) {
  return {
    type: FETCH_SETTINGS_PLANS_SUCCESS,
    payload: { settingsPlans },
  };
}

function renewSettingsPlansAction() {
  return {
    type: FETCH_SETTINGS_PLANS_FAILURE,
  };
}

function setSettingsPlansDialogOpen(hallCode: string) {
  return {
    type: SET_SETTINGS_PLANS_DIALOG_OPEN,
    payload: { hallCode },
  };
}

function setSettingsPlansDialogClose() {
  return {
    type: SET_SETTINGS_PLANS_DIALOG_CLOSE,
  };
}

export const SettingsPlansActionCreators = {
  fetchSettingsPlansAction,
  fetchSettingsPlansRequestAction,
  fetchSettingsPlansSuccessAction,
  renewSettingsPlansAction,
  setSettingsPlansDialogOpen,
  setSettingsPlansDialogClose,
};

// Actions

export type FetchSettingsPlansAction = ReturnType<
  typeof fetchSettingsPlansAction
>;

type SettingsPlansAction =
  | FetchSettingsPlansAction
  | ReturnType<typeof fetchSettingsPlansRequestAction>
  | ReturnType<typeof fetchSettingsPlansSuccessAction>
  | ReturnType<typeof renewSettingsPlansAction>
  | ReturnType<typeof setSettingsPlansDialogOpen>
  | ReturnType<typeof setSettingsPlansDialogClose>
  | PatchSettingsHallsRequestAction
  | PatchSettingsHallsSuccessAction
  | PatchSettingsHallsFailureAction;

// State

export type SettingsPlansState = {
  settingsPlans?: SettingsPlans;
  loadingState: LoadingState;
  error?: ClarisApiError;
  dialog: {
    open: boolean;
    hallCode: string | undefined;
    loadingState: LoadingState;
    error: ClarisApiError | undefined;
  };
};

export const initialState: SettingsPlansState = {
  settingsPlans: undefined,
  loadingState: 'prepare',
  error: undefined,
  dialog: {
    open: false,
    hallCode: undefined,
    loadingState: 'prepare',
    error: undefined,
  },
};

// Selector

export function settingsPlansSelector(rootState: {
  settingsPlans: SettingsPlansState;
}) {
  return rootState.settingsPlans.settingsPlans;
}

export function settingsPlansLoadingSelector(rootState: {
  settingsPlans: SettingsPlansState;
}) {
  return rootState.settingsPlans.loadingState;
}

export function settingsPlansDialogSelector(rootState: {
  settingsPlans: SettingsPlansState;
}) {
  return rootState.settingsPlans.dialog;
}

// Reducer

const replaceSettingsPlan = (
  data: SettingsPlans['data'],
  payload: SettingsHallsParams
) => {
  return data?.map((x) => {
    if (x.parameter.hallCode === payload.hallCode) {
      return {
        ...x,
        data: {
          ...x.data,
          ...payload,
        },
      };
    }
    return x;
  });
};

export function settingsPlansReducer(
  state = initialState,
  action: SettingsPlansAction
): SettingsPlansState {
  switch (action.type) {
    case FETCH_SETTINGS_PLANS_REQUEST:
      return {
        ...state,
        loadingState: 'loading',
      };
    case FETCH_SETTINGS_PLANS_SUCCESS:
      return {
        ...state,
        settingsPlans: action.payload.settingsPlans,
        loadingState: 'loaded',
      };
    case FETCH_SETTINGS_PLANS_FAILURE:
      return initialState;

    case SET_SETTINGS_PLANS_DIALOG_OPEN:
      return {
        ...state,
        dialog: {
          open: true,
          hallCode: action.payload.hallCode,
          loadingState: 'prepare',
          error: undefined,
        },
      };
    case SET_SETTINGS_PLANS_DIALOG_CLOSE:
      return {
        ...state,
        dialog: {
          open: false,
          hallCode: undefined,
          loadingState: 'prepare',
          error: undefined,
        },
      };
    case SettingsHallsActionTypes.PATCH_SETTINGS_HALLS_REQUEST:
      return {
        ...state,
        dialog: {
          ...state.dialog,
          loadingState: 'loading',
        },
      };
    case SettingsHallsActionTypes.PATCH_SETTINGS_HALLS_SUCCESS:
      return {
        ...state,
        ...(state.settingsPlans
          ? {
              settingsPlans: {
                ...state.settingsPlans,
                data: replaceSettingsPlan(
                  state.settingsPlans?.data,
                  action.payload.settingsHall
                ),
              },
            }
          : {}),
        dialog: {
          ...state.dialog,
          loadingState: 'loaded',
          error: undefined,
        },
      };
    case SettingsHallsActionTypes.PATCH_SETTINGS_HALLS_FAILURE:
      return {
        ...state,
        dialog: {
          ...state.dialog,
          loadingState: 'failed',
          error: action.payload.error,
        },
      };
    default:
      return state;
  }
}
