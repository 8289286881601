import { AxiosResponse } from 'axios';
import { call, fork, put, select, takeEvery } from 'redux-saga/effects';

import { DataKi, DataKiParams } from '../domain/dataKi';
import { DataKi3rdRow } from '../domain/dataKi3rdRow';
import { PpmShareCategory, PpmShareCatetories } from '../domain/marking';
import { MAIN_FIELD_TYPE } from '../domain/schemas';

import { dataKiParamsSelector } from '../redux/server/dataKi';
import {
  DataKi3rdRowActionCreators,
  DataKi3rdRowActionTypes,
  FetchDataKi3rdRowAction,
  SearchDataKi3rdRowAction,
} from '../redux/server/dataKi3rdRow';
import { Api, buildConfig } from '../utils/api';
import { validateQuery } from '../utils/validateQuery';
import { handleErrorSaga } from './errorSaga';

/**
 * QueryParameterを元に検索条件を加工する
 *   @param     hallId               ホール番号
 *   @param     mainFieldId          機種番号
 *   @param     searchParams         機種集計の検索条件
 *   @returns   加工された検索条件
 */
const queryParameterToFormConditions = (
  hallId: string,
  mainFieldId: string,
  mainFieldType: string,
  searchParams: DataKiParams
): DataKiParams => {
  const mainFieldParams =
    mainFieldType === 'kiList'
      ? { kiList: [mainFieldId] }
      : mainFieldType === 'sisTypes'
      ? { sisTypes: [mainFieldId] }
      : { makers: [mainFieldId] };

  // 台番別データ行の通常の検索条件
  const modifiedParams = {
    ...searchParams,
    fields: searchParams.fields,
    marking:
      searchParams.marking &&
      PpmShareCatetories.includes(searchParams.marking as PpmShareCategory)
        ? undefined
        : searchParams.marking,
    mainField:
      mainFieldType === 'sisTypes' || mainFieldType === 'makers'
        ? MAIN_FIELD_TYPE.KI_TUSHO_MEI
        : MAIN_FIELD_TYPE.DAI_BAN,
    containsAverage: false,
    sort: searchParams.sort ? searchParams.sort : MAIN_FIELD_TYPE.DAI_BAN,
    halls: [hallId],
    hallsForDonyuJokyo: [],
    ...mainFieldParams,
  };

  return {
    ...modifiedParams,
    ...{
      kiListForSijiritu: searchParams.kiList,
      sisTypesForSijiritu: searchParams.sisTypes,
      makersForSijiritu: searchParams.makers,
    },
  };
};

/**
 * QueryParameterを元にAPIから台番別データ行の内容を取得してdataKi3rdRowに登録する
 * @param action Action
 */
function* searchDataKi3rdRowSaga(action: SearchDataKi3rdRowAction) {
  const searchParams: DataKiParams = yield select(dataKiParamsSelector);
  const { hallId, mainFieldId, mainFieldType } = action.payload;

  const params = queryParameterToFormConditions(
    hallId,
    mainFieldId,
    mainFieldType,
    searchParams
  );

  yield put(
    DataKi3rdRowActionCreators.fetchDataKi3rdRowAction(
      mainFieldId,
      hallId,
      params
    )
  );
}

/**
 * 台番別データ行を取得した結果を返す
 * @param api AxiosInstance
 */
function* fetchDataKi3rdRowSaga(api: Api, action: FetchDataKi3rdRowAction) {
  try {
    const { hallId, mainFieldId, params } = action.payload;
    yield put(DataKi3rdRowActionCreators.fetchDataKi3rdRowRequestAction());

    validateQuery(params);

    const response: AxiosResponse<DataKi> = yield call(
      api.get,
      '/data/ki',
      buildConfig(params)
    );

    const dataKi3rdRow: DataKi3rdRow = {
      requestParams: { hallId, mainFieldId },
      setting: response.data.setting,
      data: response.data.data,
    };

    yield put(
      DataKi3rdRowActionCreators.fetchDataKi3rdRowSuccessAction(
        mainFieldId,
        hallId,
        dataKi3rdRow
      )
    );
  } catch (error: unknown) {
    yield put(DataKi3rdRowActionCreators.renewDataKi3rdRowAction());
    yield fork(handleErrorSaga, error);
  }
}

/**
 * SEARCH_DATA_KI_3RDROWがDispatchされた時にfetchDataKi3rdRowSagaを実行する
 */
function* handleSearchDataKi3rdRowSaga() {
  yield takeEvery(
    DataKi3rdRowActionTypes.SEARCH_DATA_KI_3RDROW,
    searchDataKi3rdRowSaga
  );
}

/**
 * FETCH_DATA_KI_3RDROWがDispatchされた時にfetchDataKi3rdRowSagaを実行する
 * @param api AxiosInstance
 */
function* handleFetchDataKi3rdRowSaga(api: Api) {
  yield takeEvery(
    DataKi3rdRowActionTypes.FETCH_DATA_KI_3RDROW,
    fetchDataKi3rdRowSaga,
    api
  );
}

/**
 * 台番別データ行に関するタスクを実行する
 * @param context AxiosInstance
 */
export function* dataKi3rdRowSagas(context: { api: Api }) {
  yield fork(handleSearchDataKi3rdRowSaga);
  yield fork(handleFetchDataKi3rdRowSaga, context.api);
}
