import {
  addDays,
  addYears,
  eachMonthOfInterval,
  eachWeekOfInterval,
  endOfMonth,
  format,
  getDay,
  parse,
  setDay,
  startOfMonth,
} from 'date-fns';

import { ReportsDateUnit } from './reportsDateRange';

/**
 * 最新の週の日付を算出する
 * @param latestDate DK-SISの最新データの週の開始日
 * @returns 最新の週の開始日と終了日
 */
export const latestWeeklyOption = (latestDate: string) => {
  try {
    const rowDate = parse(latestDate, 'yyyy-MM-dd', new Date());
    const endDate = addDays(rowDate, 6);

    return {
      sisStartDate: latestDate,
      sisEndDate: format(endDate, 'yyyy-MM-dd'),
    };
  } catch (e) {
    return {
      sisStartDate: '',
      sisEndDate: '',
    };
  }
};

/**
 * 最新の月の日付を算出する
 * @param latestDate DK-SISの最新データの月の開始日
 * @returns 最新の月の開始日と終了日
 */
export const latestMonthlyOption = (latestDate: string) => {
  try {
    const rowDate = parse(latestDate, 'yyyy-MM-dd', new Date());
    const startDate = startOfMonth(rowDate);
    const endDate = endOfMonth(rowDate);

    return {
      sisStartDate: format(startDate, 'yyyy-MM-dd'),
      sisEndDate: format(endDate, 'yyyy-MM-dd'),
    };
  } catch (e) {
    return {
      sisStartDate: '',
      sisEndDate: '',
    };
  }
};

/**
 * 週の期間の選択肢を1年分生成する
 * @param latestDate DK-SISの最新データの週の開始日
 * @returns 一年分の週の期間の選択肢
 */
export const getWeeklyOptions = (latestDate: string) => {
  try {
    const rowDate = parse(latestDate, 'yyyy-MM-dd', new Date());

    const day = getDay(rowDate); // 曜日
    const lastStartDate = setDay(addYears(rowDate, -1), day, {
      weekStartsOn: day,
    }); // 1年後の同じ曜日
    const latestEndDate = addDays(rowDate, 6); // 開始日の週の週末

    // 開始日の一覧
    const weekList = eachWeekOfInterval(
      {
        start: lastStartDate,
        end: latestEndDate,
      },
      {
        weekStartsOn: day,
      }
    ).reverse();

    // 週の期間の選択肢
    const weeklyOptions = weekList.map((date, idx) => {
      const week = latestWeeklyOption(format(date, 'yyyy-MM-dd'));
      return {
        id: idx,
        ...week,
      };
    });

    return weeklyOptions;
  } catch (e) {
    return [];
  }
};

/**
 * 月の期間の選択肢を1年分生成する
 * @param latestDate DK-SISの最新データの月の開始日
 * @returns 一年分の月の期間の選択肢
 */
export const getMonthlyOptions = (latestDate: string) => {
  try {
    const rowDate = parse(latestDate, 'yyyy-MM-dd', new Date());

    const lastStartDate = startOfMonth(addYears(rowDate, -1)); // 1年後の月の初日
    const latestEndDate = endOfMonth(rowDate); // 開始日の月の月末

    // 開始日の一覧
    const monthList = eachMonthOfInterval({
      start: lastStartDate,
      end: latestEndDate,
    }).reverse();

    // 月の期間の選択肢
    const monthlyOptions = monthList.map((date, idx) => {
      const month = latestMonthlyOption(format(date, 'yyyy-MM-dd'));
      return {
        id: idx,
        ...month,
      };
    });

    return monthlyOptions;
  } catch (e) {
    return [];
  }
};

/**
 * ReportsDateUnitからsisDateRangeTypeに変換する
 */
export const toSisDateRangeType = (from: ReportsDateUnit) => {
  switch (from) {
    case '日':
      return 'daily' as const;
    case '週':
      return 'weekly' as const;
    case '月':
      return 'monthly' as const;
    default:
      return 'custom' as const;
  }
};

/**
 * sis系のパラメータをfieldsから除外する
 *
 * お気に入りでfieldsにsis系の値を含む場合があるのですべて除外する必要がある
 * sis系のパラメータが正しく付与されていればフロントからこれらのfieldsを指定する必要はない
 * ただしこの処理を展開行に使用しないでください、必要な列データもomitされます
 * 展開行でsis系データを含めることは使用上許容されています
 * https://www.notion.so/DK-SIS-cee2b06f2e4c423aa69c67134720562b
 */
export const omitSisFields = (
  fields: string[] | undefined,
  fieldCodes: string[]
) => {
  if (fields == null) {
    return undefined;
  }

  return fields.filter((field) => fieldCodes.includes(field));
};

/**
 * DK-SISを有効にするようにパラメータを変更する
 */
export const enableSis = (
  ymdList: string[] | undefined,
  dateUnit: ReportsDateUnit
) => {
  const sisDateRangeType = toSisDateRangeType(dateUnit);

  const defaultValue = {
    sisFieldTypes: ['result'],
    sisStartDate: ymdList?.[0],
    sisEndDate: ymdList?.slice(-1)[0],
  };

  if (sisDateRangeType === 'custom') {
    return {
      ...defaultValue,
      // 何かしらの値がないとエラーになるためダミーで入力
      // この値はAPIでエラーにならない値でああればなんでもよい
      sisDateRangeType: 'daily' as const,
      sisStartDate: '0000-01-01',
      sisEndDate: '0000-01-01',
    };
  }

  return {
    ...defaultValue,
    sisDateRangeType,
  };
};

/**
 * DK-SISを無効にするようにパラメータを変更する
 */
export const disableSis = (
  fields: string[] | undefined,
  fieldCodes: string[]
) => {
  return {
    // fieldsにsis系が含まれていた場合に除外
    fields: omitSisFields(fields, fieldCodes),
    sisFieldTypes: undefined,
    sisStartDate: undefined,
    sisEndDate: undefined,
    sisDateRangeType: undefined,
  };
};
