import { GraphData } from '../domain/schemas';

const _getColumnIndices = (columns: GraphData['columns']) => (
  columnNames: string[]
) =>
  columns.reduce<number[]>(
    (accum, curr, index) =>
      columnNames.includes(curr.name) ? [...accum, index] : accum,
    []
  );

// グラフデータをactualとdiffに分離
export const mapGraphDataToActualAndDiff = (data: GraphData) => {
  const { columns, rows } = data;

  if (columns.length !== 4) {
    throw new Error('カラム数が4ではありません');
  }

  const actualGraphColumnNames = columns
    .filter((x, index) => index === 0 || !x.name.includes('比較差'))
    .map((x) => x.name);
  const diffGraphColumnNames = columns
    .filter((x, index) => index === 0 || x.name.includes('比較差'))
    .map((x) => x.name);

  const getColumnIndices = _getColumnIndices(columns);
  const actualGraphColumnIndices = getColumnIndices(actualGraphColumnNames);
  const diffGraphColumnIndices = getColumnIndices(diffGraphColumnNames);

  const actualGraph = {
    columns: columns.filter((x) => actualGraphColumnNames.includes(x.name)),
    rows: rows.map((row) =>
      row.filter((_, index) => actualGraphColumnIndices.includes(index))
    ),
  };
  const diffGraph = {
    columns: columns.filter((x) => diffGraphColumnNames.includes(x.name)),
    rows: rows.map((row) =>
      row.filter((_, index) => diffGraphColumnIndices.includes(index))
    ),
  };

  return {
    actualGraph,
    diffGraph,
  };
};
