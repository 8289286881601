import { LoadingState } from '../../domain/schemas';
import { SettingsOptionsPlan } from '../../domain/settingsOptionsPlan';

// Action Types

const FETCH_SETTINGS_OPTIONS_PLAN = 'FETCH_SETTINGS_OPTIONS_PLAN' as const;
const FETCH_SETTINGS_OPTIONS_PLAN_REQUEST = 'FETCH_SETTINGS_OPTIONS_PLAN_REQUEST' as const;
const FETCH_SETTINGS_OPTIONS_PLAN_SUCCESS = 'FETCH_SETTINGS_OPTIONS_PLAN_SUCCESS' as const;
const RENEW_SETTINGS_OPTIONS_PLAN = 'RENEW_SETTINGS_OPTIONS_PLAN' as const;

export const SettingsOptionsPlanActionTypes = {
  FETCH_SETTINGS_OPTIONS_PLAN,
  FETCH_SETTINGS_OPTIONS_PLAN_REQUEST,
  FETCH_SETTINGS_OPTIONS_PLAN_SUCCESS,
  RENEW_SETTINGS_OPTIONS_PLAN,
};

// Action Creators

function fetchSettingsOptionsPlanAction() {
  return {
    type: FETCH_SETTINGS_OPTIONS_PLAN,
  };
}

function fetchSettingsOptionsPlanRequestAction() {
  return {
    type: FETCH_SETTINGS_OPTIONS_PLAN_REQUEST,
  };
}

function fetchSettingsOptionsPlanSuccessAction(
  settingsOptionsPlan: SettingsOptionsPlan
) {
  return {
    type: FETCH_SETTINGS_OPTIONS_PLAN_SUCCESS,
    payload: { settingsOptionsPlan },
  };
}

function renewSettingsOptionsPlanAction() {
  return {
    type: RENEW_SETTINGS_OPTIONS_PLAN,
  };
}

export const SettingsOptionsPlanActionCreators = {
  fetchSettingsOptionsPlanAction,
  fetchSettingsOptionsPlanRequestAction,
  fetchSettingsOptionsPlanSuccessAction,
  renewSettingsOptionsPlanAction,
};

// Actions

type SettingsOptionsPlanAction =
  | ReturnType<typeof fetchSettingsOptionsPlanAction>
  | ReturnType<typeof fetchSettingsOptionsPlanRequestAction>
  | ReturnType<typeof fetchSettingsOptionsPlanSuccessAction>
  | ReturnType<typeof renewSettingsOptionsPlanAction>;

// State

type SettingsOptionsPlanState = {
  settingsOptionsPlan: SettingsOptionsPlan | undefined;
  loadingState: LoadingState;
};

const initialState: SettingsOptionsPlanState = {
  settingsOptionsPlan: undefined,
  loadingState: 'prepare',
};

// Selector

export function settingsOptionsPlanSelector(rootState: {
  settingsOptionsPlan: SettingsOptionsPlanState;
}) {
  return rootState.settingsOptionsPlan.settingsOptionsPlan;
}

export function settingsOptionsPlanLoadingStateSelector(rootState: {
  settingsOptionsPlan: SettingsOptionsPlanState;
}) {
  return rootState.settingsOptionsPlan.loadingState;
}

// Reducer

export function settingsOptionsPlanReducer(
  state = initialState,
  action: SettingsOptionsPlanAction
): SettingsOptionsPlanState {
  switch (action.type) {
    case FETCH_SETTINGS_OPTIONS_PLAN_REQUEST:
      return {
        ...state,
        loadingState: 'loading',
      };
    case FETCH_SETTINGS_OPTIONS_PLAN_SUCCESS:
      return {
        ...state,
        settingsOptionsPlan: action.payload.settingsOptionsPlan,
        loadingState: 'loaded',
      };
    case RENEW_SETTINGS_OPTIONS_PLAN:
      return initialState;
    default:
      return state;
  }
}
