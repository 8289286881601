import { ClarisApiError } from '../../domain/error';
import { LoadingState } from '../../domain/schemas';
import {
  SettingsHall,
  SettingsHalls,
  SettingsHallsParams,
} from '../../domain/settingsHalls';

// Action Types

const FETCH_SETTINGS_HALLS = 'FETCH_SETTINGS_HALLS' as const;
const FETCH_SETTINGS_HALLS_REQUEST = 'FETCH_SETTINGS_HALLS_REQUEST' as const;
const FETCH_SETTINGS_HALLS_SUCCESS = 'FETCH_SETTINGS_HALLS_SUCCESS' as const;
const RENEW_SETTINGS_HALLS = 'RENEW_SETTINGS_HALLS' as const;
const PUT_SETTINGS_HALLS = 'PUT_SETTINGS_HALLS' as const;
const PUT_SETTINGS_HALLS_REQUEST = 'PUT_SETTINGS_HALLS_REQUEST' as const;
const PUT_SETTINGS_HALLS_SUCCESS = 'PUT_SETTINGS_HALLS_SUCCESS' as const;
const PUT_SETTINGS_HALLS_FAILURE = 'PUT_SETTINGS_HALLS_FAILURE' as const;
const PATCH_SETTINGS_HALLS_REQUEST = 'PATCH_SETTINGS_HALLS_REQUEST' as const;
const PATCH_SETTINGS_HALLS_SUCCESS = 'PATCH_SETTINGS_HALLS_SUCCESS' as const;
const PATCH_SETTINGS_HALLS_FAILURE = 'PATCH_SETTINGS_HALLS_FAILURE' as const;
const PATCH_SETTINGS_AREA_REQUEST = 'PATCH_SETTINGS_AREA_REQUEST' as const;
const PATCH_SETTINGS_AREA_SUCCESS = 'PATCH_SETTINGS_AREA_SUCCESS' as const;
const PATCH_SETTINGS_AREA_FAILURE = 'PATCH_SETTINGS_AREA_FAILURE' as const;

export const SettingsHallsActionTypes = {
  FETCH_SETTINGS_HALLS,
  FETCH_SETTINGS_HALLS_REQUEST,
  FETCH_SETTINGS_HALLS_SUCCESS,
  RENEW_SETTINGS_HALLS,
  PUT_SETTINGS_HALLS,
  PUT_SETTINGS_HALLS_REQUEST,
  PUT_SETTINGS_HALLS_SUCCESS,
  PUT_SETTINGS_HALLS_FAILURE,
  PATCH_SETTINGS_HALLS_REQUEST,
  PATCH_SETTINGS_HALLS_SUCCESS,
  PATCH_SETTINGS_HALLS_FAILURE,
  PATCH_SETTINGS_AREA_REQUEST,
  PATCH_SETTINGS_AREA_SUCCESS,
  PATCH_SETTINGS_AREA_FAILURE,
};

// Action Creators

function fetchSettingsHallsAction() {
  return {
    type: FETCH_SETTINGS_HALLS,
  };
}

function fetchSettingsHallsRequestAction() {
  return {
    type: FETCH_SETTINGS_HALLS_REQUEST,
  };
}

function fetchSettingsHallsSuccessAction(settingsHalls: SettingsHalls) {
  return {
    type: FETCH_SETTINGS_HALLS_SUCCESS,
    payload: { settingsHalls },
  };
}

function renewSettingsHallsAction() {
  return {
    type: RENEW_SETTINGS_HALLS,
  };
}

function putSettingsHallsAction(query: SettingsHallsParams) {
  return {
    type: PUT_SETTINGS_HALLS,
    payload: { query },
  };
}

function putSettingsHallsRequestAction() {
  return {
    type: PUT_SETTINGS_HALLS_REQUEST,
  };
}

function putSettingsHallsSuccessAction(settingsHalls: SettingsHalls) {
  return {
    type: PUT_SETTINGS_HALLS_SUCCESS,
    payload: { settingsHalls },
  };
}

function putSettingsHallsFailureAction(error: ClarisApiError) {
  return {
    type: PUT_SETTINGS_HALLS_FAILURE,
    payload: { error },
  };
}

function patchSettingsHallsRequestAction(
  hallCode: string,
  isAutoLinkPlan: boolean
) {
  return {
    type: PATCH_SETTINGS_HALLS_REQUEST,
    payload: { hallCode, isAutoLinkPlan },
  };
}

function patchSettingsHallsSuccessAction(settingsHall: SettingsHall) {
  return {
    type: PATCH_SETTINGS_HALLS_SUCCESS,
    payload: { settingsHall },
  };
}

function patchSettingsHallsFailureAction(error: ClarisApiError) {
  return {
    type: PATCH_SETTINGS_HALLS_FAILURE,
    payload: { error },
  };
}

function patchSettingsAreaAction(hallCode: string, areaIds: string[] | null) {
  return {
    type: PATCH_SETTINGS_AREA_REQUEST,
    payload: { hallCode, areaIds },
  };
}

function patchSettingsAreaSuccessAction() {
  return {
    type: PATCH_SETTINGS_AREA_SUCCESS,
  };
}

function patchSettingsAreaFailureAction(error: ClarisApiError) {
  return {
    type: PATCH_SETTINGS_AREA_FAILURE,
    payload: { error },
  };
}

export const SettingsHallsActionCreators = {
  fetchSettingsHallsAction,
  fetchSettingsHallsRequestAction,
  fetchSettingsHallsSuccessAction,
  renewSettingsHallsAction,
  putSettingsHallsAction,
  putSettingsHallsRequestAction,
  putSettingsHallsSuccessAction,
  putSettingsHallsFailureAction,
  patchSettingsHallsRequestAction,
  patchSettingsHallsSuccessAction,
  patchSettingsHallsFailureAction,
  patchSettingsAreaAction,
  patchSettingsAreaSuccessAction,
  patchSettingsAreaFailureAction,
};

// Actions

export type PutSettingsHallsAction = ReturnType<typeof putSettingsHallsAction>;
export type PatchSettingsHallsRequestAction = ReturnType<
  typeof patchSettingsHallsRequestAction
>;
export type PatchSettingsHallsSuccessAction = ReturnType<
  typeof patchSettingsHallsSuccessAction
>;
export type PatchSettingsHallsFailureAction = ReturnType<
  typeof patchSettingsHallsFailureAction
>;
export type PatchSettingsAreaAction = ReturnType<
  typeof patchSettingsAreaAction
>;
type PatchSettingsAreaSuccessAction = ReturnType<
  typeof patchSettingsAreaSuccessAction
>;
type PatchSettingsAreaFailureAction = ReturnType<
  typeof patchSettingsAreaFailureAction
>;

type SettingsHallsAction =
  | ReturnType<typeof fetchSettingsHallsAction>
  | ReturnType<typeof fetchSettingsHallsRequestAction>
  | ReturnType<typeof fetchSettingsHallsSuccessAction>
  | ReturnType<typeof renewSettingsHallsAction>
  | PutSettingsHallsAction
  | ReturnType<typeof putSettingsHallsRequestAction>
  | ReturnType<typeof putSettingsHallsSuccessAction>
  | ReturnType<typeof putSettingsHallsFailureAction>
  | PatchSettingsHallsRequestAction
  | PatchSettingsHallsSuccessAction
  | PatchSettingsHallsFailureAction
  | PatchSettingsAreaAction
  | PatchSettingsAreaSuccessAction
  | PatchSettingsAreaFailureAction;

// State

type SettingsHallsState = {
  settingsHalls: SettingsHalls | undefined;
  loadingState: LoadingState;
  error: ClarisApiError | undefined;
};

const initialState: SettingsHallsState = {
  settingsHalls: undefined,
  loadingState: 'prepare',
  error: undefined,
};

// Selector

export function settingsHallsSelector(rootState: {
  settingsHalls: SettingsHallsState;
}) {
  return rootState.settingsHalls;
}

// Reducer

export function settingsHallsReducer(
  state = initialState,
  action: SettingsHallsAction
) {
  switch (action.type) {
    case FETCH_SETTINGS_HALLS_REQUEST:
      return {
        ...state,
        loadingState: 'loading',
      };
    case FETCH_SETTINGS_HALLS_SUCCESS:
      return {
        ...state,
        settingsHalls: action.payload.settingsHalls,
        loadingState: 'loaded',
      };
    case RENEW_SETTINGS_HALLS:
      return initialState;
    case PATCH_SETTINGS_AREA_REQUEST:
      return {
        ...state,
        loadingState: 'loading',
      };
    case PUT_SETTINGS_HALLS_REQUEST:
      return {
        ...state,
        loadingState: 'loading',
      };
    case PUT_SETTINGS_HALLS_SUCCESS:
      return {
        ...state,
        settingsHalls: action.payload.settingsHalls,
        loadingState: 'loaded',
      };
    case PUT_SETTINGS_HALLS_FAILURE:
      return {
        ...state,
        loadingState: 'failed',
        error: action.payload.error,
      };
    case PATCH_SETTINGS_AREA_FAILURE:
      return {
        ...state,
        loadingState: 'failed',
        error: action.payload.error,
      };

    case PATCH_SETTINGS_AREA_SUCCESS:
      return {
        ...state,
        loadingState: 'loaded',
      };
    default:
      return state;
  }
}
