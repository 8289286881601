import { SharedUser } from '../domain/favorites';

type User = {
  id: string;
  name: string;
};

/**
 * FavoriteItemから共有ユーザー一覧を取得する
 *
 * 全体共有の場合も共有ユーザー一覧を出すので、事前にisSharedをチェックする必要があります
 */
export const getSharedUsers = <
  T extends {
    privatelySharedUsers?: User[] | undefined;
    createdBy?: User | undefined;
  }
>(
  favoriteItem: T,
  userId?: string
) => {
  if (userId == null) {
    throw new Error('userIdが存在しません');
  }

  const sharedUsers: SharedUser[] = [
    ...(favoriteItem.privatelySharedUsers ?? []),
    ...(favoriteItem.createdBy ? [favoriteItem.createdBy] : []),
  ];

  const excludeCurrentUser = sharedUsers.filter((user) => user.id !== userId);

  const uniqueSharedUsers = excludeCurrentUser.filter(
    (user, index, array) => array.findIndex((u) => u.id === user.id) === index
  );

  return uniqueSharedUsers;
};
