import { AxiosResponse } from 'axios';

/**
 * 店舗レポートの時間帯別稼動数テーブル用のデータ取得に利用するSagas
 */
import { call, fork, put, select, takeEvery } from 'redux-saga/effects';

import { DataHallKado, DataHallKadoParams } from '../domain/dataHallKado';
import { HallReportsFormConditions } from '../domain/hallReportsFormConditions';
import { ShuOption } from '../domain/shu';

import {
  DataHallShuKadoActionCreators,
  DataHallShuKadoActionTypes,
  FetchDataHallShuKadoAction,
  SearchDataHallShuKadoTableAction,
  SearchDataHallShuKadoTableKadoFieldTypeAction,
  SearchDataHallShuKadoTableSortAction,
  dataHallShuKadoIsExistSelector,
  dataHallShuKadoLoadingSelector,
  dataHallShuKadoParamsSelector,
} from '../redux/server/dataHallShuKadoTable';
import { DataHallShuKadoTableGraphActionCreators } from '../redux/server/dataHallShuKadoTableGraph';
import {
  hallReportsSearchConditionSelector,
  hallReportsSelectedShuSelector,
} from '../redux/ui/hallReportsSetting';
import { Api, buildConfig } from '../utils/api';
import { selectShus2HallReportSearchCondition } from '../utils/shu';
import { handleErrorSaga } from './errorSaga';

// 検索条件を指定して時間帯別稼動数（率）稼動テーブルデータを取得する
export function* fetchDataHallShuKadoTableSaga(
  api: Api,
  action: FetchDataHallShuKadoAction
) {
  try {
    // リクエストを開始
    yield put(
      DataHallShuKadoActionCreators.fetchDataHallShuKadoRequestAction(
        action.payload.params
      )
    );
    const response: AxiosResponse<DataHallKado> = yield call(
      api.get,
      '/data/hall/shu/kado',
      buildConfig({
        ...action.payload.params,
      })
    );

    yield put(
      DataHallShuKadoActionCreators.fetchDataHallShuKadoSuccessAction(
        response.data
      )
    );
  } catch (error: unknown) {
    yield put(DataHallShuKadoActionCreators.renewDataHallShuKadoAction());
    yield fork(handleErrorSaga, error);
  }
}

// 時間帯別稼働数テーブルを初回取得する
function* initDataHallShuKadoTableSaga() {
  // 初回ロードするかどうか判定する
  const isLoading: boolean = yield select(dataHallShuKadoLoadingSelector); // ローディング状態
  const isExist: boolean = yield select(dataHallShuKadoIsExistSelector); // データが存在するか

  // 他でデータを取得中か、既にデータ取得済みか、取得したがエラーの時は初回取得しない
  if (isLoading || isExist) return;

  // 現在の検索条件
  const searchCondition: HallReportsFormConditions = yield select(
    hallReportsSearchConditionSelector
  );

  // 現在の選択されている種別
  const selectedShu: ShuOption[] = yield select(hallReportsSelectedShuSelector);

  // データを取得
  yield put(
    DataHallShuKadoActionCreators.fetchDataHallShuKadoAction({
      halls: searchCondition.halls,
      ymdList: searchCondition.ymdList,
      excludeToday: searchCondition.excludeToday,
      ...selectShus2HallReportSearchCondition(selectedShu),
    })
  );
}

// 検索実行時検索フォームの内容で時間帯別稼働数テーブルを再取得する
function* searchDataHallShuKadoTableSaga(
  action: SearchDataHallShuKadoTableAction
) {
  // 現在の時間帯別稼働数テーブルの検索条件
  const searchCondition: DataHallKadoParams = yield select(
    dataHallShuKadoParamsSelector
  );

  // データ取得
  yield put(
    DataHallShuKadoActionCreators.fetchDataHallShuKadoAction({
      ...searchCondition,
      shuList: action.payload.params.shuList,
      shuGroupIds: action.payload.params.shuGroupIds,
      halls: action.payload.params.halls,
      ymdList: action.payload.params.ymdList,
      excludeToday: action.payload.params.excludeToday,
    })
  );
}

// ソート実行時データを取得する
function* searchDataHallShuKadoTableSortSaga(
  action: SearchDataHallShuKadoTableSortAction
) {
  // 現在の時間帯別稼働数テーブルの検索条件
  const searchCondition: DataHallKadoParams = yield select(
    dataHallShuKadoParamsSelector
  );

  // データを取得
  yield put(
    DataHallShuKadoActionCreators.fetchDataHallShuKadoAction({
      ...searchCondition,
      ...action.payload,
    })
  );
}

// 稼働率・稼働数変更時テーブルとグラフを再取得する
function* searchDataHallShuKadoTableKadoFieldTypeSaga(
  action: SearchDataHallShuKadoTableKadoFieldTypeAction
) {
  // 現在の検索条件
  const searchParams: DataHallKadoParams = yield select(
    dataHallShuKadoParamsSelector
  );

  // テーブルデータを取得
  yield put(
    DataHallShuKadoActionCreators.fetchDataHallShuKadoAction({
      ...searchParams,
      kadoFieldType: action.payload.kadoFieldType,
    })
  );

  // グラフのデータも再取得
  yield put(
    DataHallShuKadoTableGraphActionCreators.searchDataHallShuKadoTableGraphKadoFieldTypeAction(
      action.payload.kadoFieldType
    )
  );
}

// FETCH_DATA_HALL_SHU_KADOがdispatchされたタイミングで時間帯別稼動数（率）データを取得
function* handleFetchDataHallShuKadoTableSaga(api: Api) {
  yield takeEvery(
    DataHallShuKadoActionTypes.FETCH_DATA_HALL_SHU_KADO,
    fetchDataHallShuKadoTableSaga,
    api
  );
}

// Init_DATA_HALL_SHU_KADO_TABLEがdispatchされた時の処理
function* handleInitDataHallShuKadoTableSaga() {
  yield takeEvery(
    DataHallShuKadoActionTypes.INIT_DATA_HALL_SHU_KADO_TABLE,
    initDataHallShuKadoTableSaga
  );
}

// SEARCH_DATA_HALL_SHU_KADO_TABLEがdispatchされた時の処理
function* handleSearchDataHallShuKadoTableSaga() {
  yield takeEvery(
    DataHallShuKadoActionTypes.SEARCH_DATA_HALL_SHU_KADO_TABLE,
    searchDataHallShuKadoTableSaga
  );
}

// SEARCH_DATA_HALL_SHU_KADO_TABLE_SORTがdispatchされた時の処理
function* handleSearchDataHallShuKadoTableSortSaga() {
  yield takeEvery(
    DataHallShuKadoActionTypes.SEARCH_DATA_HALL_SHU_KADO_TABLE_SORT,
    searchDataHallShuKadoTableSortSaga
  );
}

// SEARCH_DATA_HALL_SHU_KADO_TABLE_KADO_FIELD_TYPEがdispatchされた時の処理
function* handleSearchDataHallShuKadoTableKadoFieldTypeSaga() {
  yield takeEvery(
    DataHallShuKadoActionTypes.SEARCH_DATA_HALL_SHU_KADO_TABLE_KADO_FIELD_TYPE,
    searchDataHallShuKadoTableKadoFieldTypeSaga
  );
}

// 時間帯別稼動数（率）グラフに関するタスクを実行
export function* dataHallShuKadoTableSagas(context: { api: Api }) {
  yield fork(handleFetchDataHallShuKadoTableSaga, context.api);
  yield fork(handleInitDataHallShuKadoTableSaga);
  yield fork(handleSearchDataHallShuKadoTableSaga);
  yield fork(handleSearchDataHallShuKadoTableSortSaga);
  yield fork(handleSearchDataHallShuKadoTableKadoFieldTypeSaga);
}
