import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';
import { gaDebugMode } from './consistents';
import { setupSentry } from './sentry';

async function enableMocking() {
  if (
    process.env.NODE_ENV === 'development' &&
    process.env.REACT_APP_MSW === 'true'
  ) {
    const { setupWorker } = await import('msw/browser');
    const { handlers } = await import('./mocks/handlers');

    const worker = setupWorker(...handlers);

    return worker.start();
  }
}

enableMocking().then(() => {
  setupSentry();

  const root = createRoot(document.getElementById('root') as HTMLElement);
  root.render(
    gaDebugMode ? (
      // GAのデバックをするときStrictModeではなく本番環境と同等の状態にし
      // useEffectが複数回実行されるのを抑止する
      <App />
    ) : (
      <StrictMode>
        <App />
      </StrictMode>
    )
  );
});
