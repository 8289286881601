import { DkSisSearchParams } from './dkSis';
import { Column, MainFieldType, OrderType, Row } from './schemas';

/**
 * チェーン店レポート 新台/メイン機種の検索条件の型
 */
export type DataChainStoreKiParams = {
  mainField?: MainFieldType;
  /**
   * mainField以外で表示する項目設定コードの一覧
   */
  fields?: string[];
  /**
   * 適用するマーキング設定コード
   */
  marking?: string;
  /**
   * マーキングのフィルタリング設定（デフォルト:false）
   */
  isFiltering?: boolean;
  /**
   * 種別一覧(未指定で全種別)
   */
  shuList?: string[];
  /**
   * 種別グループID(未指定で全種別グループ)
   */
  shuGroupIds?: string[];
  /**
   *  エリアID一覧（未指定で全エリア）
   */
  areas?: string[];
  /**
   * 対象店舗コード(未指定で全店舗)
   */
  halls?: string[];
  /**
   * 集計する日付範囲の開始日(デフォルト:当月の開始日)
   */
  startDate?: string;
  /**
   * 集計する日付範囲の最終日(デフォルト:当日)
   */
  endDate?: string;
  /**
   * 比較対象として集計する日付範囲の開始日(デフォルト:なし)
   */
  startComparisonDate?: string;
  /**
   * 比較対象として集計する日付範囲の最終日(デフォルト:なし)
   */
  endComparisonDate?: string;
  /**
   * 集計する日付範囲
   */
  ymdList?: string[];
  /**
   * 比較対象として集計する日付範囲
   */
  ymdComparisonList?: string[];
  /**
   * 表示日付範囲に当日を除外するかどうか（デフォルト:true）
   */
  excludeToday?: boolean;
  /**
   * 並び替えキー(未指定で区分。区分を表示項目に含めていない場合はメインフィールドを用いる。デフォルト:kbn)
   */
  sort?: string;
  /**
   * 並び方(未指定で降順)
   */
  order?: OrderType;
} & DkSisSearchParams;

/**
 * チェーン店レポート 新台/メイン機種データの型
 */
export type DataChainStoreKi = {
  setting: DataChainStoreKiParams;
  data: {
    columns: Column[];
    rows: Row[];
  };
};

/**
 * shouldAutomaticallyOpenGraphの戻り値で使うコマンドタイプ
 */
export const GraphCommand = {
  DO_NOTHING: 'DO_NOTHING',
  OPEN_GRAPH_BY_USER: 'OPEN_GRAPH_BY_USER',
  OPEN_GRAPH_AS_NEW: 'OPEN_GRAPH_AS_NEW',
  RESET_NEW_MODELS_AND_CLOSE_GRAPH: 'RESET_NEW_MODELS_AND_CLOSE_GRAPH',
} as const;

export type GraphCommandType = keyof typeof GraphCommand;

/**
 * 自動でグラフを開くかどうか
 */
export const shouldAutomaticallyOpenGraph = (
  isGraphClosedByUser: boolean,
  isGraphOpened: boolean,
  checkedByUserModelCodes: string[],
  tableRows: Row[]
): {
  command: GraphCommandType;
  checkedModelCodes: string[];
} => {
  // 新台を抽出
  const newUnitsModelCodes = tableRows
    .filter(({ data }) => data.at(0)?.value.includes('新台'))
    .map(({ queryParameter }) => queryParameter.value);

  if (isGraphClosedByUser) {
    // ユーザーがグラフを閉じており、新台がないなら、新台のチェック状態を初期化する
    if (newUnitsModelCodes.length === 0) {
      return {
        command: GraphCommand.RESET_NEW_MODELS_AND_CLOSE_GRAPH,
        checkedModelCodes: [],
      };
    }
    // その他の場合ユーザーがグラフを閉じていれば何もしない
    return {
      command: GraphCommand.DO_NOTHING,
      checkedModelCodes: [],
    };
  }

  // ユーザーが手動でチェックしたものがあり、
  // グラフが開いていればそれだけをグラフに表示する
  if (checkedByUserModelCodes.length > 0) {
    if (isGraphOpened) {
      return {
        command: GraphCommand.OPEN_GRAPH_BY_USER,
        checkedModelCodes: checkedByUserModelCodes,
      };
    }
    return {
      command: GraphCommand.DO_NOTHING,
      checkedModelCodes: [],
    };
  }

  if (newUnitsModelCodes.length === 0) {
    return {
      command: GraphCommand.RESET_NEW_MODELS_AND_CLOSE_GRAPH,
      checkedModelCodes: [],
    };
  }

  return {
    command: GraphCommand.OPEN_GRAPH_AS_NEW,
    checkedModelCodes: newUnitsModelCodes,
  };
};
