/**
 * 各ページ用のコンポーネントを事前に読み込む
 */
export const preloadPageComponent = (pageName: string) => {
  switch (pageName) {
    case 'ホーム':
      import('../components/pages/HomePage/HomePage').catch(() =>
        Promise.resolve()
      );
      return;
    case 'チェーン店レポート':
      import(
        '../components/pages/ChainStoreReportsPage/ChainStoreReportsPage'
      ).catch(() => Promise.resolve());
      return;
    case '店舗レポート':
      import('../components/pages/HallReportsPage/HallReportsPage').catch(() =>
        Promise.resolve()
      );
      return;
    case '予定進捗推移':
      import(
        '../components/pages/PlanProgressTransitionReportsPage/PlanProgressTransitionReportsPage'
      ).catch(() => Promise.resolve());
      return;
    case 'PPMシェア一覧':
      import(
        '../components/pages/PpmShareReportsPage/PpmShareReportsPage'
      ).catch(() => Promise.resolve());
      return;
    case '機種別償却':
      import(
        '../components/pages/DepreciationPage/DepreciationPage'
      ).catch(() => Promise.resolve());
      return;
    case '機種集計':
      import(
        '../components/pages/ModelReportsPage/ModelReportsPage'
      ).catch(() => Promise.resolve());
      return;
    case 'SIS機種レポート':
      import('../components/pages/SisPage/SisPage').catch(() =>
        Promise.resolve()
      );
      return;
    case '台別データ一覧':
      import(
        '../components/pages/UnitDataListPage/UnitDataListPage'
      ).catch(() => Promise.resolve());
      return;
    case 'モード別集計':
      import('../components/pages/ModePage/ModePage').catch(() =>
        Promise.resolve()
      );
      return;
    case '導入後推移':
      import(
        '../components/pages/TransitionAfterIntroductionPage/TransitionAfterIntroductionPage'
      ).catch(() => Promise.resolve());
      return;
    case '期間推移':
      import(
        '../components/pages/TerminalTransitionPage/TerminalTransitionPage'
      ).catch(() => Promise.resolve());
      return;
    case '機種別推移':
      import(
        '../components/pages/ModelTransitionPage/ModelTransitionPage'
      ).catch(() => Promise.resolve());
      return;
  }

  throw new Error(`pageName: ${pageName} is not found`);
};
