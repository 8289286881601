import { createSelector } from 'reselect';

import { DataHallKado, DataHallKadoParams } from '../../domain/dataHallKado';
import { HallReportsFormConditions } from '../../domain/hallReportsFormConditions';
import {
  KADO_FIELD_TYPE,
  KadoFieldType,
  OrderType,
} from '../../domain/schemas';

import { RootState } from '../../store';

/**
 * Action Types
 */

// Condition(halls, startDate, endDate,)はSearchConditionからまとめて取得してSagaで混ぜるのでReduxで変更されたかは持たない。
const FETCH_DATA_HALL_SHU_KADO = 'FETCH_DATA_HALL_SHU_KADO' as const;
const FETCH_DATA_HALL_SHU_KADO_REQUEST = 'FETCH_DATA_HALL_SHU_KADO_REQUEST' as const;
const FETCH_DATA_HALL_SHU_KADO_SUCCESS = 'FETCH_DATA_HALL_SHU_KADO_SUCCESS' as const;
const INIT_DATA_HALL_SHU_KADO_TABLE = 'INIT_DATA_HALL_SHU_KADO_TABLE' as const;
const SEARCH_DATA_HALL_SHU_KADO_TABLE = 'SEARCH_DATA_HALL_SHU_KADO_TABLE' as const;
const SEARCH_DATA_HALL_SHU_KADO_TABLE_SORT = 'SEARCH_DATA_HALL_SHU_KADO_TABLE_SORT' as const;
const SEARCH_DATA_HALL_SHU_KADO_TABLE_KADO_FIELD_TYPE = 'SEARCH_DATA_HALL_SHU_KADO_TABLE_KADO_FIELD_TYPE' as const;
const CLEAR_DATA_HALL_SHU_KADO_TABLE = 'CLEAR_DATA_HALL_SHU_KADO_TABLE' as const;
const RENEW_DATA_HALL_SHU_KADO = 'RENEW_DATA_HALL_SHU_KADO' as const;

export const DataHallShuKadoActionTypes = {
  FETCH_DATA_HALL_SHU_KADO,
  FETCH_DATA_HALL_SHU_KADO_REQUEST,
  FETCH_DATA_HALL_SHU_KADO_SUCCESS,
  INIT_DATA_HALL_SHU_KADO_TABLE,
  SEARCH_DATA_HALL_SHU_KADO_TABLE,
  SEARCH_DATA_HALL_SHU_KADO_TABLE_SORT,
  SEARCH_DATA_HALL_SHU_KADO_TABLE_KADO_FIELD_TYPE,
  CLEAR_DATA_HALL_SHU_KADO_TABLE,
  RENEW_DATA_HALL_SHU_KADO,
};

/**
 * Action Creators
 */

/**
 * 指定した検索条件でデータを取得する
 * @param params 検索条件
 */
function fetchDataHallShuKadoAction(params: DataHallKadoParams) {
  return {
    type: FETCH_DATA_HALL_SHU_KADO,
    payload: { params },
  };
}

/**
 * データの取得を開始時に呼ぶもの
 * @param params 検索条件
 */
function fetchDataHallShuKadoRequestAction(params: DataHallKadoParams) {
  return {
    type: FETCH_DATA_HALL_SHU_KADO_REQUEST,
    payload: { params },
  };
}

/**
 * データ取得完了時にテーブルデータを登録
 * @param dataHallKadoTableData テーブルデータ
 */
function fetchDataHallShuKadoSuccessAction(
  dataHallKadoTableData: DataHallKado
) {
  return {
    type: FETCH_DATA_HALL_SHU_KADO_SUCCESS,
    payload: { dataHallKadoTableData },
  };
}

/**
 * 現在の検索条件を元にでテーブルデータを取得（初回取得用）
 */
function initDataHallShuKadoTableAction() {
  return {
    type: INIT_DATA_HALL_SHU_KADO_TABLE,
  };
}

/**
 * 検索フォームで設定した検索条件を元にデータを再取得する
 * @param params 検索フォームの検索条件
 */
function searchDataHallShuKadoTableAction(params: HallReportsFormConditions) {
  return {
    type: SEARCH_DATA_HALL_SHU_KADO_TABLE,
    payload: { params },
  };
}

/**
 * 指定したソートでテーブルデータを取得
 * @param kadoFieldType 稼働数・稼働率
 */
function searchDataHallShuKadoTableSortAction(sort: string, order: OrderType) {
  return {
    type: SEARCH_DATA_HALL_SHU_KADO_TABLE_SORT,
    payload: { sort, order },
  };
}

/**
 * 指定した稼働数・稼働率でテーブルデータを取得
 * @param kadoFieldType 稼働数・稼働率
 */
function searchDataHallShuKadoTableKadoFieldTypeAction(
  kadoFieldType: KadoFieldType
) {
  return {
    type: SEARCH_DATA_HALL_SHU_KADO_TABLE_KADO_FIELD_TYPE,
    payload: { kadoFieldType },
  };
}

/**
 * 時間帯別稼働数テーブルのデータを破棄する
 */
function clearDataHallShuKadoTableAction() {
  return {
    type: CLEAR_DATA_HALL_SHU_KADO_TABLE,
  };
}

function renewDataHallShuKadoAction() {
  return {
    type: RENEW_DATA_HALL_SHU_KADO,
  };
}

export const DataHallShuKadoActionCreators = {
  fetchDataHallShuKadoAction,
  fetchDataHallShuKadoRequestAction,
  fetchDataHallShuKadoSuccessAction,
  initDataHallShuKadoTableAction,
  searchDataHallShuKadoTableAction,
  searchDataHallShuKadoTableSortAction,
  searchDataHallShuKadoTableKadoFieldTypeAction,
  clearDataHallShuKadoTableAction,
  renewDataHallShuKadoAction,
};

/**
 * Actions
 */

export type FetchDataHallShuKadoAction = ReturnType<
  typeof fetchDataHallShuKadoAction
>;
type InitDataHallShuKadoTableAction = ReturnType<
  typeof initDataHallShuKadoTableAction
>;
export type SearchDataHallShuKadoTableAction = ReturnType<
  typeof searchDataHallShuKadoTableAction
>;
export type SearchDataHallShuKadoTableSortAction = ReturnType<
  typeof searchDataHallShuKadoTableSortAction
>;
export type SearchDataHallShuKadoTableKadoFieldTypeAction = ReturnType<
  typeof searchDataHallShuKadoTableKadoFieldTypeAction
>;
type ClearDataHallShuKadoTableAction = ReturnType<
  typeof clearDataHallShuKadoTableAction
>;

type DataHallKadoAction =
  | FetchDataHallShuKadoAction
  | InitDataHallShuKadoTableAction
  | SearchDataHallShuKadoTableAction
  | SearchDataHallShuKadoTableSortAction
  | SearchDataHallShuKadoTableKadoFieldTypeAction
  | ClearDataHallShuKadoTableAction
  | ReturnType<typeof fetchDataHallShuKadoRequestAction>
  | ReturnType<typeof fetchDataHallShuKadoSuccessAction>
  | ReturnType<typeof renewDataHallShuKadoAction>;

/**
 * State
 */
type DataHallShuKadoTableState = {
  isLoading: boolean;
  dataHallShuKadoTable?: DataHallKado;
};

const initialTableState: DataHallShuKadoTableState = {
  isLoading: false,
  dataHallShuKadoTable: undefined,
};

/**
 * Selector
 */

/**
 * 店舗レポートの時間帯別稼働数テーブルのデータを取得する
 * @returns テーブルデータ
 */
const dataHallShuKadoSelector = (state: RootState) => {
  return state.dataHallShuKadoTable.dataHallShuKadoTable;
};

/**
 * 店舗レポートの時間帯別稼働数テーブルが存在するか取得する
 * @returns テーブルデータ
 */
export const dataHallShuKadoIsExistSelector = createSelector(
  dataHallShuKadoSelector,
  (data) => (data === undefined ? false : true)
);

/**
 * 店舗レポートの時間帯別稼働数テーブルの現在の検索条件を取得する
 * @returns 現在の検索条件
 */
export const dataHallShuKadoParamsSelector = (state: RootState) => {
  return state.dataHallShuKadoTable.dataHallShuKadoTable?.setting;
};

/**
 * 店舗レポートの時間帯別稼働数テーブルデータを取得する
 * @returns 現在の検索条件
 */
export const dataHallShuKadoParamsDataSelector = createSelector(
  dataHallShuKadoSelector,
  (dataHallShuKadoTable) => {
    const tableData = dataHallShuKadoTable?.data;

    if (tableData === undefined)
      return {
        columns: [],
        rows: [],
      };

    return tableData;
  }
);

/**
 * 店舗レポートの時間帯別稼働数テーブルの現在のソートのカラムを取得する
 * @returns 現在の検索条件
 */
export const dataHallShuKadoParamsSortSelector = (state: RootState) =>
  state.dataHallShuKadoTable.dataHallShuKadoTable?.setting.sort ?? 'daisu';

/**
 * 店舗レポートの時間帯別稼働数テーブルの現在のソート順を取得する
 * @returns 現在の検索条件
 */
export const dataHallShuKadoParamsOrderSelector = (state: RootState) =>
  state.dataHallShuKadoTable.dataHallShuKadoTable?.setting.order ??
  ('desc' as const);

/**
 * 店舗レポートの時間帯別稼働数テーブルで現在選択されている稼働フィールドタイプ（稼働数・稼働率）を取得する
 * @returns 現在選択されている稼働数・稼働率（テーブル未取得時デフォルト条件の稼働数を返す）
 */
export const dataHallShuKadoSelectedKadoFieldTypeSelector = (
  state: RootState
) => {
  return (
    state.dataHallShuKadoTable.dataHallShuKadoTable?.setting.kadoFieldType ??
    KADO_FIELD_TYPE.VALUE
  );
};

/**
 * 店舗レポートの時間帯別稼働数テーブルのローディング状態
 * @returns ローディング状態（ローディング時true）
 */
export const dataHallShuKadoLoadingSelector = (state: RootState) => {
  // FIXME: データが空かつ取得中の時のみローディングを表示する
  return state.dataHallShuKadoTable.isLoading;
};

/**
 * Reducer
 */

export function dataHallShuKadoTableReducer(
  state = initialTableState,
  action: DataHallKadoAction
): DataHallShuKadoTableState {
  switch (action.type) {
    case FETCH_DATA_HALL_SHU_KADO_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_DATA_HALL_SHU_KADO_SUCCESS:
      return {
        ...state,
        isLoading: false,
        dataHallShuKadoTable: action.payload.dataHallKadoTableData,
      };
    case CLEAR_DATA_HALL_SHU_KADO_TABLE:
      return {
        ...state,
        dataHallShuKadoTable: initialTableState.dataHallShuKadoTable,
      };
    case RENEW_DATA_HALL_SHU_KADO:
      return initialTableState;
    default:
      return state;
  }
}
