import { AxiosResponse } from 'axios';
import { call, fork, put, takeEvery } from 'redux-saga/effects';

import { SettingsNationalHolidays } from '../domain/SettingsNationalHolidays';

import {
  SettingsNationalHolidaysActionCreators,
  SettingsNationalHolidaysActionTypes,
} from '../redux/server/settingsNationalHolidays';
import { Api } from '../utils/api';
import { handleErrorSaga } from './errorSaga';

/**
 * 祝日のデータを取得する
 * @param api AxiosInstance
 */
function* fetchSettingsNationalHolidaysSaga(api: Api) {
  try {
    yield put(
      SettingsNationalHolidaysActionCreators.fetchSettingsNationalHolidaysRequestAction()
    );
    const response: AxiosResponse<SettingsNationalHolidays> = yield call(
      api.get,
      '/settings/nationalHolidays'
    );
    yield put(
      SettingsNationalHolidaysActionCreators.fetchSettingsNationalHolidaysSuccessAction(
        response.data
      )
    );
  } catch (error: unknown) {
    yield put(
      SettingsNationalHolidaysActionCreators.renewSettingsNationalHolidaysAction()
    );
    yield fork(handleErrorSaga, error);
  }
}

function* handleFetchSettingsNationalHolidaysSaga(api: Api) {
  yield takeEvery(
    SettingsNationalHolidaysActionTypes.FETCH_SETTINGS_NATIONAL_HOLIDAYS,
    fetchSettingsNationalHolidaysSaga,
    api
  );
}

export function* SettingsNationalHolidaysSagas(context: { api: Api }) {
  yield fork(handleFetchSettingsNationalHolidaysSaga, context.api);
}
