import { createSelector } from 'reselect';

import { ChainStoreReportsFavorite } from '../../domain/chain/types';
import { ChainReportsFormConditions } from '../../domain/chainReportsFormConditions';
import {
  DataChainStoreShu,
  DataChainStoreShuParams,
  DataChainStoreShuSummary,
  DataChainStoreShuSummaryParams,
} from '../../domain/dataChainStoreShu';
import { MarkingOption } from '../../domain/marking';
import { Option, OrderType } from '../../domain/schemas';
import { ShuOption } from '../../domain/shu';

import { RootState } from '../../store';
import { getOrderedTableData } from '../../utils/orderedCell';
import {
  ChainStoreSettingActionTypes,
  ResetChainStoreShuAction,
  chainStoreReportsSearchConditionSelector,
} from '../ui/chainStoreReportsSetting';
import { dataChainStoreShuWholeLoadingSelector } from './dataChainStoreShuWhole';

const FETCH_DATA_CHAIN_STORE_SHU = 'FETCH_DATA_CHAIN_STORE_SHU' as const;
const FETCH_DATA_CHAIN_STORE_SHU_REQUEST = 'FETCH_DATA_CHAIN_STORE_SHU_REQUEST' as const;
const FETCH_DATA_CHAIN_STORE_SHU_SUCCESS = 'FETCH_DATA_CHAIN_STORE_SHU_SUCCESS' as const;

const FETCH_DATA_CHAIN_STORE_SHU_SUMMARY = 'FETCH_DATA_CHAIN_STORE_SHU_SUMMARY' as const;
const FETCH_DATA_CHAIN_STORE_SHU_SUMMARY_REQUEST = 'FETCH_DATA_CHAIN_STORE_SHU_SUMMARY_REQUEST' as const;
const FETCH_DATA_CHAIN_STORE_SHU_SUMMARY_SUCCESS = 'FETCH_DATA_CHAIN_STORE_SHU_SUMMARY_SUCCESS' as const;

const INIT_DATA_CHAIN_STORE_SHU = 'INIT_DATA_CHAIN_STORE_SHU' as const;

const SEARCH_DATA_CHAIN_STORE_SHU = 'SEARCH_DATA_CHAIN_STORE_SHU' as const;
const SEARCH_DATA_CHAIN_STORE_SHU_RESET = 'SEARCH_DATA_CHAIN_STORE_SHU_RESET' as const;
const SEARCH_DATA_CHAIN_STORE_SHU_FAVORITE = 'SEARCH_DATA_CHAIN_STORE_SHU_FAVORITE' as const;
const SEARCH_DATA_CHAIN_STORE_SHU_SORT = 'SEARCH_DATA_CHAIN_STORE_SHU_SORT' as const;
const SEARCH_DATA_CHAIN_STORE_SHU_MARKING = 'SEARCH_DATA_CHAIN_STORE_SHU_MARKING' as const;
const SEARCH_DATA_CHAIN_STORE_SHU_FIELD_TYPE = 'SEARCH_DATA_CHAIN_STORE_SHU_FIELD_TYPE' as const;
const TOGGLE_DATA_CHAIN_STORE_SHU_CONTAINS_AREA_AVERAGE = 'TOGGLE_DATA_CHAIN_STORE_SHU_CONTAINS_AREA_AVERAGE' as const;

const CLEAR_DATA_CHAIN_STORE_SHU = 'CLEAR_DATA_CHAIN_STORE_SHU' as const;
const CLEAR_DATA_CHAIN_STORE_SHU_SUMMARY = 'CLEAR_DATA_CHAIN_STORE_SHU_SUMMARY' as const;
const CLEAR_ALL_DATA_CHAIN_STORE_SHU = 'CLEAR_ALL_DATA_CHAIN_STORE_SHU' as const;
const CLEAR_DATA_CHAIN_STORE_WITHOUT_COLUMN_ORDER_CHAIN_STORE_SHU = 'CLEAR_DATA_CHAIN_STORE_WITHOUT_COLUMN_ORDER_CHAIN_STORE_SHU' as const;

const SELECT_DATA_CHAIN_STORE_SHU_COLUMNS_ORDER = 'SELECT_DATA_CHAIN_STORE_SHU_COLUMNS_ORDER' as const;
const SELECT_DATA_CHAIN_STORE_SHU_COLUMNS_ORDER_ALL = 'SELECT_DATA_CHAIN_STORE_SHU_COLUMNS_ORDER_ALL' as const;
const CHANGE_DATA_CHAIN_STORE_SHU_COLUMNS_ORDER = 'CHANGE_DATA_CHAIN_STORE_SHU_COLUMNS_ORDER' as const;

const RENEW_DATA_CHAIN_STORE_SHU = 'RENEW_DATA_CHAIN_STORE_SHU' as const;

export const DataChainStoreShuActionTypes = {
  FETCH_DATA_CHAIN_STORE_SHU,
  FETCH_DATA_CHAIN_STORE_SHU_REQUEST,
  FETCH_DATA_CHAIN_STORE_SHU_SUCCESS,
  FETCH_DATA_CHAIN_STORE_SHU_SUMMARY,
  FETCH_DATA_CHAIN_STORE_SHU_SUMMARY_REQUEST,
  FETCH_DATA_CHAIN_STORE_SHU_SUMMARY_SUCCESS,
  INIT_DATA_CHAIN_STORE_SHU,
  SEARCH_DATA_CHAIN_STORE_SHU,
  SEARCH_DATA_CHAIN_STORE_SHU_RESET,
  SEARCH_DATA_CHAIN_STORE_SHU_FAVORITE,
  SEARCH_DATA_CHAIN_STORE_SHU_SORT,
  SEARCH_DATA_CHAIN_STORE_SHU_MARKING,
  SEARCH_DATA_CHAIN_STORE_SHU_FIELD_TYPE,
  TOGGLE_DATA_CHAIN_STORE_SHU_CONTAINS_AREA_AVERAGE,
  CLEAR_DATA_CHAIN_STORE_SHU,
  CLEAR_DATA_CHAIN_STORE_SHU_SUMMARY,
  CLEAR_ALL_DATA_CHAIN_STORE_SHU,
  CLEAR_DATA_CHAIN_STORE_WITHOUT_COLUMN_ORDER_CHAIN_STORE_SHU,
  CHANGE_DATA_CHAIN_STORE_SHU_COLUMNS_ORDER,
  SELECT_DATA_CHAIN_STORE_SHU_COLUMNS_ORDER,
  SELECT_DATA_CHAIN_STORE_SHU_COLUMNS_ORDER_ALL,
  RENEW_DATA_CHAIN_STORE_SHU,
};

/**
 * チェーン店レポート 種別実績テーブルを取得する
 * @param shuCode 種別コード
 * @param params 検索条件
 */
function fetchDataChainStoreShuAction(
  shuCode: string,
  params: DataChainStoreShuParams
) {
  return {
    type: FETCH_DATA_CHAIN_STORE_SHU,
    payload: { shuCode, params },
  };
}

/**
 * チェーン店レポート 種別実績テーブル取得用APIにリクエスト前に呼ばれるもの
 */
function fetchDataChainStoreShuRequestAction(shuCode: string) {
  return {
    type: FETCH_DATA_CHAIN_STORE_SHU_REQUEST,
    payload: { shuCode },
  };
}

/**
 * チェーン店レポート 種別実績テーブル取得完了時データを更新する
 * @param shuCode 種別コード
 * @param dataChainStoreShu 種別実績データ
 */
function fetchDataChainStoreShuSuccessAction(
  shuCode: string,
  dataChainStoreShu: DataChainStoreShu
) {
  return {
    type: FETCH_DATA_CHAIN_STORE_SHU_SUCCESS,
    payload: { shuCode, dataChainStoreShu },
  };
}

/**
 * チェーン店レポート 種別実績Summaryを取得する
 * @param shuCode 種別コード
 * @param params 検索条件
 */
function fetchDataChainStoreShuSummaryAction(
  shuCode: string,
  params: DataChainStoreShuSummaryParams
) {
  return {
    type: FETCH_DATA_CHAIN_STORE_SHU_SUMMARY,
    payload: { shuCode, params },
  };
}

/**
 * チェーン店レポート 種別実績Summary取得用APIにリクエスト前に呼ばれるもの
 * @param shuCode 種別コード
 */
function fetchDataChainStoreShuSummaryRequestAction(shuCode: string) {
  return {
    type: FETCH_DATA_CHAIN_STORE_SHU_SUMMARY_REQUEST,
    payload: { shuCode },
  };
}

/**
 * チェーン店レポート 種別実績Summary取得完了時データを更新する
 * @param shuCode 種別コード
 * @param dataChainStoreShuSummary 種別実績Summaryデータ
 */
function fetchDataChainStoreShuSummarySuccessAction(
  shuCode: string,
  dataChainStoreShuSummary: DataChainStoreShuSummary
) {
  return {
    type: FETCH_DATA_CHAIN_STORE_SHU_SUMMARY_SUCCESS,
    payload: { shuCode, dataChainStoreShuSummary },
  };
}

/**
 * チェーン店レポート 種別実績を初回取得する
 * @param shuOption 種別情報
 */
function initDataChainStoreShuAction(shuOption: ShuOption) {
  return {
    type: INIT_DATA_CHAIN_STORE_SHU,
    payload: { shuOption },
  };
}

/**
 * 現在表示されている種別実績を指定した検索条件で再取得する（検索用）
 * @param params 検索フォームの検索条件
 */
function searchDataChainStoreShuAction(params: ChainReportsFormConditions) {
  return {
    type: SEARCH_DATA_CHAIN_STORE_SHU,
    payload: { params },
  };
}

/**
 * 現在表示されている種別実績を初回の検索条件で再取得する（リセット用）
 */
function searchDataChainStoreShuResetAction() {
  return {
    type: SEARCH_DATA_CHAIN_STORE_SHU_RESET,
  };
}
/**
 * 種別実績をお気に入りの検索条件で取得する
 */
function searchDataChainStoreShuFavoriteAction(
  favorite: ChainStoreReportsFavorite
) {
  return {
    type: SEARCH_DATA_CHAIN_STORE_SHU_FAVORITE,
    payload: { favorite },
  };
}

/**
 * 指定したソート条件で種別実績を再取得する
 * @param shuOption 種別データ
 * @param sort ソートする条件
 * @param order ソート順
 */
function searchDataChainStoreShuSortAction(
  shuOption: ShuOption,
  sort: string,
  order: OrderType
) {
  return {
    type: SEARCH_DATA_CHAIN_STORE_SHU_SORT,
    payload: { shuOption, sort, order },
  };
}

/**
 * 指定したマーキング条件で種別実績を再取得する
 * @param shuOption 種別データ
 * @param markingOption マーキング条件
 * @param isFiltering 該当行のみ表示するか
 */
function searchDataChainStoreShuMarkingAction(
  shuOption: ShuOption,
  markingOption: MarkingOption,
  isFiltering: boolean
) {
  return {
    type: SEARCH_DATA_CHAIN_STORE_SHU_MARKING,
    payload: { shuOption, markingOption, isFiltering },
  };
}

/**
 * 指定した表示項目に絞って種別実績を再取得する
 * @param shuOption 種別データ（
 * @param fields 選択した表示項目
 */
function searchDataChainStoreShuFieldTypeAction(
  shuOption: ShuOption,
  fields: Option[]
) {
  return {
    type: SEARCH_DATA_CHAIN_STORE_SHU_FIELD_TYPE,
    payload: { shuOption, fields },
  };
}

/**
 * 種別実績のデータにエリア平均を含めるかどうかを切り替える
 */
function toggleDataChainStoreShuContainsAreaAverageAction() {
  return {
    type: TOGGLE_DATA_CHAIN_STORE_SHU_CONTAINS_AREA_AVERAGE,
  };
}

/**
 * 指定した種別実績のデータを破棄する
 */
function clearDataChainStoreShuAction(shuCode: string) {
  return {
    type: CLEAR_DATA_CHAIN_STORE_SHU,
    payload: { shuCode },
  };
}

/**
 * 指定した種別実績Summaryのデータを破棄する
 */
function clearDataChainStoreShuSummaryAction(shuCode: string) {
  return {
    type: CLEAR_DATA_CHAIN_STORE_SHU_SUMMARY,
    payload: { shuCode },
  };
}

/**
 * 種別実績のデータを全て破棄する
 */
function clearAllDataChainStoreShuAction() {
  return {
    type: CLEAR_ALL_DATA_CHAIN_STORE_SHU,
  };
}

/**
 * 種別項目並び順以外の種別実績のデータを全て破棄する
 */
function clearDataChainStoreShuActionWithoutColumnOrderByShuCodes(
  shuCodes: string[]
) {
  return {
    type: CLEAR_DATA_CHAIN_STORE_WITHOUT_COLUMN_ORDER_CHAIN_STORE_SHU,
    payload: { shuCodes },
  };
}

/**
 * 種別実績の並び替え情報を登録する（Sagaで並び替え確定時）
 * @param shuCode 種別コード
 * @param columnsOrder 並び替え情報
 */
function selectDataChainStoreShuColumnsOrderAction(
  shuCode: string,
  columnsOrder: string[]
) {
  return {
    type: SELECT_DATA_CHAIN_STORE_SHU_COLUMNS_ORDER,
    payload: { shuCode, columnsOrder },
  };
}

/**
 * 種別実績の並び替え情報を全て登録する（お気に入り適用時に使用）
 * @param columnsOrders 並び替え情報（全件）
 */
function selectDataChainStoreShuColumnsOrderAllAction(
  columnsOrders: DataChainStoreShuState['columnsOrder']
) {
  return {
    type: SELECT_DATA_CHAIN_STORE_SHU_COLUMNS_ORDER_ALL,
    payload: { columnsOrders },
  };
}

/**
 * 種別実績の列の並び替え実行時にSagaで再計算する
 * @param shuCode 種別コード
 * @param columnsOrder 並び替え情報
 */
function changeDataChainStoreShuColumnsOrderAction(
  shuCode: string,
  draggedId?: string,
  droppedId?: string
) {
  return {
    type: CHANGE_DATA_CHAIN_STORE_SHU_COLUMNS_ORDER,
    payload: { shuCode, draggedId, droppedId },
  };
}

/**
 * 初期化
 */
function renewDataChainStoreShuAction() {
  return {
    type: RENEW_DATA_CHAIN_STORE_SHU,
  };
}

export const DataChainStoreShuActionCreators = {
  fetchDataChainStoreShuAction,
  fetchDataChainStoreShuRequestAction,
  fetchDataChainStoreShuSuccessAction,
  fetchDataChainStoreShuSummaryAction,
  fetchDataChainStoreShuSummaryRequestAction,
  fetchDataChainStoreShuSummarySuccessAction,
  initDataChainStoreShuAction,
  searchDataChainStoreShuAction,
  searchDataChainStoreShuResetAction,
  searchDataChainStoreShuFavoriteAction,
  searchDataChainStoreShuSortAction,
  searchDataChainStoreShuMarkingAction,
  searchDataChainStoreShuFieldTypeAction,
  toggleDataChainStoreShuContainsAreaAverageAction,
  clearDataChainStoreShuAction,
  clearDataChainStoreShuSummaryAction,
  clearAllDataChainStoreShuAction,
  clearDataChainStoreShuActionWithoutColumnOrderByShuCodes,
  selectDataChainStoreShuColumnsOrderAction,
  selectDataChainStoreShuColumnsOrderAllAction,
  changeDataChainStoreShuColumnsOrderAction,
  renewDataChainStoreShuAction,
};

export type FetchDataChainStoreShuAction = ReturnType<
  typeof fetchDataChainStoreShuAction
>;
export type FetchDataChainStoreShuSummaryAction = ReturnType<
  typeof fetchDataChainStoreShuSummaryAction
>;
export type FetchDataChainStoreShuSuccessAction = ReturnType<
  typeof fetchDataChainStoreShuSuccessAction
>;
export type InitDataChainStoreShuAction = ReturnType<
  typeof initDataChainStoreShuAction
>;
export type SearchDataChainStoreShuAction = ReturnType<
  typeof searchDataChainStoreShuAction
>;
type SearchDataChainStoreShuResetAction = ReturnType<
  typeof searchDataChainStoreShuResetAction
>;
export type SearchDataChainStoreShuSortAction = ReturnType<
  typeof searchDataChainStoreShuSortAction
>;
export type SearchDataChainStoreShuMarkingAction = ReturnType<
  typeof searchDataChainStoreShuMarkingAction
>;
export type SearchDataChainStoreShuFieldTypeAction = ReturnType<
  typeof searchDataChainStoreShuFieldTypeAction
>;
type ToggleDataChainStoreShuContainsAreaAverageAction = ReturnType<
  typeof toggleDataChainStoreShuContainsAreaAverageAction
>;
type ClearDataChainStoreShuAction = ReturnType<
  typeof clearDataChainStoreShuAction
>;
type ClearDataChainStoreShuSummaryAction = ReturnType<
  typeof clearDataChainStoreShuSummaryAction
>;
type ClearAllDataChainStoreShuAction = ReturnType<
  typeof clearAllDataChainStoreShuAction
>;
type ClearAllDataWithoutColumnOrderChainStoreShuAction = ReturnType<
  typeof clearDataChainStoreShuActionWithoutColumnOrderByShuCodes
>;
type SelectDataChainStoreShuColumnsOrderAction = ReturnType<
  typeof selectDataChainStoreShuColumnsOrderAction
>;
type SelectDataChainStoreShuColumnsOrderAllAction = ReturnType<
  typeof selectDataChainStoreShuColumnsOrderAllAction
>;
export type ChangeDataChainStoreShuColumnsOrderAction = ReturnType<
  typeof changeDataChainStoreShuColumnsOrderAction
>;

export type SearchDataChainStoreShuFavoriteAction = ReturnType<
  typeof searchDataChainStoreShuFavoriteAction
>;

type DataChainStoreShuAction =
  | FetchDataChainStoreShuAction
  | FetchDataChainStoreShuSummaryAction
  | FetchDataChainStoreShuSuccessAction
  | InitDataChainStoreShuAction
  | SearchDataChainStoreShuAction
  | SearchDataChainStoreShuResetAction
  | SearchDataChainStoreShuSortAction
  | SearchDataChainStoreShuMarkingAction
  | SearchDataChainStoreShuFieldTypeAction
  | ToggleDataChainStoreShuContainsAreaAverageAction
  | ClearDataChainStoreShuAction
  | ClearDataChainStoreShuSummaryAction
  | ClearAllDataChainStoreShuAction
  | ClearAllDataWithoutColumnOrderChainStoreShuAction
  | SelectDataChainStoreShuColumnsOrderAction
  | SelectDataChainStoreShuColumnsOrderAllAction
  | ChangeDataChainStoreShuColumnsOrderAction
  | SearchDataChainStoreShuFavoriteAction
  | ReturnType<typeof fetchDataChainStoreShuRequestAction>
  | ReturnType<typeof fetchDataChainStoreShuSummaryRequestAction>
  | ReturnType<typeof fetchDataChainStoreShuSummarySuccessAction>
  | ResetChainStoreShuAction
  | ReturnType<typeof renewDataChainStoreShuAction>;

export type DataChainStoreShuState = {
  dataChainStoreShu: {
    [key: string]: DataChainStoreShu | undefined;
  };
  dataChainStoreShuSummary: {
    [key: string]: DataChainStoreShuSummary | undefined;
  };
  dataChainStoreShuIsLoading: {
    [key: string]: boolean | undefined;
  };
  dataChainStoreShuSummaryIsLoading: {
    [key: string]: boolean | undefined;
  };
  columnsOrder: {
    [key: string]: string[] | undefined;
  };
  areasAverage: {
    [key: string]: DataChainStoreShu | undefined;
  };
};

const initialState: DataChainStoreShuState = {
  dataChainStoreShu: {},
  dataChainStoreShuSummary: {},
  dataChainStoreShuIsLoading: {},
  dataChainStoreShuSummaryIsLoading: {},
  columnsOrder: {},
  areasAverage: {},
};

/**
 * チェーン店レポート 種別実績テーブルデータを全て取得する
 * @returns 全ての種別実績テーブルデータ
 */
export const dataChainStoreShuAllSelector = (state: RootState) =>
  state.dataChainStoreShu.dataChainStoreShu;

/**
 * チェーン店レポート 種別実績のローディング状態を全て取得する
 * @returns 全てのローディング状態
 */
export const dataChainStoreShuLoadingAllSelector = (state: RootState) =>
  state.dataChainStoreShu.dataChainStoreShuIsLoading;

/**
 * チェーン店レポート 種別タブの全テーブルのローディング状態を全て取得する
 *
 * 一つでもローディング状態のテーブルがあればtrueとなる
 */
export const dataChainStoreShuIsAllLoadingSelector = createSelector(
  [dataChainStoreShuLoadingAllSelector, dataChainStoreShuWholeLoadingSelector],
  (all, whole) => [...Object.values(all), whole].some((item) => !!item)
);

/**
 * チェーン店レポート 種別実績Summaryデータを全て取得する
 * @returns 全ての種別実績Summaryデータ
 */
export const dataChainStoreShuSummaryAllSelector = (state: RootState) =>
  state.dataChainStoreShu.dataChainStoreShuSummary;

/**
 * チェーン店レポート 種別実績の特定の種別のデータを取得する
 * @param shuCode 種別コード
 * @returns 種別実績のデータ
 */
const dataChainStoreShuByShuSelector = (shuCode: string) =>
  createSelector(
    dataChainStoreShuAllSelector,
    (dataChainStoreShu) => dataChainStoreShu[shuCode]
  );

/**
 * 種別実績のテーブルデータのカラム部分（カラム行）のみ取得
 * @param shuCode 種別コード
 * @returns テーブルデータのカラム行（rowsを除いた部分）
 */
export const dataChainStoreShuDataColumnsSelector = (shuCode: string) =>
  createSelector(
    dataChainStoreShuByShuSelector(shuCode),
    (data) => data?.data.columns ?? []
  );

/**
 * 種別実績の全ての並び替え情報を取得
 * @returns 全ての並び替え情報
 */
export const dataChainStoreShuColumnsOrderDataSelector = (state: RootState) =>
  state.dataChainStoreShu.columnsOrder;

/**
 * 指定した種別の並び替え情報を取得
 * @param shuCode 種別コード
 * @returns 並び替え情報
 */
export const dataChainStoreShuColumnsOrderSelector = (shuCode: string) =>
  createSelector(
    dataChainStoreShuColumnsOrderDataSelector,
    (columnsOrder) => columnsOrder[shuCode]
  );

/**
 * 並び替え後のテーブルデータを取得する
 * @param shuCode 種別コード
 * @returns 並び替え後のテーブルデータ
 */
export const dataChainStoreShuOrderedData = (shuCode: string) =>
  createSelector(
    [
      dataChainStoreShuDataByShuSelector(shuCode),
      dataChainStoreShuColumnsOrderSelector(shuCode),
    ],
    (tableData, ordered) => {
      if (!ordered) return tableData.data;

      return getOrderedTableData(
        ordered,
        tableData.data.columns,
        tableData.data.rows
      );
    }
  );

/**
 * チェーン店レポート 種別実績の特定のローディング状態を取得する
 * @param shuCode: string
 * @returns ローディング状態
 */
export const dataChainStoreShuLoadingByShuSelector = (shuCode: string) =>
  createSelector(
    dataChainStoreShuLoadingAllSelector,
    (isLoading) => isLoading[shuCode] ?? false
  );

/**
 * 指定した種別の種別実績データが存在するか取得する
 * @param shu 種別データ（種別グループ・種別）
 * @returns 存在するか（存在する場合: true）
 */
export const dataChainStoreShuDataIsExistSelector = (shuCode: string) =>
  createSelector(dataChainStoreShuAllSelector, (dataChainStoreShu) => {
    return !!dataChainStoreShu[shuCode];
  });

/**
 * チェーン店レポート 種別実績Summaryの特定の種別のデータを取得する
 * @param 種別コード
 * @returns 種別実績Summaryのデータ
 */
export const dataChainStoreShuSummaryByShuSelector = (shuCode: string) =>
  createSelector(
    dataChainStoreShuSummaryAllSelector,
    (dataChainStoreShuSummary) => dataChainStoreShuSummary[shuCode]
  );

/**
 * 指定した種別と一致する種別実績データを取得する
 * @param shuCode 種別コード
 * @returns 種別実績データ
 */
export const dataChainStoreShuDataByShuSelector = (shuCode: string) =>
  createSelector(
    [dataChainStoreShuAllSelector, chainStoreReportsSearchConditionSelector],
    (dataChainStoreShu, searchCondition) => {
      const defaultValue: DataChainStoreShu = {
        setting: { ...searchCondition },
        data: {
          columns: [],
          rows: [],
        },
      };

      return dataChainStoreShu[shuCode] ?? defaultValue;
    }
  );

/**
 * 指定した種別の種別実績データの検索条件を取得する
 * @param shuCode 種別コード
 * @returns 現在の検索条件
 */
export const dataChainStoreShuDataSearchConditionSelector = (shuCode: string) =>
  createSelector(dataChainStoreShuDataByShuSelector(shuCode), (data) => {
    return data.setting;
  });

/**
 * 指定した種別の種別実績で選択されている表示項目を取得する
 * @param shuCode 種別コード
 * @returns 選択中の表示項目
 */
export const dataChainStoreShuSelectedFieldsSelector = (shuCode: string) =>
  createSelector(
    dataChainStoreShuDataSearchConditionSelector(shuCode),
    (searchCondition) => searchCondition.fields ?? []
  );

/**
 * 指定した種別の種別実績で適用されているマーキングを取得する
 * @param shuCode 種別コード
 * @returns 適用中のマーキング
 */
export const dataChainStoreShuSelectedMarkingSelector = (shuCode: string) =>
  createSelector(
    dataChainStoreShuDataSearchConditionSelector(shuCode),
    (searchCondition) => searchCondition.marking ?? ''
  );

/**
 * 指定した種別の種別実績でフィルタリング設定が適用されているか取得する
 * @param shuCode 種別コード
 * @returns フィルタリング設定が有効か
 */
export const dataChainStoreShuIsFilteringSelector = (shuCode: string) =>
  createSelector(
    dataChainStoreShuDataSearchConditionSelector(shuCode),
    (searchCondition) => searchCondition.isFiltering ?? false
  );

/**
 * 種別実績テーブルの現在の検索条件を取得する（お気に入り保存用）
 * @returns 現在の種別実績テーブルの検索条件
 */
export const dataChainStoreShuSettingSelector = createSelector(
  dataChainStoreShuAllSelector,
  (data) => {
    return Object.fromEntries(
      Object.entries(data).map((entry) => {
        const [key, value] = entry;
        return [key, value?.setting];
      })
    );
  }
);

/**
 * 種別実績Summaryの現在の検索条件を取得する（お気に入り保存用）
 * @returns 現在の種別実績Summaryの検索条件
 */
export const dataChainStoreShuSummarySettingSelector = createSelector(
  dataChainStoreShuSummaryAllSelector,
  (data) => {
    return Object.fromEntries(
      Object.entries(data).map((entry) => {
        const [key, value] = entry;
        return [key, value?.setting];
      })
    );
  }
);

/**
 * データのある種別グループコードを返す
 */
export const dataChainStoreShuGroupCodesWithDataSelector = createSelector(
  dataChainStoreShuAllSelector,
  (data) =>
    Object.keys(data).filter((x) => {
      const dataByShuCode = data[x];
      if (!dataByShuCode) {
        return false;
      }
      return dataByShuCode.data.rows?.length > 0;
    })
);

export function dataChainStoreShuReducer(
  state = initialState,
  action: DataChainStoreShuAction
): DataChainStoreShuState {
  switch (action.type) {
    case FETCH_DATA_CHAIN_STORE_SHU_REQUEST:
      return {
        ...state,
        dataChainStoreShuIsLoading: {
          ...state.dataChainStoreShuIsLoading,
          [action.payload.shuCode]: true,
        },
      };
    case FETCH_DATA_CHAIN_STORE_SHU_SUCCESS:
      return {
        ...state,
        dataChainStoreShuIsLoading: {
          ...state.dataChainStoreShuIsLoading,
          [action.payload.shuCode]: false,
        },
        dataChainStoreShu: {
          ...state.dataChainStoreShu,
          [action.payload.shuCode]: action.payload.dataChainStoreShu,
        },
      };
    case FETCH_DATA_CHAIN_STORE_SHU_SUMMARY_REQUEST:
      return {
        ...state,
        dataChainStoreShuSummaryIsLoading: {
          ...state.dataChainStoreShuSummaryIsLoading,
          [action.payload.shuCode]: true,
        },
      };
    case FETCH_DATA_CHAIN_STORE_SHU_SUMMARY_SUCCESS:
      return {
        ...state,
        dataChainStoreShuSummaryIsLoading: {
          ...state.dataChainStoreShuSummaryIsLoading,
          [action.payload.shuCode]: false,
        },
        dataChainStoreShuSummary: {
          ...state.dataChainStoreShuSummary,
          [action.payload.shuCode]: action.payload.dataChainStoreShuSummary,
        },
      };
    case CLEAR_DATA_CHAIN_STORE_SHU:
      return {
        ...state,
        dataChainStoreShuIsLoading: {
          ...state.dataChainStoreShuIsLoading,
          [action.payload.shuCode]: false,
        },
        dataChainStoreShu: {
          ...state.dataChainStoreShu,
          [action.payload.shuCode]: undefined,
        },
      };
    case CLEAR_DATA_CHAIN_STORE_SHU_SUMMARY:
      return {
        ...state,
        dataChainStoreShuSummaryIsLoading: {
          ...state.dataChainStoreShuSummaryIsLoading,
          [action.payload.shuCode]: false,
        },
        dataChainStoreShuSummary: {
          ...state.dataChainStoreShuSummary,
          [action.payload.shuCode]: undefined,
        },
      };
    case CLEAR_ALL_DATA_CHAIN_STORE_SHU:
      return initialState;
    case CLEAR_DATA_CHAIN_STORE_WITHOUT_COLUMN_ORDER_CHAIN_STORE_SHU: {
      // 表示しない種別のデータを破棄する
      const columnsOrder =
        action.payload.shuCodes.length > 0
          ? Object.fromEntries(
              Object.entries(state.columnsOrder).filter(([key]) =>
                action.payload.shuCodes.includes(key)
              )
            )
          : state.columnsOrder;
      return {
        ...initialState,
        columnsOrder,
      };
    }
    case SELECT_DATA_CHAIN_STORE_SHU_COLUMNS_ORDER:
      return {
        ...state,
        columnsOrder: {
          ...state.columnsOrder,
          [action.payload.shuCode]: action.payload.columnsOrder,
        },
      };
    case SELECT_DATA_CHAIN_STORE_SHU_COLUMNS_ORDER_ALL:
      return {
        ...state,
        columnsOrder: action.payload.columnsOrders,
      };
    case ChainStoreSettingActionTypes.RESET_CHAIN_STORE_REPORTS_SHU: {
      // お気に入りでデフォルト適用時に列の並びを初期化する
      return {
        ...state,
        columnsOrder: initialState.columnsOrder,
      };
    }
    case RENEW_DATA_CHAIN_STORE_SHU:
      return initialState;
    default:
      return state;
  }
}
