export const validateQuery = (query: {
  ymdList?: string[];
  ymdComparisonList?: string[];
  startDate?: string | null;
  endDate?: string | null;
  startComparisonDate?: string | null;
  endComparisonDate?: string | null;
}): void => {
  // 日付が入力されていない場合バックエンドのデフォルト値で検索されるが、
  // バックエンドのデフォルト値はフロントエンドと異なるデフォルト値のためエラーとする
  if (
    query.ymdList == null &&
    query.startDate == null &&
    query.endDate == null &&
    query.ymdComparisonList == null &&
    query.startComparisonDate == null &&
    query.endComparisonDate == null
  ) {
    throw new Error('期間指定のクエリが指定されていません');
  }

  // startDateが利用されるときには必ずendDateとペアになる
  if (
    (query.startDate == null && !!query.endDate) ||
    (!!query.startDate && query.endDate == null)
  ) {
    throw new Error('期間指定のクエリでstartとendがペアになっていません');
  }

  // startComparisonDateが利用されるときには必ずendComparisonDateとペアになる
  if (
    (query.startComparisonDate == null && !!query.endComparisonDate) ||
    (!!query.startComparisonDate && query.endComparisonDate == null)
  ) {
    throw new Error(
      '期間指定のクエリでstartComparisonとendComparisonがペアになっていません'
    );
  }

  /**
   * 土日祝のみの検索が無効な形式であればtrue
   *
   * 平日/土日祝比較で平日(ymdList)がundefinedになる場合、
   * 検索対象は土日祝になるためstartDate/endDateは必ずnullになる(undefinedではなくnullである点に注意)
   * 以下のようなクエリが有効な形式となります
   * ```
   * {
   *   ymdList: undefined,
   *   ymdComparisonList: ['2023-01-01'],
   *   startDate: null,
   *   endDate: null,
   * }
   * ```
   */
  const isInvalidOnlyHoliday =
    query.ymdList == null &&
    !!query.ymdComparisonList &&
    (query.startDate !== null || query.endDate !== null);

  if (isInvalidOnlyHoliday) {
    throw new Error(
      '比較区分が平日/土日祝で検索期間が土日祝のみになる場合、startDate/endDateは必ずnullである必要があります'
    );
  }

  /**
   * 平日のみの検索が無効な形式であればtrue
   *
   * 平日/土日祝比較で土日祝(ymdList)がundefinedになる場合、
   * 検索対象は平日になるためstartComparisonDate/endComparisonDateは必ずnullになる(undefinedではなくnullである点に注意)
   * 以下のようなクエリが有効な形式となります
   * ```
   * {
   *   ymdList: ['2023-01-01'],
   *   ymdComparisonList: undefined,
   *   startComparisonDate: null,
   *   endComparisonDate: null,
   * }
   * ```
   */
  const isInvalidOnlyWeekday =
    !!query.ymdList &&
    query.ymdComparisonList == null &&
    (query.startComparisonDate !== null || query.endComparisonDate !== null);

  if (isInvalidOnlyWeekday) {
    throw new Error(
      '比較区分が平日/土日祝で検索期間が平日のみになる場合、startComparisonDate/endComparisonDateは必ずnullである必要があります'
    );
  }
};
