import { AxiosResponse } from 'axios';
import { format, parse, startOfToday, startOfYesterday } from 'date-fns';
import { call, fork, put, select, takeEvery } from 'redux-saga/effects';

import { DataKiParams } from '../domain/dataKi';
import {
  DataKiGraph,
  DataKiGraphParams,
  GRAPH_DATE_TYPE,
  GRAPH_FIELD_TYPE,
  GraphDateType,
  GraphFieldType,
} from '../domain/dataKiGraph';
import { KiTagListOptions } from '../domain/schemas';

import {
  dataKiFilteredKiTagAverageSelector,
  dataKiParamsSelector,
} from '../redux/server/dataKi';
import { DataKiDaiActionCreators } from '../redux/server/dataKiDai';
import {
  ChangeDataKiGraphDkSisSearchParamsAction,
  ChangeDateTypeAction,
  ChangeFieldAction,
  DataKiGraphActionCreators,
  DataKiGraphActionTypes,
  FetchDataKiGraphAction,
  FetchDataKiGraphFromFavoriteAction,
  dataKiGraphDateTypeSelector,
  dataKiGraphFieldSelector,
  dataKiGraphParamsSelector,
} from '../redux/server/dataKiGraph';
import { DataKiKasidamaActionCreators } from '../redux/server/dataKiKasidama';
import { DataKiMksTransitiveGraphActionCreators } from '../redux/server/dataKiMksTransitiveGraph';
import { ModelReportsSettingActionCreators } from '../redux/ui/modelReportsSetting';
import { Api, buildConfig } from '../utils/api';
import { dataKiGraphSearchConditionParams } from '../utils/dataKiGraphSearchConditionParams';
import { calcStartDateFromDateType } from '../utils/date';
import { handleErrorSaga } from './errorSaga';

/**
 * 推移グラフ（店舗比較）表示ボタンをクリックされた行の情報を元に推移グラフ（店舗比較）の検索条件を加工する
 * @param queryParams クリックされたメインフィールドの行（店舗行じゃない方）
 * @param searchParams 機種集計の検索条件
 * @returns 加工された検索条件
 */
export const queryParameterToGraphParams = (
  searchParams: DataKiParams
): DataKiGraphParams => {
  const params: DataKiGraphParams = {
    field: GRAPH_FIELD_TYPE.OUT,
    seriesType: 'hall',
    containsAverage: true,
    dateType: GRAPH_DATE_TYPE.DAILY,
    // 現在の検索条件を混ぜ込む
    ...{
      hallsForDonyuJokyo: searchParams.hallsForDonyuJokyo,
      halls: searchParams.halls,
      areas: searchParams.areas,
      startDate: searchParams.ymdList && searchParams.ymdList[0],
      endDate: searchParams.ymdList && searchParams.ymdList.slice(-1)[0],
      shuList: searchParams.shuList,
      shuGroupIds: searchParams.shuGroupIds,
    },
  };

  // 現在の検索条件を混ぜ込む
  if (searchParams.isDonyuForDonyuJokyo !== undefined) {
    params.isDonyuForDonyuJokyo = searchParams.isDonyuForDonyuJokyo;
  }
  if (searchParams.excludeToday !== undefined) {
    params.excludeToday = searchParams.excludeToday;
  }

  return params;
};

/**
 * パラメータを元に推移グラフ（店舗比較）データを取得する
 * @param api AxiosInstace
 * @param params 検索条件（DataKiGraphParams）
 */
export function* getDataKiGraphSaga(api: Api, params: DataKiGraphParams) {
  try {
    // 現在の検索条件を取得
    const searchParams: DataKiParams = yield select(dataKiParamsSelector);

    // 現在の期間（最終日）を取得する
    const searchEndDate =
      params.endDate == null
        ? startOfToday()
        : parse(params.endDate, 'yyyy-MM-dd', new Date());
    // 当日除外かつ今日を含んでいる場合は今日より前の日付から算出する
    const endDate =
      searchParams.excludeToday && searchEndDate >= startOfToday()
        ? startOfYesterday()
        : searchEndDate;

    // 開始期間を算出
    const startDate = calcStartDateFromDateType(params.dateType, endDate);

    // 他のデータがあれば、同時に表示することがないためクリアする
    yield put(DataKiDaiActionCreators.hideDataKiDai());
    yield put(DataKiKasidamaActionCreators.hideDataKiKasidama());
    yield put(
      DataKiMksTransitiveGraphActionCreators.hideDataKiMksTransitiveGraphAction()
    );

    const response: AxiosResponse<DataKiGraph> = yield call(
      api.get,
      '/data/ki/graph',
      buildConfig({
        ...params,
        startDate: format(startDate, 'yyyy-MM-dd'),
        endDate: format(endDate, 'yyyy-MM-dd'),
      })
    );

    yield put(
      DataKiGraphActionCreators.fetchDataKiGraphSuccessAction(response.data)
    );
  } catch (error: unknown) {
    yield put(DataKiGraphActionCreators.renewDataKiGraphAction());
    yield fork(handleErrorSaga, error);
  }
}

// 現在の検索条件を元に推移グラフ（店舗比較）データを取得する（初回取得）
export function* fetchDataKiGraphSaga(
  api: Api,
  action: FetchDataKiGraphAction
) {
  // 現在の検索条件を取得
  const searchParams: DataKiParams = yield select(dataKiParamsSelector);
  // 選択中の表示項目と表示期間を取得
  const field: GraphFieldType = yield select(dataKiGraphFieldSelector);
  const dateType: GraphDateType = yield select(dataKiGraphDateTypeSelector);
  const onlyOneKiTagAverageTagIds = action.payload.onlyOneKiTagAverageTagIds; // 各機種タグ平均合計行から推移グラフを表示する際に利用するタグID
  // 選択された機種タグ一覧を取得するために、filteredKiTagAverageからvalue値を取得
  const tags: {
    value: KiTagListOptions[];
  } = yield select(dataKiFilteredKiTagAverageSelector);

  const filteredTags = onlyOneKiTagAverageTagIds
    ? tags.value.filter(
        (tag) => tag.tagId.toString() === onlyOneKiTagAverageTagIds.at(0)
      )
    : tags.value;

  // tagsのkiListを取得（重複は排除する）
  const kiListFromTags = Array.from(
    new Set(filteredTags.map((tag) => tag.kiList).flat())
  );

  const fetchedDataKiGraphSearchCondition = dataKiGraphSearchConditionParams(
    action.payload.queryParams,
    action.payload.requestParams,
    searchParams,
    onlyOneKiTagAverageTagIds,
    kiListFromTags
  );

  // 推移グラフ（店舗比較）の取得条件を指定
  const graphParams = {
    ...queryParameterToGraphParams(searchParams),
    ...action.payload.dkSisSearchParams,
    ...fetchedDataKiGraphSearchCondition,
  };
  graphParams.field = field;
  graphParams.dateType = dateType;

  // 機種タグ平均合計行の推移グラフから選択された場合に、推移グラフ機種セレクタに「タグ絞り込み平均合計」を表示するための条件
  if (!action.payload.isSelectedFromMainField) {
    // 機種タグ平均合計行の推移グラフ機種セレクタに「タグ絞り込み平均合計」を選択した場合、推移グラフ機種セレクタに表示する平均合計行を正しく表示するための条件
    yield put(
      ModelReportsSettingActionCreators.selectModelReportsIsKiTagAverageGraphAction(
        action.payload.queryParams.value === 'kiTagAverage'
      )
    );
    // 各機種タグ平均合計行の推移グラフ機種セレクタに「タグ絞り込み平均合計」を選択した場合に、推移グラフ機種セレクタに表示する平均合計行を正しく表示するための条件
    yield put(
      ModelReportsSettingActionCreators.selectModelReportsIsOnlyOneKiTagAverageGraphAction(
        action.payload.queryParams.value ===
          `onlyOneKiTagAverage_${onlyOneKiTagAverageTagIds?.at(0)?.toString()}`
      )
    );
  }

  // 機種タグ平均合計行の推移グラフから選択された場合、かつ機種タグ平均合計行の機種セレクタで選択された場合に、dk-sis情報などをレスポンスとして受け取らないための条件
  // https://github.com/DKClaris/claris-general/issues/3652
  if (action.payload.queryParams.value === 'kiTagAverage') {
    graphParams.tagIds = tags.value.map((tag) => tag.tagId.toString());
    graphParams.needsSis = false;
  }

  // 各機種タグ平均合計行の推移グラフから選択された場合、かつ各機種タグ平均合計行の機種セレクタで選択された場合に、dk-sis情報などをレスポンスとして受け取らないための条件
  // https://github.com/DKClaris/claris-general/issues/3734
  if (
    action.payload.queryParams.value ===
    `onlyOneKiTagAverage_${onlyOneKiTagAverageTagIds?.at(0)?.toString()}`
  ) {
    graphParams.needsSis = false;
  }

  // 推移グラフ押下時や推移グラフから機種セレクタを選択した際に、どの機種タグ平均合計行の機種タグから選択されたかを判断するための設定
  if (onlyOneKiTagAverageTagIds) {
    graphParams.tagIds = onlyOneKiTagAverageTagIds.map((tag) => tag.toString());
  }

  // 選択中の店舗を登録
  yield put(
    DataKiGraphActionCreators.changeSelectHallsAction(
      action.payload.selectHalls
    )
  );

  // 選択中の機種行がどの「メーカーID・ホールID」を持つのかを判断するために登録
  if (action.payload.requestParams) {
    yield put(
      ModelReportsSettingActionCreators.changeSelectedDataKiExpandedRowAction(
        action.payload.requestParams.mainFieldId,
        action.payload.requestParams.hallId,
        '3rdRow'
      )
    );
  } else {
    yield put(
      ModelReportsSettingActionCreators.changeSelectedDataKiExpandedRowAction(
        '',
        '',
        '1stRow'
      )
    );
  }

  // 推移グラフ（店舗比較）を取得
  yield call(getDataKiGraphSaga, api, graphParams);
}

// グラフ用の検索条件を指定して推移グラフ（店舗比較）データを取得する（お気に入り適用）
function* fetchDataKiGraphForFavoriteSaga(
  api: Api,
  action: FetchDataKiGraphFromFavoriteAction
) {
  // 推移グラフ（店舗比較）を取得
  yield call(getDataKiGraphSaga, api, action.payload.params);
}

// 指定されたグラフ項目でグラフを再取得
function* changeFieldSaga(api: Api, action: ChangeFieldAction) {
  const searchCondition: DataKiGraphParams | undefined = yield select(
    dataKiGraphParamsSelector
  ); // 現在の検索条件

  // グラフデータを取得し直す
  yield call(getDataKiGraphSaga, api, {
    ...searchCondition,
    field: action.payload.field,
  });
}

// 指定された期間でグラフを再取得
function* changeDateTypeSaga(api: Api, action: ChangeDateTypeAction) {
  const searchCondition: DataKiGraphParams | undefined = yield select(
    dataKiGraphParamsSelector
  ); // 現在の検索条件

  // グラフデータを取得し直す
  yield call(getDataKiGraphSaga, api, {
    ...searchCondition,
    dateType: action.payload.dateType,
  });
}

// 指定されたDK-SIS条件でグラフを再取得
function* changeDkSisSearchParamsSaga(
  api: Api,
  action: ChangeDataKiGraphDkSisSearchParamsAction
) {
  const searchGraphParams: DataKiGraphParams | undefined = yield select(
    dataKiGraphParamsSelector
  ); // 現在の検索条件
  if (!searchGraphParams) {
    return;
  }

  // https://app.zenhub.com/workspaces/claris-60b56db504e3ff00150ebc22/issues/gh/dkclaris/claris-general/2421
  // 推移グラフのDK-SIS条件を変更するときには、グラフデータが存在していることを前提としているので、データがない場合には変更処理を実行しない
  // ※主に、お気に入り適用時にバグを発生させないための処置
  if (!searchGraphParams) return;

  // グラフデータを取得し直す
  yield call(getDataKiGraphSaga, api, {
    ...searchGraphParams,
    ...action.payload.params,
  });
}

// CHANGE_DATA_KI_GRAPH_FIELDがdispatchされたタイミングで推移グラフ（店舗比較）を再取得
function* handleChangeFieldSaga(api: Api) {
  yield takeEvery(
    DataKiGraphActionTypes.CHANGE_DATA_KI_GRAPH_FIELD,
    changeFieldSaga,
    api
  );
}

// CHANGE_DATA_KI_GRAPH_DATE_TYPEがdispatchされたタイミングで推移グラフ（店舗比較）を再取得
function* handleChangeDateTypeSaga(api: Api) {
  yield takeEvery(
    DataKiGraphActionTypes.CHANGE_DATA_KI_GRAPH_DATE_TYPE,
    changeDateTypeSaga,
    api
  );
}

// FETCH_DATA_KI_GRAPHがdispatchされたタイミングで推移グラフ（店舗比較）を取得
function* handleFetchDataKiGraphSaga(api: Api) {
  yield takeEvery(
    DataKiGraphActionTypes.FETCH_DATA_KI_GRAPH,
    fetchDataKiGraphSaga,
    api
  );
}

// FETCH_DATA_KI_GRAPHがdispatchされたタイミングで推移グラフ（店舗比較）を取得
function* handleFetchDataKiGraphForFavoriteSaga(api: Api) {
  yield takeEvery(
    DataKiGraphActionTypes.FETCH_DATA_KI_GRAPH_FROM_FAVORITE,
    fetchDataKiGraphForFavoriteSaga,
    api
  );
}

// CHANGE_DATA_KI_GRAPH_DK_SIS_SEARCH_PARAMSがdispatchされたタイミングで推移グラフ（店舗比較）を再取得
function* handleChangeDkSisSearchParamsSaga(api: Api) {
  yield takeEvery(
    DataKiGraphActionTypes.CHANGE_DATA_KI_GRAPH_DK_SIS_SEARCH_PARAMS,
    changeDkSisSearchParamsSaga,
    api
  );
}

// 推移グラフ（店舗比較）に関するタスクを実行
export function* dataKiGraphSagas(context: { api: Api }) {
  yield fork(handleFetchDataKiGraphSaga, context.api);
  yield fork(handleFetchDataKiGraphForFavoriteSaga, context.api);
  yield fork(handleChangeFieldSaga, context.api);
  yield fork(handleChangeDateTypeSaga, context.api);
  yield fork(handleChangeDkSisSearchParamsSaga, context.api);
}
