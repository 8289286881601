import { createSelector } from 'reselect';

import { LoadingState } from '../../domain/schemas';
import { SettingsOptionsModelTransition } from '../../domain/settingsOptionsModelTransition';

import { RootState } from '../../store';
import { settingsOptionsModelTransitionDefaultValue } from '../../utils/settingsOptionsModelTransitionDefaultValue';
import { dataModelTransitionSettingAreasSelector } from './dataModelTransition';

// Action Types

const FETCH_SETTINGS_OPTIONS_MODEL_TRANSITION = 'FETCH_SETTINGS_OPTIONS_MODEL_TRANSITION' as const;
const FETCH_SETTINGS_OPTIONS_MODEL_TRANSITION_REQUEST = 'FETCH_SETTINGS_OPTIONS_MODEL_TRANSITION_REQUEST' as const;
const FETCH_SETTINGS_OPTIONS_MODEL_TRANSITION_SUCCESS = 'FETCH_SETTINGS_OPTIONS_MODEL_TRANSITION_SUCCESS' as const;
const RENEW_SETTINGS_OPTIONS_MODEL_TRANSITION = 'RENEW_SETTINGS_OPTIONS_MODEL_TRANSITION' as const;

export const SettingsOptionsModelTransitionActionTypes = {
  FETCH_SETTINGS_OPTIONS_MODEL_TRANSITION,
  FETCH_SETTINGS_OPTIONS_MODEL_TRANSITION_REQUEST,
  FETCH_SETTINGS_OPTIONS_MODEL_TRANSITION_SUCCESS,
  RENEW_SETTINGS_OPTIONS_MODEL_TRANSITION,
};

// Action Creators

function fetchSettingsOptionsModelTransitionAction() {
  return {
    type: FETCH_SETTINGS_OPTIONS_MODEL_TRANSITION,
  };
}

function fetchSettingsOptionsModelTransitionRequestAction() {
  return {
    type: FETCH_SETTINGS_OPTIONS_MODEL_TRANSITION_REQUEST,
  };
}

function fetchSettingsOptionsModelTransitionSuccessAction(
  settingsOptionsModelTransition: SettingsOptionsModelTransition
) {
  return {
    type: FETCH_SETTINGS_OPTIONS_MODEL_TRANSITION_SUCCESS,
    payload: { settingsOptionsModelTransition },
  };
}

function renewSettingsOptionsModelTransitionAction() {
  return {
    type: RENEW_SETTINGS_OPTIONS_MODEL_TRANSITION,
  };
}

export const SettingsOptionsModelTransitionActionCreators = {
  fetchSettingsOptionsModelTransitionAction,
  fetchSettingsOptionsModelTransitionRequestAction,
  fetchSettingsOptionsModelTransitionSuccessAction,
  renewSettingsOptionsModelTransitionAction,
};

// Actions

type SettingsOptionsModelTransitionAction =
  | ReturnType<typeof fetchSettingsOptionsModelTransitionAction>
  | ReturnType<typeof fetchSettingsOptionsModelTransitionRequestAction>
  | ReturnType<typeof fetchSettingsOptionsModelTransitionSuccessAction>
  | ReturnType<typeof renewSettingsOptionsModelTransitionAction>;

// State

type SettingsOptionsModelTransitionState = {
  loadingState: LoadingState;
  settingsOptionsModelTransition?: SettingsOptionsModelTransition;
};

const initialState: SettingsOptionsModelTransitionState = {
  loadingState: 'prepare',
  settingsOptionsModelTransition: undefined,
};

// Selector

// settingsOptionsを全て取得する
export const settingsOptionsModelTransitionSelector = (state: RootState) => {
  const settings =
    state.settingsOptionsModelTransition.settingsOptionsModelTransition;
  // MEMO: コンポーネント側がundefined許容しないため、空の場合空データを返す
  return settings ? settings : settingsOptionsModelTransitionDefaultValue;
};

/**
 * 表示項目のみ取得
 */
export const settingsOptionsModelTransitionFieldsSelector = createSelector(
  settingsOptionsModelTransitionSelector,
  (settingsOptionsModelTransition) => {
    return settingsOptionsModelTransition.fields;
  }
);

/**
 * 機種別推移の表示項目のFieldsのisNewがtrueのものがあるかどうかを取得する
 */
export const settingsOptionsModelTransitionFieldsIsNewSelector = createSelector(
  settingsOptionsModelTransitionFieldsSelector,
  (fields) => {
    return [...fields.transition, ...fields.nonTransition].some(
      (field) => field.isNew
    );
  }
);

/**
 * ローディング状態を取得
 */
export const settingsOptionsModelTransitionLoadingStateSelector = (
  state: RootState
) => {
  return state.settingsOptionsModelTransition.loadingState;
};

// SearchConditionだけ取得する
export const settingsOptionsModelTransitionSearchConditionSelector = createSelector(
  settingsOptionsModelTransitionSelector,
  (settingsOptionsModelTransition) => {
    // MEMO: コンポーネント側がundefined許容しないため、空の場合空データを返す
    return settingsOptionsModelTransition
      ? settingsOptionsModelTransition.searchCondition
      : settingsOptionsModelTransitionDefaultValue.searchCondition;
  }
);

// フィルター用のジャンル一覧を取得する
export const settingsOptionsModelTransitionGenresSelector = createSelector(
  settingsOptionsModelTransitionSelector,
  (settingsOptionsModelTransition) => {
    // MEMO: コンポーネント側がundefined許容しないため、空の場合空データを返す
    if (settingsOptionsModelTransition === undefined)
      return settingsOptionsModelTransitionDefaultValue.genres.transition;

    return settingsOptionsModelTransition.genres.transition;
  }
);

/**
 * グラフに表示可能なfieldsのみ取得
 */
export const settingsOptionsModelTransitionGraphFieldsSelector = createSelector(
  settingsOptionsModelTransitionFieldsSelector,
  (fields) => {
    return fields.transition.filter((field) => field.isSelectableForGraph);
  }
);

/**
 * 検索されたエリアのホール一覧を取得する
 */
export const settingsOptionsModelTransitionSearchConditionHallsBySearchedAreaSelector = createSelector(
  [
    settingsOptionsModelTransitionSearchConditionSelector,
    dataModelTransitionSettingAreasSelector,
  ],
  ({ halls, areas }, selectedAreaIds) => {
    if (!selectedAreaIds) {
      return halls ?? [];
    }
    const hallCodesInAreas = areas
      .filter((x) => selectedAreaIds.includes(x.id))
      .flatMap((x) => x.halls);

    return halls.filter((x) => hallCodesInAreas.includes(x.code));
  }
);

// Reducer

export function settingsOptionsModelTransitionReducer(
  state = initialState,
  action: SettingsOptionsModelTransitionAction
): SettingsOptionsModelTransitionState {
  switch (action.type) {
    case FETCH_SETTINGS_OPTIONS_MODEL_TRANSITION_REQUEST:
      return {
        ...state,
        loadingState: 'loading',
      };
    case FETCH_SETTINGS_OPTIONS_MODEL_TRANSITION_SUCCESS:
      return {
        loadingState: 'loaded',
        settingsOptionsModelTransition:
          action.payload.settingsOptionsModelTransition,
      };
    case RENEW_SETTINGS_OPTIONS_MODEL_TRANSITION:
      return initialState;
    default:
      return state;
  }
}
