import { AxiosResponse } from 'axios';
import { call, fork, put, takeEvery } from 'redux-saga/effects';

import { ModeData } from '../domain/mode/types';

import {
  DataModeActionCreators,
  DataModeActionTypes,
  FetchDataModeAction,
} from '../redux/server/dataMode';
import { Api, buildConfig } from '../utils/api';
import { handleErrorSaga } from './errorSaga';

function* fetchDataModeSaga(api: Api, action: FetchDataModeAction) {
  try {
    yield put(DataModeActionCreators.fetchDataModeRequestAction());

    const response: AxiosResponse<ModeData> = yield call(
      api.get,
      '/data/mode',
      buildConfig(action.payload.params)
    );

    yield put(DataModeActionCreators.fetchDataModeSuccessAction(response.data));
  } catch (error: unknown) {
    yield put(DataModeActionCreators.renewDataModeAction());
    yield fork(handleErrorSaga, error);
  }
}

function* handleFetchModeSaga(api: Api) {
  yield takeEvery(DataModeActionTypes.FETCH_DATA_MODE, fetchDataModeSaga, api);
}

export function* dataModeSagas(context: { api: Api }) {
  yield fork(handleFetchModeSaga, context.api);
}
