import { AxiosResponse } from 'axios';
import { call, fork, put, takeEvery } from 'redux-saga/effects';

import { SettingsOptionsHall } from '../domain/settingsOptionsHall';

import {
  SettingsOptionsHallActionCreators,
  SettingsOptionsHallActionTypes,
} from '../redux/server/settingsOptionsHall';
import { Api, buildConfig } from '../utils/api';
import { handleErrorSaga } from './errorSaga';

export function* fetchSettingsOptionsHallSaga(api: Api) {
  try {
    yield put(
      SettingsOptionsHallActionCreators.fetchSettingsOptionsHallRequestAction()
    );
    const response: AxiosResponse<SettingsOptionsHall> = yield call(
      api.get,
      '/settings/options/hall',
      buildConfig()
    );
    const settingsOptionsHall: SettingsOptionsHall = response.data;
    yield put(
      SettingsOptionsHallActionCreators.fetchSettingsOptionsHallSuccessAction(
        settingsOptionsHall
      )
    );
  } catch (error: unknown) {
    yield put(
      SettingsOptionsHallActionCreators.renewSettingsOptionsHallAction()
    );
    yield fork(handleErrorSaga, error);
  }
}

function* handleFetchSettingsOptionsHallSaga(api: Api) {
  yield takeEvery(
    SettingsOptionsHallActionTypes.FETCH_SETTINGS_OPTIONS_HALL,
    fetchSettingsOptionsHallSaga,
    api
  );
}

export function* settingsOptionsHallSagas(context: { api: Api }) {
  yield fork(handleFetchSettingsOptionsHallSaga, context.api);
}
