import { AxiosResponse } from 'axios';
import { call, fork, put, takeEvery } from 'redux-saga/effects';

import { DataSis } from '../domain/sis/types';

import {
  DataSisActionCreators,
  DataSisActionTypes,
  FetchDataSisAction,
} from '../redux/server/dataSis';
import { Api, buildConfig } from '../utils/api';
import { handleErrorSaga } from './errorSaga';

/**
 * dataSisのデータを取得する
 */
function* fetchDataSisSaga(api: Api, action: FetchDataSisAction) {
  try {
    yield put(DataSisActionCreators.fetchDataSisRequestAction());

    const response: AxiosResponse<DataSis> = yield call(
      api.get,
      '/data/sis',
      buildConfig(action.payload.params)
    );

    const dataSis = response.data;

    yield put(DataSisActionCreators.fetchDataSisSuccessAction(dataSis));
  } catch (error: unknown) {
    yield put(DataSisActionCreators.renewDataSis());
    yield fork(handleErrorSaga, error);
  }
}

function* handleFetchDataSisSaga(api: Api) {
  yield takeEvery(DataSisActionTypes.FETCH_DATA_SIS, fetchDataSisSaga, api);
}

export function* dataSisSaga(context: { api: Api }) {
  yield fork(handleFetchDataSisSaga, context.api);
}
