import { format } from 'date-fns';

import {
  DataTerminalTransition,
  transitiveDateTypes,
} from '../domain/dataTerminalTransition';
import { TerminalTransitionReportsSettingDateRangeParams } from '../domain/terminalTransitionReportsSettingDateRangeParams';

import {
  dialyTransitiveDateRanges,
  makeDateForDailyFromDateRange,
} from './transitiveDateRange';

// 期間推移のデフォルトデータ
export const dataTerminalTransitionDefaultValue = (): {
  setting: Partial<DataTerminalTransition['setting']>;
  data: DataTerminalTransition['data'];
  dateRangeParams: TerminalTransitionReportsSettingDateRangeParams;
} => {
  const current = new Date();
  const dateType = transitiveDateTypes[0]; // 日次
  const dateRange = dialyTransitiveDateRanges[1]; // 今月

  // 指定された期間から日付を算出する
  const date = makeDateForDailyFromDateRange(dateRange, current);
  const startDate = format(date.startDate, 'yyyy-MM-dd');
  const endDate = format(date.endDate, 'yyyy-MM-dd');

  return {
    setting: {
      startDate,
      endDate,
      isFiltering: false,
      excludeToday: true,
    },
    data: {
      columnsForNonTransition: [],
      columnsForTransition: [],
      dates: [],
      rows: [],
    },
    dateRangeParams: {
      dateType,
      dateRange,
    },
  };
};
