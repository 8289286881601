import type { ComponentProps, FC } from 'react';

type Props = ComponentProps<'svg'>;

export const CashCowIcon: FC<Props> = (props) => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 9C18.4853 9 20.5 6.98528 20.5 4.5C20.5 2.01472 18.4853 0 16 0C13.5147 0 11.5 2.01472 11.5 4.5C11.5 6.98528 13.5147 9 16 9ZM17.5981 4.46909V4.89835H16.3582L16.3541 4.90555V5.46841H17.5981V5.89423H16.3541V7H15.6192V5.89423H14.402V5.46841H15.6192V4.89835H14.402V4.46909H15.4052L14 2H14.8307L16.0057 4.27693L17.1847 2H18L16.6014 4.46909H17.5981ZM15.3095 10.022L15.629 10.485H13.726L17.532 16.194H10.8437V20H7.08409V16.194H0.5L4.20411 10.485H2.35206L8.96851 0.967773L10.4085 3.02587C10.3658 3.32261 10.3441 3.622 10.3437 3.9218C10.3476 5.35355 10.8429 6.74058 11.7467 7.85096C12.6507 8.96133 13.9083 9.7277 15.3095 10.022Z"
      fill="black"
      fillOpacity="0.54"
    />
  </svg>
);
