import {
  MAIN_FIELD_TYPE,
  MainFieldType,
  QueryParameter,
} from '../domain/schemas';

/**
 * チェーン店一覧を表示するために必要なパラメータを作成
 *
 * チェーン店一覧を表示するために、検索時に詳細条件で指定した「機種,SISタイプ,メーカー」は支持率系項目の計算に必要な情報な為、パラメータとしてそれぞれ「kiListForSijiritu,sisTypesForSijiritu,makersForSijiritu」を付与する必要がある。
 * また、選択された行によって「kiList,sisTypes,makers」を付与する必要がある。
 * 仕様：https://www.notion.so/Forsijiritu-e432054f16b343e6934364a27d1cc410
 */
export const dataKiKasidamaSearchConditionParams = <
  T extends {
    mainField?: MainFieldType;
    kiList?: string[];
    sisTypes?: string[];
    makers?: string[];
    kiListForSijiritu?: string[];
    sisTypesForSijiritu?: string[];
    makersForSijiritu?: string[];
  }
>(
  mainField: QueryParameter,
  dataKi1stRow: QueryParameter | undefined,
  searchCondition: T
) => {
  // 詳細条件で設定された機種やSISタイプ、メーカーの支持率計算のために必要なパラメータ
  // タイプやメーカー行、もしくは機種行で選択されたチェーン店一覧か判断するため
  const searchConditionForTypes = {
    makers: searchCondition.makers,
    sisTypes: searchCondition.sisTypes,
    kiListForSijiritu: searchCondition.kiListForSijiritu,
    sisTypesForSijiritu: searchCondition.sisTypesForSijiritu,
    makersForSijiritu: searchCondition.makersForSijiritu,
    [mainField.name]: [mainField.value],
  };

  // 機種行を選択したときの選択されたタイプ(makers[])やメーカー(sisTypes[])を把握
  // 出力がタイプ・メーカーの場合に、どのタイプやメーカーから選択された機種行なのかを判別するため
  if (dataKi1stRow) {
    return {
      ...searchConditionForTypes,
      [dataKi1stRow.name]: [dataKi1stRow.value],
    };
  }
  // 出力がタイプ・メーカーで、機種行ではない箇所で選択された場合
  if (searchCondition.mainField !== MAIN_FIELD_TYPE.KI_TUSHO_MEI) {
    return {
      ...searchConditionForTypes,
      kiList: searchCondition.kiList,
    };
  }

  return {
    ...searchConditionForTypes,
    kiList: [mainField.value],
  };
};
