import { format, isAfter } from 'date-fns';
import {
  all,
  call,
  cancel,
  fork,
  put,
  select,
  take,
  takeEvery,
} from 'redux-saga/effects';

import { SettingsNationalHolidays } from '../domain/SettingsNationalHolidays';
import { DataKiParams } from '../domain/dataKi';
import { DataKiDaiParams } from '../domain/dataKiDai';
import { DataKiGraphParams } from '../domain/dataKiGraph';
import { DataKiKasidamaParams } from '../domain/dataKiKasidama';
import type { DataKiMksTransitiveGraphData } from '../domain/dataKiMksTransitiveGraph';
import { DkSisSearchParams } from '../domain/dkSis';
import { FavoriteItem } from '../domain/favorites';
import { kiDateRanges } from '../domain/ki/consistent';
import { searchConditionToDateRangeParams } from '../domain/ki/date';
import {
  defaultDateRangeParams,
  generateInitialResponse,
} from '../domain/ki/defaultValues';
import {
  SearchType,
  calculateOptimalDkSisPeriod,
  getSearchTypeFromDateRange,
} from '../domain/ki/dksisDate';
import { KiReportsSettingDateRangeParams } from '../domain/ki/types';
import { ModelReportsFavorite } from '../domain/ki/types';
import { MODEL_REPORT_MARKING_CONDITIONS } from '../domain/marking';
import { ModelReportsFormConditions } from '../domain/modelReportsFormConditions';
import {
  Column,
  KiTagListOptions,
  LoadingState,
  MAIN_FIELD_TYPE,
  QueryParameter,
  SearchCondition,
} from '../domain/schemas';
import { ShuOption } from '../domain/shu';

import { SIS_TYPE_UPDATE_DATES } from '../consistents';
import {
  DataKiActionCreators,
  DataKiActionTypes,
  dataKiColumnsOrderSelector,
  dataKiFilteredKiTagAverageSelector,
  dataKiLoadingSelector,
  dataKiParamsSelector,
  dataKiRawCheckedListSelector,
  dataKiSelectedKiListSelector,
  dataKiSettingShuGroupIdSelector,
  dataKiSettingShuIdSelector,
} from '../redux/server/dataKi';
import {
  DataKi2ndRowActionCreators,
  dataKi2ndRowParamsSelector,
} from '../redux/server/dataKi2ndRow';
import {
  DataKi3rdRowActionCreators,
  dataKi3rdRowParamsSelector,
} from '../redux/server/dataKi3rdRow';
import {
  DataKiDaiActionCreators,
  dataKiDaiParamsSelector,
  dataKiDaiTableDataColumnsSelector,
} from '../redux/server/dataKiDai';
import {
  DataKiGraphActionCreators,
  dataKiGraphParamsSelector,
  dataKiGraphSelectHallsSelector,
} from '../redux/server/dataKiGraph';
import {
  DataKiKasidamaActionCreators,
  dataKiKasidamaParamsSelector,
  dataKiKasidamaTableDataColumnsSelector,
} from '../redux/server/dataKiKasidama';
import {
  DataKiMksTransitiveGraphActionCreators,
  dataKiMksTransitionGraphGraphSettingSelector,
} from '../redux/server/dataKiMksTransitiveGraph';
import {
  SettingsNationalHolidaysActionCreators,
  SettingsNationalHolidaysActionTypes,
  settingsNationalHolidaysSelector,
} from '../redux/server/settingsNationalHolidays';
import {
  SettingsOptionsKiActionCreators,
  SettingsOptionsKiActionTypes,
  settingsOptionsKiLatestBaseDateForMonthlySisDataSelector,
  settingsOptionsKiLatestBaseDateForWeeklySisDataSelector,
  settingsOptionsKiSearchConditionSelector,
} from '../redux/server/settingsOptionsKi';
import { ShortenedUrlActionCreators } from '../redux/server/shortenedUrl';
import { ErrorActionCreators } from '../redux/ui/error';
import {
  ChangeModelReportsCurrentHallsAction,
  ChangeModelReportsCurrentShuAction,
  ChangeModelReportsFavoriteAction,
  CreateShortenedUrlAction,
  InitModelReportsAction,
  ModelReportsSettingActionCreators,
  ModelReportsSettingActionTypes,
  ModelReportsSettingState,
  SaveAsModelReportsFavoriteAction,
  SaveModelReportsFavoriteAction,
  SearchModelReportsAction,
  SearchModelReportsDateRangeSlideAction,
  SearchModelReportsDkSisAction,
  SearchModelReportsFieldAction,
  SearchModelReportsMarkingAction,
  SearchModelReportsSortAction,
  modelReportsMksTransitiveGraphSelectedMksHallCodesSelector,
  modelReportsMksTransitiveGraphShowGraphNumberLabelSelector,
  modelReportsModelNameFilterSelector,
  modelReportsSearchConditionSelector,
  modelReportsSelectedCurrentShuSelector,
  modelReportsSelectedDaiColumnsOrderSelector,
  modelReportsSelectedDateRangeParamsSelector,
  modelReportsSelectedFavoriteDataSelector,
  modelReportsSelectedFavoriteSelector,
  modelReportsSelectedFavoriteSettingSelector,
  modelReportsSelectedKasidamaColumnsOrderSelector,
  modelReportsSelectedShuSelector,
  modelReportsSelectedTableFilterSelector,
  modelReportsSettingCurrentHallsSelector,
  modelReportsSettingDkSisSearchParamsSelector,
  modelReportsSettingSelector,
  modelReportsSettingisDkSisOnSelector,
  modelReportsShowKiTagSelector,
  modelReportsShowNumberLabelSelector,
  modelReportsSubMenuNumberFilterSelector,
  resetModelReportsSubMenuNumberFilterAction,
  searchModelReportsAction,
  searchModelReportsSelectedKiGraphDkSisCondition,
  searchModelReportsSelectedKiKasidamaDkSisCondition,
  selectModelReportsDaiColumnsOrderAction,
  selectModelReportsKasidamaColumnsOrderAction,
  selectModelReportsModelNameFilterAction,
  selectModelReportsSubMenuNumberFilterAction,
  selectedDataKiExpandedRowSelector,
} from '../redux/ui/modelReportsSetting';
import { SettingsFavoritesActionCreators } from '../redux/ui/settingsFavorites';
import { adjustedFields } from '../utils/adjustedFields';
import { compressToEncodedURIComponent } from '../utils/compressToEncodedURIComponent';
import {
  calcEndDateWithExcludeToday,
  calcStartDateFromDateType,
} from '../utils/date';
import { gaFavoriteEvent } from '../utils/googleAnalytics';
import { getDefaultDkSisSearchConditionValue } from '../utils/graphConditionSelectorUtils';
import { isOpenModelReportsFavorite } from '../utils/isOpenModelReportsFavorite';
import { isValidArea } from '../utils/isValidArea';
import { checkCurrentShu, getShuOption } from '../utils/shu';
import { getDefaultDkSisSearchConditionKasidamaValue } from '../utils/sisSelectBoxUtils';
import { updateSisTypesV1 } from '../utils/updateSisTypes';

/**
 * お気に入り選択時、検索条件に反映する
 * @param action Action
 */
function* applyFavoriteByIdSaga(action: ChangeModelReportsFavoriteAction) {
  const favoriteItem: FavoriteItem | undefined = yield select(
    modelReportsSelectedFavoriteDataSelector
  );
  // 選択されたお気に入りID
  const selectedFavoriteId = action.payload.favorite;

  // デフォルトのお気に入りを選択した場合、リセットと同じ動作を行う
  if (selectedFavoriteId === undefined || favoriteItem === undefined) {
    yield put(
      ModelReportsSettingActionCreators.searchResetModelReportsAction()
    );
    yield put(
      ModelReportsSettingActionCreators.resetModelReportsTableFilterAction()
    );
    return;
  }

  // 機種集計のデータを初期化する
  yield fork(initModelReportsDataSaga);
  yield put(DataKiActionCreators.resetDataKiCheckedListAction());

  // 現在選択中のお気に入りデータを取得
  const favorite: ModelReportsFavorite | undefined = yield select(
    modelReportsSelectedFavoriteSettingSelector
  );

  if (favorite == null) {
    throw new Error('該当するお気に入りが見つかりません');
  }

  const lastUpdateAt = favoriteItem.updatedAt ?? favoriteItem.createdAt;

  if (lastUpdateAt == null) {
    throw new Error('お気に入りの作成日が存在しません');
  }

  yield fork(applyFavoriteSaga, favorite, lastUpdateAt, selectedFavoriteId);
}

/**
 * ModelReportsFavoriteの内容を実際に反映させる
 */
export function* applyFavoriteSaga(
  favorite: ModelReportsFavorite,
  lastUpdatedAt: string,
  favoriteId?: number
) {
  // MEMO:
  // (favorite.kiTags && favorite.kiTags.length > 0)
  // settingsOptionsKiの機種タグ情報を元に機種タグの平均合計行を取得しているため、
  // 機種タグを選択している場合はsettingsOptionsKiを取得する必要がある
  //
  // favorite.searchCondition.sisFieldTypes
  // DK-SISが有効な場合、ユーザーが操作していないときにデフォルト値であった場合に
  // settingsOptionsKiを取得する必要がある
  //
  // 毎回取得が不要な場合は条件を追加する
  // settingsOptionsKiでデフォルト値が設定されてしまっているため、デフォルト値をundefinedに変更する必要があります
  if (
    (favorite.kiTags && favorite.kiTags.length > 0) ||
    favorite.searchCondition.sisFieldTypes ||
    favorite.showKiTag
  ) {
    yield put(SettingsOptionsKiActionCreators.fetchSettingsOptionsKiAction());
    // MEMO: 取得まで待機
    yield take(SettingsOptionsKiActionTypes.FETCH_SETTINGS_OPTIONS_KI_SUCCESS);
  }

  const loadingState: LoadingState = yield select(dataKiLoadingSelector);

  // 画面リンクなどから直接アクセスされた場合、settingsOptionsKiが取得されていない場合があるため、取得する
  if (loadingState === 'prepare') {
    yield put(SettingsOptionsKiActionCreators.fetchSettingsOptionsKiAction());
  }

  // 機種名フィルタを変更
  yield put(selectModelReportsModelNameFilterAction(favorite.modelNameFilter));

  // サブメニュー内数値フィルタを変更
  // 一度サブメニュー内数値フィルタをクリア
  yield put(resetModelReportsSubMenuNumberFilterAction());

  yield put(
    selectModelReportsSubMenuNumberFilterAction(favorite.subMenuNumberFilter)
  );

  // 期間を適用する
  const dateRange = favorite.selectedDateRangeParams.dateRange;
  // dateUnit実装前のお気に入りを考慮
  const dateUnit =
    favorite.selectedDateRangeParams.dateUnit ??
    defaultDateRangeParams.dateUnit;
  // 比較区分実装前のお気に入りを考慮
  const comparisonSection =
    favorite.selectedDateRangeParams.comparisonSection ??
    kiDateRanges.find(({ name }) => name === dateRange)
      ?.defaultComparisonSection ??
    'カスタム';

  yield put(
    ModelReportsSettingActionCreators.selectModelReportsDateRangeParamsAction({
      dateRange,
      dateUnit,
      comparisonSection,
    })
  );

  const nationalHolidays: SettingsNationalHolidays | undefined = yield select(
    settingsNationalHolidaysSelector
  );

  // 比較区分で平日/土日祝比較の場合、祝日情報がない場合は取得する
  if (comparisonSection === '平日/土日祝比較' && nationalHolidays == null) {
    yield put(
      SettingsNationalHolidaysActionCreators.fetchSettingsNationalHolidaysAction()
    );
    yield take(
      SettingsNationalHolidaysActionTypes.FETCH_SETTINGS_NATIONAL_HOLIDAYS_SUCCESS
    );
  }

  // 画面リンクのときにundefinedになる
  const updatedNationalHolidays:
    | SettingsNationalHolidays
    | undefined = yield select(settingsNationalHolidaysSelector);

  // 検索条件の期間部分を再計算
  const dateRangeParams = searchConditionToDateRangeParams(
    dateRange,
    comparisonSection,
    favorite.searchCondition,
    { holidays: updatedNationalHolidays?.holidays }
  );

  // DK-SISの期間部分を再計算
  const dkSisDateRangeParams: DkSisSearchParams = favorite.searchCondition
    .sisFieldTypes
    ? yield call(dkSisDateRangeCalc, dateRangeParams, favorite)
    : {};

  // isAutoが存在しない場合は、isAutoをTrueをする
  if (favorite.dkSis == null || favorite.dkSis?.isAuto == null) {
    yield put(
      ModelReportsSettingActionCreators.selectSearchModelReportsDkSisIsAutoAction(
        true
      )
    );
  }

  // ymdList対応以前に作成したお気に入りを考慮
  const {
    startDate,
    endDate,
    startComparisonDate,
    endComparisonDate,
    ...rest
  } = favorite.searchCondition;

  // 検索条件を適用する
  const searchConditions: ModelReportsFormConditions = {
    ...rest,
    // 期間を再算出して上書きする
    ...dateRangeParams,
    ...dkSisDateRangeParams,
    sisTypes: isAfter(
      new Date(lastUpdatedAt),
      new Date(SIS_TYPE_UPDATE_DATES.v1)
    )
      ? rest.sisTypes
      : updateSisTypesV1(rest.sisTypes),
    sisTypesForSijiritu: isAfter(
      new Date(lastUpdatedAt),
      new Date(SIS_TYPE_UPDATE_DATES.v1)
    )
      ? rest.sisTypesForSijiritu
      : updateSisTypesV1(rest.sisTypesForSijiritu),
    mainField: rest.mainField ?? MAIN_FIELD_TYPE.KI_TUSHO_MEI,
  };
  yield put(
    ModelReportsSettingActionCreators.selectModelReportsSearchConditionAction(
      searchConditions
    )
  );
  // 選択された種別を適用する
  yield put(
    ModelReportsSettingActionCreators.selectModelReportsShuAction(
      favorite.selectedShu
    )
  );
  // 表示中の種別を適用する
  yield put(
    ModelReportsSettingActionCreators.selectModelReportsCurrentShuAction(
      favorite.currentShu
    )
  );
  // チェック済みの機種リストを反映
  yield put(
    DataKiActionCreators.addDataKiListCheckedListAction(
      favorite.dataKi?.checkedKiList ?? []
    )
  );

  // フィルターで選択中の機種リストに反映
  yield put(
    DataKiActionCreators.selectDataKiListAction(favorite.dataKi?.kiList ?? [])
  );

  if (!isValidArea(favorite.searchCondition.areas)) {
    yield put(
      ErrorActionCreators.setError(
        `お気に入りの検索条件に存在しないエリアが指定されています。\n正しいエリアを選択して検索を再度行い、お気に入りに保存してください。`
      )
    );
  }

  // DK-SISの設定を反映
  if (favorite.dkSis?.isAuto != null) {
    yield put(
      ModelReportsSettingActionCreators.selectSearchModelReportsDkSisIsAutoAction(
        favorite.dkSis.isAuto
      )
    );
  }

  // 機種集計のsearchConditionの検索条件を取得
  // 機種タグ情報抽出のため
  const settingsOptionsKiSearchCondition: SearchCondition = yield select(
    settingsOptionsKiSearchConditionSelector
  );

  // お気に入り登録時から機種タグ情報が更新された場合の処理
  const findSettingKiTags =
    favorite.kiTags && settingsOptionsKiSearchCondition.kiTagList
      ? (favorite.kiTags
          .map((kiTag) =>
            settingsOptionsKiSearchCondition.kiTagList?.find(
              (item) => kiTag.tagId === item.tagId
            )
          )
          .filter((findItem) => findItem !== undefined) as KiTagListOptions[])
      : [];

  // マーキング（該当行のみを表示）の確認
  // マーキング（該当行のみを表示）機能を実行した後に、機種タグ設定が設定されていると適切に機種タグ平均合計行の値を表示できないため
  // https://github.com/DKClaris/claris-general/issues/3138
  const isMarkingFilter =
    favorite.searchCondition.isFiltering &&
    favorite.searchCondition.marking !== '';

  // 機種タグの情報を更新、GAイベントを送信
  if (
    favorite.kiTags?.length !== 0 &&
    findSettingKiTags.length !== 0 &&
    !isMarkingFilter
  ) {
    yield put(
      DataKiActionCreators.selectDataKiFilteredKiTagAverageAction(
        findSettingKiTags
      )
    );
    if (favoriteId != null) {
      gaFavoriteEvent('機種タグ絞込', {
        event_favorite_id: favoriteId,
        event_favorite_page: '機種集計',
        event_click_value: findSettingKiTags
          .map((tag) => tag.tagName)
          .join(','),
        event_click_state: 'ON',
      });
    }
  } else {
    yield put(DataKiActionCreators.resetDataKiFilteredKiTagAverageAction());
    yield put(
      DataKiActionCreators.resetSelectDataKiFilteredKiTagAverageAction()
    );
  }

  // 機種行への機種タグ表示情報を復元
  if (favorite.showKiTag !== undefined) {
    yield put(
      ModelReportsSettingActionCreators.selectShowKiTagAction(
        favorite.showKiTag
      )
    );
  } else {
    // 過去のお気に入りは機種タグを表示しない
    yield put(ModelReportsSettingActionCreators.selectShowKiTagAction(false));
  }

  // 店舗選択スライダーの店舗を反映
  yield put(
    ModelReportsSettingActionCreators.selectModelReportsSelectedHallsAction(
      favorite.selectedHalls ?? favorite.dataKi?.setting.halls ?? []
    )
  );

  // 店舗選択スライダーで選択中の店舗を反映
  yield put(
    ModelReportsSettingActionCreators.selectModelReportsCurrentHallsAction(
      //currentHallsが存在しない場合は、検索条件で選択された全店を対象とする
      favorite.currentHalls ?? favorite.dataKi?.setting.halls ?? []
    )
  );

  // DK-SISトグルスイッチの状態を反映
  const isDkSis =
    !!favorite.dataKi?.setting.sisFieldTypes &&
    favorite.dataKi.setting.sisFieldTypes.length > 0;
  if (isDkSis && favoriteId != null) {
    gaFavoriteEvent('DK-SISスイッチ', {
      event_favorite_id: favoriteId,
      event_favorite_page: '機種集計',
    });
  }
  yield put(
    ModelReportsSettingActionCreators.selectModelReportsIsDkSisAction(isDkSis)
  );

  // 機種集計テーブルを取得
  if (favorite.dataKi?.setting !== undefined) {
    //ymdList対応以前に作成したお気に入りを考慮
    const {
      startDate,
      endDate,
      startComparisonDate,
      endComparisonDate,
      ...rest
    } = favorite.dataKi.setting;

    yield put(
      DataKiActionCreators.fetchDataKiAction(
        {
          ...rest,
          // 期間を再算出して上書きする
          ...dateRangeParams,
          ...dkSisDateRangeParams,
          sisTypes: searchConditions.sisTypes,
          sisTypesForSijiritu: searchConditions.sisTypesForSijiritu,
        },
        favorite.currentShu,
        favorite.currentHalls ?? rest.halls
      )
    );
  }

  // テーブル列の並び順を変更
  if (favorite.dataKi?.columnsOrder) {
    yield put(
      DataKiActionCreators.selectDataKiColumnsOrderAction(
        favorite.dataKi.columnsOrder
      )
    );
  } else {
    // 並び順がない場合はリセットする
    yield put(DataKiActionCreators.resetDataKiColumnsOrderAction());
  }

  // 店舗展開行を取得
  const dataKi2ndRowSetting = favorite.dataKi2ndRow?.setting;
  if (dataKi2ndRowSetting !== undefined) {
    // Fetchする展開行一覧を生成
    const fetchList = Object.keys(dataKi2ndRowSetting)
      .filter((key) => {
        const setting = dataKi2ndRowSetting[key];
        return setting.queryParameter != null && setting.params != null;
      })
      .map((key) => {
        const setting = dataKi2ndRowSetting[key];
        //ymdList対応以前に作成したお気に入りを考慮
        const {
          startDate,
          endDate,
          startComparisonDate,
          endComparisonDate,
          ...rest
        } = setting.params;
        return put(
          DataKi2ndRowActionCreators.fetchDataKi2ndRowAction(
            setting.queryParameter,
            {
              ...rest,
              // 期間を再算出して上書きする
              ...dateRangeParams,
              ...dkSisDateRangeParams,
            }
          )
        );
      });
    // 全件取得
    yield all(fetchList);
  }

  /**
   * 台別行/機種行データ展開行を取得
   */
  const dataKi3rdRow = favorite.dataKi3rdRow;
  // 検索出力が「SISタイプ・メーカー別の展開行が機種のもの」かどうかを判定
  // 機種だった場合には展開対象として、そうではない場合には展開させないため
  const isOpenDataKi3rdRowFavorite = isOpenModelReportsFavorite(
    'dataKi3rdRow',
    favorite
  );
  if (dataKi3rdRow !== undefined && isOpenDataKi3rdRowFavorite) {
    // Fetchする展開行一覧を生成
    const fetchList = dataKi3rdRow.map(({ mainFieldId, hallId, setting }) => {
      //ymdList対応以前に作成したお気に入りを考慮
      const {
        startDate,
        endDate,
        startComparisonDate,
        endComparisonDate,
        marking,
        fields,
        ...rest
      } = setting;

      return put(
        DataKi3rdRowActionCreators.fetchDataKi3rdRowAction(
          mainFieldId,
          hallId,
          {
            ...rest,
            // 期間を再算出して上書きする
            ...dateRangeParams,
            ...dkSisDateRangeParams,
            // 出力タイプに応じて複数fieldsの省略をする
            fields: mainFieldId === 'kiList' ? fields : adjustedFields(setting),
            marking,
          }
        )
      );
    });
    // 全件取得
    yield all(fetchList);
  }

  /**
   * 推移グラフ（店舗比較）を取得
   */
  // 検索出力が「SISタイプ・メーカー別の展開行が機種のもの」かどうかを判定
  // 機種だった場合には展開対象として、そうではない場合には展開させないため
  const isOpenDataKiGraphFavorite = isOpenModelReportsFavorite(
    'dataKiGraph',
    favorite
  );
  if (
    favorite.dataKiGraph?.setting !== undefined &&
    isOpenDataKiGraphFavorite
  ) {
    // グラフ用に期間を算出する
    const endDate = calcEndDateWithExcludeToday(
      !!searchConditions.excludeToday,
      dateRangeParams.ymdList && dateRangeParams.ymdList.slice(-1)[0]
    );
    const startDate = calcStartDateFromDateType(
      favorite.dataKiGraph.setting.dateType,
      endDate
    );

    // SISタイプ・メーカー行の機種行を抽出
    if (favorite.dataKiGraph.selectedDataKiExpandedRow) {
      yield put(
        ModelReportsSettingActionCreators.changeSelectedDataKiExpandedRowAction(
          favorite.dataKiGraph.selectedDataKiExpandedRow.mainFieldId,
          favorite.dataKiGraph.selectedDataKiExpandedRow.hallId,
          favorite.dataKiGraph.selectedDataKiExpandedRow.selectedRow
        )
      );
    } else {
      yield put(
        ModelReportsSettingActionCreators.changeSelectedDataKiExpandedRowAction(
          '',
          '',
          '1stRow'
        )
      );
    }

    yield put(
      DataKiGraphActionCreators.fetchDataKiGraphFromFavoriteAction(
        {
          ...favorite.dataKiGraph.setting,
          // 期間を再算出して上書きする
          startDate: format(startDate, 'yyyy-MM-dd'),
          endDate: format(endDate, 'yyyy-MM-dd'),
        },
        favorite.dataKiGraph.selectHalls
      )
    );
    // グラフのDK-SIS条件選択ドロップダウンの値を設定
    yield put(
      searchModelReportsSelectedKiGraphDkSisCondition(
        getDefaultDkSisSearchConditionValue(
          !!favorite.dataKiGraph.setting.needsSis,
          {
            sisBreakEvenForPachinko:
              favorite.dataKiGraph.setting.sisBreakEvenForPachinko,
            sisBreakEvenForPachislo:
              favorite.dataKiGraph.setting.sisBreakEvenForPachislo,
            sisRank: favorite.dataKiGraph.setting.sisRank,
            sisArea: favorite.dataKiGraph.setting.sisArea,
          }
        )
      )
    );

    // 数値表示の設定を反映
    yield put(
      ModelReportsSettingActionCreators.selectModelReportsGraphShowNumberLabelAction(
        favorite.dataKiGraph.showGraphNumberLabel ?? true
      )
    );
  }

  /**
   * 台別データを取得する
   */
  // 検索出力が「SISタイプ・メーカー別の展開行が機種のもの」かどうかを判定
  // 機種だった場合には展開対象として、そうではない場合には展開させないため
  const isOpenDataKiDaiFavorite = isOpenModelReportsFavorite(
    'dataKiDai',
    favorite
  );
  if (favorite.dataKiDai?.setting !== undefined && isOpenDataKiDaiFavorite) {
    //ymdList対応以前に作成したお気に入りを考慮
    const {
      startDate,
      endDate,
      startComparisonDate,
      endComparisonDate,
      ...rest
    } = favorite.dataKiDai.setting;

    // SISタイプ・メーカー行の機種行を抽出
    if (favorite.dataKiDai.selectedDataKiExpandedRow) {
      yield put(
        ModelReportsSettingActionCreators.changeSelectedDataKiExpandedRowAction(
          favorite.dataKiDai.selectedDataKiExpandedRow.mainFieldId,
          favorite.dataKiDai.selectedDataKiExpandedRow.hallId,
          favorite.dataKiDai.selectedDataKiExpandedRow.selectedRow
        )
      );
    } else {
      yield put(
        ModelReportsSettingActionCreators.changeSelectedDataKiExpandedRowAction(
          '',
          '',
          '1stRow'
        )
      );
    }

    yield put(
      DataKiDaiActionCreators.fetchDataKiDaiAction({
        ...rest,
        // 期間を再算出して上書きする
        ...dateRangeParams,
      })
    );

    // テーブル列の並び順を変更
    if (
      favorite.dataKiDai.columnsOrder &&
      favorite.dataKiDai.columnsOrder.length > 0
    ) {
      yield put(
        ModelReportsSettingActionCreators.selectModelReportsDaiColumnsOrderAction(
          favorite.dataKiDai.columnsOrder
        )
      );
    }
  }

  /**
   * チェーン店一覧を取得する
   */
  // 検索出力が「SISタイプ・メーカー別の展開行が機種のもの」かどうかを判定
  // 機種だった場合には展開対象として、そうではない場合には展開させないため
  const isOpenDataKiKasidamaFavorite = isOpenModelReportsFavorite(
    'dataKiKasidama',
    favorite
  );
  if (
    favorite.dataKiKasidama?.setting !== undefined &&
    isOpenDataKiKasidamaFavorite
  ) {
    //ymdList対応以前に作成したお気に入りを考慮
    const {
      startDate,
      endDate,
      startComparisonDate,
      endComparisonDate,
      ...rest
    } = favorite.dataKiKasidama.setting;

    // SISタイプ・メーカー行の機種行を抽出
    if (favorite.dataKiKasidama.selectedDataKiExpandedRow) {
      yield put(
        ModelReportsSettingActionCreators.changeSelectedDataKiExpandedRowAction(
          favorite.dataKiKasidama.selectedDataKiExpandedRow.mainFieldId,
          favorite.dataKiKasidama.selectedDataKiExpandedRow.hallId,
          favorite.dataKiKasidama.selectedDataKiExpandedRow.selectedRow
        )
      );
    } else {
      yield put(
        ModelReportsSettingActionCreators.changeSelectedDataKiExpandedRowAction(
          '',
          '',
          '1stRow'
        )
      );
    }

    // searchConditionにkiListとkiListForSijirituが設定されているにもかかわらず、favorite.dataKiKasidama.settingにkiListとkiListForSijirituが設定されていない場合があるときに、favorite.dataKiKasidama.settingにkiListとkiListForSijirituを追加する
    // https://github.com/DKClaris/claris-general/issues/3377
    const kiList = favorite.dataKiKasidama.setting.kiList;
    const kiListForSijiritu = favorite.dataKiKasidama.setting.kiListForSijiritu;
    let addKiListKiListForSijirituParams = {};
    if (
      favorite.searchCondition.kiList &&
      favorite.searchCondition.kiListForSijiritu &&
      !kiList &&
      !kiListForSijiritu
    ) {
      addKiListKiListForSijirituParams = {
        kiList: favorite.searchCondition.kiList,
        kiListForSijiritu: favorite.searchCondition.kiListForSijiritu,
      };
    }

    yield put(
      DataKiKasidamaActionCreators.fetchDataKiKasidamaAction({
        ...rest,
        // 期間を再算出して上書きする
        ...dateRangeParams,
        ...addKiListKiListForSijirituParams,
      })
    );

    // テーブル列の並び順を変更
    if (
      favorite.dataKiKasidama.columnsOrder &&
      favorite.dataKiKasidama.columnsOrder.length > 0
    ) {
      yield put(
        ModelReportsSettingActionCreators.selectModelReportsKasidamaColumnsOrderAction(
          favorite.dataKiKasidama.columnsOrder
        )
      );
    }
    // DK-SIS条件選択ドロップダウンの値を設定
    yield put(
      searchModelReportsSelectedKiKasidamaDkSisCondition(
        getDefaultDkSisSearchConditionKasidamaValue(
          !!favorite.dataKiKasidama?.setting.containsSisAverage,
          {
            sisBreakEvenForPachinko:
              favorite.dataKiKasidama?.setting.sisBreakEvenForPachinko,
            sisBreakEvenForPachislo:
              favorite.dataKiKasidama?.setting.sisBreakEvenForPachislo,
            sisRank: favorite.dataKiKasidama?.setting.sisRank,
            sisArea: favorite.dataKiKasidama?.setting.sisArea,
          }
        )
      )
    );
  }

  // テーブルフィルター項目を取得する
  // 項目一覧を反映する
  yield put(
    ModelReportsSettingActionCreators.applyModelReportsTableFilterAction(
      favorite.tableFilterItems ?? []
    )
  );

  // 商圏推移グラフ
  if (favorite.dataMksTransitiveGraph) {
    const {
      field,
      dateType,
      halls,
      kiList,
      shuList,
      shuGroupIds,
      excludeToday,
      endDate,
      selectedMksHallCodes,
      showGraphNumberLabel,
    } = favorite.dataMksTransitiveGraph;

    yield put(
      DataKiMksTransitiveGraphActionCreators.fetchDataKiMksTransitiveGraphRequestAction(
        kiList[0],
        halls[0],
        shuGroupIds && shuGroupIds[0],
        shuList && shuList[0],
        excludeToday,
        endDate,
        field,
        dateType
      )
    );
    yield put(
      ModelReportsSettingActionCreators.selectModelReportsMksTransitiveGraphSelectedMksHallCodes(
        selectedMksHallCodes
      )
    );
    yield put(
      ModelReportsSettingActionCreators.selectModelReportsMksTransitiveGraphShowGraphNumberLabel(
        showGraphNumberLabel
      )
    );
  }
}

/**
 * お気に入り上書き保存時の処理
 */
function* saveFavoriteSaga(action: SaveModelReportsFavoriteAction) {
  // 選択中のお気に入りID
  const selectedFavoriteId: number | undefined = yield select(
    modelReportsSelectedFavoriteSelector
  );
  // 選択中のお気に入り
  const selectedFavorite: FavoriteItem = yield select(
    modelReportsSelectedFavoriteDataSelector
  );

  // 現在の機種集計の設定すべて
  const pageSetting: ModelReportsSettingState = yield select(
    modelReportsSettingSelector
  );
  // 機種集計テーブルの検索条件
  const dataKiParams: DataKiParams = yield select(dataKiParamsSelector);
  // 展開行の検索条件
  const dataKi2ndRowParams: {
    [key: string]: {
      queryParameter: QueryParameter;
      params: DataKiParams;
    };
  } = yield select(dataKi2ndRowParamsSelector);
  // 台別データ展開行の検索条件
  const dataKi3rdRowParams: [
    { mainFieldId: string; hallId: string; setting: DataKiParams }
  ] = yield select(dataKi3rdRowParamsSelector);
  // 推移グラフ（店舗比較）の検索条件
  const dataKiGraphParams: DataKiGraphParams | undefined = yield select(
    dataKiGraphParamsSelector
  );
  // 推移グラフ（店舗比較）で選択中の店舗
  const dataKiGraphSelectHalls: string[] = yield select(
    dataKiGraphSelectHallsSelector
  );
  // 台別データの検索条件
  const dataKiDaiParams: DataKiDaiParams | undefined = yield select(
    dataKiDaiParamsSelector
  );
  // チェーン店一覧の検索条件
  const dataKiKasidamaParams: DataKiKasidamaParams | undefined = yield select(
    dataKiKasidamaParamsSelector
  );
  // テーブル列の並び順
  const dataKiColumnsOrder: string[] | undefined = yield select(
    dataKiColumnsOrderSelector
  );
  // 選択中の機種リスト
  const dataKiCheckedList:
    | { name: string; group: string }[]
    | undefined = yield select(dataKiRawCheckedListSelector);
  // フィルターで選択中の機種リスト
  const dataKiSelectedKiList: string[] | undefined = yield select(
    dataKiSelectedKiListSelector
  );
  const daiColumnsOrder: string[] | undefined = yield select(
    modelReportsSelectedDaiColumnsOrderSelector
  );
  const kasidamaColumnsOrder: string[] | undefined = yield select(
    modelReportsSelectedKasidamaColumnsOrderSelector
  );

  // 機種名フィルタ
  const modelNameFilter: string = yield select(
    modelReportsModelNameFilterSelector
  );

  // サブメニュー内数値フィルタ
  const subMenuNumberFilter: {
    [field: string]: {
      minimumNumber: number | undefined;
      maximumNumber: number | undefined;
    };
  } = yield select(modelReportsSubMenuNumberFilterSelector);

  // テーブルフィルターで選択中の項目
  const tableFilterSelectedItems: string[] | undefined = yield select(
    modelReportsSelectedTableFilterSelector
  );

  /**
   * 機種展開行を閉じて、推移グラフが展開されていたお気に入りを反映するときに、機種リストが取得できなくなるため、別途機種展開行した時の機種リストを抽出。その後、お気に入りのdataKiGraphParams情報に登録
   */
  const selectedDataKiExpandedRow: ModelReportsSettingState['selectedDataKiExpandedRow'] = yield select(
    selectedDataKiExpandedRowSelector
  );

  // 機種タグ情報
  const {
    value: kiTags,
  }: {
    value: KiTagListOptions[];
  } = yield select(dataKiFilteredKiTagAverageSelector);

  // 商圏推移グラフ
  const mksTransitiveGraphSetting:
    | DataKiMksTransitiveGraphData['setting']
    | undefined = yield select(dataKiMksTransitionGraphGraphSettingSelector);
  const selectedMksHallCodes: string[] = yield select(
    modelReportsMksTransitiveGraphSelectedMksHallCodesSelector
  );
  const showMksTransitiveGraphNumberLabel: boolean = yield select(
    modelReportsMksTransitiveGraphShowGraphNumberLabelSelector
  );

  // 機種行への機種タグ表示情報
  const showKiTag: boolean = yield select(modelReportsShowKiTagSelector);

  const showGraphNumberLabel: boolean = yield select(
    modelReportsShowNumberLabelSelector
  );

  const dkSis: ModelReportsSettingState['dkSis'] = yield select(
    modelReportsSettingDkSisSearchParamsSelector
  );

  // デフォルトの時は動作しない
  if (selectedFavoriteId === undefined) return;
  // こんなケースはないが念の為
  if (pageSetting.currentShu === undefined) return;

  let favorite: FavoriteItem = {};
  if (action.payload.mode === 'memo') {
    // メモのみ更新モードでの変更後のお気に入りデータ
    favorite = {
      ...selectedFavorite,
      memo: action.payload.memo,
    };
  } else {
    // 変更後のお気に入りデータ
    favorite = {
      name: action.payload.name,
      isShared: action.payload.isShared,
      pageSetting: {
        modelReports: {
          searchCondition: pageSetting.searchCondition,
          selectedShu: pageSetting.selectedShu,
          selectedHalls: pageSetting.selectedHalls,
          currentShu: pageSetting.currentShu,
          currentHalls: pageSetting.currentHalls,
          selectedDateRangeParams: pageSetting.selectedDateRangeParams,
          dataKi: {
            setting: dataKiParams,
            columnsOrder: dataKiColumnsOrder,
            kiList: dataKiSelectedKiList,
            checkedKiList: dataKiCheckedList,
          },
          dataKi2ndRow: { setting: dataKi2ndRowParams },
          dataKi3rdRow: dataKi3rdRowParams,
          ...(dataKiGraphParams
            ? {
                dataKiGraph: {
                  setting: dataKiGraphParams,
                  selectHalls: dataKiGraphSelectHalls,
                  selectedDataKiExpandedRow: selectedDataKiExpandedRow,
                  showGraphNumberLabel,
                },
              }
            : {}),
          ...(dataKiDaiParams
            ? {
                dataKiDai: {
                  setting: dataKiDaiParams,
                  columnsOrder: daiColumnsOrder,
                  selectedDataKiExpandedRow: selectedDataKiExpandedRow,
                },
              }
            : {}),
          ...(dataKiKasidamaParams
            ? {
                dataKiKasidama: {
                  setting: dataKiKasidamaParams,
                  columnsOrder: kasidamaColumnsOrder,
                  selectedDataKiExpandedRow: selectedDataKiExpandedRow,
                },
              }
            : {}),
          modelNameFilter,
          subMenuNumberFilter,
          tableFilterItems: tableFilterSelectedItems,
          kiTags: kiTags,
          showKiTag: showKiTag,
          ...(mksTransitiveGraphSetting
            ? {
                dataMksTransitiveGraph: {
                  ...mksTransitiveGraphSetting,
                  selectedMksHallCodes,
                  showGraphNumberLabel: showMksTransitiveGraphNumberLabel,
                },
              }
            : {}),
          isKiTagAverageGraphDisplay: pageSetting.isKiTagAverageGraphDisplay,
          dkSis: {
            isAuto: dkSis.isAuto ?? true,
          },
        },
      },
      memo: action.payload.memo,
      privatelySharedUsers: action.payload.sharedUser,
    };
  }

  // お気に入りを上書き
  yield put(
    SettingsFavoritesActionCreators.patchSettingsFavoritesAction(
      selectedFavoriteId,
      favorite,
      { pageName: '機種集計' }
    )
  );
}

/**
 * お気に入りに保存するデータの生成
 */
function* settingsPageSaga() {
  // 現在の機種集計の設定すべて
  const pageSetting: ModelReportsSettingState = yield select(
    modelReportsSettingSelector
  );
  // 機種集計テーブルの検索条件
  const dataKiParams: DataKiParams = yield select(dataKiParamsSelector);
  // 展開行の検索条件
  const dataKi2ndRowParams: {
    [key: string]: {
      queryParameter: QueryParameter;
      params: DataKiParams;
    };
  } = yield select(dataKi2ndRowParamsSelector);
  // 台別データ展開行の検索条件
  const dataKi3rdRowParams: [
    { mainFieldId: string; hallId: string; setting: DataKiParams }
  ] = yield select(dataKi3rdRowParamsSelector);
  // 推移グラフ（店舗比較）の検索条件
  const dataKiGraphParams: DataKiGraphParams | undefined = yield select(
    dataKiGraphParamsSelector
  );
  // 推移グラフ（店舗比較）で選択中の店舗
  const dataKiGraphSelectHalls: string[] = yield select(
    dataKiGraphSelectHallsSelector
  );
  // 台別データの検索条件
  const dataKiDaiParams: DataKiDaiParams | undefined = yield select(
    dataKiDaiParamsSelector
  );
  // チェーン店一覧の検索条件
  const dataKiKasidamaParams: DataKiKasidamaParams | undefined = yield select(
    dataKiKasidamaParamsSelector
  );
  // テーブル列の並び順
  const dataKiColumnsOrder: string[] | undefined = yield select(
    dataKiColumnsOrderSelector
  );
  const daiColumnsOrder: string[] | undefined = yield select(
    modelReportsSelectedDaiColumnsOrderSelector
  );
  const kasidamaColumnsOrder: string[] | undefined = yield select(
    modelReportsSelectedKasidamaColumnsOrderSelector
  );
  // 選択中の機種リスト
  const dataKiCheckedList:
    | { name: string; group: string }[]
    | undefined = yield select(dataKiRawCheckedListSelector);
  // フィルターで選択中の機種リスト
  const dataKiSelectedKiList: string[] | undefined = yield select(
    dataKiSelectedKiListSelector
  );

  // 機種名フィルタ
  const modelNameFilter: string = yield select(
    modelReportsModelNameFilterSelector
  );

  // サブメニュー内数値フィルタ
  const subMenuNumberFilter: {
    [field: string]: {
      minimumNumber: number | undefined;
      maximumNumber: number | undefined;
    };
  } = yield select(modelReportsSubMenuNumberFilterSelector);

  // テーブルフィルターで選択中の項目
  const tableFilterSelectedItems: string[] | undefined = yield select(
    modelReportsSelectedTableFilterSelector
  );

  /**
   * 機種展開行を閉じて、推移グラフ/台別データ/チェーン店一覧が展開されていたお気に入りを反映するときに、機種リストが取得できなくなるため、別途機種展開行した時の機種リストを抽出。その後、お気に入りのdataKiGraphParams情報に登録
   */
  const selectedDataKiExpandedRow: ModelReportsSettingState['selectedDataKiExpandedRow'] = yield select(
    selectedDataKiExpandedRowSelector
  );

  // 機種タグ情報
  const {
    value: kiTags,
  }: {
    value: KiTagListOptions[];
  } = yield select(dataKiFilteredKiTagAverageSelector);

  // 商圏推移グラフ
  const mksTransitiveGraphSetting:
    | DataKiMksTransitiveGraphData['setting']
    | undefined = yield select(dataKiMksTransitionGraphGraphSettingSelector);
  const selectedMksHallCodes: string[] = yield select(
    modelReportsMksTransitiveGraphSelectedMksHallCodesSelector
  );
  const showMksTransitiveGraphNumberLabel: boolean = yield select(
    modelReportsMksTransitiveGraphShowGraphNumberLabelSelector
  );

  // 機種行への機種タグ表示情報
  const showKiTag: boolean = yield select(modelReportsShowKiTagSelector);

  const showGraphNumberLabel: boolean = yield select(
    modelReportsShowNumberLabelSelector
  );

  const dkSis: ModelReportsSettingState['dkSis'] = yield select(
    modelReportsSettingDkSisSearchParamsSelector
  );

  // initFetchModelReportsDataSagaにて初回取得時でもcurrentShuを設定している為、ここでundefinedになることはないが念の為
  if (pageSetting.currentShu === undefined) return;

  const result: FavoriteItem['pageSetting'] = {
    modelReports: {
      searchCondition: pageSetting.searchCondition,
      selectedShu: pageSetting.selectedShu,
      selectedHalls: pageSetting.selectedHalls,
      currentShu: pageSetting.currentShu,
      currentHalls: pageSetting.currentHalls,
      selectedDateRangeParams: pageSetting.selectedDateRangeParams,
      dataKi: {
        setting: dataKiParams,
        columnsOrder: dataKiColumnsOrder,
        kiList: dataKiSelectedKiList,
        checkedKiList: dataKiCheckedList,
      },
      dataKi2ndRow: { setting: dataKi2ndRowParams },
      dataKi3rdRow: dataKi3rdRowParams,
      ...(dataKiGraphParams
        ? {
            dataKiGraph: {
              setting: dataKiGraphParams,
              selectHalls: dataKiGraphSelectHalls,
              selectedDataKiExpandedRow: selectedDataKiExpandedRow,
              showGraphNumberLabel,
            },
          }
        : {}),
      ...(dataKiDaiParams
        ? {
            dataKiDai: {
              setting: dataKiDaiParams,
              columnsOrder: daiColumnsOrder,
              selectedDataKiExpandedRow: selectedDataKiExpandedRow,
            },
          }
        : {}),
      ...(dataKiKasidamaParams
        ? {
            dataKiKasidama: {
              setting: dataKiKasidamaParams,
              columnsOrder: kasidamaColumnsOrder,
              selectedDataKiExpandedRow: selectedDataKiExpandedRow,
            },
          }
        : {}),
      modelNameFilter,
      subMenuNumberFilter,
      tableFilterItems: tableFilterSelectedItems,
      kiTags: kiTags,
      showKiTag: showKiTag,
      ...(mksTransitiveGraphSetting
        ? {
            dataMksTransitiveGraph: {
              ...mksTransitiveGraphSetting,
              selectedMksHallCodes,
              showGraphNumberLabel: showMksTransitiveGraphNumberLabel,
            },
          }
        : {}),
      isKiTagAverageGraphDisplay: pageSetting.isKiTagAverageGraphDisplay,
      dkSis: {
        isAuto: dkSis.isAuto ?? true,
      },
    },
  };
  return result;
}

/**
 * お気に入り新規保存時の処理
 */
function* saveAsFavoriteSaga(action: SaveAsModelReportsFavoriteAction) {
  const pageSetting: FavoriteItem['pageSetting'] = yield call(settingsPageSaga);

  // 変更後のお気に入りデータ
  const favorite: FavoriteItem = {
    name: action.payload.name,
    isShared: action.payload.isShared,
    pageName: '機種集計',
    pageSetting,
    memo: action.payload.memo,
    privatelySharedUsers: action.payload.sharedUser,
  };

  // お気に入りを新規保存
  yield put(
    SettingsFavoritesActionCreators.postSettingsFavoritesAction([favorite], {})
  );
}

/**
 * 画面共有用短縮URL作成の処理
 */
function* createShortenedUrlSaga(action: CreateShortenedUrlAction) {
  const pageSetting: FavoriteItem['pageSetting'] = yield call(settingsPageSaga);

  const compressed = compressToEncodedURIComponent(
    action.payload.pageName,
    pageSetting ?? {}
  );
  const originalUrl = `${action.payload.locationUrl}?q=${compressed}`;

  yield put(ShortenedUrlActionCreators.postShortenedUrlAction({ originalUrl }));
}

/**
 * 機種集計の派生画面をリセットする
 */
function* resetDerivedScreenSaga() {
  yield put(DataKiGraphActionCreators.hideDataKiGraphAction()); // 推移グラフ（店舗比較）
  yield put(DataKiDaiActionCreators.hideDataKiDai()); // 台別データ
  yield put(DataKiKasidamaActionCreators.hideDataKiKasidama()); // 貸玉データ
  // 商圏推移グラフを閉じる
  yield put(
    DataKiMksTransitiveGraphActionCreators.hideDataKiMksTransitiveGraphAction()
  );
}

/**
 * 機種集計の全てのデータを破棄する
 */
export function* initModelReportsDataSaga() {
  yield put(DataKi2ndRowActionCreators.hideAllDataKi2ndRowAction()); // 店舗行
  yield put(DataKi3rdRowActionCreators.hideAllDataKi3rdRowAction()); // 台別データ展開行
  yield fork(resetDerivedScreenSaga);
}

/**
 * 初回データ取得
 */
function* initFetchModelReportsDataSaga(action: InitModelReportsAction) {
  const loadingStateModel: LoadingState = yield select(dataKiLoadingSelector);

  if (loadingStateModel !== 'prepare') {
    return;
  }

  // 初回リクエストでは、バックエンドの初期値と異なる可能性があるので日付を指定する
  const defaultValue = generateInitialResponse();

  yield put(
    DataKiActionCreators.fetchDataKiAction(
      {
        ymdList: defaultValue.setting.ymdList,
        ymdComparisonList: defaultValue.setting.ymdComparisonList,
      },
      undefined,
      undefined
    )
  );

  yield take(DataKiActionTypes.FETCH_DATA_KI_SUCCESS);
  const shuGroupId: string | undefined = yield select(
    dataKiSettingShuGroupIdSelector
  );
  const shuId: string | undefined = yield select(dataKiSettingShuIdSelector);

  // NOTE: ShuOptionの型に足りない情報をダミーで補足している
  // settingOptionのリクエストを並列に実施するため、settingOptionとの突合はしない
  const currentShu: ShuOption = {
    code: shuGroupId ?? shuId ?? '',
    group: '',
    name: '',
    type: shuGroupId ? 'shuGroup' : 'shuList',
  };

  yield put(
    ModelReportsSettingActionCreators.selectModelReportsCurrentShuAction(
      currentShu
    )
  );
}

/**
 * 検索ボタン押下時に行う処理
 */
function* searchActionSaga(action: SearchModelReportsAction) {
  // 機種集計のデータを初期化する
  yield fork(initModelReportsDataSaga);

  const { params } = action.payload;

  // 現在の検索条件項目を取得
  const settingsOptions: SearchCondition = yield select(
    settingsOptionsKiSearchConditionSelector
  );
  // 現在選択中の種別
  const currentShu: ShuOption | undefined = yield select(
    modelReportsSelectedCurrentShuSelector
  );

  // すべての種別・種別グループ一覧
  const allShuOption: ShuOption[] = getShuOption(settingsOptions);
  // 現在選択可能な種別一覧
  const shuList =
    params.selectedShu.length > 0 ? params.selectedShu : allShuOption;
  // currentShuが選択可能な種別一覧に含まれていない場合先頭を選択
  const checkedCurrentShu = checkCurrentShu(shuList, currentShu);

  // currentShuがselectedShu（選択可能な種別一覧）にない場合適切な種別を選択する
  if (
    currentShu !== undefined &&
    currentShu.type !== 'none' &&
    checkedCurrentShu.code !== currentShu.code
  ) {
    // currentShu（選択中の種別）を更新する
    yield put(
      ModelReportsSettingActionCreators.selectModelReportsCurrentShuAction(
        checkedCurrentShu
      )
    );
  }

  // selectedShu（選択中の種別一覧）を更新する
  yield put(
    ModelReportsSettingActionCreators.selectModelReportsShuAction(
      params.selectedShu
    )
  );

  // 店舗選択スライダーで選択中の店舗を反映
  const currentHalls = params.currentHalls;
  if (currentHalls == null) {
    yield put(
      ModelReportsSettingActionCreators.selectModelReportsCurrentHallsAction(
        //検索条件で選択された全店を対象とする
        params.params.halls ?? []
      )
    );
  }

  // 店舗選択スライダーの店舗を反映
  yield put(
    ModelReportsSettingActionCreators.selectModelReportsSelectedHallsAction(
      params.params.halls ?? []
    )
  );

  // dateRange・dateUnit（選択中の日付単位・期間）を更新する
  yield put(
    ModelReportsSettingActionCreators.selectModelReportsDateRangeParamsAction({
      ...params.dateRangeParams,
      comparisonSection:
        params.dateRangeParams.comparisonSection ??
        kiDateRanges.find(
          ({ name }) => name === params.dateRangeParams.dateRange
        )?.defaultComparisonSection ??
        'カスタム',
    })
  );

  const nationalHolidays: SettingsNationalHolidays | undefined = yield select(
    settingsNationalHolidaysSelector
  );

  // 比較区分で平日/土日祝比較の場合、祝日情報がない場合は取得する
  if (
    params.dateRangeParams.comparisonSection === '平日/土日祝比較' &&
    nationalHolidays == null
  ) {
    yield put(
      SettingsNationalHolidaysActionCreators.fetchSettingsNationalHolidaysAction()
    );
    yield take(
      SettingsNationalHolidaysActionTypes.FETCH_SETTINGS_NATIONAL_HOLIDAYS_SUCCESS
    );
  }

  const updatedNationalHolidays:
    | SettingsNationalHolidays
    | undefined = yield select(settingsNationalHolidaysSelector);

  // 検索条件の期間部分を再計算
  const dateRangeParams = searchConditionToDateRangeParams(
    params.dateRangeParams.dateRange,
    params.dateRangeParams.comparisonSection,
    params.params,
    { holidays: updatedNationalHolidays?.holidays }
  );

  const {
    // 日付関連パラメータはdateRangeParamsで追加されるためすべて除外する
    ymdList,
    ymdComparisonList,
    startDate,
    endDate,
    startComparisonDate,
    endComparisonDate,
    // 比較区分で平日/土日祝の場合除外するため
    dayType,
    days,
    dateSuffixes,
    dates,
    ...restParams
  } = params.params;

  // 検索条件の加工
  const modifyParams = {
    ...restParams,
    ...(params.dateRangeParams.comparisonSection !== '平日/土日祝比較'
      ? { dayType, days, dateSuffixes, dates }
      : {}),
    // MEMO: ForSijiritu 系のパラメータは検索条件で指定されているものを分母として渡す
    ...{
      kiListForSijiritu: params.params.kiList,
      sisTypesForSijiritu: params.params.sisTypes,
      makersForSijiritu: params.params.makers,
    },
    // 期間を再算出して上書きする
    ...dateRangeParams,
    mainField: params.params.mainField ?? MAIN_FIELD_TYPE.KI_TUSHO_MEI,
  };

  // searchCondition(選択中の検索条件）を更新する
  yield put(
    ModelReportsSettingActionCreators.selectModelReportsSearchConditionAction(
      modifyParams
    )
  );

  // フィルターされた機種リストの情報があれば削除
  yield put(DataKiActionCreators.selectDataKiListAction([]));

  // DK-SISの期間を取得
  const isDkSisOn: boolean = yield select(modelReportsSettingisDkSisOnSelector);
  const dkSis: ModelReportsSettingState['dkSis'] = yield select(
    modelReportsSettingDkSisSearchParamsSelector
  );

  let latestDkSisDataUnit: DkSisSearchParams = {};
  if (isDkSisOn && dkSis.isAuto) {
    const selectedDateRange: KiReportsSettingDateRangeParams = yield select(
      modelReportsSelectedDateRangeParamsSelector
    );
    const latestBaseDateForWeeklySisData: string = yield select(
      settingsOptionsKiLatestBaseDateForWeeklySisDataSelector
    );
    const latestBaseDateForMonthlySisData: string = yield select(
      settingsOptionsKiLatestBaseDateForMonthlySisDataSelector
    );

    let searchType: SearchType;
    switch (selectedDateRange.dateRange) {
      case 'カスタム':
        searchType = getSearchTypeFromDateRange({
          dateRange: 'カスタム',
          optionForCustom: {
            dateUnit: selectedDateRange.dateUnit,
            ymdList: ymdList ?? [],
            ymdComparisonList: ymdComparisonList ?? [],
            comparativeSection: selectedDateRange.comparisonSection as
              | '平日/土日祝比較'
              | 'カスタム',
          },
        });
        break;
      default:
        searchType = getSearchTypeFromDateRange({
          dateRange: selectedDateRange.dateRange,
        });
        break;
    }
    const result = calculateOptimalDkSisPeriod({
      type: searchType,
      endDateOfSearch: ymdList?.at(-1) as string,
      latestBaseDate: {
        latestBaseDateForWeeklySisData,
        latestBaseDateForMonthlySisData,
      },
    });

    latestDkSisDataUnit = {
      sisDateRangeType: searchType,
      sisStartDate: format(result.startDate, 'yyyy-MM-dd'),
      sisEndDate: format(result.endDate, 'yyyy-MM-dd'),
    };
  }

  // データ取得
  yield put(
    DataKiActionCreators.fetchDataKiAction(
      {
        ...modifyParams,
        ...latestDkSisDataUnit,
      },
      checkedCurrentShu,
      currentHalls ?? modifyParams.halls
    )
  );

  if (modifyParams.mainField !== MAIN_FIELD_TYPE.KI_TUSHO_MEI) {
    yield put(
      DataKiActionCreators.resetSelectDataKiFilteredKiTagAverageAction()
    );
    yield put(DataKiActionCreators.resetDataKiFilteredKiTagAverageAction());
  }
  // チェック機種絞り込みリセット処理、チェック状態は保持するためにチェック状態リセットは行わない
  yield put(DataKiActionCreators.resetDataKiFilteredAverageAction());

  // 現在の検索条件を取得
  const searchCondition: ModelReportsFormConditions = yield select(
    modelReportsSearchConditionSelector
  );
  // テーブルフィルターのmainFieldを取り除く
  // mainFieldのタイプやメーカーを除外されないようにするため
  if (searchCondition.mainField) {
    yield put(
      ModelReportsSettingActionCreators.removeModelReportsTableFilterAction(
        searchCondition.mainField
      )
    );
  }

  // DK-SISの期間を更新
  yield put(
    ModelReportsSettingActionCreators.selectModelReportsSearchConditionAction({
      ...searchCondition,
      ...latestDkSisDataUnit,
    })
  );
}

/**
 * 検索ボタン押下時、新しい検索条件でデータを取得する
 */
function* handleSearchSaga() {
  yield takeEvery(
    ModelReportsSettingActionTypes.SEARCH_MODEL_REPORTS,
    searchActionSaga
  );
}

/**
 * リセットボタン押下時、デフォルトの検索条件または選択中のお気に入りでデータを取得する
 */
function* resetSearchActionSaga() {
  // 機種集計のデータを初期化する
  yield fork(initModelReportsDataSaga);
  yield put(DataKiActionCreators.resetDataKiCheckedListAction());
  // 現在選択中のお気に入りを取得する
  const favoriteId: number | undefined = yield select(
    modelReportsSelectedFavoriteSelector
  );

  // お気に入りが選択されている場合はお気に入り適用
  if (favoriteId !== undefined) {
    yield put(
      ModelReportsSettingActionCreators.changeModelReportsFavoriteAction(
        favoriteId
      )
    );
    return;
  }

  // デフォルトが選択されている場合は検索条件を初期化
  yield put(
    ModelReportsSettingActionCreators.resetModelReportsSearchConditionAction()
  );
  yield put(
    ModelReportsSettingActionCreators.resetModelReportsTableFilterAction()
  );
  // 選択中の種別をリセットする
  yield put(ModelReportsSettingActionCreators.resetModelReportsShuAction());

  // 店舗選択スライダーで選択中の店舗をリセットする
  yield put(
    ModelReportsSettingActionCreators.selectModelReportsCurrentHallsAction([])
  );

  // 店舗選択スライダーの店舗をリセットする
  yield put(
    ModelReportsSettingActionCreators.selectModelReportsSelectedHallsAction([])
  );

  // 選択中の期間をリセットする
  yield put(
    ModelReportsSettingActionCreators.resetModelReportsDateRangeParamsAction()
  );
  // フィルターされた機種リストの情報があれば削除
  yield put(DataKiActionCreators.selectDataKiListAction([]));

  // チェック機種絞り込みリセット処理
  yield put(DataKiActionCreators.resetDataKiFilteredAverageAction());

  // 機種タグ設定リセット処理
  yield put(DataKiActionCreators.resetSelectDataKiFilteredKiTagAverageAction()); // 機種タグ設定 機種タグvalue値
  yield put(DataKiActionCreators.resetDataKiFilteredKiTagAverageAction()); // 機種タグ設定 機種タグ条件

  // 機種行への機種タグ表示情報をデフォルト（false）にする
  yield put(ModelReportsSettingActionCreators.selectShowKiTagAction(false));

  // 現在の検索条件を取得
  const searchCondition: ModelReportsFormConditions = yield select(
    modelReportsSearchConditionSelector
  );
  // 現在の種別を取得
  const currentShu: ShuOption = yield select(
    modelReportsSelectedCurrentShuSelector
  );
  // 現在の店舗を取得
  const currentHalls: string[] = yield select(
    modelReportsSettingCurrentHallsSelector
  );

  // セルの並び順をリセットしてデフォルト順にする
  yield put(DataKiActionCreators.resetDataKiColumnsOrderAction());

  // 機種名フィルタをクリア
  yield put(selectModelReportsModelNameFilterAction(''));

  // サブメニュー内数値フィルタをクリア
  yield put(resetModelReportsSubMenuNumberFilterAction());

  // DK-SISトグルスイッチをオフ
  yield put(
    ModelReportsSettingActionCreators.searchModelReportsDkSisOffAction()
  );

  // データ取得
  yield put(
    DataKiActionCreators.fetchDataKiAction(
      searchCondition,
      currentShu,
      currentHalls
    )
  );
}

/**
 * リセットボタン押下時機種集計の全てのデータを破棄する
 */
function* handleResetSaga() {
  yield takeEvery(
    ModelReportsSettingActionTypes.SEARCH_RESET_MODEL_REPORTS,
    resetSearchActionSaga
  );
}

function* handleResetDerivedScreenSaga() {
  yield takeEvery(
    ModelReportsSettingActionTypes.RESET_MODEL_REPORTS_DERIVED_SCREEN,
    resetDerivedScreenSaga
  );
}

/**
 * 変更後の種別でデータを取得する
 */
function* changeCurrentShuSaga(action: ChangeModelReportsCurrentShuAction) {
  // 種別が選択されていない場合は処理を中断
  if (action.payload.shu === undefined) return;

  // 機種集計のデータを初期化する
  yield fork(initModelReportsDataSaga);

  // 現在の検索条件を取得
  const searchCondition: ModelReportsFormConditions = yield select(
    modelReportsSearchConditionSelector
  );

  // 現在の店舗を取得
  const currentHalls: string[] = yield select(
    modelReportsSettingCurrentHallsSelector
  );

  // フィルターされた機種リストの情報があれば削除
  yield put(DataKiActionCreators.selectDataKiListAction([]));

  // チェック機種絞り込みリセット処理、チェック状態は保持するためにチェック状態リセットは行わない
  yield put(DataKiActionCreators.resetDataKiFilteredAverageAction());

  // 変更後の種別でデータを取得する
  yield put(
    DataKiActionCreators.fetchDataKiAction(
      searchCondition,
      action.payload.shu,
      currentHalls
    )
  );
}

/**
 * 選択中の種別変更時、データを取得する
 */
function* handleChangeCurrentShuSaga() {
  yield takeEvery(
    ModelReportsSettingActionTypes.CHANGE_MODEL_REPORTS_CURRENT_SHU,
    changeCurrentShuSaga
  );
}

/**
 * 変更後の店舗でデータを取得する
 */
function* changeCurrentHallsSaga(action: ChangeModelReportsCurrentHallsAction) {
  // 店舗が選択されていない場合は処理を中断
  if (action.payload.halls === undefined) return;

  // 機種集計のデータを初期化する
  yield fork(initModelReportsDataSaga);

  // 現在の検索条件を取得
  const searchCondition: ModelReportsFormConditions = yield select(
    modelReportsSearchConditionSelector
  );

  // 現在の種別を取得
  const currentShu: ShuOption = yield select(
    modelReportsSelectedCurrentShuSelector
  );

  // フィルターされた機種リストの情報があれば削除
  yield put(DataKiActionCreators.selectDataKiListAction([]));

  // チェック機種絞り込みリセット処理、チェック状態は保持するためにチェック状態リセットは行わない
  yield put(DataKiActionCreators.resetDataKiFilteredAverageAction());

  // 変更後の店舗でデータを取得する
  yield put(
    DataKiActionCreators.fetchDataKiAction(
      searchCondition,
      currentShu,
      action.payload.halls
    )
  );
}

/**
 * 選択中の店舗変更時、データを取得する
 */
function* handleChangeCurrentHallsSaga() {
  yield takeEvery(
    ModelReportsSettingActionTypes.CHANGE_MODEL_REPORTS_CURRENT_HALLS,
    changeCurrentHallsSaga
  );
}

/**
 * テーブル固有の検索条件を変更してデータを取得する
 * @param params 変更後の検索条件
 */
function* searchSearchConditionSaga(params: ModelReportsFormConditions) {
  // 機種集計のデータを初期化する
  yield fork(initModelReportsDataSaga);

  // 現在の検索条件を取得
  const searchCondition: ModelReportsFormConditions = yield select(
    modelReportsSearchConditionSelector
  );
  // 現在の種別を取得
  const currentShu: ShuOption = yield select(
    modelReportsSelectedCurrentShuSelector
  );
  // 現在の店舗を取得
  const currentHalls: string[] = yield select(
    modelReportsSettingCurrentHallsSelector
  );

  // 新しい検索条件
  const newSearchCondition = {
    ...searchCondition,
    ...params,
  };

  // 検索条件を更新
  yield put(
    ModelReportsSettingActionCreators.selectModelReportsSearchConditionAction(
      newSearchCondition
    )
  );

  // データ取得
  yield put(
    DataKiActionCreators.fetchDataKiAction(
      newSearchCondition,
      currentShu,
      currentHalls
    )
  );
}

/**
 * ソート実行時データを取得する
 */
function* searchSortSaga(action: SearchModelReportsSortAction) {
  yield fork(searchSearchConditionSaga, action.payload);
}

/**
 * マーキング変更時データを取得する
 */
function* searchMarkingSaga(action: SearchModelReportsMarkingAction) {
  // 現在の検索条件を取得
  const searchCondition: ModelReportsFormConditions = yield select(
    modelReportsSearchConditionSelector
  );
  const marking =
    action.payload.markingOption.code ===
    MODEL_REPORT_MARKING_CONDITIONS.at(0)?.code
      ? undefined
      : action.payload.markingOption.code;
  let params = {
    marking,
    isFiltering: action.payload.isFiltering,
  };
  // MEMO: DK-SIS が未指定、かつ DK-SIS対象の marking が指定されている場合は DK-SIS をONにする
  if (
    searchCondition.sisFieldTypes === undefined &&
    marking?.startsWith('sis') // codeが'sis'で始まるMarkingOptionはDK-SIS対象のmarkingとして扱う
  ) {
    const dateRange: DkSisSearchParams = yield call(dkSisDateRangeCalc);
    params = {
      ...params,
      ...dateRange,
      ...{ sisFieldTypes: ['result'] },
    };
    yield put(
      ModelReportsSettingActionCreators.selectModelReportsIsDkSisAction(true)
    );
  }

  yield fork(searchSearchConditionSaga, params);
}

/**
 * 表示項目変更時データを取得する
 */
function* searchFieldsSaga(action: SearchModelReportsFieldAction) {
  const fields = action.payload.fields.map((item) => item.code);

  yield fork(searchSearchConditionSaga, { fields });
}

/**
 * DK-SISの期間を現在選択されている検索フォームの期間を元に計算する
 * @returns DK-SISの期間の検索条件
 */
function* dkSisDateRangeCalc(
  favoriteDataRangeParams?: ModelReportsFormConditions,
  favorite?: ModelReportsFavorite
) {
  // 現在の期間
  const dateRangeParams: KiReportsSettingDateRangeParams = yield select(
    modelReportsSelectedDateRangeParamsSelector
  );
  // 現在の検索条件を取得
  const searchCondition: ModelReportsFormConditions = yield select(
    modelReportsSearchConditionSelector
  );
  // 最新のDK-SISの週の開始日
  const latestBaseDateForWeeklySisData: string = yield select(
    settingsOptionsKiLatestBaseDateForWeeklySisDataSelector
  );
  // 最新のDK-SISの月の開始日
  const latestBaseDateForMonthlySisData: string = yield select(
    settingsOptionsKiLatestBaseDateForMonthlySisDataSelector
  );

  // SISデータが空の場合は処理を中断する
  if (
    latestBaseDateForWeeklySisData === 'Invalid Date' ||
    latestBaseDateForMonthlySisData === 'Invalid Date'
  ) {
    yield cancel();
    return;
  }

  // DK-SIS 手動の場合
  if (
    favorite &&
    favorite.searchCondition &&
    favorite.dkSis?.isAuto === false && // 古いお気に入りの考慮 (undefinedの可能性があるため)
    favorite.searchCondition.sisDateRangeType &&
    favorite.searchCondition.sisStartDate &&
    favorite.searchCondition.sisEndDate
  ) {
    return {
      sisDateRangeType: favorite.searchCondition.sisDateRangeType,
      sisStartDate: favorite.searchCondition.sisStartDate,
      sisEndDate: favorite.searchCondition.sisEndDate,
    };
  }

  //favoriteDataRangeParamsがある場合はそちらを優先する
  const ymdList = favoriteDataRangeParams?.ymdList ?? searchCondition.ymdList;
  const ymdComparisonList =
    favoriteDataRangeParams?.ymdComparisonList ??
    searchCondition.ymdComparisonList;

  let searchType: SearchType;
  switch (dateRangeParams.dateRange) {
    case 'カスタム':
      searchType = getSearchTypeFromDateRange({
        dateRange: 'カスタム',
        optionForCustom: {
          dateUnit: dateRangeParams.dateUnit,
          ymdList: ymdList ?? [],
          ymdComparisonList: ymdComparisonList ?? [],
          comparativeSection: dateRangeParams.comparisonSection as
            | '平日/土日祝比較'
            | 'カスタム',
        },
      });
      break;
    default:
      searchType = getSearchTypeFromDateRange({
        dateRange: dateRangeParams.dateRange,
      });
      break;
  }
  const result = calculateOptimalDkSisPeriod({
    type: searchType,
    endDateOfSearch: ymdList?.at(-1) as string,
    latestBaseDate: {
      latestBaseDateForWeeklySisData,
      latestBaseDateForMonthlySisData,
    },
  });

  return {
    sisDateRangeType: searchType,
    sisStartDate: format(result.startDate, 'yyyy-MM-dd'),
    sisEndDate: format(result.endDate, 'yyyy-MM-dd'),
  };
}

/**
 * DK-SISを有効にしてデータを取得する
 */
function* searchDkSisOnSaga() {
  // DK-SISの期間
  const dateRange: DkSisSearchParams = yield call(dkSisDateRangeCalc);

  // DK-SISのデフォルト条件で取得する
  yield fork(searchSearchConditionSaga, {
    ...dateRange,
    sisFieldTypes: ['result'],
  });
}

/**
 * DK-SISを無効にしてデータを取得する
 */
function* searchDkSisOffSaga() {
  // 現在の検索条件を取得
  const searchCondition: ModelReportsFormConditions = yield select(
    modelReportsSearchConditionSelector
  );

  // 【DK-SIS】データアラーム と 【DK-SIS】BYminアラーム がマーキングされている時は DK-SIS を OFF にできないようにする
  if (
    searchCondition.marking &&
    (searchCondition.marking === 'sisDataAlarm' ||
      searchCondition.marking === 'sisByminAlarm')
  ) {
    return;
  }

  // 全てのDK-SISの検索条件を取り除く
  yield fork(searchSearchConditionSaga, {
    sisDateRangeType: undefined,
    sisStartDate: undefined,
    sisEndDate: undefined,
    sisBreakEvenForPachinko: undefined,
    sisBreakEvenForPachislo: undefined,
    sisRank: undefined,
    sisArea: undefined,
    sisFieldTypes: undefined,
  });
}

/**
 * フォームで指定したDK-SISの検索条件でデータを取得する
 */
function* searchDkSisSaga(action: SearchModelReportsDkSisAction) {
  // DK-SISの検索条件でデータを取得する
  yield fork(searchSearchConditionSaga, action.payload.params);
}

/**
 * 台別データの表示項目変更時データを取得する
 */
export function* changeDaiColumnsOrderSaga() {
  const fields: Column[] = yield select(dataKiDaiTableDataColumnsSelector);

  const fieldCodes = fields.map((field) => field.code);

  // テーブル列の並び順
  let columnsOrder: string[] = yield select(
    modelReportsSelectedDaiColumnsOrderSelector
  );

  if (!columnsOrder || columnsOrder.length === 0) {
    // ソート順が設定されていない場合、初期配置をデフォルトで設定する
    const defaultColumns: Column[] = yield select(
      dataKiDaiTableDataColumnsSelector
    );
    columnsOrder = defaultColumns.map((column) => column.code);
  }

  const sorted = [...fieldCodes].sort((a, b) => {
    // 店舗名/種別/台番号は常に先頭
    if (b === 'hlMei' || b === 'shu' || b === 'daiBan') {
      return 1;
    }
    return columnsOrder.indexOf(b) > columnsOrder.indexOf(a) ? -1 : 1;
  });

  yield put(selectModelReportsDaiColumnsOrderAction(sorted));
}

/**
 * 貸玉データの表示項目変更時データを取得する
 */
export function* changeKasidamaColumnsOrderSaga() {
  const fields: Column[] = yield select(dataKiKasidamaTableDataColumnsSelector);

  const fieldCodes = fields.map((field) => field.code);

  // テーブル列の並び順
  let columnsOrder: string[] = yield select(
    modelReportsSelectedKasidamaColumnsOrderSelector
  );

  if (!columnsOrder || columnsOrder.length === 0) {
    // ソート順が設定されていない場合、初期配置をデフォルトで設定する
    const defaultColumns: Column[] = yield select(
      dataKiKasidamaTableDataColumnsSelector
    );
    columnsOrder = defaultColumns.map((column) => column.code);
  }

  const sorted = [...fieldCodes].sort((a, b) => {
    // 種別/店舗名は常に先頭
    if (b === 'shu' || b === 'hlMei') {
      return 1;
    }
    return columnsOrder.indexOf(b) > columnsOrder.indexOf(a) ? -1 : 1;
  });

  yield put(selectModelReportsKasidamaColumnsOrderAction(sorted));
}

// 期間スライドコンポーネントがクリックされた時に検索期間を設定して再検索を実行する
function* searchDateRangeSlideSaga(
  action: SearchModelReportsDateRangeSlideAction
) {
  const { dateRange, ymdList, ymdComparisonList } = action.payload;
  // 検索条件の項目を取得
  const searchCondition: ModelReportsFormConditions = yield select(
    modelReportsSearchConditionSelector
  );

  const {
    dateUnit,
    comparisonSection,
  }: KiReportsSettingDateRangeParams = yield select(
    modelReportsSelectedDateRangeParamsSelector
  );

  const shuOptions: ShuOption[] = yield select(modelReportsSelectedShuSelector);

  const currentHalls: string[] = yield select(
    modelReportsSettingCurrentHallsSelector
  );

  yield put(
    searchModelReportsAction({
      params: {
        ...searchCondition,
        ymdList,
        ymdComparisonList,
      },
      selectedShu: shuOptions,
      dateRangeParams: {
        dateRange,
        dateUnit,
        comparisonSection,
      },
      currentHalls,
    })
  );
}

/**
 * テーブル固有の検索条件変更検知時の処理
 */
function* handleTableSearchSaga() {
  // ソート実行時
  yield takeEvery(
    ModelReportsSettingActionTypes.SEARCH_MODEL_REPORTS_SORT,
    searchSortSaga
  );

  // マーキング実行時
  yield takeEvery(
    ModelReportsSettingActionTypes.SEARCH_MODEL_REPORTS_MARKING,
    searchMarkingSaga
  );

  // 表示項目変更時
  yield takeEvery(
    ModelReportsSettingActionTypes.SEARCH_MODEL_REPORTS_FIELDS,
    searchFieldsSaga
  );
  // DK-SISを有効
  yield takeEvery(
    ModelReportsSettingActionTypes.SEARCH_MODEL_REPORTS_DKSIS_ON,
    searchDkSisOnSaga
  );
  // DK-SISを無効
  yield takeEvery(
    ModelReportsSettingActionTypes.SEARCH_MODEL_REPORTS_DKSIS_OFF,
    searchDkSisOffSaga
  );
  // DK-SISの検索条件を変更
  yield takeEvery(
    ModelReportsSettingActionTypes.SEARCH_MODEL_REPORTS_DKSIS,
    searchDkSisSaga
  );
}

/**
 * お気に入り関連の処理
 */
function* handleFavoriteSaga() {
  // お気に入り適用
  yield takeEvery(
    ModelReportsSettingActionTypes.CHANGE_MODEL_REPORTS_FAVORITE,
    applyFavoriteByIdSaga
  );
  // お気に入り上書き保存
  yield takeEvery(
    ModelReportsSettingActionTypes.SAVE_MODEL_REPORTS_FAVORITE,
    saveFavoriteSaga
  );
  // お気に入り新規保存
  yield takeEvery(
    ModelReportsSettingActionTypes.SAVE_AS_MODEL_REPORTS_FAVORITE,
    saveAsFavoriteSaga
  );
}

// 期間スライドコンポーネントがクリックされた時に実行する処理
function* handleDateRangeSlideSaga() {
  yield takeEvery(
    ModelReportsSettingActionTypes.SEARCH_MODEL_REPORTS_DATE_RANGE_SLIDE,
    searchDateRangeSlideSaga
  );
}

// 画面共有用短縮URL作成の処理
function* handleShortenedUrlSaga() {
  yield takeEvery(
    ModelReportsSettingActionTypes.CREATE_SHORTENED_URL,
    createShortenedUrlSaga
  );
}

// 初回表示時のデータ取得
function* handleInitFetchSaga() {
  yield takeEvery(
    ModelReportsSettingActionTypes.INIT_MODEL_REPORTS,
    initFetchModelReportsDataSaga
  );
}

/**
 * 機種集計の検索フォームに関するタスクを実行する
 */
export function* modelReportsSettingSaga() {
  yield fork(handleSearchSaga);
  yield fork(handleResetSaga);
  yield fork(handleResetDerivedScreenSaga);
  yield fork(handleTableSearchSaga);
  yield fork(handleChangeCurrentShuSaga);
  yield fork(handleChangeCurrentHallsSaga);
  yield fork(handleFavoriteSaga);
  yield fork(handleDateRangeSlideSaga);
  yield fork(handleShortenedUrlSaga);
  yield fork(handleInitFetchSaga);
}
