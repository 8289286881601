import { AxiosResponse } from 'axios';
import { call, fork, put, takeEvery } from 'redux-saga/effects';

import { SettingsOptionsSis } from '../domain/sis/types';

import {
  SettingsOptionsSisActionCreators,
  SettingsOptionsSisActionTypes,
} from '../redux/server/settingsOptionsSis';
import { Api, buildConfig } from '../utils/api';
import { handleErrorSaga } from './errorSaga';

function* fetchSettingsOptionsSisSaga(api: Api) {
  try {
    yield put(
      SettingsOptionsSisActionCreators.fetchSettingsOptionsSisRequestAction()
    );
    const response: AxiosResponse<SettingsOptionsSis> = yield call(
      api.get,
      '/settings/options/sis',
      buildConfig()
    );
    const settingsOptionsSis: SettingsOptionsSis = response.data;
    yield put(
      SettingsOptionsSisActionCreators.fetchSettingsOptionsSisSuccessAction(
        settingsOptionsSis
      )
    );
  } catch (error: unknown) {
    yield put(SettingsOptionsSisActionCreators.renewSettingsOptionsSisAction());
    yield fork(handleErrorSaga, error);
  }
}

function* handleFetchSettingsOptionsSisSaga(api: Api) {
  yield takeEvery(
    SettingsOptionsSisActionTypes.FETCH_SETTINGS_OPTIONS_SIS,
    fetchSettingsOptionsSisSaga,
    api
  );
}

export function* settingsOptionsSisSagas(context: { api: Api }) {
  yield fork(handleFetchSettingsOptionsSisSaga, context.api);
}
