import { createSelector } from 'reselect';

import { ClarisApiError } from '../../domain/error';
import {
  DeleteFieldPatternsTypeParams,
  PatchFieldPatternsTypeParams,
  PostFieldPatternsTypeParams,
  SettingsOptionsFieldPatterns,
  TableNameUsedTypeParams,
} from '../../domain/settingsOptionsColsOptionPanelPattern';

// Action Types

const FETCH_SETTINGS_OPTIONS_COLS_OPTION_PANEL_PATTERN_REQUEST = 'FETCH_SETTINGS_OPTIONS_COLS_OPTION_PANEL_PATTERN_REQUEST' as const;
const FETCH_SETTINGS_OPTIONS_COLS_OPTION_PANEL_PATTERN_SUCCESS = 'FETCH_SETTINGS_OPTIONS_COLS_OPTION_PANEL_PATTERN_SUCCESS' as const;
const RENEW_SETTINGS_OPTIONS_COLS_OPTION_PANEL_PATTERN = 'RENEW_SETTINGS_OPTIONS_COLS_OPTION_PANEL_PATTERN' as const;
const POST_SETTINGS_OPTIONS_COLS_OPTION_PANEL_PATTERN_REQUEST = 'POST_SETTINGS_OPTIONS_COLS_OPTION_PANEL_PATTERN_REQUEST' as const;
const POST_SETTINGS_OPTIONS_COLS_OPTION_PANEL_PATTERN_SUCCESS = 'POST_SETTINGS_OPTIONS_COLS_OPTION_PANEL_PATTERN_SUCCESS' as const;
const POST_SETTINGS_OPTIONS_COLS_OPTION_PANEL_PATTERN_FAILURE = 'POST_SETTINGS_OPTIONS_COLS_OPTION_PANEL_PATTERN_FAILURE' as const;
const PATCH_SETTINGS_OPTIONS_COLS_OPTION_PANEL_PATTERN_REQUEST = 'PATCH_SETTINGS_OPTIONS_COLS_OPTION_PANEL_PATTERN_REQUEST' as const;
const PATCH_SETTINGS_OPTIONS_COLS_OPTION_PANEL_PATTERN_SUCCESS = 'PATCH_SETTINGS_OPTIONS_COLS_OPTION_PANEL_PATTERN_SUCCESS' as const;
const PATCH_SETTINGS_OPTIONS_COLS_OPTION_PANEL_PATTERN_FAILURE = 'PATCH_SETTINGS_OPTIONS_COLS_OPTION_PANEL_PATTERN_FAILURE' as const;
const DELETE_SETTINGS_OPTIONS_COLS_OPTION_PANEL_PATTERN_REQUEST = 'DELETE_SETTINGS_OPTIONS_COLS_OPTION_PANEL_PATTERN_REQUEST' as const;
const DELETE_SETTINGS_OPTIONS_COLS_OPTION_PANEL_PATTERN_SUCCESS = 'DELETE_SETTINGS_OPTIONS_COLS_OPTION_PANEL_PATTERN_SUCCESS' as const;
const DELETE_SETTINGS_OPTIONS_COLS_OPTION_PANEL_PATTERN_FAILURE = 'DELETE_SETTINGS_OPTIONS_COLS_OPTION_PANEL_PATTERN_FAILURE' as const;

export const SettingsOptionsOptionPanelPatternActionTypes = {
  FETCH_SETTINGS_OPTIONS_COLS_OPTION_PANEL_PATTERN_REQUEST,
  FETCH_SETTINGS_OPTIONS_COLS_OPTION_PANEL_PATTERN_SUCCESS,
  RENEW_SETTINGS_OPTIONS_COLS_OPTION_PANEL_PATTERN,
  POST_SETTINGS_OPTIONS_COLS_OPTION_PANEL_PATTERN_REQUEST,
  POST_SETTINGS_OPTIONS_COLS_OPTION_PANEL_PATTERN_SUCCESS,
  POST_SETTINGS_OPTIONS_COLS_OPTION_PANEL_PATTERN_FAILURE,
  PATCH_SETTINGS_OPTIONS_COLS_OPTION_PANEL_PATTERN_REQUEST,
  PATCH_SETTINGS_OPTIONS_COLS_OPTION_PANEL_PATTERN_SUCCESS,
  PATCH_SETTINGS_OPTIONS_COLS_OPTION_PANEL_PATTERN_FAILURE,
  DELETE_SETTINGS_OPTIONS_COLS_OPTION_PANEL_PATTERN_REQUEST,
  DELETE_SETTINGS_OPTIONS_COLS_OPTION_PANEL_PATTERN_SUCCESS,
  DELETE_SETTINGS_OPTIONS_COLS_OPTION_PANEL_PATTERN_FAILURE,
};

// Action Creators

function fetchSettingsOptionsColsOptionPanelPatternRequestAction(
  params: TableNameUsedTypeParams
) {
  return {
    type: FETCH_SETTINGS_OPTIONS_COLS_OPTION_PANEL_PATTERN_REQUEST,
    payload: { params },
  };
}

function fetchSettingsOptionsColsOptionPanelPatternSuccessAction(
  settingsOptionsColsOptionPanelPatterns: SettingsOptionsFieldPatterns
) {
  return {
    type: FETCH_SETTINGS_OPTIONS_COLS_OPTION_PANEL_PATTERN_SUCCESS,
    payload: { settingsOptionsColsOptionPanelPatterns },
  };
}

function renewSettingsOptionsColsOptionPanelPatternAction() {
  return {
    type: RENEW_SETTINGS_OPTIONS_COLS_OPTION_PANEL_PATTERN,
  };
}

function postSettingsOptionsColsOptionPanelPatternRequestAction(
  params: PostFieldPatternsTypeParams
) {
  return {
    type: POST_SETTINGS_OPTIONS_COLS_OPTION_PANEL_PATTERN_REQUEST,
    payload: { params },
  };
}

function postSettingsOptionsColsOptionPanelPatternSuccessAction(
  settingsOptionsColsOptionPanelPatterns: SettingsOptionsFieldPatterns
) {
  return {
    type: POST_SETTINGS_OPTIONS_COLS_OPTION_PANEL_PATTERN_SUCCESS,
    payload: { settingsOptionsColsOptionPanelPatterns },
  };
}

function postSettingsOptionsColsOptionPanelPatternFailureAction(
  error: ClarisApiError
) {
  return {
    type: POST_SETTINGS_OPTIONS_COLS_OPTION_PANEL_PATTERN_FAILURE,
    payload: { error },
  };
}

function patchSettingsOptionsColsOptionPanelPatternRequestAction(
  params: PatchFieldPatternsTypeParams
) {
  return {
    type: PATCH_SETTINGS_OPTIONS_COLS_OPTION_PANEL_PATTERN_REQUEST,
    payload: { params },
  };
}

function patchSettingsOptionsColsOptionPanelPatternSuccessAction(
  settingsOptionsColsOptionPanelPatterns: SettingsOptionsFieldPatterns
) {
  return {
    type: PATCH_SETTINGS_OPTIONS_COLS_OPTION_PANEL_PATTERN_SUCCESS,
    payload: { settingsOptionsColsOptionPanelPatterns },
  };
}

function patchSettingsOptionsColsOptionPanelPatternFailureAction(
  error: ClarisApiError
) {
  return {
    type: PATCH_SETTINGS_OPTIONS_COLS_OPTION_PANEL_PATTERN_FAILURE,
    payload: { error },
  };
}

function deleteSettingsOptionsColsOptionPanelPatternRequestAction(
  params: DeleteFieldPatternsTypeParams
) {
  return {
    type: DELETE_SETTINGS_OPTIONS_COLS_OPTION_PANEL_PATTERN_REQUEST,
    payload: { params },
  };
}

function deleteSettingsOptionsColsOptionPanelPatternSuccessAction(
  settingsOptionsColsOptionPanelPatterns: SettingsOptionsFieldPatterns
) {
  return {
    type: DELETE_SETTINGS_OPTIONS_COLS_OPTION_PANEL_PATTERN_SUCCESS,
    payload: { settingsOptionsColsOptionPanelPatterns },
  };
}

function deleteSettingsOptionsColsOptionPanelPatternFailureAction(
  error: ClarisApiError
) {
  return {
    type: DELETE_SETTINGS_OPTIONS_COLS_OPTION_PANEL_PATTERN_FAILURE,
    payload: { error },
  };
}

export const SettingsOptionsColsOptionPanelPatternActionCreators = {
  fetchSettingsOptionsColsOptionPanelPatternRequestAction,
  fetchSettingsOptionsColsOptionPanelPatternSuccessAction,
  renewSettingsOptionsColsOptionPanelPatternAction,
  postSettingsOptionsColsOptionPanelPatternRequestAction,
  postSettingsOptionsColsOptionPanelPatternSuccessAction,
  postSettingsOptionsColsOptionPanelPatternFailureAction,
  patchSettingsOptionsColsOptionPanelPatternRequestAction,
  patchSettingsOptionsColsOptionPanelPatternSuccessAction,
  patchSettingsOptionsColsOptionPanelPatternFailureAction,
  deleteSettingsOptionsColsOptionPanelPatternRequestAction,
  deleteSettingsOptionsColsOptionPanelPatternSuccessAction,
  deleteSettingsOptionsColsOptionPanelPatternFailureAction,
};

// Actions

export type FetchSettingsOptionsColsOptionPanelPatternRequestAction = ReturnType<
  typeof fetchSettingsOptionsColsOptionPanelPatternRequestAction
>;

export type PostSettingsOptionsColsOptionPanelPatternRequestAction = ReturnType<
  typeof postSettingsOptionsColsOptionPanelPatternRequestAction
>;

export type PatchSettingsOptionsColsOptionPanelPatternRequestAction = ReturnType<
  typeof patchSettingsOptionsColsOptionPanelPatternRequestAction
>;

export type DeleteSettingsOptionsColsOptionPanelPatternRequestAction = ReturnType<
  typeof deleteSettingsOptionsColsOptionPanelPatternRequestAction
>;

type SettingsOptionsColsOptionPanelPatternAction =
  | FetchSettingsOptionsColsOptionPanelPatternRequestAction
  | ReturnType<typeof fetchSettingsOptionsColsOptionPanelPatternRequestAction>
  | ReturnType<typeof fetchSettingsOptionsColsOptionPanelPatternSuccessAction>
  | ReturnType<typeof renewSettingsOptionsColsOptionPanelPatternAction>
  | PostSettingsOptionsColsOptionPanelPatternRequestAction
  | ReturnType<typeof postSettingsOptionsColsOptionPanelPatternRequestAction>
  | ReturnType<typeof postSettingsOptionsColsOptionPanelPatternSuccessAction>
  | ReturnType<typeof postSettingsOptionsColsOptionPanelPatternFailureAction>
  | PatchSettingsOptionsColsOptionPanelPatternRequestAction
  | ReturnType<typeof patchSettingsOptionsColsOptionPanelPatternRequestAction>
  | ReturnType<typeof patchSettingsOptionsColsOptionPanelPatternSuccessAction>
  | ReturnType<typeof patchSettingsOptionsColsOptionPanelPatternFailureAction>
  | DeleteSettingsOptionsColsOptionPanelPatternRequestAction
  | ReturnType<typeof deleteSettingsOptionsColsOptionPanelPatternRequestAction>
  | ReturnType<typeof deleteSettingsOptionsColsOptionPanelPatternSuccessAction>
  | ReturnType<typeof deleteSettingsOptionsColsOptionPanelPatternFailureAction>;

// State

export type SettingsOptionsColsOptionPanelPatternState = {
  isLoading: boolean;
  settingsOptionsColsOptionPanelPatterns?: SettingsOptionsFieldPatterns;
};

const initialState: SettingsOptionsColsOptionPanelPatternState = {
  isLoading: false,
  settingsOptionsColsOptionPanelPatterns: undefined,
};

// Selector

export function settingsOptionsColsOptionPanelPatternSelector(rootState: {
  settingsOptionsColsOptionPanelPattern: SettingsOptionsColsOptionPanelPatternState;
}) {
  return rootState.settingsOptionsColsOptionPanelPattern;
}

/**
 * テーブルが存在するか取得する
 * @returns テーブルデータ
 */
export const settingsOptionsColsOptionPanelPatternIsExistSelector = createSelector(
  settingsOptionsColsOptionPanelPatternSelector,
  (data) => (data === undefined ? false : true)
);

// Reducer

export function settingsOptionsColsOptionPanelPatternReducer(
  state = initialState,
  action: SettingsOptionsColsOptionPanelPatternAction
) {
  switch (action.type) {
    case FETCH_SETTINGS_OPTIONS_COLS_OPTION_PANEL_PATTERN_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_SETTINGS_OPTIONS_COLS_OPTION_PANEL_PATTERN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        settingsOptionsColsOptionPanelPatterns:
          action.payload.settingsOptionsColsOptionPanelPatterns,
      };
    case RENEW_SETTINGS_OPTIONS_COLS_OPTION_PANEL_PATTERN:
      return {
        ...state,
        isLoading: false,
      };
    case POST_SETTINGS_OPTIONS_COLS_OPTION_PANEL_PATTERN_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case POST_SETTINGS_OPTIONS_COLS_OPTION_PANEL_PATTERN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        settingsOptionsColsOptionPanelPatterns:
          action.payload.settingsOptionsColsOptionPanelPatterns,
      };
    case POST_SETTINGS_OPTIONS_COLS_OPTION_PANEL_PATTERN_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case PATCH_SETTINGS_OPTIONS_COLS_OPTION_PANEL_PATTERN_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case PATCH_SETTINGS_OPTIONS_COLS_OPTION_PANEL_PATTERN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        settingsOptionsColsOptionPanelPatterns:
          action.payload.settingsOptionsColsOptionPanelPatterns,
      };
    case PATCH_SETTINGS_OPTIONS_COLS_OPTION_PANEL_PATTERN_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case DELETE_SETTINGS_OPTIONS_COLS_OPTION_PANEL_PATTERN_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case DELETE_SETTINGS_OPTIONS_COLS_OPTION_PANEL_PATTERN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        settingsOptionsColsOptionPanelPatterns:
          action.payload.settingsOptionsColsOptionPanelPatterns,
      };
    case DELETE_SETTINGS_OPTIONS_COLS_OPTION_PANEL_PATTERN_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
