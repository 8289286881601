import { AxiosResponse } from 'axios';
import { call, fork, put, takeEvery } from 'redux-saga/effects';

import { LoadedDataStatusesHalls } from '../domain/loadedDataStatusesHalls';

import {
  FetchLoadedDataStatusesHallsAction,
  LoadedDataStatusesHallsActionCreators,
  LoadedDataStatusesHallsActionTypes,
} from '../redux/server/dataLoadedDataStatusesHalls';
import { Api, buildConfig } from '../utils/api';
import { handleErrorSaga } from './errorSaga';

export function* fetchLoadedDataStatusesHallsSaga(
  api: Api,
  action: FetchLoadedDataStatusesHallsAction
) {
  try {
    yield put(
      LoadedDataStatusesHallsActionCreators.fetchLoadedDataStatusesHallsRequestAction()
    );
    const params = action.payload.query;

    const response: AxiosResponse<LoadedDataStatusesHalls> = yield call(
      api.get,
      '/loadedDataStatuses/halls',
      buildConfig(params)
    );
    const loadedDataStatusesHalls: LoadedDataStatusesHalls = response.data;
    yield put(
      LoadedDataStatusesHallsActionCreators.fetchLoadedDataStatusesHallsSuccessAction(
        loadedDataStatusesHalls
      )
    );
  } catch (error: unknown) {
    yield put(
      LoadedDataStatusesHallsActionCreators.renewLoadedDataStatusesHallsAction()
    );
    yield fork(handleErrorSaga, error);
  }
}

function* handleFetchLoadedDataStatusesHallsSaga(api: Api) {
  yield takeEvery(
    LoadedDataStatusesHallsActionTypes.FETCH_LOADED_DATA_STATUSES_HALLS,
    fetchLoadedDataStatusesHallsSaga,
    api
  );
}

export function* loadedDataStatusesHallsSagas(context: { api: Api }) {
  yield fork(handleFetchLoadedDataStatusesHallsSaga, context.api);
}
