import { AxiosResponse } from 'axios';
import { call, fork, put, select, takeEvery } from 'redux-saga/effects';

import {
  DataChainStoreKi,
  DataChainStoreKiParams,
} from '../domain/dataChainStoreKi';
import { MAIN_FIELD_TYPE } from '../domain/schemas';

import { dataChainStoreKiDataSearchConditionSelector } from '../redux/server/dataChainStoreKi';
import {
  DataChainStoreKi2ndRowActionCreators,
  DataChainStoreKi2ndRowActionTypes,
  FetchDataChainStoreKi2ndRowAction,
  SearchDataChainStoreKi2ndRowAction,
  ToggleDataChainStoreKi2ndRowAction,
  singleDataChainStoreKi2ndRowSelector,
} from '../redux/server/dataChainStoreKi2ndRow';
import { Api, buildConfig } from '../utils/api';
import { handleErrorSaga } from './errorSaga';

/**
 * kiCode を元に検索条件を加工する
 * @param kiCode kiList の先頭の機種
 * @param searchCondition 検索条件
 * @returns 加工された検索条件
 */
const queryParameterToFormConditions = (
  kiCode: string,
  searchCondition: DataChainStoreKiParams
): DataChainStoreKiParams => {
  return {
    ...searchCondition,
    // 展開行は hlMei を指定
    mainField: MAIN_FIELD_TYPE.HL_MEI,
    // kiList を kiCode のみに変更する
    ...{ kiList: [kiCode] },
  };
};

/**
 * 導入後推移の展開行を表示する
 * @param action Action
 */
function* searchDataChainStoreKi2ndRowSaga(
  action: SearchDataChainStoreKi2ndRowAction
) {
  const { shuCode, kiCode } = action.payload;

  // 現在の検索条件
  const searchCondition: DataChainStoreKiParams = yield select(
    dataChainStoreKiDataSearchConditionSelector(shuCode)
  );

  // 検索条件を加工
  const params = queryParameterToFormConditions(kiCode, searchCondition);

  // 展開行を取得する
  yield put(
    DataChainStoreKi2ndRowActionCreators.fetchDataChainStoreKi2ndRowAction(
      shuCode,
      kiCode,
      params
    )
  );
}

/**
 * 導入後推移の展開行データを取得する
 * @param api AxiosInstance
 * @param action Action
 */
function* fetchDataChainStoreKi2ndRowSaga(
  api: Api,
  action: FetchDataChainStoreKi2ndRowAction
) {
  const { shuCode, kiCode, params } = action.payload;

  try {
    yield put(
      DataChainStoreKi2ndRowActionCreators.fetchDataChainStoreKi2ndRowRequestAction(
        shuCode,
        kiCode,
        params
      )
    );

    const response: AxiosResponse<DataChainStoreKi> = yield call(
      api.get,
      '/data/chainStore/ki',
      buildConfig(params)
    );

    yield put(
      DataChainStoreKi2ndRowActionCreators.fetchDataChainStoreKi2ndRowSuccessAction(
        shuCode,
        kiCode,
        response.data
      )
    );
  } catch (error: unknown) {
    yield put(
      DataChainStoreKi2ndRowActionCreators.renewDataChainStoreKi2ndRowAction()
    );
    yield fork(handleErrorSaga, error);
  }
}

/**
 * 指定したQueryParameterと一致する展開行を表示・非表示する
 * @param action Action
 */
function* toggleDataChainStoreKi2ndRowSaga(
  action: ToggleDataChainStoreKi2ndRowAction
) {
  const { shuCode, kiCode } = action.payload;

  if (!kiCode) {
    throw new Error('kiCode が指定されていません。');
  }

  // queryParameterをもとに展開行を取得
  const now2ndRow: DataChainStoreKi | undefined = yield select(
    singleDataChainStoreKi2ndRowSelector(shuCode, kiCode)
  );

  if (now2ndRow === undefined) {
    // 未取得の場合データを取得して表示する
    yield put(
      DataChainStoreKi2ndRowActionCreators.searchDataChainStoreKi2ndRowAction(
        shuCode,
        kiCode
      )
    );
  } else {
    // 取得済みの場合はデータを削除する
    yield put(
      DataChainStoreKi2ndRowActionCreators.hideDataChainStoreKi2ndRowAction(
        shuCode,
        kiCode
      )
    );
  }
}

/**
 * SEARCH_DATA_TRANSITION_AFTER_INTRODUCTION_2NDROWがDispatchされた時の処理
 */
function* handleSearchDataChainStoreKi2ndRowSaga() {
  yield takeEvery(
    DataChainStoreKi2ndRowActionTypes.SEARCH_DATA_CHAIN_STORE_KI_2NDROW,
    searchDataChainStoreKi2ndRowSaga
  );
}

/**
 * FETCH_DATA_CHAIN_STORE_KI_2NDROWがDispatchされた時の処理
 */
function* handleFetchDataChainStoreKi2ndRowSaga(api: Api) {
  yield takeEvery(
    DataChainStoreKi2ndRowActionTypes.FETCH_DATA_CHAIN_STORE_KI_2NDROW,
    fetchDataChainStoreKi2ndRowSaga,
    api
  );
}

/**
 * TOGGLE_DATA_CHAIN_STORE_KI_2NDROWがDispatchされた時の処理
 */
function* handleToggleDataChainStoreKi2ndRowSaga() {
  yield takeEvery(
    DataChainStoreKi2ndRowActionTypes.TOGGLE_DATA_CHAIN_STORE_KI_2NDROW,
    toggleDataChainStoreKi2ndRowSaga
  );
}

export function* dataChainStoreKi2ndRowSagas(context: { api: Api }) {
  yield fork(handleSearchDataChainStoreKi2ndRowSaga);
  yield fork(handleFetchDataChainStoreKi2ndRowSaga, context.api);
  yield fork(handleToggleDataChainStoreKi2ndRowSaga); // 展開行を表示・非表示
}
