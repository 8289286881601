import {
  applyMiddleware,
  combineReducers,
  compose,
  legacy_createStore as createStore,
} from 'redux';
import createSagaMiddleware from 'redux-saga';

import { dataChainStoreKiReducer } from './redux/server/dataChainStoreKi';
import { dataChainStoreKi2ndRowReducer } from './redux/server/dataChainStoreKi2ndRow';
import { dataChainStoreKiGraphReducer } from './redux/server/dataChainStoreKiGraph';
import { dataChainStoreShuReducer } from './redux/server/dataChainStoreShu';
import { dataChainStoreShuGraphReducer } from './redux/server/dataChainStoreShuGraph';
import { dataChainStoreShuKadoGraphReducer } from './redux/server/dataChainStoreShuKadoGraph';
import { dataChainStoreShuWholeReducer } from './redux/server/dataChainStoreShuWhole';
import { dataDaiReducer } from './redux/server/dataDai';
import { dataDepreciationReducer } from './redux/server/dataDepreciation';
import { dataHallDailyCommentsReducer } from './redux/server/dataHallDailyComments';
import { dataHallKiReducer } from './redux/server/dataHallKi';
import { dataHallKiGraphReducer } from './redux/server/dataHallKiGraph';
import { dataHallMksReducer } from './redux/server/dataHallMks';
import { dataHallShuReducer } from './redux/server/dataHallShu';
import { dataHallShuKadoTableReducer } from './redux/server/dataHallShuKadoTable';
import { dataHallShuKadoTableGraphReducer } from './redux/server/dataHallShuKadoTableGraph';
import { dataKiReducer } from './redux/server/dataKi';
import { dataKi2ndRowReducer } from './redux/server/dataKi2ndRow';
import { dataKi3rdRowReducer } from './redux/server/dataKi3rdRow';
import { dataKiDaiReducer } from './redux/server/dataKiDai';
import { dataKiGraphReducer } from './redux/server/dataKiGraph';
import { dataKiKasidamaReducer } from './redux/server/dataKiKasidama';
import { dataKiMksTransitiveGraphReducer } from './redux/server/dataKiMksTransitiveGraph';
import { loadedDataStatusesHallsReducer } from './redux/server/dataLoadedDataStatusesHalls';
import { dataModeReducer } from './redux/server/dataMode';
import { dataModeKiListReducer } from './redux/server/dataModeKiList';
import { dataModelTransitionReducer } from './redux/server/dataModelTransition';
import { dataModelTransition2ndRowReducer } from './redux/server/dataModelTransition2ndRow';
import { dataModelTransitionGraphReducer } from './redux/server/dataModelTransitionGraph';
import { dataPlanProgressTransitionReducer } from './redux/server/dataPlanProgressTransition';
import { dataPlanProgressTransitionGraphReducer } from './redux/server/dataPlanProgressTransitionGraph';
import { dataPpmShareReducer } from './redux/server/dataPpmShare';
import { dataPpmShareGraphReducer } from './redux/server/dataPpmShareGraph';
import { dataPpmShareTransitiveGraphReducer } from './redux/server/dataPpmShareTransitiveGraph';
import { dataAreaReducer } from './redux/server/dataSettingsAreas';
import { dataSettingsDaiCostsReducer } from './redux/server/dataSettingsDaiCosts';
import { dataSettingsDataDeletionReducer } from './redux/server/dataSettingsDataDeletion';
import { dataSettingsEventsReducer } from './redux/server/dataSettingsEvents';
import { dataSettingsKiTagsReducer } from './redux/server/dataSettingsKiTags';
import { dataSettingsModesDetailsReducer } from './redux/server/dataSettingsModesDetails';
import { dataSettingsModesHallsReducer } from './redux/server/dataSettingsModesHalls';
import { dataSettingsUnitModelReducer } from './redux/server/dataSettingsUnitModel';
import { dataSisReducer } from './redux/server/dataSis';
import { dataSisGraphReducer } from './redux/server/dataSisGraph';
import { dataTerminalTransitionReducer } from './redux/server/dataTerminalTransition';
import { dataTerminalTransition2ndRowReducer } from './redux/server/dataTerminalTransition2ndRow';
import { dataTerminalTransitionGraphReducer } from './redux/server/dataTerminalTransitionGraph';
import { dataTransitionAfterIntroductionReducer } from './redux/server/dataTransitionAfterIntroduction';
import { dataTransitionAfterIntroduction2ndRowReducer } from './redux/server/dataTransitionAfterIntroduction2ndRow';
import { dataTransitionAfterIntroductionKiGraphReducer } from './redux/server/dataTransitionAfterIntroductionKiGraph';
import { settingsCommentsMonthToDateReducer } from './redux/server/settingsCommentsMonthToDate';
import { settingsHallsReducer } from './redux/server/settingsHalls';
import { settingsKiLinkReducer } from './redux/server/settingsKiLink';
import { settingsNationalHolidaysReducer } from './redux/server/settingsNationalHolidays';
import { settingsOptionsChainStoreReducer } from './redux/server/settingsOptionsChainStore';
import { settingsOptionsColsOptionPanelPatternReducer } from './redux/server/settingsOptionsColsOptionPanelPattern';
import { settingsOptionsDaiReducer } from './redux/server/settingsOptionsDai';
import { settingsOptionsDataDeletionReducer } from './redux/server/settingsOptionsDataDeletion';
import { settingsOptionsDepreciationReducer } from './redux/server/settingsOptionsDepreciation';
import { settingsOptionsEventsReducer } from './redux/server/settingsOptionsEvents';
import { settingsOptionsHallReducer } from './redux/server/settingsOptionsHall';
import { settingsOptionsHomeReducer } from './redux/server/settingsOptionsHome';
import { settingsOptionsKiReducer } from './redux/server/settingsOptionsKi';
import { settingsOptionsKiLinkReducer } from './redux/server/settingsOptionsKiLink';
import { settingsOptionsModeReducer } from './redux/server/settingsOptionsMode';
import { settingsOptionsModelTransitionReducer } from './redux/server/settingsOptionsModelTransition';
import { settingsOptionsPlanReducer } from './redux/server/settingsOptionsPlan';
import { settingsOptionsPlanProgressTransitionReducer } from './redux/server/settingsOptionsPlanProgressTransition';
import { settingsOptionsPpmShareReducer } from './redux/server/settingsOptionsPpmShare';
import { settingsOptionsSisReducer } from './redux/server/settingsOptionsSis';
import { settingsOptionsTerminalTransitionReducer } from './redux/server/settingsOptionsTerminalTransition';
import { settingsOptionsTransitionAfterIntroductionReducer } from './redux/server/settingsOptionsTransitionAfterIntroduction';
import { settingsOptionsUnitModelReducer } from './redux/server/settingsOptionsUnitModel';
import { settingsOptionsUserReducer } from './redux/server/settingsOptionsUser';
import { settingsPlansReducer } from './redux/server/settingsPlans';
import { settingsPlansDetailsReducer } from './redux/server/settingsPlansDetails';
import { settingsShuGroupsReducer } from './redux/server/settingsShuGroups';
import { settingsUsersReducer } from './redux/server/settingsUsers';
import { shortenedUrlOriginalHashReducer } from './redux/server/shortendUrlOriginalHash';
import { shortenedUrlReducer } from './redux/server/shortenedUrl';
import { chainStoreReportsSettingReducer } from './redux/ui/chainStoreReportsSetting';
import { daiCostsSettingReducer } from './redux/ui/daiCostsSetting';
import { depreciationSettingReducer } from './redux/ui/depreciationSetting';
import { errorReducer } from './redux/ui/error';
import { eventsSettingReducer } from './redux/ui/eventsSetting';
import { homeReducer } from './redux/ui/favorite';
import { hallReportsSettingReducer } from './redux/ui/hallReportsSetting';
import { homeSettingReducer } from './redux/ui/homeSetting';
import { informationReducer } from './redux/ui/information';
import { modeSettingReducer } from './redux/ui/modeSettings';
import { modelReportsSettingReducer } from './redux/ui/modelReportsSetting';
import { modelTransitionReportsSettingReducer } from './redux/ui/modelTransitionReportsSetting';
import { planProgressTransitionReportsSettingReducer } from './redux/ui/planProgressTransitionReportsSetting';
import { ppmShareReportsSettingReducer } from './redux/ui/ppmShareReportsSetting';
import { settingsDaiCostsReducer } from './redux/ui/settingsDaiCosts';
import { settingsDataDeletionReducer } from './redux/ui/settingsDataDeletion';
import { favoritesReducer } from './redux/ui/settingsFavorites';
import { SettingsKiTagsReducer } from './redux/ui/settingsKiTags';
import { settingsModesDetailsReducer } from './redux/ui/settingsModesDetails';
import { settingsUnitModelReducer } from './redux/ui/settingsUnitModel';
import { settingsSisReducer } from './redux/ui/sisSettings';
import { terminalTransitionReportsSettingReducer } from './redux/ui/terminalTransitionReportsSetting';
import { transitionAfterIntroductionReportsSettingReducer } from './redux/ui/transitionAfterIntroductionReportsSetting';
import { unitDataListReportsSettingReducer } from './redux/ui/unitDataListReportsSetting';
import { userReducer } from './redux/ui/user';
import rootSaga from './sagas';
import { api } from './utils/api';

export type RootState = {
  user: ReturnType<typeof userReducer>;
  modelReportsSetting: ReturnType<typeof modelReportsSettingReducer>;
  dataKi: ReturnType<typeof dataKiReducer>;
  dataKi2ndRow: ReturnType<typeof dataKi2ndRowReducer>;
  dataKi3rdRow: ReturnType<typeof dataKi3rdRowReducer>;
  dataKiGraph: ReturnType<typeof dataKiGraphReducer>;
  dataKiMksTransitiveGraph: ReturnType<typeof dataKiMksTransitiveGraphReducer>;
  dataKiDai: ReturnType<typeof dataKiDaiReducer>;
  dataKiKasidama: ReturnType<typeof dataKiKasidamaReducer>;
  hallReportsSetting: ReturnType<typeof hallReportsSettingReducer>;
  dataHallShu: ReturnType<typeof dataHallShuReducer>;
  dataHallKi: ReturnType<typeof dataHallKiReducer>;
  dataHallMks: ReturnType<typeof dataHallMksReducer>;
  chainStoreReportsSetting: ReturnType<typeof chainStoreReportsSettingReducer>;
  dataChainStoreShuWhole: ReturnType<typeof dataChainStoreShuWholeReducer>;
  dataChainStoreShu: ReturnType<typeof dataChainStoreShuReducer>;
  dataChainStoreShuKadoGraph: ReturnType<
    typeof dataChainStoreShuKadoGraphReducer
  >;
  dataChainStoreShuGraph: ReturnType<typeof dataChainStoreShuGraphReducer>;

  dataHallShuKadoTableGraph: ReturnType<
    typeof dataHallShuKadoTableGraphReducer
  >;
  dataHallDailyComments: ReturnType<typeof dataHallDailyCommentsReducer>;
  dataHallMksReducer: ReturnType<typeof dataHallMksReducer>;
  dataHallShuKadoTable: ReturnType<typeof dataHallShuKadoTableReducer>;
  dataChainStoreKi: ReturnType<typeof dataChainStoreKiReducer>;
  dataChainStoreKi2ndRowReducer: ReturnType<
    typeof dataChainStoreKi2ndRowReducer
  >;
  dataTransitionAfterIntroduction: ReturnType<
    typeof dataTransitionAfterIntroductionReducer
  >;
  dataTransitionAfterIntroduction2ndRow: ReturnType<
    typeof dataTransitionAfterIntroduction2ndRowReducer
  >;
  dataTransitionAfterIntroductionKiGraph: ReturnType<
    typeof dataTransitionAfterIntroductionKiGraphReducer
  >;
  dataPpmShare: ReturnType<typeof dataPpmShareReducer>;
  dataPpmShareTransitiveGraph: ReturnType<
    typeof dataPpmShareTransitiveGraphReducer
  >;
  dataPpmShareGraph: ReturnType<typeof dataPpmShareGraphReducer>;
  terminalTransitionReportsSetting: ReturnType<
    typeof terminalTransitionReportsSettingReducer
  >;
  dataModelTransition: ReturnType<typeof dataModelTransitionReducer>;
  dataModelTransition2ndRow: ReturnType<
    typeof dataModelTransition2ndRowReducer
  >;
  dataModelTransitionGraph: ReturnType<typeof dataModelTransitionGraphReducer>;
  dataTerminalTransition: ReturnType<typeof dataTerminalTransitionReducer>;
  dataTerminalTransitionGraph: ReturnType<
    typeof dataTerminalTransitionGraphReducer
  >;
  dataPlanProgressTransition: ReturnType<
    typeof dataPlanProgressTransitionReducer
  >;
  dataPlanProgressTransitionGraph: ReturnType<
    typeof dataPlanProgressTransitionGraphReducer
  >;
  dataTerminalTransition2ndRow: ReturnType<
    typeof dataTerminalTransition2ndRowReducer
  >;
  dataDai: ReturnType<typeof dataDaiReducer>;
  modelTransitionReportsSetting: ReturnType<
    typeof modelTransitionReportsSettingReducer
  >;
  dataSis: ReturnType<typeof dataSisReducer>;
  dataSisGraph: ReturnType<typeof dataSisGraphReducer>;
  settingsOptionsSis: ReturnType<typeof settingsOptionsSisReducer>;
  sisSettings: ReturnType<typeof settingsSisReducer>;
  settingsNationalHolidays: ReturnType<typeof settingsNationalHolidaysReducer>;
  settingsOptionsKi: ReturnType<typeof settingsOptionsKiReducer>;
  settingsOptionsChainStore: ReturnType<
    typeof settingsOptionsChainStoreReducer
  >;
  settingsOptionsHall: ReturnType<typeof settingsOptionsHallReducer>;
  settingsOptionsHome: ReturnType<typeof settingsOptionsHomeReducer>;
  settingsOptionsPlan: ReturnType<typeof settingsOptionsPlanReducer>;
  settingsOptionsKiLink: ReturnType<typeof settingsOptionsKiLinkReducer>;
  settingsNationalHolidaysReducer: ReturnType<
    typeof settingsNationalHolidaysReducer
  >;
  settingsPlans: ReturnType<typeof settingsPlansReducer>;
  settingsKiLink: ReturnType<typeof settingsKiLinkReducer>;
  settingsPlansDetails: ReturnType<typeof settingsPlansDetailsReducer>;
  settingsFavorites: ReturnType<typeof favoritesReducer>;
  settingsShuGroups: ReturnType<typeof settingsShuGroupsReducer>;
  settingsOptionsTransitionAfterIntroduction: ReturnType<
    typeof settingsOptionsTransitionAfterIntroductionReducer
  >;
  settingsOptionsTerminalTransition: ReturnType<
    typeof settingsOptionsTerminalTransitionReducer
  >;
  settingsOptionsModelTransition: ReturnType<
    typeof settingsOptionsModelTransitionReducer
  >;
  settingsOptionsPpmShare: ReturnType<typeof settingsOptionsPpmShareReducer>;
  settingsOptionsUser: ReturnType<typeof settingsOptionsUserReducer>;
  settingsUsers: ReturnType<typeof settingsUsersReducer>;
  loadedDataStatusesHalls: ReturnType<typeof loadedDataStatusesHallsReducer>;
  dataChainStoreKiGraph: ReturnType<typeof dataChainStoreKiGraphReducer>;
  dataHallKiGraph: ReturnType<typeof dataHallKiGraphReducer>;
  transitionAfterIntroductionReportsSetting: ReturnType<
    typeof transitionAfterIntroductionReportsSettingReducer
  >;
  information: ReturnType<typeof informationReducer>;
  home: ReturnType<typeof homeReducer>;
  settingsOptionsPlanProgressTransition: ReturnType<
    typeof settingsOptionsPlanProgressTransitionReducer
  >;
  planProgressTransitionReportsSetting: ReturnType<
    typeof planProgressTransitionReportsSettingReducer
  >;
  ppmShareReportsSetting: ReturnType<typeof ppmShareReportsSettingReducer>;

  settingsOptionsUnitModel: ReturnType<typeof settingsOptionsUnitModelReducer>;
  settingsUnitModel: ReturnType<typeof settingsUnitModelReducer>;
  dataSettingsUnitModel: ReturnType<typeof dataSettingsUnitModelReducer>;
  settingsOptionsDataDeletion: ReturnType<
    typeof settingsOptionsDataDeletionReducer
  >;
  settingsDataDeletion: ReturnType<typeof settingsDataDeletionReducer>;
  dataSettingsDataDeletion: ReturnType<typeof dataSettingsDataDeletionReducer>;
  settingsKiTags: ReturnType<typeof SettingsKiTagsReducer>;
  dataSettingsKiTags: ReturnType<typeof dataSettingsKiTagsReducer>;
  dataSettingsArea: ReturnType<typeof dataAreaReducer>;
  error: ReturnType<typeof errorReducer>;
  settingsOptionsDai: ReturnType<typeof settingsOptionsDaiReducer>;
  unitDataListReportsSetting: ReturnType<
    typeof unitDataListReportsSettingReducer
  >;
  settingsCommentsMonthToDate: ReturnType<
    typeof settingsCommentsMonthToDateReducer
  >;
  dataMode: ReturnType<typeof dataModeReducer>;
  dataModeKiList: ReturnType<typeof dataModeKiListReducer>;
  settingsOptionsMode: ReturnType<typeof settingsOptionsModeReducer>;
  modeSetting: ReturnType<typeof modeSettingReducer>;
  dataSettingsModesHalls: ReturnType<typeof dataSettingsModesHallsReducer>;
  dataSettingsModesDetails: ReturnType<typeof dataSettingsModesDetailsReducer>;
  settingsModesDetails: ReturnType<typeof settingsModesDetailsReducer>;
  shortenedUrl: ReturnType<typeof shortenedUrlReducer>;
  originalHash: ReturnType<typeof shortenedUrlOriginalHashReducer>;
  dataSettingsEvents: ReturnType<typeof dataSettingsEventsReducer>;
  settingsOptionsEvents: ReturnType<typeof settingsOptionsEventsReducer>;
  eventsSetting: ReturnType<typeof eventsSettingReducer>;
  dataSettingsDaiCosts: ReturnType<typeof dataSettingsDaiCostsReducer>;
  daiCostsSetting: ReturnType<typeof daiCostsSettingReducer>;
  dataDepreciation: ReturnType<typeof dataDepreciationReducer>;
  settingsOptionsDepreciation: ReturnType<
    typeof settingsOptionsDepreciationReducer
  >;
  depreciationSettings: ReturnType<typeof depreciationSettingReducer>;
  settingsDaiCosts: ReturnType<typeof dataSettingsDaiCostsReducer>;
  homeSetting: ReturnType<typeof homeSettingReducer>;
};

const isDevelopment = process.env.NODE_ENV === 'development';

const composeEnhancers =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (isDevelopment && (window as any)?.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

export function createFinalStore() {
  const reducers = combineReducers({
    user: userReducer,
    modelReportsSetting: modelReportsSettingReducer,
    dataKi: dataKiReducer,
    dataKi2ndRow: dataKi2ndRowReducer,
    dataKi3rdRow: dataKi3rdRowReducer,
    dataKiGraph: dataKiGraphReducer,
    dataKiMksTransitiveGraph: dataKiMksTransitiveGraphReducer,
    dataKiDai: dataKiDaiReducer,
    dataKiKasidama: dataKiKasidamaReducer,
    hallReportsSetting: hallReportsSettingReducer,
    dataHallShu: dataHallShuReducer,
    dataHallKi: dataHallKiReducer,
    chainStoreReportsSetting: chainStoreReportsSettingReducer,
    dataChainStoreShuWhole: dataChainStoreShuWholeReducer,
    dataChainStoreShu: dataChainStoreShuReducer,
    dataChainStoreShuKadoGraph: dataChainStoreShuKadoGraphReducer,
    dataChainStoreShuGraph: dataChainStoreShuGraphReducer,
    dataHallShuKadoTableGraph: dataHallShuKadoTableGraphReducer,
    dataHallMks: dataHallMksReducer,
    dataHallShuKadoTable: dataHallShuKadoTableReducer,
    dataHallDailyComments: dataHallDailyCommentsReducer,
    dataChainStoreKi: dataChainStoreKiReducer,
    dataChainStoreKi2ndRow: dataChainStoreKi2ndRowReducer,
    dataTransitionAfterIntroduction: dataTransitionAfterIntroductionReducer,
    dataTransitionAfterIntroduction2ndRow: dataTransitionAfterIntroduction2ndRowReducer,
    dataPpmShare: dataPpmShareReducer,
    dataPpmShareTransitiveGraph: dataPpmShareTransitiveGraphReducer,
    dataPpmShareGraph: dataPpmShareGraphReducer,
    terminalTransitionReportsSetting: terminalTransitionReportsSettingReducer,
    dataModelTransition: dataModelTransitionReducer,
    dataModelTransition2ndRow: dataModelTransition2ndRowReducer,
    dataModelTransitionGraph: dataModelTransitionGraphReducer,
    dataTerminalTransition: dataTerminalTransitionReducer,
    dataTerminalTransitionGraph: dataTerminalTransitionGraphReducer,
    dataPlanProgressTransition: dataPlanProgressTransitionReducer,
    dataPlanProgressTransitionGraph: dataPlanProgressTransitionGraphReducer,
    dataTerminalTransition2ndRow: dataTerminalTransition2ndRowReducer,
    dataTransitionAfterIntroductionKiGraph: dataTransitionAfterIntroductionKiGraphReducer,
    dataDai: dataDaiReducer,
    modelTransitionReportsSetting: modelTransitionReportsSettingReducer,
    settingsOptionsKi: settingsOptionsKiReducer,
    settingsOptionsChainStore: settingsOptionsChainStoreReducer,
    settingsOptionsHall: settingsOptionsHallReducer,
    settingsOptionsHome: settingsOptionsHomeReducer,
    settingsOptionsPlan: settingsOptionsPlanReducer,
    settingsOptionsKiLink: settingsOptionsKiLinkReducer,
    settingsPlans: settingsPlansReducer,
    settingsKiLink: settingsKiLinkReducer,
    settingsPlansDetails: settingsPlansDetailsReducer,
    settingsFavorites: favoritesReducer,
    settingsHalls: settingsHallsReducer,
    settingsShuGroups: settingsShuGroupsReducer,
    settingsNationalHolidays: settingsNationalHolidaysReducer,
    settingsOptionsTransitionAfterIntroduction: settingsOptionsTransitionAfterIntroductionReducer,
    settingsOptionsTerminalTransition: settingsOptionsTerminalTransitionReducer,
    settingsOptionsModelTransition: settingsOptionsModelTransitionReducer,
    settingsOptionsUser: settingsOptionsUserReducer,
    settingsUsers: settingsUsersReducer,
    settingsOptionsColsOptionPanelPattern: settingsOptionsColsOptionPanelPatternReducer,
    dataChainStoreKiGraph: dataChainStoreKiGraphReducer,
    dataHallKiGraph: dataHallKiGraphReducer,
    loadedDataStatusesHalls: loadedDataStatusesHallsReducer,
    transitionAfterIntroductionReportsSetting: transitionAfterIntroductionReportsSettingReducer,
    information: informationReducer,
    home: homeReducer,
    settingsOptionsPlanProgressTransition: settingsOptionsPlanProgressTransitionReducer,
    planProgressTransitionReportsSetting: planProgressTransitionReportsSettingReducer,
    settingsOptionsPpmShare: settingsOptionsPpmShareReducer,
    ppmShareReportsSetting: ppmShareReportsSettingReducer,
    settingsOptionsUnitModel: settingsOptionsUnitModelReducer,
    settingsUnitModel: settingsUnitModelReducer,
    dataSettingsUnitModel: dataSettingsUnitModelReducer,
    settingsOptionsDataDeletion: settingsOptionsDataDeletionReducer,
    settingsDataDeletion: settingsDataDeletionReducer,
    dataSettingsDataDeletion: dataSettingsDataDeletionReducer,
    settingsKiTags: SettingsKiTagsReducer,
    dataSettingsKiTags: dataSettingsKiTagsReducer,
    dataSettingsAreas: dataAreaReducer,
    error: errorReducer,
    settingsOptionsDai: settingsOptionsDaiReducer,
    unitDataListReportsSetting: unitDataListReportsSettingReducer,
    settingsCommentsMonthToDate: settingsCommentsMonthToDateReducer,
    dataMode: dataModeReducer,
    dataModeKiList: dataModeKiListReducer,
    settingsOptionsMode: settingsOptionsModeReducer,
    modeSetting: modeSettingReducer,
    dataSettingsModesHalls: dataSettingsModesHallsReducer,
    dataSettingsModesDetails: dataSettingsModesDetailsReducer,
    settingsModesDetails: settingsModesDetailsReducer,
    dataSis: dataSisReducer,
    dataSisGraph: dataSisGraphReducer,
    settingsOptionsSis: settingsOptionsSisReducer,
    sisSettings: settingsSisReducer,
    shortenedUrl: shortenedUrlReducer,
    originalHash: shortenedUrlOriginalHashReducer,
    dataSettingsEvents: dataSettingsEventsReducer,
    settingsOptionsEvents: settingsOptionsEventsReducer,
    eventsSetting: eventsSettingReducer,
    dataSettingsDaiCosts: dataSettingsDaiCostsReducer,
    daiCostsSetting: daiCostsSettingReducer,
    dataDepreciation: dataDepreciationReducer,
    settingsOptionsDepreciation: settingsOptionsDepreciationReducer,
    depreciationSettings: depreciationSettingReducer,
    settingsDaiCosts: settingsDaiCostsReducer,
    homeSetting: homeSettingReducer,
  });
  const sagaMiddleware = createSagaMiddleware();
  const enhancers = composeEnhancers(applyMiddleware(sagaMiddleware));

  const store = createStore(reducers, enhancers);

  sagaMiddleware.run(rootSaga, { api });

  return store;
}
