import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { FC, useId } from 'react';

type Props = {
  isOpen: boolean;
  close: () => void;
  statusCode?: number | undefined;
  message?: string | undefined;
};

export const ErrorDialog: FC<Props> = (props) => {
  const { isOpen, close, statusCode, message } = props;

  const titleId = useId();
  const descriptionId = useId();

  return (
    <Dialog
      open={isOpen}
      onClose={() => close()}
      aria-labelledby={titleId}
      aria-describedby={descriptionId}
    >
      <DialogTitle id={titleId}>
        {statusCode != null ? `${statusCode} Error` : 'Error'}
      </DialogTitle>
      <DialogContent>
        <p>エラーが発生しました</p>
        {message && (
          <DialogContentText
            id={descriptionId}
            color="error"
            sx={{ whiteSpace: 'pre-line' }}
          >
            {message}
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => close()} autoFocus>
          閉じる
        </Button>
      </DialogActions>
    </Dialog>
  );
};
