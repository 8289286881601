import { AxiosResponse } from 'axios';
import { call, fork, put, takeEvery } from 'redux-saga/effects';

import { SettingsShuGroups } from '../domain/settingsShuGroups';

import {
  DeleteSettingsShuGroupsAction,
  PostSettingsShuGroupsAction,
  PutSettingsShuGroupsAction,
  SettingsShuGroupsActionCreators,
  SettingsShuGroupsActionTypes,
} from '../redux/server/settingsShuGroups';
import { Api, buildConfig } from '../utils/api';
import { handleErrorSaga } from './errorSaga';

export function* fetchSettingsShuGroupsSaga(api: Api) {
  try {
    yield put(
      SettingsShuGroupsActionCreators.fetchSettingsShuGroupsRequestAction()
    );
    const response: AxiosResponse<SettingsShuGroups> = yield call(
      api.get,
      `/settings/shuGroups`,
      buildConfig()
    );
    const settingsShuGroups = response.data;
    yield put(
      SettingsShuGroupsActionCreators.fetchSettingsShuGroupsSuccessAction(
        settingsShuGroups
      )
    );
  } catch (error: unknown) {
    yield put(SettingsShuGroupsActionCreators.renewSettingsShuGroupsAction());
    yield fork(handleErrorSaga, error);
  }
}

export function* postSettingsShuGroupsSaga(
  api: Api,
  action: PostSettingsShuGroupsAction
) {
  try {
    const params = action.payload.query;

    yield put(
      SettingsShuGroupsActionCreators.postSettingsShuGroupsRequestAction()
    );
    const response: AxiosResponse<SettingsShuGroups> = yield call(
      api.post,
      `/settings/shuGroups`,
      params,
      buildConfig()
    );
    const settingsShuGroups = response.data;
    yield put(
      SettingsShuGroupsActionCreators.postSettingsShuGroupsSuccessAction(
        settingsShuGroups
      )
    );
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    yield put(
      SettingsShuGroupsActionCreators.postSettingsShuGroupsFailureAction(
        error.response
      )
    );
    yield fork(handleErrorSaga, error);
  }
}

export function* putSettingsShuGroupsSaga(
  api: Api,
  action: PutSettingsShuGroupsAction
) {
  try {
    const settingId = action.payload.id;
    const params = action.payload.query;

    yield put(
      SettingsShuGroupsActionCreators.putSettingsShuGroupsRequestAction()
    );
    const response: AxiosResponse<SettingsShuGroups> = yield call(
      api.put,
      `/settings/shuGroups/${settingId}`,
      params,
      buildConfig()
    );
    const settingsShuGroups = response.data;
    yield put(
      SettingsShuGroupsActionCreators.putSettingsShuGroupsSuccessAction(
        settingsShuGroups
      )
    );
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    yield put(
      SettingsShuGroupsActionCreators.putSettingsShuGroupsFailureAction(
        error.response
      )
    );
    yield fork(handleErrorSaga, error);
  }
}

export function* deleteSettingsShuGroupsSaga(
  api: Api,
  action: DeleteSettingsShuGroupsAction
) {
  try {
    const settingId = action.payload.id;

    yield put(
      SettingsShuGroupsActionCreators.deleteSettingsShuGroupsRequestAction()
    );
    const response: AxiosResponse<SettingsShuGroups> = yield call(
      api.delete,
      `/settings/shuGroups/${settingId}`,
      buildConfig()
    );
    const settingsShuGroups = response.data;
    yield put(
      SettingsShuGroupsActionCreators.deleteSettingsShuGroupsSuccessAction(
        settingsShuGroups
      )
    );
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    yield put(
      SettingsShuGroupsActionCreators.deleteSettingsShuGroupsFailureAction(
        error.response
      )
    );
    yield fork(handleErrorSaga, error);
  }
}

function* handleFetchSettingsShuGroupsSaga(api: Api) {
  yield takeEvery(
    SettingsShuGroupsActionTypes.FETCH_SETTINGS_SHU_GROUPS,
    fetchSettingsShuGroupsSaga,
    api
  );
}

function* handlePostSettingsShuGroupsSaga(api: Api) {
  yield takeEvery(
    SettingsShuGroupsActionTypes.POST_SETTINGS_SHU_GROUPS,
    postSettingsShuGroupsSaga,
    api
  );
}

function* handlePutSettingsShuGroupsSaga(api: Api) {
  yield takeEvery(
    SettingsShuGroupsActionTypes.PUT_SETTINGS_SHU_GROUPS,
    putSettingsShuGroupsSaga,
    api
  );
}

function* handleDeleteSettingsShuGroupsSaga(api: Api) {
  yield takeEvery(
    SettingsShuGroupsActionTypes.DELETE_SETTINGS_SHU_GROUPS,
    deleteSettingsShuGroupsSaga,
    api
  );
}

export function* settingsShuGroupsSagas(context: { api: Api }) {
  yield fork(handleFetchSettingsShuGroupsSaga, context.api);
  yield fork(handlePostSettingsShuGroupsSaga, context.api);
  yield fork(handlePutSettingsShuGroupsSaga, context.api);
  yield fork(handleDeleteSettingsShuGroupsSaga, context.api);
}
