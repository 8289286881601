import {
  add,
  differenceInDays,
  endOfISOWeek,
  endOfMonth,
  endOfYear,
  set,
  startOfISOWeek,
  startOfMonth,
  startOfYear,
} from 'date-fns';

// PPMシェアで使用する期間タイプ
export const ppmShareDateRanges = [
  '前日',
  '当日',
  '前週',
  '今週',
  '前月',
  '今月',
  '前年',
  '今年',
  '過去7日',
  '過去14日',
  '過去28日',
  'カスタム',
] as const;
export type PpmShareDateRange = typeof ppmShareDateRanges[number];

/**
 * PPMシェア
 * 検索パラメータに渡す日付データを算出する
 * @param dateRange 期間指定
 * @param currentDate 基準日（未指定の場合は本日）
 */
export const ppmShareMakeDateFromDateRange = (
  dateRange: PpmShareDateRange,
  currentDate: Date = new Date()
): {
  startDate: Date;
  endDate: Date;
  startComparisonDate: Date;
  endComparisonDate: Date;
} => {
  let startDate = currentDate;
  let endDate = currentDate;
  switch (dateRange) {
    case '前日': {
      startDate = add(startDate, { days: -1 });
      endDate = startDate;
      break;
    }
    case '当日': {
      startDate = new Date();
      endDate = startDate;
      break;
    }
    case '前週': {
      const lastWeek = add(startDate, { days: -7 });
      startDate = startOfISOWeek(lastWeek);
      endDate = add(startDate, { days: 6 });
      break;
    }
    case '今週': {
      startDate = startOfISOWeek(startDate);
      break;
    }
    case '前月': {
      const lastMonth = add(startDate, { months: -1 });
      startDate = startOfMonth(lastMonth);
      endDate = endOfMonth(lastMonth);
      break;
    }
    case '今月': {
      startDate = startOfMonth(startDate);
      break;
    }
    case '前年': {
      const lastYear = add(startDate, { years: -1 });
      startDate = startOfMonth(set(lastYear, { month: 0 }));
      endDate = endOfMonth(set(lastYear, { month: 11 }));
      break;
    }
    case '今年': {
      startDate = startOfMonth(set(startDate, { month: 0 }));
      break;
    }
    case '過去7日': {
      startDate = add(startDate, { days: -7 });
      endDate = add(endDate, { days: -1 });
      break;
    }
    case '過去14日': {
      startDate = add(startDate, { days: -14 });
      endDate = add(endDate, { days: -1 });
      break;
    }
    case '過去28日': {
      startDate = add(startDate, { days: -28 });
      endDate = add(endDate, { days: -1 });
      break;
    }
    case 'カスタム':
      // do nothing
      break;
  }
  const {
    startComparisonDate,
    endComparisonDate,
  } = ppmShareMakeComparisonDateFromStartAndEnd(dateRange, startDate, endDate);
  // 最終日が本日より大きい場合は本日に合わせる
  endDate = endDate > currentDate ? currentDate : endDate;
  return {
    startDate,
    endDate,
    startComparisonDate,
    endComparisonDate,
  };
};

/**
 * PPMシェア
 * 開始日と終了日から比較期間を算出する
 * @param dateRange 期間指定
 * @param startDate 開始日
 * @param endDate 終了日
 */
export const ppmShareMakeComparisonDateFromStartAndEnd = (
  dateRange: PpmShareDateRange,
  startDate: Date,
  endDate: Date
): {
  startComparisonDate: Date;
  endComparisonDate: Date;
} => {
  switch (dateRange) {
    // 前日、当日の場合は一週間前の同じ曜日を比較対象にする
    case '前日':
    case '当日': {
      const aWeekAgo = add(startDate, { days: -7 });
      return {
        startComparisonDate: aWeekAgo,
        endComparisonDate: aWeekAgo,
      };
    }
    case '前週':
    case '今週': {
      const lastWeek = add(startDate, { weeks: -1 });
      const startComparisonDate = startOfISOWeek(lastWeek);
      const endComparisonDate = endOfISOWeek(lastWeek);
      return {
        startComparisonDate,
        endComparisonDate,
      };
    }
    case '前月':
    case '今月': {
      const lastmonth = add(startDate, { months: -1 });
      const startComparisonDate = startOfMonth(lastmonth);
      const endComparisonDate = endOfMonth(lastmonth);
      return {
        startComparisonDate,
        endComparisonDate,
      };
    }
    case '今年':
    case '前年': {
      const lastmonth = add(startDate, { years: -1 });
      const startComparisonDate = startOfYear(lastmonth);
      const endComparisonDate = endOfYear(lastmonth);
      return {
        startComparisonDate,
        endComparisonDate,
      };
    }
    case '過去7日': {
      const startComparisonDate = add(startDate, { days: -7 });
      const endComparisonDate = add(endDate, { days: -7 });
      return {
        startComparisonDate,
        endComparisonDate,
      };
    }
    case '過去14日': {
      const startComparisonDate = add(startDate, { days: -14 });
      const endComparisonDate = add(endDate, { days: -14 });
      return {
        startComparisonDate,
        endComparisonDate,
      };
    }
    case '過去28日': {
      const startComparisonDate = add(startDate, { days: -28 });
      const endComparisonDate = add(endDate, { days: -28 });
      return {
        startComparisonDate,
        endComparisonDate,
      };
    }
    case 'カスタム': {
      const diff = differenceInDays(startDate, endDate) - 1;
      return {
        startComparisonDate: add(startDate, { days: diff }),
        endComparisonDate: add(endDate, { days: diff }),
      };
    }
  }
};
