import HomeIcon from '@mui/icons-material/Home';
import { SxProps, Theme } from '@mui/material';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { CenterLayout } from '../layouts/CenterLayout';

const styles: Record<string, SxProps<Theme>> = {
  notFoundText: { marginBottom: '2rem' },
  linkContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  homeIcon: { fontSize: '40px' },
};

export const NotFoundPage: FC = () => {
  return (
    <CenterLayout>
      <Typography
        component="h1"
        variant="h3"
        align="center"
        sx={styles.notFoundText}
      >
        ページが見つかりません
      </Typography>

      <Link component={RouterLink} to="/">
        <Container sx={styles.linkContainer}>
          <HomeIcon color="primary" sx={styles.homeIcon} />
          <Typography component="span" variant="h5">
            トップに戻る
          </Typography>
        </Container>
      </Link>
    </CenterLayout>
  );
};
