import { AxiosResponse } from 'axios';
import { call, fork, put, takeEvery } from 'redux-saga/effects';

import { DataDepreciationResponse } from '../domain/depreciation/types';

import { dataDepreciationData } from '../mocks/models/dataDepreciation';
import {
  DataDepreciationActionCreators,
  DataDepreciationActionTypes,
  FetchDataDepreciationAction,
} from '../redux/server/dataDepreciation';
import { Api, buildConfig } from '../utils/api';
import { handleErrorSaga } from './errorSaga';

/**
 * dataDepreciationのデータを取得する
 */
function* fetchDataDepreciationSaga(
  api: Api,
  action: FetchDataDepreciationAction
) {
  try {
    yield put(
      DataDepreciationActionCreators.fetchDataDepreciationRequestAction()
    );

    const response: AxiosResponse<DataDepreciationResponse> = yield call(
      api.get,
      '/data/depreciation',
      buildConfig(action.payload.params)
    );

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const dataDepreciation = response.data;

    yield put(
      DataDepreciationActionCreators.fetchDataDepreciationSuccessAction(
        // dataDepreciation
        // TODO 本来はここで取得したデータを使うITG動作確認ようのためにモックデータを返す
        dataDepreciationData
      )
    );
  } catch (error: unknown) {
    yield put(DataDepreciationActionCreators.renewDataDepreciationAction());
    yield fork(handleErrorSaga, error);
  }
}

function* handleFetchDataDepreciationSaga(api: Api) {
  yield takeEvery(
    DataDepreciationActionTypes.FETCH_DATA_DEPRECIATION,
    fetchDataDepreciationSaga,
    api
  );
}

export function* dataDepreciationSaga(context: { api: Api }) {
  yield fork(handleFetchDataDepreciationSaga, context.api);
}
