/**
 * DK-SISの検索条件の型
 */
export type DkSisSearchParams = {
  sisDateRangeType?: 'monthly' | 'weekly' | 'daily';
  sisStartDate?: string;
  sisEndDate?: string;
  sisBreakEvenForPachinko?: string;
  sisBreakEvenForPachislo?: string;
  sisRank?: string;
  sisArea?: string;
  sisFieldTypes?: string[];
  needsSis?: boolean;
  containsSisAverage?: boolean;
};

/**
 * DK-SISの損益分岐のパチンコの項目
 */
export const SisBreakEvenForPachinkoOption = [
  {
    value: '01',
    label: '16割以上',
  },
  {
    value: '02',
    label: '15割',
  },
  {
    value: '03',
    label: '14割',
  },
  {
    value: '04',
    label: '13割',
  },
  {
    value: '05',
    label: '12割',
  },
  {
    value: '06',
    label: '11割',
  },
  {
    value: '07',
    label: '10割',
  },
];

/**
 * DK-SISの損益分岐のパチスロの項目
 */
export const SisBreakEvenForPachisloOption = [
  {
    value: '01',
    label: '13割以上',
  },
  {
    value: '02',
    label: '12割',
  },
  {
    value: '03',
    label: '11割',
  },
  {
    value: '04',
    label: '10割',
  },
];

/**
 * DK-SISのランクの項目
 */
export const SisRankOption = [
  {
    value: '10',
    label: '10点',
  },
  {
    value: '9',
    label: '9点',
  },
  {
    value: '8',
    label: '8点',
  },
  {
    value: '7',
    label: '7点',
  },
  {
    value: '6',
    label: '6点',
  },
  {
    value: '5',
    label: '5点',
  },
  {
    value: '4',
    label: '4点',
  },
  {
    value: '3',
    label: '3点',
  },
  {
    value: '2',
    label: '2点',
  },
  {
    value: '1',
    label: '1点',
  },
];

/**
 * DK-SISのエリアの項目
 */
export const SisAreaOption = [
  {
    value: '01',
    label: '北海道地区',
  },
  {
    value: '02',
    label: '東北地区',
  },
  {
    value: '03',
    label: '北関東地区',
  },
  {
    value: '04',
    label: '南関東地区',
  },
  {
    value: '05',
    label: '東京地区',
  },
  {
    value: '06',
    label: '北陸甲信越地区',
  },
  {
    value: '07',
    label: '東海地区',
  },
  {
    value: '08',
    label: '近畿地区',
  },
  {
    value: '09',
    label: '中国地区',
  },
  {
    value: '10',
    label: '四国地区',
  },
  {
    value: '11',
    label: '九州沖縄地区',
  },
];
