import { AxiosResponse } from 'axios';
import { call, fork, put, takeEvery } from 'redux-saga/effects';

import { SettingsOptionsHome } from '../domain/settingsOptionsHome';

import {
  SettingsOptionsHomeActionCreators,
  SettingsOptionsHomeActionTypes,
} from '../redux/server/settingsOptionsHome';
import { Api, buildConfig } from '../utils/api';
import { handleErrorSaga } from './errorSaga';

export function* fetchSettingsOptionsHomeSaga(api: Api) {
  try {
    yield put(
      SettingsOptionsHomeActionCreators.fetchSettingsOptionsHomeRequestAction()
    );
    const response: AxiosResponse<SettingsOptionsHome> = yield call(
      api.get,
      '/settings/options/home',
      buildConfig()
    );
    const settingsOptionsHome: SettingsOptionsHome = response.data;
    yield put(
      SettingsOptionsHomeActionCreators.fetchSettingsOptionsHomeSuccessAction(
        settingsOptionsHome
      )
    );
  } catch (error: unknown) {
    yield put(
      SettingsOptionsHomeActionCreators.renewSettingsOptionsHomeAction()
    );
    yield fork(handleErrorSaga, error);
  }
}

function* handleFetchSettingsOptionsHomeSaga(api: Api) {
  yield takeEvery(
    SettingsOptionsHomeActionTypes.FETCH_SETTINGS_OPTIONS_HOME,
    fetchSettingsOptionsHomeSaga,
    api
  );
}

export function* settingsOptionsHomeSagas(context: { api: Api }) {
  yield fork(handleFetchSettingsOptionsHomeSaga, context.api);
}
