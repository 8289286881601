import { AxiosResponse } from 'axios';
import { call, fork, put, select, takeEvery } from 'redux-saga/effects';

import { GraphDateType } from '../domain/dataKiGraph';
import {
  DataTransitionAfterIntroductionGraph,
  DataTransitionAfterIntroductionGraphParams,
  DataTransitionAfterIntroductionParams,
} from '../domain/transitionAfterIntroduction/types';

import { dataTransitionAfterIntroductionDataSearchConditionSelector } from '../redux/server/dataTransitionAfterIntroduction';
import {
  ChangeDataTransitionAfterIntroductionKiGraphFieldAction,
  DataTransitionAfterIntroductionKiGraphActionCreators,
  DataTransitionAfterIntroductionKiGraphActionTypes,
  FetchDataTransitionAfterIntroductionKiGraphAction,
  SearchDataTransitionAfterIntroductionKiGraphAction,
  dataTransitionAfterIntroductionKiGraphSettingSelector,
} from '../redux/server/dataTransitionAfterIntroductionKiGraph';
import { Api, buildConfig } from '../utils/api';
import { handleErrorSaga } from './errorSaga';

/**
 * 検索条件を元に推移グラフ（機種比較）のデータを取得する
 * @param api AxiosInstance
 * @param action Action
 */
export function* fetchDataTransitionAfterIntroductionKiGraphSaga(
  api: Api,
  action: FetchDataTransitionAfterIntroductionKiGraphAction
) {
  const { params } = action.payload;

  try {
    yield put(
      DataTransitionAfterIntroductionKiGraphActionCreators.fetchDataTransitionAfterIntroductionKiGraphRequestAction()
    );
    const response: AxiosResponse<DataTransitionAfterIntroductionGraph> = yield call(
      api.get,
      '/data/transitionAfterIntroduction/graph',
      buildConfig(params)
    );

    yield put(
      DataTransitionAfterIntroductionKiGraphActionCreators.fetchDataTransitionAfterIntroductionKiGraphSuccessAction(
        response.data
      )
    );
  } catch (error: unknown) {
    yield put(
      DataTransitionAfterIntroductionKiGraphActionCreators.renewDataTransitionAfterIntroductionKiGraphAction()
    );
    yield fork(handleErrorSaga, error);
  }
}

/**
 * チェックボックスで選択した機種の推移グラフ（機種比較）データを取得する（推移グラフボタン押下時）
 * @param action Action
 */
function* searchDataTransitionAfterIntroductionKiGraphSaga(
  action: SearchDataTransitionAfterIntroductionKiGraphAction
) {
  const { kiList } = action.payload;

  // テーブルの検索条件
  const searchCondition: DataTransitionAfterIntroductionParams = yield select(
    dataTransitionAfterIntroductionDataSearchConditionSelector
  );

  // グラフデータ取得
  yield put(
    DataTransitionAfterIntroductionKiGraphActionCreators.fetchDataTransitionAfterIntroductionKiGraphAction(
      {
        kiList,
        kiListForSijiritu: searchCondition.kiListForSijiritu,
        shuList: searchCondition.shuList,
        shuGroupIds: searchCondition.shuGroupIds,
        halls: searchCondition.halls,
        dateType: searchCondition.dateType as GraphDateType,
        excludeToday: searchCondition.excludeToday,
        startDate: searchCondition.startDate,
        endDate: searchCondition.endDate,
        areas: searchCondition.areas,
        makers: searchCondition.makers,
        sisTypes: searchCondition.sisTypes,
      }
    )
  );
}

/**
 * 指定したグラフ項目の推移グラフ（機種比較）を取得する
 * @param action Action
 */
function* changeDataTransitionAfterIntroductionKiGraphFieldSaga(
  action: ChangeDataTransitionAfterIntroductionKiGraphFieldAction
) {
  const { field } = action.payload;

  // 推移グラフ（機種比較）の検索条件
  const searchCondition: DataTransitionAfterIntroductionGraphParams = yield select(
    dataTransitionAfterIntroductionKiGraphSettingSelector
  );

  // 推移グラフ（機種比較）データを再取得
  yield put(
    DataTransitionAfterIntroductionKiGraphActionCreators.fetchDataTransitionAfterIntroductionKiGraphAction(
      {
        ...searchCondition,
        field,
      }
    )
  );
}

function* handleFetchDataTransitionAfterIntroductionKiGraphSaga(api: Api) {
  yield takeEvery(
    DataTransitionAfterIntroductionKiGraphActionTypes.FETCH_DATA_TRANSITION_AFTER_INTRODUCTION_GRAPH,
    fetchDataTransitionAfterIntroductionKiGraphSaga,
    api
  );
}

/**
 * 検索系のActionをDispatchされるのを監視
 */
function* handleSearchDataTransitionAfterIntroductionKiGraphSaga() {
  // 初回取得
  yield takeEvery(
    DataTransitionAfterIntroductionKiGraphActionTypes.SEARCH_DATA_TRANSITION_AFTER_INTRODUCTION_GRAPH,
    searchDataTransitionAfterIntroductionKiGraphSaga
  );
  // グラフ項目変更時
  yield takeEvery(
    DataTransitionAfterIntroductionKiGraphActionTypes.CHANGE_DATA_TRANSITION_AFTER_INTRODUCTION_GRAPH_FIELD,
    changeDataTransitionAfterIntroductionKiGraphFieldSaga
  );
}

export function* dataTransitionAfterIntroductionKiGraphSaga(context: {
  api: Api;
}) {
  yield fork(
    handleFetchDataTransitionAfterIntroductionKiGraphSaga,
    context.api
  );
  yield fork(handleSearchDataTransitionAfterIntroductionKiGraphSaga);
}
