// この関数がcompressToEncodedURIComponentのラッパーとなっており、この関数でのみ読込みさせています
// eslint-disable-next-line no-restricted-imports
import { compressToEncodedURIComponent as compressToEncodedURIComponentOrigin } from 'lz-string';

import { FavoritePageSetting } from '../domain/favorites';

export const compressToEncodedURIComponent = (
  pageName: string,
  pageSetting: FavoritePageSetting
) => {
  const compressed = compressToEncodedURIComponentOrigin(
    JSON.stringify({
      pageName,
      pageSetting,
      // NOTE: SISタイプコードの変更がいつ行われたのか判断するためにそのURLがいつ生成されたものなのかの情報が必要
      createdAt: new Date().toISOString(),
    })
  );

  return compressed;
};
