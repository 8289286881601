import {
  DkSisSearchParams,
  SisAreaOption,
  SisBreakEvenForPachinkoOption,
  SisBreakEvenForPachisloOption,
  SisRankOption,
} from '../domain/dkSis';
import { ModelReportsFormConditions } from '../domain/modelReportsFormConditions';

type SisCondition =
  | 'sisBreakEvenForPachinko'
  | 'sisBreakEvenForPachislo'
  | 'sisRank'
  | 'sisArea';

const SEPARATOR = '*-*';

const convertSisOptionsToKasidamaOptions = (category: SisCondition) => ({
  value,
  label,
}: {
  value: string;
  label: string;
}) => {
  let prefix = '';
  if (category === 'sisBreakEvenForPachinko') {
    prefix = '【損益分岐】 パチンコ';
  }
  if (category === 'sisBreakEvenForPachislo') {
    prefix = '【損益分岐】 パチスロ';
  }
  if (category === 'sisRank') {
    prefix = '【ランク】 ';
  }
  if (category === 'sisArea') {
    prefix = '【エリア】 ';
  }
  return {
    category: category as SisCondition,
    value: `${category}${SEPARATOR}${value}`,
    label: `${prefix}${label}`,
  };
};

const defaultSiskasidamaParams: DkSisSearchParams = {
  sisBreakEvenForPachinko: undefined,
  sisBreakEvenForPachislo: undefined,
  sisDateRangeType: undefined,
  sisStartDate: undefined,
  sisEndDate: undefined,
  sisRank: undefined,
  sisArea: undefined,
  containsSisAverage: false,
};

export const dksisKasidamaConditionOptions: {
  category: SisCondition | 'none';
  value: string;
  label: string;
}[] = [
  {
    category: 'none',
    label: '全国',
    value: 'none',
  },
  ...SisBreakEvenForPachinkoOption.map(
    convertSisOptionsToKasidamaOptions('sisBreakEvenForPachinko')
  ),
  ...SisBreakEvenForPachisloOption.map(
    convertSisOptionsToKasidamaOptions('sisBreakEvenForPachislo')
  ),
  ...SisRankOption.map(convertSisOptionsToKasidamaOptions('sisRank')),
  ...SisAreaOption.map(convertSisOptionsToKasidamaOptions('sisArea')),
];

/**
 * 機種集計テーブルのチェーン店一覧を開くアイコンクリック時に送信するDK-SISパラメータを作る
 */
export const getDkSisSearchParamsForOpenFromModelReportsKasidamaRow = (
  isDkSisOn: boolean,
  searchCondition: ModelReportsFormConditions
) => {
  const option = getDefaultDkSisSearchConditionValueKasidama(
    isDkSisOn,
    searchCondition
  );
  return convertOptionValueToSisKasidamaParam(option);
};

const getDefaultDkSisSearchConditionValueKasidama = (
  isDkSisOn: boolean,
  searchCondition: ModelReportsFormConditions
) => {
  if (searchCondition.mainField !== 'kiTushoMei') {
    return 'no-sis';
  }
  if (!isDkSisOn) {
    return 'none';
  }

  const { sisBreakEvenForPachinko, sisBreakEvenForPachislo, sisRank, sisArea } =
    searchCondition || {};
  if (sisBreakEvenForPachinko) {
    return `sisBreakEvenForPachinko${SEPARATOR}${sisBreakEvenForPachinko}`;
  }
  if (sisBreakEvenForPachislo) {
    return `sisBreakEvenForPachislo${SEPARATOR}${sisBreakEvenForPachislo}`;
  }
  if (sisRank) {
    return `sisRank${SEPARATOR}${sisRank}`;
  }
  if (sisArea) {
    return `sisArea${SEPARATOR}${sisArea}`;
  }

  return 'none';
};

const convertOptionValueToSisKasidamaParam = (selectedOptionValue: string) => {
  if (selectedOptionValue === 'none') {
    return {
      ...defaultSiskasidamaParams,
      containsSisAverage: true,
    };
  } else if (selectedOptionValue === 'no-sis') {
    return defaultSiskasidamaParams;
  }
  const [property, value] = selectedOptionValue.split(SEPARATOR);
  return {
    ...defaultSiskasidamaParams,
    containsSisAverage: true,
    [property]: value,
  };
};

export const getDefaultDkSisSearchConditionKasidamaValue = (
  isDkSisOn: boolean,
  dkSisSearchParams: DkSisSearchParams
) => {
  if (!isDkSisOn) {
    return 'none';
  }

  const { sisBreakEvenForPachinko, sisBreakEvenForPachislo, sisRank, sisArea } =
    dkSisSearchParams || {};
  if (sisBreakEvenForPachinko) {
    return `sisBreakEvenForPachinko${SEPARATOR}${sisBreakEvenForPachinko}`;
  }
  if (sisBreakEvenForPachislo) {
    return `sisBreakEvenForPachislo${SEPARATOR}${sisBreakEvenForPachislo}`;
  }
  if (sisRank) {
    return `sisRank${SEPARATOR}${sisRank}`;
  }
  if (sisArea) {
    return `sisArea${SEPARATOR}${sisArea}`;
  }

  return 'none';
};

export const newConvertOptionValueToSisKasidamaParam = (
  selectedOptionValue: string,
  manField: string | undefined
) => {
  if (manField !== 'kiTushoMei') {
    return defaultSiskasidamaParams;
  }
  if (selectedOptionValue === 'none') {
    return {
      ...defaultSiskasidamaParams,
      containsSisAverage: true,
    };
  } else if (selectedOptionValue === 'no-sis') {
    return defaultSiskasidamaParams;
  }
  const [property, value] = selectedOptionValue.split(SEPARATOR);
  return {
    ...defaultSiskasidamaParams,
    containsSisAverage: true,
    [property]: value,
  };
};

//DK-SIS行の条件追加用文言
export const convertSisAverageCondition = (value: string) => {
  switch (value) {
    case 'none':
      return '(全国)';
    //パチンコ分岐割
    case 'sisBreakEvenForPachinko*-*01':
      return '(パチンコ16割以上)';
    case 'sisBreakEvenForPachinko*-*02':
      return '(パチンコ15割)';
    case 'sisBreakEvenForPachinko*-*03':
      return '(パチンコ14割)';
    case 'sisBreakEvenForPachinko*-*04':
      return '(パチンコ13割)';
    case 'sisBreakEvenForPachinko*-*05':
      return '(パチンコ12割)';
    case 'sisBreakEvenForPachinko*-*06':
      return '(パチンコ11割)';
    case 'sisBreakEvenForPachinko*-*07':
      return '(パチンコ10割)';
    //スロット分岐割
    case 'sisBreakEvenForPachislo*-*01':
      return '(パチスロ13割以上)';
    case 'sisBreakEvenForPachislo*-*02':
      return 'パチスロ12割)';
    case 'sisBreakEvenForPachislo*-*03':
      return '(パチスロ11割)';
    case 'sisBreakEvenForPachislo*-*04':
      return '(パチスロ10割)';
    //ランク
    case 'sisRank*-*10':
      return '(ランク10)';
    case 'sisRank*-*9':
      return '(ランク9)';
    case 'sisRank*-*8':
      return '(ランク8)';
    case 'sisRank*-*7':
      return '(ランク7)';
    case 'sisRank*-*6':
      return '(ランク6)';
    case 'sisRank*-*5':
      return '(ランク5)';
    case 'sisRank*-*4':
      return '(ランク4)';
    case 'sisRank*-*3':
      return '(ランク3)';
    case 'sisRank*-*2':
      return '(ランク2)';
    case 'sisRank*-*1':
      return '(ランク1)';
    //地域
    case 'sisArea*-*01':
      return '(北海道地区)';
    case 'sisArea*-*02':
      return '(東北地区)';
    case 'sisArea*-*03':
      return '(北関東地区)';
    case 'sisArea*-*04':
      return '(南関東地区)';
    case 'sisArea*-*05':
      return '(東京地区)';
    case 'sisArea*-*06':
      return '(北陸甲信越地区)';
    case 'sisArea*-*07':
      return '(東海地区)';
    case 'sisArea*-*08':
      return '(近畿地区)';
    case 'sisArea*-*09':
      return '(中国地区)';
    case 'sisArea*-*10':
      return '(四国地区)';
    case 'sisArea*-*11':
      return '(九州沖縄地区)';
  }
};
