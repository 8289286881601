import { AxiosResponse } from 'axios';
import { format } from 'date-fns';
import { call, fork, put, select, takeEvery } from 'redux-saga/effects';

import { ChainReportsFormConditions } from '../domain/chainReportsFormConditions';
import {
  DataChainStoreShuGraph,
  DataChainStoreShuGraphParams,
} from '../domain/dataChainStoreShuGraph';
import { GRAPH_DATE_TYPE, GraphDateType } from '../domain/dataKiGraph';

import {
  DataChainStoreShuGraphActionCreators,
  DataChainStoreShuGraphActionTypes,
  FetchDataChainStoreShuGraphAction,
  SearchDataChainStoreShuGraphAction,
  SearchDataChainStoreShuGraphDateTypeAction,
  SearchDataChainStoreShuGraphFieldsForBarAction,
  SearchDataChainStoreShuGraphFieldsForLineAction,
  SearchDataChainStoreShuGraphHallAction,
  dataChainStoreShuGraphSearchConditionSelector,
} from '../redux/server/dataChainStoreShuGraph';
import { chainStoreReportsSearchConditionSelector } from '../redux/ui/chainStoreReportsSetting';
import { Api, buildConfig } from '../utils/api';
import { calcStartDateFromDateType } from '../utils/date';
import { getHallsAndAreas } from '../utils/getHallsAndAreas';
import { selectShu2SearchCondition } from '../utils/shu';
import { handleErrorSaga } from './errorSaga';

/**
 * 推移グラフ（店舗比較）データ取得処理
 */
export function* fetchDataChainStoreShuGraphSaga(
  api: Api,
  action: FetchDataChainStoreShuGraphAction
) {
  const { params, shuCode } = action.payload;
  try {
    yield put(
      DataChainStoreShuGraphActionCreators.fetchDataChainStoreShuGraphRequestAction(
        shuCode
      )
    );
    const response: AxiosResponse<DataChainStoreShuGraph> = yield call(
      api.get,
      '/data/chainStore/shu/graph',
      buildConfig(params)
    );
    yield put(
      DataChainStoreShuGraphActionCreators.fetchDataChainStoreShuGraphSuccessAction(
        shuCode,
        response.data
      )
    );
  } catch (error: unknown) {
    yield put(
      DataChainStoreShuGraphActionCreators.renewDataChainStoreShuGraphAction()
    );
    yield fork(handleErrorSaga, error);
  }
}

/**
 * クリックしたセルの推移グラフ（店舗比較）データを取得する
 */
function* searchChainStoreShuGraphSaga(
  action: SearchDataChainStoreShuGraphAction
) {
  const { selectOption, shuOption } = action.payload;
  const shuCode = shuOption?.code ?? 'null';

  // 検索フォームの検索条件
  const searchCondition: ChainReportsFormConditions = yield select(
    chainStoreReportsSearchConditionSelector
  );

  // 取得済みのグラフの検索条件
  const graphSearchCondition: DataChainStoreShuGraphParams = yield select(
    dataChainStoreShuGraphSearchConditionSelector(shuCode)
  );

  // 開始日をグラフの期間に再計算
  const startDate = calcStartDateFromDateType(
    graphSearchCondition?.dateType ?? GRAPH_DATE_TYPE.DAILY,
    new Date(
      (searchCondition.ymdList && searchCondition.ymdList.slice(-1)[0]) ??
        new Date()
    )
  );

  yield put(
    DataChainStoreShuGraphActionCreators.fetchDataChainStoreShuGraphAction(
      shuCode,
      {
        ...graphSearchCondition,
        // MEMO: 種別がある場合は種別コードをセットし、ない場合（店舗全体実績）は種別の指定を空にする
        ...(shuOption ? selectShu2SearchCondition(shuOption) : {}),
        ...getHallsAndAreas(searchCondition, selectOption),
        areasForSijiritu: searchCondition.areas,
        hallsForSijiritu: searchCondition.halls,
        startDate: format(startDate, 'yyyy-MM-dd'),
        endDate:
          searchCondition.ymdList && searchCondition.ymdList.slice(-1)[0],
        excludeToday: searchCondition.excludeToday,
      }
    )
  );
}

/**
 * 指定した店舗の推移グラフ（店舗比較）データを再取得する
 */
function* searchChainStoreShuGraphHallSaga(
  action: SearchDataChainStoreShuGraphHallAction
) {
  const { selectOption, shuOption } = action.payload;
  const shuCode = shuOption?.code ?? 'null';

  // 検索フォームの検索条件
  const searchCondition: ChainReportsFormConditions = yield select(
    chainStoreReportsSearchConditionSelector
  );

  // 取得済みのグラフの検索条件
  const graphSearchCondition: DataChainStoreShuGraphParams = yield select(
    dataChainStoreShuGraphSearchConditionSelector(shuCode)
  );

  yield put(
    DataChainStoreShuGraphActionCreators.fetchDataChainStoreShuGraphAction(
      shuCode,
      {
        ...graphSearchCondition,
        ...getHallsAndAreas(searchCondition, selectOption),
      }
    )
  );
}

/**
 * 指定した期間の推移グラフ（店舗比較）データを再取得する
 */
function* searchChainStoreShuGraphDateTypeSaga(
  action: SearchDataChainStoreShuGraphDateTypeAction
) {
  const { dateType, shuOption } = action.payload;
  const shuCode = shuOption?.code ?? 'null';

  // 取得済みのグラフの検索条件
  const searchCondition: DataChainStoreShuGraphParams = yield select(
    dataChainStoreShuGraphSearchConditionSelector(shuCode)
  );

  // 開始日をグラフの期間に再計算
  const startDate = calcStartDateFromDateType(
    dateType as GraphDateType,
    new Date(searchCondition?.endDate ?? new Date())
  );

  yield put(
    DataChainStoreShuGraphActionCreators.fetchDataChainStoreShuGraphAction(
      shuCode,
      {
        ...searchCondition,
        startDate: format(startDate, 'yyyy-MM-dd'),
        dateType: dateType as GraphDateType,
      }
    )
  );
}

/**
 * 指定した棒グラフのグラフ項目の推移グラフ（店舗比較）データを再取得する
 */
function* searchChainStoreShuGraphFieldsForBarSaga(
  action: SearchDataChainStoreShuGraphFieldsForBarAction
) {
  const { fields, shuOption } = action.payload;
  const shuCode = shuOption?.code ?? 'null';

  // 取得済みのグラフの検索条件
  const searchCondition: DataChainStoreShuGraphParams = yield select(
    dataChainStoreShuGraphSearchConditionSelector(shuCode)
  );

  yield put(
    DataChainStoreShuGraphActionCreators.fetchDataChainStoreShuGraphAction(
      shuCode,
      {
        ...searchCondition,
        fieldsForBar: fields,
      }
    )
  );
}

/**
 * 指定した線グラフのグラフ項目の推移グラフ（店舗比較）データを再取得する
 */
function* searchChainStoreShuGraphFieldsForLineSaga(
  action: SearchDataChainStoreShuGraphFieldsForLineAction
) {
  const { fields, shuOption } = action.payload;
  const shuCode = shuOption?.code ?? 'null';

  // 取得済みのグラフの検索条件
  const searchCondition: DataChainStoreShuGraphParams = yield select(
    dataChainStoreShuGraphSearchConditionSelector(shuCode)
  );

  yield put(
    DataChainStoreShuGraphActionCreators.fetchDataChainStoreShuGraphAction(
      shuCode,
      {
        ...searchCondition,
        fieldsForLine: fields,
      }
    )
  );
}

function* handleFetchChainStoreShuGraphSaga(api: Api) {
  yield takeEvery(
    DataChainStoreShuGraphActionTypes.FETCH_DATA_CHAIN_STORE_SHU_GRAPH,
    fetchDataChainStoreShuGraphSaga,
    api
  );
}

function* handleSearchSaga() {
  yield takeEvery(
    DataChainStoreShuGraphActionTypes.SEARCH_DATA_CHAIN_STORE_SHU_GRAPH,
    searchChainStoreShuGraphSaga
  );
  yield takeEvery(
    DataChainStoreShuGraphActionTypes.SEARCH_DATA_CHAIN_STORE_SHU_GRAPH_HALL,
    searchChainStoreShuGraphHallSaga
  );
  yield takeEvery(
    DataChainStoreShuGraphActionTypes.SEARCH_DATA_CHAIN_STORE_SHU_GRAPH_DATE_TYPE,
    searchChainStoreShuGraphDateTypeSaga
  );
  yield takeEvery(
    DataChainStoreShuGraphActionTypes.SEARCH_DATA_CHAIN_STORE_SHU_GRAPH_FIELDS_FOR_BAR,
    searchChainStoreShuGraphFieldsForBarSaga
  );
  yield takeEvery(
    DataChainStoreShuGraphActionTypes.SEARCH_DATA_CHAIN_STORE_SHU_GRAPH_FIELDS_FOR_LINE,
    searchChainStoreShuGraphFieldsForLineSaga
  );
}

export function* dataChainStoreShuGraphSaga(context: { api: Api }) {
  yield fork(handleFetchChainStoreShuGraphSaga, context.api);
  yield fork(handleSearchSaga);
}
