import { createSelector } from 'reselect';

import {
  DataTerminalTransitionParams,
  TransitiveDateType,
} from '../../domain/dataTerminalTransition';
import { SharedUser } from '../../domain/favorites';
import { MarkingOption } from '../../domain/marking';
import { OrderType } from '../../domain/schemas';
import { ShuOption } from '../../domain/shu';
import { TerminalTransitionReportsSettingDateRangeParams } from '../../domain/terminalTransitionReportsSettingDateRangeParams';

import { RootState } from '../../store';
import { dataTerminalTransitionDefaultValue } from '../../utils/dataTerminalTransitionDefaultValue';
import { TransitiveDateRange } from '../../utils/transitiveDateRange';
import { favoritesSelector } from './settingsFavorites';

/**
 * Action Types
 *
 * プレフィックスの意味
 * INIT: 初回のデータを取得する（最初に画面を開いたときだけ取得）
 * SEARCH: ReducerでStateの変更はしないがデータを取得する（検索ボタン等Sagaを発火させたい）
 * CHANGE: Stateを変更してデータも取得する
 * SELECT: Stateを変更する（データ取得は行わない）
 * RESET: Stateを初期状態に戻す（データ取得は行わない）
 * SAVE: 上書き保存する（お気に入り用）
 * SAVE_AS: 新規保存する（お気に入り用）
 */

const INIT_TERMINAL_TRANSITION_REPORTS = 'INIT_TERMINAL_TRANSITION_REPORTS' as const;

const SEARCH_TERMINAL_TRANSITION_REPORTS = 'SEARCH_TERMINAL_TRANSITION_REPORTS' as const;
const SEARCH_RESET_TERMINAL_TRANSITION_REPORTS = 'SEARCH_RESET_TERMINAL_TRANSITION_REPORTS' as const;
const SEARCH_TERMINAL_TRANSITION_REPORTS_SORT_FOR_HEADER = 'SEARCH_TERMINAL_TRANSITION_REPORTS_SORT_FOR_HEADER' as const;
const SEARCH_TERMINAL_TRANSITION_REPORTS_SORT_FOR_TRANSITIVE_FIELD = 'SEARCH_TERMINAL_TRANSITION_REPORTS_SORT_FOR_TRANSITIVE_FIELD' as const;
const SEARCH_TERMINAL_TRANSITION_REPORTS_MARKING = 'SEARCH_TERMINAL_TRANSITION_REPORTS_MARKING' as const;
const SEARCH_TERMINAL_TRANSITION_REPORTS_FIELDS = 'SEARCH_TERMINAL_TRANSITION_REPORTS_FIELDS' as const;

const CHANGE_TERMINAL_TRANSITION_REPORTS_FAVORITE = 'CHANGE_TERMINAL_TRANSITION_REPORTS_FAVORITE' as const;

const SELECT_TERMINAL_TRANSITION_REPORTS_SEARCH_CONDITION = 'SELECT_TERMINAL_TRANSITION_REPORTS_SEARCH_CONDITION' as const;
const SELECT_TERMINAL_TRANSITION_REPORTS_SHU = 'SELECT_TERMINAL_TRANSITION_REPORTS_SHU' as const;
const SELECT_TERMINAL_TRANSITION_REPORTS_DATE_RANGE_PARAMS = 'SELECT_TERMINAL_TRANSITION_REPORTS_DATE_RANGE_PARAMS' as const;
const SELECT_TERMINAL_TRANSITION_REPORTS_COLUMNS_FOR_TRANSITION_ORDER = 'SELECT_TERMINAL_TRANSITION_REPORTSCOLUMNS_FOR_TRANSITION_ORDER' as const;

const RESET_TERMINAL_TRANSITION_REPORTS_SEARCH_CONDITION = 'RESET_TERMINAL_TRANSITION_REPORTS_SEARCH_CONDITION' as const;
const RESET_TERMINAL_TRANSITION_REPORTS_SHU = 'RESET_TERMINAL_TRANSITION_REPORTS_SHU' as const;
const RESET_TERMINAL_TRANSITION_REPORTS_DATE_RANGE_PARAMS = 'RESET_TERMINAL_TRANSITION_REPORTS_DATE_RANGE_PARAMS' as const;

const SAVE_TERMINAL_TRANSITION_REPORTS_FAVORITE = 'SAVE_TERMINAL_TRANSITION_REPORTS_FAVORITE' as const;
const SAVE_AS_TERMINAL_TRANSITION_REPORTS_FAVORITE = 'SAVE_AS_TERMINAL_TRANSITION_REPORTS_FAVORITE' as const;
const SEARCH_TERMINAL_TRANSITION_REPORTS_DATE_RANGE_SLIDE = 'SEARCH_TERMINAL_TRANSITION_REPORTS_DATE_RANGE_SLIDE' as const;

const ADD_TERMINAL_TRANSITION_REPORTS_TABLE_FILTER = 'ADD_TERMINAL_TRANSITION_REPORTS_TABLE_FILTER' as const;
const REMOVE_TERMINAL_TRANSITION_REPORTS_TABLE_FILTER = 'REMOVE_TERMINAL_TRANSITION_REPORTS_TABLE_FILTER' as const;
const APPLY_TERMINAL_TRANSITION_REPORTS_TABLE_FILTER = 'APPLY_TERMINAL_TRANSITION_REPORTS_TABLE_FILTER' as const;
const RESET_TERMINAL_TRANSITION_REPORTS_TABLE_FILTER = 'RESET_TERMINAL_TRANSITION_REPORTS_TABLE_FILTER' as const;
const SELECT_TERMINAL_TRANSITION_REPORTS_CURRENT_HALLS = 'SELECT_TERMINAL_TRANSITION_REPORTS_CURRENT_HALLS' as const;
const CHANGE_TERMINAL_TRANSITION_REPORTS_CURRENT_HALLS = 'CHANGE_TERMINAL_TRANSITION_REPORTS_CURRENT_HALLS' as const;

const FILTER_TERMINAL_TRANSITION_REPORTS_TABLE_FILTER = 'FILTER_TERMINAL_TRANSITION_REPORTS_TABLE_FILTER' as const;

const CHANGE_TERMINAL_TRANSITION_CURRENT_FIELD = 'CHANGE_TERMINAL_TRANSITION_CURRENT_FIELD' as const;

const SELECT_GRAPH_SHOW_GRAPH_NUMBER_LABEL = 'SELECT_GRAPH_SHOW_GRAPH_NUMBER_LABEL' as const;
const SELECT_GRAPH_SHOW_GRAPH_PLAN = 'SELECT_GRAPH_SHOW_GRAPH_PLAN' as const;
const SELECT_GRAPH_SHOW_GRAPH_TOTAL = 'SELECT_GRAPH_SHOW_GRAPH_TOTAL' as const;

const CREATE_TERMINAL_TRANSITION_REPORTS_SHORTENED_URL = 'CREATE_TERMINAL_TRANSITION_REPORTS_SHORTENED_URL' as const;

export const TerminalTransitionReportsSettingActionTypes = {
  INIT_TERMINAL_TRANSITION_REPORTS,
  SEARCH_TERMINAL_TRANSITION_REPORTS,
  SEARCH_RESET_TERMINAL_TRANSITION_REPORTS,
  SEARCH_TERMINAL_TRANSITION_REPORTS_SORT_FOR_HEADER,
  SEARCH_TERMINAL_TRANSITION_REPORTS_SORT_FOR_TRANSITIVE_FIELD,
  SEARCH_TERMINAL_TRANSITION_REPORTS_MARKING,
  SEARCH_TERMINAL_TRANSITION_REPORTS_FIELDS,
  CHANGE_TERMINAL_TRANSITION_REPORTS_FAVORITE,
  SELECT_TERMINAL_TRANSITION_REPORTS_SEARCH_CONDITION,
  SELECT_TERMINAL_TRANSITION_REPORTS_SHU,
  SELECT_TERMINAL_TRANSITION_REPORTS_DATE_RANGE_PARAMS,
  SELECT_TERMINAL_TRANSITION_REPORTS_COLUMNS_FOR_TRANSITION_ORDER,
  RESET_TERMINAL_TRANSITION_REPORTS_SEARCH_CONDITION,
  RESET_TERMINAL_TRANSITION_REPORTS_SHU,
  RESET_TERMINAL_TRANSITION_REPORTS_DATE_RANGE_PARAMS,
  SAVE_TERMINAL_TRANSITION_REPORTS_FAVORITE,
  SAVE_AS_TERMINAL_TRANSITION_REPORTS_FAVORITE,
  SEARCH_TERMINAL_TRANSITION_REPORTS_DATE_RANGE_SLIDE,
  ADD_TERMINAL_TRANSITION_REPORTS_TABLE_FILTER,
  REMOVE_TERMINAL_TRANSITION_REPORTS_TABLE_FILTER,
  APPLY_TERMINAL_TRANSITION_REPORTS_TABLE_FILTER,
  RESET_TERMINAL_TRANSITION_REPORTS_TABLE_FILTER,
  SELECT_GRAPH_SHOW_GRAPH_NUMBER_LABEL,
  SELECT_GRAPH_SHOW_GRAPH_PLAN,
  SELECT_GRAPH_SHOW_GRAPH_TOTAL,
  SELECT_TERMINAL_TRANSITION_REPORTS_CURRENT_HALLS,
  CHANGE_TERMINAL_TRANSITION_REPORTS_CURRENT_HALLS,
  FILTER_TERMINAL_TRANSITION_REPORTS_TABLE_FILTER,
  CHANGE_TERMINAL_TRANSITION_CURRENT_FIELD,
  CREATE_TERMINAL_TRANSITION_REPORTS_SHORTENED_URL,
};

/**
 * Action Creators
 */

/**
 * 初回データを取得する（初回表示時）
 */
function initTerminalTransitionReportsAction() {
  return {
    type: INIT_TERMINAL_TRANSITION_REPORTS,
  };
}

/**
 * 指定した検索条件でデータを取得する（検索ボタン押下時）
 * @param params 検索条件
 * @param selectedShu 選択中の種別
 * @param selectedDateRange 選択中の期間
 */
function searchTerminalTransitionReportsAction(
  params: DataTerminalTransitionParams,
  selectedShu: ShuOption[],
  selectedDateRange: TransitiveDateRange
) {
  return {
    type: SEARCH_TERMINAL_TRANSITION_REPORTS,
    payload: { params, selectedShu, selectedDateRange },
  };
}

/**
 * 初期条件でデータを取得する（リセットボタン押下時）
 */
function searchResetTerminalTransitionReportsAction() {
  return {
    type: SEARCH_RESET_TERMINAL_TRANSITION_REPORTS,
  };
}

/**
 * 指定した非推移項目ソート条件でデータを再取得する
 * @param sort 非推移項目でソートする条件
 * @param transitiveSort 推移項目でソートする条件
 * @param order 非推移項目での昇順・降順
 */
function searchTerminalTransitionReportsSortForHeaderAction(
  sort: string,
  order: OrderType,
  transitiveSort: string
) {
  return {
    type: SEARCH_TERMINAL_TRANSITION_REPORTS_SORT_FOR_HEADER,
    payload: { sort, order, transitiveSort },
  };
}

/**
 * 指定した推移項目ソート条件でデータを再取得する
 * @param sort 推移項目でソートする条件
 * @param order 昇順・降順
 */
function searchTerminalTransitionReportsSortForTransitiveFieldAction(
  sort: string,
  order: OrderType
) {
  return {
    type: SEARCH_TERMINAL_TRANSITION_REPORTS_SORT_FOR_TRANSITIVE_FIELD,
    payload: { sort, order },
  };
}

/**
 * 指定したマーキング条件でデータを再取得する
 * @param markingOption マーキング項目
 * @param isFiltering 該当行のみ表示するか
 */
function searchTerminalTransitionReportsMarkingAction(
  markingOption: MarkingOption,
  isFiltering: boolean
) {
  return {
    type: SEARCH_TERMINAL_TRANSITION_REPORTS_MARKING,
    payload: { markingOption, isFiltering },
  };
}

/**
 * 指定した表示項目に絞ってデータを再取得する
 * @param transitiveFields 推移項目
 * @param nonTransitiveFields 非推移項目
 */
function searchTerminalTransitionReportsFieldsAction(
  transitiveFields: string[],
  nonTransitiveFields: string[]
) {
  return {
    type: SEARCH_TERMINAL_TRANSITION_REPORTS_FIELDS,
    payload: { transitiveFields, nonTransitiveFields },
  };
}

/**
 * 選択したお気に入りでデータを取得する
 *
 * @param favorite 変更するお気に入りID（undefined時は選択なし）
 */
function changeTerminalTransitionReportsFavoriteAction(favorite?: number) {
  return {
    type: CHANGE_TERMINAL_TRANSITION_REPORTS_FAVORITE,
    payload: { favorite },
  };
}

/**
 * 検索条件を変更
 */
function selectTerminalTransitionReportsSearchConditionAction(
  params: DataTerminalTransitionParams
) {
  return {
    type: SELECT_TERMINAL_TRANSITION_REPORTS_SEARCH_CONDITION,
    payload: { params },
  };
}

/**
 * 種別一覧を変更
 * @param shus 種別一覧
 */
function selectTerminalTransitionReportsShuAction(shus: ShuOption[]) {
  return {
    type: SELECT_TERMINAL_TRANSITION_REPORTS_SHU,
    payload: { shus },
  };
}

/**
 * 推移項目の並び順を変更
 *
 * @param columnsForTransition
 * @returns
 */
export function selectTerminalTransitionReportsColumnsForTransitionAction(
  columnsForTransition: string[]
) {
  return {
    type: SELECT_TERMINAL_TRANSITION_REPORTS_COLUMNS_FOR_TRANSITION_ORDER,
    payload: { columnsForTransition },
  };
}

/**
 * 検索条件をデフォルトに戻す
 */
function resetTerminalTransitionReportsSearchConditionAction() {
  return {
    type: RESET_TERMINAL_TRANSITION_REPORTS_SEARCH_CONDITION,
  };
}

/**
 * 選択中の種別一覧をデフォルトに戻す
 */
function resetTerminalTransitionReportsShuAction() {
  return {
    type: RESET_TERMINAL_TRANSITION_REPORTS_SHU,
  };
}

/**
 * 日付タイプ・期間を変更
 *
 * @param dateType 変更する日付タイプ
 * @param dateRange 変更する期間
 */
function selectTerminalTransitionReportsDateRangeParamsAction(
  dateType: TransitiveDateType,
  dateRange: TransitiveDateRange
) {
  return {
    type: SELECT_TERMINAL_TRANSITION_REPORTS_DATE_RANGE_PARAMS,
    payload: { dateType, dateRange },
  };
}

/**
 * 選択中の期間をデフォルトに戻す
 */
function resetTerminalTransitionReportsDateRangeParamsAction() {
  return {
    type: RESET_TERMINAL_TRANSITION_REPORTS_DATE_RANGE_PARAMS,
  };
}

/**
 * 現在の検索条件でお気に入りを新規保存する
 * @param name お気に入り名
 * @param isShared 共有設定
 * @param memo メモ
 * @param sharedUser 共有ユーザ
 * @param mode メモ更新モード
 */
function saveTerminalTransitionReportsFavorite(
  name: string,
  isShared: boolean,
  memo: string,
  sharedUser: SharedUser[],
  mode?: 'standard' | 'memo'
) {
  return {
    type: SAVE_TERMINAL_TRANSITION_REPORTS_FAVORITE,
    payload: { name, isShared, memo, sharedUser, mode },
  };
}

/**
 * 現在の検索条件でお気に入りを上書き保存する
 * @param name お気に入り名
 * @param isShared 共有設定
 * @param memo メモ
 * @param sharedUser 共有ユーザ
 */
function saveAsTerminalTransitionReportsFavorite(
  name: string,
  isShared: boolean,
  memo: string,
  sharedUser: SharedUser[]
) {
  return {
    type: SAVE_AS_TERMINAL_TRANSITION_REPORTS_FAVORITE,
    payload: { name, isShared, memo, sharedUser },
  };
}

/**
 * 期間スライドコンポーネントがクリックされた時に、期間を変更して検索を実行する
 * @param {TransitiveDateRange} newDateRange   期間単位
 * @param {string} newStartDate                検索期間 開始日
 * @param {string} newEndDate                  検索期間 終了日
 */
export function searchTerminalTransitionReportsDateRangeSlideAction(
  newDateRange: TransitiveDateRange,
  newStartDate: string,
  newEndDate: string
) {
  return {
    type: SEARCH_TERMINAL_TRANSITION_REPORTS_DATE_RANGE_SLIDE,
    payload: {
      dateRange: newDateRange,
      startDate: newStartDate,
      endDate: newEndDate,
    },
  };
}

/**
 * テーブルの非表示項目を追加する
 * @param columnCode カラム名
 */
function addTerminalTransitionReportsTableFilterAction(columnCode: string) {
  return {
    type: ADD_TERMINAL_TRANSITION_REPORTS_TABLE_FILTER,
    payload: { columnCode },
  };
}

/**
 * テーブルの表示項目を削除する
 * @param columnCode カラム名
 */
function removeTerminalTransitionReportsTableFilterAction(columnCode: string) {
  return {
    type: REMOVE_TERMINAL_TRANSITION_REPORTS_TABLE_FILTER,
    payload: { columnCode },
  };
}

/**
 * テーブルの非表示項目を反映する
 * @param tableFilterItems テーブルの非表示項目
 */
function applyTerminalTransitionReportsTableFilterAction(
  tableFilterItems: string[]
) {
  return {
    type: APPLY_TERMINAL_TRANSITION_REPORTS_TABLE_FILTER,
    payload: { tableFilterItems },
  };
}

/**
 * テーブルの非表示項目をリセットする
 */
function resetTerminalTransitionReportsTableFilterAction() {
  return {
    type: RESET_TERMINAL_TRANSITION_REPORTS_TABLE_FILTER,
  };
}

/**
 * 推移グラフに数値を表示するかどうかのフラグをセットする
 * @param {boolean} flag 表示フラグ
 */
function selectGraphShowNumberLabelAction(flag: boolean) {
  return {
    type: SELECT_GRAPH_SHOW_GRAPH_NUMBER_LABEL,
    payload: {
      flag,
    },
  };
}

/**
 * 店舗選択リストを変更する、リクエストは投げない
 * @param halls 絞り込む店舗
 */
function selectTerminalTransitionReportsCurrentHallsAction(halls: string[]) {
  return {
    type: SELECT_TERMINAL_TRANSITION_REPORTS_CURRENT_HALLS,
    payload: { halls },
  };
}

/**
 * 店舗選択リストで店舗を変更し、リクエストを投げる
 * @param halls 絞り込む店舗
 */
function changeTerminalTransitionReportsCurrentHallsAction(halls: string[]) {
  return {
    type: CHANGE_TERMINAL_TRANSITION_REPORTS_CURRENT_HALLS,
    payload: { halls },
  };
}

/**
 * 推移グラフで予定を表示するかどうかのフラグをセットする
 * @param {boolean} flag 表示フラグ
 */
function selectGraphShowPlanAction(flag: boolean) {
  return {
    type: SELECT_GRAPH_SHOW_GRAPH_PLAN,
    payload: {
      flag,
    },
  };
}

/**
 * 推移グラフで累計を表示するかどうかのフラグをセットする
 * @param {boolean} flag 表示フラグ
 */
function selectGraphShowTotalAction(flag: boolean) {
  return {
    type: SELECT_GRAPH_SHOW_GRAPH_TOTAL,
    payload: {
      flag,
    },
  };
}

/**
 * 項目絞込リストの追加
 * @param columnCode カラム名
 */
function filterTerminalTransitionReportsTableFilterAction(columnCode: string) {
  return {
    type: FILTER_TERMINAL_TRANSITION_REPORTS_TABLE_FILTER,
    payload: { columnCode },
  };
}

// 項目絞込リストで現在選択中の項目を登録
function changeTerminalTransitionCurrentFieldsAction(fieldCode: string) {
  return {
    type: CHANGE_TERMINAL_TRANSITION_CURRENT_FIELD,
    payload: { fieldCode },
  };
}

/**
 * 画面共有用の短縮URLを作成する
 */
function createTerminalTransitionReportsShortenedUrlAction(
  pageName: string,
  locationUrl: string
) {
  return {
    type: CREATE_TERMINAL_TRANSITION_REPORTS_SHORTENED_URL,
    payload: { pageName, locationUrl },
  };
}

export const TerminalTransitionReportsSettingActionCreators = {
  initTerminalTransitionReportsAction,
  searchTerminalTransitionReportsAction,
  searchResetTerminalTransitionReportsAction,
  searchTerminalTransitionReportsSortForHeaderAction,
  searchTerminalTransitionReportsSortForTransitiveFieldAction,
  searchTerminalTransitionReportsMarkingAction,
  searchTerminalTransitionReportsFieldsAction,
  changeTerminalTransitionReportsFavoriteAction,
  selectTerminalTransitionReportsSearchConditionAction,
  selectTerminalTransitionReportsShuAction,
  selectTerminalTransitionReportsColumnsForTransitionAction,
  resetTerminalTransitionReportsSearchConditionAction,
  resetTerminalTransitionReportsShuAction,
  selectTerminalTransitionReportsDateRangeParamsAction,
  resetTerminalTransitionReportsDateRangeParamsAction,
  saveTerminalTransitionReportsFavorite,
  saveAsTerminalTransitionReportsFavorite,
  searchTerminalTransitionReportsDateRangeSlideAction,
  addTerminalTransitionReportsTableFilterAction,
  removeTerminalTransitionReportsTableFilterAction,
  applyTerminalTransitionReportsTableFilterAction,
  resetTerminalTransitionReportsTableFilterAction,
  selectGraphShowNumberLabelAction,
  selectGraphShowPlanAction,
  selectGraphShowTotalAction,
  selectTerminalTransitionReportsCurrentHallsAction,
  changeTerminalTransitionReportsCurrentHallsAction,
  filterTerminalTransitionReportsTableFilterAction,
  changeTerminalTransitionCurrentFieldsAction,
  createTerminalTransitionReportsShortenedUrlAction,
};

/**
 * Actions
 */

type InitTerminalTransitionReportsAction = ReturnType<
  typeof initTerminalTransitionReportsAction
>;
export type SearchTerminalTransitionReportsAction = ReturnType<
  typeof searchTerminalTransitionReportsAction
>;
type SearchResetTerminalTransitionReportsAction = ReturnType<
  typeof searchResetTerminalTransitionReportsAction
>;
export type SearchTerminalTransitionReportsSortForHeaderAction = ReturnType<
  typeof searchTerminalTransitionReportsSortForHeaderAction
>;
export type SearchTerminalTransitionReportsSortForTransitiveFieldAction = ReturnType<
  typeof searchTerminalTransitionReportsSortForTransitiveFieldAction
>;
export type SearchTerminalTransitionReportsMarkingAction = ReturnType<
  typeof searchTerminalTransitionReportsMarkingAction
>;
export type SearchTerminalTransitionReportsFieldsAction = ReturnType<
  typeof searchTerminalTransitionReportsFieldsAction
>;
type ChangeTerminalTransitionReportsFavoriteAction = ReturnType<
  typeof changeTerminalTransitionReportsFavoriteAction
>;
type SelectKiShuAction = ReturnType<
  typeof selectTerminalTransitionReportsShuAction
>;
type SelectTerminalTransitionReportsSearchConditionAction = ReturnType<
  typeof selectTerminalTransitionReportsSearchConditionAction
>;
type ResetTerminalTransitionReportsSearchConditionAction = ReturnType<
  typeof resetTerminalTransitionReportsSearchConditionAction
>;
type ResetKiShuAction = ReturnType<
  typeof resetTerminalTransitionReportsShuAction
>;
type SelectTerminalTransitionReportsDateRangeParamsAction = ReturnType<
  typeof selectTerminalTransitionReportsDateRangeParamsAction
>;
type SelectTerminalTransitionReportsColumnsForTransitionAction = ReturnType<
  typeof selectTerminalTransitionReportsColumnsForTransitionAction
>;
type ResetTerminalTransitionReportsDateRangeParamsAction = ReturnType<
  typeof resetTerminalTransitionReportsDateRangeParamsAction
>;
export type SaveTerminalTransitionReportsFavorite = ReturnType<
  typeof saveTerminalTransitionReportsFavorite
>;
type SaveAsTerminalTransitionReportsFavorite = ReturnType<
  typeof saveAsTerminalTransitionReportsFavorite
>;
export type SearchTerminalTransitionReportsDateRangeSlideAction = ReturnType<
  typeof searchTerminalTransitionReportsDateRangeSlideAction
>;

type AddTerminalTransitionReportsTableFilterAction = ReturnType<
  typeof addTerminalTransitionReportsTableFilterAction
>;
type RemoveTerminalTransitionReportsTableFilterAction = ReturnType<
  typeof removeTerminalTransitionReportsTableFilterAction
>;
type ApplyTerminalTransitionReportsTableFilterAction = ReturnType<
  typeof applyTerminalTransitionReportsTableFilterAction
>;
type ResetTerminalTransitionReportsTableFilterAction = ReturnType<
  typeof resetTerminalTransitionReportsTableFilterAction
>;
type SelectGraphShowNumberLabelAction = ReturnType<
  typeof selectGraphShowNumberLabelAction
>;
type SelectGraphShowPlanAction = ReturnType<typeof selectGraphShowPlanAction>;
type SelectGraphShowTotalAction = ReturnType<typeof selectGraphShowTotalAction>;
type SelectTerminalTransitionReportsCurrentHallsAction = ReturnType<
  typeof selectTerminalTransitionReportsCurrentHallsAction
>;
export type ChangeTerminalTransitionReportsCurrentHallsAction = ReturnType<
  typeof changeTerminalTransitionReportsCurrentHallsAction
>;
type FilterTerminalTransitionReportsTableFilterAction = ReturnType<
  typeof filterTerminalTransitionReportsTableFilterAction
>;
type ChangeTerminalTransitionCurrentFieldsAction = ReturnType<
  typeof changeTerminalTransitionCurrentFieldsAction
>;

export type CreateTerminalTransitionReportsShortenedUrlAction = ReturnType<
  typeof createTerminalTransitionReportsShortenedUrlAction
>;

type TerminalTransitionReportsSettingAction =
  | InitTerminalTransitionReportsAction
  | SearchTerminalTransitionReportsAction
  | SearchResetTerminalTransitionReportsAction
  | SearchTerminalTransitionReportsSortForHeaderAction
  | SearchTerminalTransitionReportsSortForTransitiveFieldAction
  | SearchTerminalTransitionReportsMarkingAction
  | SearchTerminalTransitionReportsFieldsAction
  | ChangeTerminalTransitionReportsFavoriteAction
  | SelectTerminalTransitionReportsSearchConditionAction
  | SelectTerminalTransitionReportsColumnsForTransitionAction
  | SelectKiShuAction
  | ResetTerminalTransitionReportsSearchConditionAction
  | ResetKiShuAction
  | SelectTerminalTransitionReportsDateRangeParamsAction
  | ResetTerminalTransitionReportsDateRangeParamsAction
  | SaveTerminalTransitionReportsFavorite
  | SaveAsTerminalTransitionReportsFavorite
  | SearchTerminalTransitionReportsDateRangeSlideAction
  | AddTerminalTransitionReportsTableFilterAction
  | RemoveTerminalTransitionReportsTableFilterAction
  | ApplyTerminalTransitionReportsTableFilterAction
  | ResetTerminalTransitionReportsTableFilterAction
  | SelectGraphShowNumberLabelAction
  | SelectGraphShowPlanAction
  | SelectGraphShowTotalAction
  | SelectTerminalTransitionReportsCurrentHallsAction
  | ChangeTerminalTransitionReportsCurrentHallsAction
  | FilterTerminalTransitionReportsTableFilterAction
  | ChangeTerminalTransitionCurrentFieldsAction
  | CreateTerminalTransitionReportsShortenedUrlAction;

/**
 * State
 */

export type TerminalTransitionReportsSettingState = {
  /**
   * 現在の検索条件
   */
  searchCondition: DataTerminalTransitionParams;
  /**
   * 現在検索フォームで選択中の種別・種別グループ（選択可能な種別・種別グループ一覧）
   */
  selectedShu: ShuOption[];
  /**
   * 現在選択中の日付・期間
   */
  selectedDateRangeParams: TerminalTransitionReportsSettingDateRangeParams;
  /**
   * 選択中のお気に入りID
   */
  selectedFavoriteId?: number;
  /**
   * 期間推移テーブルの推移項目並び順
   */
  columnsForTransitionOrder?: string[];
  /**
   * テーブルの非表示項目一覧
   */
  tableFilterItems: string[];
  /**
   * 推移グラフに数値を表示するかどうかのフラグ
   */
  showGraphNumberLabel: boolean;
  /**
   * 推移グラフで予定を表示するかどうかのフラグ
   */
  showGraphPlan: boolean;
  /**
   * 推移グラフで累計を表示するかどうかのフラグ
   */
  showGraphTotal: boolean;
  /**
   * 店舗選択リストで現在選択中の店舗
   */
  currentHalls: string[];
  /**
   * テーブルの非表示項目一覧
   */
  selectedCurrentFieldCode?: string;
};

// Stateの初期値
const initialState: TerminalTransitionReportsSettingState = {
  searchCondition: dataTerminalTransitionDefaultValue().setting,
  selectedShu: [],
  selectedDateRangeParams: {
    dateType: dataTerminalTransitionDefaultValue().dateRangeParams.dateType,
    dateRange: dataTerminalTransitionDefaultValue().dateRangeParams.dateRange,
  },
  columnsForTransitionOrder: [],
  tableFilterItems: [],
  showGraphNumberLabel: true,
  showGraphPlan: true,
  showGraphTotal: true,
  currentHalls: [],
  selectedCurrentFieldCode: undefined,
};

/**
 * Selector
 */

/**
 * 期間推移設定全てを取得する
 * @returns 全設定データ
 */
export function terminalTransitionReportsSettingSelector(rootState: {
  terminalTransitionReportsSetting: TerminalTransitionReportsSettingState;
}) {
  return rootState.terminalTransitionReportsSetting;
}

/**
 * 期間推移の現在選択されている検索条件を取得する
 * @returns 現在選択されている検索条件
 */
export function terminalTransitionReportsSearchConditionSelector(rootState: {
  terminalTransitionReportsSetting: TerminalTransitionReportsSettingState;
}) {
  return rootState.terminalTransitionReportsSetting.searchCondition;
}

/**
 * 期間推移の検索フォームで選択中の種別・種別グループを取得する（選択可能な種別・種別グループ一覧）
 * @returns 選択可能な種別一覧
 */
export function terminalTransitionReportsSelectedShuSelector(rootState: {
  terminalTransitionReportsSetting: TerminalTransitionReportsSettingState;
}) {
  return rootState.terminalTransitionReportsSetting.selectedShu;
}

/**
 * 期間推移で選択中の日付タイプを取得する（テーブルに表示されている日付タイプ・期間）
 * @returns 現在選択されている期間
 */
export function terminalTransitionReportsSelectedDateRangeParamsSelector(rootState: {
  terminalTransitionReportsSetting: TerminalTransitionReportsSettingState;
}) {
  return rootState.terminalTransitionReportsSetting.selectedDateRangeParams;
}

/**
 * 導入後推移の推移項目の並び順
 * @returns
 */
export function terminalTransitionReportsColumnsForTransitionOrderSelector(rootState: {
  terminalTransitionReportsSetting: TerminalTransitionReportsSettingState;
}) {
  return rootState.terminalTransitionReportsSetting.columnsForTransitionOrder;
}

/**
 * 期間推移で選択中のお気に入りIDを取得する
 * @returns 現在選択中のお気に入りID（undefined時未選択）
 */
export const terminalTransitionReportsSelectedFavoriteSelector = (
  state: RootState
) => {
  return state.terminalTransitionReportsSetting.selectedFavoriteId;
};

/**
 * 期間推移で選択中のお気に入りデータを取得する
 * @returns 現在選択中のお気に入りデータ（undefined時未選択）
 */
export const terminalTransitionReportsSelectedFavoriteDataSelector = createSelector(
  [terminalTransitionReportsSelectedFavoriteSelector, favoritesSelector],
  (favoriteId, favorites) => {
    if (favoriteId === undefined) return;

    return favorites?.favorites
      ?.filter((favorite) => favorite.id === favoriteId)
      .shift();
  }
);

/**
 * 期間推移で選択中のお気に入りデータ内の期間推移データのみを取得する
 * @returns 現在選択中の期間推移のお気に入りデータ
 */
export const terminalTransitionReportsSelectedFavoritePageSettingSelector = createSelector(
  terminalTransitionReportsSelectedFavoriteDataSelector,
  (favorite) => {
    return favorite?.pageSetting?.terminalTransition;
  }
);

/**
 * 非表示項目一覧を取得する
 * @returns 非表示項目一覧
 */
export const terminalTransitionReportsTableFilterSelector = (
  state: RootState
) => {
  return state.terminalTransitionReportsSetting.tableFilterItems;
};

// 推移グラフで数値ラベルを表示するかどうか
export const terminalTransitionReportsGraphShowNumberLabelSelector = createSelector(
  terminalTransitionReportsSettingSelector,
  (setting) => setting.showGraphNumberLabel
);

// 推移グラフで予定を表示するかどうか
export const terminalTransitionReportsGraphShowPlanSelector = createSelector(
  terminalTransitionReportsSettingSelector,
  (setting) => setting.showGraphPlan
);

// 推移グラフで予定を表示するかどうか
export const terminalTransitionReportsGraphShowTotalSelector = createSelector(
  terminalTransitionReportsSettingSelector,
  (setting) => setting.showGraphTotal
);

// 項目絞込リストで選択中の項目抽出
export const terminalTransitionCurrentFieldsSelector = (state: RootState) => {
  return state.terminalTransitionReportsSetting.selectedCurrentFieldCode;
};

/**
 * 検索条件に指定している店舗
 * @returns string[]
 */
export const terminalTransitionReportsSettingSelectedHallsSelector = createSelector(
  terminalTransitionReportsSearchConditionSelector,
  (searchCondition) => {
    return searchCondition.halls ?? [];
  }
);

/**
 * 店舗選択リストで選択中の店舗
 */
export const terminalTransitionReportsSettingCurrentHallsSelector = createSelector(
  terminalTransitionReportsSettingSelector,
  ({ currentHalls }) => (currentHalls.length > 0 ? currentHalls : [])
);

/**
 * Reducer
 */

export function terminalTransitionReportsSettingReducer(
  state = initialState,
  action: TerminalTransitionReportsSettingAction
): TerminalTransitionReportsSettingState {
  switch (action.type) {
    case SELECT_TERMINAL_TRANSITION_REPORTS_SEARCH_CONDITION:
      return {
        ...state,
        searchCondition: action.payload.params,
      };
    case RESET_TERMINAL_TRANSITION_REPORTS_SEARCH_CONDITION:
      return {
        ...state,
        searchCondition: initialState.searchCondition,
        currentHalls: [],
      };
    case SELECT_TERMINAL_TRANSITION_REPORTS_SHU:
      return {
        ...state,
        selectedShu: action.payload.shus,
      };
    case SELECT_TERMINAL_TRANSITION_REPORTS_DATE_RANGE_PARAMS: {
      const dateType = action.payload.dateType;
      const dateRange = action.payload.dateRange;
      return {
        ...state,
        selectedDateRangeParams: { dateType, dateRange },
      };
    }
    case SELECT_TERMINAL_TRANSITION_REPORTS_COLUMNS_FOR_TRANSITION_ORDER: {
      return {
        ...state,
        columnsForTransitionOrder: action.payload.columnsForTransition,
      };
    }
    case RESET_TERMINAL_TRANSITION_REPORTS_DATE_RANGE_PARAMS:
      return {
        ...state,
        selectedDateRangeParams: initialState.selectedDateRangeParams,
      };
    case RESET_TERMINAL_TRANSITION_REPORTS_SHU:
      return {
        ...state,
        selectedShu: [],
      };
    case CHANGE_TERMINAL_TRANSITION_REPORTS_FAVORITE:
      return {
        ...state,
        selectedFavoriteId: action.payload.favorite,
      };
    case ADD_TERMINAL_TRANSITION_REPORTS_TABLE_FILTER:
      return {
        ...state,
        tableFilterItems: [
          ...state.tableFilterItems,
          action.payload.columnCode,
        ],
      };
    case REMOVE_TERMINAL_TRANSITION_REPORTS_TABLE_FILTER:
      return {
        ...state,
        tableFilterItems: state.tableFilterItems.filter(
          (columnCode) => columnCode !== action.payload.columnCode
        ),
      };
    case FILTER_TERMINAL_TRANSITION_REPORTS_TABLE_FILTER:
      return {
        ...state,
        tableFilterItems: state.tableFilterItems.filter(
          (columnCode) => columnCode === action.payload.columnCode
        ),
      };
    case APPLY_TERMINAL_TRANSITION_REPORTS_TABLE_FILTER:
      return {
        ...state,
        tableFilterItems: action.payload.tableFilterItems,
      };
    case RESET_TERMINAL_TRANSITION_REPORTS_TABLE_FILTER:
      return {
        ...state,
        tableFilterItems: [],
      };
    case SELECT_GRAPH_SHOW_GRAPH_NUMBER_LABEL:
      return {
        ...state,
        showGraphNumberLabel: action.payload.flag,
      };
    case SELECT_GRAPH_SHOW_GRAPH_PLAN:
      return {
        ...state,
        showGraphPlan: action.payload.flag,
      };
    case SELECT_GRAPH_SHOW_GRAPH_TOTAL:
      return {
        ...state,
        showGraphTotal: action.payload.flag,
      };

    case SEARCH_TERMINAL_TRANSITION_REPORTS: {
      return {
        ...state,
        currentHalls: [],
      };
    }
    case SEARCH_TERMINAL_TRANSITION_REPORTS_DATE_RANGE_SLIDE: {
      return {
        ...state,
        selectedDateRangeParams: {
          ...state.selectedDateRangeParams,
          dateRange: action.payload.dateRange,
        },
      };
    }
    case SELECT_TERMINAL_TRANSITION_REPORTS_CURRENT_HALLS:
    case CHANGE_TERMINAL_TRANSITION_REPORTS_CURRENT_HALLS:
      return {
        ...state,
        currentHalls: action.payload.halls,
      };
    case CHANGE_TERMINAL_TRANSITION_CURRENT_FIELD:
      return {
        ...state,
        selectedCurrentFieldCode: action.payload.fieldCode,
      };
    default:
      return state;
  }
}
