import { createSelector } from 'reselect';

import { settingsOptionsDaiDefaultValue } from '../../domain/dai/settingsOptionsDaiDefaultValue';
import { SettingsOptionsDai } from '../../domain/dai/types';
import { LoadingState } from '../../domain/schemas';

import { RootState } from '../../store';

// Action Types

const FETCH_SETTINGS_OPTIONS_DAI = 'FETCH_SETTINGS_OPTIONS_DAI' as const;
const FETCH_SETTINGS_OPTIONS_DAI_REQUEST = 'FETCH_SETTINGS_OPTIONS_DAI_REQUEST' as const;
const FETCH_SETTINGS_OPTIONS_DAI_SUCCESS = 'FETCH_SETTINGS_OPTIONS_DAI_SUCCESS' as const;
const RENEW_SETTINGS_OPTIONS_DAI = 'RENEW_SETTINGS_OPTIONS_DAI' as const;

export const SettingsOptionsDaiActionTypes = {
  FETCH_SETTINGS_OPTIONS_DAI,
  FETCH_SETTINGS_OPTIONS_DAI_REQUEST,
  FETCH_SETTINGS_OPTIONS_DAI_SUCCESS,
  RENEW_SETTINGS_OPTIONS_DAI,
};

// Action Creators

function fetchSettingsOptionsDaiAction() {
  return {
    type: FETCH_SETTINGS_OPTIONS_DAI,
  };
}

function fetchSettingsOptionsDaiRequestAction() {
  return {
    type: FETCH_SETTINGS_OPTIONS_DAI_REQUEST,
  };
}

function fetchSettingsOptionsDaiSuccessAction(
  settingsOptionsDai: SettingsOptionsDai
) {
  return {
    type: FETCH_SETTINGS_OPTIONS_DAI_SUCCESS,
    payload: { settingsOptionsDai },
  };
}

function renewSettingsOptionsDaiAction() {
  return {
    type: RENEW_SETTINGS_OPTIONS_DAI,
  };
}

export const SettingsOptionsDaiActionCreators = {
  fetchSettingsOptionsDaiAction,
  fetchSettingsOptionsDaiRequestAction,
  fetchSettingsOptionsDaiSuccessAction,
  renewSettingsOptionsDaiAction,
};

// Actions

type FetchSettingsOptionsDaiAction = ReturnType<
  typeof fetchSettingsOptionsDaiAction
>;

type SettingsOptionsDaiAction =
  | FetchSettingsOptionsDaiAction
  | ReturnType<typeof fetchSettingsOptionsDaiRequestAction>
  | ReturnType<typeof fetchSettingsOptionsDaiSuccessAction>
  | ReturnType<typeof renewSettingsOptionsDaiAction>;

// State

type SettingsOptionsDaiState = {
  loadingState: LoadingState;
  settingsOptionsDai?: SettingsOptionsDai;
};

const initialState: SettingsOptionsDaiState = {
  loadingState: 'prepare',
  settingsOptionsDai: undefined,
};

// Selector

/**
 *  settingsOptionsを全て取得する
 */
export const settingsOptionsDaiSelector = (state: RootState) => {
  const settings = state.settingsOptionsDai.settingsOptionsDai;
  // MEMO: コンポーネント側がundefined許容しないため、空の場合空データを返す
  return settings ? settings : settingsOptionsDaiDefaultValue;
};

// 現在のローディング状態を取得
export const settingsOptionsDaiLoadingStateSelector = (state: RootState) => {
  return state.settingsOptionsDai.loadingState;
};

// SearchConditionだけ取得する
export const settingsOptionsDaiSearchConditionSelector = createSelector(
  settingsOptionsDaiSelector,
  (settingsOptionsDai) => {
    // MEMO: コンポーネント側がundefined許容しないため、空の場合空データを返す
    if (settingsOptionsDai === undefined)
      return settingsOptionsDaiDefaultValue.searchCondition;

    return settingsOptionsDai.searchCondition;
  }
);

/**
 * フィルタ-用のジャンル一覧を取得する
 * @returns フィルタ-用のジャンル一覧
 */
export const settingsOptionsDaiGenresSelector = createSelector(
  settingsOptionsDaiSelector,
  (settingsOptionsDai) => {
    // MEMO: コンポーネント側がundefined許容しないため、空の場合空データを返す
    if (settingsOptionsDai === undefined)
      return settingsOptionsDaiDefaultValue.genres.dai;

    return settingsOptionsDai.genres.dai;
  }
);

// 表示項目として表示する項目を取得
export const settingsOptionsDaiFieldsSelector = createSelector(
  settingsOptionsDaiSelector,
  (settingsOptionsDai) => settingsOptionsDai.fields
);

/**
 * 台別データ表示項目のFieldsのisNewがtrueのものがあるかどうかを取得する
 */
export const settingsOptionsDaiFieldsIsNewSelector = createSelector(
  settingsOptionsDaiFieldsSelector,
  (fields) => {
    return fields.dai.some((item) => item.isNew);
  }
);

// 機種コードからP-Sensorの機種コードに変換するテーブルを取得する
export const settingsOptionsDaiNumberConversionTableSelector = createSelector(
  settingsOptionsDaiSelector,
  (settingsOptionsDai) => settingsOptionsDai?.kiCodeToPSensorKiCode || {}
);

// Reducer

export function settingsOptionsDaiReducer(
  state = initialState,
  action: SettingsOptionsDaiAction
): SettingsOptionsDaiState {
  switch (action.type) {
    case FETCH_SETTINGS_OPTIONS_DAI_REQUEST:
      return {
        ...state,
        loadingState: 'loading',
      };
    case FETCH_SETTINGS_OPTIONS_DAI_SUCCESS:
      return {
        ...state,
        loadingState: 'loaded',
        settingsOptionsDai: action.payload.settingsOptionsDai,
      };
    case RENEW_SETTINGS_OPTIONS_DAI:
      return initialState;
    default:
      return state;
  }
}
