import { SxProps, Theme } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { FC } from 'react';

import { CenterLayout } from '../layouts/CenterLayout';

/**
 * Styles
 */

const styles: Record<string, SxProps<Theme>> = {
  loadingBody: {
    padding: '32px 56px',
    textAlign: 'center',
  },
  loadingText: {
    fontSize: '16px',
    marginBottom: '16px',
  },
};

export const LoadingLayout: FC = () => {
  return (
    <>
      <CenterLayout>
        <Paper elevation={8} sx={styles.loadingBody}>
          <Typography component="p" align="center" sx={styles.loadingText}>
            読み込み中
          </Typography>
          <CircularProgress />
        </Paper>
      </CenterLayout>
    </>
  );
};
