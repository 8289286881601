const SET_ERROR = 'SET_ERROR' as const;
const CLEAR_ERROR = 'CLEAR_ERROR' as const;

// Action

function setError(message: string) {
  return {
    type: SET_ERROR,
    payload: { message },
  };
}

function clearError() {
  return {
    type: CLEAR_ERROR,
  };
}

export const ErrorActionCreators = {
  setError,
  clearError,
};

type SetErrorAction = ReturnType<typeof setError>;
type ClearErrorAction = ReturnType<typeof clearError>;

type ErrorAction = SetErrorAction | ClearErrorAction;

type ErrorState = {
  error:
    | {
        isError: false;
        message?: unknown;
      }
    | {
        isError: true;
        message: string | undefined;
      };
};

const initialState: ErrorState = {
  error: {
    isError: false,
  },
};

// Selector

export function errorSelector(rootState: { error: ErrorState }) {
  return rootState.error;
}

// Reducer

export function errorReducer(
  state = initialState,
  action: ErrorAction
): ErrorState {
  switch (action.type) {
    case SET_ERROR:
      return {
        ...state,
        error: {
          isError: true,
          message: action.payload.message,
        },
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: {
          isError: false,
        },
      };
    default:
      return state;
  }
}
