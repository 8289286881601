import { SettingsOptionsKi } from '../domain/settingsOptionsKi';

// 検索条件項目のデフォルトデータ
export const settingsOptionsKiDefaultValue: SettingsOptionsKi = {
  searchCondition: {
    makers: [],
    kiList: [],
    shuGroupList: [],
    shuList: [],
    areas: [],
    halls: [],
    sisTypes: [],
    latestBaseDateForDailySisData: '',
    latestBaseDateForWeeklySisData: '',
    latestBaseDateForMonthlySisData: '',
  },
  markings: [],
  filters: [],
  fields: {
    ki: [],
    dai: [],
    kasidama: [],
    marketAreaGraph: [],
  },
  kiCodeToPSensorKiCode: {},
  genres: {
    ki: [],
    dai: [],
    kasidama: [],
  },
};
