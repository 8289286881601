import { Column } from '../domain/schemas';

/**
 * Column[]をexpectOrderの順に並べて、存在しないものは前へ、SIS項目で存在しないものは後ろに並べる
 */
export const sortFields = (columns: Column[], expectOrder: string[]) => {
  if (expectOrder.length === 0) {
    return columns;
  }

  const orderedForTransitionCode = [...columns].sort((a, b) => {
    if (!expectOrder.includes(a.code) && a.isSisField) {
      return 1;
    }
    if (!expectOrder.includes(b.code) && b.isSisField) {
      return -1;
    }

    return expectOrder.indexOf(b.code) > expectOrder.indexOf(a.code) ? -1 : 1;
  });

  return orderedForTransitionCode;
};
