import type { ComponentProps, FC } from 'react';

type Props = ComponentProps<'svg'>;

export const SisByminAlarmIcon: FC<Props> = (props) => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 9C18.4853 9 20.5 6.98528 20.5 4.5C20.5 2.01472 18.4853 0 16 0C13.5147 0 11.5 2.01472 11.5 4.5C11.5 6.98528 13.5147 9 16 9ZM17.5981 4.46909V4.89835H16.3582L16.3541 4.90555V5.46841H17.5981V5.89423H16.3541V7H15.6192V5.89423H14.402V5.46841H15.6192V4.89835H14.402V4.46909H15.4052L14 2H14.8307L16.0057 4.27693L17.1847 2H18L16.6014 4.46909H17.5981ZM14.3094 9.67822C13.5073 9.33894 12.7804 8.84442 12.1702 8.22306C12.2328 8.56468 12.2645 8.91131 12.2648 9.25864V15.3874H4.08908V9.25383C4.08908 6.71695 5.634 4.64572 8.18056 4.64572C9.03423 4.63144 9.86886 4.89912 10.555 5.40727C10.3938 4.83504 10.3122 4.24338 10.3124 3.64892C10.3124 3.42094 10.3124 3.19781 10.3464 2.97468C10.1388 2.89995 9.92757 2.836 9.71334 2.78308V2.09672C9.72159 1.89014 9.68797 1.68403 9.61462 1.49074C9.54119 1.29747 9.42954 1.121 9.28627 0.971928C9.14306 0.822864 8.97112 0.70427 8.78096 0.623255C8.59072 0.542247 8.38612 0.500488 8.17939 0.500488C7.97259 0.500488 7.76799 0.542247 7.57776 0.623255C7.3876 0.70427 7.21572 0.822864 7.07245 0.971928C6.92917 1.121 6.81751 1.29747 6.74412 1.49074C6.67073 1.68403 6.63714 1.89014 6.64535 2.09672V2.79036C3.72043 3.48642 2.03726 6.10336 2.03726 9.25383V14.3639L0 16.4109V17.432H16.3587V16.4109L14.3094 14.3639V9.67822ZM8.18055 20.5001C8.72223 20.4982 9.24115 20.2821 9.62416 19.8991C10.0072 19.5161 10.2232 18.9972 10.2251 18.4556H6.13361C6.13616 18.9975 6.35272 19.5163 6.73612 19.8993C7.11953 20.2823 7.63866 20.4982 8.18055 20.5001Z"
      fill="black"
      fillOpacity="0.54"
    />
  </svg>
);
