import type { ComponentProps, FC } from 'react';

type Props = ComponentProps<'svg'>;

export const QuestionMarkCheckIcon: FC<Props> = (props) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.9409 9.99955C19.7138 7.97177 18.8042 6.08142 17.3614 4.6386C15.9186 3.19579 14.0282 2.28621 12.0005 2.05909V0H9.99955V2.05909C7.97177 2.28621 6.08142 3.19579 4.6386 4.6386C3.19579 6.08142 2.28621 7.97177 2.05909 9.99955H0V12.0005H2.05909C2.28621 14.0282 3.19579 15.9186 4.6386 17.3614C6.08142 18.8042 7.97177 19.7138 9.99955 19.9409V22H12.0005V19.9409C14.0282 19.7138 15.9186 18.8042 17.3614 17.3614C18.8042 15.9186 19.7138 14.0282 19.9409 12.0005H22V9.99955H19.9409ZM10.9988 18.0031C9.61407 18.0031 8.26038 17.5925 7.10903 16.8232C5.95761 16.054 5.06016 14.9605 4.53013 13.6812C4.0001 12.402 3.8613 10.9943 4.13128 9.63614C4.40125 8.27791 5.06787 7.03031 6.04686 6.051C7.02584 5.07167 8.27324 4.40461 9.63132 4.13417C10.9893 3.86373 12.3971 4.00205 13.6766 4.53163C14.9561 5.06121 16.0498 5.95828 16.8195 7.10944C17.5891 8.26052 18.0002 9.61407 18.0007 10.9988C18.0013 11.9184 17.8206 12.8292 17.469 13.679C17.1173 14.5289 16.6016 15.301 15.9513 15.9513C15.301 16.6016 14.5289 17.1173 13.679 17.469C12.8292 17.8206 11.9184 18.0013 10.9988 18.0007V18.0031Z"
      fill="black"
      fillOpacity="0.54"
    />
    <path
      d="M10.9993 14.045C10.7877 14.045 10.5809 14.1077 10.405 14.2253C10.2291 14.3428 10.0921 14.5099 10.0111 14.7053C9.93015 14.9007 9.90898 15.1158 9.95023 15.3232C9.99148 15.5307 10.0934 15.7213 10.243 15.8709C10.3926 16.0205 10.5831 16.1223 10.7906 16.1636C10.998 16.2049 11.2131 16.1837 11.4086 16.1027C11.604 16.0218 11.771 15.8847 11.8886 15.7088C12.0061 15.5329 12.0688 15.3261 12.0688 15.1146C12.0681 14.8311 11.9553 14.5594 11.7548 14.359C11.5544 14.1586 11.2827 14.0457 10.9993 14.045Z"
      fill="black"
      fillOpacity="0.54"
    />
    <path
      d="M12.0688 6.56042H9.92969V12.9778H12.0688V6.56042Z"
      fill="black"
      fillOpacity="0.54"
    />
  </svg>
);
