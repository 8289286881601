import type { GraphDateType } from './dataKiGraph';
import type { GraphData } from './schemas';

export const GRAPH_FIELD_TYPES = [
  'daisu',
  'kyakuSu',
  'kadoRitu',
  'sijiRitu',
  'senyuRitu',
] as const;

export type GraphFieldType = typeof GRAPH_FIELD_TYPES[number];

export type DataKiMksTransitiveGraphData = {
  data: GraphData;
  setting: {
    field: GraphFieldType;
    startDate: string;
    endDate: string;
    dateType: GraphDateType;
    halls: string[];
    kiList: string[];
    shuList: string[];
    shuGroupIds: string[];
    excludeToday: boolean;
  };
};
