import { format, isAfter, parse } from 'date-fns';
import { call, fork, put, select, takeEvery } from 'redux-saga/effects';

import { FavoriteItem } from '../domain/favorites';
import { MODEL_REPORT_MARKING_CONDITIONS } from '../domain/marking';
import { PPM_SHARE_GRAPH_DATE_LABEL_TYPE } from '../domain/ppmShare/consistent';
import {
  PpmShareReportsFavorite,
  PpmShareReportsFormConditions,
} from '../domain/ppmShare/types';
import { DataPpmShareTransitiveGraphParams } from '../domain/ppmShare/types';
import { DataPpmShareParams } from '../domain/ppmShare/types';
import { GraphData, SearchCondition } from '../domain/schemas';
import { ShuOption } from '../domain/shu';

import { SIS_TYPE_UPDATE_DATES } from '../consistents';
import {
  DataPpmShareActionCreators,
  dataPpmShareColumnsOrderSelector,
  dataPpmShareParamsSelector,
} from '../redux/server/dataPpmShare';
import { DataPpmShareGraphActionCreators } from '../redux/server/dataPpmShareGraph';
import {
  DataPpmShareTransitiveGraphActionCreators,
  dataPpmShareTransitiveGraphDataSelector,
  dataPpmShareTransitiveGraphSearchConditionSelector,
} from '../redux/server/dataPpmShareTransitiveGraph';
import { settingsOptionsPpmShareSearchConditionSelector } from '../redux/server/settingsOptionsPpmShare';
import { ShortenedUrlActionCreators } from '../redux/server/shortenedUrl';
import { ErrorActionCreators } from '../redux/ui/error';
import {
  ChangePpmShareReportsCurrentShuAction,
  ChangePpmShareReportsFavoriteAction,
  CreatePpmShareReportsShortenedUrlAction,
  PpmShareReportsSettingActionCreators,
  PpmShareReportsSettingActionTypes,
  PpmShareReportsSettingState,
  SaveAsPpmShareReportsFavoriteAction,
  SavePpmShareReportsFavoriteAction,
  SearchPpmShareReportsAction,
  SearchPpmShareReportsDateRangeSlideAction,
  SearchPpmShareReportsFieldAction,
  SearchPpmShareReportsMarkingAction,
  SearchPpmShareReportsSortAction,
  ppmShareReportsSearchConditionSelector,
  ppmShareReportsSelectedCurrentShuSelector,
  ppmShareReportsSelectedDateRangeParamsSelector,
  ppmShareReportsSelectedFavoriteDataSelector,
  ppmShareReportsSelectedFavoriteSelector,
  ppmShareReportsSelectedFavoriteSettingSelector,
  ppmShareReportsSelectedShuSelector,
  ppmShareReportsSettingSelector,
  searchPpmShareReportsAction,
} from '../redux/ui/ppmShareReportsSetting';
import { SettingsFavoritesActionCreators } from '../redux/ui/settingsFavorites';
import { compressToEncodedURIComponent } from '../utils/compressToEncodedURIComponent';
import { calcStartDateFromDateType } from '../utils/date';
import { isValidArea } from '../utils/isValidArea';
import {
  PpmShareDateRange,
  ppmShareMakeDateFromDateRange,
} from '../utils/ppmShareDateRange';
import { checkCurrentShu, getShuOption } from '../utils/shu';
import { updateSisTypesV1 } from '../utils/updateSisTypes';

/**
 * 選択中の種別の情報を元に期間指定部分の検索条件を加工する
 * @param dateRange 現在選択中の期間
 * @param isComparison 比較期間の有無
 * @param formConditions 現在の検索条件
 * @returns 加工された期間指定の検索条件
 */
export const searchContidionToDateRangeParams = (
  dateRange: PpmShareDateRange,
  isComparison: boolean,
  formConditions: PpmShareReportsFormConditions
): PpmShareReportsFormConditions => {
  // 期間を元にstartDateとendDateを算出
  let date:
    | ReturnType<typeof ppmShareMakeDateFromDateRange>
    | undefined = undefined;

  // 期間指定がカスタムの場合は現在の検索パラメータの値を送る
  if (dateRange !== 'カスタム') {
    // 指定された期間から日付を算出する
    date = ppmShareMakeDateFromDateRange(dateRange);
  }

  return {
    // dateが算出されていない場合はカスタムと判断し、保持されている検索パラメータの値をそのまま送る
    startDate:
      date !== undefined
        ? format(date.startDate, 'yyyy-MM-dd')
        : formConditions.startDate,
    endDate:
      date !== undefined
        ? format(date.endDate, 'yyyy-MM-dd')
        : formConditions.endDate,
    // 比較期間の有無で startComparisonDate を送るか判定する
    startComparisonDate: isComparison
      ? date !== undefined
        ? format(date.startComparisonDate, 'yyyy-MM-dd')
        : formConditions.startComparisonDate
      : undefined,
    // 比較期間の有無で endComparisonDate を送るか判定する
    endComparisonDate: isComparison
      ? date !== undefined
        ? format(date.endComparisonDate, 'yyyy-MM-dd')
        : formConditions.endComparisonDate
      : undefined,
  };
};

/**
 * お気に入り選択時、検索条件に反映する
 * @param action Action
 */
function* applyFavoriteByIdSaga(action: ChangePpmShareReportsFavoriteAction) {
  const favoriteItem: FavoriteItem | undefined = yield select(
    ppmShareReportsSelectedFavoriteDataSelector
  );

  // 選択されたお気に入りID
  const selectedFavoriteId = action.payload.favorite;

  // デフォルトのお気に入りを選択した場合、リセットと同じ動作を行う
  if (selectedFavoriteId === undefined || favoriteItem === undefined) {
    yield put(
      PpmShareReportsSettingActionCreators.searchResetPpmShareReportsAction()
    );
    yield put(
      PpmShareReportsSettingActionCreators.resetPpmShareReportsTableFilterAction()
    );
    return;
  }

  // PPMシェアのデータを初期化する
  yield fork(initPpmShareReportsDataSaga);

  // 現在選択中のお気に入りデータを取得
  const favorite: PpmShareReportsFavorite | undefined = yield select(
    ppmShareReportsSelectedFavoriteSettingSelector
  );

  if (favorite == null) {
    throw new Error('該当するお気に入りが見つかりません');
  }

  const lastUpdateAt = favoriteItem.updatedAt ?? favoriteItem.createdAt;
  if (lastUpdateAt == null) {
    throw new Error('お気に入りの作成日が存在しません');
  }

  yield fork(applyFavoriteSaga, favorite, lastUpdateAt);
}

/**
 * PpmShareReportsFavoriteの内容を実際に反映させる
 */
export function* applyFavoriteSaga(
  favorite: PpmShareReportsFavorite,
  lastUpdatedAt: string
) {
  // 期間を適用する
  const dateRange = favorite.selectedDateRangeParams.dateRange;
  const isComparison = favorite.selectedDateRangeParams.isComparison;
  yield put(
    PpmShareReportsSettingActionCreators.selectPpmShareReportsDateRangeParamsAction(
      dateRange,
      isComparison
    )
  );

  // 検索条件の期間部分を再計算
  const dateRangeParams = searchContidionToDateRangeParams(
    dateRange,
    isComparison,
    favorite.searchCondition
  );

  // 古いお気に入りのSISタイプの変更
  const sisTypes = isAfter(
    new Date(lastUpdatedAt),
    new Date(SIS_TYPE_UPDATE_DATES.v1)
  )
    ? favorite.searchCondition.sisTypes
    : updateSisTypesV1(favorite.searchCondition.sisTypes);

  if (!isValidArea(favorite.searchCondition.areas)) {
    yield put(
      ErrorActionCreators.setError(
        `お気に入りの検索条件に存在しないエリアが指定されています。\n正しいエリアを選択して検索を再度行い、お気に入りに保存してください。`
      )
    );
  }

  // 検索条件を適用する
  yield put(
    PpmShareReportsSettingActionCreators.selectPpmShareReportsSearchConditionAction(
      {
        ...favorite.searchCondition,
        ...dateRangeParams,
        ...(sisTypes ? { sisTypes } : {}),
      }
    )
  );
  // 選択された種別を適用する
  yield put(
    PpmShareReportsSettingActionCreators.selectPpmShareReportsShuAction(
      favorite.selectedShu
    )
  );
  // 検索条件の種別に選択されていない種別を登録したお気に入りを表示した時、テーブルのセレクタに検索条件で選択した種別の先頭から表示させる
  if (
    favorite.selectedShu.length === 0 ||
    favorite.selectedShu.some((v) => v.code === favorite.currentShu.code)
  ) {
    yield put(
      PpmShareReportsSettingActionCreators.selectPpmShareReportsCurrentShuAction(
        favorite.currentShu
      )
    );
  } else {
    yield put(
      PpmShareReportsSettingActionCreators.selectPpmShareReportsCurrentShuAction(
        favorite.selectedShu.at(0)
      )
    );
  }

  // PPMシェアテーブルを取得
  if (favorite.dataPpmShare?.setting !== undefined) {
    yield put(
      DataPpmShareActionCreators.fetchDataPpmShareAction(
        {
          ...favorite.dataPpmShare.setting,
          // 期間を再算出して上書きする
          ...dateRangeParams,
          ...(sisTypes ? { sisTypes } : {}),
        },
        favorite.selectedShu.length === 0 ||
          favorite.selectedShu.some((v) => v.code === favorite.currentShu.code)
          ? favorite.currentShu
          : favorite.selectedShu.at(0) ?? favorite.currentShu
      )
    );

    // テーブル列の並び順を変更
    if (favorite.dataPpmShare.columnsOrder) {
      yield put(
        DataPpmShareActionCreators.selectDataPpmShareColumnsOrderAction(
          favorite.dataPpmShare.columnsOrder
        )
      );
    } else {
      // 並び順がない場合はリセットする
      yield put(
        DataPpmShareActionCreators.resetDataPpmShareColumnsOrderAction()
      );
    }
  }

  // 店舗別PPMシェアグラフを取得
  yield put(DataPpmShareGraphActionCreators.searchDataPpmShareGraphAction());

  // 推移グラフを取得
  if (favorite.dataPpmShareTransitiveGraph?.setting.dateType !== undefined) {
    // 古いお気に入りのdateType(weekly, monthly)を新しいdateType(13weeks, 13months) に変換する
    const dateType = favorite.dataPpmShareTransitiveGraph?.setting.dateType;
    if ((dateType as string) === 'weekly') {
      favorite.dataPpmShareTransitiveGraph.setting.dateType =
        PPM_SHARE_GRAPH_DATE_LABEL_TYPE.THIRTEEN_WEEKS;
    }
    if ((dateType as string) === 'monthly') {
      favorite.dataPpmShareTransitiveGraph.setting.dateType =
        PPM_SHARE_GRAPH_DATE_LABEL_TYPE.THIRTEEN_MONTHS;
    }

    // startDateを確定するために、endDateからdateType(13weeks, 24weeks, 13months)分を考慮
    const startDate = calcStartDateFromDateType(
      favorite.dataPpmShareTransitiveGraph.setting.dateType,
      parse(dateRangeParams.endDate ?? '', 'yyyy-MM-dd', new Date())
    );

    const selectedShu = favorite.selectedShu;
    const currentShu = favorite.currentShu;
    const {
      shuList: _0,
      shuGroupIds: _1,
      ...restSetting
    } = favorite.dataPpmShareTransitiveGraph.setting;

    const resultShuOption =
      selectedShu.length === 0 ||
      selectedShu.some((v) => v.code === currentShu.code)
        ? currentShu
        : selectedShu.at(0) ?? currentShu;

    const createResultShuOption = (
      shuOption: ShuOption
    ): { shuList: string[] } | { shuGroupIds: string[] } =>
      shuOption.type === 'shuList'
        ? { shuList: [shuOption.code] }
        : { shuGroupIds: [shuOption.code] };

    const result = createResultShuOption(resultShuOption);

    yield put(
      DataPpmShareTransitiveGraphActionCreators.fetchDataPpmShareTransitiveGraphAction(
        // この値は使用されていない
        favorite.currentShu.code,
        {
          ...restSetting,
          ...result,
          // 期間を再算出して上書きする
          endDate: dateRangeParams.endDate,
          startDate: format(startDate, 'yyyy-MM-dd'),
        }
      )
    );
  }
}

/**
 * お気に入りに保存するデータの生成
 */
function* setPageSetting() {
  // 現在のPPMシェアの設定すべて
  const pageSetting: PpmShareReportsSettingState = yield select(
    ppmShareReportsSettingSelector
  );
  // PPMシェアテーブルの検索条件
  const dataPpmShareParams: DataPpmShareParams = yield select(
    dataPpmShareParamsSelector
  );

  // 推移グラフのデータ
  const graphdata: GraphData = yield select(
    dataPpmShareTransitiveGraphDataSelector
  );
  // 推移グラフの検索条件
  const dataPpmShareTransitiveGraphParams:
    | DataPpmShareTransitiveGraphParams
    | undefined = yield select(
    dataPpmShareTransitiveGraphSearchConditionSelector
  );

  // テーブル列の並び順
  const dataPpmShareColumnsOrder: string[] | undefined = yield select(
    dataPpmShareColumnsOrderSelector
  );

  // こんなケースはないが念の為
  if (pageSetting.currentShu === undefined) return;

  const result: FavoriteItem['pageSetting'] = {
    ppmShareReports: {
      searchCondition: pageSetting.searchCondition,
      selectedShu: pageSetting.selectedShu,
      currentShu: pageSetting.currentShu,
      selectedDateRangeParams: pageSetting.selectedDateRangeParams,
      dataPpmShare: {
        setting: dataPpmShareParams,
        columnsOrder: dataPpmShareColumnsOrder,
      },
      dataPpmShareTransitiveGraph: graphdata
        ? {
            setting: dataPpmShareTransitiveGraphParams || {},
          }
        : undefined,
    },
  };
  return result;
}

/**
 * お気に入り上書き保存時の処理
 */
function* saveFavoriteSaga(action: SavePpmShareReportsFavoriteAction) {
  // 選択中のお気に入りID
  const selectedFavoriteId: number | undefined = yield select(
    ppmShareReportsSelectedFavoriteSelector
  );
  // 選択中のお気に入り
  const selectedFavorite: FavoriteItem = yield select(
    ppmShareReportsSelectedFavoriteDataSelector
  );

  // デフォルトの時は動作しない
  if (selectedFavoriteId === undefined) return;

  const pageSetting: FavoriteItem['pageSetting'] = yield call(setPageSetting);
  if (pageSetting == null) return;

  // 変更後のお気に入りデータ
  let favorite: FavoriteItem = {};
  if (action.payload.mode === 'memo') {
    // メモのみ更新モードでの変更後のお気に入りデータ
    favorite = {
      ...selectedFavorite,
      memo: action.payload.memo,
    };
  } else {
    favorite = {
      name: action.payload.name,
      isShared: action.payload.isShared,
      pageSetting,
      memo: action.payload.memo,
      privatelySharedUsers: action.payload.sharedUser,
    };
  }

  // お気に入りを上書き
  yield put(
    SettingsFavoritesActionCreators.patchSettingsFavoritesAction(
      selectedFavoriteId,
      favorite,
      { pageName: 'PPMシェア' }
    )
  );
}

/**
 * お気に入り新規保存時の処理
 */
function* saveAsFavoriteSaga(action: SaveAsPpmShareReportsFavoriteAction) {
  const pageSetting: FavoriteItem['pageSetting'] = yield call(setPageSetting);
  if (pageSetting == null) return;

  // 変更後のお気に入りデータ
  const favorite: FavoriteItem = {
    name: action.payload.name,
    isShared: action.payload.isShared,
    pageName: 'PPMシェア一覧',
    pageSetting,
    memo: action.payload.memo,
    privatelySharedUsers: action.payload.sharedUser,
  };

  // お気に入りを新規保存
  yield put(
    SettingsFavoritesActionCreators.postSettingsFavoritesAction([favorite], {})
  );
}

/**
 * PPMシェアの全てのデータを破棄する
 */
function* initPpmShareReportsDataSaga() {
  yield put(DataPpmShareGraphActionCreators.renewDataPpmShareGraphAction());
  yield put(
    DataPpmShareTransitiveGraphActionCreators.hideDataPpmShareTransitiveGraphAction()
  );
}

/**
 * 検索ボタン押下時に行う処理
 */
function* searchActionSaga(action: SearchPpmShareReportsAction) {
  // PPMシェアのデータを初期化する
  yield fork(initPpmShareReportsDataSaga);

  // 現在の検索条件項目を取得
  const settingsOptions: SearchCondition = yield select(
    settingsOptionsPpmShareSearchConditionSelector
  );
  // 現在選択中の種別
  const currentShu: ShuOption | undefined = yield select(
    ppmShareReportsSelectedCurrentShuSelector
  );

  // すべての種別・種別グループ一覧
  const allShuOption: ShuOption[] = getShuOption(settingsOptions);
  // 現在選択可能な種別一覧
  const shuList =
    action.payload.selectedShu.length > 0
      ? action.payload.selectedShu
      : allShuOption;
  // currentShuが選択可能な種別一覧に含まれていない場合先頭を選択
  const checkedCurrentShu = checkCurrentShu(shuList, currentShu);

  // currentShuがselectedShu（選択可能な種別一覧）にない場合適切な種別を選択する
  if (
    currentShu !== undefined &&
    currentShu.type !== 'none' &&
    checkedCurrentShu?.code !== currentShu.code
  ) {
    // currentShu（選択中の種別）を更新する
    yield put(
      PpmShareReportsSettingActionCreators.selectPpmShareReportsCurrentShuAction(
        checkedCurrentShu
      )
    );
  }

  // selectedShu（選択中の種別一覧）を更新する
  yield put(
    PpmShareReportsSettingActionCreators.selectPpmShareReportsShuAction(
      action.payload.selectedShu
    )
  );

  const dateRange = action.payload.dateRange;
  const isComparison = action.payload.isComparison;

  // dateRange・dateUnit（選択中の日付単位・期間）を更新する
  yield put(
    PpmShareReportsSettingActionCreators.selectPpmShareReportsDateRangeParamsAction(
      dateRange,
      isComparison
    )
  );

  // 検索条件の期間部分を再計算
  const dateRangeParams = searchContidionToDateRangeParams(
    dateRange,
    isComparison,
    action.payload.params
  );

  // 検索条件の加工
  const modifyParams = {
    ...action.payload.params,
    // 期間を再算出して上書きする
    ...dateRangeParams,
  };

  // searchCondition(選択中の検索条件）を更新する
  yield put(
    PpmShareReportsSettingActionCreators.selectPpmShareReportsSearchConditionAction(
      modifyParams
    )
  );

  // データ取得
  yield put(
    DataPpmShareActionCreators.fetchDataPpmShareAction(
      modifyParams,
      checkedCurrentShu
    )
  );

  // 店舗別PPMシェアグラフを取得
  yield put(DataPpmShareGraphActionCreators.searchDataPpmShareGraphAction());
}

/**
 * 検索ボタン押下時、新しい検索条件でデータを取得する
 */
function* handleSearchSaga() {
  yield takeEvery(
    PpmShareReportsSettingActionTypes.SEARCH_PPM_SHARE_REPORTS,
    searchActionSaga
  );
}

/**
 * リセットボタン押下時、デフォルトの検索条件または選択中のお気に入りでデータを取得する
 */
function* resetSearchActionSaga() {
  // PPMシェアのデータを初期化する
  yield fork(initPpmShareReportsDataSaga);
  // 現在選択中のお気に入りを取得する
  const favoriteId: number | undefined = yield select(
    ppmShareReportsSelectedFavoriteSelector
  );

  // お気に入りが選択されている場合はお気に入り適用
  if (favoriteId !== undefined) {
    yield put(
      PpmShareReportsSettingActionCreators.changePpmShareReportsFavoriteAction(
        favoriteId
      )
    );
    return;
  }

  // デフォルトが選択されている場合は検索条件を初期化
  yield put(
    PpmShareReportsSettingActionCreators.resetPpmShareReportsSearchConditionAction()
  );
  yield put(
    PpmShareReportsSettingActionCreators.resetPpmShareReportsTableFilterAction()
  );
  // 選択中の種別をリセットする
  yield put(
    PpmShareReportsSettingActionCreators.resetPpmShareReportsShuAction()
  );

  // 選択中の期間をリセットする
  yield put(
    PpmShareReportsSettingActionCreators.resetPpmShareReportsDateRangeParamsAction()
  );

  // 現在の検索条件を取得
  const searchCondition: PpmShareReportsFormConditions = yield select(
    ppmShareReportsSearchConditionSelector
  );
  // 現在の種別を取得
  const currentShu: ShuOption = yield select(
    ppmShareReportsSelectedCurrentShuSelector
  );

  // セルの並び順をリセットしてデフォルト順にする
  yield put(DataPpmShareActionCreators.resetDataPpmShareColumnsOrderAction());

  // データ取得
  yield put(
    DataPpmShareActionCreators.fetchDataPpmShareAction(
      searchCondition,
      currentShu
    )
  );

  // 店舗別PPMシェアグラフを取得
  yield put(DataPpmShareGraphActionCreators.searchDataPpmShareGraphAction());
}

/**
 * リセットボタン押下時PPMシェアの全てのデータを破棄する
 */
function* handleResetSaga() {
  yield takeEvery(
    PpmShareReportsSettingActionTypes.SEARCH_RESET_PPM_SHARE_REPORTS,
    resetSearchActionSaga
  );
}

/**
 * 変更後の種別でデータを取得する
 */
function* changeCurrentShuSaga(action: ChangePpmShareReportsCurrentShuAction) {
  // 種別が選択されていない場合は処理を中断
  if (action.payload.shu === undefined) return;

  // PPMシェアのデータを初期化する
  yield fork(initPpmShareReportsDataSaga);

  // 現在の検索条件を取得
  const searchCondition: PpmShareReportsFormConditions = yield select(
    ppmShareReportsSearchConditionSelector
  );

  // 変更後の種別でデータを取得する
  yield put(
    DataPpmShareActionCreators.fetchDataPpmShareAction(
      searchCondition,
      action.payload.shu
    )
  );
}

/**
 * 画面共有用短縮URL作成の処理
 */
function* createShortenedUrlSaga(
  action: CreatePpmShareReportsShortenedUrlAction
) {
  const pageSetting: FavoriteItem['pageSetting'] = yield call(setPageSetting);
  if (pageSetting == null) return;

  const compressed = compressToEncodedURIComponent(
    action.payload.pageName,
    pageSetting ?? {}
  );
  const originalUrl = `${action.payload.locationUrl}?q=${compressed}`;

  yield put(ShortenedUrlActionCreators.postShortenedUrlAction({ originalUrl }));
}

/**
 * 選択中の種別変更時、データを取得する
 */
function* handleChangeCurrentShuSaga() {
  yield takeEvery(
    PpmShareReportsSettingActionTypes.CHANGE_PPM_SHARE_REPORTS_CURRENT_SHU,
    changeCurrentShuSaga
  );
}

/**
 * テーブル固有の検索条件を変更してデータを取得する
 * @param params 変更後の検索条件
 */
function* searchSearchConditonSaga(params: PpmShareReportsFormConditions) {
  // PPMシェアのデータを初期化する
  yield fork(initPpmShareReportsDataSaga);

  // 現在の検索条件を取得
  const searchCondition: PpmShareReportsFormConditions = yield select(
    ppmShareReportsSearchConditionSelector
  );
  // 現在の種別を取得
  const currentShu: ShuOption = yield select(
    ppmShareReportsSelectedCurrentShuSelector
  );

  // 新しい検索条件
  const newSearchCondition = {
    ...searchCondition,
    ...params,
  };

  // 検索条件を更新
  yield put(
    PpmShareReportsSettingActionCreators.selectPpmShareReportsSearchConditionAction(
      newSearchCondition
    )
  );

  // データ取得
  yield put(
    DataPpmShareActionCreators.fetchDataPpmShareAction(
      newSearchCondition,
      currentShu
    )
  );
}

/**
 * ソート実行時データを取得する
 */
function* searchSortSaga(action: SearchPpmShareReportsSortAction) {
  yield fork(searchSearchConditonSaga, action.payload);
}

/**
 * マーキング変更時データを取得する
 */
function* searchMarkingSaga(action: SearchPpmShareReportsMarkingAction) {
  const marking =
    action.payload.markingOption.code ===
    MODEL_REPORT_MARKING_CONDITIONS.at(0)?.code
      ? undefined
      : action.payload.markingOption.code;
  const params = {
    marking,
  };

  yield fork(searchSearchConditonSaga, params);
}

/**
 * 表示項目変更時データを取得する
 */
function* searchFieldsSaga(action: SearchPpmShareReportsFieldAction) {
  const fields = action.payload.fields.map((item) => item.code);

  yield fork(searchSearchConditonSaga, { fields });
}

/**
 * テーブル固有の検索条件変更検知時の処理
 */
function* handleTableSearchSaga() {
  // ソート実行時
  yield takeEvery(
    PpmShareReportsSettingActionTypes.SEARCH_PPM_SHARE_REPORTS_SORT,
    searchSortSaga
  );

  // マーキング実行時
  yield takeEvery(
    PpmShareReportsSettingActionTypes.SEARCH_PPM_SHARE_REPORTS_MARKING,
    searchMarkingSaga
  );

  // 表示項目変更時
  yield takeEvery(
    PpmShareReportsSettingActionTypes.SEARCH_PPM_SHARE_REPORTS_FIELDS,
    searchFieldsSaga
  );
}

/**
 * お気に入り関連の処理
 */
function* handleFavoriteSaga() {
  // お気に入り適用
  yield takeEvery(
    PpmShareReportsSettingActionTypes.CHANGE_PPM_SHARE_REPORTS_FAVORITE,
    applyFavoriteByIdSaga
  );
  // お気に入り上書き保存
  yield takeEvery(
    PpmShareReportsSettingActionTypes.SAVE_PPM_SHARE_REPORTS_FAVORITE,
    saveFavoriteSaga
  );
  // お気に入り新規保存
  yield takeEvery(
    PpmShareReportsSettingActionTypes.SAVE_AS_PPM_SHARE_REPORTS_FAVORITE,
    saveAsFavoriteSaga
  );
}

// 期間スライドコンポーネントがクリックされた時に検索期間を設定して再検索を実行する
function* searchDateRangeSlideSaga(
  action: SearchPpmShareReportsDateRangeSlideAction
) {
  const {
    dateRange,
    startDate,
    endDate,
    startComparisonDate,
    endComparisonDate,
  } = action.payload;
  // 検索条件の項目を取得
  const searchCondition: PpmShareReportsFormConditions = yield select(
    ppmShareReportsSearchConditionSelector
  );

  const { isComparison } = yield select(
    ppmShareReportsSelectedDateRangeParamsSelector
  );

  const shuOptions: ShuOption[] = yield select(
    ppmShareReportsSelectedShuSelector
  );

  yield put(
    searchPpmShareReportsAction(
      {
        ...searchCondition,
        startDate,
        endDate,
        startComparisonDate,
        endComparisonDate,
      },
      shuOptions,
      dateRange,
      isComparison
    )
  );
}

// 期間スライドコンポーネントがクリックされた時に実行する処理
function* handleDateRangeSlideSaga() {
  yield takeEvery(
    PpmShareReportsSettingActionTypes.SEARCH_PPM_SHARE_REPORTS_DATE_RANGE_SLIDE,
    searchDateRangeSlideSaga
  );
}

// 画面共有用短縮URL作成の処理
function* handleShortenedUrlSaga() {
  yield takeEvery(
    PpmShareReportsSettingActionTypes.CREATE_PPM_SHARE_REPORTS_SHORTENED_URL,
    createShortenedUrlSaga
  );
}

/**
 * PPMシェアの検索フォームに関するタスクを実行する
 */
export function* ppmShareReportsSettingSaga() {
  yield fork(handleSearchSaga);
  yield fork(handleResetSaga);
  yield fork(handleTableSearchSaga);
  yield fork(handleChangeCurrentShuSaga);
  yield fork(handleFavoriteSaga);
  yield fork(handleDateRangeSlideSaga);
  yield fork(handleShortenedUrlSaga);
}
