import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import { isDebugSentry, isEnabledSentry } from './consistents';

export function setupSentry() {
  // Sentryのサンプル取得率を制御するための環境変数
  // NOTE サンプル取得率の根拠などの情報は、以下のNotionを参照
  // 参考：https://www.notion.so/0339f1a7c8674f9da036a402a4a57c91?pvs=4#4463cc0063fc4cdeb8503a3a10e156c6
  const isValidTracesRate =
    process.env.REACT_APP_SENTRY_ENV === 'prd' ||
    process.env.REACT_APP_SENTRY_ENV === 'stg';
  const isValidReplaysSessionRateRate =
    process.env.REACT_APP_SENTRY_ENV === 'prd';
  const isValidReplaysOnErrorRateRate =
    process.env.REACT_APP_SENTRY_ENV === 'prd' ||
    process.env.REACT_APP_SENTRY_ENV === 'stg';

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DNS,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
        enableInp: true,
      }),
      Sentry.replayIntegration(),
      Sentry.metrics.metricsAggregatorIntegration(),
    ],
    environment: process.env.REACT_APP_SENTRY_ENV,
    enabled: isEnabledSentry,

    // Performance Monitoring
    tracesSampleRate: isValidTracesRate ? 1 : 0,
    // Replay Sessions
    replaysSessionSampleRate: isValidReplaysSessionRateRate ? 0.017 : 0,
    replaysOnErrorSampleRate: isValidReplaysOnErrorRateRate ? 0.17 : 0,

    allowUrls: [
      ...(isEnabledSentry ? ['localhost'] : []),
      // ClarisLink
      /\.dkclaris\.net/i,
      /\.clarislink\.com/i,
      // Amplify環境は取得していないので、取得したい場合には追加する
    ],

    debug: isDebugSentry,
  });
}
