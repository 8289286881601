import { format } from 'date-fns';

import {
  ppmShareDateRanges,
  ppmShareMakeDateFromDateRange,
} from '../../utils/ppmShareDateRange';
import { DAY_TYPE } from '../schemas';
import {
  PpmShareReportsSettingDateRangeParams,
  SettingsOptionsPpmShare,
} from './types';
import { DataPpmShare } from './types';

/**
 * [PPMシェア] デフォルトレスポンスデータを生成する
 *
 * @todo dateRangeParamsを分離させる
 */
export const generateInitialResponse = (): {
  setting: DataPpmShare['setting'];
  data: DataPpmShare['data'];
  dateRangeParams: PpmShareReportsSettingDateRangeParams;
} => {
  const current = new Date();
  const dateRange = ppmShareDateRanges.filter((x) => x === '今週')[0]; // MEMO: 期間のデフォルトは'今週'

  // 指定された期間から日付を算出する
  const date = ppmShareMakeDateFromDateRange(dateRange, current);
  const startDate = format(date.startDate, 'yyyy-MM-dd');
  const endDate = format(date.endDate, 'yyyy-MM-dd');
  const startComparisonDate = format(date.startComparisonDate, 'yyyy-MM-dd');
  const endComparisonDate = format(date.endComparisonDate, 'yyyy-MM-dd');

  return {
    setting: {
      dayType: DAY_TYPE.ALL,
      excludeToday: true,
      startDate,
      endDate,
      startComparisonDate,
      endComparisonDate,
      marking: 'starAndQuestionMarkShareCheck', // デフォルトでマーキングを有効にする
      sort: 'hallId', // 初期ソートカラムはホール名
      order: 'asc', // 初期ソート順は昇順
    },
    data: undefined,
    dateRangeParams: {
      dateRange,
      isComparison: true,
    },
  };
};

export const settingsOptionsPpmShareDefaultValue: SettingsOptionsPpmShare = {
  searchCondition: {
    makers: [],
    kiList: [],
    shuGroupList: [],
    shuList: [],
    areas: [],
    halls: [],
    sisTypes: [],
    latestBaseDateForDailySisData: '',
    latestBaseDateForWeeklySisData: '',
    latestBaseDateForMonthlySisData: '',
  },
  markings: [],
  fields: {
    ppm: [],
  },
  genres: {
    ppm: [],
  },
};
