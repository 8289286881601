import { Column, Item } from '../domain/schemas';

/**
 * 推移系データで項目絞込の項目を選択したときに、特定の項目でデータをフィルターする
 */
export const filterTransitionDataByField = <
  T extends {
    data: {
      columnsForTransition: Column[];
      columnsForNonTransition: Column[];
      rows: {
        dataForNonTransition: Item[];
        dataForTransition: Item[][];
      }[];
    };
  }
>(
  data: T | undefined,
  currentField: string
): T | undefined => {
  if (data == null) {
    return undefined;
  }

  // 展開行のrows情報抽出
  const columnsForTransition = data.data.columnsForTransition;
  const columnsForNonTransition = data.data.columnsForNonTransition;

  // 項目絞込で選択された項目のrow indexを抽出
  const columnForTransitionIndexNums = columnsForTransition
    ?.map((column, i) => (currentField === column.code ? i : ''))
    .filter(String);

  // 項目絞込で選択された項目のrowを抽出
  const filteredRows = data?.data.rows.map((row) => {
    const { dataForNonTransition, dataForTransition, ...rest } = row;
    return {
      dataForNonTransition,
      dataForTransition: [
        dataForTransition[Number(columnForTransitionIndexNums)],
      ],
      ...rest,
    };
  });

  const filteredForTransitionColumns = columnsForTransition?.filter(
    (column) => column.code === currentField
  );
  const filteredForNonTransitionColumns = columnsForNonTransition?.filter(
    (column) => column.code === currentField
  );

  const result = {
    rows: filteredRows,
    columnsForTransition:
      filteredForTransitionColumns?.length !== 0
        ? filteredForTransitionColumns
        : columnsForTransition,
    columnsForNonTransition:
      filteredForNonTransitionColumns?.length !== 0
        ? filteredForNonTransitionColumns
        : columnsForNonTransition,
  };

  if (
    result.rows == null ||
    result.columnsForTransition == null ||
    result.columnsForNonTransition == null
  ) {
    return undefined;
  }

  return {
    ...data,
    data: {
      ...data.data,
      rows: result.rows,
      columnsForTransition: result.columnsForTransition,
      columnsForNonTransition: result.columnsForNonTransition,
    },
  };
};
