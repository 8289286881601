import { DataSis } from './types';

export const dataSwapColumnsOrder = (
  dataSis: DataSis,
  columnsOrder: string[]
): DataSis => {
  if (columnsOrder.length === 0) {
    return dataSis;
  }

  const indexes = columnsOrder
    .map((code) => dataSis.data.columns.findIndex((col) => col.code === code))
    .filter((index) => index !== -1);

  return {
    ...dataSis,
    data: {
      ...dataSis.data,
      columns: indexes.map((index) =>
        dataSis.data.columns.at(index)
      ) as DataSis['data']['columns'],
      rows: dataSis.data.rows.map((row) => ({
        ...row,
        data: indexes.map((index) => row.data.at(index)),
      })) as DataSis['data']['rows'],
    },
  };
};
