import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import LinkIcon from '@mui/icons-material/Link';
import { SxProps, Theme } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Skeleton from '@mui/material/Skeleton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { FC, ReactNode, RefObject, useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import {
  useHeaderState,
  useSetHeaderState,
} from '../../../contexts/HeaderContext';
import { useHeightObserver } from '../../../contexts/HeightContext';

import { pSensorApiUrl } from '../../../consistents';
import { mergeSxProps } from '../../../utils/mergeSxProps';
import { UserAction } from './UserAction';

type Props = {
  /**
   * ページのタイトル
   */
  pageTitle: string | undefined;
  /**
   * タイトルの後に追加するコンポーネント
   */
  afterPageTitle?: ReactNode;
  /**
   * タイトル横に追加するコンポーネント
   */
  beforeGlobalAction?: ReactNode;
};

type PresenterProps = {
  isMenuOpen: boolean;
  toggleDrawer: () => void;
  rootRef: RefObject<HTMLDivElement>;
} & Props;

const styles: Record<string, SxProps<Theme>> = {
  menuButton: {
    marginRight: '36px',
    padding: '12px',
  },
  divider: {
    alignSelf: 'auto',
    height: '30px',
  },
  appBar: {
    marginLeft: '-57px',
    width: 'calc(100% + 57px)',
    top: 0,
    zIndex: (theme) => theme.zIndex.drawer + 1,
    transition: (theme) =>
      theme.transitions.create(['width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
  },
  appBarShift: {
    marginLeft: 0,
    width: 'auto',
    transition: (theme) =>
      theme.transitions.create(['width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
  },
};

export const GlobalHeaderPresenter: FC<PresenterProps> = (props) => {
  const {
    pageTitle,
    isMenuOpen,
    toggleDrawer,
    afterPageTitle,
    beforeGlobalAction,
    rootRef,
  } = props;

  const { pathname } = useLocation();

  return (
    <AppBar
      position="sticky"
      color="primary"
      ref={rootRef}
      sx={mergeSxProps(styles.appBar, isMenuOpen && styles.appBarShift)}
    >
      <Toolbar
        style={{
          paddingLeft: '16px',
          paddingRight: '16px',
        }}
      >
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          sx={styles.menuButton}
          onClick={() => toggleDrawer()}
          size="large"
        >
          {isMenuOpen ? (
            <KeyboardDoubleArrowLeftIcon />
          ) : (
            <KeyboardDoubleArrowRightIcon />
          )}
        </IconButton>
        <Typography variant="h6" noWrap>
          {pageTitle ?? (
            <Skeleton
              variant="text"
              width={200}
              sx={{ backgroundColor: 'primary.light' }}
            />
          )}
        </Typography>
        <Box flex={1} px={2}>
          {afterPageTitle}
        </Box>
        {pathname === '/' ? (
          <Box px={1} mx={0}>
            <List style={{ display: 'flex', flexDirection: 'row' }}>
              <li>
                <ListItem
                  button
                  component="a"
                  href="https://clarislink-support.com"
                  target="_blank"
                  // NOTE: ClarisLinkのサポートサイトへのリンクにはリファラを送信する必要があるため、noreferrerは追加しない
                  rel="noopener"
                >
                  <ListItemIcon
                    style={{
                      color: 'white',
                      minWidth: 'auto',
                      marginRight: '8px',
                    }}
                  >
                    <LinkIcon />
                  </ListItemIcon>
                  <ListItemText primary="サポートサイト" />
                </ListItem>
              </li>
              <li>
                <ListItem
                  button
                  component="a"
                  href={pSensorApiUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ListItemIcon
                    style={{
                      color: 'white',
                      minWidth: 'auto',
                      marginRight: '8px',
                    }}
                  >
                    <LinkIcon />
                  </ListItemIcon>
                  <ListItemText primary="P-SENSOR" />
                </ListItem>
              </li>
            </List>
          </Box>
        ) : null}
        {beforeGlobalAction && <Box px={2}>{beforeGlobalAction}</Box>}
        <Divider orientation="vertical" flexItem sx={styles.divider} />
        <Box px={3}>
          <UserAction />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export const GlobalHeader: FC<Props> = (props) => {
  const { isOpenMenu } = useHeaderState();
  const setIsOpenMenu = useSetHeaderState();

  const toggleDrawer = useCallback(() => {
    setIsOpenMenu((prev) => ({ ...prev, isOpenMenu: !prev.isOpenMenu }));
  }, [setIsOpenMenu]);

  const rootRef = useHeightObserver<HTMLDivElement>((height) => (prev) => {
    if (prev.global.header === height) {
      return prev;
    }
    return {
      ...prev,
      global: { ...prev.global, header: height },
    };
  });

  return (
    <GlobalHeaderPresenter
      isMenuOpen={isOpenMenu}
      toggleDrawer={toggleDrawer}
      rootRef={rootRef}
      {...props}
    />
  );
};
