import { createSelector } from 'reselect';

import { DataKiParams } from '../../domain/dataKi';
import { DataKi3rdRow } from '../../domain/dataKi3rdRow';

import { RootState } from '../../store';
import { adjustedFields } from '../../utils/adjustedFields';
import { getOrderedTableData } from '../../utils/orderedCell';
import {
  dataKiColumnsOrderSelector,
  dataKiDataColumnsSelector,
} from './dataKi';

/**
 * Action Types
 */

const SEARCH_DATA_KI_3RDROW = 'SEARCH_DATA_KI_3RDROW' as const;
const FETCH_DATA_KI_3RDROW = 'FETCH_DATA_KI_3RDROW' as const;
const HIDE_DATA_KI_3RDROW = 'HIDE_DATA_KI_3RDROW' as const;
const HIDE_ALL_DATA_KI_3RDROW = 'HIDE_ALL_DATA_KI_3RDROW' as const;
const FETCH_DATA_KI_3RDROW_REQUEST = 'FETCH_DATA_KI_3RDROW_REQUEST' as const;
const FETCH_DATA_KI_3RDROW_SUCCESS = 'FETCH_DATA_KI_3RDROW_SUCCESS' as const;
const RENEW_DATA_KI_3RDROW = 'RENEW_DATA_KI_3RDROW' as const;

export const DataKi3rdRowActionTypes = {
  SEARCH_DATA_KI_3RDROW,
  FETCH_DATA_KI_3RDROW,
  HIDE_DATA_KI_3RDROW,
  HIDE_ALL_DATA_KI_3RDROW,
  FETCH_DATA_KI_3RDROW_REQUEST,
  FETCH_DATA_KI_3RDROW_SUCCESS,
  RENEW_DATA_KI_3RDROW,
};

/**
 * Action Creators
 */

// 指定した検索条件で台番別データ展開行を取得する
function fetchDataKi3rdRowAction(
  mainFieldId: string,
  hallId: string,
  params: DataKiParams
) {
  return {
    type: FETCH_DATA_KI_3RDROW,
    payload: { mainFieldId, hallId, params },
  };
}

/**
 * 台番別データ行を取得する
 *   @param   mainFieldId   機種番号/SISタイプ/メーカーのID
 *   @param   mainFieldType 機種番号/SISタイプ/メーカーの種別
 *   @param   hallId    ホール番号
 */
function searchDataKi3rdRowAction(
  mainFieldId: string,
  mainFieldType: string,
  hallId: string
) {
  return {
    type: SEARCH_DATA_KI_3RDROW,
    payload: { mainFieldId, mainFieldType, hallId },
  };
}

/**
 * 指定した台番別データ行を削除する
 *   @param   mainFieldId   機種番号/SISタイプ/メーカーのID
 *   @param   hallId    ホール番号
 */
function hideDataKi3rdRowAction(mainFieldId: string, hallId: string) {
  return {
    type: HIDE_DATA_KI_3RDROW,
    payload: { mainFieldId, hallId },
  };
}

/**
 * すべての台番別データ行を削除する
 */
function hideAllDataKi3rdRowAction() {
  return {
    type: HIDE_ALL_DATA_KI_3RDROW,
  };
}

/**
 * 展開行データ取得前に呼ぶ
 */
function fetchDataKi3rdRowRequestAction() {
  return {
    type: FETCH_DATA_KI_3RDROW_REQUEST,
    payload: {},
  };
}

/**
 * 展開行データ取得成功時、取得したデータを登録する
 * @param mainFieldId 機種番号
 * @param hallId ホール番号
 * @param dataKi3rdRow 取得した展開行データ
 */
function fetchDataKi3rdRowSuccessAction(
  mainFieldId: string,
  hallId: string,
  dataKi3rdRow: DataKi3rdRow
) {
  return {
    type: FETCH_DATA_KI_3RDROW_SUCCESS,
    payload: { mainFieldId, hallId, dataKi3rdRow },
  };
}

function renewDataKi3rdRowAction() {
  return {
    type: RENEW_DATA_KI_3RDROW,
  };
}

export const DataKi3rdRowActionCreators = {
  searchDataKi3rdRowAction,
  fetchDataKi3rdRowAction,
  hideDataKi3rdRowAction,
  hideAllDataKi3rdRowAction,
  fetchDataKi3rdRowRequestAction,
  fetchDataKi3rdRowSuccessAction,
  renewDataKi3rdRowAction,
};

/**
 * Actions
 */

export type SearchDataKi3rdRowAction = ReturnType<
  typeof searchDataKi3rdRowAction
>;
export type FetchDataKi3rdRowAction = ReturnType<
  typeof fetchDataKi3rdRowAction
>;
type HideDataKi3rdRowAction = ReturnType<typeof hideDataKi3rdRowAction>;
type HideAllDataKi3rdRowAction = ReturnType<typeof hideAllDataKi3rdRowAction>;

type DataKi3rdRowAction =
  | SearchDataKi3rdRowAction
  | FetchDataKi3rdRowAction
  | HideDataKi3rdRowAction
  | HideAllDataKi3rdRowAction
  | ReturnType<typeof fetchDataKi3rdRowRequestAction>
  | ReturnType<typeof fetchDataKi3rdRowSuccessAction>
  | ReturnType<typeof renewDataKi3rdRowAction>;

/**
 * State
 */

type DataKi3rdRowState = {
  isLoading: boolean;
  dataKi3rdRows: {
    [mainFieldId: string]: {
      [hallId: string]: DataKi3rdRow | undefined;
    };
  };
};

// Stateの初期値
export const initialState: DataKi3rdRowState = {
  isLoading: false,
  dataKi3rdRows: {},
};

/**
 * Selector
 */

const wholeSelector = (state: RootState) => {
  return state.dataKi3rdRow;
};

// 台別データ行をすべて取得する
export const dataKi3rdRowSelector = createSelector(
  [wholeSelector, dataKiDataColumnsSelector, dataKiColumnsOrderSelector],
  (dataKi3rdRow, originColumns, ordered) => {
    const modelRows = dataKi3rdRow.dataKi3rdRows;
    if (!ordered || originColumns.length === 0) {
      return modelRows;
    }

    const result = Object.keys(modelRows).reduce((accum, mainFieldId) => {
      const hallRows = modelRows[mainFieldId];
      const orderedHallRows = Object.keys(hallRows).reduce((acc, hallId) => {
        const thirdRows = hallRows[hallId];
        if (
          !thirdRows ||
          !thirdRows.setting ||
          !thirdRows.setting.fields ||
          !thirdRows.setting.mainField
        ) {
          return acc;
        }
        // https://github.com/DKClaris/claris-general/issues/3314
        // thirdRows.setting.fields.unshift(originColumns[0].code);
        const orderedThirdRows: DataKi3rdRow | undefined = {
          ...thirdRows,
          data: getOrderedTableData(
            ordered,
            originColumns,
            thirdRows?.data.rows ?? []
          ),
        } as DataKi3rdRow;
        return { ...acc, [hallId]: orderedThirdRows };
      }, {});

      return { ...accum, [mainFieldId]: orderedHallRows };
    }, {});

    return result;
  }
);

/**
 * 該当する機種番号の展開行を取得する
 * @param   mainFieldId   機種番号/SISタイプ/メーカーのID
 * @returns 該当する展開行
 */
export const modelDataKi3rdRowSelector = (mainFieldId: string | undefined) => {
  return createSelector(dataKi3rdRowSelector, (dataKi3rdRows) => {
    if (!mainFieldId) {
      return {};
    }
    return mainFieldId && dataKi3rdRows[mainFieldId];
  });
};

/**
 * 該当する機種番号とホール番号の展開行を取得する
 *   @param    mainFieldId   機種番号/SISタイプ/メーカーのID
 *   @param    hallId        ホール番号
 *   @returns  該当する展開行
 */
export const singleDataKi3rdRowSelector = (
  mainFieldId: string | undefined,
  hallId: string
) => {
  return createSelector(dataKi3rdRowSelector, (dataKi3rdRows) => {
    const row = mainFieldId && dataKi3rdRows[mainFieldId];
    return (row && row[hallId]) || undefined;
  });
};

/**
 * 取得済みの展開行の検索条件を全て取得する（お気に入り保存用）
 * @returns 現在の展開行のすべての検索条件
 */
export const dataKi3rdRowParamsSelector = createSelector(
  dataKi3rdRowSelector,
  (rows) =>
    Object.entries(rows).flatMap(([mainFieldId, row]) => {
      return Object.entries(row)
        .map(([hallId, value]) => {
          if (!hallId || !value) {
            return null;
          }
          const { setting } = value;
          return {
            mainFieldId: mainFieldId,
            hallId: hallId,
            setting: {
              ...setting,
              // 出力タイプに応じて複数fieldsの省略をする
              fields: adjustedFields(setting),
            },
          };
        })
        .filter((x) => x !== null);
    })
);

// 台別データのローディング状態を返す
export const dataKi3rdRowIsLoadingSelector = createSelector(
  wholeSelector,
  ({ isLoading }) => isLoading
);

/**
 * Reducer
 */
export function dataKi3rdRowReducer(
  state = initialState,
  action: DataKi3rdRowAction
): DataKi3rdRowState {
  switch (action.type) {
    case FETCH_DATA_KI_3RDROW_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_DATA_KI_3RDROW_SUCCESS:
      return {
        ...state,
        isLoading: false,
        dataKi3rdRows: {
          ...state.dataKi3rdRows,
          [action.payload.mainFieldId]: {
            ...state.dataKi3rdRows[action.payload.mainFieldId],
            [action.payload.hallId]: action.payload.dataKi3rdRow,
          },
        },
      };
    case HIDE_DATA_KI_3RDROW:
      return {
        ...state,
        isLoading: false,
        dataKi3rdRows: {
          ...state.dataKi3rdRows,
          [action.payload.mainFieldId]: {
            ...state.dataKi3rdRows[action.payload.mainFieldId],
            [action.payload.hallId]: undefined,
          },
        },
      };
    case HIDE_ALL_DATA_KI_3RDROW:
      return initialState;
    case RENEW_DATA_KI_3RDROW:
      return initialState;
    default:
      return state;
  }
}
