import type { ComponentProps, FC } from 'react';

type Props = ComponentProps<'svg'>;

export const SisDataAlarmIcon: FC<Props> = (props) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.7914 9.45243H21.9985C21.9198 7.76517 21.4728 6.11557 20.6889 4.61941C19.905 3.12325 18.8032 1.81676 17.4607 0.791626L15.8867 2.3705C17.0253 3.18425 17.9671 4.24279 18.6428 5.46833C19.3185 6.69386 19.711 8.05522 19.7914 9.45243Z"
      fill="black"
      fillOpacity="0.54"
    />
    <path
      d="M17.6207 10.0053C17.6207 6.6098 15.8212 3.78188 12.6561 3.03246V2.28062C12.6397 1.85257 12.4581 1.44752 12.1495 1.15048C11.8409 0.853435 11.4292 0.6875 11.0008 0.6875C10.5724 0.6875 10.1608 0.853435 9.85214 1.15048C9.54352 1.44752 9.36195 1.85257 9.34552 2.28062V3.03246C6.19021 3.77946 4.38093 6.60737 4.38093 10.0053V15.5204L2.18359 17.7274V18.831H19.8278V17.7274L17.6207 15.5204V10.0053ZM15.4137 16.6336H6.58796V10.0053C6.58796 7.26949 8.2469 5.04062 10.9996 5.04062C13.7523 5.04062 15.4137 7.26949 15.4137 10.0053V16.6336Z"
      fill="black"
      fillOpacity="0.54"
    />
    <path
      d="M6.12391 2.3705L4.54503 0.791626C3.19771 1.81269 2.09195 3.11811 1.30639 4.61501C0.520842 6.11193 0.0748014 7.76358 0 9.45243H2.20703C2.28509 8.05337 2.67785 6.68995 3.35602 5.46377C4.0342 4.23758 4.98033 3.18023 6.12391 2.3705Z"
      fill="black"
      fillOpacity="0.54"
    />
    <path
      d="M10.9976 21.707C11.5824 21.7051 12.1426 21.472 12.5561 21.0585C12.9695 20.645 13.2027 20.0848 13.2046 19.5H8.79297C8.79427 20.0845 9.02686 20.6448 9.43998 21.0584C9.85303 21.4719 10.4131 21.7051 10.9976 21.707Z"
      fill="black"
      fillOpacity="0.54"
    />
  </svg>
);
