import { AxiosResponse } from 'axios';
import { call, fork, put, select, takeEvery } from 'redux-saga/effects';

import { GraphSis } from '../domain/sis/types';

import {
  ChangeDataSisGraphDateTypeAction,
  ChangeDataSisGraphFieldAction,
  DataSisGraphActionCreators,
  DataSisGraphActionTypes,
  FetchDataSisGraphAction,
  SearchDataSisGraphAction,
  dataSisGraphSettingSelector,
} from '../redux/server/dataSisGraph';
import { Api, buildConfig } from '../utils/api';
import { handleErrorSaga } from './errorSaga';

/**
 * dataSisGraphのデータを取得する
 */
function* fetchDataSisGraphSaga(api: Api, action: FetchDataSisGraphAction) {
  const { params } = action.payload;
  try {
    yield put(DataSisGraphActionCreators.fetchDataSisGraphRequestAction());
    const response: AxiosResponse<GraphSis> = yield call(
      api.get,
      '/data/sis/graph',
      buildConfig(params)
    );

    yield put(
      DataSisGraphActionCreators.fetchDataSisGraphSuccessAction(response.data)
    );
  } catch (error: unknown) {
    yield put(DataSisGraphActionCreators.renewDataSisGraphAction());
    yield fork(handleErrorSaga, error);
  }
}

/**
 * 指定したグラフ項目の推移グラフを取得する
 */
function* changeDataSisGraphFieldSaga(action: ChangeDataSisGraphFieldAction) {
  const { field } = action.payload;

  const searchCondition: GraphSis['setting'] = yield select(
    dataSisGraphSettingSelector
  );

  yield put(
    DataSisGraphActionCreators.fetchDataSisGraphAction({
      ...searchCondition,
      field: field,
    })
  );
}

/**
 * 指定した期間の推移グラフを取得する
 */
function* changeDataSisGraphDateTypeSaga(
  action: ChangeDataSisGraphDateTypeAction
) {
  const { dateType } = action.payload;

  const searchCondition: GraphSis['setting'] = yield select(
    dataSisGraphSettingSelector
  );

  yield put(
    DataSisGraphActionCreators.fetchDataSisGraphAction({
      ...searchCondition,
      dateType: dateType,
    })
  );
}

function* searchDataSisGraphSaga(action: SearchDataSisGraphAction) {
  yield put(
    DataSisGraphActionCreators.changeDataSisGraphKiListAction(
      action.payload.kiList
    )
  );
  yield put(
    DataSisGraphActionCreators.fetchDataSisGraphAction({
      kiList: action.payload.kiList,
      sisDataType: action.payload.selectedDataType,
    })
  );
}

function* closeDataSisGraphSaga() {
  yield put(DataSisGraphActionCreators.renewDataSisGraphAction());
}

function* handleFetchDataSisGraphSaga(api: Api) {
  yield takeEvery(
    DataSisGraphActionTypes.FETCH_DATA_SIS_GRAPH,
    fetchDataSisGraphSaga,
    api
  );
}

function* handleSearchDataSisGraphSaga() {
  yield takeEvery(
    DataSisGraphActionTypes.SEARCH_DATA_SIS_GRAPH,
    searchDataSisGraphSaga
  );
}

function* handleChangeDataSisGraphSaga() {
  yield takeEvery(
    DataSisGraphActionTypes.CHANGE_DATA_SIS_GRAPH_FIELD,
    changeDataSisGraphFieldSaga
  );
  yield takeEvery(
    DataSisGraphActionTypes.CHANGE_DATA_SIS_GRAPH_DATE_TYPE,
    changeDataSisGraphDateTypeSaga
  );
}

function* handleCloseDataSisGraphSaga() {
  yield takeEvery(
    DataSisGraphActionTypes.CLOSE_DATA_SIS_GRAPH,
    closeDataSisGraphSaga
  );
}

export function* dataSisGraphSaga(context: { api: Api }) {
  yield fork(handleFetchDataSisGraphSaga, context.api);
  yield fork(handleSearchDataSisGraphSaga);
  yield fork(handleChangeDataSisGraphSaga);
  yield fork(handleCloseDataSisGraphSaga);
}
