import { AxiosResponse } from 'axios';
import { call, fork, put, select, take, takeEvery } from 'redux-saga/effects';

import { LoadingState } from '../domain/schemas';
import { ShuOption } from '../domain/shu';
import {
  DataTransitionAfterIntroduction,
  DataTransitionAfterIntroductionParams,
  SettingsOptionsTransitionAfterIntroduction,
} from '../domain/transitionAfterIntroduction/types';

import {
  DataTransitionAfterIntroductionActionCreators,
  DataTransitionAfterIntroductionActionTypes,
  FetchDataTransitionAfterIntroductionAction,
  dataTransitionAfterIntroductionDataSearchConditionSelector,
} from '../redux/server/dataTransitionAfterIntroduction';
import {
  SettingsOptionsTransitionAfterIntroductionActionTypes,
  settingsOptionsTransitionAfterIntroductionLoadingStateSelector,
  settingsOptionsTransitionAfterIntroductionSelector,
} from '../redux/server/settingsOptionsTransitionAfterIntroduction';
import {
  selectTransitionAfterIntroductionReportsCurrentShuAction,
  selectTransitionAfterIntroductionReportsShuAction,
  transitionAfterIntroductionReportsSelectedCurrentShuSelector,
  transitionAfterIntroductionReportsSelectedShuSelector,
} from '../redux/ui/transitionAfterIntroductionReportsSetting';
import { Api, buildConfig } from '../utils/api';
import {
  convertShuOption,
  existShuOption,
  getShuOption,
  selectShus2HallReportSearchCondition,
} from '../utils/shu';
import { handleErrorSaga } from './errorSaga';
import { changeColumnsOrderSaga } from './transitionAfterIntroductionReportsSagas';

export function* fetchDataTransitionAfterIntroductionSaga(
  api: Api,
  action: FetchDataTransitionAfterIntroductionAction
) {
  try {
    yield put(
      DataTransitionAfterIntroductionActionCreators.fetchDataTransitionAfterIntroductionRequestAction()
    );

    const params: DataTransitionAfterIntroductionParams = {
      ...action.payload.params,
      ...selectShus2HallReportSearchCondition([action.payload.shuOption]),
      halls: action.payload.currentHalls,
    };

    // 非推移項目が何も選択されなかった場合、機種名を明示的に追加する
    // これがなければデフォルトの非推移項目が表示されてしまうため
    if (params.nonTransitiveFields && params.nonTransitiveFields.length === 0) {
      params.nonTransitiveFields = ['kiTushoMei'];
    }

    const response: AxiosResponse<DataTransitionAfterIntroduction> = yield call(
      api.get,
      '/data/transitionAfterIntroduction',
      buildConfig(params)
    );

    yield put(
      DataTransitionAfterIntroductionActionCreators.fetchDataTransitionAfterIntroductionSuccessAction(
        response.data
      )
    );

    yield fork(changeColumnsOrderSaga);
  } catch (error: unknown) {
    yield put(
      DataTransitionAfterIntroductionActionCreators.renewDataTransitionAfterIntroductionAction()
    );
    yield fork(handleErrorSaga, error);
  }
}

/**
 * リクエストした種別のデータとレスポンスの種別データが不一致だったときに、レスポンスの値で上書きする
 *
 * 種別の不一致は種別が削除されたとき、あるいは共有グループをまたいだお気に入りで発生する
 */
function* validateShuSaga() {
  const currentShu: ShuOption = yield select(
    transitionAfterIntroductionReportsSelectedCurrentShuSelector
  );
  // currentShuが存在しない場合には初期リクエストになるため何もしない
  if (currentShu == null) {
    return;
  }

  const setting: DataTransitionAfterIntroductionParams = yield select(
    dataTransitionAfterIntroductionDataSearchConditionSelector
  );
  const exist = existShuOption(setting, currentShu);
  if (exist) {
    return;
  }

  const settingsOptionsLoadingState: LoadingState = yield select(
    settingsOptionsTransitionAfterIntroductionLoadingStateSelector
  );
  if (settingsOptionsLoadingState !== 'loaded') {
    yield take(
      SettingsOptionsTransitionAfterIntroductionActionTypes.FETCH_SETTINGS_OPTIONS_TRANSITION_AFTER_INTRODUCTION_SUCCESS
    );
  }
  // FETCH_SETTINGS_OPTIONS_KI_SUCCESSを待つのでundefinedにならない
  const settingsOptions: SettingsOptionsTransitionAfterIntroduction = yield select(
    settingsOptionsTransitionAfterIntroductionSelector
  );
  const shuOption =
    convertShuOption(setting, settingsOptions.searchCondition.shuGroupList) ??
    // 画面リンクを利用して存在しない場合は最初の値を選択する
    getShuOption(settingsOptions.searchCondition).at(0);

  if (shuOption == null) {
    throw new Error('適切な種別グループまたは種別が見つかりませんでした');
  }

  yield put(
    selectTransitionAfterIntroductionReportsCurrentShuAction(shuOption)
  );

  const selectedShu: ShuOption[] = yield select(
    transitionAfterIntroductionReportsSelectedShuSelector
  );

  // selectedShuの変更
  if (selectedShu.length > 0) {
    const validatedSelectedShu = [
      shuOption,
      ...selectedShu.filter((items) => items.code !== currentShu.code),
    ];
    // TODO: 変換後の重複の削除をする
    yield put(
      selectTransitionAfterIntroductionReportsShuAction(validatedSelectedShu)
    );
  }
}

function* handleFetchDataTransitionAfterIntroductionSaga(api: Api) {
  yield takeEvery(
    DataTransitionAfterIntroductionActionTypes.FETCH_DATA_TRANSITION_AFTER_INTRODUCTION,
    fetchDataTransitionAfterIntroductionSaga,
    api
  );

  yield takeEvery(
    DataTransitionAfterIntroductionActionTypes.FETCH_DATA_TRANSITION_AFTER_INTRODUCTION_SUCCESS,
    validateShuSaga
  );
}

export function* dataTransitionAfterIntroductionSagas(context: { api: Api }) {
  yield fork(handleFetchDataTransitionAfterIntroductionSaga, context.api);
}
