import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { NavLink, useLocation } from 'react-router-dom';

import { WithNewIcon } from '../../atoms/WithNewIcon/WithNewIcon';
import { WithUpdateIcon } from '../../atoms/WithUpdateIcon/WithUpdateIcon';

type Props = {
  name: string;
  href: string;
  decorationIcon?: {
    type?: 'new' | 'update';
    prdReleaseDate: string;
  };
};

export const GlobalNavigationSettingCollapseItem = (props: Props) => {
  const { name, href, decorationIcon } = props;

  const router = useLocation();

  const isSelected = (target: string, current: string) => {
    return target === current;
  };

  return (
    <ListItemButton
      disableRipple
      component={NavLink}
      to={href}
      sx={{ width: 'auto', pl: 5 }}
      selected={isSelected(href, router.pathname)}
    >
      {(() => {
        if (decorationIcon == null) {
          return <ListItemText primary={name} />;
        }

        const { type, prdReleaseDate } = decorationIcon;
        switch (type) {
          case 'new':
            return (
              <WithNewIcon
                icon={<ListItemText primary={name} />}
                prdReleaseDate={prdReleaseDate}
                sx={{ left: -38, bottom: 4 }}
              />
            );
          case 'update':
          default:
            return (
              <WithUpdateIcon
                prdReleaseDate={prdReleaseDate}
                sx={{ left: -24, bottom: 4 }}
              >
                <ListItemText primary={name} />
              </WithUpdateIcon>
            );
        }
      })()}
    </ListItemButton>
  );
};
