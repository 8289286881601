import { ClarisApiError } from '../../domain/error';
import { LoadingState } from '../../domain/schemas';
import {
  SettingsShuGroups,
  SettingsShuGroupsParams,
} from '../../domain/settingsShuGroups';

/**
 * TODO:
 * loadingStateを異なるAPIで共通で利用しているため、それぞれのLoading状態を個別にもたせる必要があります
 * https://github.com/DKClaris/claris-general/issues/3709
 */

// Action Types

const FETCH_SETTINGS_SHU_GROUPS = 'FETCH_SETTINGS_SHU_GROUPS' as const;
const FETCH_SETTINGS_SHU_GROUPS_REQUEST = 'FETCH_SETTINGS_SHU_GROUPS_REQUEST' as const;
const FETCH_SETTINGS_SHU_GROUPS_SUCCESS = 'FETCH_SETTINGS_SHU_GROUPS_SUCCESS' as const;
const RENEW_SETTINGS_SHU_GROUPS = 'RENEW_SETTINGS_SHU_GROUPS' as const;
const POST_SETTINGS_SHU_GROUPS = 'POST_SETTINGS_SHU_GROUPS' as const;
const POST_SETTINGS_SHU_GROUPS_REQUEST = 'POST_SETTINGS_SHU_GROUPS_REQUEST' as const;
const POST_SETTINGS_SHU_GROUPS_SUCCESS = 'POST_SETTINGS_SHU_GROUPS_SUCCESS' as const;
const POST_SETTINGS_SHU_GROUPS_FAILURE = 'POST_SETTINGS_SHU_GROUPS_FAILURE' as const;
const PUT_SETTINGS_SHU_GROUPS = 'PUT_SETTINGS_SHU_GROUPS' as const;
const PUT_SETTINGS_SHU_GROUPS_REQUEST = 'PUT_SETTINGS_SHU_GROUPS_REQUEST' as const;
const PUT_SETTINGS_SHU_GROUPS_SUCCESS = 'PUT_SETTINGS_SHU_GROUPS_SUCCESS' as const;
const PUT_SETTINGS_SHU_GROUPS_FAILURE = 'PUT_SETTINGS_SHU_GROUPS_FAILURE' as const;
const DELETE_SETTINGS_SHU_GROUPS = 'DELETE_SETTINGS_SHU_GROUPS' as const;
const DELETE_SETTINGS_SHU_GROUPS_REQUEST = 'DELETE_SETTINGS_SHU_GROUPS_REQUEST' as const;
const DELETE_SETTINGS_SHU_GROUPS_SUCCESS = 'DELETE_SETTINGS_SHU_GROUPS_SUCCESS' as const;
const DELETE_SETTINGS_SHU_GROUPS_FAILURE = 'DELETE_SETTINGS_SHU_GROUPS_FAILURE' as const;

export const SettingsShuGroupsActionTypes = {
  FETCH_SETTINGS_SHU_GROUPS,
  FETCH_SETTINGS_SHU_GROUPS_REQUEST,
  FETCH_SETTINGS_SHU_GROUPS_SUCCESS,
  RENEW_SETTINGS_SHU_GROUPS,
  POST_SETTINGS_SHU_GROUPS,
  POST_SETTINGS_SHU_GROUPS_REQUEST,
  POST_SETTINGS_SHU_GROUPS_SUCCESS,
  POST_SETTINGS_SHU_GROUPS_FAILURE,
  PUT_SETTINGS_SHU_GROUPS,
  PUT_SETTINGS_SHU_GROUPS_REQUEST,
  PUT_SETTINGS_SHU_GROUPS_SUCCESS,
  PUT_SETTINGS_SHU_GROUPS_FAILURE,
  DELETE_SETTINGS_SHU_GROUPS,
  DELETE_SETTINGS_SHU_GROUPS_REQUEST,
  DELETE_SETTINGS_SHU_GROUPS_SUCCESS,
  DELETE_SETTINGS_SHU_GROUPS_FAILURE,
};

// Action Creators

function fetchSettingsShuGroupsAction() {
  return {
    type: FETCH_SETTINGS_SHU_GROUPS,
  };
}

function fetchSettingsShuGroupsRequestAction() {
  return {
    type: FETCH_SETTINGS_SHU_GROUPS_REQUEST,
  };
}

function fetchSettingsShuGroupsSuccessAction(
  settingsShuGroups: SettingsShuGroups
) {
  return {
    type: FETCH_SETTINGS_SHU_GROUPS_SUCCESS,
    payload: { settingsShuGroups },
  };
}

function renewSettingsShuGroupsAction() {
  return {
    type: RENEW_SETTINGS_SHU_GROUPS,
  };
}

function postSettingsShuGroupsAction(query: SettingsShuGroupsParams) {
  return {
    type: POST_SETTINGS_SHU_GROUPS,
    payload: { query },
  };
}

function postSettingsShuGroupsRequestAction() {
  return {
    type: POST_SETTINGS_SHU_GROUPS_REQUEST,
  };
}

function postSettingsShuGroupsSuccessAction(
  settingsShuGroups: SettingsShuGroups
) {
  return {
    type: POST_SETTINGS_SHU_GROUPS_SUCCESS,
    payload: { settingsShuGroups },
  };
}

function postSettingsShuGroupsFailureAction(error: ClarisApiError) {
  return {
    type: POST_SETTINGS_SHU_GROUPS_FAILURE,
    payload: { error },
  };
}

function putSettingsShuGroupsAction(
  id: string,
  query: SettingsShuGroupsParams
) {
  return {
    type: PUT_SETTINGS_SHU_GROUPS,
    payload: { id, query },
  };
}

function putSettingsShuGroupsRequestAction() {
  return {
    type: PUT_SETTINGS_SHU_GROUPS_REQUEST,
  };
}

function putSettingsShuGroupsSuccessAction(
  settingsShuGroups: SettingsShuGroups
) {
  return {
    type: PUT_SETTINGS_SHU_GROUPS_SUCCESS,
    payload: { settingsShuGroups },
  };
}

function putSettingsShuGroupsFailureAction(error: ClarisApiError) {
  return {
    type: PUT_SETTINGS_SHU_GROUPS_FAILURE,
    payload: { error },
  };
}

function deleteSettingsShuGroupsAction(id: string) {
  return {
    type: DELETE_SETTINGS_SHU_GROUPS,
    payload: { id },
  };
}

function deleteSettingsShuGroupsRequestAction() {
  return {
    type: DELETE_SETTINGS_SHU_GROUPS_REQUEST,
  };
}

function deleteSettingsShuGroupsSuccessAction(
  settingsShuGroups: SettingsShuGroups
) {
  return {
    type: DELETE_SETTINGS_SHU_GROUPS_SUCCESS,
    payload: { settingsShuGroups },
  };
}

function deleteSettingsShuGroupsFailureAction(error: ClarisApiError) {
  return {
    type: DELETE_SETTINGS_SHU_GROUPS_FAILURE,
    payload: { error },
  };
}

export const SettingsShuGroupsActionCreators = {
  fetchSettingsShuGroupsAction,
  fetchSettingsShuGroupsRequestAction,
  fetchSettingsShuGroupsSuccessAction,
  renewSettingsShuGroupsAction,
  postSettingsShuGroupsAction,
  postSettingsShuGroupsRequestAction,
  postSettingsShuGroupsSuccessAction,
  postSettingsShuGroupsFailureAction,
  putSettingsShuGroupsAction,
  putSettingsShuGroupsRequestAction,
  putSettingsShuGroupsSuccessAction,
  putSettingsShuGroupsFailureAction,
  deleteSettingsShuGroupsAction,
  deleteSettingsShuGroupsRequestAction,
  deleteSettingsShuGroupsSuccessAction,
  deleteSettingsShuGroupsFailureAction,
};

// Actions

type FetchSettingsShuGroupsAction = ReturnType<
  typeof fetchSettingsShuGroupsAction
>;

export type PostSettingsShuGroupsAction = ReturnType<
  typeof postSettingsShuGroupsAction
>;

export type PutSettingsShuGroupsAction = ReturnType<
  typeof putSettingsShuGroupsAction
>;

export type DeleteSettingsShuGroupsAction = ReturnType<
  typeof deleteSettingsShuGroupsAction
>;

type SettingsShuGroupsAction =
  | FetchSettingsShuGroupsAction
  | ReturnType<typeof fetchSettingsShuGroupsRequestAction>
  | ReturnType<typeof fetchSettingsShuGroupsSuccessAction>
  | ReturnType<typeof renewSettingsShuGroupsAction>
  | PostSettingsShuGroupsAction
  | ReturnType<typeof postSettingsShuGroupsRequestAction>
  | ReturnType<typeof postSettingsShuGroupsSuccessAction>
  | ReturnType<typeof postSettingsShuGroupsFailureAction>
  | PutSettingsShuGroupsAction
  | ReturnType<typeof putSettingsShuGroupsRequestAction>
  | ReturnType<typeof putSettingsShuGroupsSuccessAction>
  | ReturnType<typeof putSettingsShuGroupsFailureAction>
  | DeleteSettingsShuGroupsAction
  | ReturnType<typeof deleteSettingsShuGroupsRequestAction>
  | ReturnType<typeof deleteSettingsShuGroupsSuccessAction>
  | ReturnType<typeof deleteSettingsShuGroupsFailureAction>;

// State

type SettingsShuGroupsState = {
  settingsShuGroups: SettingsShuGroups | undefined;
  loadingState: LoadingState;
  error: ClarisApiError | undefined;
};

const initialState: SettingsShuGroupsState = {
  settingsShuGroups: undefined,
  loadingState: 'prepare',
  error: undefined,
};

// Selector

export function settingsShuGroupsSelector(rootState: {
  settingsShuGroups: SettingsShuGroupsState;
}) {
  return rootState.settingsShuGroups;
}

// Reducer

export function settingsShuGroupsReducer(
  state = initialState,
  action: SettingsShuGroupsAction
): SettingsShuGroupsState {
  switch (action.type) {
    case FETCH_SETTINGS_SHU_GROUPS_REQUEST:
    case POST_SETTINGS_SHU_GROUPS_REQUEST:
    case PUT_SETTINGS_SHU_GROUPS_REQUEST:
    case DELETE_SETTINGS_SHU_GROUPS_REQUEST:
      return {
        ...state,
        loadingState: 'loading',
      };
    case FETCH_SETTINGS_SHU_GROUPS_SUCCESS:
    case POST_SETTINGS_SHU_GROUPS_SUCCESS:
    case PUT_SETTINGS_SHU_GROUPS_SUCCESS:
    case DELETE_SETTINGS_SHU_GROUPS_SUCCESS:
      return {
        ...state,
        settingsShuGroups: action.payload.settingsShuGroups,
        loadingState: 'loaded',
      };
    case RENEW_SETTINGS_SHU_GROUPS:
      return initialState;
    case POST_SETTINGS_SHU_GROUPS_FAILURE:
    case PUT_SETTINGS_SHU_GROUPS_FAILURE:
    case DELETE_SETTINGS_SHU_GROUPS_FAILURE:
      return {
        ...state,
        loadingState: 'failed',
        error: action.payload.error,
      };
    default:
      return state;
  }
}
