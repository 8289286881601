import {
  add,
  endOfMonth,
  format,
  startOfISOWeek,
  startOfMonth,
} from 'date-fns';

type DateRange =
  | '今週'
  | '前週'
  | '今月'
  | '前月'
  | '前年同月'
  | '過去7日'
  | '過去14日'
  | '過去28日'
  | '過去60日';

const dateRangeToStartEndDate = (
  dateRange: DateRange,
  baseDate: Date
): { startDate: Date; endDate: Date } => {
  switch (dateRange) {
    case '今週': {
      const startDate = startOfISOWeek(baseDate);
      return {
        startDate,
        endDate: baseDate,
      };
    }
    case '前週': {
      const lastWeek = add(baseDate, { weeks: -1 });
      const startDate = startOfISOWeek(lastWeek);
      return {
        startDate,
        endDate: add(startDate, { days: 6 }),
      };
    }
    case '今月': {
      return {
        startDate: startOfMonth(baseDate),
        endDate: baseDate,
      };
    }
    case '前月': {
      const lastMonth = add(baseDate, { months: -1 });
      return {
        startDate: startOfMonth(lastMonth),
        endDate: endOfMonth(lastMonth),
      };
    }
    case '前年同月': {
      const lastMonth = add(baseDate, { years: -1 });
      return {
        startDate: startOfMonth(lastMonth),
        endDate: endOfMonth(lastMonth),
      };
    }
    case '過去7日': {
      return {
        startDate: add(baseDate, { days: -7 }),
        endDate: add(baseDate, { days: -1 }),
      };
    }
    case '過去14日': {
      return {
        startDate: add(baseDate, { days: -14 }),
        endDate: add(baseDate, { days: -1 }),
      };
    }
    case '過去28日': {
      return {
        startDate: add(baseDate, { days: -28 }),
        endDate: add(baseDate, { days: -1 }),
      };
    }
    case '過去60日': {
      return {
        startDate: add(baseDate, { days: -60 }),
        endDate: add(baseDate, { days: -1 }),
      };
    }
  }
};

/**
 * dateRangeからstart/end算出する
 *
 * 比較期間の算出はendDateをbaseDateに指定してください
 */
export const dateRangeToStartEnd = (
  dateRange: DateRange,
  baseDate = new Date()
): { startDate: string; endDate: string } => {
  const { startDate, endDate } = dateRangeToStartEndDate(dateRange, baseDate);
  return {
    startDate: format(startDate, 'yyyy-MM-dd'),
    endDate: format(endDate, 'yyyy-MM-dd'),
  };
};
