import { fork } from 'redux-saga/effects';

import { Api } from '../utils/api';
import { chainStoreReportsSettingSaga } from './chainStoreReportsSettingSaga';
import { daiCostsSettingSaga } from './daiCostsSettingSaga';
import { dataChainStoreKi2ndRowSagas } from './dataChainStoreKi2ndRowSagas';
import { dataChainStoreKiGraphSaga } from './dataChainStoreKiGraphSaga';
import { dataChainStoreKiSagas } from './dataChainStoreKiSagas';
import { dataChainStoreShuGraphSaga } from './dataChainStoreShuGraphSaga';
import { dataChainStoreShuKadoGraphSaga } from './dataChainStoreShuKadoGraphSagas';
import { dataChainStoreShuSagas } from './dataChainStoreShuSagas';
import { dataChainStoreShuWholeSagas } from './dataChainStoreShuWholeSagas';
import { dataDaiSagas } from './dataDaiSagas';
import { dataDepreciationSaga } from './dataDepreciationSaga';
import { dataHallDailyCommentsSagas } from './dataHallDailyCommentsSagas';
import { dataHallKiGraphSaga } from './dataHallKiGraphSaga';
import { dataHallKiSaga } from './dataHallKiSagas';
import { dataHallMksSaga } from './dataHallMksSagas';
import { dataHallShuKadoTableGraphSagas } from './dataHallShuKadoTableGraphSagas';
import { dataHallShuKadoTableSagas } from './dataHallShuKadoTableSagas';
import { dataHallShuSagas } from './dataHallShuSagas';
import { dataKi2ndRowSagas } from './dataKi2ndRowSagas';
import { dataKi3rdRowSagas } from './dataKi3rdRowSagas';
import { dataKiDaiSagas } from './dataKiDaiSagas';
import { dataKiGraphSagas } from './dataKiGraphSagas';
import { dataKiKasidamaSagas } from './dataKiKasidamaSagas';
import { dataKiMksTransitiveGraphSagas } from './dataKiMksTransitiveGraphSagas';
import { dataKiSagas } from './dataKiSagas';
import { dataModeKiListSagas } from './dataModeKiListSagas';
import { dataModeSagas } from './dataModeSagas';
import { dataModelTransition2ndRowSagas } from './dataModelTransition2ndRowSagas';
import { dataModelTransitionGraphSaga } from './dataModelTransitionGraphSaga';
import { dataModelTransitionSagas } from './dataModelTransitionSagas';
import { dataPlanProgressTransitionGraphSaga } from './dataPlanProgressTransitionGraphSagas';
import { dataPlanProgressTransitionSagas } from './dataPlanProgressTransitionSagas';
import { dataPpmShareGraphSagas } from './dataPpmShareGraphSagas';
import { dataPpmShareSagas } from './dataPpmShareSagas';
import { dataPpmShareTransitiveGraphSagas } from './dataPpmShareTransitiveGraphSaga';
import { dataSettingsAreasSagas } from './dataSettingsAreasSagas';
import { dataSettingsEventsSaga } from './dataSettingsEventsSaga';
import { dataSettingsKiTagsSagas } from './dataSettingsKiTagsSagas';
import { dataSettingsModesDetailsSagas } from './dataSettingsModesDetailSaga';
import { dataSettingsModesHallsSagas } from './dataSettingsModesHallsSaga';
import { dataSettingsUnitModelSagas } from './dataSettingsUnitModelSagas';
import { dataSisGraphSaga } from './dataSisGraphSaga';
import { dataSisSaga } from './dataSisSaga';
import { dataTerminalTransition2ndRowSagas } from './dataTerminalTransition2ndRowSagas';
import { dataTerminalTransitionGraphSaga } from './dataTerminalTransitionGraphSaga';
import { dataTerminalTransitionSagas } from './dataTerminalTransitionSagas';
import { dataTransitionAfterIntroduction2ndRowSagas } from './dataTransitionAfterIntroduction2ndRowSagas';
import { dataTransitionAfterIntroductionKiGraphSaga } from './dataTransitionAfterIntroductionKiGraphSaga';
import { dataTransitionAfterIntroductionSagas } from './dataTransitionAfterIntroductionSagas';
import { depreciationSettingSaga } from './depreciationSettingSaga';
import { eventsSettingSaga } from './eventsSettingSaga';
import { favoriteSagas } from './favoriteSagas';
import { hallReportsSettingSaga } from './hallReportsSettingSaga';
import { informationSagas } from './informationSagas';
import { loadedDataStatusesHallsSagas } from './loadedDataStatusesHallsSagas';
import { modeSaga } from './modeSagas';
import { modelReportsSettingSaga } from './modelReportsSettingSaga';
import { modelTransitionReportsSettingSaga } from './modelTransitionReportsSagas';
import { planProgressTransitionReportsSettingSagas } from './planProgressTransitionReportsSettingSagas';
import { ppmShareReportsSettingSaga } from './ppmShareReportsSettingSaga';
import { SettingsNationalHolidaysSagas } from './settingNationalHolidaysSaga';
import { settingsCommentsMonthToDateSagas } from './settingsCommentsMonthToDateSaga';
import { settingsDaiCostsSagas } from './settingsDaiCostsSaga';
import { settingsDataDeletionSagas } from './settingsDataDeletionSagas';
import { settingsFavoritesSagas } from './settingsFavoritesSaga';
import { settingsHallsSagas } from './settingsHallsSaga';
import { settingsKiLinkSagas } from './settingsKiLinkSagas';
import { settingsOptionsChainStoreSagas } from './settingsOptionsChainStoreSagas';
import { settingsOptionsColsOptionPanelPatternSagas } from './settingsOptionsColsOptionPanelPatternSagas';
import { settingsOptionsDaiSagas } from './settingsOptionsDaiSagas';
import { settingsOptionsDataDeletionSagas } from './settingsOptionsDataDeletionSagas';
import { settingsOptionsDepreciationSagas } from './settingsOptionsDepreciationSagas';
import { settingsOptionsEventsSagas } from './settingsOptionsEventsSagas';
import { settingsOptionsHallSagas } from './settingsOptionsHallSagas';
import { settingsOptionsHomeSagas } from './settingsOptionsHomeSagas';
import { settingsOptionsKiLinkSagas } from './settingsOptionsKiLinkSagas';
import { settingsOptionsKiSagas } from './settingsOptionsKiSagas';
import { settingsOptionsModeSagas } from './settingsOptionsModeSagas';
import { settingsOptionsModelTransitionSagas } from './settingsOptionsModelTransitionSagas';
import { settingsOptionsPlanProgressTransitionSagas } from './settingsOptionsPlanProgressTransitionSagas';
import { settingsOptionsPlanSagas } from './settingsOptionsPlanSagas';
import { settingsOptionsPpmShareSagas } from './settingsOptionsPpmShareSagas';
import { settingsOptionsSisSagas } from './settingsOptionsSisSagas';
import { settingsOptionsTerminalTransitionSagas } from './settingsOptionsTerminalTransitionSagas';
import { settingsOptionsTransitionAfterIntroductionSagas } from './settingsOptionsTransitionAfterIntroductionSagas';
import { settingsOptionsUnitModelSagas } from './settingsOptionsUnitModelSagas';
import { settingsOptionsUserSagas } from './settingsOptionsUserSagas';
import { settingsPlansDetailsSagas } from './settingsPlansDetailsSagas';
import { settingsPlansSagas } from './settingsPlansSagas';
import { settingsShuGroupsSagas } from './settingsShuGroupsSagas';
import { settingsUsersSagas } from './settingsUsersSagas';
import { shortenedUrlOriginalHashSagas } from './shortenedUrlOriginalHashSagas';
import { shortenedUrlSagas } from './shortenedUrlSagas';
import { sisSettingSaga } from './sisSettingSaga';
import { terminalTransitionReportsSettingSaga } from './terminalTransitionReportsSettingSaga';
import { transitionAfterIntroductionReportsSettingSaga } from './transitionAfterIntroductionReportsSagas';
import { unitDataListReportsSettingSaga } from './unitDataListReportsSettingSagas';

function* rootSaga(context: { api: Api }) {
  yield fork(dataKiSagas, context);
  yield fork(modelReportsSettingSaga);
  yield fork(dataKi2ndRowSagas, context);
  yield fork(dataKi3rdRowSagas, context);
  yield fork(dataKiGraphSagas, context);
  yield fork(dataKiMksTransitiveGraphSagas, context);
  yield fork(dataKiDaiSagas, context);
  yield fork(dataKiKasidamaSagas, context);
  yield fork(chainStoreReportsSettingSaga);
  yield fork(dataChainStoreShuSagas, context);
  yield fork(dataChainStoreShuWholeSagas, context);
  yield fork(dataChainStoreKiSagas, context);
  yield fork(hallReportsSettingSaga);
  yield fork(dataHallShuSagas, context);
  yield fork(dataChainStoreShuKadoGraphSaga, context);
  yield fork(dataChainStoreShuGraphSaga, context);
  yield fork(dataHallShuKadoTableGraphSagas, context);
  yield fork(dataHallShuKadoTableSagas, context);
  yield fork(dataHallKiSaga, context);
  yield fork(dataHallMksSaga, context);
  yield fork(dataPpmShareSagas, context);
  yield fork(dataPpmShareTransitiveGraphSagas, context);
  yield fork(dataPpmShareGraphSagas, context);
  yield fork(dataTransitionAfterIntroductionSagas, context);
  yield fork(dataTransitionAfterIntroduction2ndRowSagas, context);
  yield fork(dataTransitionAfterIntroductionKiGraphSaga, context);
  yield fork(terminalTransitionReportsSettingSaga);
  yield fork(transitionAfterIntroductionReportsSettingSaga);
  yield fork(dataTerminalTransitionSagas, context);
  yield fork(dataTerminalTransitionGraphSaga, context);
  yield fork(dataPlanProgressTransitionSagas, context);
  yield fork(dataPlanProgressTransitionGraphSaga, context);
  yield fork(dataTerminalTransition2ndRowSagas, context);
  yield fork(SettingsNationalHolidaysSagas, context);
  yield fork(settingsOptionsKiSagas, context);
  yield fork(settingsOptionsChainStoreSagas, context);
  yield fork(settingsOptionsHallSagas, context);
  yield fork(settingsOptionsHomeSagas, context);
  yield fork(settingsOptionsPlanSagas, context);
  yield fork(settingsOptionsTransitionAfterIntroductionSagas, context);
  yield fork(settingsOptionsTerminalTransitionSagas, context);
  yield fork(settingsOptionsUserSagas, context);
  yield fork(settingsOptionsKiLinkSagas, context);
  yield fork(settingsPlansSagas, context);
  yield fork(settingsPlansDetailsSagas, context);
  yield fork(settingsFavoritesSagas, context);
  yield fork(settingsHallsSagas, context);
  yield fork(settingsKiLinkSagas, context);
  yield fork(settingsShuGroupsSagas, context);
  yield fork(settingsUsersSagas, context);
  yield fork(dataChainStoreKi2ndRowSagas, context);
  yield fork(dataChainStoreKiGraphSaga, context);
  yield fork(dataHallDailyCommentsSagas, context);
  yield fork(dataHallKiGraphSaga, context);
  yield fork(loadedDataStatusesHallsSagas, context);
  yield fork(informationSagas, context);
  yield fork(favoriteSagas);
  yield fork(settingsOptionsPlanProgressTransitionSagas, context);
  yield fork(planProgressTransitionReportsSettingSagas);
  yield fork(settingsOptionsPpmShareSagas, context);
  yield fork(ppmShareReportsSettingSaga);
  yield fork(dataModelTransitionSagas, context);
  yield fork(dataModelTransitionGraphSaga, context);
  yield fork(dataModelTransition2ndRowSagas, context);
  yield fork(settingsOptionsModelTransitionSagas, context);
  yield fork(modelTransitionReportsSettingSaga);
  yield fork(settingsOptionsUnitModelSagas, context);
  yield fork(dataSettingsUnitModelSagas, context);
  yield fork(settingsOptionsColsOptionPanelPatternSagas, context);
  yield fork(settingsOptionsDataDeletionSagas, context);
  yield fork(settingsDataDeletionSagas, context);
  yield fork(dataSettingsKiTagsSagas, context);
  yield fork(dataSettingsAreasSagas, context);
  yield fork(settingsOptionsDaiSagas, context);
  yield fork(dataDaiSagas, context);
  yield fork(unitDataListReportsSettingSaga);
  yield fork(settingsOptionsModeSagas, context);
  yield fork(dataModeKiListSagas, context);
  yield fork(dataModeSagas, context);
  yield fork(settingsCommentsMonthToDateSagas, context);
  yield fork(modeSaga);
  yield fork(dataSettingsModesHallsSagas, context);
  yield fork(dataSettingsModesDetailsSagas, context);
  yield fork(shortenedUrlSagas, context);
  yield fork(sisSettingSaga);
  yield fork(dataSisSaga, context);
  yield fork(dataSisGraphSaga, context);
  yield fork(settingsOptionsSisSagas, context);
  yield fork(shortenedUrlOriginalHashSagas, context);
  yield fork(settingsOptionsEventsSagas, context);
  yield fork(dataSettingsEventsSaga, context);
  yield fork(eventsSettingSaga);
  yield fork(settingsDaiCostsSagas, context);
  yield fork(daiCostsSettingSaga);
  yield fork(depreciationSettingSaga);
  yield fork(settingsOptionsDepreciationSagas, context);
  yield fork(dataDepreciationSaga, context);
}

export default rootSaga;
