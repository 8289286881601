import { format } from 'date-fns';

import {
  DataTransitionAfterIntroduction,
  TransitionAfterIntroductionReportsSettingDateRangeParams,
} from '../domain/transitionAfterIntroduction/types';

import {
  makeDateFromDateRange,
  transitionAfterIntroductionDateRange,
} from './transitionAfterIntroductionDateRange';

// 導入後推移のデフォルトデータ
export const dataTransitionAfterIntroductionDefaultValue = (): {
  setting: Partial<DataTransitionAfterIntroduction['setting']>;
  data: DataTransitionAfterIntroduction['data'];
  dateRangeParams: TransitionAfterIntroductionReportsSettingDateRangeParams;
} => {
  const current = new Date();
  const dateRange = transitionAfterIntroductionDateRange[0];

  // 指定された期間から日付を算出する
  const date = makeDateFromDateRange(dateRange, current);
  const startDate = format(date.startDate, 'yyyy-MM-dd');
  const endDate = format(date.endDate, 'yyyy-MM-dd');

  return {
    setting: {
      startDate,
      endDate,
      dateType: 'daily',
      sisFieldTypes: ['result'],
    },
    data: {
      columnsForNonTransition: [],
      columnsForTransition: [],
      dates: [],
      rows: [],
    },
    dateRangeParams: {
      dateRange,
    },
  };
};
