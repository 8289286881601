import { AxiosResponse } from 'axios';
import { call, fork, put, takeEvery } from 'redux-saga/effects';

import { SettingsModesHalls } from '../domain/settingsModes';

import {
  DataSettingsModesHallsActionCreators,
  DataSettingsModesHallsActionTypes,
  FetchDataSettingsModesHallsAction,
} from '../redux/server/dataSettingsModesHalls';
import { ErrorActionCreators } from '../redux/ui/error';
import { Api } from '../utils/api';
import { handleErrorSaga } from './errorSaga';

/**
 * モード登録店舗一覧データを取得する
 * @param api AxiosInstance
 * @param action Action
 */
function* fetchDataSettingsModesHallsSaga(
  api: Api,
  action: FetchDataSettingsModesHallsAction
) {
  try {
    yield put(
      DataSettingsModesHallsActionCreators.fetchDataSettingsModesHallsRequestAction()
    );
    const date = action.payload.params.date;
    const response: AxiosResponse<SettingsModesHalls> = yield call(
      api.get,
      `/settings/modes/halls/${date}`
    );
    yield put(
      DataSettingsModesHallsActionCreators.fetchDataSettingsModesHallsSuccessAction(
        response.data
      )
    );
    if (response.data.data.length === 0) {
      yield put(
        ErrorActionCreators.setError(
          '以下いずれかの理由により、データを表示することができません\n\n・店舗のモードデータを参照する権限が設定されていない\n・指定した日時においてモードデータを参照可能な店舗が存在しない'
        )
      );
      return;
    }
  } catch (error: unknown) {
    yield put(
      DataSettingsModesHallsActionCreators.renewDataSettingsModesHallsAction()
    );
    yield fork(handleErrorSaga, error);
  }
}

function* handleFetchDataSettingsModesHallsSaga(api: Api) {
  yield takeEvery(
    DataSettingsModesHallsActionTypes.FETCH_DATA_SETTINGS_MODES_HALLS,
    fetchDataSettingsModesHallsSaga,
    api
  );
}

export function* dataSettingsModesHallsSagas(context: { api: Api }) {
  yield fork(handleFetchDataSettingsModesHallsSaga, context.api);
}
