import { useEffect, useRef } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { gaDebugMode } from '../consistents';
import { userSelector } from '../redux/ui/user';
import { pathToPageName } from '../utils/pathToPageName';

const trackingId = process.env.REACT_APP_GA_MEASUREMENT_ID ?? '';

export const useGoogleAnalytics = () => {
  const userData = useSelector(userSelector, shallowEqual);
  const location = useLocation();

  const calledOnce = useRef(false);

  useEffect(() => {
    if (userData != null) {
      window.gtag('config', trackingId, {
        page_title: pathToPageName(location.pathname),
        user_id: userData.userId,
        user_properties: {
          kyoyu_group_code: userData.kyoyuGroupCode,
        },
        send_page_view: false,
        debug_mode: gaDebugMode,
      });
    } else {
      window.gtag('config', trackingId, {
        send_page_view: false,
        debug_mode: gaDebugMode,
      });
    }
  }, [location.pathname, userData]);

  // send_page_viewが無効の場合、初期読込時のみpage_viewが上がらないため
  // 手動でイベントを送信する
  useEffect(() => {
    if (calledOnce.current) {
      return;
    }

    if (userData != null) {
      // ログインのリダイレクト処理時でないときにのみ手動送信
      const isLogin = location.search.startsWith('?code=');

      window.gtag('event', 'page_view', {
        send_type: 'manual',
        location_search: location.search,
        ...(isLogin ? { page_title: 'ホーム' } : {}),
      });
      calledOnce.current = true;
    }
  }, [userData, location.search, location.pathname]);
};
