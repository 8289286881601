import { fork, put, select, takeEvery } from 'redux-saga/effects';

import {
  EventsDateRange,
  EventsResponse,
} from '../domain/settingsEvents/types';

import {
  DataSettingsEventsActionCreators,
  dataSettingsEventsSelector,
} from '../redux/server/dataSettingsEvents';
import {
  ChangeEventsSettingPageAction,
  ChangeEventsSettingRowsPerPageAction,
  ChangeSettingsEventsSortAndOrderAction,
  EventsSettingActionCreators,
  EventsSettingActionTypes,
  EventsSettingState,
  SearchSettingsEventsAction,
  TriggerSettingsEventsSortAction,
  eventsSettingSearchParamsSelector,
  eventsSettingSelectedDateRangeSelector,
} from '../redux/ui/eventsSetting';
import { changeOrder } from '../utils/sort';

/**
 * ページ表示時のデータ取得
 *
 * 最新のデータを表示させたいので、毎回fetchする。offsetは維持しない
 */
function* initFetchSettingsEventsDataSaga() {
  const data: EventsResponse | undefined = yield select(
    dataSettingsEventsSelector
  );
  const dateRange: EventsDateRange = yield select(
    eventsSettingSelectedDateRangeSelector
  );

  yield put(
    EventsSettingActionCreators.searchEventsSettingAction(
      data?.setting != null ? { ...data.setting, offset: 0 } : {},
      dateRange
    )
  );
}

/**
 * 変更後の検索フォームでデータを取得する
 */
function* searchDataSettingsEventsSaga(action: SearchSettingsEventsAction) {
  yield put(
    EventsSettingActionCreators.selectEventsSettingDateRangeAction(
      action.payload.dataRange
    )
  );

  yield put(
    DataSettingsEventsActionCreators.fetchDataSettingsEventsAction(
      action.payload.query
    )
  );
}

/**
 * 条件リセットでデータを取得する
 */
function* resetDataSettingsEventsSaga() {
  yield put(EventsSettingActionCreators.renewEventsSettingAction());
  yield put(DataSettingsEventsActionCreators.fetchDataSettingsEventsAction({}));
}

/**
 * ページ変更
 */
function* changeEventsSettingPageSaga(action: ChangeEventsSettingPageAction) {
  const response: EventsResponse | undefined = yield select(
    dataSettingsEventsSelector
  );

  if (response == null) {
    throw new Error('response is not defined');
  }

  yield put(
    DataSettingsEventsActionCreators.fetchDataSettingsEventsAction({
      ...(response.setting ? response.setting : {}),
      offset: action.payload.page * (response.setting.limit ?? 100),
    })
  );
}

/**
 * ページ表示数の変更
 */
function* changeEventsSettingRowsPerPagePageSaga(
  action: ChangeEventsSettingRowsPerPageAction
) {
  const response: EventsResponse | undefined = yield select(
    dataSettingsEventsSelector
  );

  if (response == null) {
    throw new Error('response is not defined');
  }

  yield put(
    DataSettingsEventsActionCreators.fetchDataSettingsEventsAction({
      ...(response.setting ? response.setting : {}),
      limit: action.payload.rowsPerPage,
      offset: 0,
    })
  );
}

function* triggerSettingsEventsSortSaga(
  action: TriggerSettingsEventsSortAction
) {
  const { sort } = action.payload;

  const response: EventsResponse | undefined = yield select(
    dataSettingsEventsSelector
  );
  if (response == null) {
    return;
  }

  yield put(
    EventsSettingActionCreators.changeEventSettingSortAndOrderAction(
      sort,
      changeOrder(sort, response.setting)
    )
  );
}

function* changeSettingsEventsSortAndOrderSaga(
  action: ChangeSettingsEventsSortAndOrderAction
) {
  const { sort, order } = action.payload;

  const searchParams: EventsSettingState['searchParams'] = yield select(
    eventsSettingSearchParamsSelector
  );

  yield put(
    DataSettingsEventsActionCreators.fetchDataSettingsEventsAction({
      ...(searchParams ? searchParams : {}),
      offset: 0,
      sort,
      order,
    })
  );
}

/**
 * 検索時のデータ取得
 */
function* handleSearchFetchSaga() {
  yield takeEvery(
    EventsSettingActionTypes.INIT_EVENTS_SETTING,
    initFetchSettingsEventsDataSaga
  );
  yield takeEvery(
    EventsSettingActionTypes.SEARCH_EVENT_SETTING,
    searchDataSettingsEventsSaga
  );
  yield takeEvery(
    EventsSettingActionTypes.RESET_EVENT_SETTING,
    resetDataSettingsEventsSaga
  );
}

/**
 *  ページの変更
 */
function* handlePageSaga() {
  yield takeEvery(
    EventsSettingActionTypes.CHANGE_EVENTS_SETTING_PAGE,
    changeEventsSettingPageSaga
  );
  yield takeEvery(
    EventsSettingActionTypes.CHANGE_EVENTS_SETTING_ROWS_PER_PAGE,
    changeEventsSettingRowsPerPagePageSaga
  );
}

/**
 * ソートの変更
 */
function* handleSortSaga() {
  yield takeEvery(
    EventsSettingActionTypes.TRIGGER_EVENTS_SETTING_SORT,
    triggerSettingsEventsSortSaga
  );
  yield takeEvery(
    EventsSettingActionTypes.CHANGE_EVENTS_SETTING_SORT_AND_ORDER,
    changeSettingsEventsSortAndOrderSaga
  );
}

export function* eventsSettingSaga() {
  yield fork(handleSearchFetchSaga);
  yield fork(handlePageSaga);
  yield fork(handleSortSaga);
}
