import { SelectOption } from '../components/molecules/SingleSelect';

/**
 * 選択されたオプションに基づいて、店舗コードとエリアコードを返す関数
 */
export const getHallsAndAreas = <
  T extends {
    halls?: string[];
    areas?: string[];
  }
>(
  searchCondition: T,
  selectOption: Pick<SelectOption, 'type' | 'value'>
) => {
  // 平均合計行
  if (selectOption.value === 'null' || selectOption.value == null)
    return {
      halls: searchCondition.halls ?? [],
      areas: searchCondition.areas ?? [],
    };
  if (selectOption.type === 'halls') {
    return { halls: [selectOption.value], areas: searchCondition.areas ?? [] };
  }
  if (selectOption.type === 'areas') {
    return { halls: searchCondition.halls ?? [], areas: [selectOption.value] };
  }
  throw new Error('不正な値です');
};
