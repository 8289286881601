import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useEffect } from 'react';

import {
  useHeaderState,
  useSetHeaderState,
} from '../../../contexts/HeaderContext';

import { useRole } from '../../../hooks/useRole';

import { Base64MenuIcon } from '../../atoms/Icon/Base64Icon/Base64MenuIcon';
import { Tooltip } from '../../atoms/Tooltip/Tooltip';
import { WithNewIcon } from '../../atoms/WithNewIcon/WithNewIcon';
import { GlobalNavigationSettingCollapseItem } from './GlobalNavigationSettingCollapseItem';

const name = 'システム設定' as const;

export const GlobalNavigationSettingItem = () => {
  const setIsOpenMenu = useSetHeaderState();

  const { isOpenMenu, isOpenMenuChild } = useHeaderState();

  const { isAdmin, isMaintenanceUser } = useRole();

  // Drawerが閉じると、設定メニューのコンテンツも非表示にしなければ
  // 設定メニューのコンテンツがはみ出てしまうので、メニューをとじたら設定メニューも閉じる
  useEffect(() => {
    if (!isOpenMenu) {
      setIsOpenMenu((prev) => ({ ...prev, isOpenMenuChild: false }));
    }
  }, [isOpenMenu, setIsOpenMenu]);

  return (
    <>
      <Tooltip
        title={isOpenMenu ? '' : name}
        arrow
        placement="right"
        disableTouchListener
      >
        <ListItem
          button
          onClick={() =>
            setIsOpenMenu((prev) => ({
              ...prev,
              isOpenMenu: true,
              isOpenMenuChild: !prev.isOpenMenuChild,
            }))
          }
        >
          <ListItemIcon sx={{ minWidth: '40px' }}>
            {(() => {
              if (isAdmin || isMaintenanceUser) {
                return (
                  <WithNewIcon
                    icon={
                      <Base64MenuIcon pageName={name} sx={{ opacity: 0.5 }} />
                    }
                    prdReleaseDate="2024-07-10" // 操作履歴
                    sx={{ bottom: -4, right: -4 }}
                  />
                );
              }

              return <Base64MenuIcon pageName={name} sx={{ opacity: 0.5 }} />;
            })()}
          </ListItemIcon>
          <ListItemText primary={name} />
          {isOpenMenuChild ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      </Tooltip>

      <Collapse in={isOpenMenuChild} timeout="auto" unmountOnExit>
        {(isAdmin || isMaintenanceUser) && (
          <GlobalNavigationSettingCollapseItem
            name="共通設定"
            href="/settings/commonSetting"
          />
        )}
        {(isAdmin || isMaintenanceUser) && (
          <GlobalNavigationSettingCollapseItem
            name="店舗設定"
            href="/settings/hall"
          />
        )}
        {(isAdmin || isMaintenanceUser) && (
          <GlobalNavigationSettingCollapseItem
            name="種別グループ設定"
            href="/settings/shu-group"
          />
        )}
        <GlobalNavigationSettingCollapseItem
          name="機種タグ設定"
          href="/settings/kiTags"
        />
        <GlobalNavigationSettingCollapseItem
          name="予定登録"
          href="/settings/plan"
        />
        <GlobalNavigationSettingCollapseItem
          name="経費登録"
          href="/settings/expense"
        />
        <GlobalNavigationSettingCollapseItem
          name="モード登録"
          href="/settings/modes"
        />
        <GlobalNavigationSettingCollapseItem
          name="機種統一設定"
          href="/settings/ki-link"
        />
        <GlobalNavigationSettingCollapseItem
          name="台番機種設定"
          href="/settings/unit-model"
        />
        {isAdmin && (
          <GlobalNavigationSettingCollapseItem
            name="アカウント設定"
            href="/settings/user"
          />
        )}
        {(isAdmin || isMaintenanceUser) && (
          <GlobalNavigationSettingCollapseItem
            name="操作履歴"
            href="/settings/event"
            decorationIcon={{ type: 'new', prdReleaseDate: '2024-07-10' }}
          />
        )}
        {(isAdmin || isMaintenanceUser) && (
          <GlobalNavigationSettingCollapseItem
            name="営業データ削除"
            href="/settings/dataDeletion"
          />
        )}
      </Collapse>
    </>
  );
};
