import { eachDayOfInterval } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

/**
 * eachDayOfIntervalのTZ考慮したwrapper関数
 *
 * eachDayOfIntervalが特定日の指定でTZの挙動が変わるため作成しています
 *
 * @link https://github.com/date-fns/date-fns/issues/2181
 */
export function eachDayOfIntervalInTimezone({
  start,
  end,
}: {
  start: Parameters<typeof utcToZonedTime>[0];
  end: Parameters<typeof utcToZonedTime>[0];
}) {
  const days = eachDayOfInterval({
    start: utcToZonedTime(start, 'Asia/Tokyo'),
    end: utcToZonedTime(end, 'Asia/Tokyo'),
  });

  return days.map((day) => zonedTimeToUtc(day, 'Asia/Tokyo'));
}
