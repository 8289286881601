import { AxiosResponse } from 'axios';
import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';

import { ChainStoreReportsFavorite } from '../domain/chain/types';
import { ChainReportsFormConditions } from '../domain/chainReportsFormConditions';
import { ChainStoreReportsSettingDateRangeParams } from '../domain/chainStoreReportsSettingDateRangeParams';
import {
  DataChainStoreKi,
  DataChainStoreKiParams,
  GraphCommand,
  shouldAutomaticallyOpenGraph,
} from '../domain/dataChainStoreKi';
import { Column } from '../domain/schemas';
import { ChainStoreSearchCondition } from '../domain/settingsOptionsChainStore';
import { ShuOption } from '../domain/shu';

import {
  ChangeDataChainStoreKiColumnsOrderAction,
  DataChainStoreKiActionCreators,
  DataChainStoreKiActionTypes,
  DataChainStoreKiState,
  FetchDataChainStoreKiAction,
  FetchDataChainStoreKiSuccessAction,
  InitDataChainStoreKiAction,
  SearchDataChainStoreKiAction,
  SearchDataChainStoreKiDkSisAction,
  SearchDataChainStoreKiDkSisOffAction,
  SearchDataChainStoreKiDkSisOnAction,
  SearchDataChainStoreKiFavoriteAction,
  SearchDataChainStoreKiFieldTypeAction,
  SearchDataChainStoreKiSortAction,
  dataChainStoreKiColumnsOrderSelector,
  dataChainStoreKiDataColumnsSelector,
  dataChainStoreKiDataIsExistSelector,
  dataChainStoreKiDataIsLoadingSelector,
  dataChainStoreKiDataSearchConditionSelector,
  dataChainStoreKiDataSelector,
  dataChainStoreKiOrderedData,
} from '../redux/server/dataChainStoreKi';
import { DataChainStoreKi2ndRowActionCreators } from '../redux/server/dataChainStoreKi2ndRow';
import {
  DataChainStoreKiGraphActionCreators,
  dataChainStoreKiGraphSelectedKiListSelector,
  dataChainStoreKiGraphSelector,
  hideDataChainStoreKiGraphAction,
} from '../redux/server/dataChainStoreKiGraph';
import {
  settingsOptionsChainStoreKiFieldCodesSelector,
  settingsOptionsChainStoreSearchConditionSelector,
} from '../redux/server/settingsOptionsChainStore';
import {
  chainReportsCheckedByUserModelCodeslSelector,
  chainReportsIsKiGraphClosedSelector,
  chainStoreReportsSearchConditionSelector,
  chainStoreReportsSelectedDateRangeParamsSelector,
  chainStoreReportsSelectedFavoriteSettingSelector,
  chainStoreReportsSelectedShuSelector,
  resetCheckedKiListAsNewModelByShuCode,
  selectCheckKiAsNewModelsByShuCode,
} from '../redux/ui/chainStoreReportsSetting';
import { Api, buildConfig } from '../utils/api';
import { disableSis, enableSis, omitSisFields } from '../utils/dkSis';
import { recalcColumnOrder } from '../utils/orderedCell';
import { changeNameDateUnit } from '../utils/reportsDateRange';
import { getShuGroupOption, getShuOption } from '../utils/shu';
import { searchConditionToDateRangeParams } from './chainStoreReportsSettingSaga';
import { handleErrorSaga } from './errorSaga';

/**
 * チェーン店レポート 新台/メイン機種の1つのテーブルのデータを取得する（種別毎に複数回呼ぶ必要あり）
 * @param api AxiosInstance
 * @param action Action
 */
export function* fetchDataChainStoreKiSaga(
  api: Api,
  action: FetchDataChainStoreKiAction
) {
  try {
    yield put(
      DataChainStoreKiActionCreators.fetchDataChainStoreKiRequestAction(
        action.payload.shuCode
      )
    );

    const response: AxiosResponse<DataChainStoreKi> = yield call(
      api.get,
      '/data/chainStore/ki',
      buildConfig(action.payload.params)
    );

    // 既にデータがある場合は上書きして登録、なければそのまま登録
    yield put(
      DataChainStoreKiActionCreators.fetchDataChainStoreKiSuccessAction(
        action.payload.shuCode,
        response.data
      )
    );
  } catch (error: unknown) {
    yield put(DataChainStoreKiActionCreators.renewDataChainStoreKi());
    yield fork(handleErrorSaga, error);
  }
}

/**
 * 新台があるときグラフを自動で開く
 */
function* automaticallyOpenGraphSaga(
  action: FetchDataChainStoreKiSuccessAction
) {
  const {
    shuCode,
    shu: { data },
  } = action.payload;
  if (!shuCode || !data) {
    return;
  }

  const shuOptions: ShuOption[] = getShuGroupOption(
    yield select(settingsOptionsChainStoreSearchConditionSelector)
  );
  const shuGroup = shuOptions.find((x) => x.code === shuCode);
  if (!shuGroup) {
    return;
  }

  const isGraphOpened: boolean = yield select(
    dataChainStoreKiGraphSelector(shuGroup)
  );

  const isGraphClosedByUser: boolean = yield select(
    chainReportsIsKiGraphClosedSelector,
    shuGroup.code
  );

  const checkedByUserModelCodes: string[] = yield select(
    chainReportsCheckedByUserModelCodeslSelector,
    shuGroup.code
  );

  const selectedModelsOnGraph: string[] = yield select(
    dataChainStoreKiGraphSelectedKiListSelector(shuGroup)
  );

  const { command, checkedModelCodes } = shouldAutomaticallyOpenGraph(
    isGraphClosedByUser,
    isGraphOpened,
    checkedByUserModelCodes,
    data.rows
  );

  switch (command) {
    case GraphCommand.DO_NOTHING: {
      return;
    }
    case GraphCommand.OPEN_GRAPH_BY_USER: {
      // グラフ表示
      yield put(
        DataChainStoreKiGraphActionCreators.searchDataChainStoreKiGraph(
          shuGroup,
          checkedModelCodes
        )
      );
      return;
    }
    case GraphCommand.OPEN_GRAPH_AS_NEW: {
      // 新台にチェック
      yield put(
        selectCheckKiAsNewModelsByShuCode(shuGroup.code, checkedModelCodes)
      );
      // グラフ表示
      yield put(
        DataChainStoreKiGraphActionCreators.searchDataChainStoreKiGraph(
          shuGroup,
          checkedModelCodes
        )
      );
      if (!selectedModelsOnGraph || selectedModelsOnGraph.length === 0) {
        // 全ての新台を選択状態にする
        yield put(
          DataChainStoreKiGraphActionCreators.changeDataChainStoreKiGraphKi(
            shuGroup.code,
            checkedModelCodes
          )
        );
      }
      return;
    }
    case GraphCommand.RESET_NEW_MODELS_AND_CLOSE_GRAPH: {
      // グラフを閉じる
      yield put(hideDataChainStoreKiGraphAction(shuGroup.code));
      // 新台のチェックを外す
      yield put(resetCheckedKiListAsNewModelByShuCode(shuGroup.code));
      return;
    }
  }
}

/**
 * 表示項目が変更されている場合並び替えに反映する
 * @param shuCode 種別コード
 */
function* columnsOrderCheckSaga(action: FetchDataChainStoreKiSuccessAction) {
  const { shuCode } = action.payload;

  const fields: Column[] = yield select(
    dataChainStoreKiDataColumnsSelector(shuCode)
  );

  // テーブル列の並び順
  let columnsOrder: string[] = yield select(
    dataChainStoreKiColumnsOrderSelector(shuCode)
  );

  if (!columnsOrder || columnsOrder.length === 0) {
    // ソート順が設定されていない場合、初期配置をデフォルトで設定する
    columnsOrder = fields.map((column) => column.code);
  }

  // MEMO: DK-SIS 項目の存在チェック
  const existSis = columnsOrder.join('_').indexOf('_sis');

  const sorted = [...fields]
    .sort((a, b) => {
      // 区分/機種名/タイプは常に先頭
      if (b.code === 'kbn' || b.code === 'kiTushoMei' || b.code === 'type') {
        return 1;
      }
      // DK-SIS項目を新規で追加する場合は強制で末尾に配置する
      if (existSis === -1 && (b.isSisField || a.isSisField)) {
        return 1;
      }
      return columnsOrder.indexOf(b.code) > columnsOrder.indexOf(a.code)
        ? -1
        : 1;
    })
    .map((column) => column.code);

  yield put(
    DataChainStoreKiActionCreators.selectDataChainStoreKiColumnsOrderAction(
      shuCode,
      sorted
    )
  );
}

/**
 * 初回取得の処理（初回取得・リセット時共通）
 */
function* firstFetchDataChainStoreKiSaga(shuOption: ShuOption) {
  const searchFormCondition: ChainReportsFormConditions = yield select(
    chainStoreReportsSearchConditionSelector
  );
  const fieldCodes: string[] = yield select(
    settingsOptionsChainStoreKiFieldCodesSelector
  );
  const chainStoreReportsFavorite: ChainStoreReportsFavorite = yield select(
    chainStoreReportsSelectedFavoriteSettingSelector
  );

  // ymdList対応以前に作成したお気に入りを考慮し、不要なパラメータを除外する
  const {
    startDate,
    endDate,
    startComparisonDate,
    endComparisonDate,
    ...rest
  } =
    chainStoreReportsFavorite?.dataChainStoreKi?.setting[shuOption.code] ?? {};
  // お気に入りがあればお気に入りの検索条件を適用し、なければ検索フォームの検索条件を適用する
  // MEMO: 新台/メイン機種テーブルのお気に入り適用はIntersectionObserverでIntersectingしたときに取得する仕様のため、ここでお気に入り適用している
  const searchCondition: DataChainStoreKiParams = !chainStoreReportsFavorite
    ?.dataChainStoreKi?.setting[shuOption.code]
    ? {
        ...searchFormCondition,
        // 初回取得時はデフォルトでDK-SISを有効にするため、sisFieldTypesのみ設定
        // 他のsis系パラメータは後続の処理で追加される
        sisFieldTypes: ['result'],
      }
    : {
        ...rest,
        // MEMO:
        // お気に入りでfieldsにsis系の値を含む場合があるのですべて除外する
        fields: omitSisFields(
          chainStoreReportsFavorite.dataChainStoreKi.setting[shuOption.code]
            .fields,
          fieldCodes
        ),
        // 期間を再計算
        ...searchConditionToDateRangeParams(
          chainStoreReportsFavorite.selectedDateRangeParams.dateRange,
          // ボタン名称が古いお気に入りを変換
          changeNameDateUnit(
            chainStoreReportsFavorite.selectedDateRangeParams.dateUnit
          ),
          chainStoreReportsFavorite.selectedDateRangeParams.isComparison,
          chainStoreReportsFavorite.dataChainStoreKi.setting[shuOption.code],
          chainStoreReportsFavorite.selectedComparativeSection
        ),
      };

  // 現在の期間
  const formDateRangeParams: ChainStoreReportsSettingDateRangeParams = yield select(
    chainStoreReportsSelectedDateRangeParamsSelector
  );
  const dateRangeParams = !chainStoreReportsFavorite?.dataChainStoreKi?.setting[
    shuOption.code
  ]
    ? formDateRangeParams
    : chainStoreReportsFavorite.selectedDateRangeParams;

  yield put(
    DataChainStoreKiActionCreators.fetchDataChainStoreKiAction(shuOption.code, {
      ...searchCondition,
      shuGroupIds: shuOption.type === 'shuGroup' ? [shuOption.code] : [],
      shuList: shuOption.type === 'shuList' ? [shuOption.code] : [],
      ...(searchCondition.sisFieldTypes != null
        ? enableSis(searchCondition.ymdList, dateRangeParams.dateUnit)
        : disableSis(searchCondition.fields, fieldCodes)),
    })
  );
}

/**
 * 現在の検索フォームのデータで初回取得する
 * @param action Action
 */
function* initDataChainStoreKiSaga(action: InitDataChainStoreKiAction) {
  const shuOption = action.payload.shu;

  // ローディング状態
  const isLoading: boolean = yield select(
    dataChainStoreKiDataIsLoadingSelector(shuOption.code)
  );

  // データが存在する
  const isExist: boolean = yield select(
    dataChainStoreKiDataIsExistSelector(shuOption.code)
  );

  // データが存在する・ローディング中の時は初回取得を行わない
  if (isExist || isLoading) return;

  // 初回取得
  yield fork(firstFetchDataChainStoreKiSaga, shuOption);
}

/**
 * チェーン店レポート 新台/メイン機種 検索ボタン押下時に行う処理
 * @param action Action
 */
function* searchDataChainStoreKiSaga(action: SearchDataChainStoreKiAction) {
  // 展開行をすべて閉じる
  yield put(
    DataChainStoreKi2ndRowActionCreators.hideAllDataChainStoreKi2ndRowAction()
  );

  // 現在のテーブルデータ全て取得する
  const dataChainStoreKi: DataChainStoreKiState['shus'] = yield select(
    dataChainStoreKiDataSelector
  );

  // 検索フォームで選択中の種別を取得する
  const selectedShu: ShuOption[] = yield select(
    chainStoreReportsSelectedShuSelector
  );

  // 現在の期間
  const dateRangeParams: ChainStoreReportsSettingDateRangeParams = yield select(
    chainStoreReportsSelectedDateRangeParamsSelector
  );

  const fieldCodes: string[] = yield select(
    settingsOptionsChainStoreKiFieldCodesSelector
  );

  // データがあるテーブルのみ検索条件を更新する
  // MEMO: 前回のテーブル固有の検索条件（ソート・表示項目）を引き継ぐためこのようにしている
  // リセットと同じ挙動をすると検索条件を引き継げないため
  const fetchList = Object.keys(dataChainStoreKi)
    .filter((key) => !!dataChainStoreKi[key])
    .map((key) => {
      // 全選択の場合または選択している種別に含まれる場合はデータを再取得する
      const data = dataChainStoreKi[key];
      if (
        (!selectedShu.length || selectedShu.some((shu) => shu.code === key)) &&
        data
      ) {
        return put(
          DataChainStoreKiActionCreators.fetchDataChainStoreKiAction(key, {
            ...data.setting,
            fields: omitSisFields(data.setting.fields, fieldCodes),
            // DK-SISの指定がある場合は再計算した期間を適用する
            ...(data.setting.sisFieldTypes != null
              ? enableSis(
                  action.payload.params.ymdList,
                  dateRangeParams.dateUnit
                )
              : disableSis(action.payload.params.fields, fieldCodes)),
            halls: action.payload.params.halls,
            ymdList: action.payload.params.ymdList,
            ymdComparisonList: action.payload.params.ymdComparisonList,
            excludeToday: action.payload.params.excludeToday,
          })
        );
      }

      // 検索フォーム変更後に種別が含まれない場合はデータを削除する
      return put(
        DataChainStoreKiActionCreators.clearDataChainStoreKiAction(key)
      );
    });

  /**
   * 400エラーが起こったときにdataChainStoreKiの中身が空になるので、
   * 検索ボタンを押しても、fetchListも空になり、yield all(fetchList); で何もリクエストが発行されない。
   * そのためテーブルデータが取得されなくなる問題があった。
   * https://app.zenhub.com/workspaces/claris-60b56db504e3ff00150ebc22/issues/gh/dkclaris/claris-general/3090
   *
   * この問題を解消するため、fetchListが空の場合に、初期化処理と同様の全種別のテーブルデータをリクエストするように処理を追加。
   */
  if (fetchList.length === 0) {
    // 検索条件項目を取得
    const settingsOptions: ChainStoreSearchCondition = yield select(
      settingsOptionsChainStoreSearchConditionSelector
    );
    // ShuOptionsを取得
    const shuOptions = getShuOption(settingsOptions);
    // 初回条件で表示中のテーブルを再取得
    const forkEffects = shuOptions
      .filter(({ code }) =>
        !selectedShu.length
          ? true
          : selectedShu.some((shu) => shu.code === code)
      )
      .map((shuOption) => {
        return fork(firstFetchDataChainStoreKiSaga, shuOption);
      });
    yield all(forkEffects);
    return;
  }

  // データ取得
  yield all(fetchList);
}

/**
 * チェーン店レポート 新台/メイン機種 リセットボタン押下時に行う処理
 */
function* searchDataChainStoreKiResetSaga() {
  // 展開行をすべて閉じる
  yield put(
    DataChainStoreKi2ndRowActionCreators.hideAllDataChainStoreKi2ndRowAction()
  );

  // 検索条件項目を取得
  const settingsOptions: ChainStoreSearchCondition = yield select(
    settingsOptionsChainStoreSearchConditionSelector
  );

  // 検索フォームで選択中の種別を取得する
  const selectedShu: ShuOption[] = yield select(
    chainStoreReportsSelectedShuSelector
  );

  // 現在のテーブルデータ全て取得する
  const dataChainStoreKi: DataChainStoreKiState['shus'] = yield select(
    dataChainStoreKiDataSelector
  );

  // ShuOptionsを取得
  const shuOptions = getShuOption(settingsOptions);

  // Fetch対象のShuOption
  const reFetchList = shuOptions.filter((shuOption) =>
    Object.keys(dataChainStoreKi)
      .filter((key) => dataChainStoreKi[key] !== undefined) // 明示的に破棄しているテーブルは除外
      .includes(shuOption.code)
  );

  // 初回条件で表示中のテーブルを再取得
  const forkEffects = reFetchList.map((shuOption) => {
    // 選択された種別または全選択の場合初回取得を行う
    if (
      !selectedShu.length ||
      selectedShu.some((shu) => shu.code === shuOption.code)
    ) {
      return fork(firstFetchDataChainStoreKiSaga, shuOption);
    }

    // 選択された種別に該当しない種別のテーブルデータを削除
    return put(
      DataChainStoreKiActionCreators.clearDataChainStoreKiAction(shuOption.code)
    );
  });

  /**
   * 400エラーが起こったときにdataChainStoreKiの中身が空になるので、
   * リセットボタンを押しても、reFetchListも空になり、yield all(forkEffects); で何もリクエストが発行されない。
   * そのためテーブルデータが取得されなくなる問題があった。
   * https://github.com/DKClaris/claris-general/issues/3173
   *
   * この問題を解消するため、reFetchListが空の場合に、初期化処理と同様の全種別のテーブルデータをリクエストするように処理を追加。
   */
  if (reFetchList.length === 0) {
    // 初回条件で表示中のテーブルを再取得
    const forkEffects = shuOptions
      .filter(({ code }) =>
        !selectedShu.length
          ? true
          : selectedShu.some((shu) => shu.code === code)
      )
      .map((shuOption) => {
        return fork(firstFetchDataChainStoreKiSaga, shuOption);
      });
    yield all(forkEffects);
    return;
  }

  yield all(forkEffects);
}

/**
 * チェーン店レポート 新台/メイン機種 お気に入り用
 */
function* searchDataChainStoreKiFavoriteSaga(
  action: SearchDataChainStoreKiFavoriteAction
) {
  // 展開行をすべて閉じる
  yield put(
    DataChainStoreKi2ndRowActionCreators.hideAllDataChainStoreKi2ndRowAction()
  );

  // 検索条件項目を取得
  const settingsOptions: ChainStoreSearchCondition = yield select(
    settingsOptionsChainStoreSearchConditionSelector
  );

  // 検索フォームで選択中の種別を取得する
  const selectedShu: ShuOption[] = yield select(
    chainStoreReportsSelectedShuSelector
  );

  // ShuOptionsを取得
  const shuOptions =
    selectedShu.length > 0 ? selectedShu : getShuOption(settingsOptions);

  // テーブルを取得
  const forkEffects = shuOptions.map((shuOption) => {
    return fork(
      favoriteFetchDataChainStoreKiSaga,
      shuOption,
      action.payload.favorite
    );
  });

  yield all(forkEffects);
}

/**
 * お気に入りでのデータ取得の処理
 */
function* favoriteFetchDataChainStoreKiSaga(
  shuOption: ShuOption,
  favorite: ChainStoreReportsFavorite
) {
  const searchFormCondition: ChainReportsFormConditions = yield select(
    chainStoreReportsSearchConditionSelector
  );

  const fieldCodes: string[] = yield select(
    settingsOptionsChainStoreKiFieldCodesSelector
  );

  // ymdList対応以前に作成したお気に入りを考慮し、不要なパラメータを除外する
  const {
    startDate,
    endDate,
    startComparisonDate,
    endComparisonDate,
    ...rest
  } = favorite?.dataChainStoreKi?.setting[shuOption.code] ?? {};
  // お気に入りがあればお気に入りの検索条件を適用し、なければ検索フォームの検索条件を適用する
  // MEMO: 新台/メイン機種テーブルのお気に入り適用はIntersectionObserverでIntersectingしたときに取得する仕様のため、ここでお気に入り適用している
  const searchCondition: DataChainStoreKiParams = !favorite?.dataChainStoreKi
    ?.setting[shuOption.code]
    ? {
        ...searchFormCondition,
        // 初回取得時はデフォルトでDK-SISを有効にするため、sisFieldTypesのみ設定
        // 他のsis系パラメータは後続の処理で追加される
        sisFieldTypes: ['result'],
      }
    : {
        ...rest,
        // MEMO:
        // お気に入りでfieldsにsis系の値を含む場合があるのですべて除外する
        fields: omitSisFields(
          favorite.dataChainStoreKi.setting[shuOption.code].fields,
          fieldCodes
        ),
        // 期間を再計算
        ...searchConditionToDateRangeParams(
          favorite.selectedDateRangeParams.dateRange,
          // ボタン名称が古いお気に入りを変換
          changeNameDateUnit(favorite.selectedDateRangeParams.dateUnit),
          favorite.selectedDateRangeParams.isComparison,
          favorite.dataChainStoreKi.setting[shuOption.code],
          favorite.selectedComparativeSection
        ),
      };

  // 現在の期間
  const formDateRangeParams: ChainStoreReportsSettingDateRangeParams = yield select(
    chainStoreReportsSelectedDateRangeParamsSelector
  );
  const dateRangeParams = !favorite?.dataChainStoreKi?.setting[shuOption.code]
    ? formDateRangeParams
    : favorite.selectedDateRangeParams;

  yield put(
    DataChainStoreKiActionCreators.fetchDataChainStoreKiAction(shuOption.code, {
      ...searchCondition,
      shuGroupIds: shuOption.type === 'shuGroup' ? [shuOption.code] : [],
      shuList: shuOption.type === 'shuList' ? [shuOption.code] : [],
      ...(searchCondition.sisFieldTypes != null
        ? enableSis(searchCondition.ymdList, dateRangeParams.dateUnit)
        : disableSis(searchCondition.fields, fieldCodes)),
    })
  );
}

/**
 * 指定した表示項目の新台/メイン機種テーブルデータを取得する
 * @param action Action
 */
function* searchDataChainStoreKiFieldTypeSaga(
  action: SearchDataChainStoreKiFieldTypeAction
) {
  const shuCode = action.payload.shu.code;
  // 現在の検索条件
  const searchCondition: DataChainStoreKiParams = yield select(
    dataChainStoreKiDataSearchConditionSelector(shuCode)
  );

  // 展開行を閉じる
  yield put(
    DataChainStoreKi2ndRowActionCreators.hideDataChainStoreKi2ndRowByShuCodeAction(
      shuCode
    )
  );

  // 新台/メイン機種テーブルを再取得
  yield put(
    DataChainStoreKiActionCreators.fetchDataChainStoreKiAction(
      action.payload.shu.code,
      {
        ...searchCondition,
        fields: action.payload.fields.map((field) => field.code),
      }
    )
  );
}

/**
 * 指定したソート条件の新台/メイン機種テーブルデータを取得する
 * @param action Action
 */
function* searchDataChainStoreKiSortSaga(
  action: SearchDataChainStoreKiSortAction
) {
  // 現在の検索条件
  const searchCondition: DataChainStoreKiParams = yield select(
    dataChainStoreKiDataSearchConditionSelector(action.payload.shu.code)
  );

  const fieldCodes: string[] = yield select(
    settingsOptionsChainStoreKiFieldCodesSelector
  );

  // 新台/メイン機種テーブルを再取得
  yield put(
    DataChainStoreKiActionCreators.fetchDataChainStoreKiAction(
      action.payload.shu.code,
      {
        ...searchCondition,
        fields: omitSisFields(searchCondition.fields, fieldCodes),
        sort: action.payload.sort,
        order: action.payload.order,
      }
    )
  );
}

/**
 * ドラッグ＆ドロップしたセルのIDを元に並び替え情報を登録する
 */
function* changeDataChainStoreKiColumnsOrderSaga(
  action: ChangeDataChainStoreKiColumnsOrderAction
) {
  const tableData: DataChainStoreKi['data'] = yield select(
    dataChainStoreKiOrderedData(action.payload.shuCode)
  );

  const ordered = recalcColumnOrder(
    tableData.columns,
    action.payload.draggedId,
    action.payload.droppedId
  );

  yield put(
    DataChainStoreKiActionCreators.selectDataChainStoreKiColumnsOrderAction(
      action.payload.shuCode,
      ordered
    )
  );
}

/**
 * DK-SISを有効にしてデータを取得する
 */
function* searchDataChainStoreKiDkSisOnSaga(
  action: SearchDataChainStoreKiDkSisOnAction
) {
  const { shu } = action.payload;
  const shuCode = shu.code;

  // 展開行を閉じる
  yield put(
    DataChainStoreKi2ndRowActionCreators.hideDataChainStoreKi2ndRowByShuCodeAction(
      shuCode
    )
  );

  // 現在の検索条件
  const searchCondition: DataChainStoreKiParams = yield select(
    dataChainStoreKiDataSearchConditionSelector(shuCode)
  );
  const dateRangeParams: ChainStoreReportsSettingDateRangeParams = yield select(
    chainStoreReportsSelectedDateRangeParamsSelector
  );

  yield put(
    DataChainStoreKiActionCreators.fetchDataChainStoreKiAction(shuCode, {
      ...searchCondition,
      ...enableSis(searchCondition.ymdList, dateRangeParams.dateUnit),
    })
  );
}

/**
 * DK-SISを無効にしてデータを取得する
 */
function* searchDataChainStoreKiDkSisOffSaga(
  action: SearchDataChainStoreKiDkSisOffAction
) {
  const { shu } = action.payload;
  const shuCode = shu.code;

  // 展開行を閉じる
  yield put(
    DataChainStoreKi2ndRowActionCreators.hideDataChainStoreKi2ndRowByShuCodeAction(
      shuCode
    )
  );

  // 現在の検索条件
  const searchCondition: DataChainStoreKiParams = yield select(
    dataChainStoreKiDataSearchConditionSelector(shuCode)
  );

  const fieldCodes: string[] = yield select(
    settingsOptionsChainStoreKiFieldCodesSelector
  );
  yield put(
    DataChainStoreKiActionCreators.fetchDataChainStoreKiAction(shuCode, {
      ...searchCondition,
      ...disableSis(searchCondition.fields, fieldCodes),
    })
  );
}

/**
 * フォームで指定したDK-SISの検索条件でデータを取得する
 */
function* searchDataChainStoreKiDkSisSaga(
  action: SearchDataChainStoreKiDkSisAction
) {
  const { shu, params } = action.payload;
  const shuCode = shu.code;

  // 展開行を閉じる
  yield put(
    DataChainStoreKi2ndRowActionCreators.hideDataChainStoreKi2ndRowByShuCodeAction(
      shuCode
    )
  );

  // DK-SISの検索条件でデータを取得する
  // 現在の検索条件
  const searchCondition: DataChainStoreKiParams = yield select(
    dataChainStoreKiDataSearchConditionSelector(shuCode)
  );

  // 全てのDK-SISの検索条件を取り除く
  yield put(
    DataChainStoreKiActionCreators.fetchDataChainStoreKiAction(shuCode, {
      ...searchCondition,
      ...params,
    })
  );
}

/**
 * FETCH_DATA_CHAIN_STORE_KIがDispatchされた時にfetchDataChainStoreKiSagaを実行する
 * @param api AxiosInstance
 */
function* handleFetchDataChainStoreKiSaga(api: Api) {
  yield takeEvery(
    DataChainStoreKiActionTypes.FETCH_DATA_CHAIN_STORE_KI,
    fetchDataChainStoreKiSaga,
    api
  );
  // 表示項目と並び替えのチェック
  yield takeEvery(
    DataChainStoreKiActionTypes.FETCH_DATA_CHAIN_STORE_KI_SUCCESS,
    columnsOrderCheckSaga
  );
}

/**
 * 検索関連のSaga
 */
function* handleSearchSaga() {
  // 初回取得
  yield takeEvery(
    DataChainStoreKiActionTypes.INIT_DATA_CHAIN_STORE_KI,
    initDataChainStoreKiSaga
  );
  // 検索時
  yield takeEvery(
    DataChainStoreKiActionTypes.SEARCH_DATA_CHAIN_STORE_KI,
    searchDataChainStoreKiSaga
  );
  // リセット時
  yield takeEvery(
    DataChainStoreKiActionTypes.SEARCH_DATA_CHAIN_STORE_KI_RESET,
    searchDataChainStoreKiResetSaga
  );

  // お気に入り時
  yield takeEvery(
    DataChainStoreKiActionTypes.SEARCH_DATA_CHAIN_STORE_KI_FAVORITE,
    searchDataChainStoreKiFavoriteSaga
  );

  // 表示項目
  yield takeEvery(
    DataChainStoreKiActionTypes.SEARCH_DATA_CHAIN_STORE_KI_FIELD_TYPE,
    searchDataChainStoreKiFieldTypeSaga
  );
  // ソート
  yield takeEvery(
    DataChainStoreKiActionTypes.SEARCH_DATA_CHAIN_STORE_KI_SORT,
    searchDataChainStoreKiSortSaga
  );
  // 並び替え
  yield takeEvery(
    DataChainStoreKiActionTypes.CHANGE_DATA_CHAIN_STORE_KI_COLUMNS_ORDER,
    changeDataChainStoreKiColumnsOrderSaga
  );
  // DK-SISを有効
  yield takeEvery(
    DataChainStoreKiActionTypes.SEARCH_DATA_CHAIN_STORE_KI_DKSIS_ON,
    searchDataChainStoreKiDkSisOnSaga
  );
  // DK-SISを無効
  yield takeEvery(
    DataChainStoreKiActionTypes.SEARCH_DATA_CHAIN_STORE_KI_DKSIS_OFF,
    searchDataChainStoreKiDkSisOffSaga
  );
  // DK-SISの検索条件を変更
  yield takeEvery(
    DataChainStoreKiActionTypes.SEARCH_DATA_CHAIN_STORE_KI_DKSIS,
    searchDataChainStoreKiDkSisSaga
  );

  // グラフデータを自動で取得
  yield takeEvery(
    DataChainStoreKiActionTypes.FETCH_DATA_CHAIN_STORE_KI_SUCCESS,
    automaticallyOpenGraphSaga
  );
}

/**
 * チェーン店レポート 新台/メイン機種に関するタスクを実行する
 * @param context AxiosInstance
 */
export function* dataChainStoreKiSagas(context: { api: Api }) {
  yield fork(handleFetchDataChainStoreKiSaga, context.api);
  yield fork(handleSearchSaga);
}
