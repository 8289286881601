import { AxiosResponse } from 'axios';
import { format } from 'date-fns';
import { call, fork, put, select, takeEvery } from 'redux-saga/effects';

import {
  ComparisonGraphParams,
  ComparisonGraphResponse,
} from '../domain/comparisonGraph';
import { DataChainStoreKiParams } from '../domain/dataChainStoreKi';
import { GRAPH_DATE_TYPE, GraphDateType } from '../domain/dataKiGraph';

import { dataChainStoreKiDataSearchConditionSelector } from '../redux/server/dataChainStoreKi';
import {
  ChangeDataChainStoreKiGraphDateType,
  ChangeDataChainStoreKiGraphField,
  DataChainStoreKiGraphActionCreators,
  DataChainStoreKiGraphActionTypes,
  FetchDataChainStoreKiGraphAction,
  SearchDataChainStoreKiGraph,
  dataChainStoreKiGraphSearchConditionSelector,
} from '../redux/server/dataChainStoreKiGraph';
import { Api, buildConfig } from '../utils/api';
import {
  calcEndDateWithExcludeToday,
  calcStartDateFromDateType,
} from '../utils/date';
import { handleErrorSaga } from './errorSaga';

/**
 * 検索条件を元に推移グラフ（機種比較）のデータを取得する
 * @param api AxiosInstance
 * @param action Action
 */
export function* fetchDataChainStoreKiGraphSaga(
  api: Api,
  action: FetchDataChainStoreKiGraphAction
) {
  const { shuCode, params } = action.payload;

  try {
    yield put(
      DataChainStoreKiGraphActionCreators.fetchDataChainStoreKiGraphRequestAction(
        shuCode
      )
    );
    const response: AxiosResponse<ComparisonGraphResponse> = yield call(
      api.get,
      '/data/chainStore/ki/graph',
      buildConfig(params)
    );

    yield put(
      DataChainStoreKiGraphActionCreators.fetchDataChainStoreKiGraphSuccessAction(
        shuCode,
        response.data
      )
    );
  } catch (error: unknown) {
    yield put(
      DataChainStoreKiGraphActionCreators.renewDataChainStoreKiGraphAction()
    );
    yield fork(handleErrorSaga, error);
  }
}

/**
 * チェックボックスで選択した機種の推移グラフ（機種比較）データを取得する（推移グラフボタン押下時）
 * @param action Action
 */
function* searchDataChainStoreKiGraphSaga(action: SearchDataChainStoreKiGraph) {
  const { shu, kiList } = action.payload;

  // 前回の検索条件から引き継ぐもの
  const prevParams: ComparisonGraphParams = yield select(
    dataChainStoreKiGraphSearchConditionSelector(shu)
  );
  const { field, dateType } = prevParams;

  // テーブルの検索条件
  const searchCondition: DataChainStoreKiParams = yield select(
    dataChainStoreKiDataSearchConditionSelector(shu.code)
  );

  // グラフ用に期間を算出する
  const endDate = calcEndDateWithExcludeToday(
    !!searchCondition.excludeToday,
    searchCondition.ymdList && searchCondition.ymdList.slice(-1)[0]
  );
  const startDate = calcStartDateFromDateType(
    dateType ?? GRAPH_DATE_TYPE.DAILY,
    endDate
  );

  // グラフデータ取得
  yield put(
    DataChainStoreKiGraphActionCreators.fetchDataChainStoreKiGraphAction(
      shu.code,
      {
        kiList,
        shuList: searchCondition.shuList,
        shuGroupIds: searchCondition.shuGroupIds,
        halls: searchCondition.halls,
        excludeToday: searchCondition.excludeToday,
        startDate: format(startDate, 'yyyy-MM-dd'),
        endDate: format(endDate, 'yyyy-MM-dd'),
        ...(dateType ? { dateType } : {}),
        ...(field ? { field } : {}),
      }
    )
  );
}

/**
 * 指定したグラフ項目の推移グラフ（機種比較）を取得する
 * @param action Action
 */
function* changeDataChainStoreKiGraphFieldSaga(
  action: ChangeDataChainStoreKiGraphField
) {
  const { shu, field } = action.payload;

  // 推移グラフ（機種比較）の検索条件
  const searchCondition: ComparisonGraphParams = yield select(
    dataChainStoreKiGraphSearchConditionSelector(shu)
  );

  // 推移グラフ（機種比較）データを再取得
  yield put(
    DataChainStoreKiGraphActionCreators.fetchDataChainStoreKiGraphAction(
      shu.code,
      {
        ...searchCondition,
        field,
      }
    )
  );
}

/**
 * 指定した日付タイプの推移グラフ（機種比較）を取得する
 * @param action Action
 */
function* changeDataChainStoreKiGraphDateTypeSaga(
  action: ChangeDataChainStoreKiGraphDateType
) {
  const { shu, dateType } = action.payload;

  // 推移グラフ（機種比較）の検索条件
  const searchCondition: ComparisonGraphParams = yield select(
    dataChainStoreKiGraphSearchConditionSelector(shu)
  );

  // グラフ用に期間を算出する
  const endDate = calcEndDateWithExcludeToday(
    !!searchCondition.excludeToday,
    searchCondition.endDate
  );
  const startDate = calcStartDateFromDateType(
    dateType as GraphDateType,
    endDate
  );

  // 推移グラフ（機種比較）データを再取得
  yield put(
    DataChainStoreKiGraphActionCreators.fetchDataChainStoreKiGraphAction(
      shu.code,
      {
        ...searchCondition,
        startDate: format(startDate, 'yyyy-MM-dd'),
        endDate: format(endDate, 'yyyy-MM-dd'),
        dateType: dateType as GraphDateType,
      }
    )
  );
}

function* handleFetchDataChainStoreKiGraphSaga(api: Api) {
  yield takeEvery(
    DataChainStoreKiGraphActionTypes.FETCH_DATA_CHAIN_STORE_KI_GRAPH,
    fetchDataChainStoreKiGraphSaga,
    api
  );
}

/**
 * 検索系のActionをDispatchされるのを監視
 */
function* handleSearchDataChainStoreKiGraphSaga() {
  // 初回取得
  yield takeEvery(
    DataChainStoreKiGraphActionTypes.SEARCH_DATA_CHAIN_STORE_KI_GRAPH,
    searchDataChainStoreKiGraphSaga
  );
  // グラフ項目変更時
  yield takeEvery(
    DataChainStoreKiGraphActionTypes.CHANGE_DATA_CHAIN_STORE_KI_GRAPH_FIELD,
    changeDataChainStoreKiGraphFieldSaga
  );
  // 日付タイプ変更時
  yield takeEvery(
    DataChainStoreKiGraphActionTypes.CHANGE_DATA_CHAIN_STORE_KI_GRAPH_DATE_TYPE,
    changeDataChainStoreKiGraphDateTypeSaga
  );
}

export function* dataChainStoreKiGraphSaga(context: { api: Api }) {
  yield fork(handleFetchDataChainStoreKiGraphSaga, context.api);
  yield fork(handleSearchDataChainStoreKiGraphSaga);
}
