import { createSelector } from 'reselect';

import { daiCostsFields } from '../../domain/daiCosts/consistent';
import { DaiCostsField } from '../../domain/daiCosts/types';

/* ---------------------------------------------------------------
 * Action Types
 */

const SELECT_SETTINGS_DAI_COSTS_FIELDS = 'SELECT_SETTINGS_DAI_COSTS_FIELDS' as const;

/* ---------------------------------------------------------------
 * Action Creators
 */

function selectSettingsDaiCostsFieldsAction(selectedFields: DaiCostsField[]) {
  return {
    type: SELECT_SETTINGS_DAI_COSTS_FIELDS,
    payload: { selectedFields },
  };
}

export const SettingsDaiCostsActionCreators = {
  selectSettingsDaiCostsFieldsAction,
};

/* ---------------------------------------------------------------
 * Actions
 */

type SettingsDaiCostsAction = ReturnType<
  typeof selectSettingsDaiCostsFieldsAction
>;

/* ---------------------------------------------------------------
 * State
 */

type SettingsDaiCostsState = {
  selectedFields: DaiCostsField[];
};

const initialState: SettingsDaiCostsState = {
  selectedFields: daiCostsFields.map(({ code }) => code),
};

/* ---------------------------------------------------------------
 * Selector
 */

function settingsDaiCostsSelector(rootState: {
  settingsDaiCosts: SettingsDaiCostsState;
}) {
  return rootState.settingsDaiCosts;
}

/**
 * 一覧画面で選択した検索期間の取得
 */
export const settingsDaiCostsSelectedFieldsSelector = createSelector(
  settingsDaiCostsSelector,
  ({ selectedFields }) => selectedFields
);

/* ---------------------------------------------------------------
 * Reducer
 */

export function settingsDaiCostsReducer(
  state = initialState,
  action: SettingsDaiCostsAction
): SettingsDaiCostsState {
  switch (action.type) {
    case SELECT_SETTINGS_DAI_COSTS_FIELDS: {
      return {
        ...state,
        selectedFields: action.payload.selectedFields,
      };
    }
    default:
      return state;
  }
}
