import { AxiosResponse } from 'axios';
import { call, fork, put, takeEvery } from 'redux-saga/effects';

import { SettingsHalls } from '../domain/settingsHalls';

import {
  PatchSettingsAreaAction,
  PatchSettingsHallsRequestAction,
  PutSettingsHallsAction,
  SettingsHallsActionCreators,
  SettingsHallsActionTypes,
} from '../redux/server/settingsHalls';
import { Api, buildConfig } from '../utils/api';
import { handleErrorSaga } from './errorSaga';

export function* fetchSettingsHallsSaga(api: Api) {
  try {
    yield put(SettingsHallsActionCreators.fetchSettingsHallsRequestAction());
    const response: AxiosResponse<SettingsHalls> = yield call(
      api.get,
      `/settings/halls`,
      buildConfig()
    );
    const settingsHalls = response.data;
    yield put(
      SettingsHallsActionCreators.fetchSettingsHallsSuccessAction(settingsHalls)
    );
  } catch (error: unknown) {
    yield put(SettingsHallsActionCreators.renewSettingsHallsAction());
    yield fork(handleErrorSaga, error);
  }
}

export function* putSettingsHallsSaga(
  api: Api,
  action: PutSettingsHallsAction
) {
  try {
    const { hallCode, ...params } = action.payload.query;

    yield put(SettingsHallsActionCreators.putSettingsHallsRequestAction());
    const response: AxiosResponse<SettingsHalls> = yield call(
      api.put,
      `/settings/halls/${hallCode}`,
      params,
      buildConfig()
    );
    const settingsHalls = response.data;
    yield put(
      SettingsHallsActionCreators.putSettingsHallsSuccessAction(settingsHalls)
    );
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    yield put(SettingsHallsActionCreators.putSettingsHallsFailureAction(error));
    yield fork(handleErrorSaga, error);
  }
}

function* patchSettingsHallsSaga(
  api: Api,
  action: PatchSettingsHallsRequestAction
) {
  try {
    const { hallCode, ...rest } = action.payload;

    const response: AxiosResponse<SettingsHalls> = yield call(
      api.patch,
      `/settings/halls/${hallCode}`,
      { ...rest },
      buildConfig()
    );

    const updatedHall = response.data.halls.find(
      (x) => x.hallCode === hallCode
    );
    if (!updatedHall) {
      throw new Error('更新済みのホール情報が見つかりませんでした');
    }

    yield put(
      SettingsHallsActionCreators.patchSettingsHallsSuccessAction(updatedHall)
    );
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    yield put(
      SettingsHallsActionCreators.patchSettingsHallsFailureAction(error)
    );
    yield fork(handleErrorSaga, error);
  }
}

function* patchSettingsAreaSaga(api: Api, action: PatchSettingsAreaAction) {
  try {
    const { hallCode, ...params } = action.payload;

    yield call(
      api.patch,
      `/settings/halls/${hallCode}`,
      { ...params },
      buildConfig()
    );
    yield put(SettingsHallsActionCreators.fetchSettingsHallsAction());
    yield put(SettingsHallsActionCreators.patchSettingsAreaSuccessAction());
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    yield put(
      SettingsHallsActionCreators.patchSettingsAreaFailureAction(error)
    );
    yield fork(handleErrorSaga, error);
  }
}

function* handleFetchSettingsHallsSaga(api: Api) {
  yield takeEvery(
    SettingsHallsActionTypes.FETCH_SETTINGS_HALLS,
    fetchSettingsHallsSaga,
    api
  );
}

function* handlePutSettingsHallsSaga(api: Api) {
  yield takeEvery(
    SettingsHallsActionTypes.PUT_SETTINGS_HALLS,
    putSettingsHallsSaga,
    api
  );
}

function* handlePatchSettingsHallsSaga(api: Api) {
  yield takeEvery(
    SettingsHallsActionTypes.PATCH_SETTINGS_HALLS_REQUEST,
    patchSettingsHallsSaga,
    api
  );
}

function* handlePatchSettingsAreaSaga(api: Api) {
  yield takeEvery(
    SettingsHallsActionTypes.PATCH_SETTINGS_AREA_REQUEST,
    patchSettingsAreaSaga,
    api
  );
}

export function* settingsHallsSagas(context: { api: Api }) {
  yield fork(handleFetchSettingsHallsSaga, context.api);
  yield fork(handlePutSettingsHallsSaga, context.api);
  yield fork(handlePatchSettingsHallsSaga, context.api);
  yield fork(handlePatchSettingsAreaSaga, context.api);
}
