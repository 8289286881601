import { filterModelName } from '../../utils/filterModelName';
import { DataSis } from './types';

export const dataFilteredByName = (
  dataSis: DataSis,
  name: string | undefined
): DataSis => {
  if (name == null) {
    return dataSis;
  }

  const predicate = filterModelName(name ?? '');

  const rows = dataSis.data.rows.filter(
    (row) => row.data.length > 0 && predicate(row.data.at(0)?.value ?? '')
  );

  return {
    ...dataSis,
    data: {
      ...dataSis.data,
      rows,
    },
  };
};
