import { AxiosResponse } from 'axios';
import { call, fork, put, takeEvery } from 'redux-saga/effects';

import { SettingsPlans } from '../domain/settingsPlans';

import {
  FetchSettingsPlansAction,
  SettingsPlansActionCreators,
  SettingsPlansActionTypes,
} from '../redux/server/settingsPlans';
import { Api, buildConfig } from '../utils/api';
import { handleErrorSaga } from './errorSaga';

export function* fetchSettingsPlansSaga(
  api: Api,
  action: FetchSettingsPlansAction
) {
  const { year, month, ...params } = action.payload.query;
  try {
    yield put(SettingsPlansActionCreators.fetchSettingsPlansRequestAction());
    const response: AxiosResponse<SettingsPlans> = yield call(
      api.get,
      `/settings/plans/${year}/${month}`,
      buildConfig(params)
    );
    const settingsPlans = response.data;
    yield put(
      SettingsPlansActionCreators.fetchSettingsPlansSuccessAction(settingsPlans)
    );
  } catch (error: unknown) {
    yield put(SettingsPlansActionCreators.renewSettingsPlansAction());
    yield fork(handleErrorSaga, error);
  }
}

function* handleFetchSettingsPlansSaga(api: Api) {
  yield takeEvery(
    SettingsPlansActionTypes.FETCH_SETTINGS_PLANS,
    fetchSettingsPlansSaga,
    api
  );
}

export function* settingsPlansSagas(context: { api: Api }) {
  yield fork(handleFetchSettingsPlansSaga, context.api);
}
