import { AxiosResponse } from 'axios';
import { call, fork, put, takeEvery } from 'redux-saga/effects';

import { SettingsOptionsDai } from '../domain/dai/types';

import {
  SettingsOptionsDaiActionCreators,
  SettingsOptionsDaiActionTypes,
} from '../redux/server/settingsOptionsDai';
import { Api, buildConfig } from '../utils/api';
import { handleErrorSaga } from './errorSaga';

export function* fetchSettingsOptionsDaiSaga(api: Api) {
  try {
    yield put(
      SettingsOptionsDaiActionCreators.fetchSettingsOptionsDaiRequestAction()
    );
    const response: AxiosResponse<SettingsOptionsDai> = yield call(
      api.get,
      '/settings/options/dai',
      buildConfig()
    );
    const settingsOptionsDai: SettingsOptionsDai = response.data;
    yield put(
      SettingsOptionsDaiActionCreators.fetchSettingsOptionsDaiSuccessAction(
        settingsOptionsDai
      )
    );
  } catch (error: unknown) {
    yield put(SettingsOptionsDaiActionCreators.renewSettingsOptionsDaiAction());
    yield fork(handleErrorSaga, error);
  }
}

function* handleFetchSettingsOptionsDaiSaga(api: Api) {
  yield takeEvery(
    SettingsOptionsDaiActionTypes.FETCH_SETTINGS_OPTIONS_DAI,
    fetchSettingsOptionsDaiSaga,
    api
  );
}

export function* settingsOptionsDaiSagas(context: { api: Api }) {
  yield fork(handleFetchSettingsOptionsDaiSaga, context.api);
}
