import { polyfill } from 'mobile-drag-drop';
import { scrollBehaviourDragImageTranslateOverride } from 'mobile-drag-drop/scroll-behaviour';
import { FC } from 'react';
import { RouterProvider } from 'react-router-dom';
import 'requestidlecallback-polyfill';

import { router } from './Routes';

polyfill({
  // use this to make use of the scroll behaviour
  dragImageTranslateOverride: scrollBehaviourDragImageTranslateOverride,
});

// DnDのiPhone対応のため必要
// eslint-disable-next-line @typescript-eslint/no-empty-function
window.addEventListener('touchmove', function () {}, { passive: false });

const App: FC = () => {
  return <RouterProvider router={router} />;
};

export default App;
