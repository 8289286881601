import { PlanProgressTransitionDateRange } from './types';

/**
 * 選択中の期間の情報を元に期間指定部分の検索条件を加工する
 */
export const makePlanProgressDateRangeParams = (
  dateRange: PlanProgressTransitionDateRange,
  year: number,
  month: number
) => {
  const now = new Date();
  // 期間指定がカスタムの場合は現在の検索パラメータの値を送る
  if (dateRange === 'カスタム') {
    return {
      year,
      month,
    };
  } else if (dateRange === '今月') {
    return {
      dateRange,
      year: now.getFullYear(),
      month: now.getMonth() + 1,
    };
  } else {
    // 前月:年跨ぎの考慮
    const prevMonthDate = new Date(now.getFullYear(), now.getMonth() - 1);
    return {
      year: prevMonthDate.getFullYear(),
      month: prevMonthDate.getMonth() + 1,
    };
  }
};
