import { AxiosResponse } from 'axios';
import { call, fork, put, takeEvery } from 'redux-saga/effects';

import { SettingsModesDetails } from '../domain/settingsModes';

import {
  DataSettingsModesDetailsActionCreators,
  DataSettingsModesDetailsActionTypes,
  FetchDataSettingsModesDetailsAction,
} from '../redux/server/dataSettingsModesDetails';
import { Api } from '../utils/api';
import { handleErrorSaga } from './errorSaga';

/**
 * モード登録モード確認データを取得する
 * @param api AxiosInstance
 * @param action Action
 */
function* fetchDataSettingsModesDetailsSaga(
  api: Api,
  action: FetchDataSettingsModesDetailsAction
) {
  try {
    yield put(
      DataSettingsModesDetailsActionCreators.fetchDataSettingsModesDetailsRequestAction()
    );
    const date = action.payload.date;
    const hallCode = action.payload.hallCode;
    const response: AxiosResponse<SettingsModesDetails> = yield call(
      api.get,
      `/settings/modes/${date}/${hallCode}`
    );
    yield put(
      DataSettingsModesDetailsActionCreators.fetchDataSettingsModesDetailsSuccessAction(
        response.data
      )
    );
  } catch (error: unknown) {
    yield put(
      DataSettingsModesDetailsActionCreators.renewDataSettingsModesDetailsAction()
    );
    yield fork(handleErrorSaga, error);
  }
}

function* handleFetchDataSettingsModesDetailsSaga(api: Api) {
  yield takeEvery(
    DataSettingsModesDetailsActionTypes.FETCH_DATA_SETTINGS_MODES_DETAILS,
    fetchDataSettingsModesDetailsSaga,
    api
  );
}

export function* dataSettingsModesDetailsSagas(context: { api: Api }) {
  yield fork(handleFetchDataSettingsModesDetailsSaga, context.api);
}
