import { toAscii, toFullwidthKana } from 'japanese-string-utils';

const normalize = (x: string) => toAscii(toFullwidthKana(x)).toLowerCase();

export const filterModelName = (filters: string) => {
  // 全角空白を含める為eslint-disableを使用
  // eslint-disable-next-line no-irregular-whitespace
  const normalizedFilters = filters?.trim().split(/[ |　]/g).map(normalize);
  return (modelName: string): boolean => {
    // 平均/合計行を除外しない
    if (modelName === '平均/合計') {
      return true;
    }
    const normalizedModelName = normalize(modelName);
    return normalizedFilters.every((filter) =>
      normalizedModelName.includes(filter)
    );
  };
};
