import { createSelector } from 'reselect';

import { GRAPH_DATE_TYPE } from '../../domain/dataKiGraph';
import type { GraphDateType } from '../../domain/dataKiGraph';
import { GRAPH_FIELD_TYPES } from '../../domain/dataKiMksTransitiveGraph';
import type {
  DataKiMksTransitiveGraphData,
  GraphFieldType,
} from '../../domain/dataKiMksTransitiveGraph';
import type { Hall } from '../../domain/schemas';

import { RootState } from '../../store';
import {
  calcEndDateWithExcludeToday,
  calcStartDateFromDateType,
  formatDate,
} from '../../utils/date';
import { modelReportsMksTransitiveGraphSelectedMksHallCodesSelector } from '../ui/modelReportsSetting';
import { settingsOptionsKiSearchConditionHallsSelector } from './settingsOptionsKi';

// Action Types
const FETCH_DATA_KI_MKS_TRANSITIVE_GRAPH_REQUEST = 'FETCH_DATA_KI_MKS_TRANSITIVE_GRAPH_REQUEST' as const;
const FETCH_DATA_KI_MKS_TRANSITIVE_GRAPH_SUCCESS = 'FETCH_DATA_KI_MKS_TRANSITIVE_GRAPH_SUCCESS' as const;
const HIDE_DATA_KI_MKS_TRANSITIVE_GRAPH = 'HIDE_DATA_KI_MKS_TRANSITIVE_GRAPH' as const;
const RENEW_DATA_KI_MKS_TRANSITIVE_GRAPH = 'RENEW_DATA_KI_MKS_TRANSITIVE_GRAPH' as const;

export const DataKiMksTransitiveGraphActionTypes = {
  HIDE_DATA_KI_MKS_TRANSITIVE_GRAPH,
  FETCH_DATA_KI_MKS_TRANSITIVE_GRAPH_REQUEST,
  FETCH_DATA_KI_MKS_TRANSITIVE_GRAPH_SUCCESS,
  RENEW_DATA_KI_MKS_TRANSITIVE_GRAPH,
};

// Action Creators

// 推移グラフ（店舗比較）を閉じる
function hideDataKiMksTransitiveGraphAction() {
  return {
    type: HIDE_DATA_KI_MKS_TRANSITIVE_GRAPH,
  };
}

/**
 * グラフデータ取得リクエスト
 * @param modelCode        - 対象の機種コード
 * @param hallCode         - 自社店舗の店舗コード
 * @param shuGroupId       - 対象の種別グループコード
 * @param shuId            - 対象の種別コード
 * @param excludeToday     - 当日除外フラグ
 * @param field            - グラフ項目
 * @param dateType         - グラフ取得期間
 */
function fetchDataKiMksTransitiveGraphRequestAction(
  modelCode: string,
  hallCode: string,
  shuGroupId: string | undefined,
  shuId: string | undefined,
  excludeToday: boolean,
  endDate: string,
  field?: GraphFieldType,
  dateType?: GraphDateType
) {
  const endDateWithExcludeToday = calcEndDateWithExcludeToday(
    excludeToday,
    endDate
  );
  const startDate = formatDate(
    calcStartDateFromDateType(dateType, endDateWithExcludeToday)
  );
  return {
    type: FETCH_DATA_KI_MKS_TRANSITIVE_GRAPH_REQUEST,
    payload: {
      kiList: [modelCode],
      halls: [hallCode],
      shuGroupIds: shuGroupId ? [shuGroupId] : [],
      shuList: shuId ? [shuId] : [],
      field: field ?? GRAPH_FIELD_TYPES[1], // デフォルトは客数
      dateType: dateType ?? GRAPH_DATE_TYPE['DAILY'],
      startDate,
      endDate: formatDate(endDateWithExcludeToday),
    },
  };
}

function fetchDataKiMksTransitiveGraphSuccessAction(
  graphData: DataKiMksTransitiveGraphData
) {
  return {
    type: FETCH_DATA_KI_MKS_TRANSITIVE_GRAPH_SUCCESS,
    payload: { graphData },
  };
}

// グラフの取得に失敗した際、payloadにエラー内容を入れる
function renewDataKiMksTransitiveGraphAction() {
  return {
    type: RENEW_DATA_KI_MKS_TRANSITIVE_GRAPH,
  };
}

export const DataKiMksTransitiveGraphActionCreators = {
  hideDataKiMksTransitiveGraphAction,
  fetchDataKiMksTransitiveGraphRequestAction,
  fetchDataKiMksTransitiveGraphSuccessAction,
  renewDataKiMksTransitiveGraphAction,
};

// Actions

export type FetchDataKiMksTransitiveGraphRequestAction = ReturnType<
  typeof fetchDataKiMksTransitiveGraphRequestAction
>;
type HideDataKiMksTransitiveGraphAction = ReturnType<
  typeof hideDataKiMksTransitiveGraphAction
>;

type DataKiMksTransitiveGraphAction =
  | HideDataKiMksTransitiveGraphAction
  | FetchDataKiMksTransitiveGraphRequestAction
  | ReturnType<typeof fetchDataKiMksTransitiveGraphSuccessAction>
  | ReturnType<typeof renewDataKiMksTransitiveGraphAction>;

// State

type DataKiMksTransitiveGraphState = {
  graph?: DataKiMksTransitiveGraphData;
  isLoading: boolean;
};

export const initialState: DataKiMksTransitiveGraphState = {
  graph: undefined,
  isLoading: false,
};

// Selector

// ベースセレクタ
const dataKiMksTransitiveGraphRootSelector = (state: RootState) =>
  state.dataKiMksTransitiveGraph;

// グラフデータ取得
export const dataKiMksTransitionGraphGraphDataSelector = createSelector(
  [
    dataKiMksTransitiveGraphRootSelector,
    modelReportsMksTransitiveGraphSelectedMksHallCodesSelector,
    settingsOptionsKiSearchConditionHallsSelector,
  ],
  ({ graph }, selectedMksHallCodes, halls) => {
    const { data, setting } = graph ?? {};
    if (!data || !setting) {
      return undefined;
    }

    const mksHalls =
      halls.find((x: Hall) => x.code === setting.halls[0])?.hallsOfMarketArea ??
      [];
    const selectedMksHallNames = [
      { code: 'mksAll', name: '商圏全体' },
      ...mksHalls,
    ]
      .filter(({ code }) => selectedMksHallCodes.includes(code))
      .map(({ name }) => name);

    // 商圏総台数のindex
    const totalNumberInMks = data.columns.findIndex(
      (x) => x.name === '商圏総台数'
    );
    const filteredColumnIndices = data.columns.reduce(
      (acc, curr, index) => {
        if (selectedMksHallNames.includes(curr.name)) {
          return [...acc, index];
        }
        return acc;
      },
      [0, totalNumberInMks] // 1列目は日付 2列目は商圏全体
    );

    return {
      columns: data.columns.filter((_, index) =>
        filteredColumnIndices.includes(index)
      ),
      rows: data.rows.map((row) =>
        row.filter((_, index) => filteredColumnIndices.includes(index))
      ),
    };
  }
);

// レスポンスのパラメータ取得
export const dataKiMksTransitionGraphGraphSettingSelector = createSelector(
  dataKiMksTransitiveGraphRootSelector,
  ({ graph }) => graph?.setting
);

// ローディング状態を取得
export const dataKiMksTransitionGraphIsLoadingSelector = createSelector(
  dataKiMksTransitiveGraphRootSelector,
  ({ isLoading }) => isLoading
);

// グラフのカラムから商圏店舗名一覧を取得
export const dataKiMksTransitionGraphMksHallNamesSelector = createSelector(
  dataKiMksTransitiveGraphRootSelector,
  ({ graph }) => graph?.data.columns.map((x) => x.name) ?? []
);

// Reducer

export function dataKiMksTransitiveGraphReducer(
  state = initialState,
  action: DataKiMksTransitiveGraphAction
): DataKiMksTransitiveGraphState {
  switch (action.type) {
    case FETCH_DATA_KI_MKS_TRANSITIVE_GRAPH_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_DATA_KI_MKS_TRANSITIVE_GRAPH_SUCCESS:
      return {
        ...state,
        graph: action.payload.graphData,
        isLoading: false,
      };
    case HIDE_DATA_KI_MKS_TRANSITIVE_GRAPH:
      return initialState;
    case RENEW_DATA_KI_MKS_TRANSITIVE_GRAPH:
      return initialState;
    default:
      return state;
  }
}
