import { fork, put, select, take, takeEvery } from 'redux-saga/effects';

import { SettingsDaiCostsResponse } from '../domain/daiCosts/types';
import { LoadingState } from '../domain/schemas';

import {
  DataSettingsDaiCostsActionCreators,
  DataSettingsDaiCostsActionTypes,
  dataSettingsDaiCostsDataSelector,
  dataSettingsDaiCostsFetchLoadingStateSelector,
} from '../redux/server/dataSettingsDaiCosts';
import {
  ChangeDaiCostsSettingSortAndOrderAction,
  DaiCostsSettingActionCreators,
  DaiCostsSettingActionTypes,
  DaiCostsSettingState,
  SearchSettingDaiCostsAction,
  TriggerDaiCostsSettingSortAction,
  daiCostsSettingSearchParamsSelector,
} from '../redux/ui/daiCostsSetting';
import { changeOrder } from '../utils/sort';

/**
 * 初回データ取得
 */
function* initFetchDaiCostsDataSaga() {
  const loadingState: LoadingState = yield select(
    dataSettingsDaiCostsFetchLoadingStateSelector
  );

  if (loadingState !== 'prepare') {
    return;
  }

  yield put(
    DataSettingsDaiCostsActionCreators.fetchDataSettingsDaiCostsAction()
  );

  // NOTE: 初回レスポンスで返されるendDateは、選択可能な最大未来日となるため
  // 以降のリクエストで上書きされないように個別にreduxへ保存する
  yield take(
    DataSettingsDaiCostsActionTypes.FETCH_DATA_SETTINGS_DAI_COSTS_SUCCESS
  );
  const daiCostsData: SettingsDaiCostsResponse = yield select(
    dataSettingsDaiCostsDataSelector
  );
  yield put(
    DaiCostsSettingActionCreators.saveDaiCostsSettingEndDateAction(
      daiCostsData.setting.endDate
    )
  );
}

/**
 * 変更後の検索フォームでデータを取得する
 */
function* searchDataSettingsDaiCostsSaga(action: SearchSettingDaiCostsAction) {
  yield put(
    DataSettingsDaiCostsActionCreators.fetchDataSettingsDaiCostsAction(
      action.payload.searchParams
    )
  );
}

/**
 * ソート条件を変更する
 */
function* triggerSettingsDaiCostsSortSaga(
  action: TriggerDaiCostsSettingSortAction
) {
  const { sort } = action.payload;

  const response: SettingsDaiCostsResponse | undefined = yield select(
    dataSettingsDaiCostsDataSelector
  );
  if (response == null) {
    return;
  }

  yield put(
    DaiCostsSettingActionCreators.changeDaiCostsSettingSortAndOrderAction(
      sort,
      changeOrder(sort, response.setting)
    )
  );
}

/**
 * ソート条件でテーブルデータを再取得する
 */
function* changeSettingsDaiCostsSortAndOrderSaga(
  action: ChangeDaiCostsSettingSortAndOrderAction
) {
  const { sort, order } = action.payload;

  const searchParams: DaiCostsSettingState['searchParams'] = yield select(
    daiCostsSettingSearchParamsSelector
  );

  yield put(
    DataSettingsDaiCostsActionCreators.fetchDataSettingsDaiCostsAction({
      ...(searchParams ? searchParams : {}),
      sort,
      order,
    })
  );
}

/**
 * 初回表示時のデータ取得
 */
function* handleInitFetchSaga() {
  yield takeEvery(
    DaiCostsSettingActionTypes.INIT_DAI_COSTS_SETTING,
    initFetchDaiCostsDataSaga
  );
}

/**
 * 検索時のデータ取得
 */
function* handleSearchFetchSaga() {
  yield takeEvery(
    DaiCostsSettingActionTypes.SEARCH_DAI_COSTS_SETTING,
    searchDataSettingsDaiCostsSaga
  );
}

/**
 * ソートの変更
 */
function* handleSortSaga() {
  yield takeEvery(
    DaiCostsSettingActionTypes.TRIGGER_DAI_COSTS_SETTING_SORT,
    triggerSettingsDaiCostsSortSaga
  );
  yield takeEvery(
    DaiCostsSettingActionTypes.CHANGE_DAI_COSTS_SETTING_SORT_AND_ORDER,
    changeSettingsDaiCostsSortAndOrderSaga
  );
}

export function* daiCostsSettingSaga() {
  yield fork(handleInitFetchSaga);
  yield fork(handleSearchFetchSaga);
  yield fork(handleSortSaga);
}
