import { AxiosResponse } from 'axios';
import { call, fork, put, takeEvery } from 'redux-saga/effects';

import { AreasSettingsParams } from '../domain/dataAreasSettings';
import { isClarisApiError } from '../domain/error';

import {
  DataAreasActionCreators,
  DataAreasActionTypes,
  DeleteDataSettingsAreasAction,
  PatchDataSettingsAreasAction,
  PostDataSettingsAreasAction,
} from '../redux/server/dataSettingsAreas';
import { SettingsHallsActionCreators } from '../redux/server/settingsHalls';
import { Api, buildConfig } from '../utils/api';
import { handleErrorSaga } from './errorSaga';

// エリア一覧取得
function* fetchDataSettingsAreasSaga(api: Api) {
  try {
    yield put(DataAreasActionCreators.fetchDataSettingsAreasRequestAction());
    const response: AxiosResponse<AreasSettingsParams> = yield call(
      api.get,
      '/settings/area',
      buildConfig()
    );
    yield put(
      DataAreasActionCreators.fetchDataSettingsAreasSuccessAction(response.data)
    );
  } catch (error: unknown) {
    yield put(DataAreasActionCreators.renewDataSettingsAreasAction());
    yield fork(handleErrorSaga, error);
  }
}

// エリア新規作成
function* postDataSettingsAreasSaga(
  api: Api,
  action: PostDataSettingsAreasAction
) {
  try {
    const { params } = action.payload;
    yield put(DataAreasActionCreators.postDataSettingsAreasRequestAction());
    yield call(api.post, '/settings/area', params, buildConfig());
    yield put(DataAreasActionCreators.fetchDataSettingsAreasAction());
    yield put(DataAreasActionCreators.postDataSettingsAreasSuccessAction());
  } catch (error: unknown) {
    yield put(DataAreasActionCreators.renewDataSettingsAreasAction());
    if (isClarisApiError(error) && error.response?.data.statusCode === '422') {
      yield put(
        DataAreasActionCreators.postDataSettingsAreasFailureAction(
          '既に同じ名称の項目が登録されているため登録できません。'
        )
      );
    }

    yield fork(handleErrorSaga, error);
  }
}

// エリア削除
function* deleteDataSettingsAreasSaga(
  api: Api,
  action: DeleteDataSettingsAreasAction
) {
  try {
    const { areaId } = action.payload;
    yield put(DataAreasActionCreators.deleteDataSettingsAreasRequestAction());
    yield call(api.delete, `/settings/area/${areaId}`, buildConfig());

    yield put(DataAreasActionCreators.fetchDataSettingsAreasAction());
    yield put(SettingsHallsActionCreators.fetchSettingsHallsAction());
    yield put(DataAreasActionCreators.deleteDataSettingsAreasSuccessAction());
  } catch (error: unknown) {
    yield put(DataAreasActionCreators.renewDataSettingsAreasAction());
    yield fork(handleErrorSaga, error);
  }
}

function* patchDataSettingsAreasSaga(
  api: Api,
  action: PatchDataSettingsAreasAction
) {
  try {
    const { areaId, params } = action.payload;
    yield put(DataAreasActionCreators.patchDataSettingsAreasRequestAction());
    yield call(api.patch, `/settings/area/${areaId}`, params, buildConfig());

    yield put(DataAreasActionCreators.fetchDataSettingsAreasAction());
    yield put(SettingsHallsActionCreators.fetchSettingsHallsAction());
    yield put(DataAreasActionCreators.patchDataSettingsAreasSuccessAction());
  } catch (error: unknown) {
    yield put(DataAreasActionCreators.renewDataSettingsAreasAction());
    if (isClarisApiError(error) && error.response?.data.statusCode === '422') {
      yield put(
        DataAreasActionCreators.postDataSettingsAreasFailureAction(
          '既に同じ名称の項目が登録されているため登録できません。'
        )
      );
    }
    yield fork(handleErrorSaga, error);
  }
}

function* handleFetchDataSettingsAreasSaga(api: Api) {
  yield takeEvery(
    DataAreasActionTypes.FETCH_DATA_AREAS,
    fetchDataSettingsAreasSaga,
    api
  );
}

function* handlePostDataSettingsAreasSaga(api: Api) {
  yield takeEvery(
    DataAreasActionTypes.POST_DATA_AREAS,
    postDataSettingsAreasSaga,
    api
  );
}

function* handleDeleteDataSettingsAreasSaga(api: Api) {
  yield takeEvery(
    DataAreasActionTypes.DELETE_DATA_AREAS,
    deleteDataSettingsAreasSaga,
    api
  );
}

function* handlePatchDataSettingsAreasSaga(api: Api) {
  yield takeEvery(
    DataAreasActionTypes.PATCH_DATA_AREAS,
    patchDataSettingsAreasSaga,
    api
  );
}

export function* dataSettingsAreasSagas(context: { api: Api }) {
  yield fork(handleFetchDataSettingsAreasSaga, context.api);
  yield fork(handlePostDataSettingsAreasSaga, context.api);
  yield fork(handleDeleteDataSettingsAreasSaga, context.api);
  yield fork(handlePatchDataSettingsAreasSaga, context.api);
}
