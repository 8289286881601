import Box from '@mui/material/Box';
import { ComponentProps, PropsWithoutRef, forwardRef } from 'react';

import { icons } from './icons';

type Props = {
  name: keyof typeof icons;
} & PropsWithoutRef<
  Omit<ComponentProps<'img'>, 'src'> &
    Omit<ComponentProps<typeof Box>, 'children'>
>;

/**
 * パフォーマンス向上のため、ループに巻き込むようなアイコンについてはBase64で埋め込んだアイコンを使用する
 *
 * 色の変更はできない（filterを使えばできなくもありませんが）ので、別の色が必要になった場合は別で用意してください
 * ただし必要な色が灰色の場合、opacityの調整で問題ないか確認してください
 */
export const Base64Icon = forwardRef<HTMLImageElement, Props>(
  ({ name, alt, width, height, ...rest }, ref) => {
    return (
      <Box
        component="img"
        src={icons[name]}
        alt={alt ?? ''}
        width={width ?? 24}
        height={height ?? 24}
        ref={ref}
        {...rest}
      />
    );
  }
);
