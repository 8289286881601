import ShareIcon from '@mui/icons-material/Share';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { FC } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { favoritesSelector } from '../../../redux/ui/settingsFavorites';
import { favoriteToPath } from '../../../utils/favoriteToPath';
import { getSharedState } from '../../../utils/getSharedState';
import { gaClickEvent } from '../../../utils/googleAnalytics';

type Props = {
  itemName: string;
};

export const GlobalFavoritesMenu: FC<Props> = (props) => {
  const { itemName } = props;

  const favorite = useSelector(favoritesSelector, shallowEqual);

  if (favorite?.favorites == null) {
    return null;
  }

  return (
    <Box
      sx={{
        minWidth: '380px',
        maxHeight: '400px',
        overflowY: 'auto',
        padding: 2,

        '&::-webkit-scrollbar': {
          width: 10,
          height: 10,
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: (theme) => theme.palette.grey['400'],
          borderRadius: 10,
        },
        '&::-webkit-scrollbar-track': {
          width: 12,
          backgroundColor: (theme) => theme.palette.grey['100'],
        },
      }}
    >
      <Typography
        sx={{
          px: 2,
          py: 1,
          borderBottom: '1px solid',
          borderColor: 'grey.300',
        }}
      >
        お気に入り
      </Typography>

      {(() => {
        if (favorite.favorites.length === 0) {
          return (
            <Typography variant="body2" sx={{ m: 2, mb: 0 }}>
              お気に入りが登録されていません。
            </Typography>
          );
        }

        return favorite.favorites
          .filter((item) => item.pageName === itemName)
          .map((item) => {
            const sharedState = getSharedState(item);
            const path = favoriteToPath(item);

            return (
              <Link
                component={RouterLink}
                key={item.id}
                to={`${path}?fav_id=${item.id}`}
                onClick={() => {
                  gaClickEvent('お気に入り適用', {
                    event_click_value: 'ページ別サイドバー',
                  });
                }}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  textDecoration: 'none',
                  color: 'inherit',
                  px: 2,
                  py: 1,
                  gap: 1,
                  '&:hover': { backgroundColor: 'grey.100' },
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 3,
                    overflow: 'hidden',
                    // 未対応ブラウザですべて表示されては困るので、念の為指定しておく
                    maxHeight: '4.5em',
                  }}
                >
                  {item.name}
                </Typography>

                {sharedState !== 'none' && (
                  <ShareIcon
                    fontSize="small"
                    sx={{
                      color: sharedState === 'all' ? 'inherit' : 'gray',
                      ml: 'auto',
                    }}
                  />
                )}
              </Link>
            );
          });
      })()}
    </Box>
  );
};
