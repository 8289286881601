import * as Sentry from '@sentry/browser';
import jwt_decode from 'jwt-decode';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { User } from '../domain/user';

import { DataSettingsEventsActionCreators } from '../redux/server/dataSettingsEvents';
import {
  UserActionCreators,
  userIsPostLoginSelector,
  userIsSetUserSelector,
} from '../redux/ui/user';
import { useAuth } from './useAuth';

export const useParseUserData = () => {
  const dispatch = useDispatch();
  const { user, getAccessTokenSilently } = useAuth();
  const isPostLogin = useSelector(userIsPostLoginSelector);
  const isSetUser = useSelector(userIsSetUserSelector);

  const setUserData = useCallback(
    (token: string) => {
      const auth0user:
        | string
        | {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            [key: string]: any;
          }
        | null = jwt_decode(token);
      if (typeof auth0user !== 'object' || auth0user == null) {
        throw new Error(`jwt のパース結果が正しくありません: ${auth0user}`);
      }

      const sub: string = auth0user['sub'];
      const userId = sub.split('|')[1];
      const kyoyuGroupCode: string =
        auth0user[`${process.env.REACT_APP_AUTH0_NAMESPACE}kyoyu_group_code`];
      const role: string =
        auth0user[`${process.env.REACT_APP_AUTH0_NAMESPACE}role`];
      const hallCodeInCharge: string =
        auth0user[
          `${process.env.REACT_APP_AUTH0_NAMESPACE}hall_code_in_charge`
        ];
      const userData: User = {
        ...user,
        userId,
        kyoyuGroupCode,
        role,
        hallCodeInCharge,
      };
      Sentry.setUser({
        id: userData.userId,
      });
      dispatch(UserActionCreators.setUserAction(userData));
    },
    [dispatch, user]
  );

  useEffect(() => {
    let unmounted = false;

    const getToken = async () => {
      const token = await getAccessTokenSilently();

      if (!unmounted) {
        setUserData(token);
      }
    };
    getToken();

    return () => {
      unmounted = true;
    };
  }, [setUserData, getAccessTokenSilently]);

  // 初回ログイン、再読み込み時に操作履歴APIを叩く
  useEffect(() => {
    let unmounted = false;
    if (!unmounted && !isPostLogin && !isSetUser) {
      dispatch(
        DataSettingsEventsActionCreators.postDataSettingsEventsAction({
          eventType: 'login',
        })
      );
    }
    return () => {
      unmounted = true;
    };
    // ログイン時にのみ必ず1度のみ実行させたいため、isSetUser以外を依存配列に含めない
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSetUser]);
};
