import { FavoriteItem } from '../domain/favorites';

/**
 * 共有状態を取得する
 *
 * TODO: 自身のみがprivatelySharedUsersにいる場合には、共有扱いなのか
 */
export const getSharedState = (item: FavoriteItem) => {
  if (item.isShared) {
    return 'all';
  }

  if (
    !item.isShared &&
    item.privatelySharedUsers &&
    item.privatelySharedUsers.length > 0
  ) {
    return 'privatelyShared';
  }

  return 'none';
};
