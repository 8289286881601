import { AxiosResponse } from 'axios';
import { call, fork, put, select, takeEvery } from 'redux-saga/effects';

import { PpmShareReportsFormConditions } from '../domain/ppmShare/types';
import {
  DataPpmShareGraph,
  DataPpmShareGraphParams,
} from '../domain/ppmShare/types';
import { LoadingState } from '../domain/schemas';
import { ShuOption } from '../domain/shu';

import {
  DataPpmShareGraphActionCreators,
  DataPpmShareGraphActionTypes,
  FetchDataPpmShareGraphAction,
  SearchDataPpmShareGraphShuAction,
  dataPpmShareGraphLoadingSelector,
} from '../redux/server/dataPpmShareGraph';
import {
  ppmShareReportsSearchConditionSelector,
  ppmShareReportsSelectedCurrentShuSelector,
} from '../redux/ui/ppmShareReportsSetting';
import { Api, buildConfig } from '../utils/api';
import { handleErrorSaga } from './errorSaga';

// 検索条件を指定して店舗別PPMシェアグラフデータを取得する
function* fetchDataPpmShareGraphSaga(
  api: Api,
  action: FetchDataPpmShareGraphAction
) {
  try {
    // リクエストを開始
    yield put(
      DataPpmShareGraphActionCreators.fetchDataPpmShareGraphRequestAction(
        action.payload.params
      )
    );
    const response: AxiosResponse<DataPpmShareGraph> = yield call(
      api.get,
      '/data/ppmShare/shareGraph',
      buildConfig({
        ...action.payload.params,
      })
    );

    yield put(
      DataPpmShareGraphActionCreators.fetchDataPpmShareGraphSuccessAction(
        response.data
      )
    );
  } catch (error: unknown) {
    yield put(DataPpmShareGraphActionCreators.renewDataPpmShareGraphAction());
    yield fork(handleErrorSaga, error);
  }
}

// FETCH_DATA_PPM_SHARE_GRAPHがdispatchされたタイミングでグラフデータを取得
function* handleFetchDataPpmShareGraphSaga(api: Api) {
  yield takeEvery(
    DataPpmShareGraphActionTypes.FETCH_DATA_PPM_SHARE_GRAPH,
    fetchDataPpmShareGraphSaga,
    api
  );
}

// 店舗別PPMシェアグラフを初回取得する
function* initDataPpmShareGraphSaga() {
  const loadingState: LoadingState = yield select(
    dataPpmShareGraphLoadingSelector
  );

  if (loadingState !== 'prepare') {
    return;
  }

  // 現在の検索条件
  const searchCondition: PpmShareReportsFormConditions = yield select(
    ppmShareReportsSearchConditionSelector
  );

  // 現在選択されている種別
  const selectedShu: ShuOption = yield select(
    // ppmShareReportsSelectedShuSelector
    ppmShareReportsSelectedCurrentShuSelector
  );

  // 店舗全体（codeが空）のときはshuListを空にする
  const shuList =
    selectedShu.type === 'shuList' && selectedShu.code !== ''
      ? [selectedShu.code]
      : [];

  const shuGroup =
    selectedShu.type === 'shuGroup' && selectedShu.code !== ''
      ? [selectedShu.code]
      : [];

  const params: DataPpmShareGraphParams = {
    startDate: searchCondition.startDate,
    endDate: searchCondition.endDate,
    days: searchCondition.days,
    dayType: searchCondition.dayType,
    dateSuffixes: searchCondition.dateSuffixes,
    dates: searchCondition.dates,
    excludeToday: searchCondition.excludeToday,
    halls: searchCondition.halls,
    shuGroupIds: shuGroup,
    shuList,
    areas: searchCondition.areas,
    sisTypes: searchCondition.sisTypes,
  };

  // データを取得
  yield put(
    DataPpmShareGraphActionCreators.fetchDataPpmShareGraphAction(params)
  );
}

// 検索実行時検索フォームの内容でグラフを再取得する
function* searchDataPpmShareGraphSaga() {
  const isLoading: boolean = yield select(dataPpmShareGraphLoadingSelector); // ローディング状態

  if (isLoading) return;

  // 現在の検索条件
  const searchCondition: PpmShareReportsFormConditions = yield select(
    ppmShareReportsSearchConditionSelector
  );

  // 現在選択されている種別
  const selectedShu: ShuOption = yield select(
    // ppmShareReportsSelectedShuSelector
    ppmShareReportsSelectedCurrentShuSelector
  );

  // 店舗全体（codeが空）のときはshuListを空にする
  const shuList =
    selectedShu.type === 'shuList' && selectedShu.code !== ''
      ? [selectedShu.code]
      : [];

  const shuGroup =
    selectedShu.type === 'shuGroup' && selectedShu.code !== ''
      ? [selectedShu.code]
      : [];

  const params: DataPpmShareGraphParams = {
    startDate: searchCondition.startDate,
    endDate: searchCondition.endDate,
    days: searchCondition.days,
    dayType: searchCondition.dayType,
    dateSuffixes: searchCondition.dateSuffixes,
    dates: searchCondition.dates,
    excludeToday: searchCondition.excludeToday,
    halls: searchCondition.halls,
    shuGroupIds: shuGroup,
    shuList,
    areas: searchCondition.areas,
    sisTypes: searchCondition.sisTypes,
  };

  // データを取得
  yield put(
    DataPpmShareGraphActionCreators.fetchDataPpmShareGraphAction(params)
  );
}

// 指定した種別のグラフを取得する
function* searchDataPpmShareGraphShuSaga(
  action: SearchDataPpmShareGraphShuAction
) {
  const isLoading: boolean = yield select(dataPpmShareGraphLoadingSelector); // ローディング状態

  if (isLoading) return;

  // 現在の検索条件
  const searchCondition: PpmShareReportsFormConditions = yield select(
    ppmShareReportsSearchConditionSelector
  );

  const shuList =
    action.payload.shu.type === 'shuList' && action.payload.shu.code !== ''
      ? [action.payload.shu.code]
      : [];
  const shuCodes =
    action.payload.shu.type === 'shuGroup' ? [action.payload.shu.code] : [];

  // データを取得
  yield put(
    DataPpmShareGraphActionCreators.fetchDataPpmShareGraphAction({
      ...searchCondition,
      shuList,
      shuGroupIds: shuCodes,
    })
  );
}

// SEARCH_DATA_HALL_PPM_SHARE_GRAPHがdispatchされた時の処理
function* handleSearchDataPpmShareGraphSaga() {
  yield takeEvery(
    DataPpmShareGraphActionTypes.SEARCH_DATA_PPM_SHARE_GRAPH,
    searchDataPpmShareGraphSaga
  );
}

// INIT_DATA_PPM_SHARE_GRAPHがdispatchされた時の処理
function* handleInitDataPpmShareGraphSaga() {
  yield takeEvery(
    DataPpmShareGraphActionTypes.INIT_DATA_PPM_SHARE_GRAPH,
    initDataPpmShareGraphSaga
  );
}

// SEARCH_DATA_PPM_SHARE_GRAPH_SHUがdispatchされた時の処理
function* handleSearchDataPpmShareGraphShuSaga() {
  yield takeEvery(
    DataPpmShareGraphActionTypes.SEARCH_DATA_PPM_SHARE_GRAPH_SHU,
    searchDataPpmShareGraphShuSaga
  );
}

// 店舗別PPMシェアグラフに関するタスクを実行
export function* dataPpmShareGraphSagas(context: { api: Api }) {
  yield fork(handleFetchDataPpmShareGraphSaga, context.api);
  yield fork(handleSearchDataPpmShareGraphSaga);
  yield fork(handleSearchDataPpmShareGraphShuSaga);
  yield fork(handleInitDataPpmShareGraphSaga);
}
