import {
  addMonths,
  endOfMonth,
  format,
  isSameDay,
  startOfMonth,
  subMonths,
} from 'date-fns';

import {
  DataSettingsUnitModelDataByHallAndDate,
  DataSettingsUnitModelTableRow,
  UnitModelSetting,
  UnitModelSettingData,
} from './dataSettingsUnitModel';

export const calcDefaultStartDate = (now = new Date()) =>
  format(startOfMonth(subMonths(now, 2)), 'yyyy-MM-dd');

export const calcDefaultEndDate = (now = new Date()) =>
  format(endOfMonth(addMonths(now, 2)), 'yyyy-MM-dd');

/**
 *  新規登録を行いたい日付に元情報が「手入力」の
 * 「有効な」台番機種設定が存在する場合は新規登録は不可
 */
export const hasManualInputValidSettingOnTheDate = (
  xs: UnitModelSettingData[] | null,
  openingDate: Date
): boolean => {
  if (xs?.length === 0 || !xs) return false;

  return xs
    .filter((x) => x.isEnabled)
    .filter((x) => x.originType === '手入力')
    .some((x) => isSameDay(openingDate, new Date(x.settingPeriod.startDate)));
};

/**
 * 新規登録したい日付に
 * 有効な申請書の台番機種設定がある場合 -> 有効な申請書の台番機種設定を返す
 * それ以外の場合 -> 一つ前の有効な台番機種設定を返す
 */
export const getValidSettingForCreate = (
  dataByHallCodeAndDate: DataSettingsUnitModelDataByHallAndDate | null
): UnitModelSetting[] => {
  if (!dataByHallCodeAndDate) {
    return [];
  }

  // 有効な手入力の台番機種設定がある場合は新規作成できないので空配列を返す
  const validManualInputSetting = dataByHallCodeAndDate.daiKisyuList?.find(
    (x) => x.originType === '手入力' && x.isEnabled
  );
  if (validManualInputSetting) {
    return [];
  }

  // 有効な申請書の台番機種設定があるかどうか確認
  const validDocumentSetting = dataByHallCodeAndDate.daiKisyuList?.find(
    (x) => x.originType === '申請書' && x.isEnabled
  );

  if (validDocumentSetting) {
    return validDocumentSetting.data;
  }

  return dataByHallCodeAndDate.latestEnabledDaiKisyu?.data ?? [];
};

/**
 * 台番機種設定が一切存在しない場合にtrueを返す
 */
export const hasNoPreviousSetting = (
  dataByHallCodeAndDate: DataSettingsUnitModelDataByHallAndDate | null
) => {
  return (
    dataByHallCodeAndDate &&
    dataByHallCodeAndDate.hallCode &&
    dataByHallCodeAndDate.daiKisyuList?.length === 0 &&
    !dataByHallCodeAndDate.latestEnabledDaiKisyu
  );
};

/**
 * 新規登録を行いたい日付に元情報が
 * 「無効な」「手入力」の台番機種設定が存在する場合は
 * 「削除する旨の確認メッセージ」を表示する必要がある
 *
 * この関数が呼ばれるタイミングでは手入力の有効な台番機種設定は存在しないはずなので、
 * その場合は例外を投げる
 */
export const shouldConfirmOverwriteDisabledManualInput = (
  xs: UnitModelSettingData[] | null,
  openingDate: Date
): boolean => {
  if (xs?.length === 0 || !xs) return false;

  const manualInputs = xs
    .filter((x) => x.originType === '手入力')
    .filter((x) => isSameDay(openingDate, new Date(x.settingPeriod.startDate)));

  if (manualInputs.some((x) => x.isEnabled)) {
    throw new Error('手入力の有効な台番機種設定が存在します');
  }

  if (manualInputs.length === 0) {
    return false;
  }
  return manualInputs.some((x) => x.isEnabled === false);
};

// 新規登録時に選択できる日付の幅は一年前から二ヶ月先まで
export const selectableDateRangeForNew = (now = new Date()) => ({
  rangeStart: startOfMonth(subMonths(now, 12)),
  rangeEnd: endOfMonth(addMonths(now, 2)),
});

// 新規作成時の期間最終日を返す関数
// https://classmethod.slack.com/archives/C026YMFKL8L/p1676962480678829?thread_ts=1676960501.289059&cid=C026YMFKL8L
export const extractEndDateAsStr = (
  detailsByHallCodeAndDate: DataSettingsUnitModelDataByHallAndDate | null
): string => {
  if (!detailsByHallCodeAndDate) {
    return '';
  }

  const endDateStr = detailsByHallCodeAndDate?.daiKisyuList?.find(
    (x) => x.isEnabled
  )
    ? detailsByHallCodeAndDate?.daiKisyuList.find((x) => x.isEnabled)
        ?.settingPeriod.endDate
    : detailsByHallCodeAndDate?.latestEnabledDaiKisyu?.settingPeriod.endDate;

  return endDateStr ? endDateStr : '';
};

/**
 * 有効化時に他の行のデータを無効化する必要があるかどうかを判断し、
 * 無効化する必要がある場合は対象の行データを返す
 */
export const shouldDisableOtherRows = (
  id: string,
  isEnabled: boolean,
  data: DataSettingsUnitModelTableRow[]
): [boolean, DataSettingsUnitModelTableRow[]] => {
  const rowToChange = data.find((x) => x.id === id);
  if (!rowToChange) {
    throw new Error('有効化したはずのデータが存在しません');
  }

  const rowsWithSameDate = data.filter((x) => {
    return (
      x.id !== id &&
      x.openingDate === rowToChange.openingDate &&
      x.hall.code === rowToChange.hall.code &&
      x.isEnabled === true
    );
  });

  if (isEnabled && rowsWithSameDate.length > 0) {
    return [true, rowsWithSameDate];
  }

  return [false, []];
};
