// この関数がdecompressFromEncodedURIComponentのラッパーとなっており、この関数でのみ読込みさせています
// eslint-disable-next-line no-restricted-imports
import { decompressFromEncodedURIComponent as decompressFromEncodedURIComponentOrigin } from 'lz-string';

import { FavoritePageSetting } from '../domain/favorites';

export const decompressFromEncodedURIComponent = (q: string) => {
  const decompressed: {
    pageName: string;
    pageSetting: FavoritePageSetting;
    createdAt: string;
  } = JSON.parse(decompressFromEncodedURIComponentOrigin(q));

  return decompressed;
};
