export const insert = <T>(
  array: T[],
  fromIndex: number,
  toIndex: number
): T[] => {
  if (fromIndex >= array.length || toIndex >= array.length) {
    throw new RangeError('fromIndex/toIndex must be less than array.length');
  }

  if (fromIndex < 0 || toIndex < 0) {
    throw new RangeError('fromIndex/toIndex must be greater than 0');
  }

  if (fromIndex === toIndex) {
    return array;
  }

  const copy = [...array];
  copy.splice(fromIndex, 1);
  copy.splice(toIndex, 0, array[fromIndex]);

  return copy;
};
