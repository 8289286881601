import { createSelector } from 'reselect';

import { DataKiParams } from '../../domain/dataKi';
import { DataKi2ndRow } from '../../domain/dataKi2ndRow';
import { QueryParameter } from '../../domain/schemas';

import { RootState } from '../../store';
import { getOrderedTableData } from '../../utils/orderedCell';
import {
  dataKiColumnsOrderSelector,
  dataKiDataColumnsSelector,
} from './dataKi';

/**
 * Action Types
 *
 * MEMO: React側からのActionの命名は"実際に起こったこと"を基準にするといい感じになる
 */

const SEARCH_DATA_KI_2NDROW = 'SEARCH_DATA_KI_2NDROW' as const;
const FETCH_DATA_KI_2NDROW = 'FETCH_DATA_KI_2NDROW' as const;
const HIDE_DATA_KI_2NDROW = 'HIDE_DATA_KI_2NDROW' as const;
const HIDE_ALL_DATA_KI_2NDROW = 'HIDE_ALL_DATA_KI_2NDROW' as const;
const TOGGLE_DATA_KI_2NDROW = 'TOGGLE_DATA_KI_2NDROW' as const;
const FETCH_DATA_KI_2NDROW_REQUEST = 'FETCH_DATA_KI_2NDROW_REQUEST' as const;
const FETCH_DATA_KI_2NDROW_SUCCESS = 'FETCH_DATA_KI_2NDROW_SUCCESS' as const;
const RENEW_DATA_KI_ONLY_KI_TAG_AVERAGE_2NDROW = 'RENEW_DATA_KI_ONLY_KI_TAG_AVERAGE_2NDROW' as const;
const RENEW_DATA_KI_2NDROW = 'RENEW_DATA_KI_2NDROW' as const;

export const DataKi2ndRowActionTypes = {
  SEARCH_DATA_KI_2NDROW,
  FETCH_DATA_KI_2NDROW,
  HIDE_DATA_KI_2NDROW,
  HIDE_ALL_DATA_KI_2NDROW,
  TOGGLE_DATA_KI_2NDROW,
  FETCH_DATA_KI_2NDROW_REQUEST,
  FETCH_DATA_KI_2NDROW_SUCCESS,
  RENEW_DATA_KI_ONLY_KI_TAG_AVERAGE_2NDROW,
  RENEW_DATA_KI_2NDROW,
};

/**
 * Action Creators
 *
 * MEMO: ReactからRedux、ReduxからSaga、SagaからReduxにActionを分けるといい感じになる（1つのActionで2つのことをしようとしない）
 */

// 指定した検索条件で店舗行を取得する
function fetchDataKi2ndRowAction(
  queryParameter: QueryParameter,
  params: DataKiParams
) {
  return {
    type: FETCH_DATA_KI_2NDROW,
    payload: { queryParameter, params },
  };
}

/**
 * QueryParamterを元に展開行を取得する
 * @param params クリックした行のQueryParameter
 * @param kiList string[] | undefined: 各機種タグ平均合計行から展開行を表示する際に利用
 */
function searchDataKi2ndRowAction(params: QueryParameter, kiList?: string[]) {
  return {
    type: SEARCH_DATA_KI_2NDROW,
    payload: { params, kiList },
  };
}

/**
 * 指定した店舗行を削除する
 * @param code 展開行のコード（QueryParameterのvalue）
 */
function hideDataKi2ndRowAction(code: string) {
  return {
    type: HIDE_DATA_KI_2NDROW,
    payload: { code },
  };
}

/**
 * すべての店舗行を削除する（検索時やリセット時に呼ぶといい）
 */
function hideAllDataKi2ndRowAction() {
  return {
    type: HIDE_ALL_DATA_KI_2NDROW,
  };
}

/**
 * QueryParameterを元に店舗行を表示・非表示する
 * @param params QueryParameter | undefined
 * @param kiList string[] | undefined: 各機種タグ平均合計行から展開行を表示する際に利用
 */
function toggleDataKi2ndRowAction(params?: QueryParameter, kiList?: string[]) {
  return {
    type: TOGGLE_DATA_KI_2NDROW,
    payload: { params, kiList },
  };
}

/**
 * 展開行データ取得前に呼ぶ
 * @param code 展開行のコード（QueryParameterのvalue）
 * @param params 取得する展開行の検索条件
 */
function fetchDataKi2ndRowRequestAction(code: string, params: DataKiParams) {
  return {
    type: FETCH_DATA_KI_2NDROW_REQUEST,
    payload: { code, params },
  };
}

/**
 * 展開行データ取得成功時、取得したデータを登録する
 * @param code 展開行のコード（QueryParameterのvalue）
 * @param dataKi2ndRow 取得した展開行データ
 */
function fetchDataKi2ndRowSuccessAction(
  code: string,
  dataKi2ndRow: DataKi2ndRow
) {
  return {
    type: FETCH_DATA_KI_2NDROW_SUCCESS,
    payload: { code, dataKi2ndRow },
  };
}

// タグ絞り込み平均合計行の第二展開データのみ初期化する
function renewDataKiOnlyKiTagAverage2ndRowAction() {
  return {
    type: RENEW_DATA_KI_ONLY_KI_TAG_AVERAGE_2NDROW,
  };
}

function renewDataKi2ndRowAction() {
  return {
    type: RENEW_DATA_KI_2NDROW,
  };
}

export const DataKi2ndRowActionCreators = {
  searchDataKi2ndRowAction,
  fetchDataKi2ndRowAction,
  hideDataKi2ndRowAction,
  hideAllDataKi2ndRowAction,
  toggleDataKi2ndRowAction,
  fetchDataKi2ndRowRequestAction,
  fetchDataKi2ndRowSuccessAction,
  renewDataKiOnlyKiTagAverage2ndRowAction,
  renewDataKi2ndRowAction,
};

/**
 * Actions
 */

export type SearchDataKi2ndRowAction = ReturnType<
  typeof searchDataKi2ndRowAction
>;
export type FetchDataKi2ndRowAction = ReturnType<
  typeof fetchDataKi2ndRowAction
>;
type HideDataKi2ndRowAction = ReturnType<typeof hideDataKi2ndRowAction>;
type HideAllDataKi2ndRowAction = ReturnType<typeof hideAllDataKi2ndRowAction>;
export type ToggleDataKi2ndRowAction = ReturnType<
  typeof toggleDataKi2ndRowAction
>;

type DataKi2ndRowAction =
  | SearchDataKi2ndRowAction
  | FetchDataKi2ndRowAction
  | HideDataKi2ndRowAction
  | HideAllDataKi2ndRowAction
  | ToggleDataKi2ndRowAction
  | ReturnType<typeof fetchDataKi2ndRowRequestAction>
  | ReturnType<typeof fetchDataKi2ndRowSuccessAction>
  | ReturnType<typeof renewDataKiOnlyKiTagAverage2ndRowAction>
  | ReturnType<typeof renewDataKi2ndRowAction>;

/**
 * State
 */

export type DataKi2ndRowState = {
  isLoading: {
    [key: string]: boolean | undefined;
  };
  dataKi2ndRow: {
    [key: string]: DataKi2ndRow | undefined;
  };
};

// Stateの初期値
export const initialState: DataKi2ndRowState = {
  isLoading: {},
  dataKi2ndRow: {},
};

/**
 * Selector
 */

// 店舗行をすべて取得する
export const dataKi2ndRowSelector = (state: RootState) => {
  return state.dataKi2ndRow.dataKi2ndRow;
};

/**
 * 該当する店舗行を取得する
 * @param code 展開行のコード（QueryParameterのvalue）
 * @returns 該当する店舗行（該当する店舗行がない場合はundefined）
 */
export const singleDataKi2ndRowSelector = (code: string) => {
  return createSelector(dataKi2ndRowSelector, (dataKi2ndRow) => {
    return dataKi2ndRow[code];
  });
};

/**
 * 取得済みの展開行の検索条件を全て取得する（お気に入り保存用）
 * @returns 現在の展開行のすべての検索条件
 */
export const dataKi2ndRowParamsSelector = createSelector(
  dataKi2ndRowSelector,
  (data) =>
    Object.fromEntries(
      Object.entries(data).map((entry) => {
        const [key, value] = entry;
        return [
          key,
          { params: value?.setting, queryParameter: value?.requestParams },
        ];
      })
    )
);

/**
 * 並び替え後の展開行のデータを取得する
 * @param code 展開行のコード（QueryParameterのvalue）
 * @returns 並び替え後の展開行データ
 */
export const dataKi2ndRowOrderedData = (code: string) =>
  createSelector(
    [
      singleDataKi2ndRowSelector(code),
      dataKiDataColumnsSelector,
      dataKiColumnsOrderSelector,
    ],
    (tableData, originColumns, ordered) => {
      if (!ordered) return tableData;
      if (!tableData) return undefined;

      const result: DataKi2ndRow | undefined = {
        ...tableData,
        data: getOrderedTableData(
          ordered,
          originColumns,
          tableData?.data.rows ?? []
        ),
      } as DataKi2ndRow;

      return result;
    }
  );

/**
 * 並び替え後の展開行の全データを取得する
 * @returns 並び替え後の展開行データ
 */
export const dataKi2ndRowOrderedMultiData = createSelector(
  [dataKi2ndRowSelector, dataKiDataColumnsSelector, dataKiColumnsOrderSelector],
  (tableData, originColumns, ordered) => {
    return Object.keys(tableData).map((key) => {
      const row = tableData[key];
      if (!ordered) {
        return {
          kiCode: row?.requestParams.value ?? 'average',
          data: row,
        };
      }
      if (!row) {
        return undefined;
      }

      const result = {
        ...row,
        data: getOrderedTableData(ordered, originColumns, row.data.rows ?? []),
      };

      return {
        kiCode: row.requestParams.value ?? 'average',
        data: result,
      };
    });
  }
);

// 店舗行のローディング状態を返す
export const dataKi2ndRowIsLoadingSelector = (state: RootState) => {
  return Object.values(state.dataKi2ndRow.isLoading).some((x) => !!x);
};

/**
 * Reducer
 *
 * MEMO: Reducerは受け取った値をそのまま更新する役割に留めるとスマートになる（副作用を伴うデータ加工はSagaへどうぞ）
 */

export function dataKi2ndRowReducer(
  state = initialState,
  action: DataKi2ndRowAction
): DataKi2ndRowState {
  switch (action.type) {
    case FETCH_DATA_KI_2NDROW_REQUEST:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [action.payload.code]: true,
        },
      };
    case FETCH_DATA_KI_2NDROW_SUCCESS:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [action.payload.code]: false,
        },
        dataKi2ndRow: {
          ...state.dataKi2ndRow,
          [action.payload.code]: action.payload.dataKi2ndRow,
        },
      };
    case RENEW_DATA_KI_ONLY_KI_TAG_AVERAGE_2NDROW:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          kiTagAverage: undefined,
        },
        dataKi2ndRow: {
          ...state.dataKi2ndRow,
          kiTagAverage: undefined,
        },
      };
    case HIDE_DATA_KI_2NDROW:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [action.payload.code]: undefined,
        },
        dataKi2ndRow: {
          ...state.dataKi2ndRow,
          [action.payload.code]: undefined,
        },
      };
    case HIDE_ALL_DATA_KI_2NDROW:
      return initialState;
    case RENEW_DATA_KI_2NDROW:
      return initialState;
    default:
      return state;
  }
}
