import { createSelector } from 'reselect';

import {
  DataChainStoreShuGraph,
  DataChainStoreShuGraphParams,
} from '../../domain/dataChainStoreShuGraph';
import { GRAPH_DATE_TYPE } from '../../domain/dataKiGraph';
import { ShuOption } from '../../domain/shu';

import { SelectOption } from '../../components/molecules/SingleSelect';

import { RootState } from '../../store';
import { getSelectedHallsAndAreas } from '../../utils/getSelectedHallsAndAreas';
import { dataChainStoreShuDataByShuSelector } from './dataChainStoreShu';
import { dataChainStoreShuWholeDataSelector } from './dataChainStoreShuWhole';

// Action Types

const FETCH_DATA_CHAIN_STORE_SHU_GRAPH = 'FETCH_DATA_CHAIN_STORE_SHU_GRAPH' as const;
const FETCH_DATA_CHAIN_STORE_SHU_GRAPH_REQUEST = 'FETCH_DATA_CHAIN_STORE_SHU_GRAPH_REQUEST' as const;
const FETCH_DATA_CHAIN_STORE_SHU_GRAPH_SUCCESS = 'FETCH_DATA_CHAIN_STORE_SHU_GRAPH_SUCCESS' as const;
const SEARCH_DATA_CHAIN_STORE_SHU_GRAPH = 'SEARCH_DATA_CHAIN_STORE_SHU_GRAPH' as const;
const SEARCH_DATA_CHAIN_STORE_SHU_GRAPH_HALL = 'SEARCH_DATA_CHAIN_STORE_SHU_GRAPH_HALL' as const;
const SEARCH_DATA_CHAIN_STORE_SHU_GRAPH_DATE_TYPE = 'SEARCH_DATA_CHAIN_STORE_SHU_GRAPH_DATE_TYPE' as const;
const SEARCH_DATA_CHAIN_STORE_SHU_GRAPH_FIELDS_FOR_LINE = 'SEARCH_DATA_CHAIN_STORE_SHU_GRAPH_FIELDS_FOR_LINE' as const;
const SEARCH_DATA_CHAIN_STORE_SHU_GRAPH_FIELDS_FOR_BAR = 'SEARCH_DATA_CHAIN_STORE_SHU_GRAPH_FIELDS_FOR_BAR' as const;
const HIDE_DATA_CHAIN_STORE_SHU_GRAPH = 'HIDE_DATA_CHAIN_STORE_SHU_GRAPH' as const;
const HIDE_ALL_DATA_CHAIN_STORE_SHU_GRAPH = 'HIDE_ALL_DATA_CHAIN_STORE_SHU_GRAPH' as const;
const RENEW_DATA_CHAIN_STORE_SHU_GRAPH = 'RENEW_DATA_CHAIN_STORE_SHU_GRAPH' as const;

export const DataChainStoreShuGraphActionTypes = {
  FETCH_DATA_CHAIN_STORE_SHU_GRAPH,
  FETCH_DATA_CHAIN_STORE_SHU_GRAPH_REQUEST,
  FETCH_DATA_CHAIN_STORE_SHU_GRAPH_SUCCESS,
  SEARCH_DATA_CHAIN_STORE_SHU_GRAPH,
  SEARCH_DATA_CHAIN_STORE_SHU_GRAPH_HALL,
  SEARCH_DATA_CHAIN_STORE_SHU_GRAPH_DATE_TYPE,
  SEARCH_DATA_CHAIN_STORE_SHU_GRAPH_FIELDS_FOR_LINE,
  SEARCH_DATA_CHAIN_STORE_SHU_GRAPH_FIELDS_FOR_BAR,
  HIDE_DATA_CHAIN_STORE_SHU_GRAPH,
  HIDE_ALL_DATA_CHAIN_STORE_SHU_GRAPH,
  RENEW_DATA_CHAIN_STORE_SHU_GRAPH,
};

// Action Creators

/**
 * 推移グラフ（店舗比較）データを取得する
 * @param shuCode 表示先テーブルの種別コード（店舗全体実績時:'null'）
 * @param params 推移グラフ（店舗比較）の検索条件
 */
function fetchDataChainStoreShuGraphAction(
  shuCode: string,
  params: DataChainStoreShuGraphParams
) {
  return {
    type: FETCH_DATA_CHAIN_STORE_SHU_GRAPH,
    payload: { params, shuCode },
  };
}

/**
 * 推移グラフ（店舗比較）取得前に実行する
 * @param shuCode 表示先テーブルの種別コード（店舗全体実績時:'null'）
 */
function fetchDataChainStoreShuGraphRequestAction(shuCode: string) {
  return {
    type: FETCH_DATA_CHAIN_STORE_SHU_GRAPH_REQUEST,
    payload: { shuCode },
  };
}

/**
 * 推移グラフ（店舗比較）データ取得完了時データを登録する
 * @param shuCode 表示先テーブルの種別コード（店舗全体実績時:'null'）
 * @param dataChainStoreShuGraph 取得したグラフデータ
 */
function fetchDataChainStoreShuGraphSuccessAction(
  shuCode: string,
  dataChainStoreShuGraph: DataChainStoreShuGraph
) {
  return {
    type: FETCH_DATA_CHAIN_STORE_SHU_GRAPH_SUCCESS,
    payload: { dataChainStoreShuGraph, shuCode },
  };
}

/**
 * 選択した店舗の推移グラフ（店舗比較）を表示する
 * @param selectOption 表示する店舗コードorエリアコード
 * @param shuOption 種別データ（空の場合:店舗全体実績）
 */
function searchDataChainStoreShuGraphAction(
  selectOption: Pick<SelectOption, 'type' | 'value'>,
  shuOption?: ShuOption
) {
  return {
    type: SEARCH_DATA_CHAIN_STORE_SHU_GRAPH,
    payload: { selectOption, shuOption },
  };
}

/**
 * 指定した店舗でグラフを絞り込む（店舗選択フォーム用）
 * @param selectOption 表示する店舗コードorエリアコード
 * @param shuOption 種別データ（空の場合:店舗全体実績）
 */
function searchDataChainStoreShuGraphHallAction(
  selectOption: Pick<SelectOption, 'type' | 'value'>,
  shuOption?: ShuOption
) {
  return {
    type: SEARCH_DATA_CHAIN_STORE_SHU_GRAPH_HALL,
    payload: { selectOption, shuOption },
  };
}

/**
 * 指定した期間で推移グラフ（店舗比較）データを再取得
 * @param dateType 変更後の期間
 * @param shuOption 種別データ（空の場合:店舗全体実績）
 */
function searchDataChainStoreShuGraphDateTypeAction(
  dateType: string,
  shuOption?: ShuOption
) {
  return {
    type: SEARCH_DATA_CHAIN_STORE_SHU_GRAPH_DATE_TYPE,
    payload: { dateType, shuOption },
  };
}

/**
 * 指定した棒グラフのグラフ項目で推移グラフ（店舗比較）データを再取得
 * @param fields 棒グラフのグラフ項目
 * @param shuOption 種別データ（空の場合:店舗全体実績）
 */
function searchDataChainStoreShuGraphFieldsForBarAction(
  fields: string[],
  shuOption?: ShuOption
) {
  return {
    type: SEARCH_DATA_CHAIN_STORE_SHU_GRAPH_FIELDS_FOR_BAR,
    payload: { fields, shuOption },
  };
}

/**
 * 指定した線グラフのグラフ項目で推移グラフ（店舗比較）データを再取得
 * @param fields 線グラフのグラフ項目
 * @param shuOption 種別データ（空の場合:店舗全体実績）
 */
function searchDataChainStoreShuGraphFieldsForLineAction(
  fields: string[],
  shuOption?: ShuOption
) {
  return {
    type: SEARCH_DATA_CHAIN_STORE_SHU_GRAPH_FIELDS_FOR_LINE,
    payload: { fields, shuOption },
  };
}

/**
 * 指定した推移グラフ（店舗比較）データを破棄する
 * @param shuCode 表示先テーブルの種別コード（店舗全体実績時:'null'）
 */
function hideDataChainStoreShuGraphAction(shuCode: string) {
  return {
    type: HIDE_DATA_CHAIN_STORE_SHU_GRAPH,
    payload: { shuCode },
  };
}

/**
 * 推移グラフ（店舗比較）の全てのデータを破棄する
 */
function hideAllDataChainStoreShuGraphAction() {
  return {
    type: HIDE_ALL_DATA_CHAIN_STORE_SHU_GRAPH,
  };
}

/**
 * 初期化
 */
function renewDataChainStoreShuGraphAction() {
  return {
    type: RENEW_DATA_CHAIN_STORE_SHU_GRAPH,
  };
}

export const DataChainStoreShuGraphActionCreators = {
  fetchDataChainStoreShuGraphAction,
  fetchDataChainStoreShuGraphRequestAction,
  fetchDataChainStoreShuGraphSuccessAction,
  searchDataChainStoreShuGraphAction,
  searchDataChainStoreShuGraphHallAction,
  searchDataChainStoreShuGraphDateTypeAction,
  searchDataChainStoreShuGraphFieldsForBarAction,
  searchDataChainStoreShuGraphFieldsForLineAction,
  hideDataChainStoreShuGraphAction,
  hideAllDataChainStoreShuGraphAction,
  renewDataChainStoreShuGraphAction,
};

// Actions

export type FetchDataChainStoreShuGraphAction = ReturnType<
  typeof fetchDataChainStoreShuGraphAction
>;
export type SearchDataChainStoreShuGraphAction = ReturnType<
  typeof searchDataChainStoreShuGraphAction
>;
export type SearchDataChainStoreShuGraphHallAction = ReturnType<
  typeof searchDataChainStoreShuGraphHallAction
>;
export type SearchDataChainStoreShuGraphDateTypeAction = ReturnType<
  typeof searchDataChainStoreShuGraphDateTypeAction
>;
export type SearchDataChainStoreShuGraphFieldsForBarAction = ReturnType<
  typeof searchDataChainStoreShuGraphFieldsForBarAction
>;
export type SearchDataChainStoreShuGraphFieldsForLineAction = ReturnType<
  typeof searchDataChainStoreShuGraphFieldsForLineAction
>;

type DataChainStoreShuGraphAction =
  | FetchDataChainStoreShuGraphAction
  | SearchDataChainStoreShuGraphAction
  | SearchDataChainStoreShuGraphHallAction
  | SearchDataChainStoreShuGraphDateTypeAction
  | SearchDataChainStoreShuGraphFieldsForBarAction
  | SearchDataChainStoreShuGraphFieldsForLineAction
  | ReturnType<typeof fetchDataChainStoreShuGraphRequestAction>
  | ReturnType<typeof fetchDataChainStoreShuGraphSuccessAction>
  | ReturnType<typeof hideDataChainStoreShuGraphAction>
  | ReturnType<typeof hideAllDataChainStoreShuGraphAction>
  | ReturnType<typeof renewDataChainStoreShuGraphAction>;

// State

type DataChainStoreShuGraphState = {
  /**
   * グラフデータ
   */
  dataChainStoreShuGraph: {
    [key: string]: DataChainStoreShuGraph | undefined;
  };
  /**
   * ローディング状態
   */
  isLoading: {
    [key: string]: boolean | undefined;
  };
};

const initialState: DataChainStoreShuGraphState = {
  dataChainStoreShuGraph: {},
  isLoading: {},
};

// Selector

/**
 * 全てのグラフデータを取得する
 * @returns 全てのグラフデータ
 */
const dataChainStoreShuGraphAllSelector = (state: RootState) =>
  state.dataChainStoreShuGraph.dataChainStoreShuGraph;

/**
 * 種別を元にグラフデータを取得する
 * @param shuCode 表示先テーブルの種別コード（店舗全体実績時:'null'）
 * @returns グラフデータ
 */
export const dataChainStoreShuGraphSelector = (shuCode: string) =>
  createSelector(dataChainStoreShuGraphAllSelector, (data) => data[shuCode]);

/**
 * 推移グラフ（店舗比較）の検索条件を取得する
 * @param shuCode 表示先テーブルの種別コード（店舗全体実績時:'null'）
 * @returns 推移グラフ（店舗比較）の検索条件
 */
export const dataChainStoreShuGraphSearchConditionSelector = (
  shuCode: string
) =>
  createSelector(
    dataChainStoreShuGraphSelector(shuCode),
    (data) => data?.setting ?? ({} as DataChainStoreShuGraphParams)
  );

/**
 * 選択された店舗を取得する
 * @param shuCode 表示先テーブルの種別コード（店舗全体実績時:'null'）
 * @returns 選択中の店舗
 */
const dataChainStoreShuGraphHallSelector = (shuCode: string) =>
  createSelector(
    dataChainStoreShuGraphSearchConditionSelector(shuCode),
    (params) => {
      return getSelectedHallsAndAreas(params);
    }
  );

/**
 * 店舗一覧（SelectOption）を取得する
 *
 * エリア集計スイッチの有効時にエリアも表示される
 * 店舗は重複可能性があるため、重複の除外処理を行う
 *
 * @param shuCode 表示先テーブルの種別コード（店舗全体実績時:'null'）
 * @returns 店舗一覧（Selectで使える形式）
 */
export const dataChainStoreShuGraphHallOptionSelector = (shuCode: string) =>
  createSelector(
    [
      dataChainStoreShuDataByShuSelector(shuCode),
      dataChainStoreShuWholeDataSelector,
    ],
    (dataChainStoreShu, dataChainStoreShuWhole) => {
      // 店舗コードがnullの場合は店舗全体実績のテーブルデータを使う
      const tableData =
        shuCode === 'null' ? dataChainStoreShuWhole : dataChainStoreShu;

      // チェックボックスでチェック入れた店舗情報をテーブルのデータから取得
      const result: SelectOption[] = tableData.data.rows
        .map((row) => ({
          name: row.data.at(0)?.value ?? '',
          value: row.queryParameter.value ?? 'null',
          type: row.queryParameter.name,
        }))
        .filter(
          (option, index, self) =>
            index ===
            self.findIndex(
              (selectOption) =>
                selectOption.value === option.value &&
                selectOption.type === option.type
            )
        );

      return result;
    }
  );

/**
 * 絞り込んでいる店舗の一覧（SelectOption)を取得する
 * @param shuCode 表示先テーブルの種別コード（店舗全体実績時:'null'）
 * @returns 絞り込んでいる店舗一覧(Selectで使える形式)
 */
export const dataChainStoreShuGraphSelectedHallListOptionSelector = (
  shuCode: string
) =>
  createSelector(
    [
      dataChainStoreShuGraphHallSelector(shuCode),
      dataChainStoreShuGraphHallOptionSelector(shuCode),
    ],
    (selectedHallAreaList, hallsAreasList) => {
      const result =
        selectedHallAreaList.value === 'null'
          ? {
              name: '店舗平均',
              value: 'null',
              type: 'halls',
            }
          : hallsAreasList.find((v) =>
              selectedHallAreaList.value.includes(v.value)
            );

      if (result == null) {
        // エリアと店舗の不整合が発生したときには店舗平均を表示させる
        // エラーをスローした場合、ErrorBoundaryが表示されてお気に入りの状態が維持されなくなるため
        // sentry: https://b2defa61f433.sentry.io/issues/5046843818/?environment=prd&project=4506188805242880&query=is%3Aunresolved&referrer=issue-stream&sort=user&statsPeriod=7d&stream_index=6
        return {
          name: '店舗平均',
          value: 'null',
          type: 'halls',
        };
      }

      return result;
    }
  );

/**
 * 現在選択されている期間を取得する
 * @param shuCode 表示先テーブルの種別コード（店舗全体実績時:'null'）
 * @returns 現在選択されている期間
 */
export const dataChainStoreShuGraphDateTypeSelector = (shuCode: string) =>
  createSelector(
    dataChainStoreShuGraphSearchConditionSelector(shuCode),
    (params) => params.dateType ?? GRAPH_DATE_TYPE.DAILY
  );

/**
 * 現在選択されている棒グラフのグラフ項目を取得する
 * @param shuCode 表示先テーブルの種別コード（店舗全体実績時:'null'）
 * @returns 現在選択されている棒グラフのグラフ項目
 */
export const dataChainStoreShuGraphFieldsForBarSelector = (shuCode: string) =>
  createSelector(
    dataChainStoreShuGraphSearchConditionSelector(shuCode),
    (params) => params.fieldsForBar ?? []
  );

/**
 * 現在選択されている線グラフのグラフ項目を取得する
 * @param shuCode 表示先テーブルの種別コード（店舗全体実績時:'null'）
 * @returns 現在選択されている線グラフのグラフ項目
 */
export const dataChainStoreShuGraphFieldsForLineSelector = (shuCode: string) =>
  createSelector(
    dataChainStoreShuGraphSearchConditionSelector(shuCode),
    (params) => params.fieldsForLine ?? []
  );

/**
 * 全てのローディング状態を取得する
 * @returns 全てのローディング状態
 */
const dataChainStoreShuGraphLoadingAllSelector = (state: RootState) =>
  state.dataChainStoreShuGraph.isLoading;

/**
 * 種別を元にグラフのローディング状態を取得する
 * @param shuCode 表示先テーブルの種別コード（店舗全体実績時:'null'）
 * @returns ローディング状態
 */
export const dataChainStoreShuGraphLoadingSelector = (shuCode: string) =>
  createSelector(
    dataChainStoreShuGraphLoadingAllSelector,
    (loading) => loading[shuCode] ?? false
  );

/**
 * 種別実績 推移グラフ（店舗比較）の現在の検索条件を取得する（お気に入り保存用）
 * @returns 現在の種別実績 推移グラフ（店舗比較）の検索条件
 */
export const dataChainStoreShuGraphSettingSelector = createSelector(
  dataChainStoreShuGraphAllSelector,
  (data) => {
    return Object.fromEntries(
      Object.entries(data).map((entry) => {
        const [key, value] = entry;
        return [key, value?.setting];
      })
    );
  }
);

export function dataChainStoreShuGraphReducer(
  state = initialState,
  action: DataChainStoreShuGraphAction
): DataChainStoreShuGraphState {
  switch (action.type) {
    case FETCH_DATA_CHAIN_STORE_SHU_GRAPH_REQUEST:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [action.payload.shuCode]: true,
        },
      };
    case FETCH_DATA_CHAIN_STORE_SHU_GRAPH_SUCCESS:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [action.payload.shuCode]: false,
        },
        dataChainStoreShuGraph: {
          ...state.dataChainStoreShuGraph,
          [action.payload.shuCode]: action.payload.dataChainStoreShuGraph,
        },
      };
    case HIDE_DATA_CHAIN_STORE_SHU_GRAPH:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [action.payload.shuCode]: undefined,
        },
        dataChainStoreShuGraph: {
          ...state.dataChainStoreShuGraph,
          [action.payload.shuCode]: undefined,
        },
      };
    case HIDE_ALL_DATA_CHAIN_STORE_SHU_GRAPH:
      return {
        ...state,
        isLoading: initialState.isLoading,
        dataChainStoreShuGraph: initialState.dataChainStoreShuGraph,
      };
    case RENEW_DATA_CHAIN_STORE_SHU_GRAPH:
      return initialState;
    default:
      return state;
  }
}
