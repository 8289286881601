/**
 * 配列の要素のインデックスを移動させて新しい配列を返す(DragDrop)
 * @param array 元の配列
 * @param index 移動したい要素の位置
 * @param at 移動後の位置
 * @returns 移動後の配列
 */
export const moveIndex = <T>(array: T[], index: number, at: number) => {
  if (index === at || index > array.length - 1 || at > array.length - 1) {
    return array;
  }
  const value = array[index];
  const tail = array.slice(index + 1);

  const newArray = [...array];
  newArray.splice(index);
  Array.prototype.push.apply(newArray, tail);
  newArray.splice(at, 0, value);

  return newArray;
};
