import { ConvertRowsToFilteredRows } from '../../components/organisms/TableHeaderSubMenu/ConvertRowsToFilteredRows';

import { DataDepreciationResponse } from './types';

export const dataDepreciationFilteredByNumber = (
  dataDepreciation: DataDepreciationResponse,
  numberFilter:
    | {
        [field: string]: {
          minimumNumber: number | undefined;
          maximumNumber: number | undefined;
        };
      }
    | undefined
): DataDepreciationResponse => {
  if (numberFilter == null) {
    return dataDepreciation;
  }
  const { filteredRows: rows } = ConvertRowsToFilteredRows(
    numberFilter,
    dataDepreciation.data.columns,
    dataDepreciation.data.rows
  );

  return {
    ...dataDepreciation,
    data: {
      ...dataDepreciation.data,
      rows,
    },
  };
};
