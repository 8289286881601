import type { ComponentProps, FC } from 'react';

type Props = ComponentProps<'svg'>;

export const IncreaseTableCandidatesIcon: FC<Props> = (props) => (
  <svg
    width="18"
    height="21"
    viewBox="0 0 18 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.2503 0H5.74976C5.28601 0.00123765 4.84154 0.225488 4.51362 0.62368C4.1857 1.02187 4.00102 1.56158 4 2.12471V14.8753C4.00153 15.4382 4.18637 15.9776 4.51418 16.3757C4.842 16.7737 5.28617 16.9981 5.74976 17H16.2503C16.7138 16.9981 17.158 16.7737 17.4858 16.3757C17.8136 15.9776 17.9985 15.4382 18 14.8753V2.12471C17.999 1.56158 17.8143 1.02187 17.4864 0.62368C17.1585 0.225488 16.714 0.00123765 16.2503 0ZM16.2503 14.8753H5.74976V2.12471H16.2503V14.8753Z"
      fill="black"
      fillOpacity="0.54"
    />
    <path
      d="M10.2427 12.2897H12.0496V9.04949H15.2921V7.2402H12.0496V4H10.2427V7.2402H7V9.04949H10.2427V12.2897Z"
      fill="black"
      fillOpacity="0.54"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 6.08333V5H1.92857V6.08333H0ZM0 10.4167V8.25H1.92857V10.4167H0ZM0 14.75V12.5833H1.92857V14.75H0ZM0 18V16.9167H1.92857V18C1.92857 18.1374 1.95477 18.266 2.00136 18.3825L0.219891 19.1486C0.0778918 18.7935 0 18.4049 0 18ZM2.89286 21C2.50239 21 2.12768 20.9192 1.78525 20.772L2.524 18.9245C2.63636 18.9728 2.76033 19 2.89286 19H4.21875V21H2.89286ZM9.52232 21H6.87054V19H9.52232V21ZM13.5 21H12.1741V19H13.5V21Z"
      fill="black"
      fillOpacity="0.54"
    />
  </svg>
);
