import { AxiosResponse } from 'axios';
import { call, fork, put, select, takeEvery } from 'redux-saga/effects';

import { modeErrorMessages } from '../domain/mode/errors';
import { ModeDataKiList } from '../domain/mode/types';

import {
  DataModeKiListActionCreators,
  DataModeKiListActionTypes,
  FetchDataModeKiListAction,
  dataModeKiListDataSelector,
} from '../redux/server/dataModeKiList';
import { ErrorActionCreators } from '../redux/ui/error';
import { Api, buildConfig } from '../utils/api';
import { handleErrorSaga } from './errorSaga';

/**
 * モード別集計の機種リストを取得する
 * @param api AxiosInstance
 * @param action Action
 */
export function* fetchDataModeKiListSaga(
  api: Api,
  action: FetchDataModeKiListAction
) {
  try {
    yield put(DataModeKiListActionCreators.fetchDataModeKiListRequestAction());
    const response: AxiosResponse<ModeDataKiList> = yield call(
      api.get,
      '/data/mode/kiList',
      buildConfig(action.payload.params)
    );
    yield put(
      DataModeKiListActionCreators.fetchDataModeKiListSuccessAction(
        response.data
      )
    );
  } catch (error: unknown) {
    yield put(DataModeKiListActionCreators.renewDataModeKiListAction());
    yield fork(handleErrorSaga, error);
  }
}

function* validateKiListSaga() {
  const data: ModeDataKiList = yield select(dataModeKiListDataSelector);
  if (data.data.kiList.length === 0) {
    yield put(ErrorActionCreators.setError(modeErrorMessages.kiList));
  }
}

function* handleFetchModeKiListSaga(api: Api) {
  yield takeEvery(
    DataModeKiListActionTypes.FETCH_DATA_MODE_KILIST,
    fetchDataModeKiListSaga,
    api
  );
}

function* handleFetchSuccessSaga() {
  yield takeEvery(
    DataModeKiListActionTypes.FETCH_DATA_MODE_KILIST_SUCCESS,
    validateKiListSaga
  );
}

export function* dataModeKiListSagas(context: { api: Api }) {
  yield fork(handleFetchModeKiListSaga, context.api);
  yield fork(handleFetchSuccessSaga);
}
