import { AxiosResponse } from 'axios';
import { call, fork, put, take, takeEvery } from 'redux-saga/effects';

import { SettingsCommentsMonthToDate } from '../domain/settingsComments';

import {
  FetchSettingsCommentsMonthToDateAction,
  PostSettingsCommentsAction,
  PostSettingsCommentsMonthToDateAction,
  SettingsCommentsMonthToDateActionCreators,
  SettingsCommentsMonthToDateActionTypes,
} from '../redux/server/settingsCommentsMonthToDate';
import { Api } from '../utils/api';
import { handleErrorSaga } from './errorSaga';

/**
 * コメント投稿
 * @param api AxiosInstance
 */
function* postSettingsCommentsSaga(
  api: Api,
  action: PostSettingsCommentsAction
) {
  const { content, date, hallCode } = action.payload.params;

  try {
    yield put(
      SettingsCommentsMonthToDateActionCreators.postSettingsCommentsRequestAction()
    );
    yield call(api.post, `/settings/comments/${hallCode}/${date}`, { content });
    yield put(
      SettingsCommentsMonthToDateActionCreators.postSettingsCommentsSuccessAction()
    );
  } catch (error: unknown) {
    yield put(
      SettingsCommentsMonthToDateActionCreators.postSettingsCommentsFailureAction(
        'コメントの投稿に失敗しました。'
      )
    );
  }
}

/**
 * コメントを投稿し、成功時に単月更新する
 */
function* postSettingsCommentsMonthToDateSaga(
  action: PostSettingsCommentsMonthToDateAction
) {
  const { params } = action.payload;

  yield put(
    SettingsCommentsMonthToDateActionCreators.postSettingsCommentsAction(params)
  );
  yield take(
    SettingsCommentsMonthToDateActionTypes.POST_SETTINGS_COMMENTS_SUCCESS
  );
  yield put(
    SettingsCommentsMonthToDateActionCreators.fetchSettingsCommentsMonthToDateAction(
      {
        year: params.date.slice(0, 4),
        month: params.date.slice(5, 7),
        hallCode: params.hallCode,
      }
    )
  );
}

/**
 * 単月コメント取得
 * @param api AxiosInstance
 */
function* fetchSettingsCommentsMonthToDateSaga(
  api: Api,
  action: FetchSettingsCommentsMonthToDateAction
) {
  const {
    pathParams: { year, month, hallCode },
  } = action.payload;

  try {
    yield put(
      SettingsCommentsMonthToDateActionCreators.fetchSettingsCommentsMonthToDateRequestAction()
    );
    const response: AxiosResponse<SettingsCommentsMonthToDate> = yield call(
      api.get,
      `/settings/comments/${year}/${month}/${hallCode}`
    );
    yield put(
      SettingsCommentsMonthToDateActionCreators.fetchSettingsCommentsMonthToDateSuccessAction(
        response.data
      )
    );
  } catch (error: unknown) {
    yield put(
      SettingsCommentsMonthToDateActionCreators.renewSettingsCommentsMonthToDateAction()
    );
    yield fork(handleErrorSaga, error);
  }
}

function* handleFetchSettingsCommentsMonthToDateSaga(api: Api) {
  yield takeEvery(
    SettingsCommentsMonthToDateActionTypes.FETCH_SETTINGS_COMMENTS_MONTH_TO_DATE,
    fetchSettingsCommentsMonthToDateSaga,
    api
  );
}

function* handlePostSettingsCommentsMonthToDateSaga(api: Api) {
  yield takeEvery(
    SettingsCommentsMonthToDateActionTypes.POST_SETTINGS_COMMENTS,
    postSettingsCommentsSaga,
    api
  );

  yield takeEvery(
    SettingsCommentsMonthToDateActionTypes.POST_SETTINGS_COMMENTS_MONTH_TO_DATE,
    postSettingsCommentsMonthToDateSaga
  );
}

export function* settingsCommentsMonthToDateSagas(context: { api: Api }) {
  yield fork(handlePostSettingsCommentsMonthToDateSaga, context.api);
  yield fork(handleFetchSettingsCommentsMonthToDateSaga, context.api);
}
