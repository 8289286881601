import { AxiosResponse } from 'axios';
import { call, fork, put, takeLatest } from 'redux-saga/effects';

import type {
  DailyComment,
  DailyCommentResponse,
  DailyCommentsResponse,
} from '../domain/dataHallDailyComments';

import {
  DataHallDailyCommentsActionCreators,
  DataHallDailyCommentsActionTypes,
  DeleteDataHallDailyCommentsRequestAction,
  FetchDataHallDailyCommentsRequestAction,
  PostDataHallDailyCommentsRequestAction,
  PutDataHallDailyCommentsRequestAction,
} from '../redux/server/dataHallDailyComments';
import { Api } from '../utils/api';
import { handleErrorSaga } from './errorSaga';

export const convertResponse = (data: DailyCommentResponse[]) => {
  return data?.reduce((accum, curr) => {
    const hallCode = curr.hall;
    const { id: userId, name } = curr.author;
    const temp: DailyComment = {
      ...curr,
      userId,
      hallCode,
      author: name,
    };
    if (accum[hallCode]) {
      return {
        ...accum,
        [hallCode]: [...accum[hallCode], temp],
      };
    }
    return {
      ...accum,
      [hallCode]: [temp],
    };
  }, {} as DailyCommentsResponse);
};

/**
 * @param action Action
 */
export function* fetchDataHallDailyCommentsSaga(
  api: Api,
  action: FetchDataHallDailyCommentsRequestAction
) {
  try {
    const response: AxiosResponse<DailyCommentResponse[]> = yield call(
      api.get,
      `/settings/comments/${action.payload.date}`
    );

    // responseの型がおかしいので変換する
    const data = convertResponse(response.data);

    yield put(
      DataHallDailyCommentsActionCreators.fetchDataHallDailyCommentsSuccessAction(
        { date: action.payload.date, data }
      )
    );
  } catch (error: unknown) {
    yield put(
      DataHallDailyCommentsActionCreators.renewDataHallDailyCommentsAction()
    );
    yield fork(handleErrorSaga, error);
  }
}

export function* postDataHallDailyCommentsSaga(
  api: Api,
  action: PostDataHallDailyCommentsRequestAction
) {
  const { hallCode, date, content } = action.payload;
  try {
    yield call(api.post, `/settings/comments/${hallCode}/${date}`, { content });

    yield put(
      DataHallDailyCommentsActionCreators.fetchDataHallDailyCommentsRequestAction(
        action.payload.date
      )
    );
  } catch (error: unknown) {
    yield put(
      DataHallDailyCommentsActionCreators.renewDataHallDailyCommentsAction()
    );
    yield fork(handleErrorSaga, error);
  }
}

export function* putDataHallDailyCommentsSaga(
  api: Api,
  action: PutDataHallDailyCommentsRequestAction
) {
  const { id, content } = action.payload;
  try {
    yield call(api.put, `/settings/comments/${id}`, { content });

    yield put(
      DataHallDailyCommentsActionCreators.putDataHallDailyCommentsSuccessAction(
        action.payload
      )
    );
  } catch (error: unknown) {
    yield put(
      DataHallDailyCommentsActionCreators.renewDataHallDailyCommentsAction()
    );
    yield fork(handleErrorSaga, error);
  }
}

export function* deleteDataHallDailyCommentsSaga(
  api: Api,
  action: DeleteDataHallDailyCommentsRequestAction
) {
  const { id, date, hallCode } = action.payload;
  try {
    yield call(api.delete, `/settings/comments/${id}`);

    yield put(
      DataHallDailyCommentsActionCreators.deleteDataHallDailyCommentsSuccessAction(
        id,
        date,
        hallCode
      )
    );
  } catch (error: unknown) {
    yield put(
      DataHallDailyCommentsActionCreators.renewDataHallDailyCommentsAction()
    );
    yield fork(handleErrorSaga, error);
  }
}

/**
 * @param api AxiosInstance
 */
function* handleFetchDataHallDailyCommentsRequestSaga(api: Api) {
  yield takeLatest(
    DataHallDailyCommentsActionTypes.FETCH_DATA_HALL_DAILY_COMMENTS_REQUEST,
    fetchDataHallDailyCommentsSaga,
    api
  );
}

function* handlePostDataHallDailyCommentsRequestSaga(api: Api) {
  yield takeLatest(
    DataHallDailyCommentsActionTypes.POST_DATA_HALL_DAILY_COMMENTS_REQUEST,
    postDataHallDailyCommentsSaga,
    api
  );
}

function* handlePutDataHallDailyCommentsRequestSaga(api: Api) {
  yield takeLatest(
    DataHallDailyCommentsActionTypes.PUT_DATA_HALL_DAILY_COMMENTS_REQUEST,
    putDataHallDailyCommentsSaga,
    api
  );
}

function* handleDeleteDataHallDailyCommentsRequestSaga(api: Api) {
  yield takeLatest(
    DataHallDailyCommentsActionTypes.DELETE_DATA_HALL_DAILY_COMMENTS_REQUEST,
    deleteDataHallDailyCommentsSaga,
    api
  );
}
/**
 */
export function* dataHallDailyCommentsSagas(context: { api: Api }) {
  yield fork(handleFetchDataHallDailyCommentsRequestSaga, context.api);
  yield fork(handlePostDataHallDailyCommentsRequestSaga, context.api);
  yield fork(handlePutDataHallDailyCommentsRequestSaga, context.api);
  yield fork(handleDeleteDataHallDailyCommentsRequestSaga, context.api);
}
