import { createSelector } from 'reselect';

import {
  DataModelTransitionParams,
  ModelTransitiveDateType,
} from '../../domain/dataModelTransition';
import { SharedUser } from '../../domain/favorites';
import { MarkingOption } from '../../domain/marking';
import { ModelTransitionReportsSettingDateRangeParams } from '../../domain/modelTransitionReportsSettingDateRangeParams';
import { OrderType } from '../../domain/schemas';
import { ShuOption } from '../../domain/shu';

import { RootState } from '../../store';
import { dataModelTransitionDefaultValue } from '../../utils/dataModelTransitionDefaultValue';
import { TransitiveDateRange } from '../../utils/transitiveDateRange';
import { favoritesSelector } from './settingsFavorites';

/**
 * Action Types
 *
 * プレフィックスの意味
 * INIT: 初回のデータを取得する（最初に画面を開いたときだけ取得）
 * SEARCH: ReducerでStateの変更はしないがデータを取得する（検索ボタン等Sagaを発火させたい）
 * CHANGE: Stateを変更してデータも取得する
 * SELECT: Stateを変更する（データ取得は行わない）
 * RESET: Stateを初期状態に戻す（データ取得は行わない）
 * SAVE: 上書き保存する（お気に入り用）
 * SAVE_AS: 新規保存する（お気に入り用）
 */

const INIT_MODEL_TRANSITION_REPORTS = 'INIT_MODEL_TRANSITION_REPORTS' as const;

const SEARCH_MODEL_TRANSITION_REPORTS = 'SEARCH_MODEL_TRANSITION_REPORTS' as const;
const SEARCH_RESET_MODEL_TRANSITION_REPORTS = 'SEARCH_RESET_MODEL_TRANSITION_REPORTS' as const;
const SEARCH_MODEL_TRANSITION_REPORTS_SORT_FOR_HEADER = 'SEARCH_MODEL_TRANSITION_REPORTS_SORT_FOR_HEADER' as const;
const SEARCH_MODEL_TRANSITION_REPORTS_SORT_FOR_TRANSITIVE_FIELD = 'SEARCH_MODEL_TRANSITION_REPORTS_SORT_FOR_TRANSITIVE_FIELD' as const;
const SEARCH_MODEL_TRANSITION_REPORTS_MARKING = 'SEARCH_MODEL_TRANSITION_REPORTS_MARKING' as const;
const SEARCH_MODEL_TRANSITION_REPORTS_FIELDS = 'SEARCH_MODEL_TRANSITION_REPORTS_FIELDS' as const;

const CHANGE_MODEL_TRANSITION_REPORTS_FAVORITE = 'CHANGE_MODEL_TRANSITION_REPORTS_FAVORITE' as const;

const SELECT_MODEL_TRANSITION_REPORTS_SEARCH_CONDITION = 'SELECT_MODEL_TRANSITION_REPORTS_SEARCH_CONDITION' as const;
const SELECT_MODEL_TRANSITION_REPORTS_SHU = 'SELECT_MODEL_TRANSITION_REPORTS_SHU' as const;
const SELECT_MODEL_TRANSITION_REPORTS_CURRENT_SHU = 'SELECT_MODEL_TRANSITION_REPORTS_CURRENT_SHU' as const;
const CHANGE_MODEL_TRANSITION_REPORTS_CURRENT_SHU = 'CHANGE_MODEL_TRANSITION_REPORTS_CURRENT_SHU' as const;
const SELECT_MODEL_TRANSITION_REPORTS_KI_LIST = 'SELECT_MODEL_TRANSITION_REPORTS_KI_LIST' as const;
const SELECT_MODEL_TRANSITION_REPORTS_SIS_LIST = 'SELECT_MODEL_TRANSITION_REPORTS_SIS_LIST' as const;
const SELECT_MODEL_TRANSITION_REPORTS_DATE_RANGE_PARAMS = 'SELECT_MODEL_TRANSITION_REPORTS_DATE_RANGE_PARAMS' as const;
const SELECT_MODEL_TRANSITION_REPORTS_COLUMNS_FOR_TRANSITION_ORDER = 'SELECT_MODEL_TRANSITION_REPORTS_COLUMNS_FOR_TRANSITION_ORDER' as const;

const RESET_MODEL_TRANSITION_REPORTS_SEARCH_CONDITION = 'RESET_MODEL_TRANSITION_REPORTS_SEARCH_CONDITION' as const;
const RESET_MODEL_TRANSITION_REPORTS_SHU = 'RESET_MODEL_TRANSITION_REPORTS_SHU' as const;
const RESET_MODEL_TRANSITION_REPORTS_DATE_RANGE_PARAMS = 'RESET_MODEL_TRANSITION_REPORTS_DATE_RANGE_PARAMS' as const;
const RESET_MODEL_RANSITION_REPORTS_FAVORITE_TO_DEFAULT = 'RESET_MODEL_RANSITION_REPORTS_FAVORITE_TO_DEFAULT' as const;

const SAVE_MODEL_TRANSITION_REPORTS_FAVORITE = 'SAVE_MODEL_TRANSITION_REPORTS_FAVORITE' as const;
const SAVE_AS_MODEL_TRANSITION_REPORTS_FAVORITE = 'SAVE_AS_MODEL_TRANSITION_REPORTS_FAVORITE' as const;
const SELECT_MODEL_TRANSITION_REPORTS_COLUMNS_FOR_NON_TRANSITION_ORDER = 'SELECT_MODEL_TRANSITION_REPORTS_COLUMNS_FOR_NON_TRANSITION_ORDER' as const;

const SELECT_MODEL_TRANSITION_REPORTS_MODEL_NAME_FILTER = 'SELECT_MODEL_TRANSITION_REPORTS_MODEL_NAME_FILTER' as const;

const SEARCH_MODEL_TRANSITION_REPORTS_DATE_RANGE_SLIDE = 'SEARCH_MODEL_TRANSITION_REPORTS_DATE_RANGE_SLIDE' as const;

const ADD_MODEL_TRANSITION_REPORTS_TABLE_FILTER = 'ADD_MODEL_TRANSITION_REPORTS_TABLE_FILTER' as const;
const REMOVE_MODEL_TRANSITION_REPORTS_TABLE_FILTER = 'REMOVE_MODEL_TRANSITION_REPORTS_TABLE_FILTER' as const;
const APPLY_MODEL_TRANSITION_REPORTS_TABLE_FILTER = 'APPLY_MODEL_TRANSITION_REPORTS_TABLE_FILTER' as const;
const RESET_MODEL_TRANSITION_REPORTS_TABLE_FILTER = 'RESET_MODEL_TRANSITION_REPORTS_TABLE_FILTER' as const;
const SELECT_MODEL_TRANSITION_REPORTS_CHECKED_KI = 'SELECT_MODEL_TRANSITION_REPORTS_CHECKED_KI' as const;
const SELECT_MODEL_TRANSITION_REPORTS_CHECKED_KI_TABLE = 'SELECT_MODEL_TRANSITION_REPORTS_CHECKED_KI_TABLE' as const;
const SELECT_MODEL_TRANSITION_SHOW_GRAPH_NUMBER_LABEL = 'SELECT_MODEL_TRANSITION_SHOW_GRAPH_NUMBER_LABEL' as const;
const CHANGE_MODEL_TRANSITION_REPORTS_CURRENT_HALLS = 'CHANGE_MODEL_TRANSITION_REPORTS_CURRENT_HALLS' as const;
const SELECT_MODEL_TRANSITION_REPORTS_CURRENT_HALLS = 'SELECT_MODEL_TRANSITION_REPORTS_CURRENT_HALLS' as const;
const SELECT_MODEL_TRANSITION_REPORTS_IS_HEATMAP_ENABLED = 'SELECT_MODEL_TRANSITION_REPORTS_IS_HEATMAP_ENABLED' as const;
const SELECT_MODEL_TRANSITION_REPORTS_CURRENT_FIELD = 'SELECT_MODEL_TRANSITION_REPORTS_CURRENT_FIELD' as const;
const CREATE_MODEL_TRANSITION_REPORTS_SHORTENED_URL = 'CREATE_MODEL_TRANSITION_REPORTS_SHORTENED_URL' as const;

export const ModelTransitionReportsSettingActionTypes = {
  INIT_MODEL_TRANSITION_REPORTS,
  SEARCH_MODEL_TRANSITION_REPORTS,
  SEARCH_RESET_MODEL_TRANSITION_REPORTS,
  SEARCH_MODEL_TRANSITION_REPORTS_SORT_FOR_HEADER,
  SEARCH_MODEL_TRANSITION_REPORTS_SORT_FOR_TRANSITIVE_FIELD,
  SEARCH_MODEL_TRANSITION_REPORTS_MARKING,
  SEARCH_MODEL_TRANSITION_REPORTS_FIELDS,
  CHANGE_MODEL_TRANSITION_REPORTS_FAVORITE,
  SELECT_MODEL_TRANSITION_REPORTS_SEARCH_CONDITION,
  SELECT_MODEL_TRANSITION_REPORTS_SHU,
  SELECT_MODEL_TRANSITION_REPORTS_CURRENT_SHU,
  CHANGE_MODEL_TRANSITION_REPORTS_CURRENT_SHU,
  SELECT_MODEL_TRANSITION_REPORTS_KI_LIST,
  SELECT_MODEL_TRANSITION_REPORTS_SIS_LIST,
  SELECT_MODEL_TRANSITION_REPORTS_DATE_RANGE_PARAMS,
  SELECT_MODEL_TRANSITION_REPORTS_COLUMNS_FOR_TRANSITION_ORDER,
  RESET_MODEL_TRANSITION_REPORTS_SEARCH_CONDITION,
  RESET_MODEL_TRANSITION_REPORTS_SHU,
  RESET_MODEL_TRANSITION_REPORTS_DATE_RANGE_PARAMS,
  RESET_MODEL_RANSITION_REPORTS_FAVORITE_TO_DEFAULT,
  SAVE_MODEL_TRANSITION_REPORTS_FAVORITE,
  SAVE_AS_MODEL_TRANSITION_REPORTS_FAVORITE,
  SELECT_MODEL_TRANSITION_REPORTS_COLUMNS_FOR_NON_TRANSITION_ORDER,
  SELECT_MODEL_TRANSITION_REPORTS_MODEL_NAME_FILTER,
  SEARCH_MODEL_TRANSITION_REPORTS_DATE_RANGE_SLIDE,
  ADD_MODEL_TRANSITION_REPORTS_TABLE_FILTER,
  REMOVE_MODEL_TRANSITION_REPORTS_TABLE_FILTER,
  APPLY_MODEL_TRANSITION_REPORTS_TABLE_FILTER,
  RESET_MODEL_TRANSITION_REPORTS_TABLE_FILTER,
  SELECT_MODEL_TRANSITION_REPORTS_CHECKED_KI,
  SELECT_MODEL_TRANSITION_REPORTS_CHECKED_KI_TABLE,
  SELECT_MODEL_TRANSITION_SHOW_GRAPH_NUMBER_LABEL,
  CHANGE_MODEL_TRANSITION_REPORTS_CURRENT_HALLS,
  SELECT_MODEL_TRANSITION_REPORTS_CURRENT_HALLS,
  SELECT_MODEL_TRANSITION_REPORTS_CURRENT_FIELD,
  CREATE_MODEL_TRANSITION_REPORTS_SHORTENED_URL,
};

/**
 * Action Creators
 */

/**
 * 初回データを取得する（初回表示時）
 */
function initModelTransitionReportsAction() {
  return {
    type: INIT_MODEL_TRANSITION_REPORTS,
  };
}

/**
 * 指定した検索条件でデータを取得する（検索ボタン押下時）
 * @param params 検索条件
 * @param selectedShu 選択中の種別
 * @param selectedDateRange 選択中の期間
 */
function searchModelTransitionReportsAction(
  params: DataModelTransitionParams,
  selectedShu: ShuOption[],
  selectedSis: string[],
  selectedDateRange: TransitiveDateRange,
  selectedKi: string[],
  selectedDateType: ModelTransitiveDateType
) {
  return {
    type: SEARCH_MODEL_TRANSITION_REPORTS,
    payload: {
      params,
      selectedDateType,
      selectedShu,
      selectedSis,
      selectedDateRange,
      selectedKi,
    },
  };
}

/**
 * 初期条件でデータを取得する（リセットボタン押下時）
 */
function searchResetModelTransitionReportsAction() {
  return {
    type: SEARCH_RESET_MODEL_TRANSITION_REPORTS,
  };
}

/**
 * 指定した非推移項目ソート条件でデータを再取得する
 * @param sort 非推移項目でソートする条件
 * @param order 昇順・降順
 */
function searchModelTransitionReportsSortForHeaderAction(
  sort: string,
  order: OrderType
) {
  return {
    type: SEARCH_MODEL_TRANSITION_REPORTS_SORT_FOR_HEADER,
    payload: { sort, order },
  };
}

/**
 * 指定した推移項目ソート条件でデータを再取得する
 * @param sort 推移項目でソートする条件
 * @param order 昇順・降順
 */
function searchModelTransitionReportsSortForTransitiveFieldAction(
  sort: string,
  order: OrderType
) {
  return {
    type: SEARCH_MODEL_TRANSITION_REPORTS_SORT_FOR_TRANSITIVE_FIELD,
    payload: { sort, order },
  };
}

/**
 * 指定したマーキング条件でデータを再取得する
 * @param markingOption マーキング項目
 * @param isFiltering 該当行のみ表示するか
 */
function searchModelTransitionReportsMarkingAction(
  markingOption: MarkingOption,
  isFiltering: boolean
) {
  return {
    type: SEARCH_MODEL_TRANSITION_REPORTS_MARKING,
    payload: { markingOption, isFiltering },
  };
}

/**
 * 指定した表示項目に絞ってデータを再取得する
 * @param transitiveFields 推移項目
 * @param nonTransitiveFields 非推移項目
 */
function searchModelTransitionReportsFieldsAction(
  transitiveFields: string[],
  nonTransitiveFields: string[]
) {
  return {
    type: SEARCH_MODEL_TRANSITION_REPORTS_FIELDS,
    payload: { transitiveFields, nonTransitiveFields },
  };
}

/**
 * 選択したお気に入りでデータを取得する
 *
 * @param favorite 変更するお気に入りID（undefined時は選択なし）
 */
function changeModelTransitionReportsFavoriteAction(favorite?: number) {
  return {
    type: CHANGE_MODEL_TRANSITION_REPORTS_FAVORITE,
    payload: { favorite },
  };
}

/**
 * 検索条件を変更
 */
function selectModelTransitionReportsSearchConditionAction(
  params: DataModelTransitionParams
) {
  return {
    type: SELECT_MODEL_TRANSITION_REPORTS_SEARCH_CONDITION,
    payload: { params },
  };
}

/**
 * 種別一覧を取得
 * @param shus 種別一覧
 */
export function selectModelTransitionReportsShuAction(shus: ShuOption[]) {
  return {
    type: SELECT_MODEL_TRANSITION_REPORTS_SHU,
    payload: { shus },
  };
}

/**
 * 絞り込む種別を取得する
 *
 * @param shu 絞り込む種別
 */
export function selectModelTransitionReportsCurrentShuAction(shu?: ShuOption) {
  return {
    type: SELECT_MODEL_TRANSITION_REPORTS_CURRENT_SHU,
    payload: { shu },
  };
}

/**
 * 絞り込む種別を変更
 *
 * @param shu 絞り込む種別
 */
function changeModelTransitionReportsCurrentShuAction(shu?: ShuOption) {
  return {
    type: CHANGE_MODEL_TRANSITION_REPORTS_CURRENT_SHU,
    payload: { shu },
  };
}

/**
 * 絞り込む機種リストを変更
 *
 * @param kiList 絞り込む機種リスト
 */
function selectModelTransitionReportsKiListAction(kiList: string[]) {
  return {
    type: SELECT_MODEL_TRANSITION_REPORTS_KI_LIST,
    payload: { kiList },
  };
}

/**
 * 絞り込むSISリストを変更
 *
 * @param selectedSis 絞り込むSISリスト
 */
function selectModelTransitionReportsSisListAction(selectedSis: string[]) {
  return {
    type: SELECT_MODEL_TRANSITION_REPORTS_SIS_LIST,
    payload: { selectedSis },
  };
}

/**
 * 推移項目の並び順を変更
 *
 * @param columnsForTransition
 * @returns
 */
export function selectModelTransitionReportsColumnsForTransitionAction(
  columnsForTransition: string[]
) {
  return {
    type: SELECT_MODEL_TRANSITION_REPORTS_COLUMNS_FOR_TRANSITION_ORDER,
    payload: { columnsForTransition },
  };
}

/**
 * 検索条件をデフォルトに戻す
 */
function resetModelTransitionReportsSearchConditionAction() {
  return {
    type: RESET_MODEL_TRANSITION_REPORTS_SEARCH_CONDITION,
  };
}

/**
 * 選択中の種別一覧をデフォルトに戻す
 */
function resetModelTransitionReportsShuAction() {
  return {
    type: RESET_MODEL_TRANSITION_REPORTS_SHU,
  };
}

/**
 * 日付タイプ・期間を変更
 *
 * @param dateRange 変更する期間
 */
function selectModelTransitionReportsDateRangeParamsAction(
  dateType: ModelTransitiveDateType,
  dateRange: TransitiveDateRange
) {
  return {
    type: SELECT_MODEL_TRANSITION_REPORTS_DATE_RANGE_PARAMS,
    payload: { dateType, dateRange },
  };
}

/**
 * 選択中の期間をデフォルトに戻す
 */
function resetModelTransitionReportsDateRangeParamsAction() {
  return {
    type: RESET_MODEL_TRANSITION_REPORTS_DATE_RANGE_PARAMS,
  };
}

/**
 * データを取得せずにお気に入りをデフォルトに戻す
 */
function resetModelTransitionReportFavoriteToDefaultAction() {
  return {
    type: RESET_MODEL_RANSITION_REPORTS_FAVORITE_TO_DEFAULT,
    payload: {},
  };
}

/**
 * 現在の検索条件でお気に入りを新規保存する
 * @param name お気に入り名
 * @param isShared 共有設定
 * @param memo メモ
 * @param sharedUser 共有ユーザ
 * @param mode メモ更新モード
 */
function saveModelTransitionReportsFavorite(
  name: string,
  isShared: boolean,
  memo: string,
  sharedUser: SharedUser[],
  mode?: 'standard' | 'memo'
) {
  return {
    type: SAVE_MODEL_TRANSITION_REPORTS_FAVORITE,
    payload: { name, isShared, memo, sharedUser, mode },
  };
}

/**
 * 現在の検索条件でお気に入りを上書き保存する
 * @param name お気に入り名
 * @param isShared 共有設定
 * @param memo メモ
 * @param sharedUser 共有ユーザ
 */
function saveAsModelTransitionReportsFavorite(
  name: string,
  isShared: boolean,
  memo: string,
  sharedUser: SharedUser[]
) {
  return {
    type: SAVE_AS_MODEL_TRANSITION_REPORTS_FAVORITE,
    payload: { name, isShared, memo, sharedUser },
  };
}

/**
 * 非推移項目の並び順を変更
 *
 * @param columnsForNonTransition
 * @returns
 */
export function selectModelTransitionReportsColumnsForNonTransitionAction(
  columnsForNonTransition: string[]
) {
  return {
    type: SELECT_MODEL_TRANSITION_REPORTS_COLUMNS_FOR_NON_TRANSITION_ORDER,
    payload: { columnsForNonTransition },
  };
}

/**
 * 機種名フィルタを変更
 * @param filterString 検索する文字列
 */
export function selectModelTransitionReportsModelNameFilterAction(
  filterString: string
) {
  return {
    type: SELECT_MODEL_TRANSITION_REPORTS_MODEL_NAME_FILTER,
    payload: { filterString },
  };
}

/**
 *  期間スライドコンポーネントがクリックされた時に、期間を変更して検索を実行する
 *  @param {TransitiveDateRange} newDateRange   期間単位
 *  @param {string} newStartDate                検索期間 開始日
 *  @param {string} newEndDate                  検索期間 終了日
 */
export function searchModelTransitionReportsDateRangeSlideAction(
  newDateRange: TransitiveDateRange,
  newStartDate: string,
  newEndDate: string
) {
  return {
    type: SEARCH_MODEL_TRANSITION_REPORTS_DATE_RANGE_SLIDE,
    payload: {
      dateRange: newDateRange,
      startDate: newStartDate,
      endDate: newEndDate,
    },
  };
}

/**
 * テーブルの非表示項目を追加する
 * @param columnCode カラム名
 */
function addModelTransitionReportsTableFilterAction(columnCode: string) {
  return {
    type: ADD_MODEL_TRANSITION_REPORTS_TABLE_FILTER,
    payload: { columnCode },
  };
}

/**
 * テーブルの表示項目を削除する
 * @param columnCode カラム名
 */
function removeModelTransitionReportsTableFilterAction(columnCode: string) {
  return {
    type: REMOVE_MODEL_TRANSITION_REPORTS_TABLE_FILTER,
    payload: { columnCode },
  };
}

/**
 * テーブルの非表示項目を反映する
 * @param tableFilterItems テーブルの非表示項目
 */
function applyModelTransitionReportsTableFilterAction(
  tableFilterItems: string[]
) {
  return {
    type: APPLY_MODEL_TRANSITION_REPORTS_TABLE_FILTER,
    payload: { tableFilterItems },
  };
}

/**
 * テーブルの非表示項目をリセットする
 */
function resetModelTransitionReportsTableFilterAction() {
  return {
    type: RESET_MODEL_TRANSITION_REPORTS_TABLE_FILTER,
  };
}

/**
 * テーブルでチェックされた機種
 */
export function selectModelTransitionCheckedKiAction(checkedKiList: string[]) {
  return {
    type: SELECT_MODEL_TRANSITION_REPORTS_CHECKED_KI,
    payload: checkedKiList,
  };
}

/**
 * お気に入り適用時にテーブルでチェックされた機種の種別ごとのテーブルを設定する
 */
export function selectModelTransitionCheckedKiListTableAction(checkedKiListTable: {
  [shuCode: string]: string[];
}) {
  return {
    type: SELECT_MODEL_TRANSITION_REPORTS_CHECKED_KI_TABLE,
    payload: checkedKiListTable,
  };
}

/**
 * 推移グラフに数値を表示するかどうかのフラグをセットする
 * @param {boolean} flag 表示フラグ
 */
function selectModelTransitionShowGraphNumberLabelAction(flag: boolean) {
  return {
    type: SELECT_MODEL_TRANSITION_SHOW_GRAPH_NUMBER_LABEL,
    payload: {
      flag,
    },
  };
}

/**
 * 店舗選択リストで店舗を変更し、リクエストを投げる
 *
 * @param halls 絞り込む店舗
 */
function changeModelTransitionReportsCurrentHallsAction(halls: string[]) {
  return {
    type: CHANGE_MODEL_TRANSITION_REPORTS_CURRENT_HALLS,
    payload: { halls },
  };
}

/**
 * 店舗選択リストを変更する、リクエストは投げない
 */
function selectModelTransitionReportsCurrentHallsAction(halls: string[]) {
  return {
    type: SELECT_MODEL_TRANSITION_REPORTS_CURRENT_HALLS,
    payload: { halls },
  };
}

/**
 * ヒートマップ表示の有効無効を変更する
 */
function selectModelTransitionReportsIsHeatmapEnabledAction(
  isEnabled: boolean
) {
  return {
    type: SELECT_MODEL_TRANSITION_REPORTS_IS_HEATMAP_ENABLED,
    payload: { isEnabled },
  };
}

/**
 * 項目絞込リストで現在選択中の項目を変更
 */
export function selectModelTransitionCurrentFieldAction(fieldCode: string) {
  return {
    type: SELECT_MODEL_TRANSITION_REPORTS_CURRENT_FIELD,
    payload: { fieldCode },
  };
}

/**
 * 画面共有用の短縮URLを作成する
 */
function createModelTransitionReportsShortenedUrlAction(
  pageName: string,
  locationUrl: string
) {
  return {
    type: CREATE_MODEL_TRANSITION_REPORTS_SHORTENED_URL,
    payload: { pageName, locationUrl },
  };
}

export const ModelTransitionReportsSettingActionCreators = {
  initModelTransitionReportsAction,
  searchModelTransitionReportsAction,
  searchResetModelTransitionReportsAction,
  searchModelTransitionReportsSortForHeaderAction,
  searchModelTransitionReportsSortForTransitiveFieldAction,
  searchModelTransitionReportsMarkingAction,
  searchModelTransitionReportsFieldsAction,
  changeModelTransitionReportsFavoriteAction,
  selectModelTransitionReportsSearchConditionAction,
  selectModelTransitionReportsShuAction,
  selectModelTransitionReportsKiListAction,
  selectModelTransitionReportsSisListAction,
  selectModelTransitionReportsColumnsForTransitionAction,
  resetModelTransitionReportsSearchConditionAction,
  resetModelTransitionReportsShuAction,
  resetModelTransitionReportFavoriteToDefaultAction,
  selectModelTransitionReportsDateRangeParamsAction,
  resetModelTransitionReportsDateRangeParamsAction,
  saveModelTransitionReportsFavorite,
  saveAsModelTransitionReportsFavorite,
  selectModelTransitionReportsCurrentShuAction,
  changeModelTransitionReportsCurrentShuAction,
  selectModelTransitionReportsColumnsForNonTransitionAction,
  selectModelTransitionReportsModelNameFilterAction,
  searchModelTransitionReportsDateRangeSlideAction,
  addModelTransitionReportsTableFilterAction,
  removeModelTransitionReportsTableFilterAction,
  applyModelTransitionReportsTableFilterAction,
  resetModelTransitionReportsTableFilterAction,
  selectModelTransitionCheckedKiAction,
  selectModelTransitionCheckedKiListTableAction,
  selectModelTransitionShowGraphNumberLabelAction,
  changeModelTransitionReportsCurrentHallsAction,
  selectModelTransitionReportsCurrentHallsAction,
  selectModelTransitionReportsIsHeatmapEnabledAction,
  selectModelTransitionCurrentFieldAction,
  createModelTransitionReportsShortenedUrlAction,
};

/**
 * Actions
 */

type InitModelTransitionReportsAction = ReturnType<
  typeof initModelTransitionReportsAction
>;
export type SearchModelTransitionReportsAction = ReturnType<
  typeof searchModelTransitionReportsAction
>;
type SearchResetModelTransitionReportsAction = ReturnType<
  typeof searchResetModelTransitionReportsAction
>;
export type SearchModelTransitionReportsSortForHeaderAction = ReturnType<
  typeof searchModelTransitionReportsSortForHeaderAction
>;
export type SearchModelTransitionReportsSortForTransitiveFieldAction = ReturnType<
  typeof searchModelTransitionReportsSortForTransitiveFieldAction
>;
export type SearchModelTransitionReportsMarkingAction = ReturnType<
  typeof searchModelTransitionReportsMarkingAction
>;
export type SearchModelTransitionReportsFieldsAction = ReturnType<
  typeof searchModelTransitionReportsFieldsAction
>;
type ChangeModelTransitionReportsFavoriteAction = ReturnType<
  typeof changeModelTransitionReportsFavoriteAction
>;
type SelectKiShuAction = ReturnType<
  typeof selectModelTransitionReportsShuAction
>;
type SelectKiListAction = ReturnType<
  typeof selectModelTransitionReportsKiListAction
>;
type SelectSisListAction = ReturnType<
  typeof selectModelTransitionReportsSisListAction
>;
type SelectModelTransitionReportsSearchConditionAction = ReturnType<
  typeof selectModelTransitionReportsSearchConditionAction
>;
type ResetModelTransitionReportsSearchConditionAction = ReturnType<
  typeof resetModelTransitionReportsSearchConditionAction
>;
type ResetKiShuAction = ReturnType<typeof resetModelTransitionReportsShuAction>;
type ResetModelTransitionReportFavoriteToDefaultAction = ReturnType<
  typeof resetModelTransitionReportFavoriteToDefaultAction
>;
type SelectModelTransitionReportsDateRangeParamsAction = ReturnType<
  typeof selectModelTransitionReportsDateRangeParamsAction
>;
type SelectModelTransitionReportsColumnsForTransitionAction = ReturnType<
  typeof selectModelTransitionReportsColumnsForTransitionAction
>;
type ResetModelTransitionReportsDateRangeParamsAction = ReturnType<
  typeof resetModelTransitionReportsDateRangeParamsAction
>;
export type SaveModelTransitionReportsFavorite = ReturnType<
  typeof saveModelTransitionReportsFavorite
>;
type SaveAsModelTransitionReportsFavorite = ReturnType<
  typeof saveAsModelTransitionReportsFavorite
>;
export type ChangeModelTransitionReportsCurrentShuAction = ReturnType<
  typeof changeModelTransitionReportsCurrentShuAction
>;
type SelectModelTransitionCurrentShuAction = ReturnType<
  typeof selectModelTransitionReportsCurrentShuAction
>;
type SelectModelTransitionReportsColumnsForNonTransitionAction = ReturnType<
  typeof selectModelTransitionReportsColumnsForNonTransitionAction
>;
type SelectModelTransitionReportsModelNameFilterAction = ReturnType<
  typeof selectModelTransitionReportsModelNameFilterAction
>;
export type SearchModelTransitionReportsDateRangeSlideAction = ReturnType<
  typeof searchModelTransitionReportsDateRangeSlideAction
>;
type AddModelTransitionReportsTableFilterAction = ReturnType<
  typeof addModelTransitionReportsTableFilterAction
>;
type RemoveModelTransitionReportsTableFilterAction = ReturnType<
  typeof removeModelTransitionReportsTableFilterAction
>;
type ApplyModelTransitionReportsTableFilterAction = ReturnType<
  typeof applyModelTransitionReportsTableFilterAction
>;
type ResetModelTransitionReportsTableFilterAction = ReturnType<
  typeof resetModelTransitionReportsTableFilterAction
>;
type SelectModelTransitionCheckedKiAction = ReturnType<
  typeof selectModelTransitionCheckedKiAction
>;
type SelectModelTransitionCheckedKiListTableAction = ReturnType<
  typeof selectModelTransitionCheckedKiListTableAction
>;
type SelectModelTransitionShowGraphNumberLabelAction = ReturnType<
  typeof selectModelTransitionShowGraphNumberLabelAction
>;
export type ChangeModelTransitionReportsCurrentHallsAction = ReturnType<
  typeof changeModelTransitionReportsCurrentHallsAction
>;
type SelectModelTransitionReportsCurrentHallsAction = ReturnType<
  typeof selectModelTransitionReportsCurrentHallsAction
>;

type SelectModelTransitionReportsIsHeatmapEnabledAction = ReturnType<
  typeof selectModelTransitionReportsIsHeatmapEnabledAction
>;

type SelectModelTransitionReportsCurrentFieldAction = ReturnType<
  typeof selectModelTransitionCurrentFieldAction
>;

export type CreateModelTransitionReportsShortenedUrlAction = ReturnType<
  typeof createModelTransitionReportsShortenedUrlAction
>;

type ModelTransitionReportsSettingAction =
  | InitModelTransitionReportsAction
  | SearchModelTransitionReportsAction
  | SearchResetModelTransitionReportsAction
  | SearchModelTransitionReportsSortForHeaderAction
  | SearchModelTransitionReportsSortForTransitiveFieldAction
  | SearchModelTransitionReportsMarkingAction
  | SearchModelTransitionReportsFieldsAction
  | ChangeModelTransitionReportsFavoriteAction
  | SelectModelTransitionReportsSearchConditionAction
  | SelectModelTransitionReportsColumnsForTransitionAction
  | SelectKiShuAction
  | SelectKiListAction
  | SelectSisListAction
  | ResetModelTransitionReportsSearchConditionAction
  | ResetKiShuAction
  | SelectModelTransitionReportsDateRangeParamsAction
  | ResetModelTransitionReportsDateRangeParamsAction
  | SaveModelTransitionReportsFavorite
  | SaveAsModelTransitionReportsFavorite
  | ChangeModelTransitionReportsCurrentShuAction
  | SelectModelTransitionCurrentShuAction
  | SelectModelTransitionReportsColumnsForNonTransitionAction
  | SelectModelTransitionReportsModelNameFilterAction
  | SearchModelTransitionReportsDateRangeSlideAction
  | AddModelTransitionReportsTableFilterAction
  | RemoveModelTransitionReportsTableFilterAction
  | ApplyModelTransitionReportsTableFilterAction
  | ResetModelTransitionReportsTableFilterAction
  | SelectModelTransitionCheckedKiAction
  | SelectModelTransitionCheckedKiListTableAction
  | SelectModelTransitionShowGraphNumberLabelAction
  | ResetModelTransitionReportFavoriteToDefaultAction
  | ChangeModelTransitionReportsCurrentHallsAction
  | SelectModelTransitionReportsCurrentHallsAction
  | SelectModelTransitionReportsIsHeatmapEnabledAction
  | SelectModelTransitionReportsCurrentFieldAction
  | CreateModelTransitionReportsShortenedUrlAction;

/**
 * State
 */

export type ModelTransitionReportsSettingState = {
  /**
   * 現在の検索条件
   */
  searchCondition: DataModelTransitionParams;
  /**
   * 現在検索フォームで選択中の機種
   */
  selectedKi: string[];
  /**
   * 現在検索フォームで選択中の種別・種別グループ（選択可能な種別・種別グループ一覧）
   */
  selectedShu: ShuOption[];
  /**
   * 現在検索フォームで選択中のSISタイプ
   */
  selectedSis: string[];
  /**
   * 現在選択中の種別・種別グループ（テーブルに表示されている種別・種別グループ）
   */
  currentShu?: ShuOption;
  /**
   * 現在選択中の日付・期間
   */
  selectedDateRangeParams: ModelTransitionReportsSettingDateRangeParams;
  /**
   * 選択中のお気に入りID
   */
  selectedFavoriteId?: number;
  /**
   * 推移項目並び順
   */
  columnsForTransitionOrder?: string[];
  /**
   * 非推移項目並び順
   */
  columnsForNonTransitionOrder?: string[];
  /**
   * 機種一覧
   */
  kiList?: string[];
  /**
   * 機種名フィルタ
   */
  modelNameFilter: string;
  /**
   * テーブルの非表示項目一覧
   */
  tableFilterItems: string[];
  /**
   * テーブルでチェックされた機種の種別ごとのテーブル
   */
  checkedKiListTable?: {
    [shuCode: string]: string[];
  };
  showGraphNumberLabel: boolean;
  /**
   * 店舗選択リストで現在選択中の店舗
   */
  currentHalls: string[];
  /**
   * ヒートマップ表示の有効無効
   */
  isHeatmapEnabled: boolean;
  /**
   * 項目絞込で選択されている項目
   */
  selectedCurrentFieldCode?: string;
};

// Stateの初期値
const initialState: ModelTransitionReportsSettingState = {
  searchCondition: dataModelTransitionDefaultValue().setting,
  selectedKi: [],
  selectedShu: [],
  selectedSis: [],
  currentShu: undefined,
  selectedDateRangeParams: {
    dateType: dataModelTransitionDefaultValue().dateRangeParams.dateType,
    dateRange: dataModelTransitionDefaultValue().dateRangeParams.dateRange,
  },
  columnsForTransitionOrder: [],
  columnsForNonTransitionOrder: [],
  kiList: [],
  modelNameFilter: '',
  tableFilterItems: [],
  checkedKiListTable: {},
  showGraphNumberLabel: true,
  currentHalls: [],
  isHeatmapEnabled: true,
  selectedCurrentFieldCode: undefined,
};

/**
 * Selector
 */

/**
 * 機種推移設定全てを取得する
 * @returns 全設定データ
 */
export function modelTransitionReportsSettingSelector(rootState: {
  modelTransitionReportsSetting: ModelTransitionReportsSettingState;
}) {
  return rootState.modelTransitionReportsSetting;
}

/**
 * 機種推移の現在選択されている検索条件を取得する
 * @returns 現在選択されている検索条件
 */
export function modelTransitionReportsSearchConditionSelector(rootState: {
  modelTransitionReportsSetting: ModelTransitionReportsSettingState;
}) {
  return rootState.modelTransitionReportsSetting.searchCondition;
}

/**
 *機種別推移で選択中の機種を返す
 * @returns 現在選択されている機種
 */
export function modelTransitionReportsSelectedKiSelector(rootState: {
  modelTransitionReportsSetting: ModelTransitionReportsSettingState;
}) {
  return rootState.modelTransitionReportsSetting.kiList;
}

/**
 * 機種推移の検索フォームで選択中の種別・種別グループを取得する（選択可能な種別・種別グループ一覧）
 * @returns 選択可能な種別一覧
 */
export function modelTransitionReportsSelectedShuSelector(rootState: {
  modelTransitionReportsSetting: ModelTransitionReportsSettingState;
}) {
  return rootState.modelTransitionReportsSetting.selectedShu;
}

/**
 *  機種推移で選択中の種別・種別グループを取得する（テーブルに表示されている種別・種別グループ）
 * @returns 現在選択されている種別（undefined時未選択）
 */
export function modelTransitionReportsSelectedCurrentShuSelector(rootState: {
  modelTransitionReportsSetting: ModelTransitionReportsSettingState;
}) {
  return rootState.modelTransitionReportsSetting.currentShu;
}

/**
 * 機種推移で選択中の日付タイプを取得する（テーブルに表示されている日付タイプ・期間）
 * @returns 現在選択されている期間
 */
export function modelTransitionReportsSelectedDateRangeParamsSelector(rootState: {
  modelTransitionReportsSetting: ModelTransitionReportsSettingState;
}) {
  return rootState.modelTransitionReportsSetting.selectedDateRangeParams;
}

/**
 * 推移項目の並び順
 * @returns
 */
export function modelTransitionReportsColumnsForTransitionOrderSelector(rootState: {
  modelTransitionReportsSetting: ModelTransitionReportsSettingState;
}) {
  return rootState.modelTransitionReportsSetting.columnsForTransitionOrder;
}

/**
 * 非推移項目の並び順
 * @returns
 */
export function modelTransitionReportsColumnsForNonTransitionOrderSelector(rootState: {
  modelTransitionReportsSetting: ModelTransitionReportsSettingState;
}) {
  return rootState.modelTransitionReportsSetting.columnsForNonTransitionOrder;
}

/**
 * 機種推移で選択中のお気に入りIDを取得する
 * @returns 現在選択中のお気に入りID（undefined時未選択）
 */
export const modelTransitionReportsSelectedFavoriteSelector = (
  state: RootState
) => {
  return state.modelTransitionReportsSetting.selectedFavoriteId;
};

/**
 * 機種推移で選択中のお気に入りデータを取得する
 * @returns 現在選択中のお気に入りデータ（undefined時未選択）
 */
export const modelTransitionReportsSelectedFavoriteDataSelector = createSelector(
  [modelTransitionReportsSelectedFavoriteSelector, favoritesSelector],
  (favoriteId, favorites) => {
    if (favoriteId === undefined) return;

    return favorites?.favorites
      ?.filter((favorite) => favorite.id === favoriteId)
      .shift();
  }
);

/**
 * 機種推移で選択中のお気に入りデータ内の機種推移データのみを取得する
 * @returns 現在選択中の機種推移のお気に入りデータ
 */
export const modelTransitionReportsSelectedFavoritePageSettingSelector = createSelector(
  modelTransitionReportsSelectedFavoriteDataSelector,
  (favorite) => {
    return favorite?.pageSetting?.modelTransition;
  }
);

/**
 * 機種別推移で入力されている機種名フィルタを返す
 */
export const modelTransitionReportsModelNameFilterSelector = (rootState: {
  modelTransitionReportsSetting: ModelTransitionReportsSettingState;
}) => rootState.modelTransitionReportsSetting.modelNameFilter;

/**
 * 非表示項目一覧を取得する
 * @returns 非表示項目一覧
 */
export const modelTransitionReportsTableFilterSelector = (state: RootState) => {
  return state.modelTransitionReportsSetting.tableFilterItems;
};

/**
 * テーブルでチェックされた機種のテーブルを取得する
 */
export const modelTransitionReportsCheckedKiListTableSelector = (
  state: RootState
) => {
  return state.modelTransitionReportsSetting.checkedKiListTable;
};

/**
 * 種別ごとのチェックされた機種を取得する
 */
export const modelTransitionReportsCheckedKiListSelector = createSelector(
  [
    modelTransitionReportsSelectedCurrentShuSelector,
    modelTransitionReportsCheckedKiListTableSelector,
  ],
  (currentShu, checkedKiListTable) => {
    return (
      (checkedKiListTable &&
        currentShu &&
        checkedKiListTable[currentShu.code]) ||
      []
    );
  }
);

// 推移グラフで数値ラベルを表示するかどうか
export const modelTransitionShowGraphNumberLabelSelector = createSelector(
  modelTransitionReportsSettingSelector,
  (setting) => setting.showGraphNumberLabel
);

/**
 * 検索条件に指定している店舗
 * @returns string[]
 */
export const modelTransitionReportsSettingSelectedHallsSelector = createSelector(
  modelTransitionReportsSearchConditionSelector,
  (searchCondition) => {
    return searchCondition.halls ?? [];
  }
);

/**
 * 店舗選択リストで選択中の店舗
 */
export const modelTransitionReportsSettingCurrentHallsSelector = createSelector(
  modelTransitionReportsSettingSelector,
  ({ currentHalls }) => (currentHalls.length > 0 ? currentHalls : [])
);

/**
 * ヒートマップ表示の有効無効を取得
 */
export const modelTransitionReportsSettingIsHeatmapEnabledSelector = createSelector(
  modelTransitionReportsSettingSelector,
  ({ isHeatmapEnabled }) => isHeatmapEnabled
);

/**
 * 項目絞込リストで選択中の項目
 */
export const modelTransitionCurrentFieldSelector = createSelector(
  modelTransitionReportsSettingSelector,
  ({ selectedCurrentFieldCode }) => selectedCurrentFieldCode ?? 'all'
);

/**
 * Reducer
 */

export function modelTransitionReportsSettingReducer(
  state = initialState,
  action: ModelTransitionReportsSettingAction
): ModelTransitionReportsSettingState {
  switch (action.type) {
    case SELECT_MODEL_TRANSITION_REPORTS_SEARCH_CONDITION:
      return {
        ...state,
        searchCondition: action.payload.params,
      };
    case RESET_MODEL_TRANSITION_REPORTS_SEARCH_CONDITION:
      return {
        ...state,
        searchCondition: initialState.searchCondition,
        currentHalls: initialState.currentHalls,
      };
    case SELECT_MODEL_TRANSITION_REPORTS_SHU:
      return {
        ...state,
        selectedShu: action.payload.shus,
      };
    case SELECT_MODEL_TRANSITION_REPORTS_KI_LIST:
      return {
        ...state,
        kiList: action.payload.kiList,
      };
    case SELECT_MODEL_TRANSITION_REPORTS_DATE_RANGE_PARAMS: {
      const dateType = action.payload.dateType;
      const dateRange = action.payload.dateRange;
      return {
        ...state,
        selectedDateRangeParams: { dateType, dateRange },
      };
    }
    case SELECT_MODEL_TRANSITION_REPORTS_CURRENT_SHU:
    case CHANGE_MODEL_TRANSITION_REPORTS_CURRENT_SHU:
      return {
        ...state,
        currentShu: action.payload.shu,
      };
    case SELECT_MODEL_TRANSITION_REPORTS_COLUMNS_FOR_TRANSITION_ORDER: {
      return {
        ...state,
        columnsForTransitionOrder: action.payload.columnsForTransition,
      };
    }
    case RESET_MODEL_TRANSITION_REPORTS_DATE_RANGE_PARAMS:
      return {
        ...state,
        selectedDateRangeParams: initialState.selectedDateRangeParams,
      };
    case RESET_MODEL_TRANSITION_REPORTS_SHU:
      return {
        ...state,
        selectedShu: [],
      };
    case CHANGE_MODEL_TRANSITION_REPORTS_FAVORITE:
      return {
        ...state,
        selectedFavoriteId: action.payload.favorite,
      };
    case SELECT_MODEL_TRANSITION_REPORTS_COLUMNS_FOR_NON_TRANSITION_ORDER:
      return {
        ...state,
        columnsForNonTransitionOrder: action.payload.columnsForNonTransition,
      };
    case SELECT_MODEL_TRANSITION_REPORTS_MODEL_NAME_FILTER:
      return {
        ...state,
        modelNameFilter: action.payload.filterString,
      };
    case ADD_MODEL_TRANSITION_REPORTS_TABLE_FILTER:
      return {
        ...state,
        tableFilterItems: [
          ...state.tableFilterItems,
          action.payload.columnCode,
        ],
      };
    case REMOVE_MODEL_TRANSITION_REPORTS_TABLE_FILTER:
      return {
        ...state,
        tableFilterItems: state.tableFilterItems.filter(
          (columnCode) => columnCode !== action.payload.columnCode
        ),
      };
    case APPLY_MODEL_TRANSITION_REPORTS_TABLE_FILTER:
      return {
        ...state,
        tableFilterItems: action.payload.tableFilterItems,
      };
    case RESET_MODEL_TRANSITION_REPORTS_TABLE_FILTER:
      return {
        ...state,
        tableFilterItems: [],
      };
    case SELECT_MODEL_TRANSITION_REPORTS_CHECKED_KI:
      if (!state.currentShu) {
        return state;
      }
      return {
        ...state,
        checkedKiListTable: {
          ...state.checkedKiListTable,
          [state.currentShu.code]: action.payload,
        },
      };
    case SELECT_MODEL_TRANSITION_REPORTS_CHECKED_KI_TABLE: {
      return {
        ...state,
        checkedKiListTable: action.payload,
      };
    }
    case SELECT_MODEL_TRANSITION_SHOW_GRAPH_NUMBER_LABEL: {
      return {
        ...state,
        showGraphNumberLabel: action.payload.flag,
      };
    }
    case RESET_MODEL_RANSITION_REPORTS_FAVORITE_TO_DEFAULT: {
      return {
        ...state,
        selectedFavoriteId: undefined,
      };
    }
    case SEARCH_MODEL_TRANSITION_REPORTS: {
      return {
        ...state,
        currentHalls: [],
      };
    }
    case SELECT_MODEL_TRANSITION_REPORTS_CURRENT_HALLS:
    case CHANGE_MODEL_TRANSITION_REPORTS_CURRENT_HALLS:
      return {
        ...state,
        currentHalls: action.payload.halls,
      };
    case SELECT_MODEL_TRANSITION_REPORTS_IS_HEATMAP_ENABLED:
      return {
        ...state,
        isHeatmapEnabled: action.payload.isEnabled,
      };
    case SELECT_MODEL_TRANSITION_REPORTS_CURRENT_FIELD:
      return {
        ...state,
        selectedCurrentFieldCode: action.payload.fieldCode,
      };
    default:
      return state;
  }
}
