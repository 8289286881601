import { SxProps, Theme } from '@mui/material';
import Box from '@mui/material/Box';
import { ReactNode } from 'react';

const styles: Record<string, SxProps<Theme>> = {
  screenOnly: {
    '@media print': {
      display: 'none',
    },
  },
};

export const ScreenOnly = (props: { children?: ReactNode }) => {
  const { children } = props;

  return <Box sx={styles.screenOnly}>{children}</Box>;
};
