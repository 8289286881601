type EventClickState = 'ON' | 'OFF';

type GaClickEvent = {
  (
    name: 'DK-SISスイッチ',
    eventParams: {
      event_click_state: EventClickState;
    }
  ): void;
  (
    name: 'チェック機種絞込',
    eventParams: {
      event_click_state: EventClickState;
    }
  ): void;
  (
    name: '画面リンク',
    eventParams: {
      event_click_value:
        | '店舗レポート'
        | 'チェーン店レポート'
        | '機種集計'
        | '予定進捗推移';
    }
  ): void;
  (
    name: 'CSV出力',
    eventParams: {
      event_click_value: string;
    }
  ): void;
  (
    name: 'マーキング適用',
    eventParams: {
      event_click_value: string;
    }
  ): void;
  (name: '表示項目適用', eventParams?: undefined): void;
  (
    name: '展開行 1階層目',
    eventParams: {
      event_click_state: EventClickState;
    }
  ): void;
  (
    name: '展開行 2階層目',
    eventParams: {
      event_click_state: EventClickState;
    }
  ): void;
  (name: 'チェーン店一覧', eventParams?: undefined): void;
  (
    name: '台別データ',
    eventParams: {
      event_click_value: '1階層目' | '2階層目' | undefined;
    }
  ): void;
  (
    name: '推移グラフ（店舗比較）',
    eventParams: {
      event_click_value: '1階層目' | '2階層目' | undefined;
    }
  ): void;
  (name: '推移グラフ（機種比較）', eventParams?: undefined): void;
  (name: '導入後推移グラフ', eventParams?: undefined): void;
  (name: '推移グラフ PPMシェア', eventParams?: undefined): void;
  (name: '数値フィルタ実行', eventParams?: undefined): void;
  (name: '表示項目パターン新規保存', eventParams?: undefined): void;
  (name: '表示項目パターン上書き保存', eventParams?: undefined): void;
  (name: '表示項目パターン反映', eventParams?: undefined): void;
  (name: '台番機種設定新規登録完了', eventParams?: undefined): void;
  (name: '台番機種設定編集登録完了', eventParams?: undefined): void;
  (
    name: '台番機種設定有効化/無効化スイッチ',
    eventParams: {
      event_click_state: EventClickState;
    }
  ): void;
  (name: '種別タブ', eventParams?: undefined): void;
  (name: '新台/メイン機種タブ', eventParams?: undefined): void;
  (name: '商圏タブ', eventParams?: undefined): void;
  (name: '時間帯別稼働数グラフ', eventParams?: undefined): void;
  (
    name: 'サイドバーお気に入り開閉',
    eventParams: {
      event_click_state: EventClickState;
    }
  ): void;
  (
    name: '項目絞込',
    eventParams: { event_click_value: 'slider' | 'select' }
  ): void;
  (
    name: 'お気に入り適用',
    eventParams: {
      event_click_value:
        | 'ホーム'
        | 'サイドバー'
        | 'ページ別サイドバー'
        | 'ヘッダー';
    }
  ): void;
  (
    name: '機種ハイライト・平均表示機能',
    eventParams: { event_click_state: EventClickState }
  ): void;
  (
    name: '機種タグ絞込',
    eventParams: {
      event_click_value: string;
      event_click_state: EventClickState;
    }
  ): void;
  (name: '機種タグ新規作成', eventParams?: undefined): void;
  (name: '機種タグ編集', eventParams?: undefined): void;
  (name: '機種タグ付与・解除', eventParams?: undefined): void;
  (name: '機種タグ一括付与', eventParams?: undefined): void;
  (name: 'デイリーコメントフローティングボタン', eventParams?: undefined): void;
  (name: 'デイリーコメント入力確定', eventParams?: undefined): void;
  (name: 'デイリーコメント閉じる', eventParams?: undefined): void;
  (name: 'デイリーコメント削除', eventParams?: undefined): void;
  (name: '商圏推移グラフ', eventParams?: undefined): void;
  (
    name: 'ヒートマップ表示',
    eventParams: {
      event_click_state: EventClickState;
    }
  ): void;
  (
    name: 'エリア集計スイッチ',
    eventParams: {
      event_click_state: EventClickState;
    }
  ): void;
  (
    name: 'カレンダー月送り',
    eventParams: { event_click_value: '戻る' | '進む' }
  ): void;
  (
    name: 'カレンダー年送り',
    eventParams: { event_click_value: '戻る' | '進む' }
  ): void;
  (name: 'モード雛形ダウンロード', eventParams?: undefined): void;
  (name: 'モードアップロード', eventParams?: undefined): void;
  (name: '購入費雛形ダウンロード', eventParams?: undefined): void;
  (name: '購入費アップロード', eventParams?: undefined): void;
  (name: 'モード確認', eventParams?: undefined): void;
  (name: 'エリア新規作成', eventParams?: undefined): void;
  (name: 'エリア編集', eventParams?: undefined): void;
  (name: 'エリア設定', eventParams?: undefined): void;
  (name: '検索ボタン', eventParams?: undefined): void;
  (
    name: '検索実行',
    eventParams: {
      event_click_value: '最新日' | '最新週' | 'カスタム';
    }
  ): void;
  (name: 'リセットボタン', eventParams?: undefined): void;
  (name: '検索結果を共有', eventParams?: undefined): void;
  (name: '店舗データ更新状況を確認', eventParams?: undefined): void;
  (name: 'リンクのコピー', eventParams?: undefined): void;
  (
    name: '画面リンク（フッター）',
    eventParams: {
      event_click_value: '導入後推移' | '機種別推移';
    }
  ): void;
  (
    name: '前年同月',
    eventParams: {
      event_click_state: EventClickState;
    }
  ): void;
  (
    name: '着地見込',
    eventParams: {
      event_click_state: EventClickState;
    }
  ): void;
  (
    name: 'DK-SIS期間 自動/手動切り替え',
    eventParams: { event_click_value: '自動' | '手動' }
  ): void;
  (name: 'DK-SIS条件変更適用', eventParams?: undefined): void;
};

type GaFavoriteEvent = {
  (
    name: 'DK-SISスイッチ',
    eventParams: {
      event_favorite_id: number;
      event_favorite_page: '機種集計' | '導入後推移';
    }
  ): void;
  (
    name:
      | 'チェック機種絞込'
      | 'マーキング適用'
      | '展開行 1階層目'
      | '展開行 2階層目'
      | 'チェーン店一覧'
      | '台別データ'
      | '推移グラフ（店舗比較）'
      | '推移グラフ PPMシェア'
      | '推移グラフ（機種比較）'
      | '数値フィルタ実行',
    eventParams: {
      event_favorite_id: number;
      event_favorite_page: '機種集計';
    }
  ): void;
  (
    name: '機種タグ絞込',
    eventParams: {
      event_favorite_id: number;
      event_favorite_page: '機種集計';
      event_click_value: string;
      event_click_state: EventClickState;
    }
  ): void;
  (
    name: 'エリア集計スイッチ',
    eventParams: {
      event_click_state: EventClickState;
      event_favorite_id: number;
      event_favorite_page: 'チェーン店レポート';
    }
  ): void;
};

export const gaClickEvent: GaClickEvent = (name, eventParams) => {
  if (window.gtag) {
    window.gtag('event', 'click_custom', {
      event_click_name: name,
      ...(eventParams ? eventParams : {}),
    });
  }
};

export const gaFavoriteEvent: GaFavoriteEvent = (name, eventParams) => {
  if (window.gtag) {
    window.gtag('event', 'favorite_custom', {
      event_click_name: name,
      ...(eventParams ? eventParams : {}),
    });
  }
};
