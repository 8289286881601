import { AxiosResponse } from 'axios';
import { call, fork, put, select, takeEvery } from 'redux-saga/effects';

import { ChainReportsFormConditions } from '../domain/chainReportsFormConditions';
import { DataKadoGraph, DataKadoGraphParams } from '../domain/dataKadoGraph';

import {
  DataChainStoreShuKadoGraphActionCreators,
  DataChainStoreShuKadoGraphActionTypes,
  FetchDataChainStoreShuKadoGraphAction,
  SearchDataChainStoreShuKadoGraphAction,
  SearchDataChainStoreShuKadoGraphHallAction,
  SearchDataChainStoreShuKadoGraphKadoTypeAction,
  dataChainStoreShuKadoGraphSearchConditionSelector,
} from '../redux/server/dataChainStoreShuKadoGraph';
import { chainStoreReportsSearchConditionSelector } from '../redux/ui/chainStoreReportsSetting';
import { Api, buildConfig } from '../utils/api';
import { getHallsAndAreas } from '../utils/getHallsAndAreas';
import { selectShu2SearchCondition } from '../utils/shu';
import { handleErrorSaga } from './errorSaga';

/**
 * 時間帯別稼働数データ取得処理
 */
export function* fetchDataChainStoreShuKadoGraphSaga(
  api: Api,
  action: FetchDataChainStoreShuKadoGraphAction
) {
  const { shuCode, params } = action.payload;
  try {
    yield put(
      DataChainStoreShuKadoGraphActionCreators.fetchDataChainStoreShuKadoGraphRequestAction(
        shuCode
      )
    );
    const response: AxiosResponse<DataKadoGraph> = yield call(
      api.get,
      '/data/hall/shu/kado/graph',
      buildConfig(params)
    );
    yield put(
      DataChainStoreShuKadoGraphActionCreators.fetchDataChainStoreShuKadoGraphSuccessAction(
        shuCode,
        response.data
      )
    );
  } catch (error: unknown) {
    yield put(
      DataChainStoreShuKadoGraphActionCreators.renewDataChainStoreShuKadoGraphAction()
    );
    yield fork(handleErrorSaga, error);
  }
}

function* handleFetchSettingsOptionsPlanSaga(api: Api) {
  yield takeEvery(
    DataChainStoreShuKadoGraphActionTypes.FETCH_DATA_CHAIN_STORE_SHU_KADO_GRAPH,
    fetchDataChainStoreShuKadoGraphSaga,
    api
  );
}

/**
 * クリックしたセルの時間帯別稼働数データを取得する
 */
function* searchChainStoreShuKadoGraphSaga(
  action: SearchDataChainStoreShuKadoGraphAction
) {
  const { selectOption, shuOption } = action.payload;
  const shuCode = shuOption?.code ?? 'null';

  // 検索フォームの検索条件を取得
  const searchCondition: ChainReportsFormConditions = yield select(
    chainStoreReportsSearchConditionSelector
  );

  // 取得済みのデータの検索条件
  const graphSearchCondition: DataKadoGraphParams = yield select(
    dataChainStoreShuKadoGraphSearchConditionSelector(shuCode)
  );

  yield put(
    DataChainStoreShuKadoGraphActionCreators.fetchDataChainStoreShuKadoGraphAction(
      shuCode,
      {
        ...graphSearchCondition,
        // MEMO: 種別がある場合は種別コードをセットし、ない場合（店舗全体実績）は種別の指定を空にする
        ...(shuOption ? selectShu2SearchCondition(shuOption) : {}),
        ...getHallsAndAreas(searchCondition, selectOption),
        ymdList: searchCondition.ymdList,
        ymdComparisonList: searchCondition.ymdComparisonList,
      }
    )
  );
}

/**
 * 指定した時間帯別稼働数の店舗のデータを再取得する
 */
function* searchChainStoreShuKadoGraphHallSaga(
  action: SearchDataChainStoreShuKadoGraphHallAction
) {
  const { shuOption, selectOption } = action.payload;
  const shuCode = shuOption?.code ?? 'null';

  // 検索フォームの検索条件を取得
  const searchCondition: ChainReportsFormConditions = yield select(
    chainStoreReportsSearchConditionSelector
  );

  // 取得済みのデータの検索条件
  const graphSearchCondition: DataKadoGraphParams = yield select(
    dataChainStoreShuKadoGraphSearchConditionSelector(shuCode)
  );

  yield put(
    DataChainStoreShuKadoGraphActionCreators.fetchDataChainStoreShuKadoGraphAction(
      shuCode,
      {
        ...graphSearchCondition,
        ...getHallsAndAreas(searchCondition, selectOption),
      }
    )
  );
}

/**
 * 指定した時間帯別稼働数の稼働数・稼働率のデータを再取得する
 */
function* searchChainStoreShuKadoGraphKadoTypeSaga(
  action: SearchDataChainStoreShuKadoGraphKadoTypeAction
) {
  const { shuOption, kadoFieldType } = action.payload;
  const shuCode = shuOption?.code ?? 'null';

  // 取得済みのデータの検索条件
  const searchCondition: DataKadoGraphParams = yield select(
    dataChainStoreShuKadoGraphSearchConditionSelector(shuCode)
  );

  yield put(
    DataChainStoreShuKadoGraphActionCreators.fetchDataChainStoreShuKadoGraphAction(
      shuCode,
      {
        ...searchCondition,
        kadoFieldType,
      }
    )
  );
}

/**
 * 検索関連の処理
 */
function* handleSearchSaga() {
  // セルの時間帯別稼働数ボタンクリック時
  yield takeEvery(
    DataChainStoreShuKadoGraphActionTypes.SEARCH_DATA_CHAIN_STORE_SHU_KADO_GRAPH,
    searchChainStoreShuKadoGraphSaga
  );
  // 時間帯別稼働数の店舗変更時
  yield takeEvery(
    DataChainStoreShuKadoGraphActionTypes.SEARCH_DATA_CHAIN_STORE_SHU_KADO_GRAPH_HALL,
    searchChainStoreShuKadoGraphHallSaga
  );
  // 時間帯別稼働数の稼働数・稼働率変更時
  yield takeEvery(
    DataChainStoreShuKadoGraphActionTypes.SEARCH_DATA_CHAIN_STORE_SHU_KADO_GRAPH_KADO_TYPE,
    searchChainStoreShuKadoGraphKadoTypeSaga
  );
}

export function* dataChainStoreShuKadoGraphSaga(context: { api: Api }) {
  yield fork(handleFetchSettingsOptionsPlanSaga, context.api);
  yield fork(handleSearchSaga);
}
