import { createSelector } from 'reselect';

import {
  DataTerminalTransitionGraph,
  DataTerminalTransitionGraphParams,
} from '../../domain/dataTerminalTransitionGraph';
import { LoadingState } from '../../domain/schemas';

import { RootState } from '../../store';

// Action Types

const FETCH_DATA_TERMINAL_TRANSITION_GRAPH = 'FETCH_DATA_TERMINAL_TRANSITION_GRAPH' as const;
const FETCH_DATA_TERMINAL_TRANSITION_GRAPH_REQUEST = 'FETCH_DATA_TERMINAL_TRANSITION_GRAPH_REQUEST' as const;
const FETCH_DATA_TERMINAL_TRANSITION_GRAPH_SUCCESS = 'FETCH_DATA_TERMINAL_TRANSITION_GRAPH_SUCCESS' as const;
const SEARCH_DATA_TERMINAL_TRANSITION_GRAPH = 'SEARCH_DATA_TERMINAL_TRANSITION_GRAPH' as const;
const SEARCH_DATA_TERMINAL_TRANSITION_GRAPH_HALL = 'SEARCH_DATA_TERMINAL_TRANSITION_GRAPH_HALL' as const;
const SEARCH_DATA_TERMINAL_TRANSITION_GRAPH_DATE = 'SEARCH_DATA_TERMINAL_TRANSITION_GRAPH_DATE' as const;
const SEARCH_DATA_TERMINAL_TRANSITION_GRAPH_FIELDS = 'SEARCH_DATA_TERMINAL_TRANSITION_GRAPH_FIELDS' as const;
const RENEW_DATA_TERMINAL_TRANSITION_GRAPH = 'RENEW_DATA_TERMINAL_TRANSITION_GRAPH' as const;

export const DataTerminalTransitionGraphActionTypes = {
  FETCH_DATA_TERMINAL_TRANSITION_GRAPH,
  FETCH_DATA_TERMINAL_TRANSITION_GRAPH_REQUEST,
  FETCH_DATA_TERMINAL_TRANSITION_GRAPH_SUCCESS,
  SEARCH_DATA_TERMINAL_TRANSITION_GRAPH,
  SEARCH_DATA_TERMINAL_TRANSITION_GRAPH_HALL,
  SEARCH_DATA_TERMINAL_TRANSITION_GRAPH_DATE,
  SEARCH_DATA_TERMINAL_TRANSITION_GRAPH_FIELDS,
  RENEW_DATA_TERMINAL_TRANSITION_GRAPH,
};

// Action Creators

/**
 * 推移グラフデータを取得する
 * @param shuCode 表示先テーブルの種別コード
 * @param params 推移グラフの検索条件
 */
function fetchDataTerminalTransitionGraphAction(
  params: DataTerminalTransitionGraphParams
) {
  return {
    type: FETCH_DATA_TERMINAL_TRANSITION_GRAPH,
    payload: { params },
  };
}

/**
 * 推移グラフ取得前に実行する
 */
function fetchDataTerminalTransitionGraphRequestAction() {
  return {
    type: FETCH_DATA_TERMINAL_TRANSITION_GRAPH_REQUEST,
  };
}

/**
 * 推移グラフデータ取得完了時データを登録する
 * @param dataTerminalTransitionGraph 取得したグラフデータ
 */
function fetchDataTerminalTransitionGraphSuccessAction(
  dataTerminalTransitionGraph: DataTerminalTransitionGraph
) {
  return {
    type: FETCH_DATA_TERMINAL_TRANSITION_GRAPH_SUCCESS,
    payload: { dataTerminalTransitionGraph },
  };
}

/**
 * 選択した店舗の推移グラフを表示する
 * @param shuGroupId 種別データ
 * @param shuList 種別データ
 * @param halls 表示する店舗コード
 * @param field 表示するグラフ項目
 */
function searchDataTerminalTransitionGraphAction(
  shuGroupId: string[],
  shuList: string[],
  halls: string[],
  field?: string
) {
  return {
    type: SEARCH_DATA_TERMINAL_TRANSITION_GRAPH,
    payload: { shuGroupId, shuList, halls, field },
  };
}

/**
 * 指定した店舗でグラフを絞り込む（店舗選択フォーム用）
 * @param hall 絞り込む店舗
 */
function searchDataTerminalTransitionGraphHallAction(hall: string[]) {
  return {
    type: SEARCH_DATA_TERMINAL_TRANSITION_GRAPH_HALL,
    payload: { hall },
  };
}

/**
 * 指定した期間で推移グラフデータを再取得
 * @param startDate 開始日
 * @param endDate 終了日
 */
function searchDataTerminalTransitionGraphDateAction(
  startDate: string,
  endDate: string
) {
  return {
    type: SEARCH_DATA_TERMINAL_TRANSITION_GRAPH_DATE,
    payload: { startDate, endDate },
  };
}

/**
 * 指定したグラフ項目で推移グラフデータを再取得
 * @param field 棒グラフのグラフ項目
 */
function searchDataTerminalTransitionGraphFieldsAction(field: string) {
  return {
    type: SEARCH_DATA_TERMINAL_TRANSITION_GRAPH_FIELDS,
    payload: { field },
  };
}

/**
 * 推移グラフデータ取得失敗時にエラー内容を登録する
 * @param error エラー内容
 */
function renewDataTerminalTransitionGraphAction() {
  return {
    type: RENEW_DATA_TERMINAL_TRANSITION_GRAPH,
  };
}

export const DataTerminalTransitionGraphActionCreators = {
  fetchDataTerminalTransitionGraphAction,
  fetchDataTerminalTransitionGraphRequestAction,
  fetchDataTerminalTransitionGraphSuccessAction,
  searchDataTerminalTransitionGraphAction,
  searchDataTerminalTransitionGraphHallAction,
  searchDataTerminalTransitionGraphDateAction,
  searchDataTerminalTransitionGraphFieldsAction,
  renewDataTerminalTransitionGraphAction,
};

// Actions

export type FetchDataTerminalTransitionGraphAction = ReturnType<
  typeof fetchDataTerminalTransitionGraphAction
>;
export type SearchDataTerminalTransitionGraphAction = ReturnType<
  typeof searchDataTerminalTransitionGraphAction
>;
export type SearchDataTerminalTransitionGraphHallAction = ReturnType<
  typeof searchDataTerminalTransitionGraphHallAction
>;
export type SearchDataTerminalTransitionGraphDateAction = ReturnType<
  typeof searchDataTerminalTransitionGraphDateAction
>;
export type SearchDataTerminalTransitionGraphFieldsAction = ReturnType<
  typeof searchDataTerminalTransitionGraphFieldsAction
>;

type DataTerminalTransitionGraphAction =
  | FetchDataTerminalTransitionGraphAction
  | SearchDataTerminalTransitionGraphAction
  | SearchDataTerminalTransitionGraphHallAction
  | SearchDataTerminalTransitionGraphDateAction
  | SearchDataTerminalTransitionGraphFieldsAction
  | ReturnType<typeof fetchDataTerminalTransitionGraphRequestAction>
  | ReturnType<typeof fetchDataTerminalTransitionGraphSuccessAction>
  | ReturnType<typeof renewDataTerminalTransitionGraphAction>;

// State

type DataTerminalTransitionGraphState = {
  dataTerminalTransitionGraph: DataTerminalTransitionGraph | undefined;
  loadingState: LoadingState;
};

const initialState: DataTerminalTransitionGraphState = {
  dataTerminalTransitionGraph: undefined,
  loadingState: 'prepare',
};

// Selector

/**
 * 全てのグラフデータを取得する
 * @returns 全てのグラフデータ
 */
export const dataTerminalTransitionGraphAllSelector = (state: RootState) =>
  state.dataTerminalTransitionGraph.dataTerminalTransitionGraph;

/**
 * 推移グラフの検索条件を取得する
 * @returns 推移グラフ（店舗比較）の検索条件
 */
export const dataTerminalTransitionGraphSearchConditionSelector = () =>
  createSelector(
    dataTerminalTransitionGraphAllSelector,
    (data) => data?.setting ?? ({} as DataTerminalTransitionGraphParams)
  );

/**
 * ローディング状態を取得する
 */
export const dataTerminalTransitionGraphLoadingStateSelector = (
  state: RootState
) => state.dataTerminalTransitionGraph.loadingState;

export function dataTerminalTransitionGraphReducer(
  state = initialState,
  action: DataTerminalTransitionGraphAction
): DataTerminalTransitionGraphState {
  switch (action.type) {
    case FETCH_DATA_TERMINAL_TRANSITION_GRAPH_REQUEST:
      return {
        ...state,
        loadingState: 'loading',
      };
    case FETCH_DATA_TERMINAL_TRANSITION_GRAPH_SUCCESS:
      return {
        ...state,
        loadingState: 'loaded',
        dataTerminalTransitionGraph: action.payload.dataTerminalTransitionGraph,
      };
    case RENEW_DATA_TERMINAL_TRANSITION_GRAPH:
      return initialState;
    default:
      return state;
  }
}
