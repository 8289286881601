import { createSelector } from 'reselect';

import { shareOptions } from '../../domain/home/consistent';

/* ---------------------------------------------------------------
 * Action Types
 */

const SELECT_HOME_FIELDS_SHARE_FILTER = 'SELECT_HOME_FIELDS_SHARE_FILTER' as const;

export const HomeSettingActionTypes = {
  SELECT_HOME_FIELDS_SHARE_FILTER,
};

/* ---------------------------------------------------------------
 * Action Creators
 */

/**
 * 表示項目の共有のフィルターの変更
 */
function selectHomeFieldsShareFilterAction(
  shareOption: typeof shareOptions[number]
) {
  return {
    type: SELECT_HOME_FIELDS_SHARE_FILTER,
    payload: { shareOption },
  };
}

export const HomeSettingActionCreators = {
  selectHomeFieldsShareFilterAction,
};

/* ---------------------------------------------------------------
 * Actions
 */

type HomeSettingAction = ReturnType<typeof selectHomeFieldsShareFilterAction>;

/* ---------------------------------------------------------------
 * State
 */

type HomeSettingState = {
  fields: {
    share: {
      filter: typeof shareOptions[number];
    };
  };
};

const initialState: HomeSettingState = {
  fields: {
    share: {
      filter: 'すべて',
    },
  },
};

/* ---------------------------------------------------------------
 * Selector
 */

function homeSettingSelector(rootState: { homeSetting: HomeSettingState }) {
  return rootState.homeSetting;
}

/**
 * [ホーム] 表示項目の共有で利用されるフィルター
 */
export const homeSettingFieldsShareFilterSelector = createSelector(
  homeSettingSelector,
  (homeSetting) => {
    return homeSetting.fields.share.filter;
  }
);

/* ---------------------------------------------------------------
 * Reducer
 */

export function homeSettingReducer(
  state = initialState,
  action: HomeSettingAction
): HomeSettingState {
  switch (action.type) {
    case SELECT_HOME_FIELDS_SHARE_FILTER:
      return {
        ...state,
        fields: {
          ...state.fields,
          share: {
            ...state.fields.share,
            filter: action.payload.shareOption,
          },
        },
      };

    default:
      return state;
  }
}
