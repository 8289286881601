import { createSelector } from 'reselect';

import { LoadingState } from '../../domain/schemas';
import { SettingsModesDetails } from '../../domain/settingsModes';

import { getModesSettingsSortedData } from '../../utils/getModesSettingsSortedData';
import { settingsModesDetailsSelector } from '../ui/settingsModesDetails';

// Action Types

const FETCH_DATA_SETTINGS_MODES_DETAILS = 'FETCH_DATA_SETTINGS_MODES_DETAILS' as const;
const FETCH_DATA_SETTINGS_MODES_DETAILS_REQUEST = 'FETCH_DATA_SETTINGS_MODES_DETAILS_REQUEST' as const;
const FETCH_DATA_SETTINGS_MODES_DETAILS_SUCCESS = 'FETCH_DATA_SETTINGS_MODES_DETAILS_SUCCESS' as const;
const FETCH_DATA_SETTINGS_MODES_DETAILS_FAILURE = 'FETCH_DATA_SETTINGS_MODES_DETAILS_FAILURE' as const;

export const DataSettingsModesDetailsActionTypes = {
  FETCH_DATA_SETTINGS_MODES_DETAILS,
  FETCH_DATA_SETTINGS_MODES_DETAILS_REQUEST,
  FETCH_DATA_SETTINGS_MODES_DETAILS_SUCCESS,
  FETCH_DATA_SETTINGS_MODES_DETAILS_FAILURE,
};

// Action Creators

function fetchDataSettingsModesDetailsAction(date: string, hallCode: string) {
  return {
    type: FETCH_DATA_SETTINGS_MODES_DETAILS,
    payload: { date, hallCode },
  };
}

function fetchDataSettingsModesDetailsRequestAction() {
  return {
    type: FETCH_DATA_SETTINGS_MODES_DETAILS_REQUEST,
  };
}

function fetchDataSettingsModesDetailsSuccessAction(
  settingsModesDetails: SettingsModesDetails
) {
  return {
    type: FETCH_DATA_SETTINGS_MODES_DETAILS_SUCCESS,
    payload: { settingsModesDetails },
  };
}

function renewDataSettingsModesDetailsAction() {
  return {
    type: FETCH_DATA_SETTINGS_MODES_DETAILS_FAILURE,
  };
}

export const DataSettingsModesDetailsActionCreators = {
  fetchDataSettingsModesDetailsAction,
  fetchDataSettingsModesDetailsRequestAction,
  fetchDataSettingsModesDetailsSuccessAction,
  renewDataSettingsModesDetailsAction,
};

export type FetchDataSettingsModesDetailsAction = ReturnType<
  typeof fetchDataSettingsModesDetailsAction
>;

type DataSettingsModesDetailsAction =
  | FetchDataSettingsModesDetailsAction
  | ReturnType<typeof fetchDataSettingsModesDetailsRequestAction>
  | ReturnType<typeof fetchDataSettingsModesDetailsSuccessAction>
  | ReturnType<typeof renewDataSettingsModesDetailsAction>;

// State

type DataSettingsModesDetailsState = {
  settingsModesDetails: SettingsModesDetails | undefined;
  loadingState: LoadingState;
};

const initialState: DataSettingsModesDetailsState = {
  settingsModesDetails: undefined,
  loadingState: 'prepare',
};

// Selector

/**
 * モード登録モード確認のデータ
 */
function dataSettingsModesDetailsSelector(rootState: {
  dataSettingsModesDetails: DataSettingsModesDetailsState;
}) {
  return rootState.dataSettingsModesDetails.settingsModesDetails;
}

/**
 * モード登録モード確認のデータ（ソート状態を反映）
 */
export const dataSettingsModesDetailsSortedSelector = createSelector(
  [dataSettingsModesDetailsSelector, settingsModesDetailsSelector],
  (data, setting) => {
    const sort = setting.sort;
    const order = setting.order;
    return getModesSettingsSortedData(data, order, sort);
  }
);

/**
 * モード登録モード確認のデータローディング状態
 */
export function dataSettingsModesDetailsLoadingSelector(rootState: {
  dataSettingsModesDetails: DataSettingsModesDetailsState;
}) {
  return rootState.dataSettingsModesDetails.loadingState;
}

// Reducer

export function dataSettingsModesDetailsReducer(
  state = initialState,
  action: DataSettingsModesDetailsAction
): DataSettingsModesDetailsState {
  switch (action.type) {
    case FETCH_DATA_SETTINGS_MODES_DETAILS_REQUEST:
      return {
        ...state,
        loadingState: 'loading',
      };
    case FETCH_DATA_SETTINGS_MODES_DETAILS_SUCCESS:
      return {
        ...state,
        settingsModesDetails: action.payload.settingsModesDetails,
        loadingState: 'loaded',
      };
    case FETCH_DATA_SETTINGS_MODES_DETAILS_FAILURE:
      return initialState;
    default:
      return state;
  }
}
