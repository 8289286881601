import { SxProps, Theme } from '@mui/material';

export const mergeSxProps = (
  ...sx: (SxProps<Theme> | false)[]
): SxProps<Theme> => {
  return [
    ...sx
      .filter((item) => item !== false)
      .flatMap((item) => (Array.isArray(item) ? item : [item])),
  ];
};
