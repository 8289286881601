import { SettingsOptionsDai } from './types';

export const settingsOptionsDaiDefaultValue: SettingsOptionsDai = {
  searchCondition: {
    halls: [],
    shuList: [],
    shuGroupList: [],
  },
  fields: {
    dai: [],
  },
  genres: {
    dai: [],
  },
  kiCodeToPSensorKiCode: {},
};
