import type { ComponentProps, FC } from 'react';

type Props = ComponentProps<'svg'>;

export const RemovalDecreaseTableCandidatesIcon: FC<Props> = (props) => (
  <svg
    width="19"
    height="21"
    viewBox="0 0 19 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.64316 4H0.5V18.5711C0.5 19.2153 0.725796 19.8331 1.12772 20.2886C1.52964 20.7441 2.07476 21 2.64316 21H15.5V18.5711H2.64316V4Z"
      fill="black"
      fillOpacity="0.54"
    />
    <path
      d="M6.87323 17.1103V14.6655H5C4.99975 14.9867 5.04804 15.3047 5.14209 15.6015C5.23614 15.8982 5.37411 16.1679 5.54811 16.3949C5.7221 16.622 5.9287 16.8021 6.15607 16.9248C6.38345 17.0476 6.62717 17.1106 6.87323 17.1103Z"
      fill="black"
      fillOpacity="0.54"
    />
    <path
      d="M6.87322 0C6.3764 0 5.89994 0.257567 5.54865 0.716038C5.19735 1.17451 5 1.79633 5 2.44472H6.87322V0Z"
      fill="black"
      fillOpacity="0.54"
    />
    <path
      d="M6.87322 9.77637H5V12.2211H6.87322V9.77637Z"
      fill="black"
      fillOpacity="0.54"
    />
    <path
      d="M10.6193 0H8.74609V2.44472H10.6193V0Z"
      fill="black"
      fillOpacity="0.54"
    />
    <path
      d="M14.3654 0H12.4922V2.44472H14.3654V0Z"
      fill="black"
      fillOpacity="0.54"
    />
    <path
      d="M16.2383 0V2.44472H18.1115C18.11 1.79693 17.9122 1.17622 17.5612 0.718163C17.2102 0.260103 16.7346 0.00191938 16.2383 0Z"
      fill="black"
      fillOpacity="0.54"
    />
    <path
      d="M6.87322 4.88916H5V7.3339H6.87322V4.88916Z"
      fill="black"
      fillOpacity="0.54"
    />
    <path
      d="M16.2383 17.1103C16.7346 17.1084 17.2102 16.8501 17.5612 16.3921C17.9122 15.934 18.11 15.3134 18.1115 14.6655H16.2383V17.1103Z"
      fill="black"
      fillOpacity="0.54"
    />
    <path
      d="M18.1115 9.77637H16.2383V12.2211H18.1115V9.77637Z"
      fill="black"
      fillOpacity="0.54"
    />
    <path
      d="M18.1115 4.88916H16.2383V7.3339H18.1115V4.88916Z"
      fill="black"
      fillOpacity="0.54"
    />
    <path
      d="M10.6193 14.6655H8.74609V17.1103H10.6193V14.6655Z"
      fill="black"
      fillOpacity="0.54"
    />
    <path
      d="M14.3654 14.6655H12.4922V17.1103H14.3654V14.6655Z"
      fill="black"
      fillOpacity="0.54"
    />
    <path
      d="M14.9378 7.75391H8.58594V9.56079H14.9378V7.75391Z"
      fill="black"
      fillOpacity="0.54"
    />
  </svg>
);
