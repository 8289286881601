import { DataDepreciationResponse } from './types';

export const dataDepreciationFilteredByKiList = (
  dataDepreciation: DataDepreciationResponse,
  kiListFilter: string[] | undefined
): DataDepreciationResponse => {
  if (kiListFilter == null || kiListFilter.length === 0) {
    return dataDepreciation;
  }

  const rows = dataDepreciation.data.rows.filter((row) =>
    kiListFilter.includes(row.queryParameter.kiList?.at(0) ?? '')
  );

  return {
    ...dataDepreciation,
    data: {
      ...dataDepreciation.data,
      rows,
    },
  };
};
