import { eachDayOfInterval, format } from 'date-fns';
import { call, fork, put, select, take, takeEvery } from 'redux-saga/effects';

import { SettingsNationalHolidays } from '../domain/SettingsNationalHolidays';
import { errorMessages } from '../domain/errors/messages';
import { FavoriteItem } from '../domain/favorites';
import {
  makeModeDateFromDateRange,
  makeModeDateFromDateRangeWithoutCustom,
} from '../domain/mode/date';
import { defaultDateRangeParams } from '../domain/mode/defaultValue';
import { modeErrorMessages } from '../domain/mode/errors';
import {
  migrateFavorite,
  updateFavoriteSearchParams,
} from '../domain/mode/favorite';
import {
  ModeData,
  ModeDataKiList,
  ModeDateRangeParams,
  ModeFavorite,
  ModeFavoriteV1,
  ModeSearchParams,
  ModeSettingsOptions,
} from '../domain/mode/types';
import { LoadingState } from '../domain/schemas';

import {
  DataModeActionCreators,
  DataModeActionTypes,
  dataModeDataSelector,
  dataModeLoadingSelector,
} from '../redux/server/dataMode';
import {
  DataModeKiListActionCreators,
  DataModeKiListActionTypes,
  dataModeKiListDataSelector,
} from '../redux/server/dataModeKiList';
import {
  SettingsNationalHolidaysActionCreators,
  SettingsNationalHolidaysActionTypes,
  settingsNationalHolidaysSelector,
} from '../redux/server/settingsNationalHolidays';
import {
  SettingsOptionsModeActionCreators,
  SettingsOptionsModeActionTypes,
  settingsOptionsModeFieldsSelector,
  settingsOptionsModeLoadingStateSelector,
  settingsOptionsModeSearchConditionSelector,
} from '../redux/server/settingsOptionsMode';
import { ShortenedUrlActionCreators } from '../redux/server/shortenedUrl';
import { ErrorActionCreators } from '../redux/ui/error';
import {
  ChangeModeCurrentKiAction,
  ChangeModeCurrentShuAction,
  ChangeModeFieldsAction,
  CreateModeShortenedUrlAction,
  ModeSettingActionCreators,
  ModeSettingActionTypes,
  ModeSettingState,
  SaveAsModeFavoriteAction,
  SaveModeFavoriteAction,
  SaveModeFavoriteMemoAction,
  SearchDataModeAction,
  TriggerModeSwapFieldsAction,
  TriggerNotModeSwapFieldsAction,
  modeColumnsModeSpecificOrderSelector,
  modeColumnsNotModeSpecificOrderSelector,
  modeCurrentKiSelector,
  modeSearchParamsSelector,
  modeSelectedFavoriteDataSelector,
  modeSelectedFavoriteSelector,
  modeSettingSelector,
} from '../redux/ui/modeSettings';
import { SettingsFavoritesActionCreators } from '../redux/ui/settingsFavorites';
import { compressToEncodedURIComponent } from '../utils/compressToEncodedURIComponent';
import { excludeHoliday } from '../utils/excludeHoliday';
import { excludeWeekday } from '../utils/excludeWeekday';
import { formatWeekdayAndHoliday } from '../utils/formatWeekdayAndHoliday';
import { isValidArea } from '../utils/isValidArea';
import { recalcColumnOrder } from '../utils/orderedCell';
import {
  makeInitialShu,
  selectShu2SearchCondition,
  updateShuParamsIfNeeded,
} from '../utils/shu';

/**
 * テーブルデータfetch用のラッパー関数。fetchしかつsearchParamsに値を設定する
 *
 * searchParamsの書き換えを行うため、使用しない検索条件のmakersとsisTypesを含めること
 * 含まれていない場合前回の値を引き継ぎます（例えば種別スライダーでは前回の値を引き継ぎます）
 * これはModeSearchParamsにデータテーブルと機種リストの条件が混在しているために必要な対応です
 */
function* fetchDataModeSaga(searchParams: ModeSearchParams) {
  const { makers, sisTypes, ...params } = searchParams;

  yield put(DataModeActionCreators.fetchDataModeAction(params));

  yield take(DataModeActionTypes.FETCH_DATA_MODE_SUCCESS);
  const dataMode: ModeData = yield select(dataModeDataSelector);

  const prev: ModeSearchParams | undefined = yield select(
    modeSearchParamsSelector
  );

  yield put(
    ModeSettingActionCreators.selectModeSearchParamsAction({
      ...dataMode.setting,
      makers: makers ?? prev?.makers ?? [],
      sisTypes: sisTypes ?? prev?.sisTypes ?? [],
    })
  );
}

/**
 * 検索用のクエリの日付を生成する、平日/土日祝のクエリの場合にはデータがAPIへリクエストする
 */
function* makeSearchParams(
  dateRangeParams: ModeDateRangeParams,
  searchParams: ModeSearchParams
) {
  const {
    // 日付関連パラメータはdateRangeParamsで追加されるためすべて除外する
    startDate,
    endDate,
    startComparisonDate: _0,
    endComparisonDate: _1,
    ymdList,
    ymdComparisonList,
    ...params
  } = searchParams;

  if (dateRangeParams.comparisonSection !== '平日/土日祝比較') {
    return {
      ...params,
      ...(dateRangeParams.dateUnit === '自由選択'
        ? { ymdList, ymdComparisonList }
        : makeModeDateFromDateRange({
            dateRangeParams,
            searchParams,
          })),
    };
  }

  // 比較区分で平日/土日祝の場合除外するため
  const {
    dayType: _2,
    days: _3,
    dateSuffixes: _4,
    dates: _5,
    ...restParams
  } = params;

  const nationalHolidays: SettingsNationalHolidays | undefined = yield select(
    settingsNationalHolidaysSelector
  );

  // 比較区分で平日/土日祝比較の場合、祝日情報がない場合は取得する
  if (nationalHolidays == null) {
    yield put(
      SettingsNationalHolidaysActionCreators.fetchSettingsNationalHolidaysAction()
    );
    yield take(
      SettingsNationalHolidaysActionTypes.FETCH_SETTINGS_NATIONAL_HOLIDAYS_SUCCESS
    );
  }

  const { holidays }: SettingsNationalHolidays = yield select(
    settingsNationalHolidaysSelector
  );

  if (dateRangeParams.dateUnit === '自由選択') {
    const dateList = ymdList?.map((v) => new Date(v)) ?? [];
    return {
      ...restParams,
      ...formatWeekdayAndHoliday({
        ymdList: excludeHoliday(dateList, holidays).map((v) =>
          format(v, 'yyyy-MM-dd')
        ),
        ymdComparisonList: excludeWeekday(dateList, holidays).map((v) =>
          format(v, 'yyyy-MM-dd')
        ),
      }),
    };
  }

  const start = startDate ?? ymdList?.at(0);
  const end = endDate ?? ymdList?.at(-1);
  if (start == null || end == null) {
    throw new Error('日付が指定されていません');
  }

  const dateList = eachDayOfInterval({
    start: new Date(start),
    end: new Date(end),
  });
  return {
    ...formatWeekdayAndHoliday({
      ymdList: excludeHoliday(dateList, holidays).map((v) =>
        format(v, 'yyyy-MM-dd')
      ),
      ymdComparisonList: excludeWeekday(dateList, holidays).map((v) =>
        format(v, 'yyyy-MM-dd')
      ),
    }),
  };
}

/**
 * デフォルト値でリクエスト
 */
function* defaultRequestSaga() {
  yield put(DataModeKiListActionCreators.fetchDataModeKiListAction(undefined));
  yield take(DataModeKiListActionTypes.FETCH_DATA_MODE_KILIST_SUCCESS);

  const dataModeKiList: ModeDataKiList = yield select(
    dataModeKiListDataSelector
  );

  // NOTE:
  // 初回リクエストは機種リストの先頭をセットしてリクエストする
  const kiCode = dataModeKiList.data.kiList.at(0)?.code;
  if (kiCode == null) {
    // fetchDataModeKiListActionでエラーハンドリング済
    return;
  }

  const settingsOptionsLoadingState: LoadingState = yield select(
    settingsOptionsModeLoadingStateSelector
  );

  if (
    settingsOptionsLoadingState === 'prepare' ||
    settingsOptionsLoadingState === 'loading'
  ) {
    yield take(
      SettingsOptionsModeActionTypes.FETCH_SETTINGS_OPTIONS_MODE_SUCCESS
    );
  }

  const {
    startDate,
    startComparisonDate,
    endDate,
    endComparisonDate,
  } = makeModeDateFromDateRangeWithoutCustom({
    dateRangeParams: defaultDateRangeParams,
  });

  const searchCondition: ModeSettingsOptions['searchCondition'] = yield select(
    settingsOptionsModeSearchConditionSelector
  );

  const fields: ModeSettingsOptions['fields'] = yield select(
    settingsOptionsModeFieldsSelector
  );

  yield fork(fetchDataModeSaga, {
    kiList: [kiCode],
    startDate: startDate,
    endDate: endDate,
    startComparisonDate: startComparisonDate,
    endComparisonDate: endComparisonDate,
    notModeSpecificFields: fields.notMode
      .filter(({ isDefault }) => isDefault)
      .map(({ code }) => code),
    ...makeInitialShu(searchCondition),
  });
}

/**
 * 初回リクエスト
 */
function* initDataModeSaga() {
  const dataLoadingState: LoadingState = yield select(dataModeLoadingSelector);
  if (dataLoadingState === 'loaded') {
    return;
  }

  yield fork(defaultRequestSaga);
}

/**
 * 種別変更時のリクエスト
 */
function* changeCurrentShuSaga(action: ChangeModeCurrentShuAction) {
  const { currentShu } = action.payload;

  const dataMode: ModeData = yield select(dataModeDataSelector);

  yield fork(fetchDataModeSaga, {
    ...dataMode.setting,
    ...selectShu2SearchCondition(currentShu),
  });
}

/**
 * 機種変更時のリクエスト
 */
function* changeCurrentKiSaga(action: ChangeModeCurrentKiAction) {
  const { kiCode } = action.payload;

  const dataMode: ModeData = yield select(dataModeDataSelector);

  yield fork(fetchDataModeSaga, {
    ...dataMode.setting,
    kiList: [kiCode],
  });
}

/**
 * 表示項目変更時のリクエスト
 */
function* changeModeFieldsSaga(action: ChangeModeFieldsAction) {
  const { notModeFields, modeFields } = action.payload;

  const dataMode: ModeData = yield select(dataModeDataSelector);

  yield fork(fetchDataModeSaga, {
    ...dataMode.setting,
    notModeSpecificFields: notModeFields,
    modeSpecificFields: modeFields,
  });

  // 新規追加された表示項目は一律前に追加する
  const allFields: ModeSettingsOptions['fields'] = yield select(
    settingsOptionsModeFieldsSelector
  );

  // 非モード項目
  const desiredNotModeOrder = allFields.notMode.map(({ code }) => code);
  const columnsNotModeOrder = dataMode.data.columnsNotModeSpecific
    .map((column) => column.code)
    .filter((code) => code != null) as string[];
  const differenceNotMode =
    notModeFields?.filter((field) => !columnsNotModeOrder.includes(field)) ??
    [];
  const sortedNotModeDifference = [...differenceNotMode].sort((a, b) => {
    if (desiredNotModeOrder == null) {
      return 0;
    }
    return desiredNotModeOrder.indexOf(a) < desiredNotModeOrder.indexOf(b)
      ? -1
      : 1;
  });
  const [hallMei, ...rest] = columnsNotModeOrder;
  const resultNotModeOrder = [hallMei, ...sortedNotModeDifference, ...rest];

  yield put(
    ModeSettingActionCreators.selectModeColumnsNotModeSpecificAction(
      resultNotModeOrder
    )
  );

  // モード項目
  const desiredModeOrder = allFields.mode.map(({ code }) => code);
  const columnsModeOrder = dataMode.data.columnsModeSpecific
    .map((column) => column.code)
    .filter((code) => code != null) as string[];
  const differenceMode =
    modeFields?.filter((field) => !columnsModeOrder.includes(field)) ?? [];
  const sortedModeDifference = [...differenceMode].sort((a, b) => {
    if (desiredModeOrder == null) {
      return 0;
    }
    return desiredModeOrder.indexOf(a) < desiredModeOrder.indexOf(b) ? -1 : 1;
  });

  const resultModeOrder = [...sortedModeDifference, ...columnsModeOrder];
  yield put(
    ModeSettingActionCreators.selectModeColumnsModeSpecificAction(
      resultModeOrder
    )
  );
}

/**
 * 非モード項目の入れ替え
 */
function* triggerNotModeSwapFieldsSaga(action: TriggerNotModeSwapFieldsAction) {
  const { draggedId, droppedId } = action.payload;

  const sisData: ModeData = yield select(dataModeDataSelector);

  const currentOrder: string[] = yield select(
    modeColumnsNotModeSpecificOrderSelector
  );

  const ordered = recalcColumnOrder(
    currentOrder.length === 0
      ? sisData.data.columnsNotModeSpecific
      : currentOrder.map((code) => ({ code })),
    draggedId,
    droppedId
  );

  yield put(
    ModeSettingActionCreators.selectModeColumnsNotModeSpecificAction(ordered)
  );
}

/**
 * モード項目の入れ替え
 */
function* triggerModeSwapFieldsSaga(action: TriggerModeSwapFieldsAction) {
  const { draggedId, droppedId } = action.payload;

  const sisData: ModeData = yield select(dataModeDataSelector);

  const currentOrder: string[] = yield select(
    modeColumnsModeSpecificOrderSelector
  );

  const ordered = recalcColumnOrder(
    currentOrder.length === 0
      ? sisData.data.columnsModeSpecific
      : currentOrder.map((code) => ({ code })),
    draggedId,
    droppedId
  );

  yield put(
    ModeSettingActionCreators.selectModeColumnsModeSpecificAction(ordered)
  );
}

/**
 * 検索ボタン押下
 */
function* searchDataModeSaga(action: SearchDataModeAction) {
  const {
    params,
    dateRangeParams,
    selectedShu,
    selectedKiList,
  } = action.payload;

  const modeData: ModeData = yield select(dataModeDataSelector);

  // 機種リストはある特定の検索条件によって変わるため、機種リストを再取得する
  // 特定条件が変わった場合にのみに再取得を絞っても良いかも
  yield put(
    DataModeKiListActionCreators.fetchDataModeKiListAction({
      areas: params.areas,
      halls: params.halls,
      makers: params.makers,
      kiList: selectedKiList,
      sisTypes: params.sisTypes,
      endDate: params.endDate ?? params.ymdList?.at(-1),
    })
  );
  yield take(DataModeKiListActionTypes.FETCH_DATA_MODE_KILIST_SUCCESS);
  const dataModeKiList: ModeDataKiList = yield select(
    dataModeKiListDataSelector
  );
  // NOTE: 機種が存在しなければdataのfetchを行わない
  if (dataModeKiList.data.kiList.length === 0) {
    return;
  }

  const searchParams: ModeSearchParams = yield call(
    makeSearchParams,
    dateRangeParams,
    params
  );

  const currentKi: string = yield select(modeCurrentKiSelector);

  yield fork(fetchDataModeSaga, {
    ...searchParams,
    ...updateShuParamsIfNeeded(
      {
        shuGroupIds: modeData?.setting.shuGroupIds,
        shuList: modeData?.setting.shuList,
      },
      selectedShu
    ),
    // 検索ボタンの押下では変更されない値は前回の値を引き継ぐ
    kiList: [currentKi],
    sort: modeData?.setting.sort,
    order: modeData?.setting.order,
    modeSpecificFields: modeData?.setting.modeSpecificFields,
    notModeSpecificFields: modeData?.setting.notModeSpecificFields,
  });
}

/**
 * リセットボタン押下
 */
function* resetSearchDataModeSaga() {
  const favoriteId: number | undefined = yield select(
    modeSelectedFavoriteSelector
  );

  // お気に入りが選択されていない場合はデフォルト値でリクエストする
  if (favoriteId == null) {
    yield fork(defaultRequestSaga);
    return;
  }

  yield put(ModeSettingActionCreators.changeModeFavoriteAction(favoriteId));
}

/**
 * お気に入りの新規保存
 */
function* saveAsFavoriteSaga(action: SaveAsModeFavoriteAction) {
  const modeSetting: ModeSettingState = yield select(modeSettingSelector);

  if (modeSetting.searchParams == null) {
    throw new Error('検索条件が存在しませんでした。');
  }

  const modeFavorite: ModeFavoriteV1 = {
    searchParams: modeSetting.searchParams,
    selectedShu: modeSetting.selectedShu,
    selectedKiList: modeSetting.selectedKiList,
    selectedDateRangeParams: modeSetting.selectedDateRangeParams,
    columnsNotModeSpecificOrder: modeSetting.columnsNotModeSpecificOrder,
    columnsModeSpecificOrder: modeSetting.columnsModeSpecificOrder,
  };

  yield put(
    SettingsFavoritesActionCreators.postSettingsFavoritesAction([
      {
        name: action.payload.name,
        isShared: action.payload.isShared,
        pageName: 'モード別集計',
        pageSetting: {
          mode: modeFavorite,
        },
        memo: action.payload.memo,
        privatelySharedUsers: action.payload.sharedUser,
      },
    ])
  );
}

/**
 * お気に入りの上書き保存
 */
function* saveFavoriteSaga(action: SaveModeFavoriteAction) {
  const id: number | undefined = yield select(modeSelectedFavoriteSelector);
  const selectedFavorite: FavoriteItem | undefined = yield select(
    modeSelectedFavoriteDataSelector
  );
  if (id == null || selectedFavorite == null) {
    throw new Error('選択されたお気に入りが存在しませんでした。');
  }

  const modeSetting: ModeSettingState = yield select(modeSettingSelector);

  if (modeSetting.searchParams == null) {
    throw new Error('検索条件が存在しませんでした。');
  }

  const modeFavorite: ModeFavoriteV1 = {
    searchParams: modeSetting.searchParams,
    selectedShu: modeSetting.selectedShu,
    selectedKiList: modeSetting.selectedKiList,
    selectedDateRangeParams: modeSetting.selectedDateRangeParams,
    columnsNotModeSpecificOrder: modeSetting.columnsNotModeSpecificOrder,
    columnsModeSpecificOrder: modeSetting.columnsModeSpecificOrder,
  };

  yield put(
    SettingsFavoritesActionCreators.patchSettingsFavoritesAction(id, {
      name: action.payload.name,
      isShared: action.payload.isShared,
      pageSetting: {
        mode: modeFavorite,
      },
      memo: action.payload.memo,
      privatelySharedUsers: action.payload.sharedUser,
    })
  );
}

/**
 * お気に入りのメモを上書き保存
 */
function* saveFavoriteMemoSaga(action: SaveModeFavoriteMemoAction) {
  const id: number | undefined = yield select(modeSelectedFavoriteSelector);
  const selectedFavorite: FavoriteItem | undefined = yield select(
    modeSelectedFavoriteDataSelector
  );
  if (id == null || selectedFavorite == null) {
    throw new Error('選択されたお気に入りが存在しませんでした。');
  }

  yield put(
    SettingsFavoritesActionCreators.patchSettingsFavoritesAction(id, {
      ...selectedFavorite,
      memo: action.payload.memo,
    })
  );
}

/**
 * お気に入り選択時、データを取得する
 */
function* applyFavoriteByIdSaga() {
  const loadingState: LoadingState = yield select(
    settingsOptionsModeLoadingStateSelector
  );
  if (loadingState === 'prepare') {
    yield put(
      SettingsOptionsModeActionCreators.fetchSettingsOptionsModeAction()
    );
    yield take(
      SettingsOptionsModeActionTypes.FETCH_SETTINGS_OPTIONS_MODE_SUCCESS
    );
  }

  const favorite: FavoriteItem | undefined = yield select(
    modeSelectedFavoriteDataSelector
  );

  // デフォルトのお気に入りを選択した場合、リセットと同じ動作を行う
  if (favorite?.pageSetting?.mode == null) {
    yield put(ModeSettingActionCreators.searchResetDataModeAction());
    return;
  }

  const lastUpdateAt = favorite.updatedAt ?? favorite.createdAt;
  if (lastUpdateAt == null) {
    throw new Error('お気に入りの作成日が存在しません');
  }

  yield fork(applyFavoriteSaga, favorite.pageSetting.mode, lastUpdateAt);
}

/**
 * ModeFavoriteの内容を実際に反映させる
 */
export function* applyFavoriteSaga(
  favorite: ModeFavorite,
  lastUpdatedAt: string
) {
  const loadingState: LoadingState = yield select(
    settingsOptionsModeLoadingStateSelector
  );

  // settingsOptionsが取得されていない場合は、取得する
  if (loadingState === 'prepare') {
    yield put(
      SettingsOptionsModeActionCreators.fetchSettingsOptionsModeAction()
    );
  }

  const migratedFavorite = migrateFavorite(favorite, lastUpdatedAt);

  const searchParams = updateFavoriteSearchParams(migratedFavorite);

  if (!isValidArea(searchParams.areas)) {
    yield put(ErrorActionCreators.setError(errorMessages.favoriteNoArea));
  }

  yield put(
    DataModeKiListActionCreators.fetchDataModeKiListAction({
      areas: searchParams?.areas,
      halls: searchParams?.halls,
      makers: searchParams?.makers,
      kiList: migratedFavorite.selectedKiList,
      sisTypes: searchParams?.sisTypes,
      endDate: searchParams.endDate ?? searchParams.ymdList?.at(-1),
    })
  );
  yield take(DataModeKiListActionTypes.FETCH_DATA_MODE_KILIST_SUCCESS);

  // NOTE:
  // 機種リスト一覧に選択した機種が含まれない場合、エラーを表示する
  // お気に入りの場合、選択された機種が存在しなくなることがある
  const dataModeKiList: ModeDataKiList = yield select(
    dataModeKiListDataSelector
  );
  const favCurrentKi = searchParams.kiList?.at(0);

  /**
   * お気に入りで選択されていた機種が存在するかどうか
   */
  const existCurrentKi =
    favCurrentKi != null &&
    dataModeKiList.data.kiList.some(({ code }) => code === favCurrentKi);

  if (!existCurrentKi) {
    yield put(ErrorActionCreators.setError(modeErrorMessages.replaceKi));
  }

  const filteredKiList = migratedFavorite.selectedKiList.filter((ki) =>
    dataModeKiList.data.kiList.some(({ code }) => code === ki)
  );

  yield put(
    ModeSettingActionCreators.selectModeDateRangeParamsAction(
      migratedFavorite.selectedDateRangeParams
    )
  );
  yield put(
    ModeSettingActionCreators.selectModeSelectedShuAction(
      migratedFavorite.selectedShu
    )
  );
  yield put(
    ModeSettingActionCreators.selectModeSelectedKiListAction(filteredKiList)
  );
  if (favorite.columnsModeSpecificOrder) {
    yield put(
      ModeSettingActionCreators.selectModeColumnsModeSpecificAction(
        favorite.columnsModeSpecificOrder
      )
    );
  }
  if (favorite.columnsNotModeSpecificOrder) {
    yield put(
      ModeSettingActionCreators.selectModeColumnsNotModeSpecificAction(
        favorite.columnsNotModeSpecificOrder
      )
    );
  }

  yield fork(fetchDataModeSaga, {
    ...searchParams,
    kiList: existCurrentKi ? [favCurrentKi] : [filteredKiList.at(0) as string],
  });
}

/**
 * 画面共有用短縮URL作成の処理
 */
export function* createShortenedUrlSaga(action: CreateModeShortenedUrlAction) {
  const modeSetting: ModeSettingState = yield select(modeSettingSelector);

  if (modeSetting.searchParams == null) {
    throw new Error('検索条件が存在しませんでした。');
  }

  const modeFavorite: ModeFavoriteV1 = {
    searchParams: modeSetting.searchParams,
    selectedShu: modeSetting.selectedShu,
    selectedKiList: modeSetting.selectedKiList,
    selectedDateRangeParams: modeSetting.selectedDateRangeParams,
    columnsNotModeSpecificOrder: modeSetting.columnsNotModeSpecificOrder,
    columnsModeSpecificOrder: modeSetting.columnsModeSpecificOrder,
  };

  const compressed = compressToEncodedURIComponent(
    action.payload.pageName,
    {
      mode: modeFavorite,
    } ?? {}
  );
  const originalUrl = `${action.payload.locationUrl}?q=${compressed}`;

  yield put(ShortenedUrlActionCreators.postShortenedUrlAction({ originalUrl }));
}

function* handleSaga() {
  yield takeEvery(ModeSettingActionTypes.INIT_DATA_MODE, initDataModeSaga);

  yield takeEvery(ModeSettingActionTypes.SEARCH_DATA_MODE, searchDataModeSaga);
  yield takeEvery(
    ModeSettingActionTypes.SEARCH_RESET_DATA_MODE,
    resetSearchDataModeSaga
  );

  yield takeEvery(
    ModeSettingActionTypes.CHANGE_MODE_CURRENT_SHU,
    changeCurrentShuSaga
  );
  yield takeEvery(
    ModeSettingActionTypes.CHANGE_MODE_CURRENT_KI,
    changeCurrentKiSaga
  );
  yield takeEvery(
    ModeSettingActionTypes.CHANGE_MODE_FIELDS,
    changeModeFieldsSaga
  );

  yield takeEvery(
    ModeSettingActionTypes.TRIGGER_NOT_MODE_SWAP_FIELDS,
    triggerNotModeSwapFieldsSaga
  );
  yield takeEvery(
    ModeSettingActionTypes.TRIGGER_MODE_SWAP_FIELDS,
    triggerModeSwapFieldsSaga
  );

  yield takeEvery(
    ModeSettingActionTypes.CHANGE_MODE_FAVORITE,
    applyFavoriteByIdSaga
  );
  yield takeEvery(
    ModeSettingActionTypes.SAVE_AS_MODE_FAVORITE,
    saveAsFavoriteSaga
  );
  yield takeEvery(ModeSettingActionTypes.SAVE_MODE_FAVORITE, saveFavoriteSaga);
  yield takeEvery(
    ModeSettingActionTypes.SAVE_MODE_FAVORITE_MEMO,
    saveFavoriteMemoSaga
  );
  yield takeEvery(
    ModeSettingActionTypes.CREATE_MODE_SHORTENED_URL,
    createShortenedUrlSaga
  );
}

export function* modeSaga() {
  yield fork(handleSaga);
}
