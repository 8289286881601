import Box from '@mui/material/Box';
import { ComponentProps, PropsWithoutRef, forwardRef } from 'react';

import { menuIcons } from './menuIcons';

type Props = {
  pageName: keyof typeof menuIcons;
} & PropsWithoutRef<
  Omit<ComponentProps<'img'>, 'src'> &
    Omit<ComponentProps<typeof Box>, 'children'>
>;

/**
 * メニュー用アイコン
 */
export const Base64MenuIcon = forwardRef<HTMLImageElement, Props>(
  ({ pageName, alt, width, height, ...rest }, ref) => {
    return (
      <Box
        component="img"
        src={menuIcons[pageName]}
        alt={alt ?? ''}
        width={width ?? 24}
        height={height ?? 24}
        ref={ref}
        {...rest}
      />
    );
  }
);
