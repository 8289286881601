import { ReactNode } from 'react';

import { ApplicationConfirmationByStoreIcon } from '../components/atoms/Icon/ApplicationConfirmationByStoreIcon';
import { CashCowIcon } from '../components/atoms/Icon/CashCowIcon';
import { DogIcon } from '../components/atoms/Icon/DogIcon';
import { IncreaseTableCandidatesIcon } from '../components/atoms/Icon/IncreaseTableCandidatesIcon';
import { QuestionMarkCheckIcon } from '../components/atoms/Icon/QuestionMarkCheckIcon';
import { RemovalDecreaseTableCandidatesIcon } from '../components/atoms/Icon/RemovalDecreaseTableCandidatesIcon';
import { SisByminAlarmIcon } from '../components/atoms/Icon/SisByminAlarmIcon';
import { SisDataAlarmIcon } from '../components/atoms/Icon/SisDataAlarmIcon';
import { StarCheckIcon } from '../components/atoms/Icon/StarCheckIcon';
import { StartCheckIcon } from '../components/atoms/Icon/StartCheckIcon';

export type MarkingOption = {
  code: string;
  label: string;
  comment: string;
  icon?: ReactNode;
  description?: MarkingDescription;
};

type Desc = {
  value: string;
  bgColor: string;
};

// 店舗レポートの説明文
type MarkingDescription = {
  cols?: string[];
  min?: Desc[];
  max?: Desc[];
};

// PPMシェアカテゴリ
export const PPM_CASH_COW = 'cashCow' as const;
export const PPM_DOG = 'dog' as const;
export const PPM_STAR = 'starCheck' as const;
export const PPM_QUESTION = 'questionMarkCheck' as const;
export const PpmShareCatetories = [
  PPM_CASH_COW,
  PPM_DOG,
  PPM_STAR,
  PPM_QUESTION,
];
export type PpmShareCategory =
  | typeof PPM_CASH_COW
  | typeof PPM_DOG
  | typeof PPM_STAR
  | typeof PPM_QUESTION;

// 機種集計のマーキング条件
export const MODEL_REPORT_MARKING_CONDITIONS: MarkingOption[] = [
  {
    code: '',
    label: 'マーキングなし',
    comment: 'マーキングなし',
  },
  {
    code: 'sisDataAlarm',
    label: '【DK-SIS】データアラーム',
    icon: <SisDataAlarmIcon />,
    comment: '各種データをDK-SIS平均と比較し、差の大きいデータを配色します',
    description: {
      cols: ["SP玉粗利'", '有効S', '入賞S', '千円S', 'BYmin', 'BA', '補T1Y'],
      min: [
        { value: '実績 - SIS >= 0.05', bgColor: 'blue' },
        { value: '実績 - SIS <= -2.0', bgColor: 'blue' },
        { value: '実績 - SIS <= -2.0', bgColor: 'blue' },
        { value: '実績 - SIS <= -1.0', bgColor: 'blue' },
        { value: '実績 - SIS <= -1.0', bgColor: 'blue' },
        { value: '実績 - SIS <= -5.0', bgColor: 'blue' },
        { value: '実績 - SIS <= -20', bgColor: 'blue' },
      ],
      max: [
        { value: '実績 - SIS <= -0.05', bgColor: 'red' },
        { value: '実績 - SIS >= 2.0', bgColor: 'red' },
        { value: '実績 - SIS >= 2.0', bgColor: 'red' },
        { value: '実績 - SIS >= 1.0', bgColor: 'red' },
        { value: '実績 - SIS >= 1.0', bgColor: 'red' },
        { value: '実績 - SIS >= 5.0', bgColor: 'red' },
        { value: '実績 - SIS >= 20', bgColor: 'red' },
      ],
    },
  },
  {
    code: 'sisByminAlarm',
    label: '【DK-SIS】BYminアラーム',
    icon: <SisByminAlarmIcon />,
    comment: 'BYminをDK-SISと比較し、差の大きいデータを配色します',
    description: {
      cols: ['BYmin'],
      min: [{ value: 'DK-SISとの差が-1.0以下', bgColor: 'blue' }],
      max: [{ value: 'DK-SISとの差が1.0以上', bgColor: 'red' }],
    },
  },
  {
    code: PPM_CASH_COW,
    label: '金のなる木機種を見つける',
    icon: <CashCowIcon />,
    comment:
      '種別平均値と比べて、アウトとSP玉粗利がともに高い「金のなる木」に該当する機種を配色します',
    description: {
      cols: ['アウト支持率', "SP玉粗利'"],
      min: [
        { value: '100%以上', bgColor: 'blue' },
        { value: '平均以上', bgColor: 'blue' },
      ],
      max: [],
    },
  },
  {
    code: PPM_STAR,
    label: '花形機種を見つける',
    icon: <StarCheckIcon />,
    comment:
      '種別平均値と比べて、アウトは高いがSP玉粗利は低い「花形」に該当する機種を配色します',
    description: {
      cols: ['アウト支持率', "SP玉粗利'"],
      min: [
        { value: '100%以上', bgColor: 'blue' },
        { value: '平均未満', bgColor: 'red' },
      ],
      max: [],
    },
  },
  {
    code: PPM_DOG,
    label: '負け犬機種を見つける',
    icon: <DogIcon />,
    comment:
      '種別平均値と比べて、アウトは低いがSP玉粗利は高い「負け犬」に該当する機種を配色します',
    description: {
      cols: ['アウト支持率', "SP玉粗利'"],
      min: [
        { value: '100%未満', bgColor: 'red' },
        { value: '平均以上', bgColor: 'blue' },
      ],
      max: [],
    },
  },
  {
    code: PPM_QUESTION,
    label: '問題児機種を見つける',
    icon: <QuestionMarkCheckIcon />,
    comment:
      '種別平均値と比べて、アウトとSP玉粗利がともに低い「問題児」に該当する機種を配色します',
    description: {
      cols: ['アウト支持率', "SP玉粗利'"],
      min: [
        { value: '100%未満', bgColor: 'red' },
        { value: '平均未満', bgColor: 'red' },
      ],
      max: [],
    },
  },
  {
    code: 'removalDecreaseTableCandidates',
    label: '撤去減台候補の機種を見つける',
    icon: <RemovalDecreaseTableCandidatesIcon />,
    comment:
      '2期間連続して、アウトと台粗利が低迷している機種を「撤去減台候補」として配色します',
    description: {
      cols: [
        'アウト支持率',
        'アウト支持率(比較)',
        '台粗利支持率',
        '台粗利支持率(比較)',
      ],
      min: [
        { value: '50%未満', bgColor: 'red' },
        { value: '50%未満', bgColor: 'red' },
        { value: '50%未満', bgColor: 'red' },
        { value: '50%未満', bgColor: 'red' },
      ],
      max: [
        { value: '50%以上70%未満', bgColor: 'yellow' },
        { value: '50%以上70%未満', bgColor: 'yellow' },
        { value: '50%以上70%未満', bgColor: 'yellow' },
        { value: '50%以上70%未満', bgColor: 'yellow' },
      ],
    },
  },
  {
    code: 'increaseTableCandidates',
    label: '増台候補の機種を見つける',
    icon: <IncreaseTableCandidatesIcon />,
    comment:
      '2期間連続して、アウトと台粗利が高い水準を示す機種を「増台候補」として配色します',
    description: {
      cols: [
        '経過週',
        'アウト支持率',
        'アウト支持率(比較)',
        '台粗利支持率',
        '台粗利支持率(比較)',
      ],
      min: [
        { value: '10以上', bgColor: 'blue' },
        { value: '120%以上', bgColor: 'blue' },
        { value: '120%以上', bgColor: 'blue' },
        { value: '120%以上', bgColor: 'blue' },
        { value: '120%以上', bgColor: 'blue' },
      ],
      max: [],
    },
  },
  {
    code: 'startCheck',
    label: '入賞Sの異常な機種を見つける',
    icon: <StartCheckIcon />,
    comment:
      '機種を問わず、入賞Sの値が高すぎるor低すぎる機種を要注意対象として配色します',
    description: {
      cols: ['入賞S'],
      min: [{ value: '49未満', bgColor: 'blue' }],
      max: [{ value: '72以上', bgColor: 'red' }],
    },
  },
  {
    code: 'applicationConfirmationByStore',
    label: '店舗ごとの活用をチェックする',
    icon: <ApplicationConfirmationByStoreIcon />,
    comment:
      '各機種の店舗別の活用データをチェックし、チェーン店平均の値から大きく離れている場合にマーキングを施す（店舗別データにのみマーキングを適用）',
    description: {
      cols: ['入賞S', 'BA', 'BYmin'],
      min: [
        { value: '店舗実績 - 機種平均 <= -2.0', bgColor: 'blue' },
        { value: '店舗実績 - 機種平均 <= -5.0', bgColor: 'blue' },
        { value: '店舗実績 - 機種平均 <= -1.0', bgColor: 'blue' },
      ],
      max: [
        { value: '店舗実績 - 機種平均 >= 2.0', bgColor: 'red' },
        { value: '店舗実績 - 機種平均 >= 5.0', bgColor: 'red' },
        { value: '店舗実績 - 機種平均 >= 1.0', bgColor: 'red' },
      ],
    },
  },
];

// 店舗レポート、チェーン店レポート用のマーキング条件
export const REPORT_MARKING_CONDITIONS: MarkingOption[] = [
  {
    code: '',
    label: 'マーキングなし',
    comment: 'マーキングなし',
  },
  {
    code: 'profitUnderachievement',
    label: '粗利実績確認(未達)',
    comment: '粗利実績が予定値に満たない場合にマーキングを施す',
    description: {
      cols: ['粗利達成率'],
      min: [{ value: '100%未満', bgColor: 'red' }],
      max: [],
    },
  },
  {
    code: 'outOfProfitAchievementRange',
    label: '粗利実績確認(上下超過)',
    comment:
      '粗利実績が予定値に対し上振れ・下振れしている場合にマーキングを施す（上振れは青色、下振れは赤色で配色）',
    description: {
      cols: ['粗利達成率'],
      min: [{ value: '80%未満', bgColor: 'red' }],
      max: [{ value: '120%以上', bgColor: 'blue' }],
    },
  },
  {
    code: 'comprehensiveUnderachievement',
    label: '総合実績確認(未達)',
    comment:
      'アウト・売上・粗利の実績が予定値に満たない場合にマーキングを施す 3項目全てで予定未達の場合は店舗名もマーキング',
    description: {
      cols: ['アウト達成率', '売上達成率', '粗利達成率'],
      min: [
        { value: '100%未満', bgColor: 'red' },
        { value: '100%未満', bgColor: 'red' },
        { value: '100%未満', bgColor: 'red' },
      ],
      max: [],
    },
  },
  {
    code: 'outOfComprehensiveAchievementRange',
    label: '総合実績確認(上下超過)',
    comment:
      'アウト・売上・粗利の実績が予定値に対して上振れ・下振れしている場合にマーキングを施す（上振れは青色、下振れは赤色で配色）3項目全てで予定未達の場合は店舗名もマーキング',
    description: {
      cols: ['アウト達成率', '売上達成率', '粗利達成率'],
      min: [
        { value: '80%未満', bgColor: 'red' },
        { value: '80%未満', bgColor: 'red' },
        { value: '80%未満', bgColor: 'red' },
      ],
      max: [
        { value: '120%以上', bgColor: 'blue' },
        { value: '120%以上', bgColor: 'blue' },
        { value: '120%以上', bgColor: 'blue' },
      ],
    },
  },
];

// PPMシェア画面のマーキング条件
export const PPM_SHARE_MARKING_CONDITIONS: MarkingOption[] = [
  {
    code: '',
    label: 'マーキングなし',
    comment: 'マーキングなし',
  },
  {
    code: 'starAndQuestionMarkShareCheck',
    label: '花形・問題児シェアをチェックする',
    comment: '各PPM区分の台数シェアを表示する',
    description: {
      cols: [
        'PPM台数シェア：花形',
        'PPM台数シェア：問題児',
        'PPM台数シェア（比較差）：花形',
        'PPM台数シェア（比較差）：問題児',
      ],
      min: [
        { value: '15%未満', bgColor: 'red' },
        { value: '10%未満', bgColor: 'blue' },
        { value: '-5%未満', bgColor: 'red' },
        { value: '-5%未満', bgColor: 'blue' },
      ],
      max: [
        { value: '30%以上', bgColor: 'blue' },
        { value: '20%以上', bgColor: 'red' },
        { value: '5%以上', bgColor: 'blue' },
        { value: '5%以上', bgColor: 'red' },
      ],
    },
  },
];
