import { KiTagListOptions } from '../schemas';
import { DataDepreciationResponse } from './types';

export const dataDepreciationFilteredByKiTagsList = (
  dataDepreciation: DataDepreciationResponse,
  kiTagList: KiTagListOptions[]
): DataDepreciationResponse => {
  if (kiTagList.length === 0) {
    return dataDepreciation;
  }

  const rows = dataDepreciation.data.rows.filter((row, i) => {
    // 平均合計行の機能が追加され次第、修正が必要
    if (i < 0) {
      return true;
    }
    return kiTagList.some((kiTag) => {
      return row.queryParameter.kiList?.some((ki) => {
        return kiTag.kiList.includes(ki);
      });
    });
  });

  return {
    ...dataDepreciation,
    data: {
      ...dataDepreciation.data,
      rows,
    },
  };
};
