import { DataDepreciationResponse } from './types';

export const dataDepreciationSwapColumnsOrder = (
  dataDepreciation: DataDepreciationResponse,
  columnsOrder: string[]
): DataDepreciationResponse => {
  if (columnsOrder.length === 0) {
    return dataDepreciation;
  }

  const indexes = columnsOrder
    .map((code) =>
      dataDepreciation.data.columns.findIndex((col) => col.code === code)
    )
    .filter((index) => index !== -1);

  return {
    ...dataDepreciation,
    data: {
      ...dataDepreciation.data,
      columns: indexes.map((index) =>
        dataDepreciation.data.columns.at(index)
      ) as DataDepreciationResponse['data']['columns'],
      rows: dataDepreciation.data.rows.map((row) => ({
        ...row,
        data: indexes.map((index) => row.data.at(index)),
      })) as DataDepreciationResponse['data']['rows'],
    },
  };
};
