import { AxiosResponse } from 'axios';
import { call, fork, put, select, take, takeEvery } from 'redux-saga/effects';

import {
  DataPlanProgressTransition,
  DataPlanProgressTransitionParams,
} from '../domain/dataPlanProgressTransition';
import { LoadingState } from '../domain/schemas';
import { SettingsOptionsPlanProgressTransition } from '../domain/settingsOptionsPlanProgressTransition';
import { ShuOption } from '../domain/shu';

import {
  DataPlanProgressTransitionActionCreators,
  DataPlanProgressTransitionActionTypes,
  FetchDataPlanProgressTransitionAction,
  dataPlanProgressTransitionSettingSelector,
} from '../redux/server/dataPlanProgressTransition';
import {
  SettingsOptionsPlanProgressTransitionActionTypes,
  settingsOptionsPlanProgressTransitionLoadingStateSelector,
  settingsOptionsPlanProgressTransitionsettingsOptionsSelector,
} from '../redux/server/settingsOptionsPlanProgressTransition';
import {
  PlanProgressTransitionReportsSettingActionCreators,
  planProgressTransitionReportsCurrentShuSelector,
  selectPlanProgressTransitionReportsCurrentShuAction,
} from '../redux/ui/planProgressTransitionReportsSetting';
import { Api, buildConfig } from '../utils/api';
import { convertShuOption, existShuOption } from '../utils/shu';
import { handleErrorSaga } from './errorSaga';

export function* fetchDataPlanProgressTransitionSaga(
  api: Api,
  action: FetchDataPlanProgressTransitionAction
) {
  try {
    yield put(
      DataPlanProgressTransitionActionCreators.fetchDataPlanProgressTransitionRequestAction()
    );
    const response: AxiosResponse<DataPlanProgressTransition> = yield call(
      api.get,
      '/data/planProgressTransition',
      buildConfig(action.payload.params)
    );
    yield put(
      DataPlanProgressTransitionActionCreators.fetchDataPlanProgressTransitionSuccessAction(
        response.data
      )
    );
    yield put(
      PlanProgressTransitionReportsSettingActionCreators.selectPlanProgressTransitionReportsCurrentHallsAction(
        response.data.setting.halls
      )
    );
  } catch (error: unknown) {
    yield put(
      DataPlanProgressTransitionActionCreators.renewDataPlanProgressTransitionAction()
    );
    yield fork(handleErrorSaga, error);
  }
}

/**
 * リクエストした種別のデータとレスポンスの種別データが不一致だったときに、レスポンスの値で上書きする
 *
 * 種別の不一致は種別が削除されたとき、あるいは共有グループをまたいだお気に入りで発生する
 */
function* validateShuSaga() {
  const currentShu: ShuOption = yield select(
    planProgressTransitionReportsCurrentShuSelector
  );
  // currentShuが存在しない場合には初期リクエストになるため何もしない
  // 予定進捗推移では種別セレクタで種別全体が選択される場合があるため
  if (currentShu == null || currentShu.code === '') {
    return;
  }

  const setting: DataPlanProgressTransitionParams = yield select(
    dataPlanProgressTransitionSettingSelector
  );
  const exist = existShuOption(setting, currentShu);
  if (exist) {
    return;
  }

  const settingsOptionsLoadingState: LoadingState = yield select(
    settingsOptionsPlanProgressTransitionLoadingStateSelector
  );
  if (settingsOptionsLoadingState !== 'loaded') {
    yield take(
      SettingsOptionsPlanProgressTransitionActionTypes.FETCH_SETTINGS_OPTIONS_PLAN_PROGRESS_TRANSITION_SUCCESS
    );
  }
  // FETCH_SETTINGS_OPTIONS_KI_SUCCESSを待つのでundefinedにならない
  const settingsOptions: SettingsOptionsPlanProgressTransition = yield select(
    settingsOptionsPlanProgressTransitionsettingsOptionsSelector
  );

  const shuOption = convertShuOption(
    setting,
    settingsOptions.searchCondition.shuGroupList
  );
  if (shuOption == null) {
    throw new Error('適切な種別がsettingsOptionsで見つかりませんでした');
  }

  yield put(selectPlanProgressTransitionReportsCurrentShuAction(shuOption));
}

function* handleFetchDataPlanProgressTransitionSaga(api: Api) {
  yield takeEvery(
    DataPlanProgressTransitionActionTypes.FETCH_DATA_PLAN_PROGRESS_TRANSITION,
    fetchDataPlanProgressTransitionSaga,
    api
  );

  yield takeEvery(
    DataPlanProgressTransitionActionTypes.FETCH_DATA_PLAN_PROGRESS_TRANSITION_SUCCESS,
    validateShuSaga
  );
}

export function* dataPlanProgressTransitionSagas(context: { api: Api }) {
  yield fork(handleFetchDataPlanProgressTransitionSaga, context.api);
}
