import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';

import { LoadingState } from '../../../domain/schemas';

import { selectPageSettingAction } from '../../../redux/ui/favorite';
import { decompressFromEncodedURIComponent } from '../../../utils/decompressFromEncodedURIComponent';
import { pathToFavoritePageName } from '../../../utils/pathToFavoritePageName';
import { LoadingPage } from '../../pages/LoadingPage';

type Props = {
  q: string;
};

export const CompressPageSetting: FC<Props> = (props) => {
  const { q } = props;

  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const [loadingState, setLoadingState] = useState<LoadingState | undefined>();

  useEffect(() => {
    // 初回あるいはページが変更されたときloadingStateをprepareに戻す
    // 画面リンクでページが変更されたときには画面遷移時に再度データを取得するため
    setLoadingState('prepare');
  }, [pathname]);

  useEffect(() => {
    if (loadingState === 'prepare') {
      setLoadingState('loading');

      try {
        const pageNameFromPath = pathToFavoritePageName(pathname);
        const {
          pageName: pageNameFromQs,
          pageSetting,
          createdAt,
        } = decompressFromEncodedURIComponent(q);

        // MEMO:
        // タブが存在していないページのチェックはここで完了していますが、
        // タブが存在しているページについてはそれぞれのページでチェックする必要があります
        // チェックにはuseIsTabEnabledを使用してください
        if (pageNameFromPath !== pageNameFromQs) {
          throw new Error('クエリ文字列の指定ページと現ページが異なります。');
        }

        dispatch(
          selectPageSettingAction({
            pageName: pageNameFromPath,
            pageSetting,
            createdAt,
          })
        );
      } catch (e) {
        if (e instanceof Error) {
          throw new Error(e.message, { cause: e });
        }
      } finally {
        setLoadingState('loaded');
      }
    }
  }, [dispatch, loadingState, pathname, q]);

  if (loadingState === 'prepare' || loadingState === 'loading') {
    return <LoadingPage />;
  }

  return <Outlet />;
};
