import { AxiosResponse } from 'axios';
import { call, fork, put, select, takeLatest } from 'redux-saga/effects';

import { GraphDateType } from '../domain/dataKiGraph';
import { DataModelTransitionParams } from '../domain/dataModelTransition';
import {
  DataModelTransitionGraph,
  DataModelTransitionGraphParams,
} from '../domain/dataModelTransitionGraph';

import { dataModelTransitionDataSearchConditionSelector } from '../redux/server/dataModelTransition';
import {
  ChangeDataModelTransitionGraphFieldAction,
  DataModelTransitionGraphActionCreators,
  DataModelTransitionGraphActionTypes,
  FetchDataModelTransitionGraphAction,
  SearchDataModelTransitionGraphAction,
  dataModelTransitionGraphSettingSelector,
} from '../redux/server/dataModelTransitionGraph';
import { Api, buildConfig } from '../utils/api';
import { handleErrorSaga } from './errorSaga';

/**
 * 検索条件を元に推移グラフ（機種比較）のデータを取得する
 * @param api AxiosInstance
 * @param action Action
 */
export function* fetchDataModelTransitionGraphSaga(
  api: Api,
  action: FetchDataModelTransitionGraphAction
) {
  const { params } = action.payload;

  try {
    yield put(
      DataModelTransitionGraphActionCreators.fetchDataModelTransitionGraphRequestAction()
    );
    const response: AxiosResponse<DataModelTransitionGraph> = yield call(
      api.get,
      '/data/kiTransition/graph',
      buildConfig(params)
    );

    yield put(
      DataModelTransitionGraphActionCreators.fetchDataModelTransitionGraphSuccessAction(
        response.data
      )
    );
  } catch (error: unknown) {
    yield put(
      DataModelTransitionGraphActionCreators.renewDataModelTransitionGraphAction()
    );
    yield fork(handleErrorSaga, error);
  }
}

/**
 * チェックボックスで選択した機種の推移グラフ（機種比較）データを取得する（推移グラフボタン押下時）
 * @param action Action
 */
function* searchDataModelTransitionGraphSaga(
  action: SearchDataModelTransitionGraphAction
) {
  const { kiList } = action.payload;

  // テーブルの検索条件
  const searchCondition: DataModelTransitionParams = yield select(
    dataModelTransitionDataSearchConditionSelector
  );

  // グラフデータ取得
  yield put(
    DataModelTransitionGraphActionCreators.fetchDataModelTransitionGraphAction({
      kiList,
      kiListForSijiritu: searchCondition.kiListForSijiritu,
      shuList: searchCondition.shuList,
      shuGroupIds: searchCondition.shuGroupIds,
      halls: searchCondition.halls,
      dateType: searchCondition.dateType as GraphDateType,
      excludeToday: searchCondition.excludeToday,
      startDate: searchCondition.startDate,
      endDate: searchCondition.endDate,
      areas: searchCondition.areas,
      sisTypes: searchCondition.sisTypes,
      dayType: searchCondition.dayType,
      dateSuffixes: searchCondition.dateSuffixes,
      days: searchCondition.days,
      dates: searchCondition.dates,
    })
  );
}

/**
 * 指定したグラフ項目の推移グラフ（機種比較）を取得する
 * @param action Action
 */
function* changeDataModelTransitionGraphFieldSaga(
  action: ChangeDataModelTransitionGraphFieldAction
) {
  const { field } = action.payload;

  // 推移グラフ（機種比較）の検索条件
  const searchCondition: DataModelTransitionGraphParams = yield select(
    dataModelTransitionGraphSettingSelector
  );

  // 推移グラフ（機種比較）データを再取得
  yield put(
    DataModelTransitionGraphActionCreators.fetchDataModelTransitionGraphAction({
      ...searchCondition,
      field,
    })
  );
}

function* handleFetchDataModelTransitionGraphSaga(api: Api) {
  yield takeLatest(
    DataModelTransitionGraphActionTypes.FETCH_DATA_MODEL_TRANSITION_GRAPH,
    fetchDataModelTransitionGraphSaga,
    api
  );
}

/**
 * 検索系のActionをDispatchされるのを監視
 */
function* handleSearchDataModelTransitionGraphSaga() {
  // 初回取得
  yield takeLatest(
    DataModelTransitionGraphActionTypes.SEARCH_DATA_MODEL_TRANSITION_GRAPH,
    searchDataModelTransitionGraphSaga
  );
  // グラフ項目変更時
  yield takeLatest(
    DataModelTransitionGraphActionTypes.CHANGE_DATA_MODEL_TRANSITION_GRAPH_FIELD,
    changeDataModelTransitionGraphFieldSaga
  );
}

export function* dataModelTransitionGraphSaga(context: { api: Api }) {
  yield fork(handleFetchDataModelTransitionGraphSaga, context.api);
  yield fork(handleSearchDataModelTransitionGraphSaga);
}
