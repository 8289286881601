import { createSelector } from 'reselect';

import { LoadingState } from '../../domain/schemas';
import { SettingsOptionsTransitionAfterIntroduction } from '../../domain/transitionAfterIntroduction/types';

import { RootState } from '../../store';
import { settingsOptionsTransitionAfterIntroductionDefaultValue } from '../../utils/settingsOptionsTransitionAfterIntroductionDefaultValue';
import { dataTransitionAfterIntroductionSettingAreasSelector } from './dataTransitionAfterIntroduction';

// Action Types

const FETCH_SETTINGS_OPTIONS_TRANSITION_AFTER_INTRODUCTION = 'FETCH_SETTINGS_OPTIONS_TRANSITION_AFTER_INTRODUCTION' as const;
const FETCH_SETTINGS_OPTIONS_TRANSITION_AFTER_INTRODUCTION_REQUEST = 'FETCH_SETTINGS_OPTIONS_TRANSITION_AFTER_INTRODUCTION_REQUEST' as const;
const FETCH_SETTINGS_OPTIONS_TRANSITION_AFTER_INTRODUCTION_SUCCESS = 'FETCH_SETTINGS_OPTIONS_TRANSITION_AFTER_INTRODUCTION_SUCCESS' as const;
const RENEW_SETTINGS_OPTIONS_TRANSITION_AFTER_INTRODUCTION = 'RENEW_SETTINGS_OPTIONS_TRANSITION_AFTER_INTRODUCTION' as const;

export const SettingsOptionsTransitionAfterIntroductionActionTypes = {
  FETCH_SETTINGS_OPTIONS_TRANSITION_AFTER_INTRODUCTION,
  FETCH_SETTINGS_OPTIONS_TRANSITION_AFTER_INTRODUCTION_REQUEST,
  FETCH_SETTINGS_OPTIONS_TRANSITION_AFTER_INTRODUCTION_SUCCESS,
  RENEW_SETTINGS_OPTIONS_TRANSITION_AFTER_INTRODUCTION,
};

// Action Creators

function fetchSettingsOptionsTransitionAfterIntroductionAction() {
  return {
    type: FETCH_SETTINGS_OPTIONS_TRANSITION_AFTER_INTRODUCTION,
  };
}

function fetchSettingsOptionsTransitionAfterIntroductionRequestAction() {
  return {
    type: FETCH_SETTINGS_OPTIONS_TRANSITION_AFTER_INTRODUCTION_REQUEST,
  };
}

function fetchSettingsOptionsTransitionAfterIntroductionSuccessAction(
  settingsOptionsTransitionAfterIntroduction: SettingsOptionsTransitionAfterIntroduction
) {
  return {
    type: FETCH_SETTINGS_OPTIONS_TRANSITION_AFTER_INTRODUCTION_SUCCESS,
    payload: { settingsOptionsTransitionAfterIntroduction },
  };
}

function renewSettingsOptionsTransitionAfterIntroductionAction() {
  return {
    type: RENEW_SETTINGS_OPTIONS_TRANSITION_AFTER_INTRODUCTION,
  };
}

export const SettingsOptionsTransitionAfterIntroductionActionCreators = {
  fetchSettingsOptionsTransitionAfterIntroductionAction,
  fetchSettingsOptionsTransitionAfterIntroductionRequestAction,
  fetchSettingsOptionsTransitionAfterIntroductionSuccessAction,
  renewSettingsOptionsTransitionAfterIntroductionAction,
};

// Actions

type FetchSettingsOptionsTransitionAfterIntroductionAction = ReturnType<
  typeof fetchSettingsOptionsTransitionAfterIntroductionAction
>;

type SettingsOptionsTransitionAfterIntroductionAction =
  | FetchSettingsOptionsTransitionAfterIntroductionAction
  | ReturnType<
      typeof fetchSettingsOptionsTransitionAfterIntroductionRequestAction
    >
  | ReturnType<
      typeof fetchSettingsOptionsTransitionAfterIntroductionSuccessAction
    >
  | ReturnType<typeof renewSettingsOptionsTransitionAfterIntroductionAction>;

// State

type SettingsOptionsTransitionAfterIntroductionState = {
  loadingState: LoadingState;
  settingsOptionsTransitionAfterIntroduction?: SettingsOptionsTransitionAfterIntroduction;
};

const initialState: SettingsOptionsTransitionAfterIntroductionState = {
  loadingState: 'prepare',
  settingsOptionsTransitionAfterIntroduction: undefined,
};

// Selector

// settingsOptionsを全て取得する
export const settingsOptionsTransitionAfterIntroductionSelector = (
  state: RootState
) => {
  const settings =
    state.settingsOptionsTransitionAfterIntroduction
      .settingsOptionsTransitionAfterIntroduction;
  // MEMO: コンポーネント側がundefined許容しないため、空の場合空データを返す
  return settings
    ? settings
    : settingsOptionsTransitionAfterIntroductionDefaultValue;
};

// フィルター用のジャンル一覧を取得する
export const settingsOptionsTransitionAfterIntroductionGenresSelector = createSelector(
  settingsOptionsTransitionAfterIntroductionSelector,
  (settingsOptionsTransitionAfterIntroduction) => {
    // MEMO: コンポーネント側がundefined許容しないため、空の場合空データを返す
    if (settingsOptionsTransitionAfterIntroduction === undefined)
      return settingsOptionsTransitionAfterIntroductionDefaultValue.genres
        .transition;

    return settingsOptionsTransitionAfterIntroduction.genres.transition;
  }
);

/**
 * fieldsのみ取得
 */
export const settingsOptionsTransitionAfterIntroductionFieldsSelector = createSelector(
  settingsOptionsTransitionAfterIntroductionSelector,
  (settingsOptionsTransitionAfterIntroduction) => {
    return settingsOptionsTransitionAfterIntroduction.fields;
  }
);

/**
 * 導入後推移の表示項目のFieldsのisNewがtrueのものがあるかどうかを取得する
 */
export const settingsOptionsTransitionAfterIntroductionFieldsIsNewSelector = createSelector(
  settingsOptionsTransitionAfterIntroductionFieldsSelector,
  (fields) => {
    return [...fields.transition, ...fields.nonTransition].some(
      (field) => field.isNew
    );
  }
);

/**
 * fieldsのみ取得
 */
export const settingsOptionsTransitionAfterIntroductionGraphTransitionFieldsSelector = createSelector(
  settingsOptionsTransitionAfterIntroductionFieldsSelector,
  (fields) => {
    return fields.transition.filter((field) => field.isSelectableForGraph);
  }
);

/**
 * 現在のローディング状態を取得
 */
export const settingsOptionsTransitionAfterIntroductionLoadingStateSelector = (
  state: RootState
) => {
  return state.settingsOptionsTransitionAfterIntroduction.loadingState;
};

// SearchConditionだけ取得する
export const settingsOptionsTransitionAfterIntroductionSearchConditionSelector = createSelector(
  settingsOptionsTransitionAfterIntroductionSelector,
  (settingsOptionsTransitionAfterIntroduction) => {
    // MEMO: コンポーネント側がundefined許容しないため、空の場合空データを返す
    return settingsOptionsTransitionAfterIntroduction
      ? settingsOptionsTransitionAfterIntroduction.searchCondition
      : settingsOptionsTransitionAfterIntroductionDefaultValue.searchCondition;
  }
);

/**
 * 検索されたエリアのホール一覧を取得する
 */
export const settingsOptionsTransitionAfterIntroductionSearchConditionHallsBySearchedAreaSelector = createSelector(
  [
    settingsOptionsTransitionAfterIntroductionSearchConditionSelector,
    dataTransitionAfterIntroductionSettingAreasSelector,
  ],
  ({ halls, areas }, selectedAreaIds) => {
    if (!selectedAreaIds) {
      return halls ?? [];
    }
    const hallCodesInAreas = areas
      .filter((x) => selectedAreaIds.includes(x.id))
      .flatMap((x) => x.halls);

    return halls.filter((x) => hallCodesInAreas.includes(x.code));
  }
);

// Reducer

export function settingsOptionsTransitionAfterIntroductionReducer(
  state = initialState,
  action: SettingsOptionsTransitionAfterIntroductionAction
): SettingsOptionsTransitionAfterIntroductionState {
  switch (action.type) {
    case FETCH_SETTINGS_OPTIONS_TRANSITION_AFTER_INTRODUCTION_REQUEST:
      return {
        ...state,
        loadingState: 'loading',
      };
    case FETCH_SETTINGS_OPTIONS_TRANSITION_AFTER_INTRODUCTION_SUCCESS:
      return {
        ...state,
        loadingState: 'loaded',
        settingsOptionsTransitionAfterIntroduction:
          action.payload.settingsOptionsTransitionAfterIntroduction,
      };
    case RENEW_SETTINGS_OPTIONS_TRANSITION_AFTER_INTRODUCTION:
      return initialState;
    default:
      return state;
  }
}
