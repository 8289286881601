import { AxiosResponse } from 'axios';
import { call, fork, put, takeEvery } from 'redux-saga/effects';

import { SettingsOptionsTransitionAfterIntroduction } from '../domain/transitionAfterIntroduction/types';

import {
  SettingsOptionsTransitionAfterIntroductionActionCreators,
  SettingsOptionsTransitionAfterIntroductionActionTypes,
} from '../redux/server/settingsOptionsTransitionAfterIntroduction';
import { Api, buildConfig } from '../utils/api';
import { handleErrorSaga } from './errorSaga';

export function* fetchSettingsOptionsTransitionAfterIntroductionSaga(api: Api) {
  try {
    yield put(
      SettingsOptionsTransitionAfterIntroductionActionCreators.fetchSettingsOptionsTransitionAfterIntroductionRequestAction()
    );
    const response: AxiosResponse<SettingsOptionsTransitionAfterIntroduction> = yield call(
      api.get,
      '/settings/options/transitionAfterIntroduction',
      buildConfig()
    );

    yield put(
      SettingsOptionsTransitionAfterIntroductionActionCreators.fetchSettingsOptionsTransitionAfterIntroductionSuccessAction(
        response.data
      )
    );
  } catch (error: unknown) {
    yield put(
      SettingsOptionsTransitionAfterIntroductionActionCreators.renewSettingsOptionsTransitionAfterIntroductionAction()
    );
    yield fork(handleErrorSaga, error);
  }
}

function* handleFetchSettingsOptionsTransitionAfterIntroductionSaga(api: Api) {
  yield takeEvery(
    SettingsOptionsTransitionAfterIntroductionActionTypes.FETCH_SETTINGS_OPTIONS_TRANSITION_AFTER_INTRODUCTION,
    fetchSettingsOptionsTransitionAfterIntroductionSaga,
    api
  );
}

export function* settingsOptionsTransitionAfterIntroductionSagas(context: {
  api: Api;
}) {
  yield fork(
    handleFetchSettingsOptionsTransitionAfterIntroductionSaga,
    context.api
  );
}
