import { DailyComment } from '../../domain/dataHallDailyComments';
import { LoadingState } from '../../domain/schemas';
import {
  SettingsCommentsMonthToDate,
  SettingsCommentsMonthToDatePathParams,
} from '../../domain/settingsComments';

import { RootState } from '../../store';

// Action Types

const POST_SETTINGS_COMMENTS = 'POST_SETTINGS_COMMENTS' as const;
const POST_SETTINGS_COMMENTS_REQUEST = 'POST_SETTINGS_COMMENTS_REQUEST' as const;
const POST_SETTINGS_COMMENTS_SUCCESS = 'POST_SETTINGS_COMMENTS_SUCCESS' as const;
const POST_SETTINGS_COMMENTS_FAILURE = 'POST_SETTINGS_COMMENTS_FAILURE' as const;
const CLEAR_SETTINGS_COMMENTS_FAILURE = 'CLEAR_SETTINGS_COMMENTS_FAILURE' as const;
const POST_SETTINGS_COMMENTS_MONTH_TO_DATE = 'POST_SETTINGS_COMMENTS_MONTH_TO_DATE' as const;
const FETCH_SETTINGS_COMMENTS_MONTH_TO_DATE = 'FETCH_SETTINGS_COMMENTS_MONTH_TO_DATE' as const;
const FETCH_SETTINGS_COMMENTS_MONTH_TO_DATE_REQUEST = 'FETCH_SETTINGS_COMMENTS_MONTH_TO_DATE_REQUEST' as const;
const FETCH_SETTINGS_COMMENTS_MONTH_TO_DATE_SUCCESS = 'FETCH_SETTINGS_COMMENTS_MONTH_TO_DATE_SUCCESS' as const;
const RENEW_SETTINGS_COMMENTS_MONTH_TO_DATE = 'RENEW_SETTINGS_COMMENTS_MONTH_TO_DATE' as const;

export const SettingsCommentsMonthToDateActionTypes = {
  POST_SETTINGS_COMMENTS,
  POST_SETTINGS_COMMENTS_REQUEST,
  POST_SETTINGS_COMMENTS_SUCCESS,
  POST_SETTINGS_COMMENTS_FAILURE,
  CLEAR_SETTINGS_COMMENTS_FAILURE,
  POST_SETTINGS_COMMENTS_MONTH_TO_DATE,
  FETCH_SETTINGS_COMMENTS_MONTH_TO_DATE,
  FETCH_SETTINGS_COMMENTS_MONTH_TO_DATE_REQUEST,
  FETCH_SETTINGS_COMMENTS_MONTH_TO_DATE_SUCCESS,
  RENEW_SETTINGS_COMMENTS_MONTH_TO_DATE,
};

// Action Creators

function postSettingsCommentsAction(
  params: Pick<DailyComment, 'hallCode' | 'date' | 'content'>
) {
  return {
    type: POST_SETTINGS_COMMENTS,
    payload: { params },
  };
}

function postSettingsCommentsRequestAction() {
  return {
    type: POST_SETTINGS_COMMENTS_REQUEST,
  };
}

function postSettingsCommentsSuccessAction() {
  return {
    type: POST_SETTINGS_COMMENTS_SUCCESS,
  };
}

function postSettingsCommentsFailureAction(error: string) {
  return {
    type: POST_SETTINGS_COMMENTS_FAILURE,
    payload: { error },
  };
}

function clearSettingsCommentsFailureAction() {
  return {
    type: CLEAR_SETTINGS_COMMENTS_FAILURE,
  };
}

function postSettingsCommentsMonthToDateAction(
  params: Pick<DailyComment, 'hallCode' | 'date' | 'content'>
) {
  return {
    type: POST_SETTINGS_COMMENTS_MONTH_TO_DATE,
    payload: { params },
  };
}

function fetchSettingsCommentsMonthToDateAction(
  pathParams: SettingsCommentsMonthToDatePathParams
) {
  return {
    type: FETCH_SETTINGS_COMMENTS_MONTH_TO_DATE,
    payload: { pathParams },
  };
}

function fetchSettingsCommentsMonthToDateRequestAction() {
  return {
    type: FETCH_SETTINGS_COMMENTS_MONTH_TO_DATE_REQUEST,
  };
}

function fetchSettingsCommentsMonthToDateSuccessAction(
  settingsCommentsMonthToDate: SettingsCommentsMonthToDate
) {
  return {
    type: FETCH_SETTINGS_COMMENTS_MONTH_TO_DATE_SUCCESS,
    payload: { settingsCommentsMonthToDate },
  };
}

function renewSettingsCommentsMonthToDateAction() {
  return {
    type: RENEW_SETTINGS_COMMENTS_MONTH_TO_DATE,
  };
}

export const SettingsCommentsMonthToDateActionCreators = {
  postSettingsCommentsAction,
  postSettingsCommentsRequestAction,
  postSettingsCommentsSuccessAction,
  postSettingsCommentsFailureAction,
  clearSettingsCommentsFailureAction,
  postSettingsCommentsMonthToDateAction,
  fetchSettingsCommentsMonthToDateAction,
  fetchSettingsCommentsMonthToDateRequestAction,
  fetchSettingsCommentsMonthToDateSuccessAction,
  renewSettingsCommentsMonthToDateAction,
};

// Actions

export type PostSettingsCommentsAction = ReturnType<
  typeof postSettingsCommentsAction
>;
export type PostSettingsCommentsMonthToDateAction = ReturnType<
  typeof postSettingsCommentsMonthToDateAction
>;
export type FetchSettingsCommentsMonthToDateAction = ReturnType<
  typeof fetchSettingsCommentsMonthToDateAction
>;

type SettingsCommentsMonthToDateAction =
  | ReturnType<typeof postSettingsCommentsAction>
  | ReturnType<typeof postSettingsCommentsRequestAction>
  | ReturnType<typeof postSettingsCommentsSuccessAction>
  | ReturnType<typeof postSettingsCommentsFailureAction>
  | ReturnType<typeof clearSettingsCommentsFailureAction>
  | PostSettingsCommentsMonthToDateAction
  | FetchSettingsCommentsMonthToDateAction
  | ReturnType<typeof fetchSettingsCommentsMonthToDateRequestAction>
  | ReturnType<typeof fetchSettingsCommentsMonthToDateSuccessAction>
  | ReturnType<typeof renewSettingsCommentsMonthToDateAction>;

// State

type SettingsCommentsMonthToDateState = {
  loadingState: LoadingState;
  postIsLoading: boolean;
  postError: string;
  settingsCommentsMonthToDate?: SettingsCommentsMonthToDate;
};

const initialState: SettingsCommentsMonthToDateState = {
  loadingState: 'prepare',
  postIsLoading: false,
  postError: '',
  settingsCommentsMonthToDate: undefined,
};

// Selector

/**
 *  settingsCommentsMonthToDateを全て取得する
 */
export const settingsCommentsMonthToDateSelector = (state: RootState) => {
  return state.settingsCommentsMonthToDate.settingsCommentsMonthToDate;
};

/**
 * POST時のローディング状態を取得する
 */
export const settingsCommentsMonthToDatePostIsLoadingSelector = (
  state: RootState
) => {
  return state.settingsCommentsMonthToDate.postIsLoading;
};

/**
 * POST時のローディング状態を取得する
 */
export const settingsCommentsMonthToDatePostErrorSelector = (
  state: RootState
) => {
  return state.settingsCommentsMonthToDate.postError;
};

// Reducer

export function settingsCommentsMonthToDateReducer(
  state = initialState,
  action: SettingsCommentsMonthToDateAction
): SettingsCommentsMonthToDateState {
  switch (action.type) {
    case POST_SETTINGS_COMMENTS_REQUEST:
      return {
        ...state,
        postIsLoading: true,
      };
    case POST_SETTINGS_COMMENTS_SUCCESS:
      return {
        ...state,
        postIsLoading: false,
      };
    case POST_SETTINGS_COMMENTS_FAILURE:
      return {
        ...state,
        postError: action.payload.error,
      };
    case CLEAR_SETTINGS_COMMENTS_FAILURE:
      return {
        ...state,
        postError: initialState.postError,
      };
    case FETCH_SETTINGS_COMMENTS_MONTH_TO_DATE_REQUEST:
      return {
        ...state,
        loadingState: 'loading',
      };
    case FETCH_SETTINGS_COMMENTS_MONTH_TO_DATE_SUCCESS:
      return {
        ...state,
        loadingState: 'loaded',
        settingsCommentsMonthToDate: action.payload.settingsCommentsMonthToDate,
      };
    case RENEW_SETTINGS_COMMENTS_MONTH_TO_DATE:
      return initialState;
    default:
      return state;
  }
}
