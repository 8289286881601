import { createSelector } from 'reselect';

import {
  DataTerminalTransition,
  DataTerminalTransitionParams,
} from '../../domain/dataTerminalTransition';
import { LoadingState } from '../../domain/schemas';

import { RootState } from '../../store';
import { filterTransitionDataByField } from '../../utils/filterTransitionDataByField';
import { terminalTransitionCurrentFieldsSelector } from '../ui/terminalTransitionReportsSetting';

// Action Types

const FETCH_DATA_TERMINAL_TRANSITION = 'FETCH_DATA_TERMINAL_TRANSITION' as const;
const FETCH_DATA_TERMINAL_TRANSITION_REQUEST = 'FETCH_DATA_TERMINAL_TRANSITION_REQUEST' as const;
const FETCH_DATA_TERMINAL_TRANSITION_SUCCESS = 'FETCH_DATA_TERMINAL_TRANSITION_SUCCESS' as const;
const RENEW_DATA_TERMINAL_TRANSITION = 'RENEW_DATA_TERMINAL_TRANSITION' as const;

export const DataTerminalTransitionActionTypes = {
  FETCH_DATA_TERMINAL_TRANSITION,
  FETCH_DATA_TERMINAL_TRANSITION_REQUEST,
  FETCH_DATA_TERMINAL_TRANSITION_SUCCESS,
  RENEW_DATA_TERMINAL_TRANSITION,
};

// Action Creators

export function fetchDataTerminalTransitionAction(
  params: DataTerminalTransitionParams
) {
  return {
    type: FETCH_DATA_TERMINAL_TRANSITION,
    payload: { params },
  };
}

function fetchDataTerminalTransitionRequestAction() {
  return {
    type: FETCH_DATA_TERMINAL_TRANSITION_REQUEST,
  };
}

function fetchDataTerminalTransitionSuccessAction(
  dataTerminalTransition: DataTerminalTransition
) {
  return {
    type: FETCH_DATA_TERMINAL_TRANSITION_SUCCESS,
    payload: { dataTerminalTransition },
  };
}

function renewDataTerminalTransitionAction() {
  return {
    type: RENEW_DATA_TERMINAL_TRANSITION,
  };
}

export const DataTerminalTransitionActionCreators = {
  fetchDataTerminalTransitionAction,
  fetchDataTerminalTransitionRequestAction,
  fetchDataTerminalTransitionSuccessAction,
  renewDataTerminalTransitionAction,
};

// Actions

export type FetchDataTerminalTransitionAction = ReturnType<
  typeof fetchDataTerminalTransitionAction
>;

type DataTerminalTransitionAction =
  | FetchDataTerminalTransitionAction
  | ReturnType<typeof fetchDataTerminalTransitionRequestAction>
  | ReturnType<typeof fetchDataTerminalTransitionSuccessAction>
  | ReturnType<typeof renewDataTerminalTransitionAction>;

// State

type DataTerminalTransitionState = {
  loadingState: LoadingState;
  dataTerminalTransition: DataTerminalTransition | undefined;
};

const initialState: DataTerminalTransitionState = {
  loadingState: 'prepare',
  dataTerminalTransition: undefined,
};

// Selector

const dataTerminalTransitionAllSelector = (state: RootState) => {
  return state.dataTerminalTransition;
};

const dataTerminalTransitionFilteredSelector = createSelector(
  [dataTerminalTransitionAllSelector, terminalTransitionCurrentFieldsSelector],
  (state, selectedCurrentField) => {
    if (selectedCurrentField == null || selectedCurrentField === 'all') {
      return state.dataTerminalTransition;
    }

    // 項目絞込で、項目を選択された場合の処理
    // selectedCurrentFieldがallのときに、項目絞込の初期値（項目全体）に戻す
    const dataTerminalTransition = state.dataTerminalTransition;

    // 別のページから来るときに、お気に入りでデータがないケースがあるためundefinedを返却
    if (dataTerminalTransition == null) {
      return undefined;
    }

    // 項目絞込の項目を選択したときに、特定の項目でrow情報を確定する処理
    const filteredData = filterTransitionDataByField(
      dataTerminalTransition,
      selectedCurrentField
    );

    if (filteredData == null) {
      return state.dataTerminalTransition;
    }

    return filteredData;
  }
);

export const dataTerminalTransitionSelector = createSelector(
  dataTerminalTransitionFilteredSelector,
  (data) => {
    return data;
  }
);

export const dataTerminalTransitionLoadingStateSelector = (
  state: RootState
) => {
  return state.dataTerminalTransition.loadingState;
};

export function dataTerminalTransitionColumnsForTransitionSelector(rootState: {
  dataTerminalTransition: DataTerminalTransitionState;
}) {
  return (
    rootState.dataTerminalTransition.dataTerminalTransition?.data
      .columnsForTransition ?? []
  );
}

/**
 * 期間推移の現在表示中のデータの検索条件を取得する
 * @returns 現在の検索条件
 */
export const dataTerminalTransitionSettingsSelector = createSelector(
  dataTerminalTransitionSelector,
  (data) => {
    return data?.setting;
  }
);

/**
 * レスポンスの検索条件からエリア一覧を取得する
 */
export const dataTerminalTransitionSettingAreasSelector = createSelector(
  dataTerminalTransitionSettingsSelector,
  (setting) => setting?.areas
);

// Reducer

export function dataTerminalTransitionReducer(
  state = initialState,
  action: DataTerminalTransitionAction
): DataTerminalTransitionState {
  switch (action.type) {
    case FETCH_DATA_TERMINAL_TRANSITION_REQUEST:
      return {
        ...state,
        loadingState: 'loading',
      };
    case FETCH_DATA_TERMINAL_TRANSITION_SUCCESS:
      return {
        ...state,
        loadingState: 'loaded',
        dataTerminalTransition: action.payload.dataTerminalTransition,
      };
    case RENEW_DATA_TERMINAL_TRANSITION:
      return initialState;
    default:
      return state;
  }
}
