import { eachDayOfInterval, format } from 'date-fns';
import { createSelector } from 'reselect';

import { ChainReportsFormConditions } from '../../domain/chainReportsFormConditions';
import { ChainStoreReportsSettingDateRangeParams } from '../../domain/chainStoreReportsSettingDateRangeParams';
import { SharedUser } from '../../domain/favorites';
import { ShuOption } from '../../domain/shu';

import { RootState } from '../../store';
import { ComparativeSection } from '../../utils/comparativeSectionUtil';
import { dataChainStoreSettingDefaultValue } from '../../utils/dataChainStoreDefaultValue';
import { ReportsDateRange } from '../../utils/reportsDateRange';
import { favoritesSelector } from './settingsFavorites';

/**
 * Action Types
 *
 * プレフィックスの意味
 * SEARCH: ReducerでStateの変更はしないがデータを取得する（検索ボタン等Sagaを発火させたい）
 * CHANGE: Stateを変更してデータも取得する
 * SELECT: Stateを変更する（データ取得は行わない）
 * RESET: Stateを初期状態に戻す（データ取得は行わない）
 */

const SEARCH_CHAIN_STORE_REPORTS = 'SEARCH_CHAIN_STORE_REPORTS' as const;
const SEARCH_RESET_CHAIN_STORE_REPORTS = 'SEARCH_RESET_CHAIN_STORE_REPORTS' as const;

const CHANGE_CHAIN_STORE_REPORTS_FAVORITE = 'CHANGE_CHAIN_STORE_REPORTS_FAVORITE' as const;

const SELECT_CHAIN_STORE_REPORTS_SEARCH_CONDITION = 'SELECT_CHAIN_STORE_REPORTS_SEARCH_CONDITION' as const;
const SELECT_CHAIN_STORE_REPORTS_SHU = 'SELECT_CHAIN_STORE_REPORTS_SHU' as const;
const SELECT_CHAIN_STORE_REPORTS_DATE_RANGE_PARAMS = 'SELECT_CHAIN_STORE_REPORTS_DATE_RANGE_PARAMS' as const;
const SELECT_CHAIN_STORE_REPORTS_TAB_ID = 'SELECT_CHAIN_STORE_REPORTS_TAB_ID' as const;

const RESET_CHAIN_STORE_REPORTS_SEARCH_CONDITION = 'RESET_CHAIN_STORE_REPORTS_SEARCH_CONDITION' as const;
const RESET_CHAIN_STORE_REPORTS_SHU = 'RESET_CHAIN_STORE_REPORTS_SHU' as const;
const RESET_CHAIN_STORE_REPORTS_DATE_RANGE_PARAMS = 'RESET_CHAIN_STORE_REPORTS_DATE_RANGE_PARAMS' as const;

const SAVE_CHAIN_STORE_REPORTS_FAVORITE = 'SAVE_CHAIN_STORE_REPORTS_FAVORITE' as const;
const SAVE_AS_CHAIN_STORE_REPORTS_FAVORITE = 'SAVE_AS_CHAIN_STORE_REPORTS_FAVORITE' as const;
const RESET_CHAIN_STORE_REPORTS_FAVORITE_TO_DEFAULT = 'RESET_CHAIN_STORE_REPORTS_FAVORITE_TO_DEFAULT' as const;

const ADD_CHAIN_STORE_REPORTS_SHU_TABLE_FILTER = 'ADD_CHAIN_STORE_REPORTS_SHU_TABLE_FILTER' as const;
const REMOVE_CHAIN_STORE_REPORTS_SHU_TABLE_FILTER = 'REMOVE_CHAIN_STORE_REPORTS_SHU_TABLE_FILTER' as const;
const APPLY_CHAIN_STORE_REPORTS_SHU_TABLE_FILTER = 'APPLY_CHAIN_STORE_REPORTS_SHU_TABLE_FILTER' as const;
const RESET_ALL_CHAIN_STORE_REPORTS_SHU_TABLE_FILTER = 'RESET_ALL_CHAIN_STORE_REPORTS_SHU_TABLE_FILTER' as const;
const RESET_CHAIN_STORE_REPORTS_SHU_TABLE_FILTER = 'RESET_CHAIN_STORE_REPORTS_SHU_TABLE_FILTER' as const;

const ADD_CHAIN_STORE_REPORTS_KI_TABLE_FILTER = 'ADD_CHAIN_STORE_REPORTS_KI_TABLE_FILTER' as const;
const REMOVE_CHAIN_STORE_REPORTS_KI_TABLE_FILTER = 'REMOVE_CHAIN_STORE_REPORTS_KI_TABLE_FILTER' as const;
const APPLY_CHAIN_STORE_REPORTS_KI_TABLE_FILTER = 'APPLY_CHAIN_STORE_REPORTS_KI_TABLE_FILTER' as const;
const RESET_ALL_CHAIN_STORE_REPORTS_KI_TABLE_FILTER = 'RESET_ALL_CHAIN_STORE_REPORTS_KI_TABLE_FILTER' as const;
const RESET_CHAIN_STORE_REPORTS_KI_TABLE_FILTER = 'RESET_CHAIN_STORE_REPORTS_KI_TABLE_FILTER' as const;

const SEARCH_CHAIN_STORE_REPORTS_DATE_RANGE_SLIDE = 'SEARCH_CHAIN_STORE_REPORTS_DATE_RANGE_SLIDE' as const;
const SELECT_SHU_GRAPH_SHOW_GRAPH_NUMBER_LABEL = 'SELECT_SHU_GRAPH_SHOW_GRAPH_NUMBER_LABEL' as const;
const SELECT_SHU_KADO_GRAPH_SHOW_GRAPH_NUMBER_LABEL = 'SELECT_SHU_KADO_GRAPH_SHOW_GRAPH_NUMBER_LABEL' as const;
const SELECT_KI_GRAPH_SHOW_GRAPH_NUMBER_LABEL = 'SELECT_KI_GRAPH_SHOW_GRAPH_NUMBER_LABEL' as const;

const SELECT_COMPARATIVE_SECTION = 'SELECT_COMPARATIVE_SECTION' as const;

const SELECT_CHAIN_STORE_IS_KI_GRAPH_CLOSED = 'SELECT_CHAIN_STORE_IS_KI_GRAPH_CLOSED' as const;
const SELECT_CHAIN_STORE_REPORTS_CHECK_KI = 'SELECT_CHAIN_STORE_REPORTS_CHECK_KI' as const;
const SELECT_CHAIN_STORE_REPORTS_UNCHECK_KI = 'SELECT_CHAIN_STORE_REPORTS_UNCHECK_KI' as const;
const SELECT_CHAIN_STORE_REPORTS_CHECK_KI_AS_NEW_MODEL_BY_SHUCODE = 'SELECT_CHAIN_STORE_REPORTS_CHECK_KI_AS_NEW_MODEL_BY_SHUCODE' as const;
const SELECT_CHAIN_STORE_REPORTS_CHECKED_KI_LIST = 'SELECT_CHAIN_STORE_REPORTS_CHECKED_KI_LIST' as const;
const RESET_CHAIN_STORE_REPORTS_CHECKED_KI_LIST_AS_NEW_MODEL_BY_SHU_CODE = 'RESET_CHAIN_STORE_REPORTS_CHECKED_KI_LIST_AS_NEW_MODEL_BY_SHU_CODE' as const;
const RESET_CHAIN_STORE_REPORTS_IS_GRAPH_CLOSED_BY_USER = 'RESET_CHAIN_STORE_REPORTS_IS_GRAPH_CLOSED_BY_USER' as const;
const RESET_CHAIN_STORE_REPORTS_CHECKED_KI_LIST = 'RESET_CHAIN_STORE_REPORTS_CHECKED_KI_LIST' as const;
const SELECT_CHAIN_STORE_REPORTS_IS_GRAPH_CLOSED_BY_USER = 'SELECT_CHAIN_STORE_REPORTS_IS_GRAPH_CLOSED_BY_USER' as const;
const SELECT_CHAIN_STORE_REPORTS_CONTAINS_AREA_AVERAGE = 'SELECT_CHAIN_STORE_REPORTS_CONTAINS_AREA_AVERAGE' as const;
const CREATE_CHAIN_STORE_REPORTS_SHORTENED_URL = 'CREATE_CHAIN_STORE_REPORTS_SHORTENED_URL' as const;

export const ChainStoreSettingActionTypes = {
  SEARCH_CHAIN_STORE_REPORTS,
  SEARCH_RESET_CHAIN_STORE_REPORTS,
  CHANGE_CHAIN_STORE_REPORTS_FAVORITE,
  SELECT_CHAIN_STORE_REPORTS_SEARCH_CONDITION,
  SELECT_CHAIN_STORE_REPORTS_SHU,
  SELECT_CHAIN_STORE_REPORTS_DATE_RANGE_PARAMS,
  SELECT_CHAIN_STORE_REPORTS_TAB_ID,
  RESET_CHAIN_STORE_REPORTS_SEARCH_CONDITION,
  RESET_CHAIN_STORE_REPORTS_SHU,
  RESET_CHAIN_STORE_REPORTS_DATE_RANGE_PARAMS,
  SAVE_CHAIN_STORE_REPORTS_FAVORITE,
  SAVE_AS_CHAIN_STORE_REPORTS_FAVORITE,
  RESET_CHAIN_STORE_REPORTS_FAVORITE_TO_DEFAULT,
  ADD_CHAIN_STORE_REPORTS_SHU_TABLE_FILTER,
  REMOVE_CHAIN_STORE_REPORTS_SHU_TABLE_FILTER,
  APPLY_CHAIN_STORE_REPORTS_SHU_TABLE_FILTER,
  RESET_ALL_CHAIN_STORE_REPORTS_SHU_TABLE_FILTER,
  RESET_CHAIN_STORE_REPORTS_SHU_TABLE_FILTER,
  ADD_CHAIN_STORE_REPORTS_KI_TABLE_FILTER,
  REMOVE_CHAIN_STORE_REPORTS_KI_TABLE_FILTER,
  APPLY_CHAIN_STORE_REPORTS_KI_TABLE_FILTER,
  RESET_ALL_CHAIN_STORE_REPORTS_KI_TABLE_FILTER,
  RESET_CHAIN_STORE_REPORTS_KI_TABLE_FILTER,
  SEARCH_CHAIN_STORE_REPORTS_DATE_RANGE_SLIDE,
  SELECT_SHU_GRAPH_SHOW_GRAPH_NUMBER_LABEL,
  SELECT_SHU_KADO_GRAPH_SHOW_GRAPH_NUMBER_LABEL,
  SELECT_KI_GRAPH_SHOW_GRAPH_NUMBER_LABEL,
  SELECT_COMPARATIVE_SECTION,
  SELECT_CHAIN_STORE_IS_KI_GRAPH_CLOSED,
  SELECT_CHAIN_STORE_REPORTS_CHECK_KI,
  SELECT_CHAIN_STORE_REPORTS_UNCHECK_KI,
  SELECT_CHAIN_STORE_REPORTS_CHECK_KI_AS_NEW_MODEL_BY_SHUCODE,
  SELECT_CHAIN_STORE_REPORTS_CHECKED_KI_LIST,
  RESET_CHAIN_STORE_REPORTS_CHECKED_KI_LIST_AS_NEW_MODEL_BY_SHU_CODE,
  RESET_CHAIN_STORE_REPORTS_IS_GRAPH_CLOSED_BY_USER,
  RESET_CHAIN_STORE_REPORTS_CHECKED_KI_LIST,
  SELECT_CHAIN_STORE_REPORTS_IS_GRAPH_CLOSED_BY_USER,
  SELECT_CHAIN_STORE_REPORTS_CONTAINS_AREA_AVERAGE,
  CREATE_CHAIN_STORE_REPORTS_SHORTENED_URL,
};

/**
 * Action Creators
 */

/**
 * 指定した検索条件でデータを取得する（検索ボタン押下時）
 * @param params 検索条件
 */
export function searchChainStoreReportsAction(
  params: ChainReportsFormConditions,
  selectedShu: ShuOption[],
  dateRange: ChainStoreReportsSettingDateRangeParams['dateRange'],
  dateUnit: ChainStoreReportsSettingDateRangeParams['dateUnit'],
  isComparison: ChainStoreReportsSettingDateRangeParams['isComparison'],
  comparativeSection: ComparativeSection
) {
  return {
    type: SEARCH_CHAIN_STORE_REPORTS,
    payload: {
      params,
      selectedShu,
      dateRange,
      dateUnit,
      isComparison,
      comparativeSection,
    },
  };
}

/**
 * 初期条件でデータを取得する（リセットボタン押下時）
 */
export function searchResetChainStoreReportsAction() {
  return {
    type: SEARCH_RESET_CHAIN_STORE_REPORTS,
  };
}

/**
 * 選択したお気に入りでデータを取得する
 * @param favorite 変更するお気に入りID（undefined時は選択なし）
 */
function changeChainStoreReportsFavoriteAction(favorite?: number) {
  return {
    type: CHANGE_CHAIN_STORE_REPORTS_FAVORITE,
    payload: { favorite },
  };
}

/**
 * 検索条件を変更する（データ取得は行わない）
 * @param params 検索条件
 */
function selectChainStoreReportsSearchConditionAction(
  params: ChainReportsFormConditions
) {
  return {
    type: SELECT_CHAIN_STORE_REPORTS_SEARCH_CONDITION,
    payload: { params },
  };
}

/**
 * 種別一覧を変更
 * @param shus 種別一覧
 */
function selectChainStoreReportsShuAction(shus: ShuOption[]) {
  return {
    type: SELECT_CHAIN_STORE_REPORTS_SHU,
    payload: { shus },
  };
}

/**
 * 選択中の期間を変更
 * @param dateRange 期間
 * @param dateUnit 日付単位
 * @param isComparison 比較期間の有無
 * @param comparativeSection 比較区分
 */
function selectChainStoreReportsDateRangeParamsAction(
  dateRange: ChainStoreReportsSettingDateRangeParams['dateRange'],
  dateUnit: ChainStoreReportsSettingDateRangeParams['dateUnit'],
  isComparison: ChainStoreReportsSettingDateRangeParams['isComparison'],
  comparativeSection: ComparativeSection
) {
  return {
    type: SELECT_CHAIN_STORE_REPORTS_DATE_RANGE_PARAMS,
    payload: { dateRange, dateUnit, isComparison, comparativeSection },
  };
}

/**
 * 選択中のタブを変更
 * @param tabID 変更するタブID
 */
function selectChainStoreReportsTabId(tabID: string) {
  return {
    type: SELECT_CHAIN_STORE_REPORTS_TAB_ID,
    payload: { tabID },
  };
}

/**
 * 検索条件をデフォルトに戻す
 */
export function resetChainStoreReportsSearchConditionAction() {
  return {
    type: RESET_CHAIN_STORE_REPORTS_SEARCH_CONDITION,
  };
}

/**
 * 選択中の種別一覧をデフォルトに戻す
 */
function resetChainStoreReportsShuAction() {
  return {
    type: RESET_CHAIN_STORE_REPORTS_SHU,
  };
}

/**
 * 選択中の期間をデフォルトに戻す
 */
function resetChainStoreReportsDateRangeParamsAction() {
  return {
    type: RESET_CHAIN_STORE_REPORTS_DATE_RANGE_PARAMS,
  };
}

/**
 * 現在の検索条件でお気に入りを上書き保存する
 * @param name お気に入り名
 * @param isShared 共有設定
 * @param memo メモ
 * @param mode メモ更新モード
 */
function saveChainStoreReportsFavoriteAction(
  name: string,
  isShared: boolean,
  memo: string,
  sharedUser: SharedUser[],
  mode?: 'standard' | 'memo'
) {
  return {
    type: SAVE_CHAIN_STORE_REPORTS_FAVORITE,
    payload: { name, isShared, memo, sharedUser, mode },
  };
}

/**
 * 現在の検索条件でお気に入りを新規保存する
 * @param name お気に入り名
 * @param isShared 共有設定
 * @param memo メモ
 */
function saveAsChainStoreReportsFavoriteAction(
  name: string,
  isShared: boolean,
  memo: string,
  sharedUser: SharedUser[]
) {
  return {
    type: SAVE_AS_CHAIN_STORE_REPORTS_FAVORITE,
    payload: { name, isShared, memo, sharedUser },
  };
}

/**
 * データを取得せずにお気に入りをデフォルトに戻す
 */
function resetChainStoreReportsFavoriteToDefaultAction() {
  return {
    type: RESET_CHAIN_STORE_REPORTS_FAVORITE_TO_DEFAULT,
  };
}

/**
 * テーブルの非表示項目を追加する（種別実績）
 * @param code コード名
 * @param columnCode カラム名
 */
function addChainStoreReportsShuTableFilterAction(
  code: string,
  columnCode: string
) {
  return {
    type: ADD_CHAIN_STORE_REPORTS_SHU_TABLE_FILTER,
    payload: { code, columnCode },
  };
}

/**
 * テーブルの表示項目を削除する（種別実績）
 * @param code コード名
 * @param columnCode カラム名
 */
function removeChainStoreReportsShuTableFilterAction(
  code: string,
  columnCode: string
) {
  return {
    type: REMOVE_CHAIN_STORE_REPORTS_SHU_TABLE_FILTER,
    payload: { code, columnCode },
  };
}

/**
 * テーブルの非表示項目を反映する（種別実績）
 * @param tableFilterItemsShu テーブルの非表示項目
 */
function applyChainStoreReportsShuTableFilterAction(
  tableFilterItemsShu: { code: string; items: string[] }[]
) {
  return {
    type: APPLY_CHAIN_STORE_REPORTS_SHU_TABLE_FILTER,
    payload: { tableFilterItemsShu },
  };
}

/**
 * テーブルの非表示項目をリセットする（種別実績）
 */
function resetChainStoreReportsShuTableFilterAction(code: string) {
  return {
    type: RESET_CHAIN_STORE_REPORTS_SHU_TABLE_FILTER,
    payload: { code },
  };
}

/**
 * テーブルの非表示項目をすべてリセットする（種別実績）
 */
function resetAllChainStoreReportsShuTableFilterAction() {
  return {
    type: RESET_ALL_CHAIN_STORE_REPORTS_SHU_TABLE_FILTER,
  };
}

/**
 * テーブルの非表示項目を追加する（新台/メイン機種）
 * @param code コード名
 * @param columnCode カラム名
 */
function addChainStoreReportsKiTableFilterAction(
  code: string,
  columnCode: string
) {
  return {
    type: ADD_CHAIN_STORE_REPORTS_KI_TABLE_FILTER,
    payload: { code, columnCode },
  };
}

/**
 * テーブルの表示項目を削除する（新台/メイン機種）
 * @param code コード名
 * @param columnCode カラム名
 */
function removeChainStoreReportsKiTableFilterAction(
  code: string,
  columnCode: string
) {
  return {
    type: REMOVE_CHAIN_STORE_REPORTS_KI_TABLE_FILTER,
    payload: { code, columnCode },
  };
}

/**
 * テーブルの非表示項目を反映する（新台/メイン機種）
 * @param tableFilterItemsKi テーブルの非表示項目
 */
function applyChainStoreReportsKiTableFilterAction(
  tableFilterItemsKi: { code: string; items: string[] }[]
) {
  return {
    type: APPLY_CHAIN_STORE_REPORTS_KI_TABLE_FILTER,
    payload: { tableFilterItemsKi },
  };
}

/**
 * テーブルの非表示項目をリセットする（新台/メイン機種）
 */
function resetChainStoreReportsKiTableFilterAction(code: string) {
  return {
    type: RESET_CHAIN_STORE_REPORTS_KI_TABLE_FILTER,
    payload: { code },
  };
}

/**
 * テーブルの非表示項目をすべてリセットする（新台/メイン機種）
 */
function resetAllChainStoreReportsKiTableFilterAction() {
  return {
    type: RESET_ALL_CHAIN_STORE_REPORTS_KI_TABLE_FILTER,
  };
}

/**
 * 期間スライドコンポーネントがクリックされた時に、期間を変更して検索を実行する
 * @param {ReportsDateRange} newDateRange   期間単位
 * @param {string} newStartDate             検索期間 開始日
 * @param {string} newEndDate               検索期間 終了日
 * @param {string} newStartComparisonDate   比較期間 開始日
 * @param {string} newEndComparisonDate     比較期間 終了日
 */
export function searchChainStoreReportsDateRangeSlideAction(
  newDateRange: ReportsDateRange,
  newStartDate: string,
  newEndDate: string,
  newStartComparisonDate: string | undefined,
  newEndComparisonDate: string | undefined
) {
  return {
    type: SEARCH_CHAIN_STORE_REPORTS_DATE_RANGE_SLIDE,
    payload: {
      dateRange: newDateRange,
      ymdList: eachDayOfInterval({
        start: new Date(newStartDate),
        end: new Date(newEndDate),
      }).map((date) => format(date, 'yyyy-MM-dd')),
      ymdComparisonList:
        newStartComparisonDate !== undefined &&
        newEndComparisonDate !== undefined
          ? eachDayOfInterval({
              start: new Date(newStartComparisonDate),
              end: new Date(newEndComparisonDate),
            }).map((date) => format(date, 'yyyy-MM-dd'))
          : undefined,
    },
  };
}

/**
 * 推移グラフの折れ線グラフに数値を表示するかどうかのフラグをセットする
 * @param {boolean} flag 表示フラグ
 */
function selectShuGraphShowNumberLabelAction(flag: boolean) {
  return {
    type: SELECT_SHU_GRAPH_SHOW_GRAPH_NUMBER_LABEL,
    payload: {
      flag,
    },
  };
}

/**
 * 稼働グラフの折れ線グラフに数値を表示するかどうかのフラグをセットする
 * @param {boolean} flag 表示フラグ
 */
function selectShuKadoGraphShowNumberLabelAction(flag: boolean) {
  return {
    type: SELECT_SHU_KADO_GRAPH_SHOW_GRAPH_NUMBER_LABEL,
    payload: {
      flag,
    },
  };
}

/**
 * 推移グラフの折れ線グラフに数値を表示するかどうかのフラグをセットする
 * @param {boolean} flag 表示フラグ
 */
function selectKiGraphShowNumberLabelAction(flag: boolean) {
  return {
    type: SELECT_KI_GRAPH_SHOW_GRAPH_NUMBER_LABEL,
    payload: {
      flag,
    },
  };
}

/**
 * カレンダーモーダル内の比較区分を変更する
 * @param {string} comparativeSection 比較区分
 */
function selectComparativeSection(
  comparativeSection: ComparativeSection | undefined
) {
  return {
    type: SELECT_COMPARATIVE_SECTION,
    payload: { comparativeSection },
  };
}

/**
 * ユーザーがグラフ閉じたときにどの種別のグラフを閉じたかを保持する
 */
export function selectChainStoreReportsIsKiGraphClosedAction(shuCode: string) {
  return {
    type: SELECT_CHAIN_STORE_IS_KI_GRAPH_CLOSED,
    payload: { shuCode },
  };
}

/**
 * チェックボックにチェックを付ける
 */
export const selectCheckKi = (shuCode: string, modelCode: string) => ({
  type: SELECT_CHAIN_STORE_REPORTS_CHECK_KI,
  payload: { shuCode, modelCode },
});

/**
 * チェックボックのチェックをはずす
 */
export const selectUncheckKi = (shuCode: string, modelCode: string) => ({
  type: SELECT_CHAIN_STORE_REPORTS_UNCHECK_KI,
  payload: { shuCode, modelCode },
});

/**
 * 種別ごとに複数の機種を一括でチェックする
 */
export const selectCheckKiAsNewModelsByShuCode = (
  shuCode: string,
  modelCodes: string[]
) => ({
  type: SELECT_CHAIN_STORE_REPORTS_CHECK_KI_AS_NEW_MODEL_BY_SHUCODE,
  payload: { shuCode, modelCodes },
});

/**
 * 指定した種別の機種のチェックをすべて外す
 */
export const resetCheckedKiListAsNewModelByShuCode = (shuCode: string) => ({
  type: RESET_CHAIN_STORE_REPORTS_CHECKED_KI_LIST_AS_NEW_MODEL_BY_SHU_CODE,
  payload: { shuCode },
});

/**
 * お気に入りの復元でチェックを一括で設定する
 */
export const selectCheckedKiListForFavorite = (
  checkedModelCodes: ChainStoreReportsSettingState['checkedModelCodes']
) => ({
  type: SELECT_CHAIN_STORE_REPORTS_CHECKED_KI_LIST,
  payload: { checkedModelCodes },
});

/**
 * グラフの開閉状態を初期値に戻す
 */
export const resetChainStoreReportsIsGraphClosedByUser = () => ({
  type: RESET_CHAIN_STORE_REPORTS_IS_GRAPH_CLOSED_BY_USER,
});

/**
 * 機種のチェック状態を初期値に戻す
 */
export const resetChainStoreReportsCheckedKiList = () => ({
  type: RESET_CHAIN_STORE_REPORTS_CHECKED_KI_LIST,
});

/**
 * お気に入りからユーザーが閉じたグラフの種別を復元
 */
const selectChainStoreReportsIsGraphClosedByUserForFavorite = (
  isKiGraphClosedByUser: ChainStoreReportsSettingState['isKiGraphClosedByUser']
) => ({
  type: SELECT_CHAIN_STORE_REPORTS_IS_GRAPH_CLOSED_BY_USER,
  payload: { isKiGraphClosedByUser },
});

const selectChainStoreReportsContainsAreaAverage = (
  containsAreaAverage: boolean
) => ({
  type: SELECT_CHAIN_STORE_REPORTS_CONTAINS_AREA_AVERAGE,
  payload: { containsAreaAverage },
});

/**
 * 画面共有用の短縮URLを作成する
 */
function createChainStoreReportsShortenedUrlAction(
  pageName: string,
  locationUrl: string
) {
  return {
    type: CREATE_CHAIN_STORE_REPORTS_SHORTENED_URL,
    payload: { pageName, locationUrl },
  };
}

export const ChainStoreSettingActionCreators = {
  searchChainStoreReportsAction,
  searchResetChainStoreReportsAction,
  changeChainStoreReportsFavoriteAction,
  selectChainStoreReportsSearchConditionAction,
  selectChainStoreReportsShuAction,
  selectChainStoreReportsDateRangeParamsAction,
  selectChainStoreReportsTabId,
  resetChainStoreReportsSearchConditionAction,
  resetChainStoreReportsShuAction,
  resetChainStoreReportsDateRangeParamsAction,
  saveChainStoreReportsFavoriteAction,
  saveAsChainStoreReportsFavoriteAction,
  resetChainStoreReportsFavoriteToDefaultAction,
  addChainStoreReportsShuTableFilterAction,
  removeChainStoreReportsShuTableFilterAction,
  applyChainStoreReportsShuTableFilterAction,
  resetChainStoreReportsShuTableFilterAction,
  resetAllChainStoreReportsShuTableFilterAction,
  addChainStoreReportsKiTableFilterAction,
  removeChainStoreReportsKiTableFilterAction,
  applyChainStoreReportsKiTableFilterAction,
  resetChainStoreReportsKiTableFilterAction,
  resetAllChainStoreReportsKiTableFilterAction,
  searchChainStoreReportsDateRangeSlideAction,
  selectShuGraphShowNumberLabelAction,
  selectShuKadoGraphShowNumberLabelAction,
  selectKiGraphShowNumberLabelAction,
  selectComparativeSection,
  selectChainStoreReportsIsKiGraphClosedAction,
  selectCheckKi,
  selectUncheckKi,
  selectCheckKiAsNewModelsByShuCode,
  resetCheckedKiListAsNewModelByShuCode,
  selectCheckedKiListForFavorite,
  resetChainStoreReportsIsGraphClosedByUser,
  resetChainStoreReportsCheckedKiList,
  selectChainStoreReportsIsGraphClosedByUserForFavorite,
  selectChainStoreReportsContainsAreaAverage,
  createChainStoreReportsShortenedUrlAction,
};

/**
 * Actions
 */

export type SearchChainStoreAction = ReturnType<
  typeof searchChainStoreReportsAction
>;
type SearchResetChainStoreAction = ReturnType<
  typeof searchResetChainStoreReportsAction
>;
export type ChangeChainStoreReportsFavoriteAction = ReturnType<
  typeof changeChainStoreReportsFavoriteAction
>;
type SelectChainStoreReportsSearchConditionAction = ReturnType<
  typeof selectChainStoreReportsSearchConditionAction
>;
type SelectChainStoreShuAction = ReturnType<
  typeof selectChainStoreReportsShuAction
>;
type SelectChainStoreDateRangeParamsAction = ReturnType<
  typeof selectChainStoreReportsDateRangeParamsAction
>;
type SelectChainStoreReportsTabId = ReturnType<
  typeof selectChainStoreReportsTabId
>;
type ResetChainStoreReportsSearchConditionAction = ReturnType<
  typeof resetChainStoreReportsSearchConditionAction
>;
export type ResetChainStoreShuAction = ReturnType<
  typeof resetChainStoreReportsShuAction
>;
type ResetChainStoreDateRangeParamsAction = ReturnType<
  typeof resetChainStoreReportsDateRangeParamsAction
>;
export type SaveChainStoreReportsFavoriteAction = ReturnType<
  typeof saveChainStoreReportsFavoriteAction
>;
export type SaveAsChainStoreReportsFavoriteAction = ReturnType<
  typeof saveAsChainStoreReportsFavoriteAction
>;
type ResetChainStoreReportsFavoriteToDefaultAction = ReturnType<
  typeof resetChainStoreReportsFavoriteToDefaultAction
>;
type AddChainStoreReportsShuTableFilterAction = ReturnType<
  typeof addChainStoreReportsShuTableFilterAction
>;
type RemoveChainStoreReportsShuTableFilterAction = ReturnType<
  typeof removeChainStoreReportsShuTableFilterAction
>;
type ApplyChainStoreReportsShuTableFilterAction = ReturnType<
  typeof applyChainStoreReportsShuTableFilterAction
>;
type ResetChainStoreReportsShuTableFilterAction = ReturnType<
  typeof resetChainStoreReportsShuTableFilterAction
>;
type ResetAllChainStoreReportsShuTableFilterAction = ReturnType<
  typeof resetAllChainStoreReportsShuTableFilterAction
>;
type AddChainStoreReportsKiTableFilterAction = ReturnType<
  typeof addChainStoreReportsKiTableFilterAction
>;
type RemoveChainStoreReportsKiTableFilterAction = ReturnType<
  typeof removeChainStoreReportsKiTableFilterAction
>;
type ApplyChainStoreReportsKiTableFilterAction = ReturnType<
  typeof applyChainStoreReportsKiTableFilterAction
>;
type ResetChainStoreReportsKiTableFilterAction = ReturnType<
  typeof resetChainStoreReportsKiTableFilterAction
>;
type ResetAllChainStoreReportsKiTableFilterAction = ReturnType<
  typeof resetAllChainStoreReportsKiTableFilterAction
>;
export type SearchChainStoreReportsDateRangeSlideAction = ReturnType<
  typeof searchChainStoreReportsDateRangeSlideAction
>;
type SelectShuGraphShowNumberLabelAction = ReturnType<
  typeof selectShuGraphShowNumberLabelAction
>;
type SelectShuKadoGraphShowNumberLabelAction = ReturnType<
  typeof selectShuKadoGraphShowNumberLabelAction
>;
type SelectKiGraphShowNumberLabelAction = ReturnType<
  typeof selectKiGraphShowNumberLabelAction
>;
type SelectComparativeSectionAction = ReturnType<
  typeof selectComparativeSection
>;
type SelectChainStoreReportsIsKiGraphClosedAction = ReturnType<
  typeof selectChainStoreReportsIsKiGraphClosedAction
>;
type SelectCheckKi = ReturnType<typeof selectCheckKi>;
type SelectUncheckKi = ReturnType<typeof selectUncheckKi>;
type SelectCheckKiAsNewModelsByShuCode = ReturnType<
  typeof selectCheckKiAsNewModelsByShuCode
>;
type ResetCheckedKiListAsNewModelByShuCode = ReturnType<
  typeof resetCheckedKiListAsNewModelByShuCode
>;
type SelectCheckedKiListForFavorite = ReturnType<
  typeof selectCheckedKiListForFavorite
>;
type ResetChainStoreReportsIsGraphClosedByUser = ReturnType<
  typeof resetChainStoreReportsIsGraphClosedByUser
>;
type ResetChainStoreReportsCheckedKiList = ReturnType<
  typeof resetChainStoreReportsCheckedKiList
>;
type SelectChainStoreReportsIsGraphClosedByUserForFavorite = ReturnType<
  typeof selectChainStoreReportsIsGraphClosedByUserForFavorite
>;
type SelectChainStoreReportsContainsAreaAverage = ReturnType<
  typeof selectChainStoreReportsContainsAreaAverage
>;

export type CreateChainStoreReportsShortenedUrlAction = ReturnType<
  typeof createChainStoreReportsShortenedUrlAction
>;

type ChainStoreSettingAction =
  | SearchChainStoreAction
  | SearchResetChainStoreAction
  | ChangeChainStoreReportsFavoriteAction
  | SelectChainStoreReportsSearchConditionAction
  | SelectChainStoreShuAction
  | SelectChainStoreDateRangeParamsAction
  | SelectChainStoreReportsTabId
  | ResetChainStoreReportsSearchConditionAction
  | ResetChainStoreShuAction
  | ResetChainStoreDateRangeParamsAction
  | SaveChainStoreReportsFavoriteAction
  | SaveAsChainStoreReportsFavoriteAction
  | ResetChainStoreReportsFavoriteToDefaultAction
  | AddChainStoreReportsShuTableFilterAction
  | RemoveChainStoreReportsShuTableFilterAction
  | ApplyChainStoreReportsShuTableFilterAction
  | ResetChainStoreReportsShuTableFilterAction
  | ResetAllChainStoreReportsShuTableFilterAction
  | AddChainStoreReportsKiTableFilterAction
  | RemoveChainStoreReportsKiTableFilterAction
  | ApplyChainStoreReportsKiTableFilterAction
  | ResetChainStoreReportsKiTableFilterAction
  | ResetAllChainStoreReportsKiTableFilterAction
  | SearchChainStoreReportsDateRangeSlideAction
  | SelectShuGraphShowNumberLabelAction
  | SelectShuKadoGraphShowNumberLabelAction
  | SelectKiGraphShowNumberLabelAction
  | SelectComparativeSectionAction
  | SelectChainStoreReportsIsKiGraphClosedAction
  | SelectCheckKi
  | SelectUncheckKi
  | SelectCheckKiAsNewModelsByShuCode
  | ResetCheckedKiListAsNewModelByShuCode
  | SelectCheckedKiListForFavorite
  | ResetChainStoreReportsIsGraphClosedByUser
  | ResetChainStoreReportsCheckedKiList
  | SelectChainStoreReportsIsGraphClosedByUserForFavorite
  | SelectChainStoreReportsContainsAreaAverage
  | CreateChainStoreReportsShortenedUrlAction;

/**
 * State
 */

export type ChainStoreReportsSettingState = {
  /**
   * 現在の検索条件
   */
  searchCondition: ChainReportsFormConditions;
  /**
   * 現在選択中の種別・種別グループ
   */
  selectedShu: ShuOption[];
  /**
   * 現在選択中の期間指定
   */
  selectedDateRangeParams: ChainStoreReportsSettingDateRangeParams;
  /**
   * 選択中のお気に入りID
   */
  selectedFavoriteId?: number;
  /**
   * 選択中のタブのID
   */
  selectedTabId: string | undefined;
  /**
   * テーブルの非表示項目一覧
   */
  tableFilterItemsShu: { code: string; items: string[] }[];
  tableFilterItemsKi: { code: string; items: string[] }[];
  /**
   * 種別実績推移グラフの折れ線グラフに数値を表示するかどうかのフラグ
   */
  showGraphNumberLabel: boolean;
  /**
   * 種別実績稼働グラフの折れ線グラフに数値を表示するかどうかのフラグ
   */
  showKadoGraphNumberLabel: boolean;
  /**
   * 新台/メイン機種推移グラフの折れ線グラフに数値を表示するかどうかのフラグ
   */
  showKiGraphNumberLabel: boolean;
  /**
   * 選択している比較区分
   */
  comparativeSection: ComparativeSection | undefined;
  /**
   * 機種別グラフをユーザーが閉じたかどうかのフラグ;
   */
  isKiGraphClosedByUser: {
    [shuCode: string]: boolean;
  };
  /**
   * チェックの付いた機種のコード
   */
  checkedModelCodes: {
    byUser: {
      // ユーザーによって手動でチェックされたもの
      [shuCode: string]: string[];
    };
    asNewModel: {
      // ページ読み込み時に新機種に自動でチェックが入ったもの
      [shuCode: string]: string[];
    };
  };
};

const initialDateRangeParams: ChainStoreReportsSettingDateRangeParams = {
  dateUnit: dataChainStoreSettingDefaultValue().dateRangeParams.dateUnit,
  dateRange: dataChainStoreSettingDefaultValue().dateRangeParams.dateRange,
  isComparison: dataChainStoreSettingDefaultValue().dateRangeParams
    .isComparison,
};

// Stateの初期値
export const initialState: ChainStoreReportsSettingState = {
  searchCondition: dataChainStoreSettingDefaultValue().setting,
  selectedShu: [],
  selectedDateRangeParams: initialDateRangeParams,
  selectedFavoriteId: undefined,
  selectedTabId: undefined,
  tableFilterItemsShu: [],
  tableFilterItemsKi: [],
  showGraphNumberLabel: true,
  showKadoGraphNumberLabel: true,
  showKiGraphNumberLabel: true,
  comparativeSection: undefined,
  isKiGraphClosedByUser: {},
  checkedModelCodes: {
    byUser: {},
    asNewModel: {},
  },
};

/**
 * Selector
 */

// チェーン店レポート設定全てを取得する
export function chainStoreReportsSettingSelector(rootState: RootState) {
  return rootState.chainStoreReportsSetting;
}

// チェーン店レポートの検索条件を取得する
export const chainStoreReportsSearchConditionSelector = createSelector(
  chainStoreReportsSettingSelector,
  ({ searchCondition }) => searchCondition
);

// チェーン店レポートで選択中の種別・種別グループを取得する
export const chainStoreReportsSelectedShuSelector = createSelector(
  chainStoreReportsSettingSelector,
  ({ selectedShu }) => selectedShu
);

// チェーン店レポートで選択中の期間指定を取得する
export const chainStoreReportsSelectedDateRangeParamsSelector = createSelector(
  chainStoreReportsSettingSelector,
  ({ selectedDateRangeParams }) => selectedDateRangeParams
);

// チェーン店レポートで選択中のお気に入りIDを取得する
export const chainStoreReportsSelectedFavoriteSelector = createSelector(
  chainStoreReportsSettingSelector,
  ({ selectedFavoriteId }) => selectedFavoriteId
);

/**
 * チェーン店レポートで現在選択中のお気に入りデータを取得する
 * @returns 現在選択中のお気に入りデータ（未選択時: undefined）
 */
export const chainStoreReportsSelectedFavoriteDataSelector = createSelector(
  [chainStoreReportsSelectedFavoriteSelector, favoritesSelector],
  (favoriteId, favorites) => {
    if (favoriteId === undefined) return;

    return favorites?.favorites?.find((favorite) => favorite.id === favoriteId);
  }
);

/**
 * 現在選択中のお気に入りのチェーン店レポートの各種データ
 */
export const chainStoreReportsSelectedFavoriteSettingSelector = createSelector(
  [chainStoreReportsSelectedFavoriteSelector, favoritesSelector],
  (favoriteId, favorite) => {
    if (!favoriteId) return;

    const favoriteItem = favorite?.favorites?.find(
      (item) => item.id === favoriteId
    );

    return favoriteItem?.pageSetting?.chainStoreReports;
  }
);

/**
 * 現在選択中のタブのIDを取得する
 */
export const chainStoreReportsSelectedTabIDSelector = createSelector(
  chainStoreReportsSettingSelector,
  ({ selectedTabId }) => selectedTabId
);

/**
 * 非表示項目一覧を取得する
 * @returns 非表示項目一覧
 */
export const chainReportsShuSelectedTableFilterSelector = createSelector(
  chainStoreReportsSettingSelector,
  ({ tableFilterItemsShu }) => tableFilterItemsShu
);

/**
 * 非表示項目一覧を取得する(新台/メイン機種)
 * @returns 非表示項目一覧
 */
export const chainReportsKiSelectedTableFilterSelector = createSelector(
  chainStoreReportsSettingSelector,
  ({ tableFilterItemsKi }) => tableFilterItemsKi
);

// 種別実績の推移グラフで数値ラベルを表示するかどうか
export const chainReportsShowNumberLabelSelector = createSelector(
  chainStoreReportsSettingSelector,
  (setting) => setting.showGraphNumberLabel
);

// 種別実績の稼働グラフで数値ラベルを表示するかどうか
export const chainReportsShowKadoNumberLabelSelector = createSelector(
  chainStoreReportsSettingSelector,
  (setting) => setting.showKadoGraphNumberLabel
);

// 種別別実績の推移グラフで数値ラベルを表示するかどうか
export const chainReportsKiShowNumberLabelSelector = createSelector(
  chainStoreReportsSettingSelector,
  (setting) => setting.showKiGraphNumberLabel
);

// 比較区分を取得する
export const chainReportsComparativeSectionSelector = createSelector(
  chainStoreReportsSettingSelector,
  (setting) => setting.comparativeSection
);

// 機種別グラフをユーザーが閉じたかどうかのフラグ
export const chainReportsIsKiGraphClosedSelectorCallback = (
  {
    isKiGraphClosedByUser,
  }: {
    isKiGraphClosedByUser: ChainStoreReportsSettingState['isKiGraphClosedByUser'];
  },
  shuCode: string
) => isKiGraphClosedByUser[shuCode] ?? false;

export const chainReportsIsKiGraphClosedSelector = createSelector(
  [
    chainStoreReportsSettingSelector,
    (_: RootState, shuCode: string) => shuCode,
  ],
  chainReportsIsKiGraphClosedSelectorCallback
);

// 種別グループごとにチェックされた機種コードを返す
export const chainReportsCheckedModelCodesSelectorCallback = (
  {
    checkedModelCodes,
  }: { checkedModelCodes: ChainStoreReportsSettingState['checkedModelCodes'] },
  shuCode: string
) => {
  const byUser = checkedModelCodes.byUser[shuCode];
  // ユーザーが手動でチェックしたものがあればそれが優先
  if (byUser && byUser.length > 0) {
    return checkedModelCodes.byUser[shuCode];
  }
  return checkedModelCodes.asNewModel[shuCode]
    ? checkedModelCodes.asNewModel[shuCode]
    : [];
};
export const chainReportsCheckedModelCodesSelector = createSelector(
  [
    chainStoreReportsSettingSelector,
    (_: RootState, shuCode: string) => shuCode,
  ],
  chainReportsCheckedModelCodesSelectorCallback
);

// 種別グループごとにユーザーがチェックした機種コードを返す
export const chainReportsCheckedByUserModelCodeslSelectorCallback = (
  {
    checkedModelCodes,
  }: { checkedModelCodes: ChainStoreReportsSettingState['checkedModelCodes'] },
  shuCode: string
) =>
  checkedModelCodes.byUser[shuCode] ? checkedModelCodes.byUser[shuCode] : [];

export const chainReportsCheckedByUserModelCodeslSelector = createSelector(
  [
    chainStoreReportsSettingSelector,
    (_: RootState, shuCode: string) => shuCode,
  ],
  chainReportsCheckedByUserModelCodeslSelectorCallback
);

/**
 * Reducer
 */

export function chainStoreReportsSettingReducer(
  state = initialState,
  action: ChainStoreSettingAction
): ChainStoreReportsSettingState {
  switch (action.type) {
    case SELECT_CHAIN_STORE_REPORTS_SEARCH_CONDITION:
      return {
        ...state,
        searchCondition: action.payload.params,
      };
    case SELECT_CHAIN_STORE_REPORTS_SHU:
      return {
        ...state,
        selectedShu: action.payload.shus,
      };
    case CHANGE_CHAIN_STORE_REPORTS_FAVORITE:
      return {
        ...state,
        selectedFavoriteId: action.payload.favorite,
      };
    case SELECT_CHAIN_STORE_REPORTS_DATE_RANGE_PARAMS:
      return {
        ...state,
        comparativeSection: action.payload.comparativeSection,
        selectedDateRangeParams: {
          dateRange: action.payload.dateRange,
          dateUnit: action.payload.dateUnit,
          isComparison: action.payload.isComparison,
        },
      };
    case SELECT_CHAIN_STORE_REPORTS_TAB_ID:
      return {
        ...state,
        selectedTabId: action.payload.tabID,
      };
    case RESET_CHAIN_STORE_REPORTS_SEARCH_CONDITION:
      return {
        ...state,
        searchCondition: initialState.searchCondition,
        checkedModelCodes: initialState.checkedModelCodes,
        isKiGraphClosedByUser: initialState.isKiGraphClosedByUser,
      };
    case RESET_CHAIN_STORE_REPORTS_SHU:
      return {
        ...state,
        selectedShu: [],
      };
    case RESET_CHAIN_STORE_REPORTS_DATE_RANGE_PARAMS:
      return {
        ...state,
        selectedDateRangeParams: initialDateRangeParams,
      };
    case SEARCH_CHAIN_STORE_REPORTS:
      return {
        ...state,
        searchCondition: action.payload.params,
        comparativeSection: action.payload.comparativeSection,
      };
    case SEARCH_RESET_CHAIN_STORE_REPORTS:
      return {
        ...state,
        searchCondition: initialState.searchCondition,
        comparativeSection: initialState.comparativeSection,
        checkedModelCodes: initialState.checkedModelCodes,
        isKiGraphClosedByUser: initialState.isKiGraphClosedByUser,
      };
    case ADD_CHAIN_STORE_REPORTS_KI_TABLE_FILTER: {
      if (state.tableFilterItemsKi.length === 0) {
        return {
          ...state,
          tableFilterItemsKi: [
            {
              code: action.payload.code,
              items: [action.payload.columnCode],
            },
          ],
        };
      }

      const result = state.tableFilterItemsKi.map((item) => {
        if (item.code === action.payload.code) {
          return {
            code: item.code,
            items: [...item.items, action.payload.columnCode],
          };
        }
        return item;
      });

      if (result.find((item) => item.code === action.payload.code)) {
        return {
          ...state,
          tableFilterItemsKi: [...result],
        };
      }

      const newItem = {
        code: action.payload.code,
        items: [action.payload.columnCode],
      };

      return {
        ...state,
        tableFilterItemsKi: [...result, newItem],
      };
    }
    case REMOVE_CHAIN_STORE_REPORTS_KI_TABLE_FILTER: {
      const result = state.tableFilterItemsKi.map((item) => {
        if (item.code === action.payload.code) {
          return {
            code: item.code,
            items: item.items.filter(
              (columnCode) => columnCode !== action.payload.columnCode
            ),
          };
        }
        return item;
      });

      return {
        ...state,
        tableFilterItemsKi: [...result],
      };
    }
    case APPLY_CHAIN_STORE_REPORTS_KI_TABLE_FILTER:
      return {
        ...state,
        tableFilterItemsKi: [...action.payload.tableFilterItemsKi],
      };
    case RESET_CHAIN_STORE_REPORTS_KI_TABLE_FILTER: {
      return {
        ...state,
        tableFilterItemsKi: [
          ...state.tableFilterItemsKi.filter(
            (item) => item.code !== action.payload.code
          ),
        ],
      };
    }
    case RESET_ALL_CHAIN_STORE_REPORTS_KI_TABLE_FILTER:
      return {
        ...state,
        tableFilterItemsKi: [],
      };
    case ADD_CHAIN_STORE_REPORTS_SHU_TABLE_FILTER: {
      if (state.tableFilterItemsShu.length === 0) {
        return {
          ...state,
          tableFilterItemsShu: [
            {
              code: action.payload.code,
              items: [action.payload.columnCode],
            },
          ],
        };
      }

      const result = state.tableFilterItemsShu.map((item) => {
        if (item.code === action.payload.code) {
          return {
            code: item.code,
            items: [...item.items, action.payload.columnCode],
          };
        }
        return item;
      });

      if (result.find((item) => item.code === action.payload.code)) {
        return {
          ...state,
          tableFilterItemsShu: [...result],
        };
      }

      const newItem = {
        code: action.payload.code,
        items: [action.payload.columnCode],
      };

      return {
        ...state,
        tableFilterItemsShu: [...result, newItem],
      };
    }
    case REMOVE_CHAIN_STORE_REPORTS_SHU_TABLE_FILTER: {
      const result = state.tableFilterItemsShu.map((item) => {
        if (item.code === action.payload.code) {
          return {
            code: item.code,
            items: item.items.filter(
              (columnCode) => columnCode !== action.payload.columnCode
            ),
          };
        }
        return item;
      });

      return {
        ...state,
        tableFilterItemsShu: [...result],
      };
    }
    case APPLY_CHAIN_STORE_REPORTS_SHU_TABLE_FILTER:
      return {
        ...state,
        tableFilterItemsShu: [...action.payload.tableFilterItemsShu],
      };
    case RESET_CHAIN_STORE_REPORTS_SHU_TABLE_FILTER: {
      return {
        ...state,
        tableFilterItemsShu: [
          ...state.tableFilterItemsShu.filter(
            (item) => item.code !== action.payload.code
          ),
        ],
      };
    }
    case RESET_ALL_CHAIN_STORE_REPORTS_SHU_TABLE_FILTER:
      return {
        ...state,
        tableFilterItemsShu: [],
      };
    case SELECT_SHU_GRAPH_SHOW_GRAPH_NUMBER_LABEL: {
      return {
        ...state,
        showGraphNumberLabel: action.payload.flag,
      };
    }
    case SELECT_SHU_KADO_GRAPH_SHOW_GRAPH_NUMBER_LABEL: {
      return {
        ...state,
        showKadoGraphNumberLabel: action.payload.flag,
      };
    }
    case SELECT_KI_GRAPH_SHOW_GRAPH_NUMBER_LABEL: {
      return {
        ...state,
        showKiGraphNumberLabel: action.payload.flag,
      };
    }
    case SELECT_COMPARATIVE_SECTION:
      return {
        ...state,
        comparativeSection: action.payload.comparativeSection,
      };
    case SELECT_CHAIN_STORE_IS_KI_GRAPH_CLOSED:
      return {
        ...state,
        isKiGraphClosedByUser: {
          ...state.isKiGraphClosedByUser,
          [action.payload.shuCode]: true,
        },
      };
    case SELECT_CHAIN_STORE_REPORTS_CHECK_KI: {
      const { shuCode, modelCode } = action.payload;
      const byUser = state.checkedModelCodes.byUser[shuCode] ?? [];
      const asNewModels = state.checkedModelCodes.asNewModel[shuCode] ?? [];
      const checked = [...byUser, ...asNewModels];
      return {
        ...state,
        checkedModelCodes: {
          ...state.checkedModelCodes,
          asNewModel: {
            ...state.checkedModelCodes.asNewModel,
            [shuCode]: [],
          },
          byUser: {
            ...state.checkedModelCodes.byUser,
            [shuCode]: [...checked, modelCode],
          },
        },
      };
    }
    case SELECT_CHAIN_STORE_REPORTS_UNCHECK_KI: {
      const { shuCode, modelCode } = action.payload;
      const byUser = state.checkedModelCodes.byUser[shuCode] ?? [];
      const asNewModels = state.checkedModelCodes.asNewModel[shuCode] ?? [];
      const checked = [...byUser, ...asNewModels];
      return {
        ...state,
        checkedModelCodes: {
          ...state.checkedModelCodes,
          asNewModel: {
            ...state.checkedModelCodes.asNewModel,
            [shuCode]: [],
          },
          byUser: {
            ...state.checkedModelCodes.byUser,
            [shuCode]: [...checked.filter((x: string) => x !== modelCode)],
          },
        },
      };
    }
    case SELECT_CHAIN_STORE_REPORTS_CHECK_KI_AS_NEW_MODEL_BY_SHUCODE: {
      const { shuCode, modelCodes } = action.payload;
      return {
        ...state,
        checkedModelCodes: {
          ...state.checkedModelCodes,
          asNewModel: {
            ...state.checkedModelCodes.asNewModel,
            [shuCode]: modelCodes,
          },
        },
      };
    }
    case RESET_CHAIN_STORE_REPORTS_CHECKED_KI_LIST_AS_NEW_MODEL_BY_SHU_CODE: {
      const { shuCode } = action.payload;
      return {
        ...state,
        checkedModelCodes: {
          ...state.checkedModelCodes,
          asNewModel: {
            ...state.checkedModelCodes.asNewModel,
            [shuCode]: [],
          },
        },
      };
    }
    case SELECT_CHAIN_STORE_REPORTS_CHECKED_KI_LIST: {
      const { checkedModelCodes } = action.payload;
      return {
        ...state,
        checkedModelCodes,
      };
    }

    case RESET_CHAIN_STORE_REPORTS_IS_GRAPH_CLOSED_BY_USER: {
      return {
        ...state,
        isKiGraphClosedByUser: initialState.isKiGraphClosedByUser,
      };
    }
    case RESET_CHAIN_STORE_REPORTS_CHECKED_KI_LIST: {
      return {
        ...state,
        checkedModelCodes: initialState.checkedModelCodes,
      };
    }
    case SELECT_CHAIN_STORE_REPORTS_IS_GRAPH_CLOSED_BY_USER: {
      return {
        ...state,
        isKiGraphClosedByUser: action.payload.isKiGraphClosedByUser,
      };
    }
    case RESET_CHAIN_STORE_REPORTS_FAVORITE_TO_DEFAULT: {
      return {
        ...state,
        selectedFavoriteId: undefined,
      };
    }
    case SELECT_CHAIN_STORE_REPORTS_CONTAINS_AREA_AVERAGE: {
      return {
        ...state,
        searchCondition: {
          ...state.searchCondition,
          containsAreaAverage: action.payload.containsAreaAverage,
        },
      };
    }
    default:
      return state;
  }
}
