import { AxiosResponse } from 'axios';
import {
  all,
  call,
  fork,
  put,
  select,
  take,
  takeEvery,
} from 'redux-saga/effects';

import { DailyComment } from '../domain/dataHallDailyComments';
import {
  DataHallKi,
  DataHallKiParams,
  GraphCommand,
  shouldAutomaticallyOpenGraph,
} from '../domain/dataHallKi';
import { HallReportsFavorite } from '../domain/hall/types';
import { HallReportsFormConditions } from '../domain/hallReportsFormConditions';
import { HallReportsSettingDateRangeParams } from '../domain/hallReportsSettingDateRangeParams';
import { Column, SearchCondition } from '../domain/schemas';
import { ShuOption } from '../domain/shu';

import {
  DataHallDailyCommentsActionCreators,
  dataHallDailyCommentsByDateSelector,
  dataHallDailyCommentsIsLoadingSelector,
} from '../redux/server/dataHallDailyComments';
import {
  ChangeDataHallKiColumnsOrderAction,
  DataHallKiActionCreators,
  DataHallKiActionTypes,
  DataHallKiState,
  FetchDataHallKiAction,
  FetchDataHallKiSuccessAction,
  InitDataHallKiAction,
  SearchDataHallKiAction,
  SearchDataHallKiDkSisAction,
  SearchDataHallKiDkSisOffAction,
  SearchDataHallKiDkSisOnAction,
  SearchDataHallKiFavoriteAction,
  SearchDataHallKiFieldTypeAction,
  SearchDataHallKiSortAction,
  dataHallKiColumnsOrderSelector,
  dataHallKiDataColumnsSelector,
  dataHallKiDataIsExistSelector,
  dataHallKiDataIsLoadingSelector,
  dataHallKiDataSearchConditionSelector,
  dataHallKiDataSelector,
  dataHallKiOrderedData,
} from '../redux/server/dataHallKi';
import {
  DataHallKiGraphActionCreators,
  dataHallKiGraphSelectedKiListSelector,
  dataHallKiGraphSelector,
  hideDataHallKiGraphAction,
} from '../redux/server/dataHallKiGraph';
import {
  SettingsOptionsHallActionTypes,
  settingsOptionsHallKiFieldCodesSelector,
  settingsOptionsHallSearchConditionSelector,
} from '../redux/server/settingsOptionsHall';
import {
  hallReportsCheckedByUserModelCodeslSelector,
  hallReportsComparativeSectionSelector,
  hallReportsIsKiGraphClosedSelector,
  hallReportsSearchConditionSelector,
  hallReportsSelectedDateRangeParamsSelector,
  hallReportsSelectedFavoriteSettingSelector,
  hallReportsSelectedShuSelector,
  resetCheckedKiListAsNewModelByShuCode,
  selectCheckKiAsNewModelsByShuCode,
} from '../redux/ui/hallReportsSetting';
import { Api, buildConfig } from '../utils/api';
import { ComparativeSection } from '../utils/comparativeSectionUtil';
import { disableSis, enableSis, omitSisFields } from '../utils/dkSis';
import { recalcColumnOrder } from '../utils/orderedCell';
import { getShuGroupOption } from '../utils/shu';
import { handleErrorSaga } from './errorSaga';
import { searchConditionToDateRangeParams } from './hallReportsSettingSaga';

/**
 * 店舗レポート 新台/メイン機種の1つのテーブルのデータを取得する（種別毎に複数回呼ぶ必要あり）
 * @param api AxiosInstance
 * @param action Action
 */
export function* fetchDataHallKiSaga(api: Api, action: FetchDataHallKiAction) {
  try {
    yield put(
      DataHallKiActionCreators.fetchDataHallKiRequestAction(
        action.payload.shuCode
      )
    );

    const response: AxiosResponse<DataHallKi> = yield call(
      api.get,
      '/data/hall/ki',
      buildConfig(action.payload.params)
    );

    // 既にデータがある場合は上書きして登録、なければそのまま登録
    yield put(
      DataHallKiActionCreators.fetchDataHallKiSuccessAction(
        action.payload.shuCode,
        response.data
      )
    );

    if (
      response?.data?.setting?.ymdList &&
      response.data.setting.ymdList.length > 0 &&
      response?.data?.setting?.halls &&
      response.data.setting.halls.length > 0
    ) {
      const date = response.data.setting.ymdList[0];
      const comment: { [hallCode: string]: DailyComment[] } = yield select(
        dataHallDailyCommentsByDateSelector(date)
      );
      const isLoading: boolean = yield select(
        dataHallDailyCommentsIsLoadingSelector(date)
      );
      if (!isLoading && comment == null) {
        yield put(
          DataHallDailyCommentsActionCreators.fetchDataHallDailyCommentsRequestAction(
            date
          )
        );
      }
    }
  } catch (error: unknown) {
    yield put(DataHallKiActionCreators.renewDataHallKiAction());
    yield fork(handleErrorSaga, error);
  }
}

/**
 * 新台があるときグラフを自動で開く
 */
function* automaticallyOpenGraphSaga(action: FetchDataHallKiSuccessAction) {
  const {
    shuCode,
    shu: { data },
  } = action.payload;
  if (!shuCode || !data) {
    return;
  }

  // 検索条件が取得できるまで待つ
  // FIXME: 店舗レポートでは、searchConditionがない状態でデータテーブルのコンポーネントをマウントしているので、チェーン店レポートと同様実装が完了したときに削除する
  const searchCondition: SearchCondition = yield select(
    settingsOptionsHallSearchConditionSelector
  );
  if (
    [...searchCondition.shuList, ...searchCondition.shuGroupList].length === 0
  ) {
    yield take(
      SettingsOptionsHallActionTypes.FETCH_SETTINGS_OPTIONS_HALL_SUCCESS
    );
  }
  const shuOptions: ShuOption[] = getShuGroupOption(
    yield select(settingsOptionsHallSearchConditionSelector)
  );

  const shuGroup = shuOptions.find((x) => x.code === shuCode);
  if (!shuGroup) {
    return;
  }

  const isGraphOpened: boolean = yield select(
    dataHallKiGraphSelector(shuGroup)
  );

  const isGraphClosedByUser: boolean = yield select(
    hallReportsIsKiGraphClosedSelector,
    shuGroup.code
  );

  const checkedByUserModelCodes: string[] = yield select(
    hallReportsCheckedByUserModelCodeslSelector,
    shuGroup.code
  );

  const selectedModelsOnGraph: string[] = yield select(
    dataHallKiGraphSelectedKiListSelector(shuGroup)
  );

  const { command, checkedModelCodes } = shouldAutomaticallyOpenGraph(
    isGraphClosedByUser,
    isGraphOpened,
    checkedByUserModelCodes,
    data.rows
  );

  switch (command) {
    case GraphCommand.DO_NOTHING: {
      return;
    }
    case GraphCommand.OPEN_GRAPH_BY_USER: {
      // グラフ表示
      yield put(
        DataHallKiGraphActionCreators.searchDataHallKiGraph(
          shuGroup,
          checkedModelCodes
        )
      );
      return;
    }
    case GraphCommand.OPEN_GRAPH_AS_NEW: {
      // 新台にチェック
      yield put(
        selectCheckKiAsNewModelsByShuCode(shuGroup.code, checkedModelCodes)
      );
      // グラフ表示
      yield put(
        DataHallKiGraphActionCreators.searchDataHallKiGraph(
          shuGroup,
          checkedModelCodes
        )
      );
      if (!selectedModelsOnGraph || selectedModelsOnGraph.length === 0) {
        // 全ての新台を選択状態にする
        yield put(
          DataHallKiGraphActionCreators.changeDataHallKiGraphKi(
            shuGroup.code,
            checkedModelCodes
          )
        );
      }
      return;
    }
    case GraphCommand.RESET_NEW_MODELS_AND_CLOSE_GRAPH: {
      // グラフを閉じる
      yield put(hideDataHallKiGraphAction(shuGroup.code));
      // 新台のチェックを外す
      yield put(resetCheckedKiListAsNewModelByShuCode(shuGroup.code));
      return;
    }
  }
}

/**
 * 表示項目が変更されている場合並び替えに反映する
 */
function* columnsOrderCheckSaga(action: FetchDataHallKiSuccessAction) {
  const { shuCode } = action.payload;

  const fields: Column[] = yield select(dataHallKiDataColumnsSelector(shuCode));

  // テーブル列の並び順
  let columnsOrder: string[] = yield select(
    dataHallKiColumnsOrderSelector(shuCode)
  );

  if (!columnsOrder || columnsOrder.length === 0) {
    // ソート順が設定されていない場合、初期配置をデフォルトで設定する
    columnsOrder = fields.map((column) => column.code);
  }

  // MEMO: DK-SIS 項目の存在チェック
  const existSis = columnsOrder.join('_').indexOf('_sis');

  const sorted = [...fields]
    .sort((a, b) => {
      // 区分/機種名/タイプは常に先頭
      if (b.code === 'kbn' || b.code === 'kiTushoMei' || b.code === 'type') {
        return 1;
      }
      // DK-SIS項目を新規で追加する場合は強制で末尾に配置する
      if (existSis === -1 && (b.isSisField || a.isSisField)) {
        return 1;
      }
      return columnsOrder.indexOf(b.code) > columnsOrder.indexOf(a.code)
        ? -1
        : 1;
    })
    .map((column) => column.code);

  yield put(
    DataHallKiActionCreators.selectDataHallKiColumnsOrderAction(shuCode, sorted)
  );
}

/**
 * 現在の検索フォームのデータで初回取得する
 * @param action Action
 */
function* initDataHallKiSaga(action: InitDataHallKiAction) {
  const shuOption = action.payload.shu;

  // ローディング状態
  const isLoading: boolean = yield select(
    dataHallKiDataIsLoadingSelector(shuOption)
  );

  // データが存在する
  const isExist: boolean = yield select(
    dataHallKiDataIsExistSelector(shuOption)
  );

  // データが存在する・ローディング中・エラー時の時は初回取得を行わない
  if (isExist || isLoading) return;

  // 現在の検索フォームの検索条件
  const searchFormCondition: HallReportsFormConditions = yield select(
    hallReportsSearchConditionSelector
  );

  const comparativeSection: ComparativeSection = yield select(
    hallReportsComparativeSectionSelector
  );

  // 現在選択中のお気に入りの店舗レポートの各種データ
  const hallReportsFavorite: HallReportsFavorite = yield select(
    hallReportsSelectedFavoriteSettingSelector
  );
  const fieldCodes: string[] = yield select(
    settingsOptionsHallKiFieldCodesSelector
  );

  // 現在の期間
  const formDateRangeParams: HallReportsSettingDateRangeParams = yield select(
    hallReportsSelectedDateRangeParamsSelector
  );
  const dateRangeParams = !hallReportsFavorite?.dataHallKi?.setting[
    shuOption.code
  ]
    ? formDateRangeParams
    : hallReportsFavorite.selectedDateRangeParams;

  // ymdList対応以前に作成したお気に入りを考慮し、不要なパラメータを除外する
  const {
    startDate,
    endDate,
    startComparisonDate,
    endComparisonDate,
    ...rest
  } = hallReportsFavorite?.dataHallKi?.setting[shuOption.code] ?? {};

  // お気に入りがあればお気に入りの検索条件を適用し、なければ検索フォームの検索条件を適用する
  // MEMO: 新台/メイン機種テーブルのお気に入り適用はIntersectionObserverでIntersectingしたときに取得する仕様のため、ここでお気に入り適用している
  const searchCondition: DataHallKiParams = !hallReportsFavorite?.dataHallKi
    ?.setting[shuOption.code]
    ? {
        ...searchFormCondition,
        ...searchConditionToDateRangeParams(
          dateRangeParams.dateRange,
          dateRangeParams.dateUnit,
          dateRangeParams.isComparison,
          searchFormCondition,
          comparativeSection
        ),
        // 初回取得時はデフォルトでDK-SISを有効にするため、sisFieldTypesのみ設定
        // 他のsis系パラメータは後続の処理で追加される
        sisFieldTypes: ['result'],
      }
    : {
        ...rest,
        // MEMO:
        // お気に入りでfieldsにsis系の値を含む場合があるのですべて除外する
        fields: omitSisFields(
          hallReportsFavorite.dataHallKi.setting[shuOption.code].fields,
          fieldCodes
        ),
        ...searchConditionToDateRangeParams(
          dateRangeParams.dateRange,
          dateRangeParams.dateUnit,
          dateRangeParams.isComparison,
          hallReportsFavorite.dataHallKi.setting[shuOption.code],
          hallReportsFavorite.comparativeSection
        ),
      };

  yield put(
    DataHallKiActionCreators.fetchDataHallKiAction(shuOption.code, {
      ...searchCondition,
      shuGroupIds: shuOption.type === 'shuGroup' ? [shuOption.code] : [],
      shuList: shuOption.type === 'shuList' ? [shuOption.code] : [],
      ...(searchCondition.sisFieldTypes != null
        ? enableSis(searchCondition.ymdList, dateRangeParams.dateUnit)
        : disableSis(searchCondition.fields, fieldCodes)),
    })
  );
}

/**
 * 検索ボタン押下時に行う処理
 * @param action Action
 */
function* searchDataHallKiSaga(action: SearchDataHallKiAction) {
  // 現在のテーブルデータ全て取得する
  const dataHallKi: DataHallKiState['shus'] = yield select(
    dataHallKiDataSelector
  );

  // 検索フォームで選択中の種別を取得する
  const selectedShu: ShuOption[] = yield select(hallReportsSelectedShuSelector);

  // 現在の期間
  const dateRangeParams: HallReportsSettingDateRangeParams = yield select(
    hallReportsSelectedDateRangeParamsSelector
  );

  const fieldCodes: string[] = yield select(
    settingsOptionsHallKiFieldCodesSelector
  );

  // データがあるテーブルのみ検索条件を更新する
  // MEMO: 前回のテーブル固有の検索条件（ソート・表示項目）を引き継ぐためこのようにしている
  // リセットと同じ挙動をすると検索条件を引き継げないため
  const fetchList = Object.keys(dataHallKi)
    .filter((key) => !!dataHallKi[key])
    .map((key) => {
      const data = dataHallKi[key];
      // 全選択の場合または選択している種別に含まれる場合はデータを再取得する
      if (
        (!selectedShu.length || selectedShu.some((shu) => shu.code === key)) &&
        data
      ) {
        return put(
          DataHallKiActionCreators.fetchDataHallKiAction(key, {
            ...data.setting,
            fields: omitSisFields(data.setting.fields, fieldCodes),
            // DK-SISの指定がある場合は再計算した期間を適用する
            ...(data.setting.sisFieldTypes != null
              ? enableSis(
                  action.payload.params.ymdList,
                  dateRangeParams.dateUnit
                )
              : disableSis(action.payload.params.fields, fieldCodes)),
            halls: action.payload.params.halls,
            ymdList: action.payload.params.ymdList,
            ymdComparisonList: action.payload.params.ymdComparisonList,
            excludeToday: action.payload.params.excludeToday,
          })
        );
      }

      // 検索フォーム変更後に種別が含まれない場合はデータを削除する
      return put(DataHallKiActionCreators.clearDataHallKiAction(key));
    });

  // fetch時に再取得したいため、コメントがあれば削除
  const date = action.payload.params.ymdList?.at(0);
  if (date) {
    yield put(
      DataHallDailyCommentsActionCreators.clearDataHallDailyCommentsAction(date)
    );
  }

  // データ取得
  yield all(fetchList);
}

/**
 * 指定した表示項目の新台/メイン機種テーブルデータを取得する
 * @param action Action
 */
function* searchDataHallKiFieldTypeSaga(
  action: SearchDataHallKiFieldTypeAction
) {
  // 現在の検索条件
  const searchCondition: DataHallKiParams = yield select(
    dataHallKiDataSearchConditionSelector(action.payload.shu)
  );

  // 新台/メイン機種テーブルを再取得
  yield put(
    DataHallKiActionCreators.fetchDataHallKiAction(action.payload.shu.code, {
      ...searchCondition,
      fields: action.payload.fields.map((field) => field.code),
    })
  );
}

/**
 * 指定したソート条件の新台/メイン機種テーブルデータを取得する
 * @param action Action
 */
function* searchDataHallKiSortSaga(action: SearchDataHallKiSortAction) {
  // 現在の検索条件
  const searchCondition: DataHallKiParams = yield select(
    dataHallKiDataSearchConditionSelector(action.payload.shu)
  );

  const fieldCodes: string[] = yield select(
    settingsOptionsHallKiFieldCodesSelector
  );

  // 新台/メイン機種テーブルを再取得
  yield put(
    DataHallKiActionCreators.fetchDataHallKiAction(action.payload.shu.code, {
      ...searchCondition,
      fields: omitSisFields(searchCondition.fields, fieldCodes),
      sort: action.payload.sort,
      order: action.payload.order,
    })
  );
}

/**
 * ドラッグ＆ドロップしたセルのIDを元に並び替え情報を登録する
 */
function* changeDataHallKiColumnsOrderSaga(
  action: ChangeDataHallKiColumnsOrderAction
) {
  const tableData: DataHallKi['data'] = yield select(
    dataHallKiOrderedData(action.payload.shuCode)
  );

  const ordered = recalcColumnOrder(
    tableData?.columns || [],
    action.payload.draggedId,
    action.payload.droppedId
  );

  yield put(
    DataHallKiActionCreators.selectDataHallKiColumnsOrderAction(
      action.payload.shuCode,
      ordered
    )
  );
}

/**
 * DK-SISを有効にしてデータを取得する
 */
function* searchDataHallKiDkSisOnSaga(action: SearchDataHallKiDkSisOnAction) {
  const { shu } = action.payload;
  const shuCode = shu.code;

  // 現在の検索条件
  const searchCondition: DataHallKiParams = yield select(
    dataHallKiDataSearchConditionSelector(shu)
  );

  const dateRangeParams: HallReportsSettingDateRangeParams = yield select(
    hallReportsSelectedDateRangeParamsSelector
  );

  yield put(
    DataHallKiActionCreators.fetchDataHallKiAction(shuCode, {
      ...searchCondition,
      ...enableSis(searchCondition.ymdList, dateRangeParams.dateUnit),
    })
  );
}

/**
 * DK-SISを無効にしてデータを取得する
 */
function* searchDataHallKiDkSisOffSaga(action: SearchDataHallKiDkSisOffAction) {
  const { shu } = action.payload;
  const shuCode = shu.code;

  // 現在の検索条件
  const searchCondition: DataHallKiParams = yield select(
    dataHallKiDataSearchConditionSelector(shu)
  );

  const fieldCodes: string[] = yield select(
    settingsOptionsHallKiFieldCodesSelector
  );

  // 全てのDK-SISの検索条件を取り除く
  yield put(
    DataHallKiActionCreators.fetchDataHallKiAction(shuCode, {
      ...searchCondition,
      ...disableSis(searchCondition.fields, fieldCodes),
    })
  );
}

/**
 * フォームで指定したDK-SISの検索条件でデータを取得する
 */
function* searchDataHallKiDkSisSaga(action: SearchDataHallKiDkSisAction) {
  const { shu, params } = action.payload;
  const shuCode = shu.code;

  // DK-SISの検索条件でデータを取得する
  // 現在の検索条件
  const searchCondition: DataHallKiParams = yield select(
    dataHallKiDataSearchConditionSelector(shu)
  );

  // 全てのDK-SISの検索条件を取り除く
  yield put(
    DataHallKiActionCreators.fetchDataHallKiAction(shuCode, {
      ...searchCondition,
      ...params,
    })
  );
}

/**
 *  新台/メイン機種 お気に入り用
 */
function* searchDataHallKiFavoriteSaga(action: SearchDataHallKiFavoriteAction) {
  // // 検索条件項目を取得
  const settingsOptions: SearchCondition = yield select(
    settingsOptionsHallSearchConditionSelector
  );

  // TODO: [店舗レポート] 画面共有機能の改善 https://app.zenhub.com/workspaces/claris-60b56db504e3ff00150ebc22/issues/gh/dkclaris/claris-general/4310
  // 上記issueで存在しない種別を指定した場合の処理を追加するので追加後action.payload.favorite.selectedShuをselectedShu(検索フォームで選択中の種別)を参照する様に変更する必要がある
  // ShuOptionsを取得
  const shuOptions =
    action.payload.favorite.selectedShu.length > 0
      ? action.payload.favorite.selectedShu
      : getShuGroupOption(settingsOptions);

  // お気に入り条件でテーブルデータを取得
  const forkEffects = shuOptions.map((shuOption) => {
    return fork(
      favoriteFetchDataHallKiFavoriteSaga,
      shuOption,
      action.payload.favorite
    );
  });

  yield all(forkEffects);
}

/**
 *  新台/メイン機種 お気に入り条件でのテーブルデータ取得
 */
function* favoriteFetchDataHallKiFavoriteSaga(
  shuOption: ShuOption,
  favorite: HallReportsFavorite
) {
  // 現在の検索フォームの検索条件
  const searchFormCondition: HallReportsFormConditions = yield select(
    hallReportsSearchConditionSelector
  );

  const fieldCodes: string[] = yield select(
    settingsOptionsHallKiFieldCodesSelector
  );

  // 現在の期間
  const formDateRangeParams: HallReportsSettingDateRangeParams = yield select(
    hallReportsSelectedDateRangeParamsSelector
  );
  const dateRangeParams = !favorite?.dataHallKi?.setting[shuOption.code]
    ? formDateRangeParams
    : favorite.selectedDateRangeParams;

  // ymdList対応以前に作成したお気に入りを考慮し、不要なパラメータを除外する
  const {
    startDate,
    endDate,
    startComparisonDate,
    endComparisonDate,
    ...rest
  } = favorite?.dataHallKi?.setting[shuOption.code] ?? {};

  // お気に入りがあればお気に入りの検索条件を適用し、なければ検索フォームの検索条件を適用する
  // MEMO: 新台/メイン機種テーブルのお気に入り適用はIntersectionObserverでIntersectingしたときに取得する仕様のため、ここでお気に入り適用している
  const searchCondition: DataHallKiParams = !favorite?.dataHallKi?.setting[
    shuOption.code
  ]
    ? {
        ...searchFormCondition,
        ...searchConditionToDateRangeParams(
          dateRangeParams.dateRange,
          dateRangeParams.dateUnit,
          dateRangeParams.isComparison,
          searchFormCondition
        ),
        // 初回取得時はデフォルトでDK-SISを有効にするため、sisFieldTypesのみ設定
        // 他のsis系パラメータは後続の処理で追加される
        sisFieldTypes: ['result'],
      }
    : {
        ...rest,
        // MEMO:
        // お気に入りでfieldsにsis系の値を含む場合があるのですべて除外する
        fields: omitSisFields(
          favorite.dataHallKi.setting[shuOption.code].fields,
          fieldCodes
        ),
        ...searchConditionToDateRangeParams(
          dateRangeParams.dateRange,
          dateRangeParams.dateUnit,
          dateRangeParams.isComparison,
          favorite.dataHallKi.setting[shuOption.code],
          favorite.comparativeSection
        ),
      };

  yield put(
    DataHallKiActionCreators.fetchDataHallKiAction(shuOption.code, {
      ...searchCondition,
      shuGroupIds: shuOption.type === 'shuGroup' ? [shuOption.code] : [],
      shuList: shuOption.type === 'shuList' ? [shuOption.code] : [],
      ...(searchCondition.sisFieldTypes != null
        ? enableSis(searchCondition.ymdList, dateRangeParams.dateUnit)
        : disableSis(searchCondition.fields, fieldCodes)),
    })
  );
}

/**
 * FETCH_DATA_HALL_KIがDispatchされた時にfetchDataHallKiSagaを実行する
 * @param api AxiosInstance
 */
function* handleFetchDataHallKiSaga(api: Api) {
  yield takeEvery(
    DataHallKiActionTypes.FETCH_DATA_HALL_KI,
    fetchDataHallKiSaga,
    api
  );
  // 表示項目と並び替えのチェック
  yield takeEvery(
    DataHallKiActionTypes.FETCH_DATA_HALL_KI_SUCCESS,
    columnsOrderCheckSaga
  );
}

/**
 * INIT_DATA_HALL_KIがDispatchされた時の処理
 */
function* handleInitDataHallKiSaga() {
  yield takeEvery(DataHallKiActionTypes.INIT_DATA_HALL_KI, initDataHallKiSaga);
}

/**
 * SEARCH_DATA_HALL_KIがDispatchされた時の処理
 */
function* handleSearchDataHallKiSaga() {
  yield takeEvery(
    DataHallKiActionTypes.SEARCH_DATA_HALL_KI,
    searchDataHallKiSaga
  );
}

/**
 * SEARCH_DATA_HALL_KI_FIELD_TYPEがDispatchされた時の処理
 */
function* handleSearchDataHallKiFieldTypeSaga() {
  yield takeEvery(
    DataHallKiActionTypes.SEARCH_DATA_HALL_KI_FIELD_TYPE,
    searchDataHallKiFieldTypeSaga
  );
}

/**
 * SEARCH_DATA_HALL_KI_SORTがDispatchされた時の処理
 */
function* handleSearchDataHallKiSortSaga() {
  yield takeEvery(
    DataHallKiActionTypes.SEARCH_DATA_HALL_KI_SORT,
    searchDataHallKiSortSaga
  );
}

/**
 * CHANGE_DATA_HALL_KI_COLUMNS_ORDERがDispatchされた時の処理
 */
function* handleChangeDataHallKiColumnsOrderSaga() {
  yield takeEvery(
    DataHallKiActionTypes.CHANGE_DATA_HALL_KI_COLUMNS_ORDER,
    changeDataHallKiColumnsOrderSaga
  );
  // DK-SISを有効
  yield takeEvery(
    DataHallKiActionTypes.SEARCH_DATA_HALL_KI_DKSIS_ON,
    searchDataHallKiDkSisOnSaga
  );
  // DK-SISを無効
  yield takeEvery(
    DataHallKiActionTypes.SEARCH_DATA_HALL_KI_DKSIS_OFF,
    searchDataHallKiDkSisOffSaga
  );
  // DK-SISの検索条件を変更
  yield takeEvery(
    DataHallKiActionTypes.SEARCH_DATA_HALL_KI_DKSIS,
    searchDataHallKiDkSisSaga
  );

  // グラフデータを自動で取得
  yield takeEvery(
    DataHallKiActionTypes.FETCH_DATA_HALL_KI_SUCCESS,
    automaticallyOpenGraphSaga
  );
}

/**
 * お気に入り適用時のSaga
 */
function* handleSearchSaga() {
  yield takeEvery(
    DataHallKiActionTypes.SEARCH_DATA_HALL_KI_FAVORITE,
    searchDataHallKiFavoriteSaga
  );
}

/**
 * 店舗レポート 新台/メイン機種に関するタスクを実行する
 * @param context AxiosInstance
 */
export function* dataHallKiSaga(context: { api: Api }) {
  yield fork(handleFetchDataHallKiSaga, context.api);
  yield fork(handleInitDataHallKiSaga);
  yield fork(handleSearchDataHallKiSaga);
  yield fork(handleSearchDataHallKiFieldTypeSaga);
  yield fork(handleSearchDataHallKiSortSaga);
  yield fork(handleChangeDataHallKiColumnsOrderSaga);
  yield fork(handleSearchSaga);
}
