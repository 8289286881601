import isSameDay from 'date-fns/isSameDay';
import isWeekend from 'date-fns/isWeekend';

/**
 * Dateの配列から平日を除外する
 */
export const excludeWeekday = (dates: Date[], holidays: string[]) => {
  // 平日を除外
  const nonWeekdays = dates.filter(
    (date) =>
      isWeekend(date) ||
      holidays.some((holiday) => isSameDay(date, new Date(holiday)))
  );

  return nonWeekdays;
};
