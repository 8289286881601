import { createSelector } from 'reselect';

import {
  DataKiKasidama,
  DataKiKasidamaParams,
} from '../../domain/dataKiKasidama';
import { DkSisSearchParams } from '../../domain/dkSis';
import { MarkingOption } from '../../domain/marking';
import {
  MAIN_FIELD_TYPE,
  Option,
  OrderType,
  QueryParameter,
  ShuGroupList,
} from '../../domain/schemas';

import { RootState } from '../../store';
import {
  convertShuGroupToShuOption,
  convertShuToShuOption,
} from '../../utils/shu';
import { ModelReportsSettingState } from '../ui/modelReportsSetting';
import {
  dataKiSelectOptionsSelector,
  dataKiSettingMainFieldSelector,
} from './dataKi';
import { settingsOptionsKiSearchConditionSelector } from './settingsOptionsKi';

// Action Types

const SEARCH_DATA_KI_KASIDAMA = 'SEARCH_DATA_KI_KASIDAMA' as const;
const SEARCH_DATA_KI_KASIDAMA_KI = 'SEARCH_DATA_KI_KASIDAMA_KI' as const;
const SEARCH_DATA_KI_KASIDAMA_SORT = 'SEARCH_DATA_KI_KASIDAMA_SORT' as const;
const SEARCH_DATA_KI_KASIDAMA_MARKING = 'SEARCH_DATA_KI_KASIDAMA_MARKING' as const;
const SEARCH_DATA_KI_KASIDAMA_FIELD = 'SEARCH_DATA_KI_KASIDAMA_FIELD' as const;
const SEARCH_DATA_KI_KASIDAMA_KI_SHU = 'SEARCH_DATA_KI_KASIDAMA_KI_SHU' as const;

const FETCH_DATA_KI_KASIDAMA = 'FETCH_DATA_KI_KASIDAMA' as const;
const FETCH_DATA_KI_KASIDAMA_REQUEST = 'FETCH_DATA_KI_KASIDAMA_REQUEST' as const;
const FETCH_DATA_KI_KASIDAMA_SUCCESS = 'FETCH_DATA_KI_KASIDAMA_SUCCESS' as const;

const HIDE_DATA_KI_KASIDAMA = 'HIDE_DATA_KI_KASIDAMA' as const;
const RENEW_DATA_KI_KASIDAMA = 'RENEW_DATA_KI_KASIDAMA' as const;

const CHANGE_DATA_KI_KASIDAMA_DK_SIS_SEARCH_PARAMS = 'CHANGE_DATA_KI_KASIDAMA_DK_SIS_SEARCH_PARAMS' as const;

export const DataKiKasidamaActionTypes = {
  SEARCH_DATA_KI_KASIDAMA,
  SEARCH_DATA_KI_KASIDAMA_KI,
  SEARCH_DATA_KI_KASIDAMA_SORT,
  SEARCH_DATA_KI_KASIDAMA_MARKING,
  SEARCH_DATA_KI_KASIDAMA_FIELD,
  FETCH_DATA_KI_KASIDAMA,
  FETCH_DATA_KI_KASIDAMA_REQUEST,
  FETCH_DATA_KI_KASIDAMA_SUCCESS,
  RENEW_DATA_KI_KASIDAMA,
  HIDE_DATA_KI_KASIDAMA,
  SEARCH_DATA_KI_KASIDAMA_KI_SHU,
  CHANGE_DATA_KI_KASIDAMA_DK_SIS_SEARCH_PARAMS,
};

// Action Creators

/**
 * クリックしたセルの貸玉データを取得する
 * @param mainField 機種コード/メーカーコード/SISタイプのいずれかのQueryParameter
 * @param hall 店舗コードのQueryParameter
 */
function searchDataKiKasidamaAction(
  mainField: QueryParameter,
  dkSisSearchParams: DkSisSearchParams,
  hall?: QueryParameter,
  requestParams?: {
    hallId: string;
    mainFieldId: string;
  },
  dataKi1stRow?: QueryParameter
) {
  return {
    type: SEARCH_DATA_KI_KASIDAMA,
    payload: {
      mainField,
      dkSisSearchParams,
      hall,
      requestParams,
      dataKi1stRow,
    },
  };
}

/**
 * 選択した機種の貸玉データを取得する（種別選択フォーム）
 * @param mainField 機種コード/メーカーコード/SISタイプのいずれかのQueryParameter
 */
function searchDataKiKasidamaKiAction(mainField: QueryParameter) {
  return {
    type: SEARCH_DATA_KI_KASIDAMA_KI,
    payload: { mainField },
  };
}

/**
 * 選択した種別の貸玉データを取得する（チェーン店一覧の種別セレクトボックス）
 *
 */
function searchDataKiKasidamaKiShuAction(
  shuList?: string[],
  shuGroup?: string[]
) {
  return {
    type: SEARCH_DATA_KI_KASIDAMA_KI_SHU,
    payload: { shuList, shuGroup },
  };
}

/**
 * 指定したソート条件で貸玉データを再取得する
 * @param sort ソートする条件
 * @param order ソート順
 */
function searchDataKiKasidamaSortAction(sort: string, order: OrderType) {
  return {
    type: SEARCH_DATA_KI_KASIDAMA_SORT,
    payload: { sort, order },
  };
}

/**
 * 指定したマーキング条件で貸玉データを再取得する
 * @param markingOption マーキング項目
 * @param isFiltering 該当行のみ表示するか
 */
function searchDataKiKasidamaMarkingAction(
  markingOption: MarkingOption,
  isFiltering: boolean
) {
  return {
    type: SEARCH_DATA_KI_KASIDAMA_MARKING,
    payload: { markingOption, isFiltering },
  };
}

/**
 * 指定した表示項目の貸玉データを再取得する
 * @param fields 表示項目
 */
function searchDataKiKasidamaFieldAction(fields: Option[]) {
  return {
    type: SEARCH_DATA_KI_KASIDAMA_FIELD,
    payload: { fields },
  };
}

/**
 * 貸玉データを取得する
 * @param params 検索条件
 */
export function fetchDataKiKasidamaAction(
  params: DataKiKasidamaParams,
  selectedDataKiExpandedRow?: ModelReportsSettingState['selectedDataKiExpandedRow']
) {
  return {
    type: FETCH_DATA_KI_KASIDAMA,
    payload: { params, selectedDataKiExpandedRow },
  };
}

/**
 * 貸玉データ取得開始時に実行
 */
function fetchDataKiKasidamaRequestAction() {
  return {
    type: FETCH_DATA_KI_KASIDAMA_REQUEST,
  };
}

/**
 * 貸玉データ取得成功時に実行
 * @param dataKiKasidama 取得した貸玉データ
 */
function fetchDataKiKasidamaSuccessAction(dataKiKasidama: DataKiKasidama) {
  return {
    type: FETCH_DATA_KI_KASIDAMA_SUCCESS,
    payload: { dataKiKasidama },
  };
}

function renewDataKiKasidamaAction() {
  return {
    type: RENEW_DATA_KI_KASIDAMA,
  };
}

/**
 * 貸玉データを閉じる
 */
function hideDataKiKasidama() {
  return {
    type: HIDE_DATA_KI_KASIDAMA,
  };
}

// DK-SIS検索条件を変更
function changeDataKiKasidamaDkSisSearchParamsAction(
  params: DkSisSearchParams
) {
  return {
    type: CHANGE_DATA_KI_KASIDAMA_DK_SIS_SEARCH_PARAMS,
    payload: { params },
  };
}

export const DataKiKasidamaActionCreators = {
  searchDataKiKasidamaAction,
  searchDataKiKasidamaKiAction,
  searchDataKiKasidamaSortAction,
  searchDataKiKasidamaMarkingAction,
  searchDataKiKasidamaFieldAction,
  fetchDataKiKasidamaAction,
  fetchDataKiKasidamaRequestAction,
  fetchDataKiKasidamaSuccessAction,
  renewDataKiKasidamaAction,
  hideDataKiKasidama,
  searchDataKiKasidamaKiShuAction,
  changeDataKiKasidamaDkSisSearchParamsAction,
};

// Actions

export type SearchDataKiKasidamaKiShuAction = ReturnType<
  typeof searchDataKiKasidamaKiShuAction
>;

export type SearchDataKiKasidamaAction = ReturnType<
  typeof searchDataKiKasidamaAction
>;
export type SearchDataKiKasidamaKiAction = ReturnType<
  typeof searchDataKiKasidamaKiAction
>;
export type SearchDataKiKasidamaSortAction = ReturnType<
  typeof searchDataKiKasidamaSortAction
>;
export type SearchDataKiKasidamaMarkingAction = ReturnType<
  typeof searchDataKiKasidamaMarkingAction
>;
export type SearchDataKiKasidamaFieldAction = ReturnType<
  typeof searchDataKiKasidamaFieldAction
>;
export type FetchDataKiKasidamaAction = ReturnType<
  typeof fetchDataKiKasidamaAction
>;
type HideDataKiKasidama = ReturnType<typeof hideDataKiKasidama>;

export type ChangeDataKiKasidamaDkSisSearchParamsAction = ReturnType<
  typeof changeDataKiKasidamaDkSisSearchParamsAction
>;

type DataKiKasidamaAction =
  | SearchDataKiKasidamaAction
  | SearchDataKiKasidamaSortAction
  | SearchDataKiKasidamaMarkingAction
  | SearchDataKiKasidamaFieldAction
  | FetchDataKiKasidamaAction
  | HideDataKiKasidama
  | ReturnType<typeof fetchDataKiKasidamaRequestAction>
  | ReturnType<typeof fetchDataKiKasidamaSuccessAction>
  | ReturnType<typeof renewDataKiKasidamaAction>
  | SearchDataKiKasidamaKiShuAction
  | ChangeDataKiKasidamaDkSisSearchParamsAction;

// State

type DataKiKasidamaState = {
  dataKiKasidama?: DataKiKasidama;
  isLoading: boolean;
};

export const initialState: DataKiKasidamaState = {
  dataKiKasidama: undefined,
  isLoading: false,
};

//種別セレクタ用
export const shuDefault = {
  code: '',
  name: '種別全体',
  group: '',
  type: 'none', // shu.tsのselectShu2SearchConditionで種別未指定として扱われるように'none'を設定しておく
};
//種別セレクタ用
const shuGroupDefault = {
  id: '',
  name: '選択なし',
  shuList: [''],
};

// Selector

/**
 * 貸玉データすべてを取得する
 * @returns 貸玉データ（未取得の場合undefined）
 */
const dataKiKasidamaSelector = (state: RootState) =>
  state.dataKiKasidama.dataKiKasidama;

/**
 * 貸玉データのローディング状態を取得する
 * @returns ローディング状態（ローディング中の場合true）
 */
export const dataKiKasidamaLoadingSelector = (state: RootState) =>
  state.dataKiKasidama.isLoading;

/**
 * 貸玉データの現在の検索条件
 * @returns 検索条件
 */
export const dataKiKasidamaParamsSelector = (state: RootState) =>
  state.dataKiKasidama.dataKiKasidama?.setting;

/**
 * 貸玉データのテーブルデータを取得
 * @returns テーブルデータ
 */
export const dataKiKasidamaTableDataSelector = createSelector(
  dataKiKasidamaSelector,
  (dataKiKasidama) => {
    const tableData = dataKiKasidama?.data;

    if (tableData === undefined)
      return {
        columns: [],
        rows: [],
      };

    return tableData;
  }
);

/**
 * 機種集計の貸玉テーブルデータのカラムのみ取得
 * @returns テーブルデータのカラム
 */
export const dataKiKasidamaTableDataColumnsSelector = createSelector(
  dataKiKasidamaTableDataSelector,
  (data) => {
    return data.columns;
  }
);

/**
 * 貸玉データのデータが存在するか取得
 * @returns 存在するか（存在する場合true）
 */
export const dataKiKasidamaIsExistSelector = createSelector(
  dataKiKasidamaSelector,
  (dataKiKasidama) => dataKiKasidama?.data !== undefined
);

/**
 * 現在の並び替えキーを取得する
 * @returns 並び替えキー（空の場合はデフォルトの並び替えキー）
 */
export const dataKiKasidamaParamsSortSelector = createSelector(
  dataKiKasidamaParamsSelector,
  (params) => {
    const sort = params?.sort;
    return sort ? sort : 'daiBan';
  }
);

/**
 * 現在の並び方を取得する
 * @returns 並び方（空の場合はデフォルトの降順）
 */
export const dataKiKasidamaParamsOrderSelector = createSelector(
  dataKiKasidamaParamsSelector,
  (params) => {
    const order = params?.order;
    return order ? order : ('desc' as const);
  }
);

/**
 * 現在選択されている種別を取得する
 * @returns 現在選択されている種別（undefined時未選択）
 */
export const dataKiKasidamaSelectedShuSelector = createSelector(
  dataKiKasidamaParamsSelector,
  settingsOptionsKiSearchConditionSelector,
  (params, searchCondition) => {
    //各種別
    if (params?.shuList !== undefined && params?.shuGroupIds === undefined) {
      const shuLists = params?.shuList[0];
      const shuSelectLists = convertShuToShuOption(shuLists);
      return shuSelectLists;
    }
    //種別グループ
    else if (
      params?.shuGroupIds !== undefined &&
      params?.shuList === undefined
    ) {
      const searchConditioShuGroup = searchCondition.shuGroupList;
      const paramsShuSelect = params?.shuGroupIds[0];

      // paramsと一致するshuGroupListを探索
      const convertparamsShuSelect = (shuGroup: ShuGroupList) => {
        return shuGroup.id === paramsShuSelect;
      };
      const result =
        searchConditioShuGroup.find(convertparamsShuSelect) ?? shuGroupDefault;

      //型を合わせる
      const selectGroup = convertShuGroupToShuOption(result);

      const shuSelectGroup = selectGroup;

      return shuSelectGroup;
    }
    //種別全体
    else if (
      params?.shuGroupIds === undefined &&
      params?.shuList === undefined
    ) {
      const shuSelectAll = shuDefault;
      return shuSelectAll;
    }
  }
);

/**
 * 現在選択されている機種を取得する
 * @returns 選択されている機種（空の場合はundefined）
 */
export const dataKiKasidamaParamsSelectedItemSelector = createSelector(
  [
    dataKiKasidamaParamsSelector,
    dataKiSelectOptionsSelector,
    dataKiSettingMainFieldSelector,
  ],
  (params, options, mainField) => {
    switch (mainField) {
      case MAIN_FIELD_TYPE.KI_TUSHO_MEI: {
        const kiList = params?.kiList;
        return kiList
          ? options.find((option) => option.value === kiList[0])
          : undefined;
      }
      case MAIN_FIELD_TYPE.TYPE: {
        const sisTypes = params?.sisTypes;
        return sisTypes
          ? options.find((option) => option.value === sisTypes[0])
          : undefined;
      }
      case MAIN_FIELD_TYPE.MAKER: {
        const makers = params?.makers;
        return makers
          ? options.find((option) => option.value === makers[0])
          : undefined;
      }
    }
  }
);

/**
 * 現在選択中の表示項目を取得する
 * @returns 選択中の表示項目
 */
export const dataKiKasidamaParamsFieldsSelector = createSelector(
  [dataKiKasidamaParamsSelector],
  (params) => {
    const selectedFields = params?.fields;

    return selectedFields ? selectedFields : [];
  }
);

// Reducer

export function dataKiKasidamaReducer(
  state = initialState,
  action: DataKiKasidamaAction
): DataKiKasidamaState {
  switch (action.type) {
    case FETCH_DATA_KI_KASIDAMA_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_DATA_KI_KASIDAMA_SUCCESS:
      return {
        isLoading: false,
        dataKiKasidama: action.payload.dataKiKasidama,
      };
    case RENEW_DATA_KI_KASIDAMA:
      return initialState;
    case HIDE_DATA_KI_KASIDAMA:
      return {
        ...state,
        dataKiKasidama: initialState.dataKiKasidama,
      };
    default:
      return state;
  }
}
