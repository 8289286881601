import { SxProps, Theme } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { format } from 'date-fns/esm';
import { FC, useMemo } from 'react';

import { Maintenance } from '../../../domain/maintenance';

import { Logo } from '../../atoms/Icon/Logo';
import { CenterLayout } from '../../layouts/CenterLayout';

type MaintenanceWindowPagePresenterProps = {
  maintenanceTime: string;
};

type MaintenanceWindowPageProps = {
  maintenance: Maintenance;
};

const styles: Record<string, SxProps<Theme>> = {
  root: {
    left: 0,
    top: 0,
    height: `100%`,
    width: `100%`,
    overflow: `hidden`,
    position: `fixed`,
    backgroundColor: `#e5e5e5`,
  },
  maintenanceWindowBody: {
    padding: '30px',
  },
  logoImage: {
    height: `81px`,
    width: `auto`,
    marginTop: `20px`,
    marginBottom: `20px`,
    display: `block`,
    margin: `auto`,
  },
  maintenanceDoorImage: {
    height: `154px`,
    width: `auto`,
  },
  maintenanceText: {
    fontSize: '16px',
    color: 'text.primary',
  },
  maintenanceTimeLabel: {
    marginTop: `16px`,
    fontSize: '16px',
    color: 'info.dark',
  },
  maintenanceTimeText: {
    fontSize: '24px',
    color: 'primary.dark',
  },
};

export const MaintenanceWindowPagePresenter: FC<MaintenanceWindowPagePresenterProps> = (
  props
) => {
  const { maintenanceTime } = props;

  return (
    <Box sx={styles.root}>
      <CenterLayout>
        <Paper elevation={3} sx={styles.maintenanceWindowBody}>
          <Logo />
          <Grid container spacing={3}>
            <Grid item>
              <Typography variant="subtitle1" sx={styles.maintenanceText}>
                ただいまメンテナンス中です。
                <br />
                メンテナンス終了後にアクセスをお願いします。
              </Typography>
              <Typography variant="subtitle1" sx={styles.maintenanceTimeLabel}>
                メンテナンス終了時間
              </Typography>
              <Typography variant="h5" sx={styles.maintenanceTimeText}>
                {maintenanceTime}
              </Typography>
            </Grid>
            <Grid item>
              <img src="/maintenance.svg" alt="Maintenance" height={154} />
            </Grid>
          </Grid>
        </Paper>
      </CenterLayout>
    </Box>
  );
};

export const MaintenanceWindowPage: FC<MaintenanceWindowPageProps> = (
  props
) => {
  const { maintenance } = props;

  const maintenanceTime = useMemo(() => {
    const startDate = maintenance.startDate
      ? format(new Date(maintenance.startDate), 'yyyy.MM.dd HH:mm')
      : '';
    const endDate = maintenance.endDate
      ? format(new Date(maintenance.endDate), 'yyyy.MM.dd HH:mm')
      : '';
    return `${startDate} - ${endDate}`;
  }, [maintenance.startDate, maintenance.endDate]);

  return <MaintenanceWindowPagePresenter maintenanceTime={maintenanceTime} />;
};
