import { AxiosResponse } from 'axios';
import { call, fork, put, takeEvery } from 'redux-saga/effects';

import { CreateShortenedUrlResponse } from '../domain/shortenedUrl';

import {
  PostShortenedUrlAction,
  ShortenedUrlActionCreators,
  ShortenedUrlActionTypes,
} from '../redux/server/shortenedUrl';
import { Api, buildConfig } from '../utils/api';
import { handleErrorSaga } from './errorSaga';

function* postShortenedUrlSaga(api: Api, action: PostShortenedUrlAction) {
  try {
    const { params } = action.payload;

    yield put(ShortenedUrlActionCreators.postShortenedUrlRequestAction());

    const response: AxiosResponse<CreateShortenedUrlResponse> = yield call(
      api.post,
      `/shortenedUrl`,
      params,
      buildConfig()
    );

    yield put(
      ShortenedUrlActionCreators.postShortenedUrlSuccessAction(response.data)
    );
  } catch (error: unknown) {
    yield put(ShortenedUrlActionCreators.renewShortenedUrlAction());
    yield fork(handleErrorSaga, error);
  }
}

function* handlePostShortenedUrlSaga(api: Api) {
  yield takeEvery(
    ShortenedUrlActionTypes.POST_SHORTENED_URL,
    postShortenedUrlSaga,
    api
  );
}

export function* shortenedUrlSagas(context: { api: Api }) {
  yield fork(handlePostShortenedUrlSaga, context.api);
}
