import { createSelector } from 'reselect';

import { DataKadoGraph, DataKadoGraphParams } from '../../domain/dataKadoGraph';
import { HallReportsFormConditions } from '../../domain/hallReportsFormConditions';
import { KadoFieldType } from '../../domain/schemas';
import { ShuOption } from '../../domain/shu';

import { RootState } from '../../store';
import { dataHallShuKadoParamsDataSelector } from './dataHallShuKadoTable';

/**
 * Action Types
 */

const FETCH_DATA_HALL_SHU_KADO_TABLE_GRAPH = 'FETCH_DATA_HALL_SHU_KADO_TABLE_GRAPH' as const;
const FETCH_DATA_HALL_SHU_KADO_TABLE_GRAPH_REQUEST = 'FETCH_DATA_HALL_SHU_KADO_TABLE_GRAPH_REQUEST' as const;
const FETCH_DATA_HALL_SHU_KADO_TABLE_GRAPH_SUCCESS = 'FETCH_DATA_HALL_SHU_KADO_TABLE_GRAPH_SUCCESS' as const;
const RENEW_DATA_HALL_SHU_KADO_TABLE_GRAPH = 'RENEW_DATA_HALL_SHU_KADO_TABLE_GRAPH' as const;
const INIT_DATA_HALL_SHU_KADO_TABLE_GRAPH = 'INIT_DATA_HALL_SHU_KADO_TABLE_GRAPH' as const;
const SEARCH_DATA_HALL_SHU_KADO_TABLE_GRAPH = 'SEARCH_DATA_HALL_SHU_KADO_TABLE_GRAPH' as const;
const SEARCH_DATA_HALL_SHU_KADO_TABLE_GRAPH_SHU = 'SEARCH_DATA_HALL_SHU_KADO_TABLE_GRAPH_SHU' as const;
const SEARCH_DATA_HALL_SHU_KADO_TABLE_GRAPH_KADO_FIELD_TYPE = 'SEARCH_DATA_HALL_SHU_KADO_TABLE_GRAPH_KADO_FIELD_TYPE' as const;
const CLEAR_DATA_HALL_SHU_KADO_TABLE_GRAPH = 'CLEAR_DATA_HALL_SHU_KADO_TABLE_GRAPH' as const;

export const DataHallShuKadoTableGraphActionTypes = {
  FETCH_DATA_HALL_SHU_KADO_TABLE_GRAPH,
  FETCH_DATA_HALL_SHU_KADO_TABLE_GRAPH_REQUEST,
  FETCH_DATA_HALL_SHU_KADO_TABLE_GRAPH_SUCCESS,
  RENEW_DATA_HALL_SHU_KADO_TABLE_GRAPH,
  INIT_DATA_HALL_SHU_KADO_TABLE_GRAPH,
  SEARCH_DATA_HALL_SHU_KADO_TABLE_GRAPH,
  SEARCH_DATA_HALL_SHU_KADO_TABLE_GRAPH_SHU,
  SEARCH_DATA_HALL_SHU_KADO_TABLE_GRAPH_KADO_FIELD_TYPE,
  CLEAR_DATA_HALL_SHU_KADO_TABLE_GRAPH,
};

/**
 * Action Creators
 */

/**
 * 指定した検索条件でグラフデータを取得する
 * @param params 検索条件
 */
function fetchDataHallShuKadoTableGraphAction(params: DataKadoGraphParams) {
  return {
    type: FETCH_DATA_HALL_SHU_KADO_TABLE_GRAPH,
    payload: { params },
  };
}

/**
 * グラフのデータ取得前に呼ぶもの
 * @param params 検索条件
 */
function fetchDataHallShuKadoTableGraphRequestAction(
  params: DataKadoGraphParams
) {
  return {
    type: FETCH_DATA_HALL_SHU_KADO_TABLE_GRAPH_REQUEST,
    payload: { params },
  };
}

/**
 * グラフ取得成功時、グラフデータを登録
 * @param dataHallKadoTableGraphData 取得したグラフデータ
 */
function fetchDataHallShuKadoTableGraphSuccessAction(
  dataHallKadoTableGraphData: DataKadoGraph
) {
  return {
    type: FETCH_DATA_HALL_SHU_KADO_TABLE_GRAPH_SUCCESS,
    payload: { dataHallKadoTableGraphData },
  };
}

function renewDataHallShuKadoTableGraphAction() {
  return {
    type: RENEW_DATA_HALL_SHU_KADO_TABLE_GRAPH,
  };
}

/**
 * 現在の検索条件を元にグラフデータを取得する（初回取得用）
 */
function initDataHallShuKadoTableGraphAction() {
  return {
    type: INIT_DATA_HALL_SHU_KADO_TABLE_GRAPH,
  };
}

/**
 * 検索フォームで設定した検索条件を元にデータを再取得する
 * @param params 検索フォームの検索条件
 */
function searchDataHallShuKadoTableGraphAction(
  params: HallReportsFormConditions
) {
  return {
    type: SEARCH_DATA_HALL_SHU_KADO_TABLE_GRAPH,
    payload: { params },
  };
}

/**
 * 指定した種別でグラフデータを再取得する
 * @param shu 取得する種別
 */
function searchDataHallShuKadoTableGraphShuAction(shu: ShuOption) {
  return {
    type: SEARCH_DATA_HALL_SHU_KADO_TABLE_GRAPH_SHU,
    payload: { shu },
  };
}

/**
 * 指定した稼働フィールドタイプでグラフデータを再取得する
 * @param kadoFieldType 取得する稼働フィールドタイプ（稼働数・稼働率）
 */
function searchDataHallShuKadoTableGraphKadoFieldTypeAction(
  kadoFieldType: KadoFieldType
) {
  return {
    type: SEARCH_DATA_HALL_SHU_KADO_TABLE_GRAPH_KADO_FIELD_TYPE,
    payload: { kadoFieldType },
  };
}

/**
 * 時間帯別稼働数グラフのデータを破棄する
 */
function clearDataHallShuKadoTableGraphAction() {
  return {
    type: CLEAR_DATA_HALL_SHU_KADO_TABLE_GRAPH,
  };
}

export const DataHallShuKadoTableGraphActionCreators = {
  fetchDataHallShuKadoTableGraphAction,
  fetchDataHallShuKadoTableGraphRequestAction,
  fetchDataHallShuKadoTableGraphSuccessAction,
  renewDataHallShuKadoTableGraphAction,
  initDataHallShuKadoTableGraphAction,
  searchDataHallShuKadoTableGraphAction,
  searchDataHallShuKadoTableGraphShuAction,
  searchDataHallShuKadoTableGraphKadoFieldTypeAction,
  clearDataHallShuKadoTableGraphAction,
};

/**
 * Actions
 */

export type FetchDataHallShuKadoTableGraphAction = ReturnType<
  typeof fetchDataHallShuKadoTableGraphAction
>;
type InitDataHallShuKadoTableGraphAction = ReturnType<
  typeof initDataHallShuKadoTableGraphAction
>;
export type SearchDataHallShuKadoTableGraphAction = ReturnType<
  typeof searchDataHallShuKadoTableGraphAction
>;
export type SearchDataHallShuKadoTableGraphShuAction = ReturnType<
  typeof searchDataHallShuKadoTableGraphShuAction
>;
export type SearchDataHallShuKadoTableGraphKadoFieldTypeAction = ReturnType<
  typeof searchDataHallShuKadoTableGraphKadoFieldTypeAction
>;
type ClearDataHallShuKadoTableGraphAction = ReturnType<
  typeof clearDataHallShuKadoTableGraphAction
>;

type DataHallKadoTableGraphAction =
  | FetchDataHallShuKadoTableGraphAction
  | InitDataHallShuKadoTableGraphAction
  | SearchDataHallShuKadoTableGraphAction
  | SearchDataHallShuKadoTableGraphShuAction
  | SearchDataHallShuKadoTableGraphKadoFieldTypeAction
  | ClearDataHallShuKadoTableGraphAction
  | ReturnType<typeof fetchDataHallShuKadoTableGraphRequestAction>
  | ReturnType<typeof fetchDataHallShuKadoTableGraphSuccessAction>
  | ReturnType<typeof renewDataHallShuKadoTableGraphAction>;

type DataHallShuKadoTableGraphState = {
  dataHallShuKadoTableGraph?: DataKadoGraph;
  isLoading: boolean;
};

const initialState: DataHallShuKadoTableGraphState = {
  dataHallShuKadoTableGraph: undefined,
  isLoading: false,
};

/**
 * Selector
 */

/**
 * 店舗レポートの時間帯別稼働数グラフのグラフデータを取得する
 * @returns 時間帯別稼働数グラフのグラフデータ
 */
export const dataHallShuKadoTableGraphSelector = (state: RootState) => {
  return state.dataHallShuKadoTableGraph?.dataHallShuKadoTableGraph;
};

/**
 * 店舗レポートの時間帯別稼働数グラフの現在の検索条件を取得する
 * @returns 現在の検索条件
 */
export const dataHallShuKadoTableGraphParamsSelector = (state: RootState) => {
  return state.dataHallShuKadoTableGraph.dataHallShuKadoTableGraph?.setting;
};

/**
 * 店舗レポートの時間帯別稼働数グラフで選択可能な種別の一覧を取得する
 * @returns 選択可能な種別一覧
 */
export const dataHallShuKadoTableGraphAvailableShuSelector = createSelector(
  dataHallShuKadoParamsDataSelector,
  (searchResult) => {
    // 店舗全体
    const all: ShuOption = {
      code: '',
      name: '店舗全体',
      group: '種別',
      type: 'shuList',
    };

    const rows = searchResult?.rows;

    // 稼働数テーブルのデータから種別一覧を取得
    const result: ShuOption[] =
      rows === undefined
        ? []
        : rows
            .filter((item) => item.queryParameter.value)
            .map((row) => {
              const { value, name } = row.queryParameter;

              if (name === 'shuCodes') {
                return {
                  code: value,
                  name: value === '01' ? 'パチンコ全体' : 'パチスロ全体',
                  group: '種別コード',
                  type: name,
                };
              }

              return {
                code: value,
                name: value,
                group: '種別',
                type: name,
              };
            });

    return [all, ...result];
  }
);

/**
 * 店舗レポートの時間帯別稼働数グラフで現在選択されている種別を取得する
 * @returns 現在選択されている種別
 */
export const dataHallShuKadoTableGraphSelectedShuSelector = createSelector(
  [
    dataHallShuKadoTableGraphParamsSelector,
    dataHallShuKadoTableGraphAvailableShuSelector,
  ],
  (params, availableShu) => {
    // 未選択の場合は店舗全体を選択
    if (params === undefined) return availableShu[0];

    const { shuList, shuCodes } = params;

    // パチンコ全体・パチスロ全体
    if (shuCodes && shuCodes.length > 0) {
      const shu: ShuOption = {
        code: shuCodes[0],
        name: shuCodes[0] === '01' ? 'パチンコ全体' : 'パチスロ全体',
        group: '種別コード',
        type: 'shuCodes',
      };
      return shu;
    }

    // 選択されている種別（未選択または''が選択されている場合店舗全体とみなす）
    if (shuList === undefined) return availableShu[0];
    return (
      availableShu.find(
        (shu) => shu.type === 'shuList' && shu.code === shuList[0]
      ) ?? availableShu[0]
    );
  }
);

/**
 * 店舗レポートの時間帯別稼働数グラフの現在のローディング状態を取得する
 * @returns 現在のローディング状態（ローディング時true）
 */
export const dataHallShuKadoTableGraphLoadingSelector = (state: RootState) => {
  return state.dataHallShuKadoTableGraph.isLoading;
};

/**
 * 店舗レポートの時間帯別稼働数グラフが存在するか取得する
 * @returns テーブルデータ
 */
export const dataHallShuKadoTableGraphIsExistSelector = createSelector(
  dataHallShuKadoTableGraphSelector,
  (data) => (data === undefined ? false : true)
);

/**
 * Reducer
 */

export function dataHallShuKadoTableGraphReducer(
  state = initialState,
  action: DataHallKadoTableGraphAction
): DataHallShuKadoTableGraphState {
  switch (action.type) {
    case FETCH_DATA_HALL_SHU_KADO_TABLE_GRAPH_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_DATA_HALL_SHU_KADO_TABLE_GRAPH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        dataHallShuKadoTableGraph: action.payload.dataHallKadoTableGraphData,
      };
    case RENEW_DATA_HALL_SHU_KADO_TABLE_GRAPH:
      return initialState;
    case CLEAR_DATA_HALL_SHU_KADO_TABLE_GRAPH:
      return {
        ...state,
        dataHallShuKadoTableGraph: initialState.dataHallShuKadoTableGraph,
      };
    default:
      return state;
  }
}
