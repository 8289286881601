import { createSelector } from 'reselect';

import { User } from '../../domain/user';

/* ---------------------------------------------------------------
 * Action Types
 */

const SET_USER = 'SET_USER' as const;
const SET_IS_POST_LOGIN = 'SET_IS_POST_LOGIN' as const;

export const UserActionTypes = {
  SET_USER,
  SET_IS_POST_LOGIN,
};

/* ---------------------------------------------------------------
 * Action Creators
 */

function setUserAction(user: User) {
  return {
    type: SET_USER,
    payload: { user },
  };
}

function setIsLoginAction(isLogin: boolean) {
  return {
    type: SET_IS_POST_LOGIN,
    payload: { isLogin },
  };
}

export const UserActionCreators = {
  setUserAction,
  setIsLoginAction,
};

/* ---------------------------------------------------------------
 * Actions
 */

type SetUserAction = ReturnType<typeof setUserAction>;
type SetIsLoginAction = ReturnType<typeof setIsLoginAction>;

type UserAction = SetUserAction | SetIsLoginAction;

/* ---------------------------------------------------------------
 * State
 */

type UserState = {
  user: User | undefined;
  isSetUser: boolean;
  isPostLogin: boolean;
};

export const initialState: UserState = {
  user: undefined,
  isSetUser: false,
  isPostLogin: false,
};

/* ---------------------------------------------------------------
 * Selector
 */

function allSelector(rootState: { user: UserState }) {
  return rootState.user;
}

export const userSelector = createSelector(allSelector, ({ user }) => user);

export const userIsSetUserSelector = createSelector(
  allSelector,
  ({ isSetUser }) => isSetUser
);

export const userIsPostLoginSelector = createSelector(
  allSelector,
  ({ isPostLogin }) => isPostLogin
);

export const userHallInChargeSelector = createSelector(
  userSelector,
  (user) => user?.hallCodeInCharge
);

/* ---------------------------------------------------------------
 * Reducer
 */

export function userReducer(
  state = initialState,
  action: UserAction
): UserState {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        user: action.payload.user,
      };
    case SET_IS_POST_LOGIN: {
      return {
        ...state,
        isPostLogin: action.payload.isLogin,
      };
    }
    default:
      return state;
  }
}
