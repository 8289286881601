import { createSelector } from 'reselect';

import {
  KiTagsSettingsKiList,
  KiTagsSettingsKiListParams,
  KiTagsSettingsKiListPatchParams,
  KiTagsSettingsKiTags,
  KiTagsSettingsKiTagsPatchParams,
  KiTagsSettingsKiTagsPostParams,
} from '../../domain/dataKiTagsSettings';
import { LoadingState } from '../../domain/schemas';

import { filterModelName } from '../../utils/filterModelName';
import {
  kiTagsMakerNameFilterSelector,
  kiTagsModelNameFilterSelector,
  kiTagsPgNameFilterSelector,
  kiTagsSelectedKiSelector,
  kiTagsTagNameFilterSelector,
  kiTagsTypeNameFilterSelector,
} from '../ui/settingsKiTags';

/**
 * TODO:
 * loadingStateを異なるAPIで共通で利用しているため、それぞれのLoading状態を個別にもたせる必要があります
 * https://github.com/DKClaris/claris-general/issues/3708
 */

// Action Types

const FETCH_DATA_KITAGS_KILIST = 'FETCH_DATA_KITAGS_KILIST' as const;
const FETCH_DATA_KITAGS_KILIST_REQUEST = 'FETCH_DATA_KITAGS_KILIST_REQUEST' as const;
const FETCH_DATA_KITAGS_KILIST_SUCCESS = 'FETCH_DATA_KITAGS_KILIST_SUCCESS' as const;
const PATCH_DATA_KITAGS_KILIST_BY_TAGID = 'PATCH_DATA_KITAGS_KILIST_BY_TAGID' as const;
const PATCH_DATA_KITAGS_KILIST_BY_TAGID_REQUEST = 'PATCH_DATA_KITAGS_KILIST_BY_TAGID_REQUEST' as const;
const PATCH_DATA_KITAGS_KILIST_BY_TAGID_SUCCESS = 'PATCH_DATA_KITAGS_KILIST_BY_TAGID_SUCCESS' as const;
const FETCH_DATA_KITAGS = 'FETCH_DATA_KITAGS' as const;
const FETCH_DATA_KITAGS_REQUEST = 'FETCH_DATA_KITAGS_REQUEST' as const;
const FETCH_DATA_KITAGS_SUCCESS = 'FETCH_DATA_KITAGS_SUCCESS' as const;
const POST_DATA_KITAGS = 'POST_DATA_KITAGS' as const;
const POST_DATA_KITAGS_REQUEST = 'POST_DATA_KITAGS_REQUEST' as const;
const POST_DATA_KITAGS_SUCCESS = 'POST_DATA_KITAGS_SUCCESS' as const;
const PATCH_DATA_KITAGS_BY_TAGID = 'PATCH_DATA_KITAGS_BY_TAGID' as const;
const PATCH_DATA_KITAGS_BY_TAGID_REQUEST = 'PATCH_DATA_KITAGS_BY_TAGID_REQUEST' as const;
const PATCH_DATA_KITAGS_BY_TAGID_SUCCESS = 'PATCH_DATA_KITAGS_BY_TAGID_SUCCESS' as const;
const DELETE_DATA_KITAGS_BY_TAGID = 'DELETE_DATA_KITAGS_BY_TAGID' as const;
const DELETE_DATA_KITAGS_BY_TAGID_REQUEST = 'DELETE_DATA_KITAGS_BY_TAGID_REQUEST' as const;
const DELETE_DATA_KITAGS_BY_TAGID_SUCCESS = 'DELETE_DATA_KITAGS_BY_TAGID_SUCCESS' as const;
const RENEW_DATA_KITAGS = 'RENEW_DATA_KITAGS' as const;

export const DataSettingsKiTagsActionTypes = {
  FETCH_DATA_KITAGS_KILIST,
  FETCH_DATA_KITAGS_KILIST_REQUEST,
  FETCH_DATA_KITAGS_KILIST_SUCCESS,
  PATCH_DATA_KITAGS_KILIST_BY_TAGID,
  PATCH_DATA_KITAGS_KILIST_BY_TAGID_REQUEST,
  PATCH_DATA_KITAGS_KILIST_BY_TAGID_SUCCESS,
  FETCH_DATA_KITAGS,
  FETCH_DATA_KITAGS_REQUEST,
  FETCH_DATA_KITAGS_SUCCESS,
  POST_DATA_KITAGS,
  POST_DATA_KITAGS_REQUEST,
  POST_DATA_KITAGS_SUCCESS,
  PATCH_DATA_KITAGS_BY_TAGID,
  PATCH_DATA_KITAGS_BY_TAGID_REQUEST,
  PATCH_DATA_KITAGS_BY_TAGID_SUCCESS,
  DELETE_DATA_KITAGS_BY_TAGID,
  DELETE_DATA_KITAGS_BY_TAGID_REQUEST,
  DELETE_DATA_KITAGS_BY_TAGID_SUCCESS,
  RENEW_DATA_KITAGS,
};

// Action Creators

function fetchDataSettingsKiTagsKiListAction(
  params: KiTagsSettingsKiListParams | undefined
) {
  return {
    type: FETCH_DATA_KITAGS_KILIST,
    payload: { params },
  };
}

function fetchDataSettingsKiTagsKiListRequestAction() {
  return {
    type: FETCH_DATA_KITAGS_KILIST_REQUEST,
  };
}

function fetchDataSettingsKiTagsKiListSuccessAction(
  kiList: KiTagsSettingsKiList
) {
  return {
    type: FETCH_DATA_KITAGS_KILIST_SUCCESS,
    payload: { kiList },
  };
}

function patchDataSettingsKiTagsKiListAction(
  id: number,
  params: KiTagsSettingsKiListPatchParams
) {
  return {
    type: PATCH_DATA_KITAGS_KILIST_BY_TAGID,
    payload: { id, params },
  };
}

function patchDataSettingsKiTagsKiListRequestAction() {
  return {
    type: PATCH_DATA_KITAGS_KILIST_BY_TAGID_REQUEST,
  };
}

function patchDataSettingsKiTagsKiListSuccessAction() {
  return {
    type: PATCH_DATA_KITAGS_KILIST_BY_TAGID_SUCCESS,
  };
}

function fetchDataSettingsKiTagsAction() {
  return {
    type: FETCH_DATA_KITAGS,
  };
}

function fetchDataSettingsKiTagsRequestAction() {
  return {
    type: FETCH_DATA_KITAGS_REQUEST,
  };
}

function fetchDataSettingsKiTagsSuccessAction(kiTags: KiTagsSettingsKiTags) {
  return {
    type: FETCH_DATA_KITAGS_SUCCESS,
    payload: { kiTags },
  };
}

function patchDataSettingsKiTagsAction(
  id: number,
  params: KiTagsSettingsKiTagsPatchParams
) {
  return {
    type: PATCH_DATA_KITAGS_BY_TAGID,
    payload: { id, params },
  };
}

function patchDataSettingsKiTagsRequestAction() {
  return {
    type: PATCH_DATA_KITAGS_BY_TAGID_REQUEST,
  };
}

function patchDataSettingsKiTagsSuccessAction(kiTags: KiTagsSettingsKiTags) {
  return {
    type: PATCH_DATA_KITAGS_BY_TAGID_SUCCESS,
    payload: { kiTags },
  };
}

function postDataSettingsKiTagsAction(params: KiTagsSettingsKiTagsPostParams) {
  return {
    type: POST_DATA_KITAGS,
    payload: { params },
  };
}

function postDataSettingsKiTagsRequestAction() {
  return {
    type: POST_DATA_KITAGS_REQUEST,
  };
}

function postDataSettingsKiTagsSuccessAction() {
  return {
    type: POST_DATA_KITAGS_SUCCESS,
  };
}

function deleteDataSettingsKiTagsAction(id: number) {
  return {
    type: DELETE_DATA_KITAGS_BY_TAGID,
    payload: { id },
  };
}

function deleteDataSettingsKiTagsRequestAction() {
  return {
    type: DELETE_DATA_KITAGS_BY_TAGID_REQUEST,
  };
}

function deleteDataSettingsKiTagsSuccessAction() {
  return {
    type: DELETE_DATA_KITAGS_BY_TAGID_SUCCESS,
  };
}

/**
 * 初期化
 */
function renewDataSettingsKiTagsAction() {
  return {
    type: RENEW_DATA_KITAGS,
  };
}

export const DataSettingsKiTagsActionCreators = {
  fetchDataSettingsKiTagsKiListAction,
  fetchDataSettingsKiTagsKiListRequestAction,
  fetchDataSettingsKiTagsKiListSuccessAction,
  patchDataSettingsKiTagsKiListAction,
  patchDataSettingsKiTagsKiListRequestAction,
  patchDataSettingsKiTagsKiListSuccessAction,
  fetchDataSettingsKiTagsAction,
  fetchDataSettingsKiTagsRequestAction,
  fetchDataSettingsKiTagsSuccessAction,
  patchDataSettingsKiTagsAction,
  patchDataSettingsKiTagsRequestAction,
  patchDataSettingsKiTagsSuccessAction,
  postDataSettingsKiTagsAction,
  postDataSettingsKiTagsRequestAction,
  postDataSettingsKiTagsSuccessAction,
  deleteDataSettingsKiTagsAction,
  deleteDataSettingsKiTagsRequestAction,
  deleteDataSettingsKiTagsSuccessAction,
  renewDataSettingsKiTagsAction,
};

// Actions

export type FetchDataSettingsKiTagsKiListAction = ReturnType<
  typeof fetchDataSettingsKiTagsKiListAction
>;
export type PatchDataSettingsKiTagsKiListAction = ReturnType<
  typeof patchDataSettingsKiTagsKiListAction
>;
type FetchDataSettingsKiTagsAction = ReturnType<
  typeof fetchDataSettingsKiTagsAction
>;
export type PatchDataSettingsKiTagsAction = ReturnType<
  typeof patchDataSettingsKiTagsAction
>;
export type PostDataSettingsKiTagsAction = ReturnType<
  typeof postDataSettingsKiTagsAction
>;
export type DeleteDataSettingsKiTagsAction = ReturnType<
  typeof deleteDataSettingsKiTagsAction
>;

type DataSettingsKiTagsAction =
  | FetchDataSettingsKiTagsKiListAction
  | ReturnType<typeof fetchDataSettingsKiTagsKiListRequestAction>
  | ReturnType<typeof fetchDataSettingsKiTagsKiListSuccessAction>
  | PatchDataSettingsKiTagsKiListAction
  | ReturnType<typeof patchDataSettingsKiTagsKiListRequestAction>
  | ReturnType<typeof patchDataSettingsKiTagsKiListSuccessAction>
  | FetchDataSettingsKiTagsAction
  | ReturnType<typeof fetchDataSettingsKiTagsRequestAction>
  | ReturnType<typeof fetchDataSettingsKiTagsSuccessAction>
  | PatchDataSettingsKiTagsAction
  | ReturnType<typeof patchDataSettingsKiTagsRequestAction>
  | ReturnType<typeof patchDataSettingsKiTagsSuccessAction>
  | PostDataSettingsKiTagsAction
  | ReturnType<typeof postDataSettingsKiTagsRequestAction>
  | ReturnType<typeof postDataSettingsKiTagsSuccessAction>
  | DeleteDataSettingsKiTagsAction
  | ReturnType<typeof deleteDataSettingsKiTagsRequestAction>
  | ReturnType<typeof deleteDataSettingsKiTagsSuccessAction>
  | ReturnType<typeof renewDataSettingsKiTagsAction>;

// State

type DataSettingsKiTagsState = {
  loadingState: LoadingState;
  kiList: KiTagsSettingsKiList;
  kiTags: KiTagsSettingsKiTags;
};

const initialState: DataSettingsKiTagsState = {
  loadingState: 'prepare',
  kiList: { data: [] },
  kiTags: {
    tags: [],
    sisTypes: [],
    autoTags: [],
  },
};

// Selector

function dataSettingsKiTagsAllSelector(rootState: {
  dataSettingsKiTags: DataSettingsKiTagsState;
}) {
  return rootState.dataSettingsKiTags;
}

export const dataSettingsKiTagsKiListSelector = createSelector(
  [
    dataSettingsKiTagsAllSelector,
    kiTagsModelNameFilterSelector,
    kiTagsPgNameFilterSelector,
    kiTagsTypeNameFilterSelector,
    kiTagsMakerNameFilterSelector,
    kiTagsTagNameFilterSelector,
  ],
  (
    dataSettingsKiTagsState,
    modelNameFilter,
    pgNameFilter,
    typeNameFilter,
    makerNameFilter,
    kiTagNameFilter
  ) => {
    if (!dataSettingsKiTagsState.kiList) {
      return;
    }
    if (
      !modelNameFilter &&
      !pgNameFilter &&
      !typeNameFilter &&
      !makerNameFilter &&
      !kiTagNameFilter
    ) {
      return dataSettingsKiTagsState.kiList;
    }
    const predicateModelName = filterModelName(modelNameFilter);
    const predicatePgName = filterModelName(pgNameFilter);
    const predicateMakerName = filterModelName(makerNameFilter);

    return {
      data: [
        ...dataSettingsKiTagsState.kiList.data.filter(
          (row) =>
            predicateModelName(row.ki.name) &&
            predicatePgName(row.pg) &&
            (typeNameFilter.length === 0 ||
              typeNameFilter.some((name) => row.type === name)) &&
            predicateMakerName(row.maker) &&
            // フィルターのタグを全て含んでいたらtrue
            kiTagNameFilter.every((filter) =>
              row.tags.map((tag) => tag.tagId).includes(filter)
            )
        ),
      ],
    } as KiTagsSettingsKiList;
  }
);

export const dataSettingsKiTagsSelector = createSelector(
  dataSettingsKiTagsAllSelector,
  (dataSettingsKiTagsState) => dataSettingsKiTagsState.kiTags
);

export const dataSettingsKiTagsLoadingStateSelector = createSelector(
  dataSettingsKiTagsAllSelector,
  (dataSettingsKiTagsState) => dataSettingsKiTagsState.loadingState
);

export const dataSettingsKiTagsFilteredDataSelector = createSelector(
  [dataSettingsKiTagsKiListSelector, kiTagsSelectedKiSelector],
  (dataSettingsKiList, kiList) => {
    if (dataSettingsKiList === undefined) {
      return { data: [] };
    }

    // 全件指定の場合はそのまま戻す
    if (!kiList || kiList.length === 0) {
      return dataSettingsKiList;
    }

    // フィルターされている場合
    const filteredRows = dataSettingsKiList.data.filter(
      (row) => row.ki && kiList.includes(row.ki.code)
    );

    return {
      data: filteredRows,
    };
  }
);

// Reducer

export function dataSettingsKiTagsReducer(
  state = initialState,
  action: DataSettingsKiTagsAction
): DataSettingsKiTagsState {
  switch (action.type) {
    case FETCH_DATA_KITAGS_KILIST_REQUEST:
    case PATCH_DATA_KITAGS_KILIST_BY_TAGID_REQUEST:
    case FETCH_DATA_KITAGS_REQUEST:
    case PATCH_DATA_KITAGS_BY_TAGID_REQUEST:
    case POST_DATA_KITAGS_REQUEST:
    case DELETE_DATA_KITAGS_BY_TAGID_REQUEST:
      return {
        ...state,
        loadingState: 'loading',
      };
    case FETCH_DATA_KITAGS_KILIST_SUCCESS:
      return {
        ...state,
        loadingState: 'loaded',
        kiList: action.payload.kiList,
      };
    case FETCH_DATA_KITAGS_SUCCESS:
    case PATCH_DATA_KITAGS_BY_TAGID_SUCCESS:
      return {
        ...state,
        loadingState: 'loaded',
        kiTags: action.payload.kiTags,
      };
    case PATCH_DATA_KITAGS_KILIST_BY_TAGID_SUCCESS:
    case POST_DATA_KITAGS_SUCCESS:
    case DELETE_DATA_KITAGS_BY_TAGID_SUCCESS:
      return {
        ...state,
        loadingState: 'loaded',
      };
    case RENEW_DATA_KITAGS:
      return initialState;
    default:
      return state;
  }
}
