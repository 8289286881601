import { createSelector } from 'reselect';

import { ClarisApiError } from '../../domain/error';
import { LoadingState } from '../../domain/schemas';
import {
  CreateShortenedUrlParams,
  CreateShortenedUrlResponse,
} from '../../domain/shortenedUrl';

// Action Types

const POST_SHORTENED_URL = 'POST_SHORTENED_URL' as const;
const POST_SHORTENED_URL_REQUEST = 'POST_SHORTENED_URL_REQUEST' as const;
const POST_SHORTENED_URL_SUCCESS = 'POST_SHORTENED_URL_SUCCESS' as const;
const RENEW_SHORTENED_URL = 'RENEW_SHORTENED_URL' as const;

export const ShortenedUrlActionTypes = {
  POST_SHORTENED_URL,
  POST_SHORTENED_URL_REQUEST,
  POST_SHORTENED_URL_SUCCESS,
  RENEW_SHORTENED_URL,
};

// Action Creators
function postShortenedUrlAction(params: CreateShortenedUrlParams) {
  return {
    type: POST_SHORTENED_URL,
    payload: { params },
  };
}

function postShortenedUrlRequestAction() {
  return {
    type: POST_SHORTENED_URL_REQUEST,
  };
}

function postShortenedUrlSuccessAction(
  shortenedUrlData: CreateShortenedUrlResponse
) {
  return {
    type: POST_SHORTENED_URL_SUCCESS,
    payload: { shortenedUrlData },
  };
}

function renewShortenedUrlAction() {
  return {
    type: RENEW_SHORTENED_URL,
  };
}

export const ShortenedUrlActionCreators = {
  postShortenedUrlAction,
  postShortenedUrlRequestAction,
  postShortenedUrlSuccessAction,
  renewShortenedUrlAction,
};

// Actions

export type PostShortenedUrlAction = ReturnType<typeof postShortenedUrlAction>;

type ShortenedUrlAction =
  | PostShortenedUrlAction
  | ReturnType<typeof postShortenedUrlRequestAction>
  | ReturnType<typeof postShortenedUrlSuccessAction>
  | ReturnType<typeof renewShortenedUrlAction>;

// State

type ShortenedUrlState = {
  loadingState: LoadingState;
  error: ClarisApiError | undefined;
  shortenedUrl: CreateShortenedUrlResponse['shortenedUrl'] | undefined;
};

export const initialState: ShortenedUrlState = {
  loadingState: 'prepare',
  error: undefined,
  shortenedUrl: undefined,
};

// Selector

export function shortenedUrlLoadingStateSelector(rootState: {
  shortenedUrl: ShortenedUrlState;
}) {
  return rootState.shortenedUrl.loadingState;
}

function shortenedUrlSelector(rootState: { shortenedUrl: ShortenedUrlState }) {
  return rootState.shortenedUrl.shortenedUrl;
}

export const shortenedHashSelector = createSelector(
  shortenedUrlSelector,
  (shortenedUrl) => {
    if (shortenedUrl == null) {
      return undefined;
    }
    const parts = shortenedUrl.split('/s/');
    return parts.length > 1 ? parts.at(1) : undefined;
  }
);

// Reducer

export function shortenedUrlReducer(
  state = initialState,
  action: ShortenedUrlAction
): ShortenedUrlState {
  switch (action.type) {
    case POST_SHORTENED_URL_REQUEST:
      return {
        ...state,
        loadingState: 'loading',
        error: undefined,
      };

    case POST_SHORTENED_URL_SUCCESS:
      return {
        ...state,
        loadingState: 'loaded',
        shortenedUrl: action.payload.shortenedUrlData.shortenedUrl,
      };
    case RENEW_SHORTENED_URL:
      return initialState;
    default:
      return state;
  }
}
