import { DataDai } from '../domain/dai/types';
import { Row } from '../domain/schemas';

import { insert } from './insert';

/**
 * 並び替え後の並び替え情報を生成する
 * @param orderedColumns 並び替え対象のテーブルのカラム一覧
 * @param fromId 並び替え対象のカラムのID
 * @param toId 移動させる位置にあるカラムのID
 * @returns 並び替え後の並び替え情報
 */
export const recalcColumnOrder = (
  orderedColumns: {
    code: string | null;
  }[],
  fromId: string | undefined,
  toId: string | undefined
) => {
  if (fromId == null || toId == null || fromId === toId) {
    return [];
  }
  const fromIndex = orderedColumns.findIndex(
    (column) => column.code === fromId
  );
  const toIndex = orderedColumns.findIndex((column) => column.code === toId);

  // columns を desiredSort の順に並び替える
  const resultColumns = insert(orderedColumns, fromIndex, toIndex);

  return resultColumns
    .map((colmun) => colmun.code)
    .filter((code) => code != null) as string[];
};

/**
 * 並び替え情報を元にテーブルデータを並び替える
 * @param desiredOrderCode 並び替え情報
 * @param columns テーブルのカラム
 * @param rows テーブルのデータ部分
 * @returns 並び替えたテーブルデータ
 */
export const getOrderedTableData = <
  T extends Row[] | DataDai['data']['rows'],
  K extends {
    code: string | null;
    columns?: { code: string | null }[];
  }[]
>(
  desiredOrderCode: string[],
  columns: K,
  rows: T
): { rows: T; columns: K } => {
  // TODO:
  // お気に入り適用時、fetch完了前のcolumnsには存在していないことがあり
  // そのタイミングでエラーをスローしています。再render時にこの問題は解消されますが
  // 1. 使用側でこの関数を呼び出すタイミングを考慮する
  // 2. undefinedが戻されることを考慮する
  // などの対応をしたほうが良いかもしれません。
  try {
    const orderedColumns = desiredOrderCode.map((code) => {
      const index = columns.findIndex((column) => column.code === code);

      if (index === -1) {
        throw new Error('並び替え対象のカラムIDが存在しません');
      }

      return columns[index];
    }) as K;

    const rowDesiredOrderCodes = desiredOrderCode.reduce<(string | null)[]>(
      (prev, current) => {
        const column = columns.find((column) => column.code === current);

        if (!column) {
          throw new Error('並び替え対象のカラムIDが存在しません');
        }

        // 一つの列で複数のアイテムを所持する場合、
        // 子カラムのnameに差し替えてflatな構造の配列にする
        if (column?.columns) {
          const columnNames = column.columns.map((val) => val.code);
          return [...prev, ...columnNames];
        }
        return [...prev, column.code];
      },
      []
    );

    const rowDesiredOrderIndexes = rowDesiredOrderCodes.map<number>((code) => {
      let count = 0;
      for (const column of columns) {
        if (column.code === code) {
          return count;
        }
        if (column.columns) {
          for (const childColumn of column.columns) {
            if (childColumn.code === code) {
              return count;
            }
            count++;
          }
          continue;
        }
        count++;
      }

      throw new Error('並び替え対象のカラムIDが存在しません');
    });

    const orderedRows = rows.map((row) => {
      return {
        ...row,
        data: rowDesiredOrderIndexes.map((i) => row.data[i]),
      };
    }) as T;

    return {
      columns: orderedColumns,
      rows: orderedRows,
    };
  } catch {
    return { columns, rows };
  }
};
