import { AxiosResponse } from 'axios';
import { call, fork, put, takeEvery } from 'redux-saga/effects';

import { DataTerminalTransition } from '../domain/dataTerminalTransition';

import {
  DataTerminalTransitionActionCreators,
  DataTerminalTransitionActionTypes,
  FetchDataTerminalTransitionAction,
} from '../redux/server/dataTerminalTransition';
import { Api, buildConfig } from '../utils/api';
import { handleErrorSaga } from './errorSaga';
import { changeColumnsOrderSaga } from './terminalTransitionReportsSettingSaga';

export function* fetchDataTerminalTransitionSaga(
  api: Api,
  action: FetchDataTerminalTransitionAction
) {
  try {
    yield put(
      DataTerminalTransitionActionCreators.fetchDataTerminalTransitionRequestAction()
    );

    const response: AxiosResponse<DataTerminalTransition> = yield call(
      api.get,
      '/data/terminalTransition',
      buildConfig(action.payload.params)
    );

    yield put(
      DataTerminalTransitionActionCreators.fetchDataTerminalTransitionSuccessAction(
        response.data
      )
    );

    yield fork(changeColumnsOrderSaga);
  } catch (error: unknown) {
    yield put(
      DataTerminalTransitionActionCreators.renewDataTerminalTransitionAction()
    );
    yield fork(handleErrorSaga, error);
  }
}

function* handleFetchDataTerminalTransitionSaga(api: Api) {
  yield takeEvery(
    DataTerminalTransitionActionTypes.FETCH_DATA_TERMINAL_TRANSITION,
    fetchDataTerminalTransitionSaga,
    api
  );
}

export function* dataTerminalTransitionSagas(context: { api: Api }) {
  yield fork(handleFetchDataTerminalTransitionSaga, context.api);
}
