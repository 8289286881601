/**
 * [SIS機種レポート] 過去に遡れる限界の日付
 *
 * @todo 週の場合、この日付より遡れるため対応が必要？ https://github.com/DKClaris/claris-general/issues/4427
 */
export const pastLimitDate = '2021-09-01';

/**
 * [SIS機種レポート] 期間の一覧
 */
export const sisDateRanges = [
  {
    name: '最新日',
  },
  {
    name: '最新週',
  },
  {
    name: 'カスタム',
  },
] as const;

/**
 * [SIS機種レポート] 期間単位の一覧
 */
export const sisDateUnits = [
  {
    name: '日',
  },
  {
    name: '週',
  },
] as const;
