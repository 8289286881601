import { AxiosResponse } from 'axios';
import {
  call,
  fork,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import { DataKi, DataKiData, DataKiParams } from '../domain/dataKi';
import {
  Column,
  KiTagListOptions,
  LoadingState,
  QueryParameter,
  Row,
} from '../domain/schemas';
import { SettingsOptionsKi } from '../domain/settingsOptionsKi';
import { ShuOption } from '../domain/shu';

import {
  ChangeDataKiColumnsOrderAction,
  DataKiActionCreators,
  DataKiActionTypes,
  FetchDataKiAction,
  FetchDataKiOnlyOneKiTagAverageRequestAction,
  dataKiColumnsOrderSelector,
  dataKiDataColumnsSelector,
  dataKiFilteredKiTagAverageTagsValueSelector,
  dataKiOrderedDataSelector,
  dataKiParamsSelector,
  dataKiSelectedKiListSelector,
  dataKiSelector,
} from '../redux/server/dataKi';
import {
  SettingsOptionsKiActionTypes,
  settingsOptionsKiLoadingSelector,
  settingsOptionsKiSelector,
  settingsOptionsKiTagListSelector,
} from '../redux/server/settingsOptionsKi';
import {
  modelReportsSelectedCurrentShuSelector,
  modelReportsSelectedShuSelector,
  selectModelReportsCurrentShuAction,
  selectModelReportsShuAction,
} from '../redux/ui/modelReportsSetting';
import { Api, buildConfig } from '../utils/api';
import { recalcColumnOrder } from '../utils/orderedCell';
import { retrieveMatchedIdLists } from '../utils/retrieveMatchedIdLists';
import {
  convertShuOption,
  existShuOption,
  removeDuplicateShuOptions,
  selectShu2SearchCondition,
} from '../utils/shu';
import { validateQuery } from '../utils/validateQuery';
import { handleErrorSaga } from './errorSaga';

// dataKiのデータを取得する
export function* fetchDataKiSaga(api: Api, action: FetchDataKiAction) {
  try {
    yield put(DataKiActionCreators.resetDataKiOnlyOneKiTagAverageAction());
    yield put(DataKiActionCreators.fetchDataKiRequestAction());
    const params: DataKiParams = {
      ...action.payload.params, // 変更後の検索条件
      ...(action.payload.currentShu
        ? selectShu2SearchCondition(action.payload.currentShu)
        : {}), // 変更後の種別・種別グループ
      halls: action.payload.currentHalls, //変更後の店舗
    };

    validateQuery(params);

    const response: AxiosResponse<DataKi> = yield call(
      api.get,
      '/data/ki',
      buildConfig(params)
    );
    const dataKi = response.data;

    yield put(DataKiActionCreators.fetchDataKiSuccessAction(dataKi));

    // チェックした機種で絞り込みしているとき、
    // 絞り込んだ機種のみの平均合計行を取得する
    const modelsFilteredByChecks: string[] = yield select(
      dataKiSelectedKiListSelector
    );
    if (modelsFilteredByChecks.length > 0) {
      yield put(
        DataKiActionCreators.fetchDataKiFilteredAverageRequestAction(
          params,
          modelsFilteredByChecks
        )
      );
    }

    const data: {
      data: {
        rows: Row<QueryParameter>[];
        columns: Column[];
      };
      setting: DataKiParams;
    } = yield select(dataKiSelector);

    // 機種集計のsearchConditionの検索条件を取得
    // 機種タグ設定画面で登録された機種タグ一覧を抽出するため
    const kiTagList: KiTagListOptions[] = yield select(
      settingsOptionsKiTagListSelector
    );
    // 選択された機種タグを抽出
    const fetchedSelectedKiTags: KiTagListOptions[] = yield select(
      dataKiFilteredKiTagAverageTagsValueSelector
    );
    // 選択されたタグに紐づく機種とdata/kiで設定されている機種の比較と抽出
    const mergedKiLists = kiTagList
      ? retrieveMatchedIdLists(kiTagList, fetchedSelectedKiTags)
      : [];

    // データに存在する機種のみをリクエストする
    // 不要なkiListを送信するとURLが長くなり414エラーとなるため
    const dataKiList = data.data.rows
      .filter((item) => item.queryParameter.name === 'kiList')
      .map((item) => item.queryParameter.value);

    const resultKiList = dataKiList.filter((item) =>
      mergedKiLists.includes(item)
    );

    // 以下はタグ別平均行用
    // fetchedSelectedKiTags毎にretrieveMatchedIdListsを実行して、配列で管理する
    const kiListByTags = fetchedSelectedKiTags.map((item) => {
      return kiTagList ? retrieveMatchedIdLists(kiTagList, [item]) : [];
    });

    const filteredKiListByTags = kiListByTags.map((item) => {
      return dataKiList.filter((dataItem) => item.includes(dataItem));
    });

    // 機種タグ絞り込みで機種行が、DKSISやマーキング、表示項目などで変更されたとき
    // 機種タグに紐づいた機種の平均合計行を取得する
    if (resultKiList.length > 0) {
      yield put(
        DataKiActionCreators.fetchDataKiFilteredKiTagAverageRequestAction(
          params,
          resultKiList
        )
      );
      for (let i = 0; i < filteredKiListByTags.length; i++) {
        const tag = fetchedSelectedKiTags.at(i);
        if (tag?.tagId != null) {
          yield put(
            DataKiActionCreators.fetchDataKiOnlyOneKiTagAverageRequestAction(
              params,
              filteredKiListByTags[i],
              tag.tagId.toString()
            )
          );
        }
      }
    }
  } catch (error: unknown) {
    yield put(DataKiActionCreators.renewDataKi());
    yield fork(handleErrorSaga, error);
  }
}

// チェックされた機種のみの平均合計行データを取得する
function* fetchDataKiFilteredAverageSaga(api: Api, action: FetchDataKiAction) {
  try {
    const params = {
      ...action.payload.params,
      containsData: false,
    };

    validateQuery(params);

    const response: AxiosResponse<DataKi> = yield call(
      api.get,
      '/data/ki',
      buildConfig(params)
    );
    const dataKi = response.data;

    // 機種タグ設定 機種タグ条件のリセット処理
    yield put(DataKiActionCreators.resetDataKiFilteredKiTagAverageAction());

    // タグ別平均行のリセット処理
    yield put(DataKiActionCreators.resetDataKiOnlyOneKiTagAverageAction());

    yield put(
      DataKiActionCreators.fetchDataKiFilteredAverageSuccessAction(dataKi)
    );
  } catch (error: unknown) {
    yield put(DataKiActionCreators.renewDataKi());
    yield fork(handleErrorSaga, error);
  }
}

// 機種タグを選択した機種のみの平均合計行データを取得する
function* fetchDataKiFilteredKiTagAverageSaga(
  api: Api,
  action: FetchDataKiAction
) {
  try {
    const params = {
      ...action.payload.params,
      containsData: false,
    };

    validateQuery(params);

    const response: AxiosResponse<DataKi> = yield call(
      api.get,
      '/data/ki',
      buildConfig(params)
    );
    const dataKi = response.data;

    yield put(
      DataKiActionCreators.fetchDataKiFilteredKiTagAverageSuccessAction(dataKi)
    );
  } catch (error: unknown) {
    yield put(DataKiActionCreators.renewDataKi());
    yield fork(handleErrorSaga, error);
  }
}

// 機種タグを選択した機種のみの平均合計行データを取得する（タグ別平均行用）
function* fetchDataKiOnlyOneKiTagAverageSaga(
  api: Api,
  action: FetchDataKiOnlyOneKiTagAverageRequestAction
) {
  try {
    const params = { ...action.payload.params, containsData: false };

    validateQuery(params);

    const response: AxiosResponse<DataKi> = yield call(
      api.get,
      '/data/ki',
      buildConfig(params)
    );
    const dataKi = response.data;

    yield put(
      DataKiActionCreators.fetchDataKiOnlyOneKiTagAverageSuccessAction(dataKi)
    );
  } catch (error: unknown) {
    yield put(DataKiActionCreators.renewDataKi());
    yield fork(handleErrorSaga, error);
  }
}

/**
 * 表示項目が変更されている場合並び替えに反映する
 * @param shuCode 種別コード
 */
function* columnsOrderCheckSaga() {
  const fields: Column[] = yield select(dataKiDataColumnsSelector);

  const fieldCodes = fields.map((field) => field.code);

  // テーブル列の並び順
  const nowColumnsOrder: string[] | undefined = yield select(
    dataKiColumnsOrderSelector
  );

  // 並び替え未設定時はデフォルトを使用
  const columnsOrder =
    !nowColumnsOrder || nowColumnsOrder.length === 0
      ? fieldCodes
      : nowColumnsOrder;

  // ColumnsOrderとAPIとの差分の配列（SIS項目）
  const sisArray = fieldCodes.filter((item) => {
    return columnsOrder.indexOf(item) === -1 && ~item.indexOf('sis');
  });

  // ColumnsOrderとAPIとの差分の配列（差）
  const diffArray = fieldCodes.filter((item) => {
    return columnsOrder.indexOf(item) === -1 && ~item.indexOf('Diff');
  });

  // ColumnsOrderとAPIとの差分の配列（比率）
  const hirituArray = fieldCodes.filter((item) => {
    return columnsOrder.indexOf(item) === -1 && ~item.indexOf('Hiritu');
  });

  if (sisArray.length > 0) {
    sisArray.forEach((item) => {
      const target = columnsOrder.find((t) => {
        // 玉粗利のみ例外的にSP玉粗利がDK-SISの対象となるための分岐
        if (
          item.toLowerCase() === 'sistmaarari' &&
          !columnsOrder.includes('spTmaArari')
        ) {
          return false;
        }
        if (t.toLowerCase() === 'tmaarari') {
          return false;
        }
        if (
          t.toLowerCase() === 'sptmaarari' &&
          item.toLowerCase() === 'sistmaarari'
        ) {
          return true;
        }
        // ここまで
        return `sis${t.toLowerCase()}` === item.toLowerCase();
      });
      if (target) {
        const idx = columnsOrder.indexOf(target);
        return columnsOrder.splice(idx + 1, 0, item);
      }
      return target;
    });

    diffArray.forEach((item) => {
      const target = columnsOrder.find((t) => `${t}Diff` === item);
      if (target) {
        const idx = columnsOrder.indexOf(target);
        return columnsOrder.splice(idx + 1, 0, item);
      }
      return target;
    });

    hirituArray.forEach((item) => {
      const target = columnsOrder.find((t) => `${t}Hiritu` === item);
      if (target) {
        const idx = columnsOrder.indexOf(target);
        if (~columnsOrder.indexOf(`${target}Diff`)) {
          columnsOrder.splice(idx + 2, 0, item);
        }
        columnsOrder.splice(idx + 1, 0, item);
      }
    });
  }

  const sorted = [...fieldCodes].sort((a, b) => {
    // 機種名は常に先頭
    if (b === fieldCodes[0]) {
      return 1;
    }

    return columnsOrder.indexOf(b) > columnsOrder.indexOf(a) ? -1 : 1;
  });

  yield put(DataKiActionCreators.selectDataKiColumnsOrderAction(sorted));
}

/**
 * ドラッグ＆ドロップしたセルのIDを元に並び替え情報を登録する
 */
function* changeDataKiColumnsOrderSaga(action: ChangeDataKiColumnsOrderAction) {
  const tableData: DataKiData = yield select(dataKiOrderedDataSelector);

  const ordered = recalcColumnOrder(
    tableData.columns,
    action.payload.draggedId,
    action.payload.droppedId
  );

  yield put(DataKiActionCreators.selectDataKiColumnsOrderAction(ordered));
}

/**
 * リクエストした種別のデータとレスポンスの種別データが不一致だったときに、レスポンスの値で上書きする
 *
 * 種別の不一致は種別が削除されたとき、あるいは共有グループをまたいだお気に入りで発生する
 */
function* validateShuSaga() {
  const currentShu: ShuOption = yield select(
    modelReportsSelectedCurrentShuSelector
  );
  // currentShuが存在しない場合には初期リクエストになるため何もしない
  if (currentShu == null) {
    return;
  }

  const setting: DataKiParams = yield select(dataKiParamsSelector);

  const exist = existShuOption(setting, currentShu);
  if (exist) {
    return;
  }

  const settingsOptionsLoadingState: LoadingState = yield select(
    settingsOptionsKiLoadingSelector
  );

  if (settingsOptionsLoadingState !== 'loaded') {
    yield take(SettingsOptionsKiActionTypes.FETCH_SETTINGS_OPTIONS_KI_SUCCESS);
  }
  // FETCH_SETTINGS_OPTIONS_KI_SUCCESSを待つのでundefinedにならない
  const settingsOptions: SettingsOptionsKi = yield select(
    settingsOptionsKiSelector
  );

  const selectedShu: ShuOption[] = yield select(
    modelReportsSelectedShuSelector
  );

  const shuOption = convertShuOption(
    setting,
    settingsOptions.searchCondition.shuGroupList
  );

  if (shuOption == null) {
    // 変換先が存在しない種別になる場合何もしない
    // setting.shuGroupIdが0の場合に発生します
    return;
  }

  // currentShuの変更
  yield put(selectModelReportsCurrentShuAction(shuOption));

  // selectedShuの変更
  if (selectedShu.length > 0) {
    const validatedSelectedShu = removeDuplicateShuOptions([
      shuOption,
      ...selectedShu.filter((items) => items.code !== currentShu.code),
    ]);

    yield put(selectModelReportsShuAction(validatedSelectedShu));
  }
}

function* handleFetchDataKiSaga(api: Api) {
  yield takeEvery(DataKiActionTypes.FETCH_DATA_KI, fetchDataKiSaga, api);
  // 表示項目と並び替えのチェック
  yield takeEvery(
    DataKiActionTypes.FETCH_DATA_KI_SUCCESS,
    columnsOrderCheckSaga
  );

  yield takeEvery(DataKiActionTypes.FETCH_DATA_KI_SUCCESS, validateShuSaga);
}

function* handleSearchSaga() {
  // 並び替え時
  yield takeEvery(
    DataKiActionTypes.CHANGE_DATA_KI_COLUMNS_ORDER,
    changeDataKiColumnsOrderSaga
  );
}

function* handleFetchDataKiFilteredAverageSaga(api: Api) {
  yield takeLatest(
    DataKiActionTypes.FETCH_DATA_KI_FILTERED_AVERAGE_REQUEST,
    fetchDataKiFilteredAverageSaga,
    api
  );
}

function* handleFetchDataKiFilteredKiTagAverageSaga(api: Api) {
  yield takeEvery(
    DataKiActionTypes.FETCH_DATA_KI_FILTERED_KI_TAG_AVERAGE_REQUEST,
    fetchDataKiFilteredKiTagAverageSaga,
    api
  );
}

function* handleFetchDataKiOnlyOneKiTagAverageSaga(api: Api) {
  yield takeEvery(
    DataKiActionTypes.FETCH_DATA_KI_ONLY_ONE_KI_TAG_AVERAGE_REQUEST,
    fetchDataKiOnlyOneKiTagAverageSaga,
    api
  );
}

export function* dataKiSagas(context: { api: Api }) {
  yield fork(handleFetchDataKiSaga, context.api);
  yield fork(handleSearchSaga);
  yield fork(handleFetchDataKiFilteredAverageSaga, context.api);
  yield fork(handleFetchDataKiFilteredKiTagAverageSaga, context.api);
  yield fork(handleFetchDataKiOnlyOneKiTagAverageSaga, context.api);
}
