import { ComparativeSection } from './comparativeSectionUtil';
import { ReportsDateRange } from './reportsDateRange';

/**
 * dateRangeとcomparisonSectionの整合性をチェックして、適切なcomparisonSectionを返す
 *
 * `ComparativeSection | undefined`からundefinedを除外したいときに使用してください
 * 前回選択していた状態があれば保持、もしなければデフォルト値となる値を戻す動作になるため
 * 具体例としては古いお気に入りでcomparativeSectionがない場合の対応に使用します
 */
export const findComparativeSection = (
  dateRange: ReportsDateRange,
  comparisonSection: ComparativeSection | undefined
): ComparativeSection => {
  switch (dateRange) {
    case '前日':
    case '当日': {
      if (comparisonSection === '前日' || comparisonSection === '前週同曜日') {
        return comparisonSection;
      }
      return '前週同曜日' as const;
    }
    case '前週':
    case '今週': {
      return '前週' as const;
    }
    case '前月':
    case '今月': {
      if (comparisonSection === '前月' || comparisonSection === '前年同月') {
        return comparisonSection;
      }
      return '前月' as const;
    }
    default: {
      return comparisonSection ?? ('カスタム' as const);
    }
  }
};
