/**
 * [モード別集計] 期間タイプ
 */
export const modeDateRanges = [
  {
    name: '前週',
    comparisonSection: ['前週', '平日/土日祝比較'],
    defaultComparisonSection: '前週',
  },
  {
    name: '今週',
    comparisonSection: ['前週', '平日/土日祝比較'],
    defaultComparisonSection: '前週',
  },
  {
    name: '前月',
    comparisonSection: ['前月', '前年同月', '平日/土日祝比較'],
    defaultComparisonSection: '前月',
  },
  {
    name: '今月',
    comparisonSection: ['前月', '前年同月', '平日/土日祝比較'],
    defaultComparisonSection: '前月',
  },
  {
    name: '過去7日',
    comparisonSection: ['過去7日', '平日/土日祝比較'],
    defaultComparisonSection: '過去7日',
  },
  {
    name: '過去14日',
    comparisonSection: ['過去14日', '平日/土日祝比較'],
    defaultComparisonSection: '過去14日',
  },
  {
    name: '過去28日',
    comparisonSection: ['過去28日', '平日/土日祝比較'],
    defaultComparisonSection: '過去28日',
  },
  {
    name: '過去60日',
    comparisonSection: ['過去60日', '平日/土日祝比較'],
    defaultComparisonSection: '過去60日',
  },
  {
    name: 'カスタム',
    comparisonSection: ['カスタム'],
    defaultComparisonSection: 'カスタム',
  },
] as const;

/**
 * [モード別集計] 日付の単位
 */
export const modeDateUnits = [
  {
    name: '範囲選択',
    comparisonSection: ['カスタム', '平日/土日祝比較'],
    defaultComparisonSection: 'カスタム',
  },
  {
    name: '自由選択',
    comparisonSection: ['カスタム', '平日/土日祝比較'],
    defaultComparisonSection: 'カスタム',
  },
] as const;
