import { createSelector } from 'reselect';

import { ClarisApiError } from '../../domain/error';
import { LoadingState } from '../../domain/schemas';
import { SettingsOptionsPlanProgressTransition } from '../../domain/settingsOptionsPlanProgressTransition';

import { RootState } from '../../store';
import { settingsOptionsPlanProgressTransitionDefaultValue } from '../../utils/settingsOptionsPlanProgressTransitionDefaultValue';
import { dataPlanProgressTransitionSettingAreaSelector } from './dataPlanProgressTransition';

/* ---------------------------------------------------------------
 * Action Types
 */

const INIT_SETTINGS_OPTIONS_PLAN_PROGRESS_TRANSITION = 'INIT_SETTINGS_OPTIONS_PLAN_PROGRESS_TRANSITION' as const;
const FETCH_SETTINGS_OPTIONS_PLAN_PROGRESS_TRANSITION = 'FETCH_SETTINGS_OPTIONS_PLAN_PROGRESS_TRANSITION' as const;
const FETCH_SETTINGS_OPTIONS_PLAN_PROGRESS_TRANSITION_REQUEST = 'FETCH_SETTINGS_OPTIONS_PLAN_PROGRESS_TRANSITION_REQUEST' as const;
const FETCH_SETTINGS_OPTIONS_PLAN_PROGRESS_TRANSITION_SUCCESS = 'FETCH_SETTINGS_OPTIONS_PLAN_PROGRESS_TRANSITION_SUCCESS' as const;
const RENEW_SETTINGS_OPTIONS_PLAN_PROGRESS_TRANSITION = 'RENEW_SETTINGS_OPTIONS_PLAN_PROGRESS_TRANSITION' as const;

export const SettingsOptionsPlanProgressTransitionActionTypes = {
  INIT_SETTINGS_OPTIONS_PLAN_PROGRESS_TRANSITION,
  FETCH_SETTINGS_OPTIONS_PLAN_PROGRESS_TRANSITION,
  FETCH_SETTINGS_OPTIONS_PLAN_PROGRESS_TRANSITION_REQUEST,
  FETCH_SETTINGS_OPTIONS_PLAN_PROGRESS_TRANSITION_SUCCESS,
  RENEW_SETTINGS_OPTIONS_PLAN_PROGRESS_TRANSITION,
};

/* ---------------------------------------------------------------
 * Action Creators
 */

function initSettingsOptionsPlanProgressTransitionAction() {
  return {
    type: INIT_SETTINGS_OPTIONS_PLAN_PROGRESS_TRANSITION,
  };
}

function fetchSettingsOptionsPlanProgressTransitionAction() {
  return {
    type: FETCH_SETTINGS_OPTIONS_PLAN_PROGRESS_TRANSITION,
  };
}

function fetchSettingsOptionsPlanProgressTransitionRequestAction() {
  return {
    type: FETCH_SETTINGS_OPTIONS_PLAN_PROGRESS_TRANSITION_REQUEST,
  };
}

function fetchSettingsOptionsPlanProgressTransitionSuccessAction(
  settingsOptionsPlanProgressTransition: SettingsOptionsPlanProgressTransition
) {
  return {
    type: FETCH_SETTINGS_OPTIONS_PLAN_PROGRESS_TRANSITION_SUCCESS,
    payload: { settingsOptionsPlanProgressTransition },
  };
}

function renewSettingsOptionsPlanProgressTransitionAction() {
  return {
    type: RENEW_SETTINGS_OPTIONS_PLAN_PROGRESS_TRANSITION,
  };
}

export const SettingsOptionsPlanProgressTransitionActionCreators = {
  initSettingsOptionsPlanProgressTransitionAction,
  fetchSettingsOptionsPlanProgressTransitionAction,
  fetchSettingsOptionsPlanProgressTransitionRequestAction,
  fetchSettingsOptionsPlanProgressTransitionSuccessAction,
  renewSettingsOptionsPlanProgressTransitionAction,
};

/* ---------------------------------------------------------------
 * Actions
 */

type SettingsOptionsPlanProgressTransitionAction =
  | ReturnType<typeof initSettingsOptionsPlanProgressTransitionAction>
  | ReturnType<typeof fetchSettingsOptionsPlanProgressTransitionAction>
  | ReturnType<typeof fetchSettingsOptionsPlanProgressTransitionRequestAction>
  | ReturnType<typeof fetchSettingsOptionsPlanProgressTransitionSuccessAction>
  | ReturnType<typeof renewSettingsOptionsPlanProgressTransitionAction>;

/* ---------------------------------------------------------------
 * State
 */

type SettingsOptionsPlanProgressTransitionState = {
  loadingState: LoadingState;
  settingsOptionsPlanProgressTransition?: SettingsOptionsPlanProgressTransition;
  error?: ClarisApiError;
};

const initialState: SettingsOptionsPlanProgressTransitionState = {
  loadingState: 'prepare',
  settingsOptionsPlanProgressTransition: undefined,
  error: undefined,
};

/* ---------------------------------------------------------------
 * Selector
 */

// settingsOptionsを全て取得する
export const settingsOptionsPlanProgressTransitionsettingsOptionsSelector = (
  state: RootState
) => {
  const settings =
    state.settingsOptionsPlanProgressTransition
      .settingsOptionsPlanProgressTransition;
  // MEMO: コンポーネント側がundefined許容しないため、空の場合空データを返す
  return settings
    ? settings
    : settingsOptionsPlanProgressTransitionDefaultValue;
};

// 店舗一覧を取得する
export const settingsOptionsPlanProgressTransitionSelector = (
  state: RootState
) => {
  const settingsOptionsPlanProgressTransition =
    state.settingsOptionsPlanProgressTransition
      .settingsOptionsPlanProgressTransition;

  return settingsOptionsPlanProgressTransition
    ? settingsOptionsPlanProgressTransition.searchCondition
    : settingsOptionsPlanProgressTransitionDefaultValue.searchCondition;
};

// APIで取得した検索条件を取得する
export const settingsOptionsPlanProgressTransitionSearchConditionSelector = (
  state: RootState
) => {
  const settingsOptionsPlanProgressTransition =
    state.settingsOptionsPlanProgressTransition
      .settingsOptionsPlanProgressTransition;

  return settingsOptionsPlanProgressTransition
    ? settingsOptionsPlanProgressTransition.searchCondition
    : settingsOptionsPlanProgressTransitionDefaultValue.searchCondition;
};

export const settingsOptionsPlanProgressTransitionLoadingStateSelector = (
  state: RootState
) => {
  return state.settingsOptionsPlanProgressTransition.loadingState;
};

/**
 * 検索されたエリアのホール一覧を取得する
 */
export const settingsOptionsPlanProgressTransitionSearchConditionHallsBySearchedAreaSelector = createSelector(
  [
    settingsOptionsPlanProgressTransitionSearchConditionSelector,
    dataPlanProgressTransitionSettingAreaSelector,
  ],
  ({ halls, areas }, selectedAreaIds) => {
    if (!selectedAreaIds) {
      return halls ?? [];
    }
    const hallCodesInAreas = areas
      .filter((x) => selectedAreaIds.includes(x.id))
      .flatMap((x) => x.halls);

    return halls.filter((x) => hallCodesInAreas.includes(x.code));
  }
);

/* ---------------------------------------------------------------
 * Reducer
 */

export function settingsOptionsPlanProgressTransitionReducer(
  state = initialState,
  action: SettingsOptionsPlanProgressTransitionAction
): SettingsOptionsPlanProgressTransitionState {
  switch (action.type) {
    case FETCH_SETTINGS_OPTIONS_PLAN_PROGRESS_TRANSITION_REQUEST:
      return {
        ...state,
        loadingState: 'loading',
      };
    case FETCH_SETTINGS_OPTIONS_PLAN_PROGRESS_TRANSITION_SUCCESS:
      return {
        ...state,
        loadingState: 'loaded',
        settingsOptionsPlanProgressTransition:
          action.payload.settingsOptionsPlanProgressTransition,
      };
    case RENEW_SETTINGS_OPTIONS_PLAN_PROGRESS_TRANSITION:
      return initialState;
    default:
      return state;
  }
}
