import { OrderType } from '../domain/schemas';
import { SettingsModesDetails } from '../domain/settingsModes';

import { sortBy } from './sortBy';

/**
 * 指定されたフィールドと順序に基づいて、モード設定データをソートします。
 *
 * @param data ソート対象のモード設定データ
 * @param order ソート順序。'asc' は昇順、'desc' は降順
 * @param sort ソート対象のフィールド名
 * @returns ソートされたモード設定データ。データが undefined の場合は undefined を返す
 */
export const getModesSettingsSortedData = (
  data: SettingsModesDetails | undefined,
  order: OrderType,
  sort: string
) => {
  if (!data) {
    return undefined;
  }
  const sortIndex = data.data.columns.findIndex((column) => {
    return column === sort;
  });
  const sortedRows =
    order === 'asc'
      ? sortBy(data.data.rows, (item) => {
          // 台番号の場合は数値としてソートする
          if (sortIndex === 2) {
            return Number(item[sortIndex]);
          }
          return item[sortIndex];
        })
      : sortBy(
          // 降順の場合もnullを一番下にする
          sortBy(data.data.rows, (item) => {
            // 台番号の場合は数値としてソートする
            if (sortIndex === 2) {
              return Number(item[sortIndex]);
            }
            return item[sortIndex];
          }).reverse(),
          (item) => {
            if (item[sortIndex] == null) {
              return 1;
            } else {
              return 0;
            }
          }
        );
  return { data: { ...data.data, rows: sortedRows } };
};
