import { call, fork, put, takeEvery } from 'redux-saga/effects';

import {
  DataSettingsDataDeletionActionCreators,
  DataSettingsDataDeletionActionTypes,
  DeleteDataSettingsDataDeletionAction,
} from '../redux/server/dataSettingsDataDeletion';
import { Api, buildConfig } from '../utils/api';
import { handleErrorSaga } from './errorSaga';

export function* deleteDataSettingsDataDeletionSaga(
  api: Api,
  action: DeleteDataSettingsDataDeletionAction
) {
  try {
    const { hallCode, date } = action.payload;

    yield put(
      DataSettingsDataDeletionActionCreators.deleteDataSettingsDataDeletionRequestAction()
    );

    yield call(
      api.delete,
      `/settings/dataDeletion/${hallCode}/${date}`,
      buildConfig()
    );

    yield put(
      DataSettingsDataDeletionActionCreators.deleteDataSettingsDataDeletionSuccessAction()
    );
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    yield put(
      DataSettingsDataDeletionActionCreators.deleteDataSettingsDataDeletionFailureAction(
        error
      )
    );
    yield fork(handleErrorSaga, error);
  }
}

function* handleDeleteSettingsDataDeletionSaga(api: Api) {
  yield takeEvery(
    DataSettingsDataDeletionActionTypes.DELETE_DATA_SETTINGS_DATADELETION,
    deleteDataSettingsDataDeletionSaga,
    api
  );
}

export function* settingsDataDeletionSagas(context: { api: Api }) {
  yield fork(handleDeleteSettingsDataDeletionSaga, context.api);
}
