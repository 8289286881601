import { useEffect } from 'react';

import { useSetApiErrorState } from '../contexts/ApiErrorContext';

import { isClarisApiError } from '../domain/error';

import { api } from '../utils/api';

/**
 * Axiosのinterceptorsを使用してAPIで発生したエラーの処理をする
 */
export const useInterceptError = () => {
  const setError = useSetApiErrorState();

  useEffect(() => {
    let responseInterceptors: number | undefined;

    const fn = () => {
      responseInterceptors = api.interceptors.response.use(
        (response) => response,
        (error) => {
          if (isClarisApiError(error)) {
            if (error.response?.data != null) {
              setError({
                isError: true,
                data: error.response.data,
              });
            } else {
              // 414などのバックエンド以外でのエラー
              setError({
                isError: true,
                data: {
                  statusCode: error.code?.toString() ?? 'unknown',
                  error: '',
                  message: '',
                },
              });
            }
          }

          throw error;
        }
      );
    };

    fn();

    return () => {
      if (responseInterceptors != null) {
        api.interceptors.response.eject(responseInterceptors);
      }
    };
  }, [setError]);
};
