import { AxiosResponse } from 'axios';
import { call, fork, put, select, takeEvery } from 'redux-saga/effects';

import { LoadingState } from '../domain/schemas';
import { SettingsOptionsEvents } from '../domain/settingsEvents/types';

import {
  SettingsOptionsEventsActionCreators,
  SettingsOptionsEventsActionTypes,
  settingsOptionsEventsLoadingStateSelector,
} from '../redux/server/settingsOptionsEvents';
import { Api, buildConfig } from '../utils/api';
import { handleErrorSaga } from './errorSaga';

function* initSettingsOptionsEventsSaga(api: Api) {
  const loadingState: LoadingState = yield select(
    settingsOptionsEventsLoadingStateSelector
  );

  if (loadingState === 'prepare') {
    yield put(
      SettingsOptionsEventsActionCreators.fetchSettingsOptionsEventsAction()
    );
  }
}

function* fetchSettingsOptionsEventsSaga(api: Api) {
  try {
    yield put(
      SettingsOptionsEventsActionCreators.fetchSettingsOptionsEventsRequestAction()
    );
    const response: AxiosResponse<SettingsOptionsEvents> = yield call(
      api.get,
      '/settings/options/events',
      buildConfig()
    );

    yield put(
      SettingsOptionsEventsActionCreators.fetchSettingsOptionsEventsSuccessAction(
        response.data
      )
    );
  } catch (error: unknown) {
    yield put(
      SettingsOptionsEventsActionCreators.renewSettingsOptionsEventsAction()
    );
    yield fork(handleErrorSaga, error);
  }
}

function* handleFetchSettingsOptionsEventsSaga(api: Api) {
  yield takeEvery(
    SettingsOptionsEventsActionTypes.INIT_SETTINGS_OPTIONS_EVENTS,
    initSettingsOptionsEventsSaga,
    api
  );

  yield takeEvery(
    SettingsOptionsEventsActionTypes.FETCH_SETTINGS_OPTIONS_EVENTS,
    fetchSettingsOptionsEventsSaga,
    api
  );
}

export function* settingsOptionsEventsSagas(context: { api: Api }) {
  yield fork(handleFetchSettingsOptionsEventsSaga, context.api);
}
