import { AxiosResponse } from 'axios';
import { format } from 'date-fns';
import { call, fork, put, select, takeEvery } from 'redux-saga/effects';

import { ChainReportsFormConditions } from '../domain/chainReportsFormConditions';
import { PPM_SHARE_GRAPH_DATE_LABEL_TYPE } from '../domain/ppmShare/consistent';
import { PpmShareGraphDateLabelType } from '../domain/ppmShare/types';
import {
  DataPpmShareTransitiveGraph,
  DataPpmShareTransitiveGraphParams,
} from '../domain/ppmShare/types';

import {
  DataPpmShareTransitiveGraphActionCreators,
  DataPpmShareTransitiveGraphActionTypes,
  FetchDataPpmShareTransitiveGraphAction,
  SearchDataPpmShareTransitiveGraphAction,
  SearchDataPpmShareTransitiveGraphDateTypeAction,
  SearchDataPpmShareTransitiveGraphHallAction,
  dataPpmShareTransitiveGraphSearchConditionSelector,
} from '../redux/server/dataPpmShareTransitiveGraph';
import { ppmShareReportsSearchConditionSelector } from '../redux/ui/ppmShareReportsSetting';
import { Api, buildConfig } from '../utils/api';
import { calcStartDateFromDateType } from '../utils/date';
import { selectShu2SearchCondition } from '../utils/shu';
import { handleErrorSaga } from './errorSaga';

// 2週間ごとという定義がAPIに存在しないのでANYに変換する必要がある
const convertDateTypeToApiDateTypeAnyAndDuration = (
  dateType: PpmShareGraphDateLabelType | undefined
) => {
  if (
    dateType === PPM_SHARE_GRAPH_DATE_LABEL_TYPE.TWENTYSIX_WEEKS ||
    !dateType
  ) {
    return {
      dateType: 'any',
      durationForAnyDateType: 14,
    };
  }
  if (dateType === PPM_SHARE_GRAPH_DATE_LABEL_TYPE.THIRTEEN_WEEKS) {
    return {
      dateType: 'weekly',
    };
  }
  if (dateType === PPM_SHARE_GRAPH_DATE_LABEL_TYPE.THIRTEEN_MONTHS) {
    return {
      dateType: 'monthly',
    };
  }
  return {
    dateType: 'any',
  };
};

/**
 * 推移グラフデータ取得処理
 */
function* fetchDataPpmShareTransitiveGraphSaga(
  api: Api,
  action: FetchDataPpmShareTransitiveGraphAction
) {
  const { params, shuCode } = action.payload;
  try {
    yield put(
      DataPpmShareTransitiveGraphActionCreators.fetchDataPpmShareTransitiveGraphRequestAction(
        shuCode
      )
    );
    const response: AxiosResponse<DataPpmShareTransitiveGraph> = yield call(
      api.get,
      '/data/ppmShare/transitiveGraph',
      buildConfig({
        ...params,
        ...convertDateTypeToApiDateTypeAnyAndDuration(params.dateType),
      })
    );
    yield put(
      DataPpmShareTransitiveGraphActionCreators.fetchDataPpmShareTransitiveGraphSuccessAction(
        shuCode,
        response.data
      )
    );
  } catch (error: unknown) {
    yield put(
      DataPpmShareTransitiveGraphActionCreators.renewDataPpmShareTransitiveGraphAction()
    );
    yield fork(handleErrorSaga, error);
  }
}

/**
 * クリックしたセルの推移グラフデータを取得する
 */
function* searchPpmShareTransitiveGraphSaga(
  action: SearchDataPpmShareTransitiveGraphAction
) {
  const { halls, shuOption } = action.payload;
  const shuCode = shuOption?.code ?? 'null';

  // 検索フォームの検索条件
  const searchCondition: ChainReportsFormConditions = yield select(
    ppmShareReportsSearchConditionSelector
  );

  // 取得済みのグラフの検索条件
  const graphSearchCondition: DataPpmShareTransitiveGraphParams = yield select(
    dataPpmShareTransitiveGraphSearchConditionSelector
  );
  const { halls: prevHalls, ...rest } = graphSearchCondition;

  const newGraphSearchCondition = {
    ...rest,
    // hallsが[null]の場合は平均/合計行なのでキー自体をなくす
    ...(!halls || halls[0] === null ? {} : { halls }),
  };

  // 開始日をグラフの期間に再計算
  const startDate = calcStartDateFromDateType(
    graphSearchCondition?.dateType,
    new Date(searchCondition?.endDate ?? new Date())
  );

  yield put(
    DataPpmShareTransitiveGraphActionCreators.fetchDataPpmShareTransitiveGraphAction(
      shuCode,
      {
        ...newGraphSearchCondition,
        // MEMO: 種別がある場合は種別コードをセットし、ない場合（店舗全体実績）は種別の指定を空にする
        ...(shuOption ? selectShu2SearchCondition(shuOption) : {}),
        startDate: format(startDate, 'yyyy-MM-dd'),
        endDate: searchCondition.endDate,
        excludeToday: searchCondition.excludeToday,
      }
    )
  );
}

/**
 * 指定した店舗の推移グラフデータを再取得する
 */
function* searchPpmShareTransitiveGraphHallSaga(
  action: SearchDataPpmShareTransitiveGraphHallAction
) {
  const { hall, shuOption } = action.payload;
  const shuCode = shuOption?.code ?? 'null';

  // 取得済みのグラフの検索条件
  const searchCondition: DataPpmShareTransitiveGraphParams = yield select(
    dataPpmShareTransitiveGraphSearchConditionSelector
  );

  yield put(
    DataPpmShareTransitiveGraphActionCreators.fetchDataPpmShareTransitiveGraphAction(
      shuCode,
      {
        ...searchCondition,
        halls: hall === 'null' ? undefined : [hall],
      }
    )
  );
}

/**
 * 指定した期間の推移グラフデータを再取得する
 */
function* searchPpmShareTransitiveGraphDateTypeSaga(
  action: SearchDataPpmShareTransitiveGraphDateTypeAction
) {
  const { dateType, shuOption } = action.payload;
  const shuCode = shuOption?.code ?? 'null';

  // 取得済みのグラフの検索条件
  const searchCondition: DataPpmShareTransitiveGraphParams = yield select(
    dataPpmShareTransitiveGraphSearchConditionSelector
  );

  // 開始日をグラフの期間に再計算
  const startDate = calcStartDateFromDateType(
    dateType,
    new Date(searchCondition?.endDate ?? new Date())
  );

  yield put(
    DataPpmShareTransitiveGraphActionCreators.fetchDataPpmShareTransitiveGraphAction(
      shuCode,
      {
        ...searchCondition,
        startDate: format(startDate, 'yyyy-MM-dd'),
        dateType,
      }
    )
  );
}

function* handleFetchPpmShareTransitiveGraphSaga(api: Api) {
  yield takeEvery(
    DataPpmShareTransitiveGraphActionTypes.FETCH_DATA_PPM_SHARE_TRANSITIVE_GRAPH,
    fetchDataPpmShareTransitiveGraphSaga,
    api
  );
}

function* handleSearchSaga() {
  yield takeEvery(
    DataPpmShareTransitiveGraphActionTypes.SEARCH_DATA_PPM_SHARE_TRANSITIVE_GRAPH,
    searchPpmShareTransitiveGraphSaga
  );
  yield takeEvery(
    DataPpmShareTransitiveGraphActionTypes.SEARCH_DATA_PPM_SHARE_TRANSITIVE_GRAPH_HALL,
    searchPpmShareTransitiveGraphHallSaga
  );
  yield takeEvery(
    DataPpmShareTransitiveGraphActionTypes.SEARCH_DATA_PPM_SHARE_TRANSITIVE_GRAPH_DATE_TYPE,
    searchPpmShareTransitiveGraphDateTypeSaga
  );
}

export function* dataPpmShareTransitiveGraphSagas(context: { api: Api }) {
  yield fork(handleFetchPpmShareTransitiveGraphSaga, context.api);
  yield fork(handleSearchSaga);
}
