import { DataKiParams } from '../domain/dataKi';
import { MAIN_FIELD_TYPE } from '../domain/schemas';

/**
 * 出力タイプに応じて複数fieldsの省略をする
 * 以下PRより、fieldsが増えるケースがあるのでここで考慮
 * https://github.com/DKClaris/claris-client/pull/1080/files
 * MEMO 出力タイプに関わらず、「機種別出力なのにfieldsにtypeなどが入るケース」「SISタイプ出力なのにfieldsにmakerなどが入るケース」がお気に入りを開いたときに発生したため、全出力のfieldsを考慮して重複削除
 * @param params
 * @returns
 */
export const adjustedFields = (params: DataKiParams) =>
  params.fields?.filter(
    (x) =>
      x !== MAIN_FIELD_TYPE.KI_TUSHO_MEI &&
      x !== MAIN_FIELD_TYPE.TYPE &&
      x !== MAIN_FIELD_TYPE.MAKER
  );
