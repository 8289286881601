import { createSelector } from 'reselect';

import {
  AreasSettingsParams,
  AreasSettingsPatchParams,
  AreasSettingsPostParams,
} from '../../domain/dataAreasSettings';
import { LoadingState } from '../../domain/schemas';

// Action Types
const FETCH_DATA_AREAS = 'FETCH_DATA_AREAS' as const;
const FETCH_DATA_AREAS_REQUEST = 'FETCH_DATA_AREAS_REQUEST' as const;
const FETCH_DATA_AREAS_SUCCESS = 'FETCH_DATA_AREAS_SUCCESS' as const;
const RENEW_DATA_AREAS = 'RENEW_DATA_AREAS' as const;
const POST_DATA_AREAS = 'POST_DATA_AREAS' as const;
const POST_DATA_AREAS_REQUEST = 'POST_DATA_AREAS_REQUEST' as const;
const POST_DATA_AREAS_SUCCESS = 'POST_DATA_AREAS_SUCCESS' as const;
const POST_DATA_AREAS_FAILURE = 'POST_DATA_AREAS_FAILURE' as const;
const POST_DATA_AREAS_FAILURE_CLEAR = 'POST_DATA_AREAS_FAILURE_CLEAR' as const;
const DELETE_DATA_AREAS = 'DELETE_DATA_AREAS' as const;
const DELETE_DATA_AREAS_REQUEST = 'DELETE_DATA_AREAS_REQUEST' as const;
const DELETE_DATA_AREAS_SUCCESS = 'DELETE_DATA_AREAS_SUCCESS' as const;
const PATCH_DATA_AREAS = 'PATCH_DATA_AREAS' as const;
const PATCH_DATA_AREAS_REQUEST = 'PATCH_DATA_AREAS_REQUEST' as const;
const PATCH_DATA_AREAS_SUCCESS = 'PATCH_DATA_AREAS_SUCCESS' as const;

export const DataAreasActionTypes = {
  FETCH_DATA_AREAS,
  FETCH_DATA_AREAS_REQUEST,
  FETCH_DATA_AREAS_SUCCESS,
  RENEW_DATA_AREAS,
  POST_DATA_AREAS,
  POST_DATA_AREAS_REQUEST,
  POST_DATA_AREAS_SUCCESS,
  POST_DATA_AREAS_FAILURE,
  POST_DATA_AREAS_FAILURE_CLEAR,
  DELETE_DATA_AREAS,
  DELETE_DATA_AREAS_REQUEST,
  DELETE_DATA_AREAS_SUCCESS,
  PATCH_DATA_AREAS,
  PATCH_DATA_AREAS_REQUEST,
  PATCH_DATA_AREAS_SUCCESS,
};

// Action Creators

// エリア一覧
function fetchDataSettingsAreasAction() {
  return {
    type: FETCH_DATA_AREAS,
  };
}

function fetchDataSettingsAreasRequestAction() {
  return {
    type: FETCH_DATA_AREAS_REQUEST,
  };
}

function fetchDataSettingsAreasSuccessAction(areas: AreasSettingsParams) {
  return {
    type: FETCH_DATA_AREAS_SUCCESS,
    payload: { areas },
  };
}

function renewDataSettingsAreasAction() {
  return {
    type: RENEW_DATA_AREAS,
  };
}

//エリア新規作成
function postDataSettingsAreasAction(params: AreasSettingsPostParams) {
  return {
    type: POST_DATA_AREAS,
    payload: { params },
  };
}

function postDataSettingsAreasRequestAction() {
  return {
    type: POST_DATA_AREAS_REQUEST,
  };
}

function postDataSettingsAreasSuccessAction() {
  return {
    type: POST_DATA_AREAS_SUCCESS,
  };
}

// エリア削除
function deleteDataSettingsAreasAction(areaId: string) {
  return {
    type: DELETE_DATA_AREAS,
    payload: { areaId },
  };
}

function deleteDataSettingsAreasRequestAction() {
  return {
    type: DELETE_DATA_AREAS_REQUEST,
  };
}

function deleteDataSettingsAreasSuccessAction() {
  return {
    type: DELETE_DATA_AREAS_SUCCESS,
  };
}

// エリア編集
function patchDataSettingsAreasAction(
  areaId: string,
  params: AreasSettingsPatchParams
) {
  return {
    type: PATCH_DATA_AREAS,
    payload: { areaId, params },
  };
}

function patchDataSettingsAreasRequestAction() {
  return {
    type: PATCH_DATA_AREAS_REQUEST,
  };
}

function patchDataSettingsAreasSuccessAction() {
  return {
    type: PATCH_DATA_AREAS_SUCCESS,
  };
}

function postDataSettingsAreasFailureAction(error: string) {
  return {
    type: POST_DATA_AREAS_FAILURE,
    payload: { error },
  };
}

function postDataSettingsAreasFailureClearAction() {
  return {
    type: POST_DATA_AREAS_FAILURE_CLEAR,
  };
}

export const DataAreasActionCreators = {
  fetchDataSettingsAreasAction,
  fetchDataSettingsAreasRequestAction,
  fetchDataSettingsAreasSuccessAction,
  renewDataSettingsAreasAction,
  postDataSettingsAreasAction,
  postDataSettingsAreasRequestAction,
  postDataSettingsAreasSuccessAction,
  postDataSettingsAreasFailureAction,
  postDataSettingsAreasFailureClearAction,
  deleteDataSettingsAreasAction,
  deleteDataSettingsAreasRequestAction,
  deleteDataSettingsAreasSuccessAction,
  patchDataSettingsAreasAction,
  patchDataSettingsAreasRequestAction,
  patchDataSettingsAreasSuccessAction,
};

// Actions

type FetchDataSettingsAreasAction = ReturnType<
  typeof fetchDataSettingsAreasAction
>;

export type PostDataSettingsAreasAction = ReturnType<
  typeof postDataSettingsAreasAction
>;

export type DeleteDataSettingsAreasAction = ReturnType<
  typeof deleteDataSettingsAreasAction
>;

export type PatchDataSettingsAreasAction = ReturnType<
  typeof patchDataSettingsAreasAction
>;

type DataAreasAction =
  | FetchDataSettingsAreasAction
  | ReturnType<typeof fetchDataSettingsAreasRequestAction>
  | ReturnType<typeof fetchDataSettingsAreasSuccessAction>
  | ReturnType<typeof renewDataSettingsAreasAction>
  | ReturnType<typeof postDataSettingsAreasAction>
  | ReturnType<typeof postDataSettingsAreasRequestAction>
  | ReturnType<typeof postDataSettingsAreasSuccessAction>
  | ReturnType<typeof postDataSettingsAreasFailureAction>
  | ReturnType<typeof postDataSettingsAreasFailureClearAction>
  | ReturnType<typeof deleteDataSettingsAreasAction>
  | ReturnType<typeof deleteDataSettingsAreasRequestAction>
  | ReturnType<typeof deleteDataSettingsAreasSuccessAction>
  | ReturnType<typeof patchDataSettingsAreasAction>
  | ReturnType<typeof patchDataSettingsAreasRequestAction>
  | ReturnType<typeof patchDataSettingsAreasSuccessAction>;

// State

type DataAreasState = {
  loadingState: LoadingState;
  areas: AreasSettingsParams | undefined;
  postError: string;
};

const initialState: DataAreasState = {
  loadingState: 'prepare',
  areas: undefined,
  postError: '',
};

function dataSettingsAreasAllSelector(rootState: {
  dataSettingsAreas: DataAreasState;
}) {
  return rootState.dataSettingsAreas;
}

export const dataSettingsAreasSelector = createSelector(
  dataSettingsAreasAllSelector,
  (dataSettingsAreas) => dataSettingsAreas.areas
);

export function dataSettingsAreasLoadingSelector(rootState: {
  dataSettingsAreas: DataAreasState;
}) {
  return rootState.dataSettingsAreas.loadingState;
}
export function dataSettingsAreasPostErrorSelector(rootState: {
  dataSettingsAreas: DataAreasState;
}) {
  return rootState.dataSettingsAreas.postError;
}
// Reducer

export function dataAreaReducer(
  state = initialState,
  action: DataAreasAction
): DataAreasState {
  switch (action.type) {
    case PATCH_DATA_AREAS_REQUEST:
    case POST_DATA_AREAS_REQUEST:
    case DELETE_DATA_AREAS_REQUEST:
    case FETCH_DATA_AREAS_REQUEST:
      return {
        ...state,
        loadingState: 'loading',
      };
    case FETCH_DATA_AREAS_SUCCESS:
      return {
        ...state,
        areas: action.payload.areas,
        loadingState: 'loaded',
      };
    case PATCH_DATA_AREAS_SUCCESS:
    case POST_DATA_AREAS_SUCCESS:
    case DELETE_DATA_AREAS_SUCCESS:
      return {
        ...state,
        loadingState: 'loaded',
      };
    case RENEW_DATA_AREAS:
      return initialState;
    case POST_DATA_AREAS_FAILURE:
      return {
        ...state,
        postError: action.payload.error,
      };
    case POST_DATA_AREAS_FAILURE_CLEAR:
      return {
        ...state,
        postError: initialState.postError,
      };
    default:
      return state;
  }
}
