export const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN ?? '';
export const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID ?? '';
export const AUTH0_AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE ?? '';
export const AUTH0_CALLBACK_URL = `${window.location.protocol}//${window.location.host}`;
export const AUTH0_LOGOUT_URL = `${window.location.protocol}//${window.location.host}`;
export const CHATPLUS_URL = 'https://app.chatplus.jp';
export const API_HOST = process.env.REACT_APP_API_HOST || '';

export const gaDebugMode =
  process.env.NODE_ENV === 'development' &&
  process.env.REACT_APP_GA_DEBUG_MODE === 'true';

export const isDebugSentry = process.env.REACT_APP_SENTRY_DEBUG_MODE === 'true';

export const isEnabledSentry =
  process.env.NODE_ENV === 'production' ||
  (process.env.NODE_ENV === 'development' && isDebugSentry);

/**
 * ClarisLinkで基準となる開始日
 * @link https://github.com/DKClaris/claris-general/issues/4153
 */
export const serviceStartDate = '2019-01-01';

export const SIS_TYPE_UPDATE_DATES = {
  v1: '2023-09-14T16:20:00+09:00',
};

// P-Sensorのurl
export const pSensorApiUrl = process.env.REACT_APP_PSENSOR_API_URI;
