import { SettingsOptionsHall } from '../domain/settingsOptionsHall';

// 店舗レポート用設定の選択肢のデフォルトデータ
export const settingsOptionsHallDefaultValue: SettingsOptionsHall = {
  searchCondition: {
    makers: [],
    kiList: [],
    shuGroupList: [],
    shuList: [],
    areas: [],
    halls: [],
    sisTypes: [],
    latestBaseDateForDailySisData: '',
    latestBaseDateForWeeklySisData: '',
    latestBaseDateForMonthlySisData: '',
  },
  fields: {
    shu: {
      shu: [],
    },
    ki: [],
    marketArea: [],
  },
  genres: {
    ki: [],
    shu: {
      shu: [],
    },
  },
  kiCodeToPSensorKiCode: {},
};
