import { ConvertRowsToFilteredRows } from '../../components/organisms/TableHeaderSubMenu/ConvertRowsToFilteredRows';

import { DataSis } from './types';

export const dataFilteredByNumber = (
  dataSis: DataSis,
  numberFilter:
    | {
        [field: string]: {
          minimumNumber: number | undefined;
          maximumNumber: number | undefined;
        };
      }
    | undefined
): DataSis => {
  if (numberFilter == null) {
    return dataSis;
  }
  const { filteredRows: rows } = ConvertRowsToFilteredRows(
    numberFilter,
    dataSis.data.columns,
    dataSis.data.rows
  );

  return {
    ...dataSis,
    data: {
      ...dataSis.data,
      rows,
    },
  };
};
