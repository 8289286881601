import { AxiosResponse } from 'axios';
import { call, fork, put, takeEvery } from 'redux-saga/effects';

import { SettingsOptionsChainStore } from '../domain/settingsOptionsChainStore';

import {
  SettingsOptionsChainStoreActionCreators,
  SettingsOptionsChainStoreActionTypes,
} from '../redux/server/settingsOptionsChainStore';
import { Api, buildConfig } from '../utils/api';
import { handleErrorSaga } from './errorSaga';

export function* fetchSettingsOptionsChainStoreSaga(api: Api) {
  try {
    yield put(
      SettingsOptionsChainStoreActionCreators.fetchSettingsOptionsChainStoreRequestAction()
    );
    const response: AxiosResponse<SettingsOptionsChainStore> = yield call(
      api.get,
      '/settings/options/chainStore',
      buildConfig()
    );
    const settingsOptionsChainStore = response.data;
    yield put(
      SettingsOptionsChainStoreActionCreators.fetchSettingsOptionsChainStoreSuccessAction(
        settingsOptionsChainStore
      )
    );
  } catch (error: unknown) {
    yield put(
      SettingsOptionsChainStoreActionCreators.renewSettingsOptionsChainStoreAction()
    );
    yield fork(handleErrorSaga, error);
  }
}

function* handleFetchSettingsOptionsChainStoreSaga(api: Api) {
  yield takeEvery(
    SettingsOptionsChainStoreActionTypes.FETCH_SETTINGS_OPTIONS_CHAIN_STORE,
    fetchSettingsOptionsChainStoreSaga,
    api
  );
}

export function* settingsOptionsChainStoreSagas(context: { api: Api }) {
  yield fork(handleFetchSettingsOptionsChainStoreSaga, context.api);
}
