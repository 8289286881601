import { filterModelName } from '../../utils/filterModelName';
import { SettingsDaiCostsResponse } from './types';

export const dataDaiCostsFilteredByName = (
  dataDaiCost: SettingsDaiCostsResponse,
  name: string | undefined
): SettingsDaiCostsResponse => {
  if (name == null) {
    return dataDaiCost;
  }

  const predicate = filterModelName(name ?? '');

  const dataDaiCostFilteredByName = dataDaiCost.data.filter(
    (row) => row.kiMei.length > 0 && predicate(row.kiMei)
  );

  return {
    ...dataDaiCost,
    data: dataDaiCostFilteredByName,
  };
};
