import { OrderType } from '../domain/schemas';

/**
 * ソートを実行した時に常に降順→昇順になるように調整する
 * @param sorted ソート済みかどうか
 * @param order ソート順
 */
export function orderParamDefaultDesc(
  sorted: boolean,
  order?: OrderType
): OrderType {
  if (!sorted) return 'desc';
  return order === 'asc' ? 'desc' : 'asc';
}

/**
 * ソート順を入れ替える
 * @param order ソート順
 */
export const swapOrder = (order: OrderType): OrderType => {
  return order === 'desc' ? 'asc' : 'desc';
};

/**
 * 指定されたフィールドに基づいてソート順を変更する
 *
 * @param sort ソート対象のフィールド名
 * @param  current 現在のソート状態。ソートフィールドとソート順を含むオブジェクト
 * @returns 新しいソート順。'asc' または 'desc'
 *
 * @example
 * // 現在のソートが undefined の場合、昇順 ('asc') を返す
 * changeOrder('name', undefined); // 'asc'
 *
 * @example
 * // 現在のソートが 'name' で降順 ('desc') の場合、昇順 ('asc') を返す
 * changeOrder('name', { sort: 'name', order: 'desc' }); // 'asc'
 *
 * @example
 * // 現在のソートが 'name' で昇順 ('asc') の場合、降順 ('desc') を返す
 * changeOrder('name', { sort: 'name', order: 'asc' }); // 'desc'
 */
export const changeOrder = (
  sort: string,
  current:
    | {
        sort?: string;
        order?: OrderType;
      }
    | undefined
): OrderType => {
  // 値がなければデフォルトが降順なので昇順にする
  if (current?.sort == null || current?.order == null) {
    return 'asc';
  }

  // sortが一致しなければデフォルトの降順
  if (sort !== current.sort) {
    return 'desc';
  }

  return current.order === 'desc' ? 'asc' : 'desc';
};
