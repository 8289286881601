import { AxiosResponse } from 'axios';
import { call, fork, put, select, takeEvery } from 'redux-saga/effects';

import {
  KiTagsSettingsKiList,
  KiTagsSettingsKiTags,
  Tag,
} from '../domain/dataKiTagsSettings';

import {
  DataSettingsKiTagsActionCreators,
  DataSettingsKiTagsActionTypes,
  DeleteDataSettingsKiTagsAction,
  FetchDataSettingsKiTagsKiListAction,
  PatchDataSettingsKiTagsAction,
  PatchDataSettingsKiTagsKiListAction,
  PostDataSettingsKiTagsAction,
  dataSettingsKiTagsSelector,
} from '../redux/server/dataSettingsKiTags';
import { kiTagsDateRangeSelector } from '../redux/ui/settingsKiTags';
import { Api, buildConfig } from '../utils/api';
import { moveIndex } from '../utils/moveIndex';
import { handleErrorSaga } from './errorSaga';

export function* fetchDataSettingsKiTagsKiListSaga(
  api: Api,
  action: FetchDataSettingsKiTagsKiListAction
) {
  try {
    yield put(
      DataSettingsKiTagsActionCreators.fetchDataSettingsKiTagsKiListRequestAction()
    );
    const response: AxiosResponse<KiTagsSettingsKiList> = yield call(
      api.get,
      '/settings/kiTags/kiList',
      buildConfig(action.payload.params)
    );
    yield put(
      DataSettingsKiTagsActionCreators.fetchDataSettingsKiTagsKiListSuccessAction(
        response.data
      )
    );
  } catch (error: unknown) {
    yield put(DataSettingsKiTagsActionCreators.renewDataSettingsKiTagsAction());
    yield fork(handleErrorSaga, error);
  }
}

export function* patchDataSettingsKiTagsKiListSaga(
  api: Api,
  action: PatchDataSettingsKiTagsKiListAction
) {
  try {
    const { id, params } = action.payload;
    yield put(
      DataSettingsKiTagsActionCreators.patchDataSettingsKiTagsKiListRequestAction()
    );
    yield call(
      api.patch,
      `/settings/kiTags/kiList/${id}`,
      params,
      buildConfig()
    );

    const {
      startDate,
      endDate,
    }: { startDate: string; endDate: string } = yield select(
      kiTagsDateRangeSelector
    );
    yield put(
      DataSettingsKiTagsActionCreators.fetchDataSettingsKiTagsKiListAction({
        startDate,
        endDate,
      })
    );
    yield put(
      DataSettingsKiTagsActionCreators.patchDataSettingsKiTagsKiListSuccessAction()
    );
  } catch (error: unknown) {
    yield put(DataSettingsKiTagsActionCreators.renewDataSettingsKiTagsAction());
    yield fork(handleErrorSaga, error);
  }
}

export function* fetchDataSettingsKiTagsSaga(api: Api) {
  try {
    yield put(
      DataSettingsKiTagsActionCreators.fetchDataSettingsKiTagsRequestAction()
    );
    const response: AxiosResponse<KiTagsSettingsKiTags> = yield call(
      api.get,
      '/settings/kiTags',
      buildConfig()
    );
    yield put(
      DataSettingsKiTagsActionCreators.fetchDataSettingsKiTagsSuccessAction(
        response.data
      )
    );
  } catch (error: unknown) {
    yield put(DataSettingsKiTagsActionCreators.renewDataSettingsKiTagsAction());
    yield fork(handleErrorSaga, error);
  }
}

export function* patchDataSettingsKiTagsSaga(
  api: Api,
  action: PatchDataSettingsKiTagsAction
) {
  const { tags, sisTypes, autoTags }: KiTagsSettingsKiTags = yield select(
    dataSettingsKiTagsSelector
  );
  const previousTags = [...tags];
  try {
    const { id, params } = action.payload;
    //viewOrderの変更時は楽観的UI実現のためAPI通信より先にstateを変更する
    if (params.viewOrder) {
      const target = tags.find((tag) => tag.tagId === id) ?? ({} as Tag);
      //配列の並び順を変更
      const moveArray = moveIndex(
        tags,
        tags.indexOf(target),
        params.viewOrder - 1
      );
      //配列の並び順にviewOrderを更新
      const optimisticTags = moveArray.map((tag, i) => {
        return { ...tag, viewOrder: i + 1 };
      });
      yield put(
        DataSettingsKiTagsActionCreators.patchDataSettingsKiTagsSuccessAction({
          tags: optimisticTags,
          sisTypes: sisTypes,
          autoTags: autoTags,
        })
      );
    }
    yield call(api.patch, `/settings/kiTags/${id}`, params, buildConfig());
    //タグの情報取得(viewOrderの変更時はfetchしても差分がない想定)
    yield put(DataSettingsKiTagsActionCreators.fetchDataSettingsKiTagsAction());
    //テーブルで選択中のタグの情報を更新するため機種リストを取得
    const {
      startDate,
      endDate,
    }: { startDate: string; endDate: string } = yield select(
      kiTagsDateRangeSelector
    );
    yield put(
      DataSettingsKiTagsActionCreators.fetchDataSettingsKiTagsKiListAction({
        startDate,
        endDate,
      })
    );
  } catch (error: unknown) {
    // APIでの更新が失敗した場合もとの値に戻す
    yield put(
      DataSettingsKiTagsActionCreators.patchDataSettingsKiTagsSuccessAction({
        tags: previousTags,
        sisTypes: sisTypes,
        autoTags: autoTags,
      })
    );
    yield put(DataSettingsKiTagsActionCreators.renewDataSettingsKiTagsAction());
    yield fork(handleErrorSaga, error);
  }
}

export function* postDataSettingsKiTagsSaga(
  api: Api,
  action: PostDataSettingsKiTagsAction
) {
  try {
    const { params } = action.payload;
    yield put(
      DataSettingsKiTagsActionCreators.postDataSettingsKiTagsRequestAction()
    );
    yield call(api.post, '/settings/kiTags', params, buildConfig());
    yield put(DataSettingsKiTagsActionCreators.fetchDataSettingsKiTagsAction());

    // NOTE 自動タグ付与条件は該当機種に自動的にタグを付与させるため、機種タグ設定画面のTOPに即時反映させる必要がある
    // テーブルで選択中のタグの情報を更新するため機種リストを取得する
    if (params.autoTagCode != null) {
      const {
        startDate,
        endDate,
      }: { startDate: string; endDate: string } = yield select(
        kiTagsDateRangeSelector
      );
      yield put(
        DataSettingsKiTagsActionCreators.fetchDataSettingsKiTagsKiListAction({
          startDate,
          endDate,
        })
      );
    }
    yield put(
      DataSettingsKiTagsActionCreators.postDataSettingsKiTagsSuccessAction()
    );
  } catch (error: unknown) {
    yield put(DataSettingsKiTagsActionCreators.renewDataSettingsKiTagsAction());
    yield fork(handleErrorSaga, error);
  }
}

export function* deleteDataSettingsKiTagsSaga(
  api: Api,
  action: DeleteDataSettingsKiTagsAction
) {
  try {
    const { id } = action.payload;
    yield put(
      DataSettingsKiTagsActionCreators.deleteDataSettingsKiTagsRequestAction()
    );
    yield call(api.delete, `/settings/kiTags/${id}`, buildConfig());

    yield put(DataSettingsKiTagsActionCreators.fetchDataSettingsKiTagsAction());
    //テーブルで選択中のタグの情報を更新するため機種リストを取得する
    const {
      startDate,
      endDate,
    }: { startDate: string; endDate: string } = yield select(
      kiTagsDateRangeSelector
    );
    yield put(
      DataSettingsKiTagsActionCreators.fetchDataSettingsKiTagsKiListAction({
        startDate,
        endDate,
      })
    );
    yield put(
      DataSettingsKiTagsActionCreators.deleteDataSettingsKiTagsSuccessAction()
    );
  } catch (error: unknown) {
    yield put(DataSettingsKiTagsActionCreators.renewDataSettingsKiTagsAction());
    yield fork(handleErrorSaga, error);
  }
}

function* handleFetchDataSettingsKiTagsKiListSaga(api: Api) {
  yield takeEvery(
    DataSettingsKiTagsActionTypes.FETCH_DATA_KITAGS_KILIST,
    fetchDataSettingsKiTagsKiListSaga,
    api
  );
}
function* handlePatchDataSettingsKiTagsKiListSaga(api: Api) {
  yield takeEvery(
    DataSettingsKiTagsActionTypes.PATCH_DATA_KITAGS_KILIST_BY_TAGID,
    patchDataSettingsKiTagsKiListSaga,
    api
  );
}
function* handleFetchDataSettingsKiTagsSaga(api: Api) {
  yield takeEvery(
    DataSettingsKiTagsActionTypes.FETCH_DATA_KITAGS,
    fetchDataSettingsKiTagsSaga,
    api
  );
}
function* handlePatchDataSettingsKiTagsSaga(api: Api) {
  yield takeEvery(
    DataSettingsKiTagsActionTypes.PATCH_DATA_KITAGS_BY_TAGID,
    patchDataSettingsKiTagsSaga,
    api
  );
}
function* handlePostDataSettingsKiTagsSaga(api: Api) {
  yield takeEvery(
    DataSettingsKiTagsActionTypes.POST_DATA_KITAGS,
    postDataSettingsKiTagsSaga,
    api
  );
}
function* handleDeleteDataSettingsKiTagsSaga(api: Api) {
  yield takeEvery(
    DataSettingsKiTagsActionTypes.DELETE_DATA_KITAGS_BY_TAGID,
    deleteDataSettingsKiTagsSaga,
    api
  );
}

export function* dataSettingsKiTagsSagas(context: { api: Api }) {
  yield fork(handleFetchDataSettingsKiTagsKiListSaga, context.api);
  yield fork(handlePatchDataSettingsKiTagsKiListSaga, context.api);
  yield fork(handleFetchDataSettingsKiTagsSaga, context.api);
  yield fork(handlePatchDataSettingsKiTagsSaga, context.api);
  yield fork(handlePostDataSettingsKiTagsSaga, context.api);
  yield fork(handleDeleteDataSettingsKiTagsSaga, context.api);
}
