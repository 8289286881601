import { AxiosResponse } from 'axios';
import { call, fork, put, select, takeEvery } from 'redux-saga/effects';

import { DataKi, DataKiParams } from '../domain/dataKi';
import { DataKi2ndRow } from '../domain/dataKi2ndRow';
import { MAIN_FIELD_TYPE, QueryParameter } from '../domain/schemas';

import {
  dataKiModelCodesOfSelectedTagsSelector,
  dataKiParamsSelector,
} from '../redux/server/dataKi';
import {
  DataKi2ndRowActionCreators,
  DataKi2ndRowActionTypes,
  FetchDataKi2ndRowAction,
  SearchDataKi2ndRowAction,
  ToggleDataKi2ndRowAction,
  singleDataKi2ndRowSelector,
} from '../redux/server/dataKi2ndRow';
import { Api, buildConfig } from '../utils/api';
import { validateQuery } from '../utils/validateQuery';
import { handleErrorSaga } from './errorSaga';

/**
 * QueryParameterを元に検索条件を加工する
 * @param queryParams QueryParameter
 * @param searchParams 機種集計の検索条件
 * @returns 加工された検索条件
 */
export const queryParameterToFormConditions = (
  queryParams: QueryParameter,
  searchParams: DataKiParams,
  kiTagsKiLists?: string[],
  kiList?: string[]
): DataKiParams => {
  // 店舗行の通常の検索条件
  const modifiedParams = {
    ...searchParams,
    mainField: MAIN_FIELD_TYPE.HL_MEI,
    containsAverage: false,
    sort: searchParams.sort === 'kiTushoMei' ? 'hlMei' : searchParams.sort,
  };

  if (queryParams.value === 'kiTagAverage') {
    return {
      ...modifiedParams,
      ...{ kiList: kiTagsKiLists },
      // MEMO: ForSijiritu 系のパラメータは検索条件で指定されているものを分母として渡す
      ...{
        kiListForSijiritu: searchParams.kiList,
        sisTypesForSijiritu: searchParams.sisTypes,
        makersForSijiritu: searchParams.makers,
      },
    };
  }

  if (queryParams.value !== null) {
    // 各機種タグ平均合計行から展開行を表示する際に機種タグに紐づく機種一覧を渡す
    if (queryParams.value.includes('onlyOneKiTagAverage')) {
      return {
        ...modifiedParams,
        ...{ kiList: kiList },
        // MEMO: ForSijiritu 系のパラメータは検索条件で指定されているものを分母として渡す
        ...{
          kiListForSijiritu: searchParams.kiList,
          sisTypesForSijiritu: searchParams.sisTypes,
          makersForSijiritu: searchParams.makers,
        },
      };
    }

    // 平均行以外 → QueryParameterで絞り込んだ検索条件
    return {
      ...modifiedParams,
      // MEMO: queryParams.name (kiList / sisTypes / makers) を指定されたもののみに変更する
      ...{ [queryParams.name]: [queryParams.value] },
      // MEMO: ForSijiritu 系のパラメータは検索条件で指定されているものを分母として渡す
      ...{
        kiListForSijiritu: searchParams.kiList,
        sisTypesForSijiritu: searchParams.sisTypes,
        makersForSijiritu: searchParams.makers,
      },
    };
  }

  // 平均行の場合 → 通常の検索条件
  return modifiedParams;
};

/**
 * QueryParameterを元にAPIから店舗行の内容を取得してdataKi2ndRowに登録する
 * @param api AxiosInstance
 * @param action Action
 */
function* searchDataKi2ndRowSaga(action: SearchDataKi2ndRowAction) {
  // APIリクエスト前に必要な情報を取得
  const searchParams: DataKiParams = yield select(dataKiParamsSelector); // 現在の検索条件
  const queryParameter = action.payload.params; // 検索条件を取得
  const kiList = action.payload.kiList; // 機種タグ平均合計行から展開行を表示する際に利用する機種一覧
  const kiTagsKiLists: string[] = yield select(
    dataKiModelCodesOfSelectedTagsSelector
  ); // 通常機種タグ平均合計行の機種タグに紐づいた機種一覧

  // 検索条件を加工
  const params = queryParameterToFormConditions(
    queryParameter,
    searchParams,
    kiTagsKiLists,
    kiList
  );

  // APIにリクエストを行う
  yield put(
    DataKi2ndRowActionCreators.fetchDataKi2ndRowAction(queryParameter, params)
  );
}

/**
 * 店舗行を取得した結果を返す
 * @param api AxiosInstance
 * @param params 検索パラメータ（DataKiParams）
 */
export function* fetchDataKi2ndRowSaga(
  api: Api,
  action: FetchDataKi2ndRowAction
) {
  try {
    const params = action.payload.params; // 検索条件を取得
    yield put(
      DataKi2ndRowActionCreators.fetchDataKi2ndRowRequestAction(
        action.payload.queryParameter.value,
        params
      )
    );

    validateQuery(params);

    // 展開行データを取得
    const response: AxiosResponse<DataKi> = yield call(
      api.get,
      '/data/ki',
      buildConfig(params)
    );

    // 取得した内容を店舗行に追加する
    const dataKi2ndRow: DataKi2ndRow = {
      requestParams: action.payload.queryParameter,
      setting: response.data.setting,
      data: response.data.data,
    };

    // 正常時取得したデータをdataKi2ndRowにDispatchする
    yield put(
      DataKi2ndRowActionCreators.fetchDataKi2ndRowSuccessAction(
        action.payload.queryParameter.value,
        dataKi2ndRow
      )
    );
  } catch (error: unknown) {
    yield put(DataKi2ndRowActionCreators.renewDataKi2ndRowAction());
    yield fork(handleErrorSaga, error);
  }
}

/**
 * 指定したQueryParameterと一致する店舗行を表示・非表示する
 * @param action Action
 */
function* toggleDataKi2ndRowSaga(action: ToggleDataKi2ndRowAction) {
  const queryParameter = action.payload.params;
  const kiList = action.payload.kiList;

  // queryParameterがundefinedの場合何もしない
  if (queryParameter === undefined) return;

  // queryParameterをもとに店舗行を取得
  const now2ndRow: DataKi2ndRow | undefined = yield select(
    singleDataKi2ndRowSelector(queryParameter.value)
  );

  // 未取得の場合データを取得して表示する
  if (now2ndRow === undefined) {
    yield put(
      DataKi2ndRowActionCreators.searchDataKi2ndRowAction(
        queryParameter,
        kiList
      )
    );
    return;
  }

  // 取得済みの場合はデータを削除する
  yield put(
    DataKi2ndRowActionCreators.hideDataKi2ndRowAction(queryParameter.value)
  );
}

/**
 * SEARCH_DATA_KI_2NDROWがDispatchされた時にfetchDataKi2ndRowSagaを実行する
 * @param api AxiosInstance
 */
function* handleSearchDataKi2ndRowSaga() {
  yield takeEvery(
    DataKi2ndRowActionTypes.SEARCH_DATA_KI_2NDROW,
    searchDataKi2ndRowSaga
  );
}

/**
 * FETCH_DATA_KI_2NDROWがDispatchされた時にfetchDataKi2ndRowSagaを実行する
 * @param api AxiosInstance
 */
function* handleFetchDataKi2ndRowSaga(api: Api) {
  yield takeEvery(
    DataKi2ndRowActionTypes.FETCH_DATA_KI_2NDROW,
    fetchDataKi2ndRowSaga,
    api
  );
}

/**
 * TOGGLE_DATA_KI_2NDROWがDispatchされた時にtoggleDataKi2ndRowSagaを実行する
 */
function* handleToggleDataKi2ndRowSaga() {
  yield takeEvery(
    DataKi2ndRowActionTypes.TOGGLE_DATA_KI_2NDROW,
    toggleDataKi2ndRowSaga
  );
}

/**
 * 店舗行に関するタスクを実行する
 * @param context AxiosInstance
 */
export function* dataKi2ndRowSagas(context: { api: Api }) {
  yield fork(handleSearchDataKi2ndRowSaga); // 検索条件セット時店舗行データを取得
  yield fork(handleFetchDataKi2ndRowSaga, context.api); // 指定した検索条件で店舗行データを取得
  yield fork(handleToggleDataKi2ndRowSaga); // 店舗行を表示・非表示
}
