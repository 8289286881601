import { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useAuth } from '../../../hooks/useAuth';

import { LoadingPage } from '../LoadingPage';

export const LoginPage: FC = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth();

  const { search } = useLocation();

  useEffect(() => {
    let ignore = false;

    const login = async () => {
      if (ignore) {
        return;
      }
      const params = new URLSearchParams(decodeURIComponent(search));
      await loginWithRedirect({
        appState: { redirect_path: params.get('from') },
      });
    };

    login();

    return () => {
      ignore = true;
    };
  }, [isAuthenticated, loginWithRedirect, search]);

  return <LoadingPage />;
};
