import { AxiosResponse } from 'axios';
import { call, fork, put, takeEvery } from 'redux-saga/effects';

import { SettingsOptionsUnitModel } from '../domain/settingsOptionsUnitModel';

import {
  SettingsOptionsUnitModelActionCreators,
  SettingsOptionsUnitModelActionTypes,
} from '../redux/server/settingsOptionsUnitModel';
import { Api, buildConfig } from '../utils/api';
import { handleErrorSaga } from './errorSaga';

function* fetchSettingsOptionsUnitModelSaga(api: Api) {
  try {
    const response: AxiosResponse<SettingsOptionsUnitModel> = yield call(
      api.get,
      '/settings/options/daiKisyu',
      buildConfig()
    );

    yield put(
      SettingsOptionsUnitModelActionCreators.fetchSettingsOptionsUnitModelSuccessAction(
        response.data
      )
    );
  } catch (error: unknown) {
    yield put(
      SettingsOptionsUnitModelActionCreators.renewSettingsOptionsUnitModelAction()
    );
    yield fork(handleErrorSaga, error);
  }
}

function* handleFetchSettingsOptionsUnitModelSaga(api: Api) {
  yield takeEvery(
    SettingsOptionsUnitModelActionTypes.FETCH_SETTINGS_OPTIONS_UNIT_MODEL_REQUEST,
    fetchSettingsOptionsUnitModelSaga,
    api
  );
}

export function* settingsOptionsUnitModelSagas(context: { api: Api }) {
  yield fork(handleFetchSettingsOptionsUnitModelSaga, context.api);
}
