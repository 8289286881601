import { SettingsOptionsTerminalTransition } from '../domain/settingsOptionsTerminalTransition';

// 検索条件項目のデフォルトデータ
export const settingsOptionsTerminalTransitionDefaultValue: SettingsOptionsTerminalTransition = {
  searchCondition: {
    makers: [],
    kiList: [],
    shuGroupList: [],
    shuList: [],
    areas: [],
    halls: [],
    sisTypes: [],
    latestBaseDateForDailySisData: '',
    latestBaseDateForWeeklySisData: '',
    latestBaseDateForMonthlySisData: '',
  },
  fields: {
    transition: [],
    nonTransition: [],
  },
  genres: {
    transition: [],
  },
};
