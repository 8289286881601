import PlaylistPlay from '@mui/icons-material/PlaylistPlay';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { NavLink, useLocation } from 'react-router-dom';

import { useHeaderState } from '../../../contexts/HeaderContext';

import { preloadPageComponent } from '../../../utils/preloadPageComponent';
import { Base64MenuIcon } from '../../atoms/Icon/Base64Icon/Base64MenuIcon';
import { menuIcons } from '../../atoms/Icon/Base64Icon/menuIcons';
import { Tooltip } from '../../atoms/Tooltip/Tooltip';
import { WithNewIcon } from '../../atoms/WithNewIcon/WithNewIcon';
import { WithUpdateIcon } from '../../atoms/WithUpdateIcon/WithUpdateIcon';
import { GlobalFavoritesMenu } from './GlobalFavoritesMenu';

type Props = {
  name: keyof typeof menuIcons;
  href: string;
  hasFavorite?: boolean;
  decorationIcon?: {
    type?: 'new' | 'update';
    prdReleaseDate: string;
  };
};

const isSelected = (target: string, current: string) => {
  // タブを持つページは、最初のパスが一致していれば選択状態とする
  if (target === '/report/hall' || target === '/report/chain') {
    return current.startsWith(target);
  }

  return target === current;
};

export const GlobalNavigationItem = (props: Props) => {
  const { name, href, hasFavorite = true, decorationIcon } = props;

  const router = useLocation();

  const { isOpenMenu } = useHeaderState();

  return (
    <Tooltip
      title={isOpenMenu ? '' : name}
      arrow
      placement="right"
      disableTouchListener
    >
      <ListItemButton
        disableRipple
        component={NavLink}
        to={href}
        selected={isSelected(href, router.pathname)}
        onMouseEnter={() => preloadPageComponent(name)}
      >
        <ListItemIcon sx={{ minWidth: '40px' }}>
          {(() => {
            if (decorationIcon == null) {
              return <Base64MenuIcon pageName={name} sx={{ opacity: 0.5 }} />;
            }

            const { type, prdReleaseDate } = decorationIcon;
            switch (type) {
              case 'new':
                return (
                  <WithNewIcon
                    icon={
                      <Base64MenuIcon pageName={name} sx={{ opacity: 0.5 }} />
                    }
                    prdReleaseDate={prdReleaseDate}
                    sx={{ bottom: -4, right: -4 }}
                  />
                );
              case 'update':
              default:
                return (
                  <WithUpdateIcon
                    prdReleaseDate={prdReleaseDate}
                    sx={{ bottom: -4, right: -4 }}
                  >
                    <Base64MenuIcon pageName={name} sx={{ opacity: 0.5 }} />
                  </WithUpdateIcon>
                );
            }
          })()}
        </ListItemIcon>
        <ListItemText primary={name} />
        {hasFavorite && (
          <Tooltip
            arrow
            title={<GlobalFavoritesMenu itemName={name} />}
            componentsProps={{
              arrow: {
                sx: {
                  color: (theme) => theme.palette.common.white,
                  '&:before': { boxShadow: 3 },
                },
              },
              tooltip: {
                sx: {
                  color: (theme) => theme.palette.text.primary,
                  backgroundColor: (theme) => theme.palette.common.white,
                  marginTop: '-11px',
                  borderRadius: '0.25rem',
                  boxShadow: 3,
                  padding: 0,
                  maxWidth: '380px',
                  maxHeight: '500px',
                },
              },
            }}
            placement="right-start"
            disableTouchListener
            disableInteractive={false}
          >
            <PlaylistPlay color="action" />
          </Tooltip>
        )}
      </ListItemButton>
    </Tooltip>
  );
};
