import { format } from 'date-fns';
import { createSelector } from 'reselect';

// Action Types

const SELECT_SETTINGS_DATADELETION_DATE_RANGE = 'SELECT_SETTINGS_DATADELETION_DATE_RANGE' as const;
const SELECT_SETTINGS_DATADELETION_HALL_CODE = 'SELECT_SETTINGS_DATADELETION_HALL_CODE' as const;

export const SettingsDataDeletionlActionTypes = {
  SELECT_SETTINGS_DATADELETION_DATE_RANGE,
  SELECT_SETTINGS_DATADELETION_HALL_CODE,
};

// Action Creators

function selectSettingsDataDeletionDateRangeAction(deleteDate: string) {
  return {
    type: SELECT_SETTINGS_DATADELETION_DATE_RANGE,
    payload: {
      deleteDate,
    },
  };
}

function selectSettingsDataDeletionHallCodeAction(hallCode: string) {
  return {
    type: SELECT_SETTINGS_DATADELETION_HALL_CODE,
    payload: {
      hallCode,
    },
  };
}

export const SettingsDataDeletionActionCreators = {
  selectSettingsDataDeletionDateRangeAction,
  selectSettingsDataDeletionHallCodeAction,
};

// Actions
type SettingsDataDeletionAction =
  | ReturnType<typeof selectSettingsDataDeletionDateRangeAction>
  | ReturnType<typeof selectSettingsDataDeletionHallCodeAction>;

// State

type SettingsDataDeletionState = {
  hallCode: string;
  deleteDate: string;
};

const initialState: SettingsDataDeletionState = {
  deleteDate: format(new Date(), 'yyyy-MM-dd'),
  hallCode: 'none',
};

// Selector
function settingsDataDeletionSelector(rootState: {
  settingsDataDeletion: SettingsDataDeletionState;
}) {
  return rootState.settingsDataDeletion;
}

/**
 * 選択した削除日の取得
 */
export const settingsDataDeletionDateRangeSelector = createSelector(
  settingsDataDeletionSelector,
  ({ deleteDate }) => ({
    deleteDate,
  })
);

/**
 * 選択した店舗の取得
 */
export const settingsDataDeletionHallCodeSelector = createSelector(
  settingsDataDeletionSelector,
  ({ hallCode }) => hallCode
);

// Reducer

export function settingsDataDeletionReducer(
  state = initialState,
  action: SettingsDataDeletionAction
): SettingsDataDeletionState {
  switch (action.type) {
    case SELECT_SETTINGS_DATADELETION_DATE_RANGE: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case SELECT_SETTINGS_DATADELETION_HALL_CODE: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
}
