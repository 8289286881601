/**
 * 有効エリアかどうかを判定
 * @param searchConditionsArea SearchConditionsのエリア
 * @returns boolean
 */
export const isValidArea = (searchConditionsArea: string[] | undefined) => {
  // searchConditionsAreaのareaの文字列が三桁の場合に、falseを返却する
  // MGモバイルと連携する場合、エリアのidの長さが3桁以下であり、エリア設定で新規エリアを作成して連携をする場合、エリアのidの長さが4桁以上である（背景：https://daikoku-cloud.slack.com/archives/C026YMFKL8L/p1705633363740349?thread_ts=1705565046.726709&cid=C026YMFKL8L）
  if (searchConditionsArea?.some((area) => area.length <= 3)) {
    return false;
  }

  return true;
};
