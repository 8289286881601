import { DataElementForTable } from '../schemas';
import { ModeData } from './types';

/**
 * 非モード項目の並び替え
 */
const swapNotModeColumnsOrder = (
  dataMode: ModeData,
  columnsNotModeSpecificOrder: string[]
): ModeData => {
  if (columnsNotModeSpecificOrder.length === 0) {
    return dataMode;
  }

  const notModeIndexes = columnsNotModeSpecificOrder
    .map((code) =>
      dataMode.data.columnsNotModeSpecific.findIndex((col) => col.code === code)
    )
    .filter((index) => index !== -1);

  const columnsNotModeSpecific = notModeIndexes.map((index) =>
    dataMode.data.columnsNotModeSpecific.at(index)
  ) as ModeData['data']['columnsNotModeSpecific'];

  return {
    ...dataMode,
    data: {
      ...dataMode.data,
      columnsNotModeSpecific:
        columnsNotModeSpecificOrder.length === 0
          ? dataMode.data.columnsNotModeSpecific
          : columnsNotModeSpecific,
      rows: dataMode.data.rows.map((row) => {
        const dataNotModeSpecific = notModeIndexes.map((index) =>
          row.dataNotModeSpecific.at(index)
        ) as DataElementForTable[];

        return {
          ...row,
          dataNotModeSpecific:
            columnsNotModeSpecificOrder.length === 0
              ? row.dataNotModeSpecific
              : dataNotModeSpecific,
        };
      }),
    },
  };
};

/**
 * モード項目の並び替え
 */
const swapModeColumnsOrder = (
  dataMode: ModeData,
  columnsModeSpecificOrder: string[]
): ModeData => {
  if (columnsModeSpecificOrder.length === 0) {
    return dataMode;
  }

  const indexes = columnsModeSpecificOrder
    .map((code) =>
      dataMode.data.columnsModeSpecific.findIndex((col) => col.code === code)
    )
    .filter((index) => index !== -1);

  const columnsModeSpecific = indexes.map((index) =>
    dataMode.data.columnsModeSpecific.at(index)
  ) as ModeData['data']['columnsModeSpecific'];

  return {
    ...dataMode,
    data: {
      ...dataMode.data,
      columnsModeSpecific:
        columnsModeSpecificOrder.length === 0
          ? dataMode.data.columnsModeSpecific
          : columnsModeSpecific,
      rows: dataMode.data.rows.map((row) => {
        const dataModeSpecific = row.dataModeSpecific.map((data) =>
          indexes.map((index) => data.at(index))
        ) as DataElementForTable[][];

        return {
          ...row,
          dataModeSpecific:
            columnsModeSpecificOrder.length === 0
              ? row.dataModeSpecific
              : dataModeSpecific,
        };
      }),
    },
  };
};

/**
 * 表示項目並び替え
 */
export const dataSwapColumnsOrder = (
  dataMode: ModeData,
  columnsNotModeSpecificOrder: string[],
  columnsModeSpecificOrder: string[]
): ModeData => {
  if (
    columnsNotModeSpecificOrder.length === 0 &&
    columnsModeSpecificOrder.length === 0
  ) {
    return dataMode;
  }

  const swappedNotMode = swapNotModeColumnsOrder(
    dataMode,
    columnsNotModeSpecificOrder
  );
  const swappedMode = swapModeColumnsOrder(
    swappedNotMode,
    columnsModeSpecificOrder
  );

  return swappedMode;
};
