/**
 * 平日/土日祝比較で平日のみまたは休日のみになった場合、startDateとendDateをnullにする対応
 */
export const formatWeekdayAndHoliday = ({
  ymdList,
  ymdComparisonList,
}: {
  ymdList: string[] | undefined;
  ymdComparisonList: string[] | undefined;
}): {
  ymdList?: string[] | undefined;
  ymdComparisonList?: string[] | undefined;
  startDate?: string | null;
  endDate?: string | null;
  startComparisonDate?: string | null;
  endComparisonDate?: string | null;
} => {
  if (ymdList == null || ymdComparisonList == null) {
    return {
      ymdList: undefined,
      ymdComparisonList: undefined,
    };
  }

  if (ymdList.length === 0) {
    return {
      ymdComparisonList,
      startDate: null,
      endDate: null,
    };
  }

  if (ymdComparisonList.length === 0) {
    return {
      ymdList,
      startComparisonDate: null,
      endComparisonDate: null,
    };
  }

  return { ymdList, ymdComparisonList };
};
