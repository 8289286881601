import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { FC, ReactNode, Suspense } from 'react';

function queryErrorHandler(error: unknown): void {
  // error is type unknown because in js, anything can be an error (e.g. throw(5))
  const id = 'react-query-error';
  const title =
    error instanceof Error ? error.message : 'error connecting to server';

  console.warn(id, title);

  // some toast message
}

function generateQueryClient(): QueryClient {
  return new QueryClient({
    defaultOptions: {
      queries: {
        onError: queryErrorHandler,
        retry: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        suspense: true,
      },
      mutations: {
        onError: queryErrorHandler,
      },
    },
  });
}

export const queryClient = generateQueryClient();

// テスト用React Query Client
export const createTestingQueryClientWrapper = (): FC => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
      },
    },
    logger: {
      log: console.log,
      warn: console.warn,
      error: () => {
        // do nothing
      },
    },
  });
  return ({ children }: { children?: ReactNode }) => (
    <Suspense fallback={null}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </Suspense>
  );
};
