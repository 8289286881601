import { AxiosResponse } from 'axios';
import { call, fork, put, select, takeEvery } from 'redux-saga/effects';

import {
  FieldPatternsType,
  SettingsOptionsFieldPatterns,
} from '../domain/settingsOptionsColsOptionPanelPattern';

import {
  DeleteSettingsOptionsColsOptionPanelPatternRequestAction,
  FetchSettingsOptionsColsOptionPanelPatternRequestAction,
  PatchSettingsOptionsColsOptionPanelPatternRequestAction,
  PostSettingsOptionsColsOptionPanelPatternRequestAction,
  SettingsOptionsColsOptionPanelPatternActionCreators,
  SettingsOptionsColsOptionPanelPatternState,
  SettingsOptionsOptionPanelPatternActionTypes,
  settingsOptionsColsOptionPanelPatternSelector,
} from '../redux/server/settingsOptionsColsOptionPanelPattern';
import { Api, buildConfig } from '../utils/api';
import { handleErrorSaga } from './errorSaga';

function* fetchSettingsOptionsColsOptionPanelPatternSaga(
  api: Api,
  action: FetchSettingsOptionsColsOptionPanelPatternRequestAction
) {
  try {
    const { tableName } = action.payload.params;
    const response: AxiosResponse<SettingsOptionsFieldPatterns> = yield call(
      api.get,
      `/settings/fieldPatterns/${tableName}`,
      buildConfig()
    );
    const settingsOptionsColsOptionPanelPattern = response.data;
    yield put(
      SettingsOptionsColsOptionPanelPatternActionCreators.fetchSettingsOptionsColsOptionPanelPatternSuccessAction(
        settingsOptionsColsOptionPanelPattern
      )
    );
  } catch (error: unknown) {
    yield put(
      SettingsOptionsColsOptionPanelPatternActionCreators.renewSettingsOptionsColsOptionPanelPatternAction()
    );
    yield fork(handleErrorSaga, error);
  }
}

function* postSettingsOptionsColsOptionPanelPatternSaga(
  api: Api,
  action: PostSettingsOptionsColsOptionPanelPatternRequestAction
) {
  try {
    const { tableName, ...params } = action.payload.params;
    const response: AxiosResponse = yield call(
      api.post,
      `/settings/fieldPatterns/${tableName}`,
      params,
      buildConfig()
    );
    const settingsOptionsColsOptionPanelPattern = response.data;

    yield put(
      SettingsOptionsColsOptionPanelPatternActionCreators.postSettingsOptionsColsOptionPanelPatternSuccessAction(
        settingsOptionsColsOptionPanelPattern
      )
    );
    yield put(
      SettingsOptionsColsOptionPanelPatternActionCreators.fetchSettingsOptionsColsOptionPanelPatternRequestAction(
        { tableName }
      )
    );
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    yield put(
      SettingsOptionsColsOptionPanelPatternActionCreators.postSettingsOptionsColsOptionPanelPatternFailureAction(
        error
      )
    );
    yield fork(handleErrorSaga, error);
  }
}

// 配列の入れ替え
function moveAt<T>(array: T[], index: number, at: number) {
  if (index === at || index > array.length - 1 || at > array.length - 1) {
    return array;
  }

  const value = array[index];
  const tail = array.slice(index + 1);

  array.splice(index);
  Array.prototype.push.apply(array, tail);
  array.splice(at, 0, value);

  return array;
}

function* patchSettingsOptionsColsOptionPanelPatternSaga(
  api: Api,
  action: PatchSettingsOptionsColsOptionPanelPatternRequestAction
) {
  const { settingId, tableName, ...params } = action.payload.params;

  const {
    settingsOptionsColsOptionPanelPatterns,
  }: SettingsOptionsColsOptionPanelPatternState = yield select(
    settingsOptionsColsOptionPanelPatternSelector
  );
  if (!settingsOptionsColsOptionPanelPatterns) {
    throw new Error('並び替えるときにデータが存在しないことがあり得ないため');
  }
  const orgSettingsOptionsColsOptionPanelPatterns = [
    ...settingsOptionsColsOptionPanelPatterns.fieldPatterns,
  ];
  try {
    // 表示項目パターン順番変更の場合
    // APIの変更を待たずとフロント側の表示項目パターン順番値を変更する処理
    if (params.order) {
      const target =
        settingsOptionsColsOptionPanelPatterns.fieldPatterns.find(
          (f) => f.id === action.payload.params.settingId
        ) ?? ({} as FieldPatternsType);

      const order = action.payload.params.order;

      if (order != null) {
        const optimisticData = moveAt(
          settingsOptionsColsOptionPanelPatterns.fieldPatterns,
          settingsOptionsColsOptionPanelPatterns.fieldPatterns.indexOf(target),
          order
        );
        yield put(
          SettingsOptionsColsOptionPanelPatternActionCreators.patchSettingsOptionsColsOptionPanelPatternSuccessAction(
            { fieldPatterns: optimisticData }
          )
        );
      }
    }

    yield call(
      api.patch,
      `/settings/fieldPatterns/${settingId}`,
      params,
      buildConfig()
    );

    yield put(
      SettingsOptionsColsOptionPanelPatternActionCreators.fetchSettingsOptionsColsOptionPanelPatternRequestAction(
        { tableName }
      )
    );
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (params.order) {
      yield put(
        SettingsOptionsColsOptionPanelPatternActionCreators.patchSettingsOptionsColsOptionPanelPatternSuccessAction(
          { fieldPatterns: orgSettingsOptionsColsOptionPanelPatterns }
        )
      );
    }
    yield put(
      SettingsOptionsColsOptionPanelPatternActionCreators.patchSettingsOptionsColsOptionPanelPatternFailureAction(
        error
      )
    );
    yield fork(handleErrorSaga, error);
  }
}

function* deleteSettingsOptionsColsOptionPanelPatternSaga(
  api: Api,
  action: DeleteSettingsOptionsColsOptionPanelPatternRequestAction
) {
  try {
    const { settingId, tableName } = action.payload.params;
    const response: AxiosResponse = yield call(
      api.delete,
      `/settings/fieldPatterns/${settingId}`,
      buildConfig()
    );
    const settingsOptionsColsOptionPanelPattern = response.data;

    yield put(
      SettingsOptionsColsOptionPanelPatternActionCreators.deleteSettingsOptionsColsOptionPanelPatternSuccessAction(
        settingsOptionsColsOptionPanelPattern
      )
    );
    yield put(
      SettingsOptionsColsOptionPanelPatternActionCreators.fetchSettingsOptionsColsOptionPanelPatternRequestAction(
        { tableName }
      )
    );
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    yield put(
      SettingsOptionsColsOptionPanelPatternActionCreators.deleteSettingsOptionsColsOptionPanelPatternFailureAction(
        error
      )
    );
    yield fork(handleErrorSaga, error);
  }
}

function* handleFetchsettingsOptionsColsOptionPanelPatternSaga(api: Api) {
  yield takeEvery(
    SettingsOptionsOptionPanelPatternActionTypes.FETCH_SETTINGS_OPTIONS_COLS_OPTION_PANEL_PATTERN_REQUEST,
    fetchSettingsOptionsColsOptionPanelPatternSaga,
    api
  );
}

function* handlePostsettingsOptionsColsOptionPanelPatternSaga(api: Api) {
  yield takeEvery(
    SettingsOptionsOptionPanelPatternActionTypes.POST_SETTINGS_OPTIONS_COLS_OPTION_PANEL_PATTERN_REQUEST,
    postSettingsOptionsColsOptionPanelPatternSaga,
    api
  );
}

function* handlePatchsettingsOptionsColsOptionPanelPatternSaga(api: Api) {
  yield takeEvery(
    SettingsOptionsOptionPanelPatternActionTypes.PATCH_SETTINGS_OPTIONS_COLS_OPTION_PANEL_PATTERN_REQUEST,
    patchSettingsOptionsColsOptionPanelPatternSaga,
    api
  );
}

function* handleDeletesettingsOptionsColsOptionPanelPatternSaga(api: Api) {
  yield takeEvery(
    SettingsOptionsOptionPanelPatternActionTypes.DELETE_SETTINGS_OPTIONS_COLS_OPTION_PANEL_PATTERN_REQUEST,
    deleteSettingsOptionsColsOptionPanelPatternSaga,
    api
  );
}

export function* settingsOptionsColsOptionPanelPatternSagas(context: {
  api: Api;
}) {
  yield fork(handleFetchsettingsOptionsColsOptionPanelPatternSaga, context.api);
  yield fork(handlePostsettingsOptionsColsOptionPanelPatternSaga, context.api);
  yield fork(handlePatchsettingsOptionsColsOptionPanelPatternSaga, context.api);
  yield fork(
    handleDeletesettingsOptionsColsOptionPanelPatternSaga,
    context.api
  );
}
