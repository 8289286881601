import { ClarisApiError } from '../../domain/error';
import { LoadingState } from '../../domain/schemas';
import {
  UserListData,
  UserListDataParams,
  UserListDataPostParams,
} from '../../domain/settingsUsers';

/**
 * TODO:
 * loadingStateを異なるAPIで共通で利用しているため、それぞれのLoading状態を個別にもたせる必要があります
 * https://github.com/DKClaris/claris-general/issues/3712
 */

// Action Types

const FETCH_SETTINGS_USERS = 'FETCH_SETTINGS_USERS' as const;
const FETCH_SETTINGS_USERS_REQUEST = 'FETCH_SETTINGS_USERS_REQUEST' as const;
const FETCH_SETTINGS_USERS_SUCCESS = 'FETCH_SETTINGS_USERS_SUCCESS' as const;
const RENAME_SETTINGS_USERS = 'RENAME_SETTINGS_USERS' as const;
const POST_SETTINGS_USERS = 'POST_SETTINGS_USERS' as const;
const POST_SETTINGS_USERS_REQUEST = 'POST_SETTINGS_USERS_REQUEST' as const;
const POST_SETTINGS_USERS_SUCCESS = 'POST_SETTINGS_USERS_SUCCESS' as const;
const POST_SETTINGS_USERS_FAILURE = 'POST_SETTINGS_USERS_FAILURE' as const;
const PUT_SETTINGS_USERS = 'PUT_SETTINGS_USERS' as const;
const PUT_SETTINGS_USERS_REQUEST = 'PUT_SETTINGS_USERS_REQUEST' as const;
const PUT_SETTINGS_USERS_SUCCESS = 'PUT_SETTINGS_USERS_SUCCESS' as const;
const PUT_SETTINGS_USERS_FAILURE = 'PUT_SETTINGS_USERS_FAILURE' as const;
const DELETE_SETTINGS_USERS = 'DELETE_SETTINGS_USERS' as const;
const DELETE_SETTINGS_USERS_REQUEST = 'DELETE_SETTINGS_USERS_REQUEST' as const;
const DELETE_SETTINGS_USERS_SUCCESS = 'DELETE_SETTINGS_USERS_SUCCESS' as const;
const DELETE_SETTINGS_USERS_FAILURE = 'DELETE_SETTINGS_USERS_FAILURE' as const;

export const SettingsUsersActionTypes = {
  FETCH_SETTINGS_USERS,
  FETCH_SETTINGS_USERS_REQUEST,
  FETCH_SETTINGS_USERS_SUCCESS,
  RENAME_SETTINGS_USERS,
  POST_SETTINGS_USERS,
  POST_SETTINGS_USERS_REQUEST,
  POST_SETTINGS_USERS_SUCCESS,
  POST_SETTINGS_USERS_FAILURE,
  PUT_SETTINGS_USERS,
  PUT_SETTINGS_USERS_REQUEST,
  PUT_SETTINGS_USERS_SUCCESS,
  PUT_SETTINGS_USERS_FAILURE,
  DELETE_SETTINGS_USERS,
  DELETE_SETTINGS_USERS_REQUEST,
  DELETE_SETTINGS_USERS_SUCCESS,
  DELETE_SETTINGS_USERS_FAILURE,
};

// Action Creators

function fetchSettingsUsersAction(params?: UserListDataParams) {
  return {
    type: FETCH_SETTINGS_USERS,
    payload: { params },
  };
}

function fetchSettingsUsersRequestAction() {
  return {
    type: FETCH_SETTINGS_USERS_REQUEST,
  };
}

function fetchSettingsUsersSuccessAction(settingsUsers: UserListData) {
  return {
    type: FETCH_SETTINGS_USERS_SUCCESS,
    payload: { settingsUsers },
  };
}

function renewSettingsUsersAction() {
  return {
    type: RENAME_SETTINGS_USERS,
  };
}

function postSettingsUsersAction(params: UserListDataPostParams) {
  return {
    type: POST_SETTINGS_USERS,
    payload: { params },
  };
}

function postSettingsUsersRequestAction() {
  return {
    type: POST_SETTINGS_USERS_REQUEST,
  };
}

function postSettingsUsersSuccessAction() {
  return {
    type: POST_SETTINGS_USERS_SUCCESS,
  };
}

function postSettingsUsersFailureAction(error: ClarisApiError) {
  return {
    type: POST_SETTINGS_USERS_FAILURE,
    payload: { error },
  };
}

function putSettingsUsersAction(id: string, params: UserListDataPostParams) {
  return {
    type: PUT_SETTINGS_USERS,
    payload: { id, params },
  };
}

function putSettingsUsersRequestAction() {
  return {
    type: PUT_SETTINGS_USERS_REQUEST,
  };
}

function putSettingsUsersSuccessAction() {
  return {
    type: PUT_SETTINGS_USERS_SUCCESS,
  };
}

function putSettingsUsersFailureAction(error: ClarisApiError) {
  return {
    type: PUT_SETTINGS_USERS_FAILURE,
    payload: { error },
  };
}

function deleteSettingsUsersAction(id: string) {
  return {
    type: DELETE_SETTINGS_USERS,
    payload: { id },
  };
}

function deleteSettingsUsersRequestAction() {
  return {
    type: DELETE_SETTINGS_USERS_REQUEST,
  };
}

function deleteSettingsUsersSuccessAction() {
  return {
    type: DELETE_SETTINGS_USERS_SUCCESS,
  };
}

function deleteSettingsUsersFailureAction(error: ClarisApiError) {
  return {
    type: DELETE_SETTINGS_USERS_FAILURE,
    payload: { error },
  };
}

export const SettingsUsersActionCreators = {
  fetchSettingsUsersAction,
  fetchSettingsUsersRequestAction,
  fetchSettingsUsersSuccessAction,
  renewSettingsUsersAction,
  postSettingsUsersAction,
  postSettingsUsersRequestAction,
  postSettingsUsersSuccessAction,
  postSettingsUsersFailureAction,
  putSettingsUsersAction,
  putSettingsUsersRequestAction,
  putSettingsUsersSuccessAction,
  putSettingsUsersFailureAction,
  deleteSettingsUsersAction,
  deleteSettingsUsersRequestAction,
  deleteSettingsUsersSuccessAction,
  deleteSettingsUsersFailureAction,
};

// Actions

export type FetchSettingsUsersAction = ReturnType<
  typeof fetchSettingsUsersAction
>;

export type PostSettingsUsersAction = ReturnType<
  typeof postSettingsUsersAction
>;

export type PutSettingsUsersAction = ReturnType<typeof putSettingsUsersAction>;

export type DeleteSettingsUsersAction = ReturnType<
  typeof deleteSettingsUsersAction
>;

type SettingsUsersAction =
  | FetchSettingsUsersAction
  | ReturnType<typeof fetchSettingsUsersRequestAction>
  | ReturnType<typeof fetchSettingsUsersSuccessAction>
  | ReturnType<typeof renewSettingsUsersAction>
  | PostSettingsUsersAction
  | ReturnType<typeof postSettingsUsersRequestAction>
  | ReturnType<typeof postSettingsUsersSuccessAction>
  | ReturnType<typeof postSettingsUsersFailureAction>
  | PutSettingsUsersAction
  | ReturnType<typeof putSettingsUsersRequestAction>
  | ReturnType<typeof putSettingsUsersSuccessAction>
  | ReturnType<typeof putSettingsUsersFailureAction>
  | DeleteSettingsUsersAction
  | ReturnType<typeof deleteSettingsUsersRequestAction>
  | ReturnType<typeof deleteSettingsUsersSuccessAction>
  | ReturnType<typeof deleteSettingsUsersFailureAction>;

// State

type SettingsUsersState = {
  settingsUsers: UserListData | undefined;
  loadingState: LoadingState;
  error: ClarisApiError | undefined;
};

const initialState: SettingsUsersState = {
  settingsUsers: undefined,
  loadingState: 'prepare',
  error: undefined,
};

// Selector

export function settingsUsersSelector(rootState: {
  settingsUsers: SettingsUsersState;
}) {
  return rootState.settingsUsers.settingsUsers;
}

export function settingsUsersLoadingStateSelector(rootState: {
  settingsUsers: SettingsUsersState;
}) {
  return rootState.settingsUsers.loadingState;
}

export function settingsUsersErrorSelector(rootState: {
  settingsUsers: SettingsUsersState;
}) {
  return rootState.settingsUsers.error;
}

// Reducer

export function settingsUsersReducer(
  state = initialState,
  action: SettingsUsersAction
): SettingsUsersState {
  switch (action.type) {
    case FETCH_SETTINGS_USERS_REQUEST:
    case POST_SETTINGS_USERS_REQUEST:
    case PUT_SETTINGS_USERS_REQUEST:
    case DELETE_SETTINGS_USERS_REQUEST:
      return {
        ...state,
        loadingState: 'loading',
        error: undefined,
      };
    case FETCH_SETTINGS_USERS_SUCCESS:
      return {
        ...state,
        settingsUsers: action.payload.settingsUsers,
        loadingState: 'loaded',
      };
    case POST_SETTINGS_USERS_SUCCESS:
    case PUT_SETTINGS_USERS_SUCCESS:
    case DELETE_SETTINGS_USERS_SUCCESS:
      return {
        ...state,
        loadingState: 'loaded',
      };
    case RENAME_SETTINGS_USERS:
      return initialState;
    case POST_SETTINGS_USERS_FAILURE:
    case PUT_SETTINGS_USERS_FAILURE:
    case DELETE_SETTINGS_USERS_FAILURE:
      return {
        ...state,
        loadingState: 'failed',
        error: action.payload.error,
      };
    default:
      return state;
  }
}
