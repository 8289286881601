import { SxProps, Theme } from '@mui/material';
import Grid from '@mui/material/Grid';
import { ReactNode } from 'react';

/**
 * Styles
 */

const styles: Record<string, SxProps<Theme>> = {
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1301,
  },
};

/**
 * Container
 *
 * z-index: 1301; は Dialogよりも前面に表示するため
 */
export const CenterLayout = (props: { children?: ReactNode }) => {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={styles.root}
    >
      <Grid item>{props.children}</Grid>
    </Grid>
  );
};
