import { SxProps, Theme } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { FC, useCallback } from 'react';

export type ErrorPagePresenterProps = {
  redirectToHome: () => void;
};

const styles: Record<string, SxProps<Theme>> = {
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  loginTitle: {
    fontSize: '40px',
    marginBottom: '24px',
  },
};

export const ErrorPagePresenter: FC<ErrorPagePresenterProps> = (props) => {
  const { redirectToHome } = props;

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={styles.root}
    >
      <Grid item>
        <Paper elevation={3}>
          <Box p={3}>
            <Box mb={2}>
              <Typography variant="h6" component="h2" noWrap>
                エラーが発生しました
              </Typography>
            </Box>

            <Button color="primary" onClick={() => redirectToHome()}>
              ホームに戻る
            </Button>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};

export const ErrorPage: FC = () => {
  const redirectToHome = useCallback(() => {
    window.location.href = '/';
  }, []);

  return <ErrorPagePresenter redirectToHome={redirectToHome} />;
};
