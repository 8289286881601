import { add } from 'date-fns';

import { ReportsDateRange } from './reportsDateRange';

/**
 * dateRangeが何月何日かの日付を計算する
 */
export const calcCurrentDate = (
  dateRange: ReportsDateRange,
  currentDate = new Date()
) => {
  switch (dateRange) {
    case '前日':
      return add(currentDate, { days: -1 });
    case '前週':
      return add(currentDate, { weeks: -1 });
    case '前月':
      return add(currentDate, { months: -1 });
    case '当日':
    case '今週':
    case '今月':
    case 'カスタム':
      return currentDate;
  }
};
