import { DataDai } from '../../../domain/dai/types';
import { DataDepreciationResponse } from '../../../domain/depreciation/types';
import { Column, ColumnForDataTable, Row } from '../../../domain/schemas';
import { DataSis } from '../../../domain/sis/types';

import { removeSpecialString } from '../../../utils/string';

type SubMenuNumberFilter = {
  [field: string]: {
    minimumNumber: number | undefined;
    maximumNumber: number | undefined;
  };
};

type Columns =
  | Column[]
  | {
      name: string;
      isSisField: boolean | null;
      code: string | null;
      type: 'string' | 'number' | 'numberWithPrefix' | 'numberOrString' | null;
      backgroundColor: string | null;
      color: string | null;
      description: string | null;
    }[];

export const ConvertRowsToFilteredRows = <
  T extends
    | Row[]
    | DataDai['data']['rows']
    | DataSis['data']['rows']
    | DataDepreciationResponse['data']['rows']
>(
  subMenuNumberFilter: SubMenuNumberFilter,
  columns: Columns | ColumnForDataTable[],
  rows: T
) => {
  const subMenuNumberFilterProperty = Object.keys(subMenuNumberFilter);

  //モード別台数＆モード別シェアなどcolumnsの中にcolumnsがある場合は展開する
  const expandColumns = columns.flatMap((column) => {
    if ('columns' in column) {
      return [...(column.columns ?? [])] as Columns;
    }
    return [column];
  });

  /**
   * フィルタしている項目のIndex番号を全て抜き出したもの
   */
  const columnIndexNumbers = expandColumns
    .map((column, i) =>
      subMenuNumberFilterProperty.includes(column.code ?? '') ? i : ''
    )
    .filter(String);

  let filteredRows = [...rows];

  for (let i = 0; i < columnIndexNumbers.length; i++) {
    const code = expandColumns[Number(columnIndexNumbers[i])].code;

    if (code == null) {
      continue;
    }

    const target = subMenuNumberFilter[code];

    if (
      //最大値、最小値両方の入力があった場合
      target.maximumNumber !== undefined &&
      target.minimumNumber !== undefined
    ) {
      filteredRows = filteredRows.filter(
        (row) =>
          row.data.at(0)?.value === '平均/合計' ||
          (row.data.length > 0 &&
            Number(
              removeSpecialString(row.data[Number(columnIndexNumbers[i])].value)
            ) >= Number(target.minimumNumber) &&
            Number(
              removeSpecialString(row.data[Number(columnIndexNumbers[i])].value)
            ) <= Number(target.maximumNumber))
      );
    } else if (
      //最大値のみ入力があった場合
      target.maximumNumber !== undefined
    ) {
      filteredRows = filteredRows.filter(
        (row) =>
          row.data.at(0)?.value === '平均/合計' ||
          (row.data.length > 0 &&
            Number(
              removeSpecialString(row.data[Number(columnIndexNumbers[i])].value)
            ) <= Number(target.maximumNumber))
      );
    } else if (
      //最小値のみ入力があった場合
      target.minimumNumber !== undefined
    ) {
      filteredRows = filteredRows.filter(
        (row) =>
          row.data.at(0)?.value === '平均/合計' ||
          (row.data.length > 0 &&
            Number(
              removeSpecialString(row.data[Number(columnIndexNumbers[i])].value)
            ) >= Number(target.minimumNumber))
      );
    }
  }

  return {
    filteredRows: filteredRows,
  };
};
