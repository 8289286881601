import { AxiosResponse } from 'axios';
import { call, fork, put, takeEvery } from 'redux-saga/effects';

import { SettingsOptionsModelTransition } from '../domain/settingsOptionsModelTransition';

import {
  SettingsOptionsModelTransitionActionCreators,
  SettingsOptionsModelTransitionActionTypes,
} from '../redux/server/settingsOptionsModelTransition';
import { Api, buildConfig } from '../utils/api';
import { handleErrorSaga } from './errorSaga';

export function* fetchSettingsOptionsModelTransitionSaga(api: Api) {
  try {
    yield put(
      SettingsOptionsModelTransitionActionCreators.fetchSettingsOptionsModelTransitionRequestAction()
    );
    const response: AxiosResponse<SettingsOptionsModelTransition> = yield call(
      api.get,
      '/settings/options/kiTransition',
      buildConfig()
    );

    yield put(
      SettingsOptionsModelTransitionActionCreators.fetchSettingsOptionsModelTransitionSuccessAction(
        response.data
      )
    );
  } catch (error: unknown) {
    yield put(
      SettingsOptionsModelTransitionActionCreators.renewSettingsOptionsModelTransitionAction()
    );
    yield fork(handleErrorSaga, error);
  }
}

function* handleFetchSettingsOptionsModelTransitionSaga(api: Api) {
  yield takeEvery(
    SettingsOptionsModelTransitionActionTypes.FETCH_SETTINGS_OPTIONS_MODEL_TRANSITION,
    fetchSettingsOptionsModelTransitionSaga,
    api
  );
}

export function* settingsOptionsModelTransitionSagas(context: { api: Api }) {
  yield fork(handleFetchSettingsOptionsModelTransitionSaga, context.api);
}
