import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import PersonIcon from '@mui/icons-material/Person';
import { PopoverProps } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Popover from '@mui/material/Popover';
import { FC, useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { User } from '../../../domain/user';

import { useAuth } from '../../../hooks/useAuth';

import { AUTH0_LOGOUT_URL } from '../../../consistents';
import { userSelector } from '../../../redux/ui/user';

export type UserActionPresenterProps = {
  user: User | undefined;
  anchorEl: PopoverProps['anchorEl'];
  isOpen: boolean;
  openMenu: (target: EventTarget & HTMLElement) => void;
  closeMenu: () => void;
  logout: () => void;
};

export const UserActionPresenter: FC<UserActionPresenterProps> = (props) => {
  const { user, anchorEl, isOpen, openMenu, closeMenu, logout } = props;

  return (
    <>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={(e) => openMenu(e.currentTarget)}
        size="large"
      >
        <PersonIcon />
      </IconButton>
      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={() => closeMenu()}
      >
        {user && (
          <>
            <List>
              <ListItem>
                <ListItemAvatar>
                  <Avatar alt={user.name} src={user.picture} />
                </ListItemAvatar>
                <ListItemText primary={user.name} />
              </ListItem>
            </List>
            <Divider />
          </>
        )}
        <List>
          <ListItem button onClick={() => logout()}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="ログアウト" />
          </ListItem>
        </List>
      </Popover>
    </>
  );
};

export const UserAction: FC = () => {
  const { logout: logoutAuth0 } = useAuth();

  const user = useSelector(userSelector);

  const [anchorEl, setAnchorEl] = useState<PopoverProps['anchorEl']>();

  const isOpen = useMemo(() => {
    return !!anchorEl;
  }, [anchorEl]);

  const openMenu = useCallback((target: EventTarget & HTMLElement) => {
    setAnchorEl(target);
  }, []);

  const closeMenu = useCallback(() => {
    setAnchorEl(undefined);
  }, []);

  const logout = useCallback(() => {
    logoutAuth0({
      logoutParams: {
        returnTo: AUTH0_LOGOUT_URL,
      },
    });
    sessionStorage.clear();
  }, [logoutAuth0]);

  return (
    <UserActionPresenter
      user={user}
      anchorEl={anchorEl}
      isOpen={isOpen}
      openMenu={openMenu}
      closeMenu={closeMenu}
      logout={logout}
    />
  );
};
