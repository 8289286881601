import {
  Dispatch,
  ReactNode,
  RefObject,
  SetStateAction,
  createContext,
  useContext,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

type HeightState = {
  global: {
    window: number | undefined;
    header: number | undefined;
  };
  report: {
    chain: {
      tab: undefined;
      shuNav: undefined;
      kiNav: undefined;
    };
    hall: {
      tab: undefined;
      mksNav: undefined;
      kiNav: undefined;
    };
    model: {
      tableToolbar: number | undefined;
      searchConditionTabs: number | undefined;
      searchConditionTabsBox: number | undefined;
      tableBoxContainer: number | undefined;
      tablePagination: number | undefined;
      bottomDrawerMenu: number | undefined;
    };
    sis: {
      searchForm: number | undefined;
      searchConditionTabs: number | undefined;
      bottomDrawerMenu: number | undefined;
      graph: number | undefined;
    };
    transitionAfterIntroduction: {
      tableToolbar: number | undefined;
      searchConditionTabs: number | undefined;
      tablePagination: number | undefined;
    };
    modelTransition: {
      tableToolbar: number | undefined;
      searchConditionTabs: number | undefined;
      tablePagination: number | undefined;
    };
    daiCosts: {
      searchForm: number | undefined;
      searchConditionTabs: number | undefined;
    };
    depreciation: {
      searchConditionTabs: number | undefined;
    };
    dai: {
      tableToolbar: number | undefined;
      searchConditionTabs: number | undefined;
      tableContainer: number | undefined;
    };
  };
};

const initialState: HeightState = {
  global: {
    window: undefined,
    header: undefined,
  },
  report: {
    chain: {
      tab: undefined,
      shuNav: undefined,
      kiNav: undefined,
    },
    hall: {
      tab: undefined,
      mksNav: undefined,
      kiNav: undefined,
    },
    model: {
      tableToolbar: undefined,
      searchConditionTabs: undefined,
      searchConditionTabsBox: undefined,
      tableBoxContainer: undefined,
      tablePagination: undefined,
      bottomDrawerMenu: undefined,
    },
    sis: {
      searchForm: undefined,
      searchConditionTabs: undefined,
      bottomDrawerMenu: undefined,
      graph: undefined,
    },
    transitionAfterIntroduction: {
      tableToolbar: undefined,
      searchConditionTabs: undefined,
      tablePagination: undefined,
    },
    modelTransition: {
      tableToolbar: undefined,
      searchConditionTabs: undefined,
      tablePagination: undefined,
    },
    daiCosts: {
      searchForm: undefined,
      searchConditionTabs: undefined,
    },
    depreciation: {
      searchConditionTabs: undefined,
    },
    dai: {
      tableToolbar: undefined,
      searchConditionTabs: undefined,
      tableContainer: undefined,
    },
  },
};

const HeightStateContext = createContext<HeightState>(initialState);
const SetHeightStateContext = createContext<
  Dispatch<SetStateAction<HeightState>>
>(() => {
  // do nothing
});

export const useHeightState = () => {
  return useContext(HeightStateContext);
};

/**
 * 要素のResizeを監視して、高さに変更があった場合には設定し直す
 */
export const useHeightObserver = <T extends HTMLElement>(
  fn: (height: number | undefined) => SetStateAction<HeightState>
): RefObject<T> => {
  const rootRef = useRef<T>(null);
  const setHeight = useContext(SetHeightStateContext);

  useLayoutEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      setHeight(fn(entries.at(0)?.contentRect.height));
    });

    rootRef.current && resizeObserver.observe(rootRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, [setHeight, fn]);

  return rootRef;
};

/**
 * ウィンドウの高さを監視して、ストアに設定する
 */
export const useObserveWindowSize = () => {
  const frame = useRef(0);
  const setHeight = useContext(SetHeightStateContext);

  useLayoutEffect(() => {
    const handler = () => {
      cancelAnimationFrame(frame.current);
      frame.current = requestAnimationFrame(() => {
        setHeight((prev) => ({
          ...prev,
          global: { ...prev.global, window: window.innerHeight },
        }));
      });
    };
    handler();
    window.addEventListener('resize', handler);

    return () => {
      window.removeEventListener('resize', handler);
    };
  }, [setHeight]);
};

export const HeightContextProvider = (props: { children?: ReactNode }) => {
  const [state, setState] = useState(initialState);

  return (
    <HeightStateContext.Provider value={state}>
      <SetHeightStateContext.Provider value={setState}>
        {props.children}
      </SetHeightStateContext.Provider>
    </HeightStateContext.Provider>
  );
};
