import {
  endOfMonth,
  endOfWeek,
  isBefore,
  isSameDay,
  startOfMonth,
  startOfWeek,
} from 'date-fns';

import { KiDateRange, KiDateUnit } from '../../domain/ki/types';

// 検索期間の種類
export type SearchType = 'weekly' | 'monthly';

/**
 * 月曜を週初として、週の開始日を返す関数
 */
const startOfWeekOnMonday = (date: Date) =>
  startOfWeek(date, { weekStartsOn: 1 });

/**
 * 月曜を週初として、週の終了日を返す関数
 */
const endOfWeekOnMonday = (date: Date) => endOfWeek(date, { weekStartsOn: 1 });

/**
 * 検索されている期間から、最適なDK-SIS期間を導出する関数
 * @returns DK-SIS期間
 */
export const calculateOptimalDkSisPeriod = ({
  endDateOfSearch,
  type,
  latestBaseDate: {
    latestBaseDateForWeeklySisData,
    latestBaseDateForMonthlySisData,
  },
}: {
  /** 検索されている期間の最終日 */
  endDateOfSearch: string;
  /** 検索期間の種類 */
  type: SearchType;
  latestBaseDate: {
    /** 最新基準日(週) */
    latestBaseDateForWeeklySisData: string;
    /** 最新基準日(月) */
    latestBaseDateForMonthlySisData: string;
  };
}): {
  startDate: Date;
  endDate: Date;
} => {
  const endDateOfSearchDate = new Date(endDateOfSearch);

  switch (type) {
    case 'weekly': {
      const latestBaseDate = {
        // 最新基準日の週初
        startDateForWeekly: startOfWeekOnMonday(
          new Date(latestBaseDateForWeeklySisData)
        ),
        // 最新基準日の週末
        endDateForWeekly: endOfWeekOnMonday(
          new Date(latestBaseDateForWeeklySisData)
        ),
      };

      // 検索期間の最終日が 最新基準日より過去
      if (isBefore(endDateOfSearchDate, latestBaseDate.startDateForWeekly)) {
        return {
          startDate: startOfWeekOnMonday(endDateOfSearchDate),
          endDate: endOfWeekOnMonday(endDateOfSearchDate),
        };
      }
      // 検索期間の最終日が 最新基準日に含まれる or 検索期間の最終日が 最新基準日より未来
      return {
        startDate: latestBaseDate.startDateForWeekly,
        endDate: latestBaseDate.endDateForWeekly,
      };
    }
    case 'monthly': {
      const latestBaseDate = {
        // 最新基準日の月初
        startDateForMonthly: startOfMonth(
          new Date(latestBaseDateForMonthlySisData)
        ),
        // 最新基準日の月末
        endDateForMonthly: endOfMonth(
          new Date(latestBaseDateForMonthlySisData)
        ),
      };

      // 検索期間の最終日が 最新基準日より過去
      if (isBefore(endDateOfSearchDate, latestBaseDate.startDateForMonthly)) {
        return {
          startDate: startOfMonth(endDateOfSearchDate),
          endDate: endOfMonth(endDateOfSearchDate),
        };
      }
      // 検索期間の最終日が 最新基準日に含まれる or 検索期間の最終日が 最新基準日より未来
      return {
        startDate: latestBaseDate.startDateForMonthly,
        endDate: latestBaseDate.endDateForMonthly,
      };
    }
  }
};

/**
 * dateRangeから、検索期間の種類を返す関数
 * @returns 検索期間の種類
 */
export const getSearchTypeFromDateRange = (
  args:
    | { dateRange: Exclude<KiDateRange, 'カスタム'> }
    | {
        dateRange: 'カスタム';
        optionForCustom: Parameters<typeof getSearchTypeFromDateRangeCustom>[0];
      }
): SearchType => {
  switch (args.dateRange) {
    case '前日':
    case '当日':
    case '前週':
    case '今週':
    case '過去7日':
    case '過去14日':
    case '過去28日':
      return 'weekly';
    case '前月':
    case '今月':
      return 'monthly';
    case 'カスタム': {
      return getSearchTypeFromDateRangeCustom(args.optionForCustom);
    }
  }
};

const getSearchTypeFromDateRangeCustom = (
  optionForCustom: (
    | {
        comparativeSection: '平日/土日祝比較';
        ymdComparisonList: string[];
      }
    | {
        comparativeSection: 'カスタム';
      }
    | {
        comparativeSection: '前月' | '前年同月';
      }
  ) & {
    dateUnit: KiDateUnit;
    ymdList: string[];
  }
): SearchType => {
  if (optionForCustom.dateUnit === '自由選択') {
    return 'weekly';
  }
  const allYmdList = [];
  switch (optionForCustom.comparativeSection) {
    case '前月':
    case '前年同月':
      return 'monthly';
    case 'カスタム':
      allYmdList.push(...optionForCustom.ymdList);
      break;
    case '平日/土日祝比較':
      allYmdList.push(
        ...optionForCustom.ymdList
          .concat(optionForCustom.ymdComparisonList)
          .sort((a, b) => (a < b ? -1 : 1))
      );
      break;
  }

  if (allYmdList.length === 0) return 'weekly';

  const startDate = new Date(`${allYmdList.at(0)}`);
  const endDate = new Date(`${allYmdList.at(-1)}`);
  return isSameDay(startOfMonth(startDate), startDate) &&
    isSameDay(endOfMonth(startDate), endDate)
    ? 'monthly'
    : 'weekly';
};
