import { AxiosResponse } from 'axios';
import { call, fork, put, select, take, takeEvery } from 'redux-saga/effects';

import { DataDai, SettingsOptionsDai } from '../domain/dai/types';
import { Column, LoadingState } from '../domain/schemas';
import { ShuOption } from '../domain/shu';

import {
  ChangeDataDaiColumnsOrderAction,
  DataDaiActionCreators,
  DataDaiActionTypes,
  FetchDataDaiAction,
  dataDaiColumnsOrderSelector,
  dataDaiDataColumnsSelector,
  dataDaiOrderedDataSelector,
  dataDaiParamsSelector,
} from '../redux/server/dataDai';
import {
  SettingsOptionsDaiActionTypes,
  settingsOptionsDaiLoadingStateSelector,
  settingsOptionsDaiSelector,
} from '../redux/server/settingsOptionsDai';
import {
  selectUnitDataListReportsCurrentShuAction,
  unitDataListReportsSelectedCurrentShuSelector,
} from '../redux/ui/unitDataListReportsSetting';
import { Api, buildConfig } from '../utils/api';
import { recalcColumnOrder } from '../utils/orderedCell';
import { convertShuOption, existShuOption } from '../utils/shu';
import { handleErrorSaga } from './errorSaga';

/**
 * 台別データ一覧のデータを取得する
 * @param api AxiosInstance
 * @param action Action
 */
function* fetchDataDaiSaga(api: Api, action: FetchDataDaiAction) {
  try {
    yield put(DataDaiActionCreators.fetchDataDaiRequestAction());
    const response: AxiosResponse<DataDai> = yield call(
      api.get,
      '/data/dai',
      buildConfig(action.payload.params)
    );
    yield put(DataDaiActionCreators.fetchDataDaiSuccessAction(response.data));
  } catch (error: unknown) {
    yield put(DataDaiActionCreators.renewDataDaiAction());
    yield fork(handleErrorSaga, error);
  }
}

// ハイライト機種の平均合計行データを取得する
function* fetchDataDaiHighlightAverageSaga(
  api: Api,
  action: FetchDataDaiAction
) {
  try {
    const response: AxiosResponse<DataDai> = yield call(
      api.get,
      '/data/dai',
      buildConfig({
        ...action.payload.params,
        containsAverage: true,
        containsData: false,
      })
    );
    const dataDai = response.data;

    yield put(
      DataDaiActionCreators.fetchDataDaiHighlightAverageSuccessAction(dataDai)
    );
  } catch (error: unknown) {
    yield put(DataDaiActionCreators.renewDataDaiAction());
    yield fork(handleErrorSaga, error);
  }
}

/**
 * 表示項目が変更されている場合並び替えに反映する
 */
function* columnsOrderCheckSaga() {
  const fields: Column[] = yield select(dataDaiDataColumnsSelector);

  const fieldCodes = fields.map((field) => field.code);

  // テーブル列の並び順
  const nowColumnsOrder: string[] | undefined = yield select(
    dataDaiColumnsOrderSelector
  );

  // 並び替え未設定時はデフォルトを使用
  const columnsOrder =
    !nowColumnsOrder || nowColumnsOrder.length === 0
      ? fieldCodes
      : nowColumnsOrder;

  // 機種名と台番号は常に先頭
  const sorted = [
    ...fieldCodes.slice(0, 2),
    ...fieldCodes
      .slice(2)
      .sort((a, b) => columnsOrder.indexOf(a) - columnsOrder.indexOf(b)),
  ];

  yield put(DataDaiActionCreators.selectDataDaiColumnsOrderAction(sorted));
}

/**
 * ドラッグ＆ドロップしたセルのIDを元に並び替え情報を登録する
 */
function* changeDataDaiColumnsOrderSaga(
  action: ChangeDataDaiColumnsOrderAction
) {
  const tableData: DataDai['data'] = yield select(dataDaiOrderedDataSelector);

  const ordered = recalcColumnOrder(
    tableData.columns,
    action.payload.draggedId,
    action.payload.droppedId
  );

  yield put(DataDaiActionCreators.selectDataDaiColumnsOrderAction(ordered));
}

/**
 * リクエストした種別のデータとレスポンスの種別データが不一致だったときに、レスポンスの値で上書きする
 *
 * 種別の不一致は種別が削除されたとき、あるいは共有グループをまたいだお気に入りで発生する
 */
function* validateShuSaga() {
  const currentShu: ShuOption = yield select(
    unitDataListReportsSelectedCurrentShuSelector
  );
  // currentShuが存在しない場合には初期リクエストになるため何もしない
  if (currentShu == null) {
    return;
  }

  const setting: DataDai['setting'] = yield select(dataDaiParamsSelector);
  const exist = existShuOption(setting, currentShu);
  if (exist) {
    return;
  }

  const settingsOptionsLoadingState: LoadingState = yield select(
    settingsOptionsDaiLoadingStateSelector
  );
  if (settingsOptionsLoadingState !== 'loaded') {
    yield take(
      SettingsOptionsDaiActionTypes.FETCH_SETTINGS_OPTIONS_DAI_SUCCESS
    );
  }
  // FETCH_SETTINGS_OPTIONS_KI_SUCCESSを待つのでundefinedにならない
  const settingsOptions: SettingsOptionsDai = yield select(
    settingsOptionsDaiSelector
  );

  const shuOption = convertShuOption(
    setting,
    settingsOptions.searchCondition.shuGroupList
  );
  if (shuOption == null) {
    throw new Error('適切な種別がsettingsOptionsで見つかりませんでした');
  }

  yield put(selectUnitDataListReportsCurrentShuAction(shuOption));
}

function* handleFetchDaiSaga(api: Api) {
  yield takeEvery(DataDaiActionTypes.FETCH_DATA_DAI, fetchDataDaiSaga, api);
  // 表示項目と並び替えのチェック
  yield takeEvery(
    DataDaiActionTypes.FETCH_DATA_DAI_SUCCESS,
    columnsOrderCheckSaga
  );

  yield takeEvery(DataDaiActionTypes.FETCH_DATA_DAI_SUCCESS, validateShuSaga);
}

function* handleChangeColumnsOrderSaga() {
  // 並び替え時
  yield takeEvery(
    DataDaiActionTypes.CHANGE_DATA_DAI_COLUMNS_ORDER,
    changeDataDaiColumnsOrderSaga
  );
}

function* handleFetchDataDaiHighlightAverageSaga(api: Api) {
  yield takeEvery(
    DataDaiActionTypes.FETCH_DATA_DAI_HIGHLIGHT_AVERAGE_REQUEST,
    fetchDataDaiHighlightAverageSaga,
    api
  );
}

export function* dataDaiSagas(context: { api: Api }) {
  yield fork(handleFetchDaiSaga, context.api);
  yield fork(handleChangeColumnsOrderSaga);
  yield fork(handleFetchDataDaiHighlightAverageSaga, context.api);
}
