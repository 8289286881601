import { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import {
  SettingsFavoritesActionCreators,
  favoritesLoadingStateSelector,
  favoritesSelector,
} from '../../../redux/ui/settingsFavorites';
import { CompressPageSetting } from './CompressPageSetting';
import { Favorite } from './Favorite';
import { InitRequest } from './InitRequest';
import { ShortenedHashPageSetting } from './ShortenedHashPageSetting';

export const QueryStringHandler = () => {
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();

  const favId = searchParams.get('fav_id');
  const q = searchParams.get('q');
  const s = searchParams.get('s');

  const settingsFavorite = useSelector(favoritesSelector, shallowEqual);
  const favoritesLoadingState = useSelector(
    favoritesLoadingStateSelector,
    shallowEqual
  );

  useEffect(() => {
    if (favoritesLoadingState === 'prepare') {
      dispatch(SettingsFavoritesActionCreators.fetchSettingsFavoritesAction());
    }
  }, [dispatch, favoritesLoadingState]);

  if (
    (favId != null && q != null) ||
    (favId != null && s != null) ||
    (q != null && s != null)
  ) {
    throw new Error('クエリ文字列のfavIdとqとsは同時に指定できません');
  }

  if (favId != null) {
    return (
      <Favorite favId={Number(favId)} settingsFavorite={settingsFavorite} />
    );
  }

  if (q != null) {
    return <CompressPageSetting q={q} />;
  }

  if (s != null) {
    return <ShortenedHashPageSetting s={s} />;
  }

  return <InitRequest />;
};
