import { SxProps, Theme } from '@mui/material';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { FC, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { useHeaderState } from '../../contexts/HeaderContext';

import { mergeSxProps } from '../../utils/mergeSxProps';
import { PlaceholderLoading } from '../atoms/PlaceholderLoading/PlaceholderLoading';
import { ScreenOnly } from '../atoms/ScreenOnly/ScreenOnly';
import { GlobalHeader } from '../organisms/GlobalHeader/GlobalHeader';
import { GlobalNavigation } from '../organisms/GlobalNavigation/GlobalNavigation';

type PresenterProps = {
  isOpenMenu: boolean;
};

const drawerWidth = 260;

const styles: Record<string, SxProps<Theme>> = {
  root: {
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  toolbarSpace: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: (theme) => theme.spacing(0, 1),
    minHeight: '64px',
  },
  contents: {
    padding: 0,
    flex: 1,
    minWidth: 0,
  },
  drawer: {
    width: `${drawerWidth}px`,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: `${drawerWidth}px`,
    transition: (theme) =>
      theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
  },
  drawerClose: {
    transition: (theme) =>
      theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    overflowX: 'hidden',
    width: 57,
  },
};

const GenericLayoutPresenter: FC<PresenterProps> = (props) => {
  const { isOpenMenu } = props;

  return (
    <Box sx={styles.root}>
      <ScreenOnly>
        <Drawer
          variant="permanent"
          sx={mergeSxProps(
            styles.drawer,
            isOpenMenu ? styles.drawerOpen : styles.drawerClose
          )}
          PaperProps={{
            sx: mergeSxProps(
              styles.drawer,
              isOpenMenu ? styles.drawerOpen : styles.drawerClose
            ),
          }}
        >
          <GlobalNavigation />
        </Drawer>
      </ScreenOnly>
      <Box component="main" sx={styles.contents}>
        <Suspense
          fallback={
            <>
              <GlobalHeader pageTitle={undefined} />
              <PlaceholderLoading />
            </>
          }
        >
          <Outlet />
        </Suspense>
      </Box>
    </Box>
  );
};

export const GenericLayout: FC = () => {
  const { isOpenMenu } = useHeaderState();

  return <GenericLayoutPresenter isOpenMenu={isOpenMenu} />;
};
