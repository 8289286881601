import { createSelector } from 'reselect';

import { DataHallKi, DataHallKiParams } from '../../domain/dataHallKi';
import { DkSisSearchParams } from '../../domain/dkSis';
import { HallReportsFavorite } from '../../domain/hall/types';
import { HallReportsFormConditions } from '../../domain/hallReportsFormConditions';
import { Option, OrderType } from '../../domain/schemas';
import { ShuOption } from '../../domain/shu';

import { RootState } from '../../store';
import { getOrderedTableData } from '../../utils/orderedCell';
import { hallReportsSearchConditionSelector } from '../ui/hallReportsSetting';

/**
 * Action Types
 */

const FETCH_DATA_HALL_KI = 'FETCH_DATA_HALL_KI' as const;
const FETCH_DATA_HALL_KI_REQUEST = 'FETCH_DATA_HALL_KI_REQUEST' as const;
const FETCH_DATA_HALL_KI_SUCCESS = 'FETCH_DATA_HALL_KI_SUCCESS' as const;
const INIT_DATA_HALL_KI = 'INIT_DATA_HALL_KI' as const;
const SEARCH_DATA_HALL_KI = 'SEARCH_DATA_HALL_KI' as const;
const SEARCH_DATA_HALL_KI_FIELD_TYPE = 'SEARCH_DATA_HALL_KI_FIELD_TYPE' as const;
const SEARCH_DATA_HALL_KI_SORT = 'SEARCH_DATA_HALL_KI_SORT' as const;
const CLEAR_DATA_HALL_KI = 'CLEAR_DATA_HALL_KI' as const;
const CLEAR_ALL_DATA_HALL_KI = 'CLEAR_ALL_DATA_HALL_KI' as const;
const SELECT_DATA_HALL_KI_COLUMNS_ORDER = 'SELECT_DATA_HALL_KI_COLUMNS_ORDER' as const;
const SELECT_DATA_HALL_KI_COLUMNS_ORDER_ALL = 'SELECT_DATA_HALL_KI_COLUMNS_ORDER_ALL' as const;
const CHANGE_DATA_HALL_KI_COLUMNS_ORDER = 'CHANGE_DATA_HALL_KI_COLUMNS_ORDER' as const;
const SEARCH_DATA_HALL_KI_DKSIS_ON = 'SEARCH_DATA_HALL_KI_DKSIS_ON' as const;
const SEARCH_DATA_HALL_KI_DKSIS_OFF = 'SEARCH_DATA_HALL_KI_DKSIS_OFF' as const;
const SEARCH_DATA_HALL_KI_DKSIS = 'SEARCH_DATA_KI_DKSIS' as const;
const SEARCH_DATA_HALL_KI_FAVORITE = 'SEARCH_DATA_HALL_KI_FAVORITE' as const;
const RENEW_DATA_HALL_KI = 'RENEW_DATA_HALL_KI' as const;

export const DataHallKiActionTypes = {
  FETCH_DATA_HALL_KI,
  FETCH_DATA_HALL_KI_REQUEST,
  FETCH_DATA_HALL_KI_SUCCESS,
  INIT_DATA_HALL_KI,
  SEARCH_DATA_HALL_KI,
  SEARCH_DATA_HALL_KI_FIELD_TYPE,
  SEARCH_DATA_HALL_KI_SORT,
  CLEAR_DATA_HALL_KI,
  CLEAR_ALL_DATA_HALL_KI,
  SELECT_DATA_HALL_KI_COLUMNS_ORDER,
  SELECT_DATA_HALL_KI_COLUMNS_ORDER_ALL,
  CHANGE_DATA_HALL_KI_COLUMNS_ORDER,
  SEARCH_DATA_HALL_KI_DKSIS_ON,
  SEARCH_DATA_HALL_KI_DKSIS_OFF,
  SEARCH_DATA_HALL_KI_DKSIS,
  SEARCH_DATA_HALL_KI_FAVORITE,
  RENEW_DATA_HALL_KI,
};

/**
 * Action Creators
 */

/**
 * 種別毎の新台/メイン機種を取得する
 * @param shuCode 種別
 * @param params 検索条件
 */
function fetchDataHallKiAction(shuCode: string, params: DataHallKiParams) {
  return {
    type: FETCH_DATA_HALL_KI,
    payload: { shuCode, params },
  };
}

/**
 * APIにリクエスト前に呼ばれるもの
 * @param shuCode 種別コード
 */
function fetchDataHallKiRequestAction(shuCode: string) {
  return {
    type: FETCH_DATA_HALL_KI_REQUEST,
    payload: { shuCode },
  };
}

/**
 * データ取得成功時新台/メイン機種データを更新する
 * @param shuCode 種別コード
 * @param shu 新台/メイン機種データ
 */
function fetchDataHallKiSuccessAction(shuCode: string, shu: DataHallKi) {
  return {
    type: FETCH_DATA_HALL_KI_SUCCESS,
    payload: { shuCode, shu },
  };
}

/**
 * 指定した種別グループ・種別のデータを初回取得する
 * @param shu 種別データ（種別グループ・種別）
 */
function initDataHallKiAction(shu: ShuOption) {
  return {
    type: INIT_DATA_HALL_KI,
    payload: { shu },
  };
}

/**
 * 現在表示されている新台/メイン機種を指定した検索条件で再取得する（検索用）
 * @param params 検索フォームの検索条件
 */
function searchDataHallKiAction(params: HallReportsFormConditions) {
  return {
    type: SEARCH_DATA_HALL_KI,
    payload: { params },
  };
}

/**
 * 新台/メイン機種を取得する（お気に入り用）
 */
function searchDataHallKiFavoriteAction(favorite: HallReportsFavorite) {
  return {
    type: SEARCH_DATA_HALL_KI_FAVORITE,
    payload: { favorite },
  };
}

/**
 * 指定した表示項目に絞って新台/メイン機種テーブルデータを再取得する
 * @param shu 種別データ（種別グループ・種別）
 * @param fields 表示項目
 */
function searchDataHallKiFieldTypeAction(shu: ShuOption, fields: Option[]) {
  return {
    type: SEARCH_DATA_HALL_KI_FIELD_TYPE,
    payload: { shu, fields },
  };
}

/**
 * 指定したソート条件で新台/メイン機種
 * @param shu 種別データ（種別グループ・種別）
 * @param sort ソートする条件
 * @param order ソート順
 */
function searchDataHallKiSortAction(
  shu: ShuOption,
  sort: string,
  order: OrderType
) {
  return {
    type: SEARCH_DATA_HALL_KI_SORT,
    payload: { shu, sort, order },
  };
}

/**
 * 指定した種別の新台/メイン機種データを破棄する
 * @param shuCode 種別コード
 */
function clearDataHallKiAction(shuCode: string) {
  return {
    type: CLEAR_DATA_HALL_KI,
    payload: { shuCode },
  };
}

/**
 * 新台/メイン機種のデータを全て破棄する
 */
function clearAllDataHallKiAction() {
  return {
    type: CLEAR_ALL_DATA_HALL_KI,
  };
}

/**
 * 新台/メイン機種の並び替え情報を登録する（Sagaで並び替え確定時）
 * @param shuCode 種別コード
 * @param columnsOrder 並び替え情報
 */
function selectDataHallKiColumnsOrderAction(
  shuCode: string,
  columnsOrder: string[]
) {
  return {
    type: SELECT_DATA_HALL_KI_COLUMNS_ORDER,
    payload: { shuCode, columnsOrder },
  };
}

/**
 * 新台/メイン機種の並び替え情報を全て登録する（お気に入り適用時に使用）
 * @param columnsOrders 並び替え情報（全件）
 */
function selectDataHallKiColumnsOrderAllAction(
  columnsOrders: DataHallKiState['columnsOrder']
) {
  return {
    type: SELECT_DATA_HALL_KI_COLUMNS_ORDER_ALL,
    payload: { columnsOrders },
  };
}

/**
 * 新台/メイン機種の列の並び替え実行時にSagaで再計算する
 * @param shuCode 種別コード
 */
export function changeDataHallKiColumnsOrderAction(
  shuCode: string,
  draggedId?: string,
  droppedId?: string
) {
  return {
    type: CHANGE_DATA_HALL_KI_COLUMNS_ORDER,
    payload: { shuCode, draggedId, droppedId },
  };
}

/**
 * DK-SISを有効にする
 * @param shu 種別データ（種別グループ・種別）
 */
function searchDataHallKiDkSisOnAction(shu: ShuOption) {
  return {
    type: SEARCH_DATA_HALL_KI_DKSIS_ON,
    payload: { shu },
  };
}

/**
 * DK-SISを無効にする
 * @param shu 種別データ（種別グループ・種別）
 */
function searchDataHallKiDkSisOffAction(shu: ShuOption) {
  return {
    type: SEARCH_DATA_HALL_KI_DKSIS_OFF,
    payload: { shu },
  };
}

/**
 * 指定したDK-SISの検索条件で再取得する
 * @param shu 種別データ（種別グループ・種別）
 * @param params DK-SIS個別の検索パラメータ
 */
function searchDataHallKiDkSisAction(
  shu: ShuOption,
  params: DkSisSearchParams
) {
  return {
    type: SEARCH_DATA_HALL_KI_DKSIS,
    payload: { shu, params },
  };
}

/**
 * 初期化
 */
function renewDataHallKiAction() {
  return {
    type: RENEW_DATA_HALL_KI,
  };
}

export const DataHallKiActionCreators = {
  fetchDataHallKiAction,
  fetchDataHallKiRequestAction,
  fetchDataHallKiSuccessAction,
  initDataHallKiAction,
  searchDataHallKiAction,
  searchDataHallKiFieldTypeAction,
  searchDataHallKiSortAction,
  clearDataHallKiAction,
  clearAllDataHallKiAction,
  selectDataHallKiColumnsOrderAction,
  selectDataHallKiColumnsOrderAllAction,
  changeDataHallKiColumnsOrderAction,
  searchDataHallKiDkSisOnAction,
  searchDataHallKiDkSisOffAction,
  searchDataHallKiDkSisAction,
  searchDataHallKiFavoriteAction,
  renewDataHallKiAction,
};

/**
 * Actions
 */

export type FetchDataHallKiAction = ReturnType<typeof fetchDataHallKiAction>;
export type FetchDataHallKiSuccessAction = ReturnType<
  typeof fetchDataHallKiSuccessAction
>;
export type InitDataHallKiAction = ReturnType<typeof initDataHallKiAction>;
export type SearchDataHallKiAction = ReturnType<typeof searchDataHallKiAction>;
export type SearchDataHallKiFieldTypeAction = ReturnType<
  typeof searchDataHallKiFieldTypeAction
>;
export type SearchDataHallKiSortAction = ReturnType<
  typeof searchDataHallKiSortAction
>;
type ClearDataHallKiAction = ReturnType<typeof clearDataHallKiAction>;
type ClearAllDataHallKiAction = ReturnType<typeof clearAllDataHallKiAction>;
type SelectDataHallKiColumnsOrderAction = ReturnType<
  typeof selectDataHallKiColumnsOrderAction
>;
export type ChangeDataHallKiColumnsOrderAction = ReturnType<
  typeof changeDataHallKiColumnsOrderAction
>;
export type SearchDataHallKiDkSisOnAction = ReturnType<
  typeof searchDataHallKiDkSisOnAction
>;
export type SearchDataHallKiDkSisOffAction = ReturnType<
  typeof searchDataHallKiDkSisOffAction
>;
export type SearchDataHallKiDkSisAction = ReturnType<
  typeof searchDataHallKiDkSisAction
>;

export type SearchDataHallKiFavoriteAction = ReturnType<
  typeof searchDataHallKiFavoriteAction
>;

type DataHallKiAction =
  | FetchDataHallKiAction
  | FetchDataHallKiSuccessAction
  | ReturnType<typeof fetchDataHallKiRequestAction>
  | ReturnType<typeof fetchDataHallKiSuccessAction>
  | ReturnType<typeof selectDataHallKiColumnsOrderAllAction>
  | InitDataHallKiAction
  | SearchDataHallKiAction
  | SearchDataHallKiFieldTypeAction
  | SearchDataHallKiSortAction
  | ClearDataHallKiAction
  | ClearAllDataHallKiAction
  | SelectDataHallKiColumnsOrderAction
  | ChangeDataHallKiColumnsOrderAction
  | SearchDataHallKiDkSisOnAction
  | SearchDataHallKiDkSisOffAction
  | SearchDataHallKiDkSisAction
  | SearchDataHallKiFavoriteAction
  | ReturnType<typeof renewDataHallKiAction>;

/**
 * State
 */

export type DataHallKiState = {
  isLoading: {
    [key: string]: boolean | undefined;
  };
  shus: {
    [key: string]: DataHallKi | undefined;
  };
  columnsOrder: {
    [key: string]: string[] | undefined;
  };
};

// Stateの初期値
const initialState: DataHallKiState = {
  isLoading: {},
  shus: {},
  columnsOrder: {},
};

/**
 * Selector
 */

/**
 * 新台/メイン機種に関するデータ全てを取得する
 * @returns 新台/メイン機種に関するデータ
 */
const dataHallKiSelector = (state: RootState) => state.dataHallKi;

/**
 * 全ての新台/メイン機種データを取得する
 * @returns 全ての新台/メイン機種データ
 */
export const dataHallKiDataSelector = (state: RootState) => {
  return state.dataHallKi.shus;
};

/**
 * 店舗レポート 新台/メイン機種のローディング状態を全て取得する
 * @returns 全てのローディング状態
 */
const dataHallKiLoadingAllSelector = (state: RootState) =>
  state.dataHallKi.isLoading;

/**
 * 店舗レポート 新台/メイン機種の全テーブルのローディング状態を全て取得する
 *
 * 一つでもローディング状態のテーブルがあればtrueとなる
 */
export const dataHallKiIsAllLoadingSelector = createSelector(
  dataHallKiLoadingAllSelector,
  (all) => Object.values(all).some((item) => Boolean(item))
);

/**
 * 指定した種別コードと一致する新台/メイン機種データを取得する
 * @param shuCode 種別コード
 * @returns 新台/メイン機種データ
 */
export const dataHallKiDataByShuSelector = (shuCode: string) =>
  createSelector(
    [dataHallKiDataSelector, hallReportsSearchConditionSelector],
    (shus, searchCondition) => {
      const defaultValue: DataHallKi = {
        setting: { ...searchCondition },
      };

      return shus[shuCode] ?? defaultValue;
    }
  );

/**
 * 並び替え後のテーブルデータを取得する
 * @param shuCode 種別コード
 * @returns 並び替え後のテーブルデータ
 */
export const dataHallKiOrderedData = (shuCode: string) =>
  createSelector(
    [
      dataHallKiDataByShuSelector(shuCode),
      dataHallKiColumnsOrderSelector(shuCode),
    ],
    (tableData, ordered) => {
      if (!ordered) return tableData.data;

      return getOrderedTableData(
        ordered,
        tableData?.data?.columns || [],
        tableData?.data?.rows || []
      );
    }
  );

/**
 * 指定した種別の新台/メイン機種データが存在するか取得する
 * @param shu 種別データ（種別グループ・種別）
 * @returns 存在するか（存在する場合: true）
 */
export const dataHallKiDataIsExistSelector = (shu: ShuOption) =>
  createSelector(dataHallKiSelector, (data) => {
    return !!data.shus[shu.code];
  });

/**
 * 指定した種別の新台/メイン機種データの検索条件を取得する
 * @param shu 種別データ（種別グループ・種別）
 * @returns 現在の検索条件
 */
export const dataHallKiDataSearchConditionSelector = (shu: ShuOption) =>
  createSelector(dataHallKiDataByShuSelector(shu.code), (data) => {
    return data.setting;
  });

/**
 * 指定した種別の新台/メイン機種で選択されている表示項目一覧を取得する
 * @param shu 種別データ（種別グループ・種別）
 * @returns 現在選択されている表示項目一覧
 */
export const dataHallKiSelectedFieldsSelector = (shu: ShuOption) =>
  createSelector(dataHallKiDataSearchConditionSelector(shu), (param) => {
    return param.fields ?? [];
  });

/**
 * 指定した種別の新台/メイン機種が現在ローディング状態か取得する
 * @param shu 種別データ（種別グループ・種別）
 * @returns ローディング状態
 */
export const dataHallKiDataIsLoadingSelector = (shu: ShuOption) =>
  createSelector(dataHallKiSelector, (dataHallKi) => {
    return dataHallKi.isLoading[shu.code] ?? false;
  });

/**
 * 新台/メイン機種テーブルのすべての検索条件を取得する（お気に入り保存用）
 * @returns 現在の新台/メイン機種テーブルのすべての検索条件
 */
export const dataHallKiSettingSelector = createSelector(
  dataHallKiDataSelector,
  (data) => {
    return Object.fromEntries(
      Object.entries(data).map((entry) => {
        const [key, value] = entry;
        return [key, value?.setting];
      })
    );
  }
);

/**
 * 新台/メイン機種のテーブルデータのカラム部分（カラム行）のみ取得
 * @param shuCode 種別コード
 * @returns テーブルデータのカラム行（rowsを除いた部分）
 */
export const dataHallKiDataColumnsSelector = (shuCode: string) =>
  createSelector(dataHallKiSelector, (data) => {
    const dataHallKi = data?.shus[shuCode];
    return dataHallKi?.data?.columns ?? [];
  });

/**
 * 新台/メイン機種の全ての並び替え情報を取得
 * @returns 全ての並び替え情報
 */
export const dataHallKiColumnsOrderDataSelector = (state: RootState) =>
  state.dataHallKi.columnsOrder;

/**
 * 指定した種別の並び替え情報を取得
 * @param shuCode 種別コード
 * @returns 並び替え情報
 */
export const dataHallKiColumnsOrderSelector = (shuCode: string) =>
  createSelector(
    dataHallKiColumnsOrderDataSelector,
    (columnsOrder) => columnsOrder[shuCode]
  );

/**
 * データのある種別グループコードを返す
 */
export const dataHallKiGroupCodesWithDataSelector = createSelector(
  dataHallKiDataSelector,
  (data) =>
    Object.keys(data).filter((x) => {
      const dataByShuCode = data[x];
      if (!dataByShuCode || !dataByShuCode.data) {
        return false;
      }
      return dataByShuCode.data.rows?.length > 0;
    })
);

/**
 * 新台/メイン機種テーブルで検索済みの期間を取得する
 * @returns 検索期間
 */
export const dataHallKiYmdListSelector = createSelector(
  dataHallKiDataSelector,
  (shus) => {
    const keys = Object.keys(shus);
    if (!shus || keys.length === 0) {
      return null;
    }
    return shus[keys[0]]?.setting.ymdList;
  }
);

/**
 * 新台/メイン機種テーブルで検索済みの店舗を取得する
 * @returns 店舗
 */
export const dataHallKiHallsSelector = createSelector(
  dataHallKiDataSelector,
  (shus) => {
    const keys = Object.keys(shus);
    if (!shus || keys.length === 0) {
      return null;
    }
    return shus[keys[0]]?.setting.halls;
  }
);

/**
 * Reducer
 */

export function dataHallKiReducer(
  state = initialState,
  action: DataHallKiAction
): DataHallKiState {
  switch (action.type) {
    case FETCH_DATA_HALL_KI_REQUEST:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [action.payload.shuCode]: true,
        },
      };
    case FETCH_DATA_HALL_KI_SUCCESS:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [action.payload.shuCode]: false,
        },
        shus: {
          ...state.shus,
          [action.payload.shuCode]: action.payload.shu,
        },
      };
    case CLEAR_DATA_HALL_KI:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [action.payload.shuCode]: false,
        },
        shus: {
          ...state.shus,
          [action.payload.shuCode]: undefined,
        },
      };
    case CLEAR_ALL_DATA_HALL_KI:
      return {
        ...state,
        shus: {},
        columnsOrder: initialState.columnsOrder,
      };
    case SELECT_DATA_HALL_KI_COLUMNS_ORDER:
      return {
        ...state,
        columnsOrder: {
          ...state.columnsOrder,
          [action.payload.shuCode]: action.payload.columnsOrder,
        },
      };
    case SELECT_DATA_HALL_KI_COLUMNS_ORDER_ALL:
      return {
        ...state,
        columnsOrder: action.payload.columnsOrders,
      };
    case RENEW_DATA_HALL_KI: {
      return initialState;
    }
    default:
      return state;
  }
}
