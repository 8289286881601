/**
 * sisTypesがパチンコ(コード番号6以下のもの)の場合タイプNo.を+1ずつする
 *
 * https://github.com/DKClaris/claris-general/issues/3243
 */
export const updateSisTypesV1 = (sisTypes: string[] | undefined) => {
  if (!sisTypes) return;
  return sisTypes.map((sisType) => {
    const sisTypeNumber = Number(sisType);
    if (sisTypeNumber <= 6) {
      return String(sisTypeNumber + 1).padStart(2, '0');
    }
    return sisType;
  });
};
