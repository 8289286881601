import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

type HeaderState = {
  isOpenMenu: boolean;
  isOpenMenuChild: boolean;
  isOpenFavoriteMenuChild: boolean;
};
const initialState: HeaderState = {
  isOpenMenu:
    localStorage.getItem('isOpenMenu') != null
      ? localStorage.getItem('isOpenMenu') === 'open'
      : true,
  isOpenMenuChild: false,
  isOpenFavoriteMenuChild:
    localStorage.getItem('isOpenFavoriteMenuChild') != null
      ? localStorage.getItem('isOpenFavoriteMenuChild') === 'open'
      : false,
};

const HeaderStateContext = createContext<HeaderState>(initialState);
const SetHeaderStateContext = createContext<
  Dispatch<SetStateAction<HeaderState>>
>(() => {
  // do nothing
});

export const useHeaderState = () => {
  return useContext(HeaderStateContext);
};

export const useSetHeaderState = () => {
  return useContext(SetHeaderStateContext);
};

export const HeaderContextProvider = (props: { children?: ReactNode }) => {
  const [state, setState] = useState(initialState);

  useEffect(() => {
    localStorage.setItem('isOpenMenu', state.isOpenMenu ? 'open' : '');
  }, [state.isOpenMenu]);

  useEffect(() => {
    localStorage.setItem(
      'isOpenFavoriteMenuChild',
      state.isOpenFavoriteMenuChild ? 'open' : ''
    );
  }, [state.isOpenFavoriteMenuChild]);

  return (
    <HeaderStateContext.Provider value={state}>
      <SetHeaderStateContext.Provider value={setState}>
        {props.children}
      </SetHeaderStateContext.Provider>
    </HeaderStateContext.Provider>
  );
};
