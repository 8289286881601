import { format } from 'date-fns';

import { ChainReportsFormConditions } from '../domain/chainReportsFormConditions';
import { ChainStoreReportsSettingDateRangeParams } from '../domain/chainStoreReportsSettingDateRangeParams';

import {
  makeDateFromDateUnitAndRange,
  reportsDateRanges,
  reportsDateUnits,
} from './reportsDateRange';

// チェーン店レポート検索条件の初期値
export const dataChainStoreSettingDefaultValue = (): {
  setting: ChainReportsFormConditions;
  dateRangeParams: ChainStoreReportsSettingDateRangeParams;
} => {
  const current = new Date();
  const dateUnit = reportsDateUnits[0];
  const dateRange = reportsDateRanges[0];

  // 指定された期間から日付を算出する
  const date = makeDateFromDateUnitAndRange(dateRange, dateUnit, current);
  const ymdList = date.ymdList.map((date) => format(date, 'yyyy-MM-dd'));
  const ymdComparisonList = date.ymdComparisonList.map((date) =>
    format(date, 'yyyy-MM-dd')
  );

  return {
    setting: {
      excludeToday: true,
      ymdList,
      ymdComparisonList,
      containsAreaAverage: false,
    },
    dateRangeParams: {
      dateUnit,
      dateRange,
      isComparison: true,
    },
  };
};
