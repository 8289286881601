import { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { userSelector } from '../redux/ui/user';

type ReturnValue =
  | {
      isLoading: true;
      isAdmin: undefined;
      isMaintenanceUser: undefined;
      role: undefined;
    }
  | {
      isLoading: false;
      isAdmin: boolean;
      isMaintenanceUser: boolean;
      role: string;
    };

export const useRole = (): ReturnValue => {
  const user = useSelector(userSelector, shallowEqual);

  const result = useMemo(() => {
    if (user == null) {
      return {
        isLoading: true,
        isAdmin: undefined,
      } as const;
    }

    return {
      isLoading: false,
      isAdmin: user.role === 'admin',
      isMaintenanceUser: user.role === 'maintenance',
      role: user.role,
    } as const;
  }, [user]);

  return result;
};
