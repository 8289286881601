import { AxiosResponse } from 'axios';
import { call, fork, put, takeEvery } from 'redux-saga/effects';

import { SettingsOptionsKi } from '../domain/settingsOptionsKi';

import {
  SettingsOptionsKiActionCreators,
  SettingsOptionsKiActionTypes,
} from '../redux/server/settingsOptionsKi';
import { Api, buildConfig } from '../utils/api';
import { handleErrorSaga } from './errorSaga';

export function* fetchSettingsOptionsKiSaga(api: Api) {
  try {
    yield put(
      SettingsOptionsKiActionCreators.fetchSettingsOptionsKiRequestAction()
    );
    const response: AxiosResponse<SettingsOptionsKi> = yield call(
      api.get,
      '/settings/options/ki',
      buildConfig()
    );
    const settingsOptionsKi: SettingsOptionsKi = response.data;
    yield put(
      SettingsOptionsKiActionCreators.fetchSettingsOptionsKiSuccessAction(
        settingsOptionsKi
      )
    );
  } catch (error: unknown) {
    yield put(SettingsOptionsKiActionCreators.renewSettingsOptionsKiAction());
    yield fork(handleErrorSaga, error);
  }
}

function* handleFetchSettingsOptionsKiSaga(api: Api) {
  yield takeEvery(
    SettingsOptionsKiActionTypes.FETCH_SETTINGS_OPTIONS_KI,
    fetchSettingsOptionsKiSaga,
    api
  );
}

export function* settingsOptionsKiSagas(context: { api: Api }) {
  yield fork(handleFetchSettingsOptionsKiSaga, context.api);
}
