import { AxiosResponse } from 'axios';
import { call, fork, put, takeEvery } from 'redux-saga/effects';

import { SettingsOptionsUser } from '../domain/settingsOptionsUser';

import {
  SettingsOptionsUserActionCreators,
  SettingsOptionsUserActionTypes,
} from '../redux/server/settingsOptionsUser';
import { Api, buildConfig } from '../utils/api';
import { handleErrorSaga } from './errorSaga';

export function* fetchSettingsOptionsUserSaga(api: Api) {
  try {
    yield put(
      SettingsOptionsUserActionCreators.fetchSettingsOptionsUserRequestAction()
    );
    const response: AxiosResponse<SettingsOptionsUser> = yield call(
      api.get,
      '/settings/options/user',
      buildConfig()
    );

    yield put(
      SettingsOptionsUserActionCreators.fetchSettingsOptionsUserSuccessAction(
        response.data
      )
    );
  } catch (error: unknown) {
    yield put(
      SettingsOptionsUserActionCreators.renewSettingsOptionsUserAction()
    );
    yield fork(handleErrorSaga, error);
  }
}

function* handleFetchSettingsOptionsUserSaga(api: Api) {
  yield takeEvery(
    SettingsOptionsUserActionTypes.FETCH_SETTINGS_OPTIONS_USER,
    fetchSettingsOptionsUserSaga,
    api
  );
}

export function* settingsOptionsUserSagas(context: { api: Api }) {
  yield fork(handleFetchSettingsOptionsUserSaga, context.api);
}
