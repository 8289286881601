import { SettingsModesFormCondition } from '../../domain/SettingsModesFormCondition';
import { LoadingState } from '../../domain/schemas';
import { SettingsModesHalls } from '../../domain/settingsModes';

// Action Types

const FETCH_DATA_SETTINGS_MODES_HALLS = 'FETCH_DATA_SETTINGS_MODES_HALLS' as const;
const FETCH_DATA_SETTINGS_MODES_HALLS_REQUEST = 'FETCH_DATA_SETTINGS_MODES_HALLS_REQUEST' as const;
const FETCH_DATA_SETTINGS_MODES_HALLS_SUCCESS = 'FETCH_DATA_SETTINGS_MODES_HALLS_SUCCESS' as const;
const FETCH_DATA_SETTINGS_MODES_HALLS_FAILURE = 'FETCH_DATA_SETTINGS_MODES_HALLS_FAILURE' as const;

export const DataSettingsModesHallsActionTypes = {
  FETCH_DATA_SETTINGS_MODES_HALLS,
  FETCH_DATA_SETTINGS_MODES_HALLS_REQUEST,
  FETCH_DATA_SETTINGS_MODES_HALLS_SUCCESS,
  FETCH_DATA_SETTINGS_MODES_HALLS_FAILURE,
};

// Action Creators

function fetchDataSettingsModesHallsAction(params: SettingsModesFormCondition) {
  return {
    type: FETCH_DATA_SETTINGS_MODES_HALLS,
    payload: { params },
  };
}

function fetchDataSettingsModesHallsRequestAction() {
  return {
    type: FETCH_DATA_SETTINGS_MODES_HALLS_REQUEST,
  };
}

function fetchDataSettingsModesHallsSuccessAction(
  settingsModesHalls: SettingsModesHalls
) {
  return {
    type: FETCH_DATA_SETTINGS_MODES_HALLS_SUCCESS,
    payload: { settingsModesHalls },
  };
}

function renewDataSettingsModesHallsAction() {
  return {
    type: FETCH_DATA_SETTINGS_MODES_HALLS_FAILURE,
  };
}

export const DataSettingsModesHallsActionCreators = {
  fetchDataSettingsModesHallsAction,
  fetchDataSettingsModesHallsRequestAction,
  fetchDataSettingsModesHallsSuccessAction,
  renewDataSettingsModesHallsAction,
};

export type FetchDataSettingsModesHallsAction = ReturnType<
  typeof fetchDataSettingsModesHallsAction
>;

type DataSettingsModesHallsAction =
  | FetchDataSettingsModesHallsAction
  | ReturnType<typeof fetchDataSettingsModesHallsRequestAction>
  | ReturnType<typeof fetchDataSettingsModesHallsSuccessAction>
  | ReturnType<typeof renewDataSettingsModesHallsAction>;

// State

type DataSettingsModesHallsState = {
  settingsModesHalls: SettingsModesHalls | undefined;
  loadingState: LoadingState;
};

const initialState: DataSettingsModesHallsState = {
  settingsModesHalls: undefined,
  loadingState: 'prepare',
};

// Selector

/**
 * モード登録店舗一覧のデータ
 */
export function dataSettingsModesHallsSelector(rootState: {
  dataSettingsModesHalls: DataSettingsModesHallsState;
}) {
  return rootState.dataSettingsModesHalls.settingsModesHalls;
}

/**
 * モード登録店舗一覧のデータのローディング状態
 */
export function dataSettingsModesHallsLoadingSelector(rootState: {
  dataSettingsModesHalls: DataSettingsModesHallsState;
}) {
  return rootState.dataSettingsModesHalls.loadingState;
}

// Reducer

export function dataSettingsModesHallsReducer(
  state = initialState,
  action: DataSettingsModesHallsAction
): DataSettingsModesHallsState {
  switch (action.type) {
    case FETCH_DATA_SETTINGS_MODES_HALLS_REQUEST:
      return {
        ...state,
        loadingState: 'loading',
      };
    case FETCH_DATA_SETTINGS_MODES_HALLS_SUCCESS:
      return {
        ...state,
        settingsModesHalls: action.payload.settingsModesHalls,
        loadingState: 'loaded',
      };
    case FETCH_DATA_SETTINGS_MODES_HALLS_FAILURE:
      return initialState;
    default:
      return state;
  }
}
