import { AxiosResponse } from 'axios';
import { call, fork, put, takeEvery } from 'redux-saga/effects';

import { InformationData } from '../domain/information';

import {
  InformationActionCreators,
  InformationActionTypes,
} from '../redux/ui/information';
import { Api, buildConfig } from '../utils/api';
import { handleErrorSaga } from './errorSaga';

export function* fetchInformationSaga(api: Api) {
  try {
    yield put(InformationActionCreators.fetchInformationRequestAction());
    const response: AxiosResponse<InformationData> = yield call(
      api.get,
      '/information',
      buildConfig()
    );
    const information: InformationData = response.data;
    yield put(
      InformationActionCreators.fetchInformationSuccessAction(information)
    );
  } catch (error: unknown) {
    yield put(InformationActionCreators.renewInformationAction());
    yield fork(handleErrorSaga, error);
  }
}

function* handleFetchInformationSaga(api: Api) {
  yield takeEvery(
    InformationActionTypes.FETCH_INFORMATION,
    fetchInformationSaga,
    api
  );
}

export function* informationSagas(context: { api: Api }) {
  yield fork(handleFetchInformationSaga, context.api);
}
