import { DataDepreciationResponse } from './types';

export const dataDepreciationHiddenByField = (
  dataDepreciation: DataDepreciationResponse,
  fields: string[] | undefined
): DataDepreciationResponse => {
  if (fields == null) {
    return dataDepreciation;
  }

  const hiddenIndexes = fields.map((field) => {
    return dataDepreciation.data.columns.findIndex(
      (column) => column.code === field
    );
  });

  return {
    ...dataDepreciation,
    data: {
      ...dataDepreciation.data,
      columns: dataDepreciation.data.columns.filter(
        (_, i) => !hiddenIndexes.includes(i)
      ),
      rows: dataDepreciation.data.rows.map((row) => {
        return {
          ...row,
          data: row.data.filter((_, i) => !hiddenIndexes.includes(i)),
        };
      }),
    },
  };
};
