import { AxiosResponse } from 'axios';
import { call, fork, put, select, takeEvery } from 'redux-saga/effects';

import { UserListData } from '../domain/settingsUsers';

import {
  DeleteSettingsUsersAction,
  FetchSettingsUsersAction,
  PostSettingsUsersAction,
  PutSettingsUsersAction,
  SettingsUsersActionCreators,
  SettingsUsersActionTypes,
  settingsUsersSelector,
} from '../redux/server/settingsUsers';
import { Api, buildConfig } from '../utils/api';
import { handleErrorSaga } from './errorSaga';

export function* fetchSettingsUsersSaga(
  api: Api,
  action: FetchSettingsUsersAction
) {
  try {
    const { params } = action.payload;

    yield put(SettingsUsersActionCreators.fetchSettingsUsersRequestAction());

    const response: AxiosResponse<UserListData> = yield call(
      api.get,
      `/settings/users`,
      buildConfig(params)
    );

    yield put(
      SettingsUsersActionCreators.fetchSettingsUsersSuccessAction(response.data)
    );
  } catch (error: unknown) {
    yield put(SettingsUsersActionCreators.renewSettingsUsersAction());
    yield fork(handleErrorSaga, error);
  }
}

export function* postSettingsUsersSaga(
  api: Api,
  action: PostSettingsUsersAction
) {
  try {
    const { params } = action.payload;

    yield put(SettingsUsersActionCreators.postSettingsUsersRequestAction());

    yield call(api.post, `/settings/users`, params, buildConfig());

    // POST時に sortとorderが渡せないため、POSTのレスポンスのデータを使用せず
    // 前回リクエストしたパラメータで全体を再リクエストする必要がある
    const { settings }: UserListData = yield select(settingsUsersSelector);
    yield put(SettingsUsersActionCreators.fetchSettingsUsersAction(settings));

    yield put(SettingsUsersActionCreators.postSettingsUsersSuccessAction());
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    yield put(
      SettingsUsersActionCreators.postSettingsUsersFailureAction(error)
    );
    yield fork(handleErrorSaga, error);
  }
}

export function* putSettingsUsersSaga(
  api: Api,
  action: PutSettingsUsersAction
) {
  try {
    const { params, id } = action.payload;

    yield put(SettingsUsersActionCreators.putSettingsUsersRequestAction());

    yield call(api.put, `/settings/users/${id}`, params, buildConfig());

    // PUT時に sortとorderが渡せないため、PUTのレスポンスのデータを使用せず
    // 前回リクエストしたパラメータで全体を再リクエストする必要がある
    const { settings }: UserListData = yield select(settingsUsersSelector);
    yield put(SettingsUsersActionCreators.fetchSettingsUsersAction(settings));

    yield put(SettingsUsersActionCreators.putSettingsUsersSuccessAction());
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    yield put(SettingsUsersActionCreators.putSettingsUsersFailureAction(error));
    yield fork(handleErrorSaga, error);
  }
}

export function* deleteSettingsUsersSaga(
  api: Api,
  action: DeleteSettingsUsersAction
) {
  try {
    const { id } = action.payload;

    yield put(SettingsUsersActionCreators.deleteSettingsUsersRequestAction());

    yield call(api.delete, `/settings/users/${id}`, buildConfig());

    // DELETE時に sortとorderが渡せないため、DELETEのレスポンスのデータを使用せず
    // 前回リクエストしたパラメータで全体を再リクエストする必要がある
    const { settings }: UserListData = yield select(settingsUsersSelector);
    yield put(SettingsUsersActionCreators.fetchSettingsUsersAction(settings));

    yield put(SettingsUsersActionCreators.deleteSettingsUsersSuccessAction());
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    yield put(
      SettingsUsersActionCreators.deleteSettingsUsersFailureAction(error)
    );
    yield fork(handleErrorSaga, error);
  }
}

function* handleFetchSettingsUsersSaga(api: Api) {
  yield takeEvery(
    SettingsUsersActionTypes.FETCH_SETTINGS_USERS,
    fetchSettingsUsersSaga,
    api
  );
}

function* handlePostSettingsUsersSaga(api: Api) {
  yield takeEvery(
    SettingsUsersActionTypes.POST_SETTINGS_USERS,
    postSettingsUsersSaga,
    api
  );
}

function* handlePutSettingsUsersSaga(api: Api) {
  yield takeEvery(
    SettingsUsersActionTypes.PUT_SETTINGS_USERS,
    putSettingsUsersSaga,
    api
  );
}

function* handleDeleteSettingsUsersSaga(api: Api) {
  yield takeEvery(
    SettingsUsersActionTypes.DELETE_SETTINGS_USERS,
    deleteSettingsUsersSaga,
    api
  );
}

export function* settingsUsersSagas(context: { api: Api }) {
  yield fork(handleFetchSettingsUsersSaga, context.api);
  yield fork(handlePostSettingsUsersSaga, context.api);
  yield fork(handlePutSettingsUsersSaga, context.api);
  yield fork(handleDeleteSettingsUsersSaga, context.api);
}
