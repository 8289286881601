import {
  DataPpmShareGraph,
  DataPpmShareGraphParams,
} from '../../domain/ppmShare/types';
import { LoadingState } from '../../domain/schemas';
import { ShuOption } from '../../domain/shu';

import { RootState } from '../../store';

/**
 * Action Types
 */

const FETCH_DATA_PPM_SHARE_GRAPH = 'FETCH_DATA_PPM_SHARE_GRAPH' as const;
const FETCH_DATA_PPM_SHARE_GRAPH_REQUEST = 'FETCH_DATA_PPM_SHARE_GRAPH_REQUEST' as const;
const FETCH_DATA_PPM_SHARE_GRAPH_SUCCESS = 'FETCH_DATA_PPM_SHARE_GRAPH_SUCCESS' as const;
const INIT_DATA_PPM_SHARE_GRAPH = 'INIT_DATA_PPM_SHARE_GRAPH' as const;
const SEARCH_DATA_PPM_SHARE_GRAPH = 'SEARCH_DATA_PPM_SHARE_GRAPH' as const;
const SEARCH_DATA_PPM_SHARE_GRAPH_SHU = 'SEARCH_DATA_PPM_SHARE_GRAPH_SHU' as const;
const RENEW_DATA_PPM_SHARE_GRAPH = 'RENEW_DATA_PPM_SHARE_GRAPH' as const;

export const DataPpmShareGraphActionTypes = {
  FETCH_DATA_PPM_SHARE_GRAPH,
  FETCH_DATA_PPM_SHARE_GRAPH_REQUEST,
  FETCH_DATA_PPM_SHARE_GRAPH_SUCCESS,
  INIT_DATA_PPM_SHARE_GRAPH,
  SEARCH_DATA_PPM_SHARE_GRAPH,
  SEARCH_DATA_PPM_SHARE_GRAPH_SHU,
  RENEW_DATA_PPM_SHARE_GRAPH,
};

/**
 * Action Creators
 */

/**
 * 指定した検索条件でグラフデータを取得する
 * @param params 検索条件
 */
function fetchDataPpmShareGraphAction(params: DataPpmShareGraphParams) {
  return {
    type: FETCH_DATA_PPM_SHARE_GRAPH,
    payload: { params },
  };
}

/**
 * グラフのデータ取得前に呼ぶもの
 * @param params 検索条件
 */
function fetchDataPpmShareGraphRequestAction(params: DataPpmShareGraphParams) {
  return {
    type: FETCH_DATA_PPM_SHARE_GRAPH_REQUEST,
    payload: { params },
  };
}

/**
 * グラフ取得成功時、グラフデータを登録
 * @param dataHallKadoTableGraphData 取得したグラフデータ
 */
function fetchDataPpmShareGraphSuccessAction(
  dataPpmShareGraphData: DataPpmShareGraph
) {
  return {
    type: FETCH_DATA_PPM_SHARE_GRAPH_SUCCESS,
    payload: { dataPpmShareGraphData },
  };
}

/**
 * グラフの取得失敗時、エラー内容を登録
 * @param params 検索条件
 * @param error エラー内容
 */
function renewDataPpmShareGraphAction() {
  return {
    type: RENEW_DATA_PPM_SHARE_GRAPH,
  };
}

/**
 * 現在の検索条件を元にグラフデータを取得する（初回取得用）
 */
function initDataPpmShareGraphAction() {
  return {
    type: INIT_DATA_PPM_SHARE_GRAPH,
  };
}

/**
 * 検索フォームで設定した検索条件を元にデータを再取得する
 * @param params 検索フォームの検索条件
 */
export function searchDataPpmShareGraphAction(
  params?: DataPpmShareGraphParams
) {
  return {
    type: SEARCH_DATA_PPM_SHARE_GRAPH,
    payload: { params },
  };
}

/**
 * 指定した種別でグラフデータを再取得する
 * @param shu 取得する種別
 */
function searchDataPpmShareGraphShuAction(shu: ShuOption) {
  return {
    type: SEARCH_DATA_PPM_SHARE_GRAPH_SHU,
    payload: { shu },
  };
}

export const DataPpmShareGraphActionCreators = {
  fetchDataPpmShareGraphAction,
  fetchDataPpmShareGraphRequestAction,
  fetchDataPpmShareGraphSuccessAction,
  renewDataPpmShareGraphAction,
  initDataPpmShareGraphAction,
  searchDataPpmShareGraphAction,
  searchDataPpmShareGraphShuAction,
};

/**
 * Actions
 */

export type FetchDataPpmShareGraphAction = ReturnType<
  typeof fetchDataPpmShareGraphAction
>;
type InitDataPpmShareGraphAction = ReturnType<
  typeof initDataPpmShareGraphAction
>;
export type SearchDataPpmShareGraphShuAction = ReturnType<
  typeof searchDataPpmShareGraphShuAction
>;

type DataPpmShareGraphAction =
  | FetchDataPpmShareGraphAction
  | InitDataPpmShareGraphAction
  | SearchDataPpmShareGraphShuAction
  | ReturnType<typeof searchDataPpmShareGraphAction>
  | ReturnType<typeof fetchDataPpmShareGraphRequestAction>
  | ReturnType<typeof fetchDataPpmShareGraphSuccessAction>
  | ReturnType<typeof renewDataPpmShareGraphAction>;

type DataPpmShareGraphState = {
  dataPpmShareGraph: DataPpmShareGraph | undefined;
  loadingState: LoadingState;
};

const initialState: DataPpmShareGraphState = {
  dataPpmShareGraph: undefined,
  loadingState: 'prepare',
};

/**
 * Selector
 */

/**
 * 店舗別PPMシェアグラフのグラフデータを取得する
 * @returns 時間帯別稼働数グラフのグラフデータ
 */
export const dataPpmShareGraphSelector = (state: RootState) => {
  return state.dataPpmShareGraph?.dataPpmShareGraph;
};

/**
 * 店舗別PPMシェアグラフの現在のローディング状態を取得する
 * @returns 現在のローディング状態（ローディング時true）
 */
export const dataPpmShareGraphLoadingSelector = (state: RootState) => {
  return state.dataPpmShareGraph.loadingState;
};

/**
 * Reducer
 */

export function dataPpmShareGraphReducer(
  state = initialState,
  action: DataPpmShareGraphAction
): DataPpmShareGraphState {
  switch (action.type) {
    case FETCH_DATA_PPM_SHARE_GRAPH_REQUEST:
      return {
        ...state,
        loadingState: 'loading',
      };
    case FETCH_DATA_PPM_SHARE_GRAPH_SUCCESS:
      return {
        ...state,
        loadingState: 'loaded',
        dataPpmShareGraph: action.payload.dataPpmShareGraphData,
      };
    case RENEW_DATA_PPM_SHARE_GRAPH:
      return initialState;
    default:
      return state;
  }
}
