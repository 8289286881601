import { KiTagListOptions } from '../schemas';
import { DataSis } from './types';

export const dataFilteredByKiTagList = (
  dataSis: DataSis,
  kiTagList: KiTagListOptions[]
): DataSis => {
  if (kiTagList.length === 0) {
    return dataSis;
  }

  const rows = dataSis.data.rows.filter((row, i) => {
    if (i < 1) {
      return true;
    }
    return kiTagList.some((kiTag) => {
      return row.queryParameter.kiList?.some((ki) => {
        return kiTag.kiList.includes(ki);
      });
    });
  });

  return {
    ...dataSis,
    data: {
      ...dataSis.data,
      rows,
    },
  };
};
