import { FC } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';

import {
  useApiErrorState,
  useSetApiErrorState,
} from '../../../contexts/ApiErrorContext';

import { ErrorActionCreators, errorSelector } from '../../../redux/ui/error';
import { MaintenanceWindowPage } from '../../pages/MaintenanceWindowPage/MaintenanceWindowPage';
import { ErrorDialog } from './ErrorDialog';

export const ErrorCatcher: FC = () => {
  const dispatch = useDispatch();

  /**
   * APIで発生するすべてのエラー
   */
  const apiError = useApiErrorState();
  const setApiError = useSetApiErrorState();

  /**
   * Sagaで発生する予期せぬエラー
   */
  const errorState = useSelector(errorSelector, shallowEqual);

  if (apiError.isError) {
    // 503: メンテナンス
    if (apiError.data.maintenance != null) {
      return <MaintenanceWindowPage maintenance={apiError.data.maintenance} />;
    }

    const statusCode = Number(apiError.data.statusCode);
    // 5xx: サーバーエラー
    if (statusCode >= 500) {
      throw new Error(apiError.data.message);
    }

    // 422: POST/PUT/PATCH時に発生したエラーは必ずクライエント側で制御
    // 422: 422エラーの場合ErrorDialogを表示させたくない為、Outletを返す
    if (statusCode === 422) {
      return <Outlet />;
    }

    // 4xx: クライアントエラー
    return (
      <>
        <Outlet />
        <ErrorDialog
          statusCode={!isNaN(statusCode) ? statusCode : undefined}
          isOpen={apiError.isError}
          close={() => setApiError({ isError: false })}
          message={apiError.data.message}
        />
      </>
    );
  }

  if (errorState.error.isError) {
    return (
      <>
        <Outlet />
        <ErrorDialog
          isOpen={errorState.error.isError}
          close={() => dispatch(ErrorActionCreators.clearError())}
          message={errorState.error.message}
        />
      </>
    );
  }

  return <Outlet />;
};
