import { AxiosResponse } from 'axios';
import { call, fork, put, takeEvery } from 'redux-saga/effects';

import { SettingsOptionsKiLink } from '../domain/settingsOptionsKiLink';

import {
  SettingsOptionsKiLinkActionCreators,
  SettingsOptionsKiLinkActionTypes,
} from '../redux/server/settingsOptionsKiLink';
import { Api, buildConfig } from '../utils/api';
import { handleErrorSaga } from './errorSaga';

export function* fetchSettingsOptionsKiLinkSaga(api: Api) {
  try {
    yield put(
      SettingsOptionsKiLinkActionCreators.fetchSettingsOptionsKiLinkRequestAction()
    );
    const response: AxiosResponse<SettingsOptionsKiLink> = yield call(
      api.get,
      '/settings/options/kiLink',
      buildConfig()
    );
    const settingsOptionsKiLink: SettingsOptionsKiLink = response.data;
    yield put(
      SettingsOptionsKiLinkActionCreators.fetchSettingsOptionsKiLinkSuccessAction(
        settingsOptionsKiLink
      )
    );
  } catch (error: unknown) {
    yield put(
      SettingsOptionsKiLinkActionCreators.renewSettingsOptionsKiLinkAction()
    );
    yield fork(handleErrorSaga, error);
  }
}

function* handleFetchSettingsOptionsKiLinkSaga(api: Api) {
  yield takeEvery(
    SettingsOptionsKiLinkActionTypes.FETCH_SETTINGS_OPTIONS_KI_LINK,
    fetchSettingsOptionsKiLinkSaga,
    api
  );
}

export function* settingsOptionsKiLinkSagas(context: { api: Api }) {
  yield fork(handleFetchSettingsOptionsKiLinkSaga, context.api);
}
