import { AxiosResponse } from 'axios';
import { call, fork, put, select, takeEvery } from 'redux-saga/effects';

import { SettingsFavorite } from '../domain/favorites';

import {
  DeleteSettingsFavoritesAction,
  FetchSettingsFavoritesAction,
  PatchSettingsFavoritesAction,
  PostSettingsFavoritesAction,
  SettingsFavoritesActionCreators,
  SettingsFavoritesActionTypes,
  favoritesSelector,
} from '../redux/ui/settingsFavorites';
import { Api, buildConfig } from '../utils/api';
import { handleErrorSaga } from './errorSaga';

/**
 * お気に入り一覧を取得する
 * @param api AxiosInstance
 * @param action Action
 */
export function* fetchSettingsFavoritesSaga(
  api: Api,
  action: FetchSettingsFavoritesAction
) {
  try {
    yield put(
      SettingsFavoritesActionCreators.fetchSettingsFavoritesRequestAction()
    );
    const response: AxiosResponse<SettingsFavorite> = yield call(
      api.get,
      '/settings/favorites',
      buildConfig(action.payload.params)
    );
    const settingsFavorites: SettingsFavorite = response.data;
    yield put(
      SettingsFavoritesActionCreators.fetchSettingsFavoritesSuccessAction(
        settingsFavorites
      )
    );
  } catch (error: unknown) {
    yield put(SettingsFavoritesActionCreators.renewSettingsFavoritesAction());
    yield fork(handleErrorSaga, error);
  }
}

/**
 * FETCH_SETTINGS_FAVORITESがDispatchされた時にfetchSettingsFavoritesSagaを実行する
 * @param api AxiosInstance
 */
function* handleFetchSettingsFavoritesSaga(api: Api) {
  yield takeEvery(
    SettingsFavoritesActionTypes.FETCH_SETTINGS_FAVORITES,
    fetchSettingsFavoritesSaga,
    api
  );
}

/**
 * お気に入りデータを新規登録する
 * @param api AxiosInstance
 * @param action Action
 */
export function* postSettingsFavoritesSaga(
  api: Api,
  action: PostSettingsFavoritesAction
) {
  try {
    yield call(
      api.post,
      '/settings/favorites',
      { favorites: action.payload.favorites },
      buildConfig()
    );

    const { setting }: SettingsFavorite = yield select(favoritesSelector);

    // 登録完了後再取得
    yield put(
      SettingsFavoritesActionCreators.fetchSettingsFavoritesAction({
        ...setting,
        ...action.payload.params,
      })
    );
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    yield put(
      SettingsFavoritesActionCreators.postSettingsFavoritesFailureAction(
        error.response
      )
    );
    yield fork(handleErrorSaga, error);
  }
}

/**
 * POST_SETTINGS_FAVORITESがDispatchされた時にhandlePostSettingsFavoritesSagaを実行する
 * @param api AxiosInstance
 */
function* handlePostSettingsFavoritesSaga(api: Api) {
  yield takeEvery(
    SettingsFavoritesActionTypes.POST_SETTINGS_FAVORITES,
    postSettingsFavoritesSaga,
    api
  );
}

// 配列の入れ替え
function moveAt<T>(array: T[], index: number, at: number) {
  if (index === at || index > array.length - 1 || at > array.length - 1) {
    return array;
  }

  const value = array[index];
  const tail = array.slice(index + 1);

  array.splice(index);
  Array.prototype.push.apply(array, tail);
  array.splice(at, 0, value);

  return array;
}

/**
 * お気に入りデータを更新する
 * @param api AxiosInstance
 * @param action Action
 */
export function* patchSettingsFavoritesSaga(
  api: Api,
  action: PatchSettingsFavoritesAction
) {
  const { favorites, ...rest }: SettingsFavorite = yield select(
    favoritesSelector
  );
  if (favorites == null) {
    throw new Error('お気に入りが見つかりませんでした');
  }
  const orgFavorites = [...favorites];
  try {
    const target = favorites.find((f) => f.id === action.payload.id) ?? {};

    const order = action.payload.favorite.order;

    if (order != null) {
      const optimisticData = moveAt(
        favorites,
        favorites.indexOf(target),
        order - 1
      );

      yield put(
        SettingsFavoritesActionCreators.patchSettingsFavoritesSuccessAction({
          favorites: optimisticData,
          ...rest,
        })
      );
    }

    const response: AxiosResponse<SettingsFavorite> = yield call(
      api.patch,
      `/settings/favorites/${action.payload.id}`,
      action.payload.favorite,
      buildConfig()
    );

    const settingsFavorites: SettingsFavorite = response.data;

    yield put(
      SettingsFavoritesActionCreators.patchSettingsFavoritesSuccessAction(
        settingsFavorites
      )
    );
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    // APIでの更新が失敗した場合もとの値に戻す
    yield put(
      SettingsFavoritesActionCreators.patchSettingsFavoritesSuccessAction({
        favorites: orgFavorites,
        ...rest,
      })
    );
    yield put(
      SettingsFavoritesActionCreators.patchSettingsFavoritesFailureAction(
        error.response
      )
    );
    yield fork(handleErrorSaga, error);
  }
}

/**
 * PUT_SETTINGS_FAVORITESがDispatchされた時にhandlePutSettingsFavoritesSagaを実行する
 * @param api AxiosInstance
 */
function* handlePatchSettingsFavoritesSaga(api: Api) {
  yield takeEvery(
    SettingsFavoritesActionTypes.PATCH_SETTINGS_FAVORITES,
    patchSettingsFavoritesSaga,
    api
  );
}

/**
 * お気に入りデータを削除する
 * @param api AxiosInstance
 * @param action Action
 */
export function* deleteSettingsFavoritesSaga(
  api: Api,
  action: DeleteSettingsFavoritesAction
) {
  try {
    yield call(
      api.delete,
      `/settings/favorites/${action.payload.id}`,
      buildConfig()
    );

    const { setting }: SettingsFavorite = yield select(favoritesSelector);

    // 登録完了後再取得
    yield put(
      SettingsFavoritesActionCreators.fetchSettingsFavoritesAction({
        ...setting,
        ...action.payload.params,
      })
    );
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    yield put(
      SettingsFavoritesActionCreators.deleteSettingsFavoritesFailureAction(
        error.response
      )
    );
    yield fork(handleErrorSaga, error);
  }
}

/**
 * DELETE_SETTINGS_FAVORITESがDispatchされた時にhandleDeleteSettingsFavoritesSagaを実行する
 * @param api AxiosInstance
 */
function* handleDeleteSettingsFavoritesSaga(api: Api) {
  yield takeEvery(
    SettingsFavoritesActionTypes.DELETE_SETTINGS_FAVORITES,
    deleteSettingsFavoritesSaga,
    api
  );
}

/**
 * お気に入り一覧に関するタスクを実行する
 * @param context AxiosInstance
 */
export function* settingsFavoritesSagas(context: { api: Api }) {
  yield fork(handleFetchSettingsFavoritesSaga, context.api);
  yield fork(handlePostSettingsFavoritesSaga, context.api);
  yield fork(handlePatchSettingsFavoritesSaga, context.api);
  yield fork(handleDeleteSettingsFavoritesSaga, context.api);
}
