import {
  addDays,
  addMonths,
  addWeeks,
  eachDayOfInterval,
  format,
  getDay,
  isMonday,
  parse,
  parseISO,
  previousMonday,
  setDate,
  startOfMonth,
  startOfToday,
  startOfYesterday,
} from 'date-fns';
import ja from 'date-fns/locale/ja';

import { GRAPH_DATE_TYPE, GraphDateType } from '../domain/dataKiGraph';
import {
  DateRangeId,
  MKS_GRAPH_DATE_TYPE,
} from '../domain/hallReportsSettingMks';
import { PPM_SHARE_GRAPH_DATE_LABEL_TYPE } from '../domain/ppmShare/consistent';
import { PpmShareGraphDateLabelType } from '../domain/ppmShare/types';

import {
  MonthlyTransitiveDateRange,
  MonthlyTransitiveDateRangesForModelTransition,
  WeeklyTransitiveDateRange,
  WeeklyTransitiveDateRangesForTerminalTransition,
} from './transitiveDateRange';

// 曜日を取得する
const getJaDay = (date: Date | string) =>
  ['日', '月', '火', '水', '木', '金', '土'][getDay(new Date(date))];

// 期間のラベルを取得する
export const makeDatePeriodLabel = (start?: string, end?: string) => {
  if (start === undefined || end === undefined) return undefined;

  const startDay = getJaDay(start);
  const endDay = getJaDay(end);

  return `${start.replace(/-/g, '/')}（${startDay}）~
   ${end.replace(/-/g, '/')}（${endDay}）`;
};

//ymdListのラベルを取得する
export const makeDateListLabel = (ymdList?: string[]) => {
  if (ymdList === undefined) return undefined;
  const makeDateList = ymdList.map(
    (date) => `${date.replace(/-/g, '/')}(${getJaDay(date)})`
  );
  return makeDateList.join(', ');
};

export const fotmatObjectValue = <T extends { [key: string]: Date }>(
  obj: T
) => {
  const formattedDate = Object.fromEntries(
    Object.keys(obj).map((key) => [key, format(obj[key], 'yyyy-MM-dd')])
  );

  return formattedDate;
};

/**
 * コレクションに指定アイテムを存在しなければ追加、する場合には削除しソートされた状態で戻す
 */
export const toggleDate = (collection: number[], date: number) => {
  let dateList = [...collection];

  if (dateList.includes(date)) {
    dateList = dateList.filter((item) => item !== date);
  } else {
    dateList.push(date);
  }

  dateList.sort((a, b) => (a < b ? -1 : 1));

  return dateList;
};

/**
 * endDateとexcludeTodayから正しいendDateを計算する
 */
export const calcEndDateWithExcludeToday = (
  excludeToday: boolean,
  endDate?: string
) => {
  const today = startOfToday();

  // 現在の期間（最終日）を取得する
  const searchEndDate = endDate
    ? parse(endDate, 'yyyy-MM-dd', new Date())
    : today;

  // 当日除外かつ今日を含んでいる場合は今日より前の日付から算出する
  return excludeToday && searchEndDate >= today
    ? startOfYesterday()
    : searchEndDate;
};

/**
 * endDateとdateTypeから正しいstartDateを計算する
 */
export const calcStartDateFromDateType = (
  dateType:
    | GraphDateType
    | PpmShareGraphDateLabelType
    | DateRangeId
    | WeeklyTransitiveDateRange
    | WeeklyTransitiveDateRangesForTerminalTransition
    | MonthlyTransitiveDateRange
    | MonthlyTransitiveDateRangesForModelTransition
    | undefined,
  endDate: Date
) => {
  switch (dateType) {
    // 30日
    case GRAPH_DATE_TYPE.DAILY:
    case MKS_GRAPH_DATE_TYPE.THIRTY_DAYS: {
      return addDays(endDate, -30);
    }

    // 7週
    case '7週': {
      const startDate = addWeeks(endDate, -6);
      if (isMonday(startDate)) {
        return startDate;
      }
      return previousMonday(startDate);
    }

    // 13週
    case GRAPH_DATE_TYPE.WEEKLY:
    case PPM_SHARE_GRAPH_DATE_LABEL_TYPE.THIRTEEN_WEEKS:
    case MKS_GRAPH_DATE_TYPE.THIRTEEN_WEEKS:
    case '13週': {
      const startDate = addWeeks(endDate, -12);
      if (isMonday(startDate)) {
        return startDate;
      }
      return previousMonday(startDate);
    }

    // 26週
    case PPM_SHARE_GRAPH_DATE_LABEL_TYPE.TWENTYSIX_WEEKS: {
      const startDate = addWeeks(endDate, -25);
      if (isMonday(startDate)) {
        return startDate;
      }
      return previousMonday(startDate);
    }

    case '3ヶ月': {
      return setDate(addMonths(endDate, -2), 1);
    }

    // 7ヶ月
    case '7ヶ月': {
      return setDate(addMonths(endDate, -6), 1);
    }

    // 13ヶ月
    case GRAPH_DATE_TYPE.MONTHLY:
    case MKS_GRAPH_DATE_TYPE.THIRTEEN_MONTHS:
    case PPM_SHARE_GRAPH_DATE_LABEL_TYPE.THIRTEEN_MONTHS:
    case '13ヶ月': {
      return setDate(addMonths(endDate, -12), 1);
    }

    case '前月': {
      return startOfMonth(addMonths(endDate, -1));
    }

    case '今月': {
      return startOfMonth(endDate);
    }

    default: {
      return addDays(endDate, -30);
    }
  }
};

/**
 * 日付文字列を日付に変換する
 * @param {string} dateString yyyy-MM-dd のフォーマットで日付を表す文字列
 */
export const parseDateString = (dateString = '') => {
  const result = parseISO(`${dateString}T00:00:00Z`);
  if (Number.isNaN(result.getTime())) {
    throw new Error(`invalid date string: ${dateString}`);
  }
  return result;
};

/**
 * Dateを文字列に変換する
 */
export const formatDate = (x: Date) => format(x, 'yyyy-MM-dd');

/**
 * 開始日と終了日から期間内の日付の配列を算出する
 */
export const getYmdList = (startDate: string, endDate: string): string[] => {
  return eachDayOfInterval({
    start: new Date(startDate),
    end: new Date(endDate),
  }).map(formatDate);
};

/**
 * 日付文字列の形式を変換する
 * yyyy-MM-dd => yyyy年MM月dd日
 * @param {string} dateString yyyy-MM-dd のフォーマットで日付を表す文字列
 */
export const parseDateStringTitle = (dateString: string) =>
  format(parse(dateString, 'yyyy-MM-dd', new Date()), 'yyyy年MM月dd日');

/**
 * デイリーコメントの日付フォーマット
 */
export const formatDateDailyComment = (
  x: string | number,
  withClock = true
) => {
  const date = typeof x === 'string' ? new Date(x) : x;
  const formatString = withClock
    ? 'yyyy/MM/dd(EEEEE) HH:mm'
    : 'yyyy/MM/dd(EEEEE)';
  return format(date, formatString, {
    locale: ja,
  });
};

/**
 * formConditionからendDateを算出する
 *
 * 平日/土日祝比較の場合、ymdListとymdComparisonListをマージして最後の日付を取得する必要があります
 */
export const toEndDate = (
  formCondition: {
    ymdList?: string[];
    ymdComparisonList?: string[];
    startDate?: string | null;
    endDate?: string | null;
  },
  comparisonSection?: string
) => {
  if (comparisonSection === '平日/土日祝比較') {
    return [
      ...(formCondition.ymdList ?? []),
      ...(formCondition.ymdComparisonList ?? []),
    ]
      .sort((a, b) => (a > b ? 1 : -1))
      ?.at(-1);
  }

  return formCondition.ymdList?.at(-1) ?? formCondition.endDate;
};
