import { DataSis } from './types';

export const dataHiddenByField = (
  dataSis: DataSis,
  fields: string[] | undefined
): DataSis => {
  if (fields == null) {
    return dataSis;
  }

  const hiddenIndexes = fields.map((field) => {
    return dataSis.data.columns.findIndex((column) => column.code === field);
  });

  return {
    ...dataSis,
    data: {
      ...dataSis.data,
      columns: dataSis.data.columns.filter(
        (_, i) => !hiddenIndexes.includes(i)
      ),
      rows: dataSis.data.rows.map((row) => {
        return {
          ...row,
          data: row.data.filter((_, i) => !hiddenIndexes.includes(i)),
        };
      }),
    },
  };
};
