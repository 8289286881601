import type { ComponentProps, FC } from 'react';

type Props = ComponentProps<'svg'>;

export const ApplicationConfirmationByStoreIcon: FC<Props> = (props) => (
  <svg
    width="22"
    height="20"
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.43347 17.7411L4.21519 17.763L10.2445 17.7654L10.2761 19.9361H2.28949H0V17.7411H2.25069V9.18706H0V0H2.3283H19H20.7508H21V8H19V2.18278H2V7H4H4.43347H17V9H4.43347V17.7411ZM10.4 13.4L13.6 16.6L20.2 10L21.6 11.4L13.6 19.4L9 14.8L10.4 13.4Z"
      fill="black"
      fillOpacity="0.54"
    />
  </svg>
);
