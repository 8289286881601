import { createSelector } from 'reselect';

import { DataPlanProgressTransitionParams } from '../../domain/dataPlanProgressTransition';
import { SharedUser } from '../../domain/favorites';
import { dataPlanProgressTransitionDefaultValue } from '../../domain/planProgressTransition/defaultValue';
import {
  PlanProgressTransitionDateRange,
  PlanProgressTransitionReportsSettingDateRangeParams,
} from '../../domain/planProgressTransition/types';
import { ShuOption } from '../../domain/shu';

import { RootState } from '../../store';
import { favoritesSelector } from './settingsFavorites';

// Action Types

const SAVE_PLAN_PROGRESS_TRANSITION_REPORTS_SEARCH_CONDITION = 'SAVE_PLAN_PROGRESS_TRANSITION_REPORTS_SEARCH_CONDITION' as const;

const INIT_PLAN_PROGRESS_TRANSITION_REPORTS = 'INIT_PLAN_PROGRESS_TRANSITION_REPORTS' as const;

const SEARCH_PLAN_PROGRESS_TRANSITION_REPORTS = 'SEARCH_PLAN_PROGRESS_TRANSITION_REPORTS' as const;
const SEARCH_RESET_PLAN_PROGRESS_TRANSITION_REPORTS = 'SEARCH_RESET_PLAN_PROGRESS_TRANSITION_REPORTS' as const;

const SELECT_PLAN_PROGRESS_TRANSITION_REPORTS_CURRENT_SHU = 'SELECT_PLAN_PROGRESS_TRANSITION_REPORTS_CURRENT_SHU' as const;
const CHANGE_PLAN_PROGRESS_TRANSITION_REPORTS_CURRENT_SHU = 'CHANGE_PLAN_PROGRESS_TRANSITION_REPORTS_CURRENT_SHU' as const;
const SELECT_PLAN_PROGRESS_TRANSITION_REPORTS_CURRENT_HALLS = 'SELECT_PLAN_PROGRESS_TRANSITION_REPORTS_CURRENT_HALLS' as const;
const CHANGE_PLAN_PROGRESS_TRANSITION_REPORTS_CURRENT_HALLS = 'CHANGE_PLAN_PROGRESS_TRANSITION_REPORTS_CURRENT_HALLS' as const;
const RESET_PLAN_PROGRESS_TRANSITION_REPORTS_CURRENT_HALLS = 'RESET_PLAN_PROGRESS_TRANSITION_REPORTS_CURRENT_HALLS' as const;

const CHANGE_PLAN_PROGRESS_TRANSITION_REPORTS_DATA_RANGE = 'CHANGE_PLAN_PROGRESS_TRANSITION_REPORTS_DATA_RANGE' as const;

const SELECT_PLAN_GRAPH_SHOW_GRAPH_NUMBER_LABEL = 'SELECT_GRAPH_SHOW_GRAPH_NUMBER_LABEL' as const;
const SELECT_PLAN_GRAPH_SHOW_GRAPH_PLAN = 'SELECT_PLAN_GRAPH_SHOW_GRAPH_PLAN' as const;
const SELECT_PLAN_GRAPH_SHOW_GRAPH_TOTAL = 'SELECT_PLAN_GRAPH_SHOW_GRAPH_TOTAL' as const;
const CHANGE_PLAN_PROGRESS_TRANSITION_REPORTS_FAVORITE = 'CHANGE_PLAN_PROGRESS_TRANSITION_REPORTS_FAVORITE' as const;
const SAVE_AS_PLAN_PROGRESS_TRANSITION_REPORTS_FAVORITE = 'SAVE_AS_PLAN_PROGRESS_TRANSITION_REPORTS_FAVORITE' as const;
const SAVE_PLAN_PROGRESS_TRANSITION_REPORTS_FAVORITE = 'SAVE_PLAN_PROGRESS_TRANSITION_REPORTS_FAVORITE' as const;
const RESET_PLAN_PROGRESS_TRANSITION_REPORTS_SEARCH_CONDITION = 'RESET_PLAN_PROGRESS_TRANSITION_REPORTS_SEARCH_CONDITION' as const;
const SAVE_PLAN_PROGRESS_TRANSITION_REPORTS_DATE_RANGE = 'SAVE_PLAN_PROGRESS_TRANSITION_REPORTS_DATE_RANGE' as const;
const RESET_PLAN_PROGRESS_TRANSITION_REPORTS_FAVORITE_TO_DEFAULT = 'RESET_PLAN_PROGRESS_TRANSITION_REPORTS_FAVORITE_TO_DEFAULT' as const;
const RESET_PLAN_PROGRESS_TRANSITION_REPORTS_CURRENT_SHU = 'RESET_PLAN_PROGRESS_TRANSITION_REPORTS_CURRENT_SHU' as const;
const RESET_PLAN_PROGRESS_TRANSITION_REPORTS_DATE_RANGE = 'RESET_PLAN_PROGRESS_TRANSITION_REPORTS_DATE_RANGE' as const;
const SELECT_PLAN_PROGRESS_TRANSITION_REPORTS_IS_SHOW_ROW = 'SELECT_PLAN_PROGRESS_TRANSITION_REPORTS_IS_SHOW_ROW' as const;
const RESET_PLAN_PROGRESS_TRANSITION_REPORTS_IS_SHOW_ROW = 'RESET_PLAN_PROGRESS_TRANSITION_REPORTS_IS_SHOW_ROW' as const;

export const PlanProgressTransitionReportsSettingActionTypes = {
  SAVE_PLAN_PROGRESS_TRANSITION_REPORTS_SEARCH_CONDITION,
  INIT_PLAN_PROGRESS_TRANSITION_REPORTS,
  SEARCH_PLAN_PROGRESS_TRANSITION_REPORTS,
  SEARCH_RESET_PLAN_PROGRESS_TRANSITION_REPORTS,
  SELECT_PLAN_PROGRESS_TRANSITION_REPORTS_CURRENT_SHU,
  CHANGE_PLAN_PROGRESS_TRANSITION_REPORTS_CURRENT_SHU,
  SELECT_PLAN_PROGRESS_TRANSITION_REPORTS_CURRENT_HALLS,
  CHANGE_PLAN_PROGRESS_TRANSITION_REPORTS_CURRENT_HALLS,
  RESET_PLAN_PROGRESS_TRANSITION_REPORTS_CURRENT_HALLS,
  CHANGE_PLAN_PROGRESS_TRANSITION_REPORTS_DATA_RANGE,
  SELECT_PLAN_GRAPH_SHOW_GRAPH_NUMBER_LABEL,
  SELECT_PLAN_GRAPH_SHOW_GRAPH_PLAN,
  SELECT_PLAN_GRAPH_SHOW_GRAPH_TOTAL,
  CHANGE_PLAN_PROGRESS_TRANSITION_REPORTS_FAVORITE,
  SAVE_AS_PLAN_PROGRESS_TRANSITION_REPORTS_FAVORITE,
  SAVE_PLAN_PROGRESS_TRANSITION_REPORTS_FAVORITE,
  SAVE_PLAN_PROGRESS_TRANSITION_REPORTS_DATE_RANGE,
  RESET_PLAN_PROGRESS_TRANSITION_REPORTS_SEARCH_CONDITION,
  RESET_PLAN_PROGRESS_TRANSITION_REPORTS_FAVORITE_TO_DEFAULT,
  RESET_PLAN_PROGRESS_TRANSITION_REPORTS_CURRENT_SHU,
  RESET_PLAN_PROGRESS_TRANSITION_REPORTS_DATE_RANGE,
  SELECT_PLAN_PROGRESS_TRANSITION_REPORTS_IS_SHOW_ROW,
  RESET_PLAN_PROGRESS_TRANSITION_REPORTS_IS_SHOW_ROW,
};

/**
 * 検索条件をStoreに格納
 * @param params 検索条件
 */
export function savePlanProgressTransitionReportsSearchConditionAction(
  params: DataPlanProgressTransitionParams
) {
  return {
    type: SAVE_PLAN_PROGRESS_TRANSITION_REPORTS_SEARCH_CONDITION,
    payload: { params },
  };
}

/**
 * 初回表示時のデータ取得
 */
function initPlanProgressTransitionReportsAction() {
  return {
    type: INIT_PLAN_PROGRESS_TRANSITION_REPORTS,
  };
}

/**
 * 検索ボタンでデータ取得
 * @param params 検索条件
 */
function searchPlanProgressTransitionReportsAction(
  params: DataPlanProgressTransitionParams
) {
  return {
    type: SEARCH_PLAN_PROGRESS_TRANSITION_REPORTS,
    payload: { params },
  };
}

/**
 * 初期条件でデータを取得する（リセットボタン押下時）
 *
 */

function searchResetPlanProgressTransitionReportsAction(halls?: string[]) {
  return {
    type: SEARCH_RESET_PLAN_PROGRESS_TRANSITION_REPORTS,
    payload: { halls },
  };
}

/**
 * 種別選択リストを変更、リクエストを投げない
 * @param shu 選択した種別
 */
export function selectPlanProgressTransitionReportsCurrentShuAction(
  shu: ShuOption
) {
  return {
    type: SELECT_PLAN_PROGRESS_TRANSITION_REPORTS_CURRENT_SHU,
    payload: { shu },
  };
}

/**
 * 種別選択リストを変更、リクエストを投げる
 * @param shu 選択した種別
 */
function changePlanProgressTransitionReportsCurrentShuAction(shu: ShuOption) {
  return {
    type: CHANGE_PLAN_PROGRESS_TRANSITION_REPORTS_CURRENT_SHU,
    payload: { shu },
  };
}

/**
 * 店舗選択リストを変更する、リクエストは投げない
 * @param halls 絞り込む店舗
 */
function selectPlanProgressTransitionReportsCurrentHallsAction(
  halls: string[]
) {
  return {
    type: SELECT_PLAN_PROGRESS_TRANSITION_REPORTS_CURRENT_HALLS,
    payload: { halls },
  };
}

/**
 * 店舗選択リストで店舗を変更し、リクエストを投げる
 * @param halls 絞り込む店舗
 */
function changePlanProgressTransitionReportsCurrentHallsAction(
  halls: string[]
) {
  return {
    type: CHANGE_PLAN_PROGRESS_TRANSITION_REPORTS_CURRENT_HALLS,
    payload: { halls },
  };
}

/**
 * 店舗選択リストの選択を破棄する
 */
export function resetPlanProgressTransitionReportsCurrentHallsAction() {
  return {
    type: RESET_PLAN_PROGRESS_TRANSITION_REPORTS_CURRENT_HALLS,
  };
}

/**
 * 種別選択リストの選択を破棄する
 */
function resetPlanProgressTransitionReportsCurrentShuAction() {
  return {
    type: RESET_PLAN_PROGRESS_TRANSITION_REPORTS_CURRENT_SHU,
  };
}

/**
 * 検索条件をデフォルトに戻す
 */
function resetPlanProgressTransitionReportsSearchConditionAction() {
  return {
    type: RESET_PLAN_PROGRESS_TRANSITION_REPORTS_SEARCH_CONDITION,
  };
}

/**
 * 期間スライダーで期間を変更する
 *
 */
function changePlanProgressTransitionReportsDataRangeAction(
  year: number,
  month: number,
  dateRange: PlanProgressTransitionDateRange
) {
  return {
    type: CHANGE_PLAN_PROGRESS_TRANSITION_REPORTS_DATA_RANGE,
    payload: { year, month, dateRange },
  };
}

/**
 * 推移グラフで予定を表示するかどうかのフラグをセットする
 * @param {boolean} flag 表示フラグ
 */
function selectPlanGraphShowPlanAction(flag: boolean) {
  return {
    type: SELECT_PLAN_GRAPH_SHOW_GRAPH_PLAN,
    payload: {
      flag,
    },
  };
}

/**
 * 推移グラフで累計を表示するかどうかのフラグをセットする
 * @param {boolean} flag 表示フラグ
 */
function selectPlanGraphShowTotalAction(flag: boolean) {
  return {
    type: SELECT_PLAN_GRAPH_SHOW_GRAPH_TOTAL,
    payload: {
      flag,
    },
  };
}

/**
 * 推移グラフに数値を表示するかどうかのフラグをセットする
 * @param {boolean} flag 表示フラグ
 */
function selectPlanGraphShowNumberLabelAction(flag: boolean) {
  return {
    type: SELECT_PLAN_GRAPH_SHOW_GRAPH_NUMBER_LABEL,
    payload: {
      flag,
    },
  };
}

/**
 * 選択したお気に入りでデータを取得する
 * @param favorite 変更するお気に入りID（undefined時は選択なし）
 */
function changePlanProgressTransitionReportsFavoriteAction(favorite?: number) {
  return {
    type: CHANGE_PLAN_PROGRESS_TRANSITION_REPORTS_FAVORITE,
    payload: { favorite },
  };
}

/**
 * データを取得せずにお気に入りをデフォルトに戻す
 */
export function resetPlanProgressTransitionReportsFavoriteToDefaultAction() {
  return {
    type: RESET_PLAN_PROGRESS_TRANSITION_REPORTS_FAVORITE_TO_DEFAULT,
    payload: {},
  };
}

/**
 * 現在の検索条件でお気に入りを新規保存する
 * @param name お気に入り名
 * @param isShared 共有設定
 * @param memo メモ
 */
function saveAsPlanProgressTransitionReportsFavoriteAction(
  name: string,
  isShared: boolean,
  memo: string,
  sharedUser: SharedUser[]
) {
  return {
    type: SAVE_AS_PLAN_PROGRESS_TRANSITION_REPORTS_FAVORITE,
    payload: { name, isShared, memo, sharedUser },
  };
}

/**
 * 現在の検索条件でお気に入りを上書き保存する
 * @param name お気に入り名
 * @param isShared 共有設定
 * @param memo メモ
 * @param mode メモ更新モード
 */
function savePlanProgressTransitionReportsFavoriteAction(
  name: string,
  isShared: boolean,
  memo: string,
  sharedUser: SharedUser[],
  mode?: 'standard' | 'memo'
) {
  return {
    type: SAVE_PLAN_PROGRESS_TRANSITION_REPORTS_FAVORITE,
    payload: { name, isShared, memo, sharedUser, mode },
  };
}

/**
 * 期間選択の保存
 */
function savePlanProgressTransitionReportsDateRangeAction(
  dateRange: PlanProgressTransitionDateRange
) {
  return {
    type: SAVE_PLAN_PROGRESS_TRANSITION_REPORTS_DATE_RANGE,
    payload: { dateRange },
  };
}

/**
 * 期間選択の初期化
 */
function resetPlanProgressTransitionReportsDateRangeAction() {
  return {
    type: RESET_PLAN_PROGRESS_TRANSITION_REPORTS_DATE_RANGE,
  };
}

/**
 * 行の表示管理フラグをセットする
 */
function selectPlanProgressTransitionReportsIsShowRowAction(isShowRow: {
  previousYearSameMonth?: boolean;
  forecast?: boolean;
}) {
  return {
    type: SELECT_PLAN_PROGRESS_TRANSITION_REPORTS_IS_SHOW_ROW,
    payload: {
      isShowRow,
    },
  };
}

/**
 * 行の表示管理フラグをリセットする
 */
function resetPlanProgressTransitionReportsIsShowRowAction() {
  return {
    type: RESET_PLAN_PROGRESS_TRANSITION_REPORTS_IS_SHOW_ROW,
  };
}

export const PlanProgressTransitionReportsSettingActionCreators = {
  savePlanProgressTransitionReportsSearchConditionAction,
  initPlanProgressTransitionReportsAction,
  searchPlanProgressTransitionReportsAction,
  selectPlanProgressTransitionReportsCurrentShuAction,
  changePlanProgressTransitionReportsCurrentShuAction,
  selectPlanProgressTransitionReportsCurrentHallsAction,
  changePlanProgressTransitionReportsCurrentHallsAction,
  resetPlanProgressTransitionReportsCurrentHallsAction,
  changePlanProgressTransitionReportsDataRangeAction,
  selectPlanGraphShowPlanAction,
  selectPlanGraphShowTotalAction,
  selectPlanGraphShowNumberLabelAction,
  changePlanProgressTransitionReportsFavoriteAction,
  saveAsPlanProgressTransitionReportsFavoriteAction,
  savePlanProgressTransitionReportsFavoriteAction,
  savePlanProgressTransitionReportsDateRangeAction,
  searchResetPlanProgressTransitionReportsAction,
  resetPlanProgressTransitionReportsSearchConditionAction,
  resetPlanProgressTransitionReportsFavoriteToDefaultAction,
  resetPlanProgressTransitionReportsCurrentShuAction,
  resetPlanProgressTransitionReportsDateRangeAction,
  selectPlanProgressTransitionReportsIsShowRowAction,
  resetPlanProgressTransitionReportsIsShowRowAction,
};

// Actions

export type SearchPlanProgressTransitionReportsAction = ReturnType<
  typeof searchPlanProgressTransitionReportsAction
>;

export type SearchResetPlanProgressTransitionReportsAction = ReturnType<
  typeof searchResetPlanProgressTransitionReportsAction
>;

type SelectPlanProgressTransitionReportsCurrentShuAction = ReturnType<
  typeof selectPlanProgressTransitionReportsCurrentShuAction
>;

export type ChangePlanProgressTransitionReportsCurrentShuAction = ReturnType<
  typeof changePlanProgressTransitionReportsCurrentShuAction
>;

type SelectPlanProgressTransitionReportsCurrentHallsAction = ReturnType<
  typeof selectPlanProgressTransitionReportsCurrentHallsAction
>;

export type ChangePlanProgressTransitionReportsCurrentHallsAction = ReturnType<
  typeof changePlanProgressTransitionReportsCurrentHallsAction
>;

type ResetPlanProgressTransitionReportsCurrentHallsAction = ReturnType<
  typeof resetPlanProgressTransitionReportsCurrentHallsAction
>;

export type ChangePlanProgressTransitionReportsDataRangeAction = ReturnType<
  typeof changePlanProgressTransitionReportsDataRangeAction
>;

type SelectPlanGraphShowNumberLabelAction = ReturnType<
  typeof selectPlanGraphShowNumberLabelAction
>;
type SelectPlanGraphShowPlanAction = ReturnType<
  typeof selectPlanGraphShowPlanAction
>;
type SelectPlanGraphShowTotalAction = ReturnType<
  typeof selectPlanGraphShowTotalAction
>;
export type ChangePlanProgressTransitionReportsFavoriteAction = ReturnType<
  typeof changePlanProgressTransitionReportsFavoriteAction
>;
export type SaveAsPlanProgressTransitionReportsFavoriteAction = ReturnType<
  typeof saveAsPlanProgressTransitionReportsFavoriteAction
>;
export type SavePlanProgressTransitionReportsFavoriteAction = ReturnType<
  typeof savePlanProgressTransitionReportsFavoriteAction
>;

type SavePlanProgressTransitionReportsDateRangeAction = ReturnType<
  typeof savePlanProgressTransitionReportsDateRangeAction
>;
type ResetPlanProgressTransitionReportsSearchConditionAction = ReturnType<
  typeof resetPlanProgressTransitionReportsSearchConditionAction
>;

type ResetPlanProgressTransitionReportsFavoriteToDefaultAction = ReturnType<
  typeof resetPlanProgressTransitionReportsFavoriteToDefaultAction
>;
type ResetPlanProgressTransitionReportsCurrentShuAction = ReturnType<
  typeof resetPlanProgressTransitionReportsCurrentShuAction
>;

type ResetPlanProgressTransitionReportsDateRangeAction = ReturnType<
  typeof resetPlanProgressTransitionReportsDateRangeAction
>;

type SelectPlanProgressTransitionReportsIsShowRowAction = ReturnType<
  typeof selectPlanProgressTransitionReportsIsShowRowAction
>;

type ResetPlanProgressTransitionReportsIsShowRowAction = ReturnType<
  typeof resetPlanProgressTransitionReportsIsShowRowAction
>;

type PlanProgressTransitionReportsSettingAction =
  | ReturnType<typeof savePlanProgressTransitionReportsSearchConditionAction>
  | ReturnType<typeof initPlanProgressTransitionReportsAction>
  | SearchPlanProgressTransitionReportsAction
  | SelectPlanProgressTransitionReportsCurrentShuAction
  | ChangePlanProgressTransitionReportsCurrentShuAction
  | SelectPlanProgressTransitionReportsCurrentHallsAction
  | ChangePlanProgressTransitionReportsCurrentHallsAction
  | ResetPlanProgressTransitionReportsCurrentHallsAction
  | ChangePlanProgressTransitionReportsDataRangeAction
  | SelectPlanGraphShowNumberLabelAction
  | SelectPlanGraphShowPlanAction
  | SelectPlanGraphShowTotalAction
  | ChangePlanProgressTransitionReportsFavoriteAction
  | SaveAsPlanProgressTransitionReportsFavoriteAction
  | SavePlanProgressTransitionReportsFavoriteAction
  | SavePlanProgressTransitionReportsDateRangeAction
  | ResetPlanProgressTransitionReportsSearchConditionAction
  | ResetPlanProgressTransitionReportsFavoriteToDefaultAction
  | ResetPlanProgressTransitionReportsCurrentShuAction
  | ResetPlanProgressTransitionReportsDateRangeAction
  | SelectPlanProgressTransitionReportsIsShowRowAction
  | ResetPlanProgressTransitionReportsIsShowRowAction;

// State

export type PlanProgressTransitionReportsSettingState = {
  searchCondition: DataPlanProgressTransitionParams;
  currentShu: ShuOption;
  currentHalls: string[];
  currentYears: number;
  currentMonths: number;
  /**
   * 推移グラフに数値を表示するかどうかのフラグ
   */
  showGraphNumberLabel: boolean;
  /**
   * 推移グラフで予定を表示するかどうかのフラグ
   */
  showGraphPlan: boolean;
  /**
   * 推移グラフで累計を表示するかどうかのフラグ
   */
  showGraphTotal: boolean;
  /**
   * 現在選択中の期間指定
   */
  selectedDateRangeParams: PlanProgressTransitionReportsSettingDateRangeParams;
  /**
   * 選択中のお気に入りID
   */
  selectedFavoriteId?: number;
  /**
   * 行の表示管理フラグ
   */
  isShowRow: {
    /**
     * 前年同月、前年同月比
     */
    previousYearSameMonth: boolean;
    /**
     * 着地見込、着地見込差
     */
    forecast: boolean;
  };
};

export const defaultShu = {
  code: '',
  name: '種別全体',
  group: '',
  type: 'none', // shu.tsのselectShu2SearchConditionで種別未指定として扱われるように'none'を設定しておく
};

const initialDateRangeParams: PlanProgressTransitionReportsSettingDateRangeParams = {
  dateRange: dataPlanProgressTransitionDefaultValue().dateRangeParams.dateRange,
};

const initialState: PlanProgressTransitionReportsSettingState = {
  searchCondition: dataPlanProgressTransitionDefaultValue().setting,
  currentShu: defaultShu,
  currentHalls: [],
  currentYears: 0,
  currentMonths: 0,
  selectedDateRangeParams: initialDateRangeParams,
  showGraphNumberLabel: true,
  showGraphPlan: true,
  showGraphTotal: true,
  selectedFavoriteId: undefined,
  isShowRow: {
    previousYearSameMonth: true,
    forecast: true,
  },
};

// Selector

/**
 * 予定進捗推移設定全てを取得する
 * @returns 全設定データ
 */
export function planProgressTransitionReportsSettingSelector(rootState: {
  planProgressTransitionReportsSetting: PlanProgressTransitionReportsSettingState;
}) {
  return rootState.planProgressTransitionReportsSetting;
}

// 予定進捗推移の検索条件を取得する
export function planProgressTransitionReportsSearchConditionSelector(
  state: RootState
) {
  return state.planProgressTransitionReportsSetting.searchCondition;
}

// 選択中の種別・種別グループを取得する（テーブルに表示されている種別・種別グループ）
// デフォルト（undefined）時は「店舗全体」として表示する
export const planProgressTransitionReportsCurrentShuSelector = (
  state: RootState
) => {
  return state.planProgressTransitionReportsSetting.currentShu;
};

/**
 * 予定進捗推移で選択中の期間指定を取得する
 * @returns 選択中の機期間情報
 */
export function planProgressTransitionReportsSelectedDateRangeParamsSelector(rootState: {
  planProgressTransitionReportsSetting: PlanProgressTransitionReportsSettingState;
}) {
  return rootState.planProgressTransitionReportsSetting.selectedDateRangeParams;
}

/**
 * 検索条件に指定している店舗
 * @returns string[]
 */
export const planProgressTransitionReportsSettingSelectedHallsSelector = createSelector(
  planProgressTransitionReportsSearchConditionSelector,
  (searchCondition: DataPlanProgressTransitionParams) => {
    return searchCondition.halls ?? [];
  }
);

/**
 * 店舗選択リストで選択中の店舗
 */
export const planProgressTransitionReportsSettingCurrentHallsSelector = createSelector(
  planProgressTransitionReportsSettingSelector,
  ({ currentHalls }) => (currentHalls?.length > 0 ? currentHalls : [])
);

// 推移グラフで数値ラベルを表示するかどうか
export const planProgressReportsGraphShowNumberLabelSelector = createSelector(
  planProgressTransitionReportsSettingSelector,
  (setting) => setting.showGraphNumberLabel
);

// 推移グラフで予定を表示するかどうか
export const planProgressReportsGraphShowPlanSelector = createSelector(
  planProgressTransitionReportsSettingSelector,
  (setting) => setting.showGraphPlan
);

// 推移グラフで累計を表示するかどうか
export const planProgressReportsGraphShowTotalSelector = createSelector(
  planProgressTransitionReportsSettingSelector,
  (setting) => setting.showGraphTotal
);

// 予定進捗推移で選択中のお気に入りIDを取得する
export const planProgressTransitionReportsSelectedFavoriteSelector = createSelector(
  planProgressTransitionReportsSettingSelector,
  ({ selectedFavoriteId }) => selectedFavoriteId
);

/**
 * 予定進捗推移で現在選択中のお気に入りデータを取得する
 * @returns 現在選択中のお気に入りデータ（未選択時: undefined）
 */
export const planProgressTransitionReportsSelectedFavoriteDataSelector = createSelector(
  [planProgressTransitionReportsSelectedFavoriteSelector, favoritesSelector],
  (favoriteId, favorites) => {
    if (favoriteId === undefined) return;

    return favorites?.favorites?.find((favorite) => favorite.id === favoriteId);
  }
);

/**
 * 予定進捗推移で選択中のお気に入りデータを取得する
 * @returns 現在選択中のお気に入りデータ（undefined時未選択）
 */
export const planProgressTransitionReportsSelectedFavoriteSettingSelector = createSelector(
  [planProgressTransitionReportsSelectedFavoriteSelector, favoritesSelector],
  (favoriteId, favorites) => {
    if (favoriteId === undefined) return;

    const favoriteItem = favorites?.favorites?.find(
      (favorite) => favorite.id === favoriteId
    );

    return favoriteItem?.pageSetting?.planProgressTransition;
  }
);

/**
 * 行の表示管理フラグを取得する
 */
export const planProgressReportsIsShowRowSelector = createSelector(
  planProgressTransitionReportsSettingSelector,
  (setting) => setting.isShowRow
);

/**
 * Reducer
 */

export function planProgressTransitionReportsSettingReducer(
  state = initialState,
  action: PlanProgressTransitionReportsSettingAction
): PlanProgressTransitionReportsSettingState {
  switch (action.type) {
    case SAVE_PLAN_PROGRESS_TRANSITION_REPORTS_SEARCH_CONDITION:
      return {
        ...state,
        searchCondition: action.payload.params,
      };
    case SAVE_PLAN_PROGRESS_TRANSITION_REPORTS_DATE_RANGE:
      return {
        ...state,
        selectedDateRangeParams: {
          ...state.selectedDateRangeParams,
          dateRange: action.payload.dateRange,
        },
      };
    case SELECT_PLAN_PROGRESS_TRANSITION_REPORTS_CURRENT_SHU:
      return {
        ...state,
        currentShu: action.payload.shu,
      };
    case CHANGE_PLAN_PROGRESS_TRANSITION_REPORTS_CURRENT_SHU:
      return {
        ...state,
        currentShu: action.payload.shu,
      };
    case RESET_PLAN_PROGRESS_TRANSITION_REPORTS_CURRENT_SHU:
      return {
        ...state,
        currentShu: initialState.currentShu,
      };
    case SELECT_PLAN_PROGRESS_TRANSITION_REPORTS_CURRENT_HALLS:
    case CHANGE_PLAN_PROGRESS_TRANSITION_REPORTS_CURRENT_HALLS:
      return {
        ...state,
        currentHalls: action.payload.halls,
      };
    case RESET_PLAN_PROGRESS_TRANSITION_REPORTS_CURRENT_HALLS:
      return {
        ...state,
        currentHalls: initialState.currentHalls,
      };
    case CHANGE_PLAN_PROGRESS_TRANSITION_REPORTS_DATA_RANGE:
      return {
        ...state,
        currentYears: action.payload.year,
        currentMonths: action.payload.month,
        selectedDateRangeParams: {
          dateRange: action.payload.dateRange,
        },
      };
    case SELECT_PLAN_GRAPH_SHOW_GRAPH_NUMBER_LABEL:
      return {
        ...state,
        showGraphNumberLabel: action.payload.flag,
      };
    case SELECT_PLAN_GRAPH_SHOW_GRAPH_PLAN:
      return {
        ...state,
        showGraphPlan: action.payload.flag,
      };
    case SELECT_PLAN_GRAPH_SHOW_GRAPH_TOTAL:
      return {
        ...state,
        showGraphTotal: action.payload.flag,
      };
    case CHANGE_PLAN_PROGRESS_TRANSITION_REPORTS_FAVORITE:
      return {
        ...state,
        selectedFavoriteId: action.payload.favorite,
      };
    case RESET_PLAN_PROGRESS_TRANSITION_REPORTS_SEARCH_CONDITION:
      return {
        ...state,
        searchCondition: initialState.searchCondition,
      };
    case RESET_PLAN_PROGRESS_TRANSITION_REPORTS_FAVORITE_TO_DEFAULT:
      return {
        ...state,
        selectedFavoriteId: undefined,
      };
    case RESET_PLAN_PROGRESS_TRANSITION_REPORTS_DATE_RANGE:
      return {
        ...state,
        selectedDateRangeParams: initialDateRangeParams,
      };
    case SELECT_PLAN_PROGRESS_TRANSITION_REPORTS_IS_SHOW_ROW:
      return {
        ...state,
        isShowRow: {
          previousYearSameMonth:
            action.payload.isShowRow.previousYearSameMonth ??
            state.isShowRow.previousYearSameMonth,
          forecast:
            action.payload.isShowRow.forecast ?? state.isShowRow.forecast,
        },
      };
    case RESET_PLAN_PROGRESS_TRANSITION_REPORTS_IS_SHOW_ROW:
      return {
        ...state,
        isShowRow: initialState.isShowRow,
      };
    default:
      return state;
  }
}
