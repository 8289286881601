import { DataDepreciationResponse } from '../../domain/depreciation/types';

export const dataDepreciationData: DataDepreciationResponse = {
  setting: {
    areas: ['1462'],
    halls: ['23999255', '23999193'],
    startDate: '2024-08-19',
    endDate: '2024-08-23',
    mainField: 'kiTushoMei',
    kiList: ['025450', '025451', '024984', '056987'],
    tagIds: ['1', '2'],
    sort: 'soDaisu',
    order: 'desc',
    fields: [
      'days',
      'soDaisu',
      'heikinDaisu',
      'out',
      'outSijiRitu',
      'daiYugiJikan',
      'dtmart',
      'daiUriage',
      'daiUriageSijiRitu',
      'daiArari',
      'daiArariSijiRitu',
      'tmaTanka',
      'tmaArari',
      'riekiRitu',
      'kikaiWarisu',
      'uriageGk',
      'arariGk',
      'replayRitu',
    ],
    containsAverage: true,
    containsData: true,
    excludeToday: true,
    daysAfterIntroduction: 14,
    konyuKbn: '新台',
    status: '稼動中',
  },
  data: {
    columns: [
      {
        name: '機種名',
        isSisField: false,
        code: 'kiTushoMei',
        type: 'string',
        backgroundColor: null,
        color: null,
      },
      {
        name: '日数',
        isSisField: false,
        code: 'days',
        type: 'number',
        backgroundColor: null,
        color: null,
      },
      {
        name: '総台数',
        isSisField: false,
        code: 'soDaisu',
        type: 'number',
        backgroundColor: null,
        color: null,
      },
      {
        name: '平均台数',
        isSisField: false,
        code: 'heikinDaisu',
        type: 'number',
        backgroundColor: null,
        color: null,
      },
      {
        name: 'アウト',
        isSisField: false,
        code: 'out',
        type: 'number',
        backgroundColor: null,
        color: null,
      },
      {
        name: 'アウト支持率',
        isSisField: false,
        code: 'outSijiRitu',
        type: 'number',
        backgroundColor: null,
        color: null,
      },
      {
        name: '台遊技時間',
        isSisField: false,
        code: 'daiYugiJikan',
        type: 'number',
        backgroundColor: null,
        color: null,
      },
      {
        name: '出玉率',
        isSisField: false,
        code: 'dtmart',
        type: 'number',
        backgroundColor: null,
        color: null,
      },
      {
        name: '台売上',
        isSisField: false,
        code: 'daiUriage',
        type: 'number',
        backgroundColor: null,
        color: null,
      },
      {
        name: '台売上支持率',
        isSisField: false,
        code: 'daiUriageSijiRitu',
        type: 'number',
        backgroundColor: null,
        color: null,
      },
      {
        name: '台粗利',
        isSisField: false,
        code: 'daiArari',
        type: 'number',
        backgroundColor: null,
        color: null,
      },
      {
        name: '台粗利支持率',
        isSisField: false,
        code: 'daiArariSijiRitu',
        type: 'number',
        backgroundColor: null,
        color: null,
      },
      {
        name: '玉単価',
        isSisField: false,
        code: 'tmaTanka',
        type: 'number',
        backgroundColor: null,
        color: null,
      },
      {
        name: '玉粗利',
        isSisField: false,
        code: 'tmaArari',
        type: 'number',
        backgroundColor: null,
        color: null,
      },
      {
        name: '利益率',
        isSisField: false,
        code: 'riekiRitu',
        type: 'number',
        backgroundColor: null,
        color: null,
      },
      {
        name: '機械割数',
        isSisField: false,
        code: 'kikaiWarisu',
        type: 'number',
        backgroundColor: null,
        color: null,
      },
      {
        name: '売上合計',
        isSisField: false,
        code: 'uriageGk',
        type: 'number',
        backgroundColor: null,
        color: null,
      },
      {
        name: '粗利合計',
        isSisField: false,
        code: 'arariGk',
        type: 'number',
        backgroundColor: null,
        color: null,
      },
      {
        name: '再P率',
        code: 'replayRitu',
        isSisField: false,
        type: 'number',
        backgroundColor: null,
        color: null,
        description: null,
      },
      {
        name: '中古機価格',
        code: 'usedDaiPrice',
        isSisField: false,
        type: 'number',
        backgroundColor: null,
        color: null,
        description: 'P-SENSORにおける直近1週間の平均取引価格',
      },
    ],
    rows: [
      {
        queryParameter: { kiList: ['000213'] },
        data: [
          { value: 'AAAA', color: '#000000', backgroundColor: null },
          { value: '4.0', color: '#000000', backgroundColor: null },
          { value: '8,057.0', color: '#000000', backgroundColor: null },
          { value: '383.7', color: '#000000', backgroundColor: null },
          { value: '14,044', color: '#000000', backgroundColor: null },
          { value: '-', color: '#000000', backgroundColor: null },
          { value: '3.02', color: '#000000', backgroundColor: null },
          { value: '97.0%', color: '#000000', backgroundColor: null },
          { value: '22,995', color: '#000000', backgroundColor: null },
          { value: '-', color: '#000000', backgroundColor: null },
          { value: '2,725', color: '#000000', backgroundColor: null },
          { value: '-', color: '#000000', backgroundColor: null },
          { value: '1.64', color: '#000000', backgroundColor: null },
          { value: '0.19', color: '#000000', backgroundColor: null },
          { value: '11.9%', color: '#000000', backgroundColor: null },
          { value: '0.927', color: '#000000', backgroundColor: null },
          { value: '741,096,092', color: '#000000', backgroundColor: null },
          { value: '87,821,759', color: '#000000', backgroundColor: null },
          { value: '6.5%', color: '#000000', backgroundColor: null },
          { value: '12,400', color: '#000000', backgroundColor: null },
        ],
      },
      {
        queryParameter: { kiList: ['024984'] },
        data: [
          {
            value: 'CR大海物語４ MTB',
            color: '#000000',
            backgroundColor: null,
          },
          { value: '4.0', color: '#000000', backgroundColor: null },
          { value: '692.0', color: '#000000', backgroundColor: null },
          { value: '36.4', color: '#000000', backgroundColor: null },
          { value: '21,228', color: '#000000', backgroundColor: null },
          { value: '151%', color: '#000000', backgroundColor: null },
          { value: '3.99', color: '#000000', backgroundColor: null },
          { value: '99.9%', color: '#000000', backgroundColor: null },
          { value: '30,138', color: '#000000', backgroundColor: null },
          { value: '131%', color: '#000000', backgroundColor: null },
          { value: '2,126', color: '#000000', backgroundColor: null },
          { value: '78%', color: '#000000', backgroundColor: null },
          { value: '1.42', color: '#000000', backgroundColor: null },
          { value: '0.10', color: '#000000', backgroundColor: null },
          { value: '7.1%', color: '#000000', backgroundColor: null },
          { value: '0.996', color: '#000000', backgroundColor: null },
          { value: '83,421,412', color: '#000000', backgroundColor: null },
          { value: '5,883,884', color: '#000000', backgroundColor: null },
          { value: '7.3%', color: '#000000', backgroundColor: null },
          { value: '12,400', color: '#000000', backgroundColor: null },
        ],
      },
      {
        queryParameter: { kiList: ['024986'] },
        data: [
          {
            value: 'CR大海物語４ MTB2',
            color: '#000000',
            backgroundColor: null,
          },
          { value: '4.0', color: '#000000', backgroundColor: null },
          { value: '692.0', color: '#000000', backgroundColor: null },
          { value: '36.4', color: '#000000', backgroundColor: null },
          { value: '21,228', color: '#000000', backgroundColor: null },
          { value: '151%', color: '#000000', backgroundColor: null },
          { value: '3.99', color: '#000000', backgroundColor: null },
          { value: '99.9%', color: '#000000', backgroundColor: null },
          { value: '30,138', color: '#000000', backgroundColor: null },
          { value: '131%', color: '#000000', backgroundColor: null },
          { value: '2,126', color: '#000000', backgroundColor: null },
          { value: '78%', color: '#000000', backgroundColor: null },
          { value: '1.42', color: '#000000', backgroundColor: null },
          { value: '0.10', color: '#000000', backgroundColor: null },
          { value: '7.1%', color: '#000000', backgroundColor: null },
          { value: '0.996', color: '#000000', backgroundColor: null },
          { value: '83,421,412', color: '#000000', backgroundColor: null },
          { value: '5,883,884', color: '#000000', backgroundColor: null },
          { value: '7.3%', color: '#000000', backgroundColor: null },
          { value: '12,400', color: '#000000', backgroundColor: null },
        ],
      },
      {
        queryParameter: { kiList: ['024987'] },
        data: [
          {
            value: 'CR大海物語４ MTB3',
            color: '#000000',
            backgroundColor: null,
          },
          { value: '4.0', color: '#000000', backgroundColor: null },
          { value: '692.0', color: '#000000', backgroundColor: null },
          { value: '36.4', color: '#000000', backgroundColor: null },
          { value: '21,228', color: '#000000', backgroundColor: null },
          { value: '151%', color: '#000000', backgroundColor: null },
          { value: '3.99', color: '#000000', backgroundColor: null },
          { value: '99.9%', color: '#000000', backgroundColor: null },
          { value: '30,138', color: '#000000', backgroundColor: null },
          { value: '131%', color: '#000000', backgroundColor: null },
          { value: '2,126', color: '#000000', backgroundColor: null },
          { value: '78%', color: '#000000', backgroundColor: null },
          { value: '1.42', color: '#000000', backgroundColor: null },
          { value: '0.10', color: '#000000', backgroundColor: null },
          { value: '7.1%', color: '#000000', backgroundColor: null },
          { value: '0.996', color: '#000000', backgroundColor: null },
          { value: '83,421,412', color: '#000000', backgroundColor: null },
          { value: '5,883,884', color: '#000000', backgroundColor: null },
          { value: '7.3%', color: '#000000', backgroundColor: null },
          { value: '12,400', color: '#000000', backgroundColor: null },
        ],
      },
      {
        queryParameter: { kiList: ['024988'] },
        data: [
          {
            value: 'P大海物語',
            color: '#000000',
            backgroundColor: null,
          },
          { value: '4.0', color: '#000000', backgroundColor: null },
          { value: '692.0', color: '#000000', backgroundColor: null },
          { value: '36.4', color: '#000000', backgroundColor: null },
          { value: '21,228', color: '#000000', backgroundColor: null },
          { value: '151%', color: '#000000', backgroundColor: null },
          { value: '3.99', color: '#000000', backgroundColor: null },
          { value: '99.9%', color: '#000000', backgroundColor: null },
          { value: '30,138', color: '#000000', backgroundColor: null },
          { value: '131%', color: '#000000', backgroundColor: null },
          { value: '2,126', color: '#000000', backgroundColor: null },
          { value: '78%', color: '#000000', backgroundColor: null },
          { value: '1.42', color: '#000000', backgroundColor: null },
          { value: '0.10', color: '#000000', backgroundColor: null },
          { value: '7.1%', color: '#000000', backgroundColor: null },
          { value: '0.996', color: '#000000', backgroundColor: null },
          { value: '83,421,412', color: '#000000', backgroundColor: null },
          { value: '5,883,884', color: '#000000', backgroundColor: null },
          { value: '7.3%', color: '#000000', backgroundColor: null },
          { value: '12,400', color: '#000000', backgroundColor: null },
        ],
      },
      {
        queryParameter: { kiList: ['026987'] },
        data: [
          {
            value: 'eフィーバーからくりサーカス2 魔王ver.3',
            color: '#000000',
            backgroundColor: null,
          },
          { value: '4.0', color: '#000000', backgroundColor: null },
          { value: '692.0', color: '#000000', backgroundColor: null },
          { value: '36.4', color: '#000000', backgroundColor: null },
          { value: '21,228', color: '#000000', backgroundColor: null },
          { value: '151%', color: '#000000', backgroundColor: null },
          { value: '3.99', color: '#000000', backgroundColor: null },
          { value: '99.9%', color: '#000000', backgroundColor: null },
          { value: '30,138', color: '#000000', backgroundColor: null },
          { value: '131%', color: '#000000', backgroundColor: null },
          { value: '2,126', color: '#000000', backgroundColor: null },
          { value: '78%', color: '#000000', backgroundColor: null },
          { value: '1.42', color: '#000000', backgroundColor: null },
          { value: '0.10', color: '#000000', backgroundColor: null },
          { value: '7.1%', color: '#000000', backgroundColor: null },
          { value: '0.996', color: '#000000', backgroundColor: null },
          { value: '83,421,412', color: '#000000', backgroundColor: null },
          { value: '5,883,884', color: '#000000', backgroundColor: null },
          { value: '7.3%', color: '#000000', backgroundColor: null },
          { value: '12,400', color: '#000000', backgroundColor: null },
        ],
      },
      {
        queryParameter: { kiList: ['027987'] },
        data: [
          {
            value: 'Pフィーバー機動戦士ガンダムSEED',
            color: '#000000',
            backgroundColor: null,
          },
          { value: '4.0', color: '#000000', backgroundColor: null },
          { value: '692.0', color: '#000000', backgroundColor: null },
          { value: '36.4', color: '#000000', backgroundColor: null },
          { value: '21,228', color: '#000000', backgroundColor: null },
          { value: '151%', color: '#000000', backgroundColor: null },
          { value: '3.99', color: '#000000', backgroundColor: null },
          { value: '99.9%', color: '#000000', backgroundColor: null },
          { value: '30,138', color: '#000000', backgroundColor: null },
          { value: '131%', color: '#000000', backgroundColor: null },
          { value: '2,126', color: '#000000', backgroundColor: null },
          { value: '78%', color: '#000000', backgroundColor: null },
          { value: '1.42', color: '#000000', backgroundColor: null },
          { value: '0.10', color: '#000000', backgroundColor: null },
          { value: '7.1%', color: '#000000', backgroundColor: null },
          { value: '0.996', color: '#000000', backgroundColor: null },
          { value: '83,421,412', color: '#000000', backgroundColor: null },
          { value: '5,883,884', color: '#000000', backgroundColor: null },
          { value: '7.3%', color: '#000000', backgroundColor: null },
          { value: '12,400', color: '#000000', backgroundColor: null },
        ],
      },
      {
        queryParameter: { kiList: ['028987'] },
        data: [
          {
            value: 'Pルパン三世 ONE COLLECTION',
            color: '#000000',
            backgroundColor: null,
          },
          { value: '4.0', color: '#000000', backgroundColor: null },
          { value: '692.0', color: '#000000', backgroundColor: null },
          { value: '36.4', color: '#000000', backgroundColor: null },
          { value: '21,228', color: '#000000', backgroundColor: null },
          { value: '151%', color: '#000000', backgroundColor: null },
          { value: '3.99', color: '#000000', backgroundColor: null },
          { value: '99.9%', color: '#000000', backgroundColor: null },
          { value: '30,138', color: '#000000', backgroundColor: null },
          { value: '131%', color: '#000000', backgroundColor: null },
          { value: '2,126', color: '#000000', backgroundColor: null },
          { value: '78%', color: '#000000', backgroundColor: null },
          { value: '1.42', color: '#000000', backgroundColor: null },
          { value: '0.10', color: '#000000', backgroundColor: null },
          { value: '7.1%', color: '#000000', backgroundColor: null },
          { value: '0.996', color: '#000000', backgroundColor: null },
          { value: '83,421,412', color: '#000000', backgroundColor: null },
          { value: '5,883,884', color: '#000000', backgroundColor: null },
          { value: '7.3%', color: '#000000', backgroundColor: null },
          { value: '12,400', color: '#000000', backgroundColor: null },
        ],
      },
      {
        queryParameter: { kiList: ['024987'] },
        data: [
          {
            value: 'CR大海物語４ MTB3',
            color: '#000000',
            backgroundColor: null,
          },
          { value: '4.0', color: '#000000', backgroundColor: null },
          { value: '692.0', color: '#000000', backgroundColor: null },
          { value: '36.4', color: '#000000', backgroundColor: null },
          { value: '21,228', color: '#000000', backgroundColor: null },
          { value: '151%', color: '#000000', backgroundColor: null },
          { value: '3.99', color: '#000000', backgroundColor: null },
          { value: '99.9%', color: '#000000', backgroundColor: null },
          { value: '30,138', color: '#000000', backgroundColor: null },
          { value: '131%', color: '#000000', backgroundColor: null },
          { value: '2,126', color: '#000000', backgroundColor: null },
          { value: '78%', color: '#000000', backgroundColor: null },
          { value: '1.42', color: '#000000', backgroundColor: null },
          { value: '0.10', color: '#000000', backgroundColor: null },
          { value: '7.1%', color: '#000000', backgroundColor: null },
          { value: '0.996', color: '#000000', backgroundColor: null },
          { value: '83,421,412', color: '#000000', backgroundColor: null },
          { value: '5,883,884', color: '#000000', backgroundColor: null },
          { value: '7.3%', color: '#000000', backgroundColor: null },
          { value: '12,400', color: '#000000', backgroundColor: null },
        ],
      },
      {
        queryParameter: { kiList: ['024987'] },
        data: [
          {
            value: 'CR大海物語４ MTB3',
            color: '#000000',
            backgroundColor: null,
          },
          { value: '4.0', color: '#000000', backgroundColor: null },
          { value: '692.0', color: '#000000', backgroundColor: null },
          { value: '36.4', color: '#000000', backgroundColor: null },
          { value: '21,228', color: '#000000', backgroundColor: null },
          { value: '151%', color: '#000000', backgroundColor: null },
          { value: '3.99', color: '#000000', backgroundColor: null },
          { value: '99.9%', color: '#000000', backgroundColor: null },
          { value: '30,138', color: '#000000', backgroundColor: null },
          { value: '131%', color: '#000000', backgroundColor: null },
          { value: '2,126', color: '#000000', backgroundColor: null },
          { value: '78%', color: '#000000', backgroundColor: null },
          { value: '1.42', color: '#000000', backgroundColor: null },
          { value: '0.10', color: '#000000', backgroundColor: null },
          { value: '7.1%', color: '#000000', backgroundColor: null },
          { value: '0.996', color: '#000000', backgroundColor: null },
          { value: '83,421,412', color: '#000000', backgroundColor: null },
          { value: '5,883,884', color: '#000000', backgroundColor: null },
          { value: '7.3%', color: '#000000', backgroundColor: null },
          { value: '12,400', color: '#000000', backgroundColor: null },
        ],
      },
      {
        queryParameter: { kiList: ['028187'] },
        data: [
          {
            value: 'PTo LOVEる ダークネス',
            color: '#000000',
            backgroundColor: null,
          },
          { value: '4.0', color: '#000000', backgroundColor: null },
          { value: '692.0', color: '#000000', backgroundColor: null },
          { value: '36.4', color: '#000000', backgroundColor: null },
          { value: '21,228', color: '#000000', backgroundColor: null },
          { value: '151%', color: '#000000', backgroundColor: null },
          { value: '3.99', color: '#000000', backgroundColor: null },
          { value: '99.9%', color: '#000000', backgroundColor: null },
          { value: '30,138', color: '#000000', backgroundColor: null },
          { value: '131%', color: '#000000', backgroundColor: null },
          { value: '2,126', color: '#000000', backgroundColor: null },
          { value: '78%', color: '#000000', backgroundColor: null },
          { value: '1.42', color: '#000000', backgroundColor: null },
          { value: '0.10', color: '#000000', backgroundColor: null },
          { value: '7.1%', color: '#000000', backgroundColor: null },
          { value: '0.996', color: '#000000', backgroundColor: null },
          { value: '83,421,412', color: '#000000', backgroundColor: null },
          { value: '5,883,884', color: '#000000', backgroundColor: null },
          { value: '7.3%', color: '#000000', backgroundColor: null },
          { value: '12,400', color: '#000000', backgroundColor: null },
        ],
      },
      {
        queryParameter: { kiList: ['0249873'] },
        data: [
          {
            value: 'CR大海物語４ MTB3',
            color: '#000000',
            backgroundColor: null,
          },
          { value: '4.0', color: '#000000', backgroundColor: null },
          { value: '692.0', color: '#000000', backgroundColor: null },
          { value: '36.4', color: '#000000', backgroundColor: null },
          { value: '21,228', color: '#000000', backgroundColor: null },
          { value: '151%', color: '#000000', backgroundColor: null },
          { value: '3.99', color: '#000000', backgroundColor: null },
          { value: '99.9%', color: '#000000', backgroundColor: null },
          { value: '30,138', color: '#000000', backgroundColor: null },
          { value: '131%', color: '#000000', backgroundColor: null },
          { value: '2,126', color: '#000000', backgroundColor: null },
          { value: '78%', color: '#000000', backgroundColor: null },
          { value: '1.42', color: '#000000', backgroundColor: null },
          { value: '0.10', color: '#000000', backgroundColor: null },
          { value: '7.1%', color: '#000000', backgroundColor: null },
          { value: '0.996', color: '#000000', backgroundColor: null },
          { value: '83,421,412', color: '#000000', backgroundColor: null },
          { value: '5,883,884', color: '#000000', backgroundColor: null },
          { value: '7.3%', color: '#000000', backgroundColor: null },
          { value: '12,400', color: '#000000', backgroundColor: null },
        ],
      },
      {
        queryParameter: { kiList: ['0249875'] },
        data: [
          {
            value: 'CR大海物語４ MTB3',
            color: '#000000',
            backgroundColor: null,
          },
          { value: '4.0', color: '#000000', backgroundColor: null },
          { value: '692.0', color: '#000000', backgroundColor: null },
          { value: '36.4', color: '#000000', backgroundColor: null },
          { value: '21,228', color: '#000000', backgroundColor: null },
          { value: '151%', color: '#000000', backgroundColor: null },
          { value: '3.99', color: '#000000', backgroundColor: null },
          { value: '99.9%', color: '#000000', backgroundColor: null },
          { value: '30,138', color: '#000000', backgroundColor: null },
          { value: '131%', color: '#000000', backgroundColor: null },
          { value: '2,126', color: '#000000', backgroundColor: null },
          { value: '78%', color: '#000000', backgroundColor: null },
          { value: '1.42', color: '#000000', backgroundColor: null },
          { value: '0.10', color: '#000000', backgroundColor: null },
          { value: '7.1%', color: '#000000', backgroundColor: null },
          { value: '0.996', color: '#000000', backgroundColor: null },
          { value: '83,421,412', color: '#000000', backgroundColor: null },
          { value: '5,883,884', color: '#000000', backgroundColor: null },
          { value: '7.3%', color: '#000000', backgroundColor: null },
          { value: '12,400', color: '#000000', backgroundColor: null },
        ],
      },
      {
        queryParameter: { kiList: ['0249871'] },
        data: [
          {
            value: 'CR大海物語100 MTB100',
            color: '#000000',
            backgroundColor: null,
          },
          { value: '4.0', color: '#000000', backgroundColor: null },
          { value: '692.0', color: '#000000', backgroundColor: null },
          { value: '36.4', color: '#000000', backgroundColor: null },
          { value: '21,228', color: '#000000', backgroundColor: null },
          { value: '151%', color: '#000000', backgroundColor: null },
          { value: '3.99', color: '#000000', backgroundColor: null },
          { value: '99.9%', color: '#000000', backgroundColor: null },
          { value: '30,138', color: '#000000', backgroundColor: null },
          { value: '131%', color: '#000000', backgroundColor: null },
          { value: '2,126', color: '#000000', backgroundColor: null },
          { value: '78%', color: '#000000', backgroundColor: null },
          { value: '1.42', color: '#000000', backgroundColor: null },
          { value: '0.10', color: '#000000', backgroundColor: null },
          { value: '7.1%', color: '#000000', backgroundColor: null },
          { value: '0.996', color: '#000000', backgroundColor: null },
          { value: '83,421,412', color: '#000000', backgroundColor: null },
          { value: '5,883,884', color: '#000000', backgroundColor: null },
          { value: '7.3%', color: '#000000', backgroundColor: null },
          { value: '12,400', color: '#000000', backgroundColor: null },
        ],
      },
      {
        queryParameter: { kiList: ['036987'] },
        data: [
          {
            value: 'eフィーバーからくりサーカス2 魔王ver.30',
            color: '#000000',
            backgroundColor: null,
          },
          { value: '4.0', color: '#000000', backgroundColor: null },
          { value: '692.0', color: '#000000', backgroundColor: null },
          { value: '36.4', color: '#000000', backgroundColor: null },
          { value: '21,228', color: '#000000', backgroundColor: null },
          { value: '151%', color: '#000000', backgroundColor: null },
          { value: '3.99', color: '#000000', backgroundColor: null },
          { value: '99.9%', color: '#000000', backgroundColor: null },
          { value: '30,138', color: '#000000', backgroundColor: null },
          { value: '131%', color: '#000000', backgroundColor: null },
          { value: '2,126', color: '#000000', backgroundColor: null },
          { value: '78%', color: '#000000', backgroundColor: null },
          { value: '1.42', color: '#000000', backgroundColor: null },
          { value: '0.10', color: '#000000', backgroundColor: null },
          { value: '7.1%', color: '#000000', backgroundColor: null },
          { value: '0.996', color: '#000000', backgroundColor: null },
          { value: '83,421,412', color: '#000000', backgroundColor: null },
          { value: '5,883,884', color: '#000000', backgroundColor: null },
          { value: '7.3%', color: '#000000', backgroundColor: null },
          { value: '12,400', color: '#000000', backgroundColor: null },
        ],
      },
      {
        queryParameter: { kiList: ['046987'] },
        data: [
          {
            value: 'eフィーバーからくりサーカス2 魔王ver.33',
            color: '#000000',
            backgroundColor: null,
          },
          { value: '4.0', color: '#000000', backgroundColor: null },
          { value: '692.0', color: '#000000', backgroundColor: null },
          { value: '36.4', color: '#000000', backgroundColor: null },
          { value: '21,228', color: '#000000', backgroundColor: null },
          { value: '151%', color: '#000000', backgroundColor: null },
          { value: '3.99', color: '#000000', backgroundColor: null },
          { value: '99.9%', color: '#000000', backgroundColor: null },
          { value: '30,138', color: '#000000', backgroundColor: null },
          { value: '131%', color: '#000000', backgroundColor: null },
          { value: '2,126', color: '#000000', backgroundColor: null },
          { value: '78%', color: '#000000', backgroundColor: null },
          { value: '1.42', color: '#000000', backgroundColor: null },
          { value: '0.10', color: '#000000', backgroundColor: null },
          { value: '7.1%', color: '#000000', backgroundColor: null },
          { value: '0.996', color: '#000000', backgroundColor: null },
          { value: '83,421,412', color: '#000000', backgroundColor: null },
          { value: '5,883,884', color: '#000000', backgroundColor: null },
          { value: '7.3%', color: '#000000', backgroundColor: null },
          { value: '12,400', color: '#000000', backgroundColor: null },
        ],
      },
      {
        queryParameter: { kiList: ['056987'] },
        data: [
          {
            value: 'eフィーバーからくりサーカス2 魔王ver.31',
            color: '#000000',
            backgroundColor: null,
          },
          { value: '4.0', color: '#000000', backgroundColor: null },
          { value: '692.0', color: '#000000', backgroundColor: null },
          { value: '36.4', color: '#000000', backgroundColor: null },
          { value: '21,228', color: '#000000', backgroundColor: null },
          { value: '151%', color: '#000000', backgroundColor: null },
          { value: '3.99', color: '#000000', backgroundColor: null },
          { value: '99.9%', color: '#000000', backgroundColor: null },
          { value: '30,138', color: '#000000', backgroundColor: null },
          { value: '131%', color: '#000000', backgroundColor: null },
          { value: '2,126', color: '#000000', backgroundColor: null },
          { value: '78%', color: '#000000', backgroundColor: null },
          { value: '1.42', color: '#000000', backgroundColor: null },
          { value: '0.10', color: '#000000', backgroundColor: null },
          { value: '7.1%', color: '#000000', backgroundColor: null },
          { value: '0.996', color: '#000000', backgroundColor: null },
          { value: '83,421,412', color: '#000000', backgroundColor: null },
          { value: '5,883,884', color: '#000000', backgroundColor: null },
          { value: '7.3%', color: '#000000', backgroundColor: null },
          { value: '12,400', color: '#000000', backgroundColor: null },
        ],
      },
      {
        queryParameter: { kiList: ['0526987'] },
        data: [
          {
            value: 'eフィーバーからくりサーカス2 魔王ver.3133',
            color: '#000000',
            backgroundColor: null,
          },
          { value: '4.0', color: '#000000', backgroundColor: null },
          { value: '692.0', color: '#000000', backgroundColor: null },
          { value: '36.4', color: '#000000', backgroundColor: null },
          { value: '21,228', color: '#000000', backgroundColor: null },
          { value: '151%', color: '#000000', backgroundColor: null },
          { value: '3.99', color: '#000000', backgroundColor: null },
          { value: '99.9%', color: '#000000', backgroundColor: null },
          { value: '30,138', color: '#000000', backgroundColor: null },
          { value: '131%', color: '#000000', backgroundColor: null },
          { value: '2,126', color: '#000000', backgroundColor: null },
          { value: '78%', color: '#000000', backgroundColor: null },
          { value: '1.42', color: '#000000', backgroundColor: null },
          { value: '0.10', color: '#000000', backgroundColor: null },
          { value: '7.1%', color: '#000000', backgroundColor: null },
          { value: '0.996', color: '#000000', backgroundColor: null },
          { value: '83,421,412', color: '#000000', backgroundColor: null },
          { value: '5,883,884', color: '#000000', backgroundColor: null },
          { value: '7.3%', color: '#000000', backgroundColor: null },
          { value: '12,400', color: '#000000', backgroundColor: null },
        ],
      },
      {
        queryParameter: { kiList: ['0563987'] },
        data: [
          {
            value: 'eフィーバーからくりサーカス2 魔王ver.3111魔王',
            color: '#000000',
            backgroundColor: null,
          },
          { value: '4.0', color: '#000000', backgroundColor: null },
          { value: '692.0', color: '#000000', backgroundColor: null },
          { value: '36.4', color: '#000000', backgroundColor: null },
          { value: '21,228', color: '#000000', backgroundColor: null },
          { value: '151%', color: '#000000', backgroundColor: null },
          { value: '3.99', color: '#000000', backgroundColor: null },
          { value: '99.9%', color: '#000000', backgroundColor: null },
          { value: '30,138', color: '#000000', backgroundColor: null },
          { value: '131%', color: '#000000', backgroundColor: null },
          { value: '2,126', color: '#000000', backgroundColor: null },
          { value: '78%', color: '#000000', backgroundColor: null },
          { value: '1.42', color: '#000000', backgroundColor: null },
          { value: '0.10', color: '#000000', backgroundColor: null },
          { value: '7.1%', color: '#000000', backgroundColor: null },
          { value: '0.996', color: '#000000', backgroundColor: null },
          { value: '83,421,412', color: '#000000', backgroundColor: null },
          { value: '5,883,884', color: '#000000', backgroundColor: null },
          { value: '7.3%', color: '#000000', backgroundColor: null },
          { value: '12,400', color: '#000000', backgroundColor: null },
        ],
      },
      {
        queryParameter: { kiList: ['063987'] },
        data: [
          {
            value: 'eフィーバーからくりサーカス2',
            color: '#000000',
            backgroundColor: null,
          },
          { value: '4.0', color: '#000000', backgroundColor: null },
          { value: '692.0', color: '#000000', backgroundColor: null },
          { value: '36.4', color: '#000000', backgroundColor: null },
          { value: '21,228', color: '#000000', backgroundColor: null },
          { value: '151%', color: '#000000', backgroundColor: null },
          { value: '3.99', color: '#000000', backgroundColor: null },
          { value: '99.9%', color: '#000000', backgroundColor: null },
          { value: '30,138', color: '#000000', backgroundColor: null },
          { value: '131%', color: '#000000', backgroundColor: null },
          { value: '2,126', color: '#000000', backgroundColor: null },
          { value: '78%', color: '#000000', backgroundColor: null },
          { value: '1.42', color: '#000000', backgroundColor: null },
          { value: '0.10', color: '#000000', backgroundColor: null },
          { value: '7.1%', color: '#000000', backgroundColor: null },
          { value: '0.996', color: '#000000', backgroundColor: null },
          { value: '83,421,412', color: '#000000', backgroundColor: null },
          { value: '5,883,884', color: '#000000', backgroundColor: null },
          { value: '7.3%', color: '#000000', backgroundColor: null },
          { value: '12,400', color: '#000000', backgroundColor: null },
        ],
      },
      {
        queryParameter: { kiList: ['03987'] },
        data: [
          {
            value: 'eフィーバーからくりサーカス2',
            color: '#000000',
            backgroundColor: null,
          },
          { value: '4.0', color: '#000000', backgroundColor: null },
          { value: '692.0', color: '#000000', backgroundColor: null },
          { value: '36.4', color: '#000000', backgroundColor: null },
          { value: '21,228', color: '#000000', backgroundColor: null },
          { value: '151%', color: '#000000', backgroundColor: null },
          { value: '3.99', color: '#000000', backgroundColor: null },
          { value: '99.9%', color: '#000000', backgroundColor: null },
          { value: '30,138', color: '#000000', backgroundColor: null },
          { value: '131%', color: '#000000', backgroundColor: null },
          { value: '2,126', color: '#000000', backgroundColor: null },
          { value: '78%', color: '#000000', backgroundColor: null },
          { value: '1.42', color: '#000000', backgroundColor: null },
          { value: '0.10', color: '#000000', backgroundColor: null },
          { value: '7.1%', color: '#000000', backgroundColor: null },
          { value: '0.996', color: '#000000', backgroundColor: null },
          { value: '83,421,412', color: '#000000', backgroundColor: null },
          { value: '5,883,884', color: '#000000', backgroundColor: null },
          { value: '7.3%', color: '#000000', backgroundColor: null },
          { value: '12,400', color: '#000000', backgroundColor: null },
        ],
      },
      {
        queryParameter: { kiList: ['0987'] },
        data: [
          {
            value: 'eフィーバーからくりサーカス2',
            color: '#000000',
            backgroundColor: null,
          },
          { value: '4.0', color: '#000000', backgroundColor: null },
          { value: '692.0', color: '#000000', backgroundColor: null },
          { value: '36.4', color: '#000000', backgroundColor: null },
          { value: '21,228', color: '#000000', backgroundColor: null },
          { value: '151%', color: '#000000', backgroundColor: null },
          { value: '3.99', color: '#000000', backgroundColor: null },
          { value: '99.9%', color: '#000000', backgroundColor: null },
          { value: '30,138', color: '#000000', backgroundColor: null },
          { value: '131%', color: '#000000', backgroundColor: null },
          { value: '2,126', color: '#000000', backgroundColor: null },
          { value: '78%', color: '#000000', backgroundColor: null },
          { value: '1.42', color: '#000000', backgroundColor: null },
          { value: '0.10', color: '#000000', backgroundColor: null },
          { value: '7.1%', color: '#000000', backgroundColor: null },
          { value: '0.996', color: '#000000', backgroundColor: null },
          { value: '83,421,412', color: '#000000', backgroundColor: null },
          { value: '5,883,884', color: '#000000', backgroundColor: null },
          { value: '7.3%', color: '#000000', backgroundColor: null },
          { value: '12,400', color: '#000000', backgroundColor: null },
        ],
      },
      {
        queryParameter: { kiList: ['087'] },
        data: [
          {
            value: 'eフィーバーからくりサーカス2',
            color: '#000000',
            backgroundColor: null,
          },
          { value: '4.0', color: '#000000', backgroundColor: null },
          { value: '692.0', color: '#000000', backgroundColor: null },
          { value: '36.4', color: '#000000', backgroundColor: null },
          { value: '21,228', color: '#000000', backgroundColor: null },
          { value: '151%', color: '#000000', backgroundColor: null },
          { value: '3.99', color: '#000000', backgroundColor: null },
          { value: '99.9%', color: '#000000', backgroundColor: null },
          { value: '30,138', color: '#000000', backgroundColor: null },
          { value: '131%', color: '#000000', backgroundColor: null },
          { value: '2,126', color: '#000000', backgroundColor: null },
          { value: '78%', color: '#000000', backgroundColor: null },
          { value: '1.42', color: '#000000', backgroundColor: null },
          { value: '0.10', color: '#000000', backgroundColor: null },
          { value: '7.1%', color: '#000000', backgroundColor: null },
          { value: '0.996', color: '#000000', backgroundColor: null },
          { value: '83,421,412', color: '#000000', backgroundColor: null },
          { value: '5,883,884', color: '#000000', backgroundColor: null },
          { value: '7.3%', color: '#000000', backgroundColor: null },
          { value: '12,400', color: '#000000', backgroundColor: null },
        ],
      },
      {
        queryParameter: { kiList: ['07'] },
        data: [
          {
            value: 'eフィーバーからくりサーカス2',
            color: '#000000',
            backgroundColor: null,
          },
          { value: '4.0', color: '#000000', backgroundColor: null },
          { value: '692.0', color: '#000000', backgroundColor: null },
          { value: '36.4', color: '#000000', backgroundColor: null },
          { value: '21,228', color: '#000000', backgroundColor: null },
          { value: '151%', color: '#000000', backgroundColor: null },
          { value: '3.99', color: '#000000', backgroundColor: null },
          { value: '99.9%', color: '#000000', backgroundColor: null },
          { value: '30,138', color: '#000000', backgroundColor: null },
          { value: '131%', color: '#000000', backgroundColor: null },
          { value: '2,126', color: '#000000', backgroundColor: null },
          { value: '78%', color: '#000000', backgroundColor: null },
          { value: '1.42', color: '#000000', backgroundColor: null },
          { value: '0.10', color: '#000000', backgroundColor: null },
          { value: '7.1%', color: '#000000', backgroundColor: null },
          { value: '0.996', color: '#000000', backgroundColor: null },
          { value: '83,421,412', color: '#000000', backgroundColor: null },
          { value: '5,883,884', color: '#000000', backgroundColor: null },
          { value: '7.3%', color: '#000000', backgroundColor: null },
          { value: '12,400', color: '#000000', backgroundColor: null },
        ],
      },
      {
        queryParameter: { kiList: ['0'] },
        data: [
          {
            value: 'eフィーバーからくりサーカス2',
            color: '#000000',
            backgroundColor: null,
          },
          { value: '4.0', color: '#000000', backgroundColor: null },
          { value: '692.0', color: '#000000', backgroundColor: null },
          { value: '36.4', color: '#000000', backgroundColor: null },
          { value: '21,228', color: '#000000', backgroundColor: null },
          { value: '151%', color: '#000000', backgroundColor: null },
          { value: '3.99', color: '#000000', backgroundColor: null },
          { value: '99.9%', color: '#000000', backgroundColor: null },
          { value: '30,138', color: '#000000', backgroundColor: null },
          { value: '131%', color: '#000000', backgroundColor: null },
          { value: '2,126', color: '#000000', backgroundColor: null },
          { value: '78%', color: '#000000', backgroundColor: null },
          { value: '1.42', color: '#000000', backgroundColor: null },
          { value: '0.10', color: '#000000', backgroundColor: null },
          { value: '7.1%', color: '#000000', backgroundColor: null },
          { value: '0.996', color: '#000000', backgroundColor: null },
          { value: '83,421,412', color: '#000000', backgroundColor: null },
          { value: '5,883,884', color: '#000000', backgroundColor: null },
          { value: '7.3%', color: '#000000', backgroundColor: null },
          { value: '12,400', color: '#000000', backgroundColor: null },
        ],
      },
      {
        queryParameter: { kiList: ['1'] },
        data: [
          {
            value: 'eフィーバーからくりサーカス2',
            color: '#000000',
            backgroundColor: null,
          },
          { value: '4.0', color: '#000000', backgroundColor: null },
          { value: '692.0', color: '#000000', backgroundColor: null },
          { value: '36.4', color: '#000000', backgroundColor: null },
          { value: '21,228', color: '#000000', backgroundColor: null },
          { value: '151%', color: '#000000', backgroundColor: null },
          { value: '3.99', color: '#000000', backgroundColor: null },
          { value: '99.9%', color: '#000000', backgroundColor: null },
          { value: '30,138', color: '#000000', backgroundColor: null },
          { value: '131%', color: '#000000', backgroundColor: null },
          { value: '2,126', color: '#000000', backgroundColor: null },
          { value: '78%', color: '#000000', backgroundColor: null },
          { value: '1.42', color: '#000000', backgroundColor: null },
          { value: '0.10', color: '#000000', backgroundColor: null },
          { value: '7.1%', color: '#000000', backgroundColor: null },
          { value: '0.996', color: '#000000', backgroundColor: null },
          { value: '83,421,412', color: '#000000', backgroundColor: null },
          { value: '5,883,884', color: '#000000', backgroundColor: null },
          { value: '7.3%', color: '#000000', backgroundColor: null },
          { value: '12,400', color: '#000000', backgroundColor: null },
        ],
      },
      {
        queryParameter: { kiList: ['2'] },
        data: [
          {
            value: 'eフィーバーからくりサーカス2',
            color: '#000000',
            backgroundColor: null,
          },
          { value: '4.0', color: '#000000', backgroundColor: null },
          { value: '692.0', color: '#000000', backgroundColor: null },
          { value: '36.4', color: '#000000', backgroundColor: null },
          { value: '21,228', color: '#000000', backgroundColor: null },
          { value: '151%', color: '#000000', backgroundColor: null },
          { value: '3.99', color: '#000000', backgroundColor: null },
          { value: '99.9%', color: '#000000', backgroundColor: null },
          { value: '30,138', color: '#000000', backgroundColor: null },
          { value: '131%', color: '#000000', backgroundColor: null },
          { value: '2,126', color: '#000000', backgroundColor: null },
          { value: '78%', color: '#000000', backgroundColor: null },
          { value: '1.42', color: '#000000', backgroundColor: null },
          { value: '0.10', color: '#000000', backgroundColor: null },
          { value: '7.1%', color: '#000000', backgroundColor: null },
          { value: '0.996', color: '#000000', backgroundColor: null },
          { value: '83,421,412', color: '#000000', backgroundColor: null },
          { value: '5,883,884', color: '#000000', backgroundColor: null },
          { value: '7.3%', color: '#000000', backgroundColor: null },
          { value: '12,400', color: '#000000', backgroundColor: null },
        ],
      },
      {
        queryParameter: { kiList: ['2'] },
        data: [
          {
            value: 'eフィーバーからくりサーカス2',
            color: '#000000',
            backgroundColor: null,
          },
          { value: '4.0', color: '#000000', backgroundColor: null },
          { value: '692.0', color: '#000000', backgroundColor: null },
          { value: '36.4', color: '#000000', backgroundColor: null },
          { value: '21,228', color: '#000000', backgroundColor: null },
          { value: '151%', color: '#000000', backgroundColor: null },
          { value: '3.99', color: '#000000', backgroundColor: null },
          { value: '99.9%', color: '#000000', backgroundColor: null },
          { value: '30,138', color: '#000000', backgroundColor: null },
          { value: '131%', color: '#000000', backgroundColor: null },
          { value: '2,126', color: '#000000', backgroundColor: null },
          { value: '78%', color: '#000000', backgroundColor: null },
          { value: '1.42', color: '#000000', backgroundColor: null },
          { value: '0.10', color: '#000000', backgroundColor: null },
          { value: '7.1%', color: '#000000', backgroundColor: null },
          { value: '0.996', color: '#000000', backgroundColor: null },
          { value: '83,421,412', color: '#000000', backgroundColor: null },
          { value: '5,883,884', color: '#000000', backgroundColor: null },
          { value: '7.3%', color: '#000000', backgroundColor: null },
          { value: '12,400', color: '#000000', backgroundColor: null },
        ],
      },
      {
        queryParameter: { kiList: ['3'] },
        data: [
          {
            value: 'eフィーバーからくりサーカス2',
            color: '#000000',
            backgroundColor: null,
          },
          { value: '4.0', color: '#000000', backgroundColor: null },
          { value: '692.0', color: '#000000', backgroundColor: null },
          { value: '36.4', color: '#000000', backgroundColor: null },
          { value: '21,228', color: '#000000', backgroundColor: null },
          { value: '151%', color: '#000000', backgroundColor: null },
          { value: '3.99', color: '#000000', backgroundColor: null },
          { value: '99.9%', color: '#000000', backgroundColor: null },
          { value: '30,138', color: '#000000', backgroundColor: null },
          { value: '131%', color: '#000000', backgroundColor: null },
          { value: '2,126', color: '#000000', backgroundColor: null },
          { value: '78%', color: '#000000', backgroundColor: null },
          { value: '1.42', color: '#000000', backgroundColor: null },
          { value: '0.10', color: '#000000', backgroundColor: null },
          { value: '7.1%', color: '#000000', backgroundColor: null },
          { value: '0.996', color: '#000000', backgroundColor: null },
          { value: '83,421,412', color: '#000000', backgroundColor: null },
          { value: '5,883,884', color: '#000000', backgroundColor: null },
          { value: '7.3%', color: '#000000', backgroundColor: null },
          { value: '12,400', color: '#000000', backgroundColor: null },
        ],
      },
      {
        queryParameter: { kiList: ['4'] },
        data: [
          {
            value: 'eフィーバーからくりサーカス2',
            color: '#000000',
            backgroundColor: null,
          },
          { value: '4.0', color: '#000000', backgroundColor: null },
          { value: '692.0', color: '#000000', backgroundColor: null },
          { value: '36.4', color: '#000000', backgroundColor: null },
          { value: '21,228', color: '#000000', backgroundColor: null },
          { value: '151%', color: '#000000', backgroundColor: null },
          { value: '3.99', color: '#000000', backgroundColor: null },
          { value: '99.9%', color: '#000000', backgroundColor: null },
          { value: '30,138', color: '#000000', backgroundColor: null },
          { value: '131%', color: '#000000', backgroundColor: null },
          { value: '2,126', color: '#000000', backgroundColor: null },
          { value: '78%', color: '#000000', backgroundColor: null },
          { value: '1.42', color: '#000000', backgroundColor: null },
          { value: '0.10', color: '#000000', backgroundColor: null },
          { value: '7.1%', color: '#000000', backgroundColor: null },
          { value: '0.996', color: '#000000', backgroundColor: null },
          { value: '83,421,412', color: '#000000', backgroundColor: null },
          { value: '5,883,884', color: '#000000', backgroundColor: null },
          { value: '7.3%', color: '#000000', backgroundColor: null },
          { value: '12,400', color: '#000000', backgroundColor: null },
        ],
      },
      {
        queryParameter: { kiList: ['5'] },
        data: [
          {
            value: 'eフィーバーからくりサーカス2',
            color: '#000000',
            backgroundColor: null,
          },
          { value: '4.0', color: '#000000', backgroundColor: null },
          { value: '692.0', color: '#000000', backgroundColor: null },
          { value: '36.4', color: '#000000', backgroundColor: null },
          { value: '21,228', color: '#000000', backgroundColor: null },
          { value: '151%', color: '#000000', backgroundColor: null },
          { value: '3.99', color: '#000000', backgroundColor: null },
          { value: '99.9%', color: '#000000', backgroundColor: null },
          { value: '30,138', color: '#000000', backgroundColor: null },
          { value: '131%', color: '#000000', backgroundColor: null },
          { value: '2,126', color: '#000000', backgroundColor: null },
          { value: '78%', color: '#000000', backgroundColor: null },
          { value: '1.42', color: '#000000', backgroundColor: null },
          { value: '0.10', color: '#000000', backgroundColor: null },
          { value: '7.1%', color: '#000000', backgroundColor: null },
          { value: '0.996', color: '#000000', backgroundColor: null },
          { value: '83,421,412', color: '#000000', backgroundColor: null },
          { value: '5,883,884', color: '#000000', backgroundColor: null },
          { value: '7.3%', color: '#000000', backgroundColor: null },
          { value: '12,400', color: '#000000', backgroundColor: null },
        ],
      },
      {
        queryParameter: { kiList: ['6'] },
        data: [
          {
            value: 'eフィーバーからくりサーカス2',
            color: '#000000',
            backgroundColor: null,
          },
          { value: '4.0', color: '#000000', backgroundColor: null },
          { value: '692.0', color: '#000000', backgroundColor: null },
          { value: '36.4', color: '#000000', backgroundColor: null },
          { value: '21,228', color: '#000000', backgroundColor: null },
          { value: '151%', color: '#000000', backgroundColor: null },
          { value: '3.99', color: '#000000', backgroundColor: null },
          { value: '99.9%', color: '#000000', backgroundColor: null },
          { value: '30,138', color: '#000000', backgroundColor: null },
          { value: '131%', color: '#000000', backgroundColor: null },
          { value: '2,126', color: '#000000', backgroundColor: null },
          { value: '78%', color: '#000000', backgroundColor: null },
          { value: '1.42', color: '#000000', backgroundColor: null },
          { value: '0.10', color: '#000000', backgroundColor: null },
          { value: '7.1%', color: '#000000', backgroundColor: null },
          { value: '0.996', color: '#000000', backgroundColor: null },
          { value: '83,421,412', color: '#000000', backgroundColor: null },
          { value: '5,883,884', color: '#000000', backgroundColor: null },
          { value: '7.3%', color: '#000000', backgroundColor: null },
          { value: '12,400', color: '#000000', backgroundColor: null },
        ],
      },
      {
        queryParameter: { kiList: ['7'] },
        data: [
          {
            value: 'eフィーバーからくりサーカス2',
            color: '#000000',
            backgroundColor: null,
          },
          { value: '4.0', color: '#000000', backgroundColor: null },
          { value: '692.0', color: '#000000', backgroundColor: null },
          { value: '36.4', color: '#000000', backgroundColor: null },
          { value: '21,228', color: '#000000', backgroundColor: null },
          { value: '151%', color: '#000000', backgroundColor: null },
          { value: '3.99', color: '#000000', backgroundColor: null },
          { value: '99.9%', color: '#000000', backgroundColor: null },
          { value: '30,138', color: '#000000', backgroundColor: null },
          { value: '131%', color: '#000000', backgroundColor: null },
          { value: '2,126', color: '#000000', backgroundColor: null },
          { value: '78%', color: '#000000', backgroundColor: null },
          { value: '1.42', color: '#000000', backgroundColor: null },
          { value: '0.10', color: '#000000', backgroundColor: null },
          { value: '7.1%', color: '#000000', backgroundColor: null },
          { value: '0.996', color: '#000000', backgroundColor: null },
          { value: '83,421,412', color: '#000000', backgroundColor: null },
          { value: '5,883,884', color: '#000000', backgroundColor: null },
          { value: '7.3%', color: '#000000', backgroundColor: null },
          { value: '12,400', color: '#000000', backgroundColor: null },
        ],
      },
      {
        queryParameter: { kiList: ['8'] },
        data: [
          {
            value: 'eフィーバーからくりサーカス2',
            color: '#000000',
            backgroundColor: null,
          },
          { value: '4.0', color: '#000000', backgroundColor: null },
          { value: '692.0', color: '#000000', backgroundColor: null },
          { value: '36.4', color: '#000000', backgroundColor: null },
          { value: '21,228', color: '#000000', backgroundColor: null },
          { value: '151%', color: '#000000', backgroundColor: null },
          { value: '3.99', color: '#000000', backgroundColor: null },
          { value: '99.9%', color: '#000000', backgroundColor: null },
          { value: '30,138', color: '#000000', backgroundColor: null },
          { value: '131%', color: '#000000', backgroundColor: null },
          { value: '2,126', color: '#000000', backgroundColor: null },
          { value: '78%', color: '#000000', backgroundColor: null },
          { value: '1.42', color: '#000000', backgroundColor: null },
          { value: '0.10', color: '#000000', backgroundColor: null },
          { value: '7.1%', color: '#000000', backgroundColor: null },
          { value: '0.996', color: '#000000', backgroundColor: null },
          { value: '83,421,412', color: '#000000', backgroundColor: null },
          { value: '5,883,884', color: '#000000', backgroundColor: null },
          { value: '7.3%', color: '#000000', backgroundColor: null },
          { value: '12,400', color: '#000000', backgroundColor: null },
        ],
      },
      {
        queryParameter: { kiList: ['9'] },
        data: [
          {
            value: 'eフィーバーからくりサーカス2',
            color: '#000000',
            backgroundColor: null,
          },
          { value: '4.0', color: '#000000', backgroundColor: null },
          { value: '692.0', color: '#000000', backgroundColor: null },
          { value: '36.4', color: '#000000', backgroundColor: null },
          { value: '21,228', color: '#000000', backgroundColor: null },
          { value: '151%', color: '#000000', backgroundColor: null },
          { value: '3.99', color: '#000000', backgroundColor: null },
          { value: '99.9%', color: '#000000', backgroundColor: null },
          { value: '30,138', color: '#000000', backgroundColor: null },
          { value: '131%', color: '#000000', backgroundColor: null },
          { value: '2,126', color: '#000000', backgroundColor: null },
          { value: '78%', color: '#000000', backgroundColor: null },
          { value: '1.42', color: '#000000', backgroundColor: null },
          { value: '0.10', color: '#000000', backgroundColor: null },
          { value: '7.1%', color: '#000000', backgroundColor: null },
          { value: '0.996', color: '#000000', backgroundColor: null },
          { value: '83,421,412', color: '#000000', backgroundColor: null },
          { value: '5,883,884', color: '#000000', backgroundColor: null },
          { value: '7.3%', color: '#000000', backgroundColor: null },
          { value: '12,400', color: '#000000', backgroundColor: null },
        ],
      },
      {
        queryParameter: { kiList: ['10'] },
        data: [
          {
            value: 'eフィーバーからくりサーカス2',
            color: '#000000',
            backgroundColor: null,
          },
          { value: '4.0', color: '#000000', backgroundColor: null },
          { value: '692.0', color: '#000000', backgroundColor: null },
          { value: '36.4', color: '#000000', backgroundColor: null },
          { value: '21,228', color: '#000000', backgroundColor: null },
          { value: '151%', color: '#000000', backgroundColor: null },
          { value: '3.99', color: '#000000', backgroundColor: null },
          { value: '99.9%', color: '#000000', backgroundColor: null },
          { value: '30,138', color: '#000000', backgroundColor: null },
          { value: '131%', color: '#000000', backgroundColor: null },
          { value: '2,126', color: '#000000', backgroundColor: null },
          { value: '78%', color: '#000000', backgroundColor: null },
          { value: '1.42', color: '#000000', backgroundColor: null },
          { value: '0.10', color: '#000000', backgroundColor: null },
          { value: '7.1%', color: '#000000', backgroundColor: null },
          { value: '0.996', color: '#000000', backgroundColor: null },
          { value: '83,421,412', color: '#000000', backgroundColor: null },
          { value: '5,883,884', color: '#000000', backgroundColor: null },
          { value: '7.3%', color: '#000000', backgroundColor: null },
          { value: '12,400', color: '#000000', backgroundColor: null },
        ],
      },
      {
        queryParameter: { kiList: ['11'] },
        data: [
          {
            value: 'eフィーバーからくりサーカス2',
            color: '#000000',
            backgroundColor: null,
          },
          { value: '4.0', color: '#000000', backgroundColor: null },
          { value: '692.0', color: '#000000', backgroundColor: null },
          { value: '36.4', color: '#000000', backgroundColor: null },
          { value: '21,228', color: '#000000', backgroundColor: null },
          { value: '151%', color: '#000000', backgroundColor: null },
          { value: '3.99', color: '#000000', backgroundColor: null },
          { value: '99.9%', color: '#000000', backgroundColor: null },
          { value: '30,138', color: '#000000', backgroundColor: null },
          { value: '131%', color: '#000000', backgroundColor: null },
          { value: '2,126', color: '#000000', backgroundColor: null },
          { value: '78%', color: '#000000', backgroundColor: null },
          { value: '1.42', color: '#000000', backgroundColor: null },
          { value: '0.10', color: '#000000', backgroundColor: null },
          { value: '7.1%', color: '#000000', backgroundColor: null },
          { value: '0.996', color: '#000000', backgroundColor: null },
          { value: '83,421,412', color: '#000000', backgroundColor: null },
          { value: '5,883,884', color: '#000000', backgroundColor: null },
          { value: '7.3%', color: '#000000', backgroundColor: null },
          { value: '12,400', color: '#000000', backgroundColor: null },
        ],
      },
    ],
  },
};
