import { AxiosResponse } from 'axios';
import { call, fork, put, takeEvery } from 'redux-saga/effects';

import { SettingsOptionsTerminalTransition } from '../domain/settingsOptionsTerminalTransition';

import {
  SettingsOptionsTerminalTransitionActionCreators,
  SettingsOptionsTerminalTransitionActionTypes,
} from '../redux/server/settingsOptionsTerminalTransition';
import { Api, buildConfig } from '../utils/api';
import { handleErrorSaga } from './errorSaga';

export function* fetchSettingsOptionsTerminalTransitionSaga(api: Api) {
  try {
    yield put(
      SettingsOptionsTerminalTransitionActionCreators.fetchSettingsOptionsTerminalTransitionRequestAction()
    );
    const response: AxiosResponse<SettingsOptionsTerminalTransition> = yield call(
      api.get,
      '/settings/options/terminalTransition',
      buildConfig()
    );
    const settingsOptionsKi: SettingsOptionsTerminalTransition = response.data;
    yield put(
      SettingsOptionsTerminalTransitionActionCreators.fetchSettingsOptionsTerminalTransitionSuccessAction(
        settingsOptionsKi
      )
    );
  } catch (error: unknown) {
    yield put(
      SettingsOptionsTerminalTransitionActionCreators.renewSettingsOptionsTerminalTransitionAction()
    );
    yield fork(handleErrorSaga, error);
  }
}

function* handleFetchSettingsOptionsTerminalTransitionSaga(api: Api) {
  yield takeEvery(
    SettingsOptionsTerminalTransitionActionTypes.FETCH_SETTINGS_OPTIONS_TERMINAL_TRANSITION,
    fetchSettingsOptionsTerminalTransitionSaga,
    api
  );
}

export function* settingsOptionsTerminalTransitionSagas(context: { api: Api }) {
  yield fork(handleFetchSettingsOptionsTerminalTransitionSaga, context.api);
}
