import { AxiosResponse } from 'axios';
import { call, fork, put, select, takeEvery } from 'redux-saga/effects';

import {
  DataHallShu,
  DataHallShuParams,
  DataHallShuSummary,
} from '../domain/dataHallShu';
import { HallReportsFormConditions } from '../domain/hallReportsFormConditions';
import { REPORT_MARKING_CONDITIONS } from '../domain/marking';
import { Column } from '../domain/schemas';
import { ShuOption } from '../domain/shu';

import { DataHallDailyCommentsActionCreators } from '../redux/server/dataHallDailyComments';
import {
  ChangeDataHallShuColumnsOrderAction,
  DataHallShuActionCreators,
  DataHallShuActionTypes,
  FetchDataHallShuAction,
  FetchDataHallShuSummaryAction,
  SearchDataHallShuAction,
  SearchDataHallShuFieldTypeAction,
  SearchDataHallShuMarkingAction,
  SearchDataHallShuSortAction,
  dataHallShuColumnsOrderSelector,
  dataHallShuDataColumnsSelector,
  dataHallShuOrderedData,
  dataHallShuSummaryIsExistSelector,
  dataHallShuSummaryLoadingSelector,
  dataHallShuTableIsExistSelector,
  dataHallShuTableLoadingSelector,
  dataHallShuTableSearchConditionSelector,
} from '../redux/server/dataHallShu';
import {
  hallReportsSearchConditionSelector,
  hallReportsSelectedShuSelector,
} from '../redux/ui/hallReportsSetting';
import { Api, buildConfig } from '../utils/api';
import { recalcColumnOrder } from '../utils/orderedCell';
import { selectShus2HallReportSearchCondition } from '../utils/shu';
import { handleErrorSaga } from './errorSaga';

/**
 * 種別実績テーブルデータをAPIから取得する
 * @param api AxiosInstance
 * @param action Action
 */
export function* fetchDataHallShuTableSaga(
  api: Api,
  action: FetchDataHallShuAction
) {
  try {
    yield put(DataHallShuActionCreators.fetchDataHallShuRequestAction());
    // params 内で指定されている種別ごとにステートを管理する
    const response: AxiosResponse<DataHallShu> = yield call(
      api.get,
      '/data/hall/shu',
      buildConfig(action.payload.params)
    );

    // 正常時取得したデータを dataHallShu にDispatchする
    yield put(
      DataHallShuActionCreators.fetchDataHallShuSuccessAction(response.data)
    );

    if (
      response?.data?.setting?.ymdList &&
      response.data.setting.ymdList.length > 0
    ) {
      yield put(
        DataHallDailyCommentsActionCreators.fetchDataHallDailyCommentsRequestAction(
          response.data.setting.ymdList[0]
        )
      );
    }
  } catch (error: unknown) {
    // エラー時エラー内容をDispatchする
    yield put(DataHallShuActionCreators.renewDataHallShuAction());
    yield fork(handleErrorSaga, error);
  }
}

/**
 * 種別集計概要データをAPIから取得する
 * @param api AxiosInstance
 * @param action Action
 */
export function* fetchDataHallShuSummarySaga(
  api: Api,
  action: FetchDataHallShuSummaryAction
) {
  try {
    yield put(DataHallShuActionCreators.fetchDataHallShuSummaryRequestAction());
    const response: AxiosResponse<DataHallShuSummary> = yield call(
      api.get,
      '/data/hall/shu/summary',
      buildConfig(action.payload.params)
    );
    yield put(
      DataHallShuActionCreators.fetchDataHallShuSummarySuccessAction(
        response.data
      )
    );
  } catch (error: unknown) {
    yield put(DataHallShuActionCreators.renewDataHallShuAction());
    yield fork(handleErrorSaga, error);
  }
}

/**
 * 種別実績テーブルと種別集計概要を初回取得する
 */
function* initDataHallShuSaga() {
  // 種別実績Summary部分の初回取得判定用データ
  const isSummaryExist: boolean = yield select(
    dataHallShuSummaryIsExistSelector
  );
  const isSummaryLoading: boolean = yield select(
    dataHallShuSummaryLoadingSelector
  );

  // 種別実績テーブル部分の初回取得判定用データ
  const isShuExist: boolean = yield select(dataHallShuTableIsExistSelector);
  const isShuLoading: boolean = yield select(dataHallShuTableLoadingSelector);

  // 現在の検索条件
  const searchCondition: HallReportsFormConditions = yield select(
    hallReportsSearchConditionSelector
  );

  // 現在の選択されている種別
  const selectedShu: ShuOption[] = yield select(hallReportsSelectedShuSelector);

  // 他でデータを取得中か、既にデータ取得済みか、取得したがエラーの時は初回取得しない
  if (!isSummaryLoading && !isSummaryExist) {
    // 種別集計概要を取得
    yield put(
      DataHallShuActionCreators.fetchDataHallShuSummaryAction({
        halls: searchCondition.halls,
        ymdList: searchCondition.ymdList,
        excludeToday: searchCondition.excludeToday,
        ...selectShus2HallReportSearchCondition(selectedShu),
      })
    );
  }

  // 他でデータを取得中か、既にデータ取得済みか、取得したがエラーの時は初回取得しない
  if (!isShuLoading && !isShuExist) {
    // 種別実績テーブルを取得
    yield put(
      DataHallShuActionCreators.fetchDataHallShuAction({
        halls: searchCondition.halls,
        ymdList: searchCondition.ymdList,
        ymdComparisonList: searchCondition.ymdComparisonList,
        excludeToday: searchCondition.excludeToday,
        ...selectShus2HallReportSearchCondition(selectedShu),
      })
    );
  }
}

/**
 * 検索実行時検索フォームの内容でテーブルを再取得する
 * @param action Action
 */
function* searchDataHallShuSaga(action: SearchDataHallShuAction) {
  // 検索フォームで指定した検索条件
  const params = action.payload.params;

  // 現在の種別実績テーブルの検索条件を取得する
  const searchCondition: DataHallShuParams = yield select(
    dataHallShuTableSearchConditionSelector
  );

  // 種別集計概要を取得
  yield put(
    DataHallShuActionCreators.fetchDataHallShuSummaryAction({
      shuList: params.shuList,
      shuGroupIds: params.shuGroupIds,
      halls: params.halls,
      ymdList: params.ymdList,
      excludeToday: params.excludeToday,
    })
  );

  // 種別実績テーブルを取得
  yield put(
    DataHallShuActionCreators.fetchDataHallShuAction({
      ...searchCondition, // テーブル固有の検索条件を混ぜる
      shuList: params.shuList,
      shuGroupIds: params.shuGroupIds,
      halls: params.halls,
      ymdList: params.ymdList,
      ymdComparisonList: params.ymdComparisonList,
      excludeToday: params.excludeToday,
    })
  );
}

/**
 * ソート条件で種別実績テーブルデータを再取得する
 * @param action Action
 */
function* searchDataHallShuSortSaga(action: SearchDataHallShuSortAction) {
  // 現在の種別実績テーブルの検索条件を取得する
  const searchCondition: DataHallShuParams = yield select(
    dataHallShuTableSearchConditionSelector
  );

  // 種別実績テーブルを取得
  yield put(
    DataHallShuActionCreators.fetchDataHallShuAction({
      ...searchCondition,
      sort: action.payload.sort,
      order: action.payload.order,
    })
  );
}

/**
 * マーキング条件で種別実績テーブルデータを再取得する
 * @param action Action
 */
function* searchDataHallShuMarkingSaga(action: SearchDataHallShuMarkingAction) {
  // 現在の種別実績テーブルの検索条件を取得する
  const searchCondition: DataHallShuParams = yield select(
    dataHallShuTableSearchConditionSelector
  );

  // 種別実績テーブルを取得
  yield put(
    DataHallShuActionCreators.fetchDataHallShuAction({
      ...searchCondition,
      marking:
        action.payload.markingOption.code ===
        REPORT_MARKING_CONDITIONS.at(0)?.code
          ? undefined
          : action.payload.markingOption.code,
      isFiltering: action.payload.isFiltering,
    })
  );
}

/**
 * 指定した表示項目の種別実績テーブルデータを再取得する
 * @param action Action
 */
function* searchDataHallShuFieldTypeSaga(
  action: SearchDataHallShuFieldTypeAction
) {
  // 現在の種別実績テーブルの検索条件を取得する
  const searchCondition: DataHallShuParams = yield select(
    dataHallShuTableSearchConditionSelector
  );

  // 種別実績テーブルを取得
  yield put(
    DataHallShuActionCreators.fetchDataHallShuAction({
      ...searchCondition,
      fields: action.payload.fields.map((field) => field.code),
    })
  );
}

/**
 * FETCH_DATA_HALL_SHU がDispatchされた時に fetchDataHallShuTableSaga を実行する
 * @param api AxiosInstance
 */
function* handleFetchDataHallShuTableSaga(api: Api) {
  yield takeEvery(
    DataHallShuActionTypes.FETCH_DATA_HALL_SHU,
    fetchDataHallShuTableSaga,
    api
  );
  // 表示項目と並び替えのチェック
  yield takeEvery(
    DataHallShuActionTypes.FETCH_DATA_HALL_SHU_SUCCESS,
    columnsOrderCheckSaga
  );
}

/**
 * FETCH_DATA_HALL_SHU_SUMMARY がDispatchされた時に fetchDataHallShuSummarySaga を実行する
 * @param api AxiosInstance
 */
function* handleFetchDataHallShuSummarySaga(api: Api) {
  yield takeEvery(
    DataHallShuActionTypes.FETCH_DATA_HALL_SHU_SUMMARY,
    fetchDataHallShuSummarySaga,
    api
  );
}

/**
 * 表示項目が変更されている場合並び替えに反映する
 */
function* columnsOrderCheckSaga() {
  const fields: Column[] = yield select(dataHallShuDataColumnsSelector);

  const fieldCodes = fields.map((field) => field.code);

  // テーブル列の並び順
  let columnsOrder: string[] = yield select(dataHallShuColumnsOrderSelector);

  if (!columnsOrder || columnsOrder.length === 0) {
    // ソート順が設定されていない場合、初期配置をデフォルトで設定する
    columnsOrder = fields.map((column) => column.code);
  }

  const sorted = [...fieldCodes].sort((a, b) => {
    // 店舗名は常に先頭
    if (b === 'shu') {
      return 1;
    }
    return columnsOrder.indexOf(b) > columnsOrder.indexOf(a) ? -1 : 1;
  });

  yield put(
    DataHallShuActionCreators.selectDataHallShuColumnsOrderAction(sorted)
  );
}

/**
 * ドラッグ＆ドロップしたセルのIDを元に並び替え情報を登録する
 */
function* changeDataHallShuColumnsOrderSaga(
  action: ChangeDataHallShuColumnsOrderAction
) {
  const tableData: DataHallShu['data'] = yield select(dataHallShuOrderedData);

  const ordered = recalcColumnOrder(
    tableData.columns,
    action.payload.draggedId,
    action.payload.droppedId
  );

  yield put(
    DataHallShuActionCreators.selectDataHallShuColumnsOrderAction(ordered)
  );
}

/**
 * 初回取得時
 */
function* handleInitDataHallShuSaga() {
  yield takeEvery(
    DataHallShuActionTypes.INIT_DATA_HALL_SHU,
    initDataHallShuSaga
  );
}

/**
 * 検索時
 */
function* handleSearchDataHallShuSaga() {
  yield takeEvery(
    DataHallShuActionTypes.SEARCH_DATA_HALL_SHU,
    searchDataHallShuSaga
  );
}

/**
 * ソート時
 */
function* handleSearchDataHallShuSortSaga() {
  yield takeEvery(
    DataHallShuActionTypes.SEARCH_DATA_HALL_SHU_SORT,
    searchDataHallShuSortSaga
  );
}

/**
 * マーキング時
 */
function* handleSearchDataHallShuMarkingSaga() {
  yield takeEvery(
    DataHallShuActionTypes.SEARCH_DATA_HALL_SHU_MARKING,
    searchDataHallShuMarkingSaga
  );
}

/**
 * 表示項目変更時
 */
function* handleSearchDataHallShuFieldTypeSaga() {
  yield takeEvery(
    DataHallShuActionTypes.SEARCH_DATA_HALL_SHU_FIELD_TYPE,
    searchDataHallShuFieldTypeSaga
  );
}

/**
 * 表示項目並び替え変更時
 */
function* handleChangeDataHallShuColumnsOrderSaga() {
  yield takeEvery(
    DataHallShuActionTypes.CHANGE_DATA_HALL_SHU_COLUMNS_ORDER,
    changeDataHallShuColumnsOrderSaga
  );
}

/**
 * 種別集計データに関するタスクを実行する
 * @param context AxiosInstance
 */
export function* dataHallShuSagas(context: { api: Api }) {
  yield fork(handleFetchDataHallShuTableSaga, context.api);
  yield fork(handleFetchDataHallShuSummarySaga, context.api);
  yield fork(handleInitDataHallShuSaga);
  yield fork(handleSearchDataHallShuSaga);
  yield fork(handleSearchDataHallShuSortSaga);
  yield fork(handleSearchDataHallShuMarkingSaga);
  yield fork(handleSearchDataHallShuFieldTypeSaga);
  yield fork(handleChangeDataHallShuColumnsOrderSaga);
}
