import { createSelector } from 'reselect';

import { LoadingState } from '../../domain/schemas';
import { SettingsOptionsEvents } from '../../domain/settingsEvents/types';

/* ---------------------------------------------------------------
 * Action Types
 */

const INIT_SETTINGS_OPTIONS_EVENTS = 'INIT_SETTINGS_OPTIONS_EVENTS' as const;
const FETCH_SETTINGS_OPTIONS_EVENTS = 'FETCH_SETTINGS_OPTIONS_EVENTS' as const;
const FETCH_SETTINGS_OPTIONS_EVENTS_REQUEST = 'FETCH_SETTINGS_OPTIONS_EVENTS_REQUEST' as const;
const FETCH_SETTINGS_OPTIONS_EVENTS_SUCCESS = 'FETCH_SETTINGS_OPTIONS_EVENTS_SUCCESS' as const;
const RENEW_SETTINGS_OPTIONS_EVENTS = 'RENEW_SETTINGS_OPTIONS_EVENTS' as const;

export const SettingsOptionsEventsActionTypes = {
  INIT_SETTINGS_OPTIONS_EVENTS,
  FETCH_SETTINGS_OPTIONS_EVENTS,
  FETCH_SETTINGS_OPTIONS_EVENTS_REQUEST,
  FETCH_SETTINGS_OPTIONS_EVENTS_SUCCESS,
  RENEW_SETTINGS_OPTIONS_EVENTS,
};

/* ---------------------------------------------------------------
 * Action Creators
 */

function initSettingsOptionsEventsAction() {
  return {
    type: INIT_SETTINGS_OPTIONS_EVENTS,
  };
}

function fetchSettingsOptionsEventsAction() {
  return {
    type: FETCH_SETTINGS_OPTIONS_EVENTS,
  };
}

function fetchSettingsOptionsEventsRequestAction() {
  return {
    type: FETCH_SETTINGS_OPTIONS_EVENTS_REQUEST,
  };
}

function fetchSettingsOptionsEventsSuccessAction(
  settingsOptionsEvents: SettingsOptionsEvents
) {
  return {
    type: FETCH_SETTINGS_OPTIONS_EVENTS_SUCCESS,
    payload: { settingsOptionsEvents },
  };
}

function renewSettingsOptionsEventsAction() {
  return {
    type: RENEW_SETTINGS_OPTIONS_EVENTS,
  };
}

export const SettingsOptionsEventsActionCreators = {
  initSettingsOptionsEventsAction,
  fetchSettingsOptionsEventsAction,
  fetchSettingsOptionsEventsRequestAction,
  fetchSettingsOptionsEventsSuccessAction,
  renewSettingsOptionsEventsAction,
};

/* ---------------------------------------------------------------
 * Actions
 */

type SettingsOptionsEventsAction =
  | ReturnType<typeof initSettingsOptionsEventsAction>
  | ReturnType<typeof fetchSettingsOptionsEventsAction>
  | ReturnType<typeof fetchSettingsOptionsEventsRequestAction>
  | ReturnType<typeof fetchSettingsOptionsEventsSuccessAction>
  | ReturnType<typeof renewSettingsOptionsEventsAction>;

/* ---------------------------------------------------------------
 * State
 */

type SettingsOptionsEventsState = {
  settingsOptionsEvents: SettingsOptionsEvents | undefined;
  loadingState: LoadingState;
};

const initialState: SettingsOptionsEventsState = {
  settingsOptionsEvents: undefined,
  loadingState: 'prepare',
};

/* ---------------------------------------------------------------
 * Selector
 */

function settingsOptionsEventsSelector(state: {
  settingsOptionsEvents: SettingsOptionsEventsState;
}) {
  return state.settingsOptionsEvents.settingsOptionsEvents;
}

export function settingsOptionsEventsLoadingStateSelector(state: {
  settingsOptionsEvents: SettingsOptionsEventsState;
}) {
  return state.settingsOptionsEvents.loadingState;
}

export const settingsOptionsEventsSearchConditionSelector = createSelector(
  settingsOptionsEventsSelector,
  (settingsOptionsEvents) => settingsOptionsEvents?.searchCondition
);

/* ---------------------------------------------------------------
 * Reducer
 */

export function settingsOptionsEventsReducer(
  state = initialState,
  action: SettingsOptionsEventsAction
): SettingsOptionsEventsState {
  switch (action.type) {
    case FETCH_SETTINGS_OPTIONS_EVENTS_REQUEST:
      return {
        ...state,
        loadingState: 'loading',
      };
    case FETCH_SETTINGS_OPTIONS_EVENTS_SUCCESS:
      return {
        settingsOptionsEvents: action.payload.settingsOptionsEvents,
        loadingState: 'loaded',
      };
    case RENEW_SETTINGS_OPTIONS_EVENTS:
      return initialState;
    default:
      return state;
  }
}
