import { format } from 'date-fns';

import {
  DataModelTransition,
  modelTransitiveDateTypes,
} from '../domain/dataModelTransition';
import { ModelTransitionReportsSettingDateRangeParams } from '../domain/modelTransitionReportsSettingDateRangeParams';

import {
  makeDateForWeeklyFromDateRange,
  weeklyTransitiveDateRanges,
} from './transitiveDateRange';

// 機種推移のデフォルトデータ
export const dataModelTransitionDefaultValue = (): {
  setting: Partial<DataModelTransition['setting']>;
  data: DataModelTransition['data'];
  dateRangeParams: ModelTransitionReportsSettingDateRangeParams;
} => {
  const current = new Date();
  const dateType = modelTransitiveDateTypes[1]; // 週次
  const dateRange = weeklyTransitiveDateRanges[0]; // ７週

  // 指定された期間から日付を算出する
  const date = makeDateForWeeklyFromDateRange(dateRange, current);
  const startDate = format(date.startDate, 'yyyy-MM-dd');
  const endDate = format(date.endDate, 'yyyy-MM-dd');

  return {
    setting: {
      startDate,
      endDate,
      isFiltering: false,
      excludeToday: true,
    },
    data: {
      columnsForNonTransition: [],
      columnsForTransition: [],
      dates: [],
      rows: [],
    },
    dateRangeParams: {
      dateType,
      dateRange,
    },
  };
};
