import { LoadingState } from '../../domain/schemas';
import { SettingsOptionsUser } from '../../domain/settingsOptionsUser';

import { RootState } from '../../store';

// Action Types

const FETCH_SETTINGS_OPTIONS_USER = 'FETCH_SETTINGS_OPTIONS_USER' as const;
const FETCH_SETTINGS_OPTIONS_USER_REQUEST = 'FETCH_SETTINGS_OPTIONS_USER_REQUEST' as const;
const FETCH_SETTINGS_OPTIONS_USER_SUCCESS = 'FETCH_SETTINGS_OPTIONS_USER_SUCCESS' as const;
const RENEW_SETTINGS_OPTIONS_USER = 'RENEW_SETTINGS_OPTIONS_USER' as const;

export const SettingsOptionsUserActionTypes = {
  FETCH_SETTINGS_OPTIONS_USER,
  FETCH_SETTINGS_OPTIONS_USER_REQUEST,
  FETCH_SETTINGS_OPTIONS_USER_SUCCESS,
  RENEW_SETTINGS_OPTIONS_USER,
};

// Action Creators

function fetchSettingsOptionsUserAction() {
  return {
    type: FETCH_SETTINGS_OPTIONS_USER,
  };
}

function fetchSettingsOptionsUserRequestAction() {
  return {
    type: FETCH_SETTINGS_OPTIONS_USER_REQUEST,
  };
}

function fetchSettingsOptionsUserSuccessAction(
  settingsOptionsUser: SettingsOptionsUser
) {
  return {
    type: FETCH_SETTINGS_OPTIONS_USER_SUCCESS,
    payload: { settingsOptionsUser },
  };
}

function renewSettingsOptionsUserAction() {
  return {
    type: RENEW_SETTINGS_OPTIONS_USER,
  };
}

export const SettingsOptionsUserActionCreators = {
  fetchSettingsOptionsUserAction,
  fetchSettingsOptionsUserRequestAction,
  fetchSettingsOptionsUserSuccessAction,
  renewSettingsOptionsUserAction,
};

// Actions

type SettingsOptionsUserAction =
  | ReturnType<typeof fetchSettingsOptionsUserAction>
  | ReturnType<typeof fetchSettingsOptionsUserRequestAction>
  | ReturnType<typeof fetchSettingsOptionsUserSuccessAction>
  | ReturnType<typeof renewSettingsOptionsUserAction>;

// State

type SettingsOptionsUserState = {
  loadingState: LoadingState;
  settingsOptionsUser: SettingsOptionsUser | undefined;
};

const initialState: SettingsOptionsUserState = {
  loadingState: 'prepare',
  settingsOptionsUser: undefined,
};

// Selector

// settingsOptionsを取得する
export const settingsOptionsUserSelector = (state: RootState) => {
  return state.settingsOptionsUser.settingsOptionsUser;
};

// ローディングを取得
export const settingsOptionsUserLoadingStateSelector = (state: RootState) => {
  return state.settingsOptionsUser.loadingState;
};

// Reducer

export function settingsOptionsUserReducer(
  state = initialState,
  action: SettingsOptionsUserAction
): SettingsOptionsUserState {
  switch (action.type) {
    case FETCH_SETTINGS_OPTIONS_USER_REQUEST:
      return {
        ...state,
        loadingState: 'loading',
      };
    case FETCH_SETTINGS_OPTIONS_USER_SUCCESS:
      return {
        ...state,
        loadingState: 'loaded',
        settingsOptionsUser: action.payload.settingsOptionsUser,
      };
    case RENEW_SETTINGS_OPTIONS_USER:
      return initialState;
    default:
      return state;
  }
}
