import axios, { AxiosError } from 'axios';

import { Maintenance } from './maintenance';

export type ModelError = {
  statusCode: string;
  error: string;
  message: string;
  details?: string[];
  maintenance?: Maintenance;
};

export type ClarisApiError = AxiosError<ModelError>;

export const isClarisApiError = (error: unknown): error is ClarisApiError => {
  return axios.isAxiosError(error);
};
