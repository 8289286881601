import { AxiosResponse } from 'axios';
import { call, fork, put, takeEvery } from 'redux-saga/effects';

import { SettingsOptionsDataDeletion } from '../domain/settingsOptionsDataDeletion';

import {
  SettingsOptionsDataDeletionActionCreators,
  SettingsOptionsDataDeletionActionTypes,
} from '../redux/server/settingsOptionsDataDeletion';
import { Api, buildConfig } from '../utils/api';
import { handleErrorSaga } from './errorSaga';

export function* fetchSettingsOptionsDataDeletionSaga(api: Api) {
  try {
    yield put(
      SettingsOptionsDataDeletionActionCreators.fetchSettingsOptionsDataDeletionRequestAction()
    );
    const response: AxiosResponse<SettingsOptionsDataDeletion> = yield call(
      api.get,
      '/settings/options/dataDeletion',
      buildConfig()
    );

    yield put(
      SettingsOptionsDataDeletionActionCreators.fetchSettingsOptionsDataDeletionSuccessAction(
        response.data
      )
    );
  } catch (error: unknown) {
    yield put(
      SettingsOptionsDataDeletionActionCreators.renewSettingsOptionsDataDeletionAction()
    );
    yield fork(handleErrorSaga, error);
  }
}

function* handleFetchSettingsOptionsDataDeletionSaga(api: Api) {
  yield takeEvery(
    SettingsOptionsDataDeletionActionTypes.FETCH_SETTINGS_OPTIONS_DATADELETION,
    fetchSettingsOptionsDataDeletionSaga,
    api
  );
}

export function* settingsOptionsDataDeletionSagas(context: { api: Api }) {
  yield fork(handleFetchSettingsOptionsDataDeletionSaga, context.api);
}
