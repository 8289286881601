import { SettingsNationalHolidays } from '../../domain/SettingsNationalHolidays';
import { LoadingState } from '../../domain/schemas';

import { RootState } from '../../store';

// Action Types

const FETCH_SETTINGS_NATIONAL_HOLIDAYS = 'FETCH_SETTINGS_NATIONAL_HOLIDAYS' as const;
const FETCH_SETTINGS_NATIONAL_HOLIDAYS_REQUEST = 'FETCH_SETTINGS_NATIONAL_HOLIDAYS_REQUEST' as const;
const FETCH_SETTINGS_NATIONAL_HOLIDAYS_SUCCESS = 'FETCH_SETTINGS_NATIONAL_HOLIDAYS_SUCCESS' as const;
const RENEW_SETTINGS_NATIONAL_HOLIDAYS = 'RENEW_SETTINGS_NATIONAL_HOLIDAYS' as const;

export const SettingsNationalHolidaysActionTypes = {
  FETCH_SETTINGS_NATIONAL_HOLIDAYS,
  FETCH_SETTINGS_NATIONAL_HOLIDAYS_REQUEST,
  FETCH_SETTINGS_NATIONAL_HOLIDAYS_SUCCESS,
  RENEW_SETTINGS_NATIONAL_HOLIDAYS,
};

// Action Creators

function fetchSettingsNationalHolidaysAction() {
  return {
    type: FETCH_SETTINGS_NATIONAL_HOLIDAYS,
  };
}

function fetchSettingsNationalHolidaysRequestAction() {
  return {
    type: FETCH_SETTINGS_NATIONAL_HOLIDAYS_REQUEST,
  };
}

function fetchSettingsNationalHolidaysSuccessAction(
  settingsNationalHolidays: SettingsNationalHolidays
) {
  return {
    type: FETCH_SETTINGS_NATIONAL_HOLIDAYS_SUCCESS,
    payload: { settingsNationalHolidays },
  };
}

function renewSettingsNationalHolidaysAction() {
  return {
    type: RENEW_SETTINGS_NATIONAL_HOLIDAYS,
  };
}

export const SettingsNationalHolidaysActionCreators = {
  fetchSettingsNationalHolidaysAction,
  fetchSettingsNationalHolidaysRequestAction,
  fetchSettingsNationalHolidaysSuccessAction,
  renewSettingsNationalHolidaysAction,
};

// Actions

type SettingsNationalHolidaysAction =
  | ReturnType<typeof fetchSettingsNationalHolidaysAction>
  | ReturnType<typeof fetchSettingsNationalHolidaysRequestAction>
  | ReturnType<typeof fetchSettingsNationalHolidaysSuccessAction>
  | ReturnType<typeof renewSettingsNationalHolidaysAction>;

// State

type SettingsNationalHolidaysState = {
  loadingState: LoadingState;
  settingsNationalHolidays?: SettingsNationalHolidays;
};

const initialState: SettingsNationalHolidaysState = {
  loadingState: 'prepare',
  settingsNationalHolidays: undefined,
};

// Selector

/**
 *  settingsNationalHolidaysを全て取得する
 */
export const settingsNationalHolidaysSelector = (state: RootState) => {
  return state.settingsNationalHolidays.settingsNationalHolidays;
};

/**
 * 現在のローディング状態を取得する
 */
export const settingsNationalHolidaysLoadingStateSelector = (
  state: RootState
) => {
  return state.settingsNationalHolidays.loadingState;
};

// Reducer

export function settingsNationalHolidaysReducer(
  state = initialState,
  action: SettingsNationalHolidaysAction
): SettingsNationalHolidaysState {
  switch (action.type) {
    case FETCH_SETTINGS_NATIONAL_HOLIDAYS_REQUEST:
      return {
        ...state,
        loadingState: 'loading',
      };
    case FETCH_SETTINGS_NATIONAL_HOLIDAYS_SUCCESS:
      return {
        ...state,
        loadingState: 'loaded',
        settingsNationalHolidays: action.payload.settingsNationalHolidays,
      };
    case RENEW_SETTINGS_NATIONAL_HOLIDAYS:
      return initialState;
    default:
      return state;
  }
}
