import { LoadingState } from '../../domain/schemas';
import { SettingsOptionsKiLink } from '../../domain/settingsOptionsKiLink';

// Action Types

const FETCH_SETTINGS_OPTIONS_KI_LINK = 'FETCH_SETTINGS_OPTIONS_KI_LINK' as const;
const FETCH_SETTINGS_OPTIONS_KI_LINK_REQUEST = 'FETCH_SETTINGS_OPTIONS_KI_LINK_REQUEST' as const;
const FETCH_SETTINGS_OPTIONS_KI_LINK_SUCCESS = 'FETCH_SETTINGS_OPTIONS_KI_LINK_SUCCESS' as const;
const RENEW_SETTINGS_OPTIONS_KI_LINK = 'RENEW_SETTINGS_OPTIONS_KI_LINK' as const;

export const SettingsOptionsKiLinkActionTypes = {
  FETCH_SETTINGS_OPTIONS_KI_LINK,
  FETCH_SETTINGS_OPTIONS_KI_LINK_REQUEST,
  FETCH_SETTINGS_OPTIONS_KI_LINK_SUCCESS,
  RENEW_SETTINGS_OPTIONS_KI_LINK,
};

// Action Creators

function fetchSettingsOptionsKiLinkAction() {
  return {
    type: FETCH_SETTINGS_OPTIONS_KI_LINK,
  };
}

function fetchSettingsOptionsKiLinkRequestAction() {
  return {
    type: FETCH_SETTINGS_OPTIONS_KI_LINK_REQUEST,
  };
}

function fetchSettingsOptionsKiLinkSuccessAction(
  settingsOptionsKiLink: SettingsOptionsKiLink
) {
  return {
    type: FETCH_SETTINGS_OPTIONS_KI_LINK_SUCCESS,
    payload: { settingsOptionsKiLink },
  };
}

function renewSettingsOptionsKiLinkAction() {
  return {
    type: RENEW_SETTINGS_OPTIONS_KI_LINK,
  };
}

export const SettingsOptionsKiLinkActionCreators = {
  fetchSettingsOptionsKiLinkAction,
  fetchSettingsOptionsKiLinkRequestAction,
  fetchSettingsOptionsKiLinkSuccessAction,
  renewSettingsOptionsKiLinkAction,
};

// Actions

type FetchSettingsOptionsKiLinkAction = ReturnType<
  typeof fetchSettingsOptionsKiLinkAction
>;

type SettingsOptionsKiLinkAction =
  | FetchSettingsOptionsKiLinkAction
  | ReturnType<typeof fetchSettingsOptionsKiLinkRequestAction>
  | ReturnType<typeof fetchSettingsOptionsKiLinkSuccessAction>
  | ReturnType<typeof renewSettingsOptionsKiLinkAction>;

// State

type SettingsOptionsKiLinkState = {
  settingsOptionsKiLink?: SettingsOptionsKiLink;
  loadingState: LoadingState;
};

const initialState: SettingsOptionsKiLinkState = {
  settingsOptionsKiLink: undefined,
  loadingState: 'prepare',
};

// Selector

export function settingsOptionsKiLinkSelector(rootState: {
  settingsOptionsKiLink: SettingsOptionsKiLinkState;
}) {
  return rootState.settingsOptionsKiLink.settingsOptionsKiLink;
}

export function settingsOptionsKiLinkLoadingStateSelector(rootState: {
  settingsOptionsKiLink: SettingsOptionsKiLinkState;
}) {
  return rootState.settingsOptionsKiLink.loadingState;
}

// Reducer

export function settingsOptionsKiLinkReducer(
  state = initialState,
  action: SettingsOptionsKiLinkAction
): SettingsOptionsKiLinkState {
  switch (action.type) {
    case FETCH_SETTINGS_OPTIONS_KI_LINK_REQUEST:
      return {
        ...state,
        loadingState: 'loading',
      };
    case FETCH_SETTINGS_OPTIONS_KI_LINK_SUCCESS:
      return {
        ...state,
        settingsOptionsKiLink: action.payload.settingsOptionsKiLink,
        loadingState: 'loaded',
      };
    case RENEW_SETTINGS_OPTIONS_KI_LINK:
      return initialState;
    default:
      return state;
  }
}
