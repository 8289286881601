import {
  DataTerminalTransitionDataQueryParameter,
  DataTerminalTransitionParams,
} from '../domain/dataTerminalTransition';
import { ShuGroupList } from '../domain/schemas';
import { ChainStoreSearchCondition } from '../domain/settingsOptionsChainStore';
import { ShuOption } from '../domain/shu';

/**
 * 店舗レポート用：選択中の種別と種別グループをパラメーターの形式に変換する
 * 現在の種別グループ・種別の選択状態を元に検索条件形式に変換する
 * @param selectValues 単数または複数の種別グループ・種別選択状態
 * @returns 検索条件形式のデータ
 */
export const selectShus2HallReportSearchCondition = (
  selectValues: ShuOption[]
): {
  shuGroupIds: string[] | undefined;
  shuList: string[] | undefined;
} => {
  const shuGroupIds: string[] = [];
  const shuList: string[] = [];
  selectValues.forEach((selectValue) => {
    if (!selectValue) {
      return;
    }
    if (selectValue.type === 'shuGroup') {
      shuGroupIds.push(selectValue.code);
      return;
    }

    shuList.push(selectValue.code);
  });
  return {
    shuList: shuList.length > 0 ? shuList : undefined,
    shuGroupIds: shuGroupIds.length > 0 ? shuGroupIds : undefined,
  };
};

/**
 * 現在の種別グループ・種別の選択状態を元に検索条件形式に変換する
 * @param selectValue 種別グループ・種別選択状態
 * @returns 検索条件形式のデータ
 */
export const selectShu2SearchCondition = (
  selectValue: ShuOption
): {
  shuGroupIds: string[] | undefined;
  shuList: string[] | undefined;
} => {
  // 選択なしの場合
  if (selectValue.type === 'none') {
    return {
      shuList: undefined,
      shuGroupIds: undefined,
    };
  }
  // 種別グループの場合
  if (selectValue.type === 'shuGroup') {
    return {
      shuList: undefined,
      shuGroupIds: [selectValue.code],
    };
  }
  // 種別の場合
  return {
    shuList: [selectValue.code],
    shuGroupIds: undefined,
  };
};

/**
 * 検索条件項目を元に種別の選択項目を生成する
 * @param settingsOptions 検索条件項目
 * @returns 種別の選択項目（AutocompleteやSelectのoptionとして使用できる形式）
 */
export const getShuOption = (settingsOptions: {
  shuGroupList: ShuGroupList[];
  shuList: string[];
}) => {
  const shuGroup = settingsOptions.shuGroupList.map(
    convertShuGroupToShuOption
  ) as ShuOption[];
  const shuList = settingsOptions.shuList.map(
    convertShuToShuOption
  ) as ShuOption[];
  return [...shuGroup, ...shuList];
};

/**
 * 検索条件項目を元に種別グループの選択項目を生成する
 * @param settingsOptions 検索条件項目
 * @returns 種別の選択項目（AutocompleteやSelectのoptionとして使用できる形式）
 */
export const getShuGroupOption = (
  settingsOptions: ChainStoreSearchCondition
) => {
  const shuGroup = settingsOptions.shuGroupList.map<ShuOption>(
    convertShuGroupToShuOption
  );
  return shuGroup;
};

/**
 * 選択されている種別と選択可能な種別一覧の内容に応じて適切な種別を返す
 * @param shuList 選択可能な種別一覧
 * @param currentShu 現在選択されている種別
 */
export const checkCurrentShu = (
  shuList: ShuOption[],
  currentShu?: ShuOption
): ShuOption => {
  // currentShuがまだ選択されていない場合は種別一覧の先頭を返す
  if (currentShu === undefined) return shuList[0];

  // 種別一覧にcurrentShuが含まれない場合 → 種別一覧の先頭を返す
  return shuList.findIndex((shu) => shu.code === currentShu.code) < 0
    ? shuList[0]
    : currentShu;
};

/**
 * 期間推移用
 * QueryParameter から 種別一覧・種別グループID を抽出する
 * @param queryParameter クエリパラメータ
 * @returns 種別一覧・種別グループID の Code
 */
export const getShuCodeFromDataTerminalTransitionDataQueryParameter = (
  queryParameter?: DataTerminalTransitionDataQueryParameter
) => {
  if (queryParameter) {
    // shuList が指定されている場合
    if (queryParameter.shuList !== undefined) {
      return queryParameter.shuList[0];
    }
    // shuGroupIds が指定されている場合
    if (queryParameter.shuGroupIds !== undefined) {
      return queryParameter.shuGroupIds[0];
    }
    // どちらも存在しない場合は平均合計行扱いにする
    return '平均/合計行';
  }
  return undefined;
};

/**
 * 期間推移用
 * 検索パラメータ から QueryParameter を作成する
 * @param shuCode 種別一覧・種別グループID の Code
 * @param searchParams クエリパラメータ
 * @returns クエリパラメータ
 */
export const getQueryParameterFromDataTerminalTransitionParams = (
  shuCode: string,
  searchParams: DataTerminalTransitionParams
): DataTerminalTransitionDataQueryParameter | undefined => {
  // 平均合計行の場合はクエリパラメータを空で送る
  if (shuCode === '平均/合計行') {
    return {};
  }
  // 検索パラメータに shuList が存在、かつ shuCode が一致した場合は shuList とする
  if (
    searchParams.shuList !== undefined &&
    searchParams.shuList[0] === shuCode
  ) {
    return { shuList: searchParams.shuList };
  }
  // 検索パラメータに shuList が存在、かつ shuCode が一致した場合は shuList とする
  if (
    searchParams.shuGroupIds !== undefined &&
    searchParams.shuGroupIds[0] === shuCode
  ) {
    return { shuGroupIds: searchParams.shuGroupIds };
  }
  return undefined;
};

export const convertShuGroupToShuOption = (shuGroup: ShuGroupList) => {
  return {
    code: shuGroup.id,
    name: shuGroup.name,
    group: '種別グループ',
    type: 'shuGroup',
  };
};

export const convertShuToShuOption = (shuName: string) => {
  return {
    code: shuName,
    name: shuName,
    group: '種別',
    type: 'shuList',
  };
};

/**
 * データレスポンスに含まれるsettingとsettingsOptionsのsearchConditionから、ShuOptionを作成する
 */
export const convertShuOption = (
  {
    shuList,
    shuGroupIds,
  }: {
    shuGroupIds?: string[];
    shuList?: string[];
  },
  shuGroupList: ShuGroupList[]
): ShuOption | undefined => {
  const shuListItem = shuList?.at(0);
  if (shuListItem) {
    return {
      code: shuListItem,
      name: shuListItem,
      group: '種別',
      type: 'shuList',
    };
  }
  const shuGroupId = shuGroupIds?.at(0);
  if (shuGroupId) {
    const shuGroup = shuGroupList.find((group) => group.id === shuGroupId);
    if (shuGroup) {
      return {
        code: shuGroup.id,
        name: shuGroup.name,
        group: '種別グループ',
        type: 'shuGroup',
      };
    }
  }

  return undefined;
};

/**
 * currentShuがデータレスポンスに含まれるsettingに含まれる種別かどうかを判別する
 */
export const existShuOption = (
  {
    shuList,
    shuGroupIds,
  }: {
    shuGroupIds?: string[];
    shuList?: string[];
  },
  currentShu: ShuOption
): boolean => {
  const items = [...(shuList ?? []), ...(shuGroupIds ?? [])];

  return items.includes(currentShu.code);
};

/**
 * ShuOptionの配列の中に重複があればそれを除外する
 */
export const removeDuplicateShuOptions = (shuOptions: ShuOption[]) => {
  return shuOptions.filter(
    (option, index, self) =>
      self.findIndex((t) => t.code === option.code) === index
  );
};

/**
 * settingsOptionsから初期の種別選択状態を作成する
 */
export const makeInitialShu = (searchCondition: {
  shuGroupList?: ShuGroupList[];
  shuList?: string[];
}) => {
  const currentShuGroupId = searchCondition.shuGroupList?.at(0)?.id;
  const shuList = searchCondition.shuList?.at(0);

  if (currentShuGroupId != null) {
    return {
      shuGroupIds: [currentShuGroupId],
    };
  }

  if (shuList != null) {
    return { shuList: [shuList] };
  }

  return {};
};

/**
 * 検索する種別が存在しない場合、検索フォームで選択された種別の先頭を検索条件にする
 *
 * 種別スライダーが実装されているページで、検索フォームで選択された種別と種別スライダーで選択された種別が不一致になる場合を考慮するために必要です
 */
export const updateShuParamsIfNeeded = (
  params: {
    shuGroupIds?: string[];
    shuList?: string[];
  },
  selectedShu: ShuOption[]
) => {
  if (selectedShu.length === 0) {
    return params;
  }

  if (selectedShu.some((shuOption) => existShuOption(params, shuOption))) {
    return params;
  }

  const shuOption = selectedShu.at(0);
  if (shuOption == null) {
    throw new Error('shuOptionが存在しませんでした');
  }

  return selectShu2SearchCondition(shuOption);
};
