// Action Types
import { FavoritePageSetting } from '../../domain/favorites';

const SELECT_HOME_FAVORITE = 'SELECT_HOME_FAVORITE' as const;
const SELECT_PAGE_SETTING = 'SELECT_PAGE_SETTING' as const;
const SELECT_PAGE_SETTING_WITH_ORIGINAL_HASH = 'SELECT_PAGE_SETTING_WITH_ORIGINAL_HASH' as const;

export const HomeActionTypes = {
  SELECT_HOME_FAVORITE,
  SELECT_PAGE_SETTING,
  SELECT_PAGE_SETTING_WITH_ORIGINAL_HASH,
};

// Action Creators

export function selectHomeFavoriteAction(favoriteId: number, pageName: string) {
  return {
    type: SELECT_HOME_FAVORITE,
    payload: { favoriteId, pageName },
  };
}

export function selectPageSettingAction(payload: {
  pageName: string;
  pageSetting: FavoritePageSetting;
  createdAt: string;
}) {
  return {
    type: SELECT_PAGE_SETTING,
    payload,
  };
}

export function selectPageSettingWithOriginalHashAction(payload: {
  shortenedHash: string;
  pageName: string;
}) {
  return {
    type: SELECT_PAGE_SETTING_WITH_ORIGINAL_HASH,
    payload,
  };
}

export const HomeActionCreators = {
  selectHomeFavoriteAction,
  selectPageSettingAction,
  selectPageSettingWithOriginalHashAction,
};

// Actions

export type SelectHomeFavoriteAction = ReturnType<
  typeof selectHomeFavoriteAction
>;
export type SelectPageSettingAction = ReturnType<
  typeof selectPageSettingAction
>;
export type SelectPageSettingWithOriginalHashAction = ReturnType<
  typeof selectPageSettingWithOriginalHashAction
>;

type HomeAction =
  | SelectHomeFavoriteAction
  | SelectPageSettingAction
  | SelectPageSettingWithOriginalHashAction;

// State

type HomeState = unknown;

const initialState: HomeState = {};

// Reducer

export function homeReducer(
  state = initialState,
  action: HomeAction
): HomeState {
  switch (action.type) {
    default:
      return state;
  }
}
