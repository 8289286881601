import { format, isAfter } from 'date-fns';
import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';

import {
  DataModelTransition,
  DataModelTransitionParams,
} from '../domain/dataModelTransition';
import { DataModelTransitionGraphParams } from '../domain/dataModelTransitionGraph';
import { FavoriteItem } from '../domain/favorites';
import { MODEL_REPORT_MARKING_CONDITIONS } from '../domain/marking';
import { ModelTransitionFavorite } from '../domain/modelTransition/types';
import { ModelTransitionReportsSettingDateRangeParams } from '../domain/modelTransitionReportsSettingDateRangeParams';
import { Column, SearchCondition } from '../domain/schemas';
import { SettingsOptionsModelTransition } from '../domain/settingsOptionsModelTransition';
import { ShuOption } from '../domain/shu';

import { SIS_TYPE_UPDATE_DATES } from '../consistents';
import {
  DataModelTransitionActionCreators,
  dataModelTransitionColumnsForNonTransitionSelector,
  dataModelTransitionColumnsForTransitionSelector,
  dataModelTransitionSelector,
  dataModelTransitionSettingsSelector,
} from '../redux/server/dataModelTransition';
import {
  DataModelTransition2ndRowActionCreators,
  dataModelTransition2ndRowSettingsSelector,
} from '../redux/server/dataModelTransition2ndRow';
import {
  DataModelTransitionGraphActionCreators,
  dataModelTransitionGraphSelectedKiListSelector,
  dataModelTransitionGraphSettingSelector,
} from '../redux/server/dataModelTransitionGraph';
import {
  settingsOptionsModelTransitionFieldsSelector,
  settingsOptionsModelTransitionSearchConditionSelector,
} from '../redux/server/settingsOptionsModelTransition';
import { ShortenedUrlActionCreators } from '../redux/server/shortenedUrl';
import { ErrorActionCreators } from '../redux/ui/error';
import {
  ChangeModelTransitionReportsCurrentHallsAction,
  ChangeModelTransitionReportsCurrentShuAction,
  CreateModelTransitionReportsShortenedUrlAction,
  ModelTransitionReportsSettingActionCreators,
  ModelTransitionReportsSettingActionTypes,
  ModelTransitionReportsSettingState,
  SaveModelTransitionReportsFavorite,
  SearchModelTransitionReportsAction,
  SearchModelTransitionReportsDateRangeSlideAction,
  SearchModelTransitionReportsFieldsAction,
  SearchModelTransitionReportsMarkingAction,
  SearchModelTransitionReportsSortForHeaderAction,
  SearchModelTransitionReportsSortForTransitiveFieldAction,
  modelTransitionCurrentFieldSelector,
  modelTransitionReportsCheckedKiListTableSelector,
  modelTransitionReportsColumnsForNonTransitionOrderSelector,
  modelTransitionReportsColumnsForTransitionOrderSelector,
  modelTransitionReportsModelNameFilterSelector,
  modelTransitionReportsSearchConditionSelector,
  modelTransitionReportsSelectedCurrentShuSelector,
  modelTransitionReportsSelectedDateRangeParamsSelector,
  modelTransitionReportsSelectedFavoriteDataSelector,
  modelTransitionReportsSelectedFavoritePageSettingSelector,
  modelTransitionReportsSelectedFavoriteSelector,
  modelTransitionReportsSettingCurrentHallsSelector,
  modelTransitionReportsSettingIsHeatmapEnabledSelector,
  modelTransitionReportsSettingSelector,
  modelTransitionReportsTableFilterSelector,
  selectModelTransitionCheckedKiListTableAction,
  selectModelTransitionReportsColumnsForNonTransitionAction,
  selectModelTransitionReportsColumnsForTransitionAction,
  selectModelTransitionReportsModelNameFilterAction,
} from '../redux/ui/modelTransitionReportsSetting';
import { SettingsFavoritesActionCreators } from '../redux/ui/settingsFavorites';
import { compressToEncodedURIComponent } from '../utils/compressToEncodedURIComponent';
import { dataModelTransitionDefaultValue } from '../utils/dataModelTransitionDefaultValue';
import { isValidArea } from '../utils/isValidArea';
import {
  checkCurrentShu,
  getShuOption,
  selectShus2HallReportSearchCondition,
} from '../utils/shu';
import { calcStartEndDate } from '../utils/transitiveDateRange';
import { updateSisTypesV1 } from '../utils/updateSisTypes';

/**
 * 選択中の種別の情報を元に検索条件を加工する
 * @param currentShu 選択中の種別の情報
 * @param searchParams 期間推移の検索条件
 * @returns 加工された検索条件
 */
export const queryParameterToSearchParams = (
  currentShu: ShuOption,
  searchParams: DataModelTransitionParams
): DataModelTransitionParams => {
  const selectedShuParams = selectShus2HallReportSearchCondition([currentShu]);
  // 必要に応じて検索パラメータを加工する
  return {
    ...searchParams,
    // 選択中の種別の情報を加える
    ...selectedShuParams,
    // MEMO: ForSijiritu 系のパラメータは検索条件で指定されているものを分母として渡す
    ...{
      kiListForSijiritu: searchParams.kiList,
    },
  };
};

// 検索条件に非推移項目があるかどうか判定する
export const isExistNonTransitiveField = (
  searchCondition: DataModelTransitionParams
) => {
  return searchCondition.nonTransitiveFields !== undefined
    ? searchCondition.nonTransitiveFields.length === 0
      ? false
      : true
    : false;
};

/**
 * お気に入り選択時、データを取得する
 */
function* applyFavoriteByIdSaga() {
  const favoriteItem: FavoriteItem | undefined = yield select(
    modelTransitionReportsSelectedFavoriteDataSelector
  );
  // 現在選択中のお気に入りデータを取得
  const favorite: ModelTransitionFavorite | undefined = yield select(
    modelTransitionReportsSelectedFavoritePageSettingSelector
  );

  // デフォルトのお気に入りを選択した場合、リセットと同じ動作を行う
  if (favorite === undefined || favoriteItem === undefined) {
    yield put(
      ModelTransitionReportsSettingActionCreators.searchResetModelTransitionReportsAction()
    );
    return;
  }

  const lastUpdateAt = favoriteItem.updatedAt ?? favoriteItem.createdAt;
  if (lastUpdateAt == null) {
    throw new Error('お気に入りの作成日が存在しません');
  }

  // 機種別推移のデータを初期化する
  yield fork(initModelTransitionReportsDataSaga);

  yield fork(applyFavoriteSaga, favorite, lastUpdateAt);
}

/**
 * ModelTransitionFavoriteの内容を実際に反映させる
 */
export function* applyFavoriteSaga(
  favorite: ModelTransitionFavorite,
  lastUpdatedAt: string
) {
  // 選択中の種別に反映
  yield put(
    ModelTransitionReportsSettingActionCreators.selectModelTransitionReportsCurrentShuAction(
      favorite.currentShu
    )
  );
  // 選択中の種別一覧に反映
  yield put(
    ModelTransitionReportsSettingActionCreators.selectModelTransitionReportsShuAction(
      favorite.selectedShu
    )
  );
  // 選択中の機種リストに反映
  yield put(
    ModelTransitionReportsSettingActionCreators.selectModelTransitionReportsKiListAction(
      favorite.kiList
    )
  );
  // 選択中のSISリストに反映
  yield put(
    ModelTransitionReportsSettingActionCreators.selectModelTransitionReportsSisListAction(
      favorite.selectedSis
    )
  );

  yield put(
    ModelTransitionReportsSettingActionCreators.selectModelTransitionCurrentFieldAction(
      favorite.selectedCurrentFieldCode ?? 'all'
    )
  );

  // 期間を元にstartDateとendDateを算出
  let date: ReturnType<typeof calcStartEndDate> | undefined = undefined;

  const dateRange = favorite.selectedDateRangeParams.dateRange;
  // 期間指定がカスタムの場合は現在の検索パラメータの値を送る
  if (dateRange !== 'カスタム') {
    // 指定された期間から日付を算出する
    date = calcStartEndDate(
      favorite.selectedDateRangeParams.dateType,
      favorite.selectedDateRangeParams.dateRange
    );
  }

  // 期間を再算出して上書きする
  // dateが算出されていない場合はカスタムと判断し、保持されている検索パラメータの値をそのまま送る
  const startDate =
    date !== undefined
      ? format(date.startDate, 'yyyy-MM-dd')
      : favorite.searchCondition.startDate;
  const endDate =
    date !== undefined
      ? format(date.endDate, 'yyyy-MM-dd')
      : favorite.searchCondition.endDate;

  const sisTypes = isAfter(
    new Date(lastUpdatedAt),
    new Date(SIS_TYPE_UPDATE_DATES.v1)
  )
    ? favorite.searchCondition.sisTypes
    : updateSisTypesV1(favorite.searchCondition.sisTypes);

  if (!isValidArea(favorite.searchCondition.areas)) {
    yield put(
      ErrorActionCreators.setError(
        `お気に入りの検索条件に存在しないエリアが指定されています。\n正しいエリアを選択して検索を再度行い、お気に入りに保存してください。`
      )
    );
  }

  // 選択中の検索条件に反映
  yield put(
    ModelTransitionReportsSettingActionCreators.selectModelTransitionReportsSearchConditionAction(
      {
        ...favorite.searchCondition,
        startDate,
        endDate,
        ...(sisTypes ? { sisTypes } : {}),
      }
    )
  );
  // dateType・dateRange（選択中の日付タイプ・期間）を更新する
  yield put(
    ModelTransitionReportsSettingActionCreators.selectModelTransitionReportsDateRangeParamsAction(
      favorite.selectedDateRangeParams.dateType,
      favorite.selectedDateRangeParams.dateRange
    )
  );

  // 機種別推移テーブルを取得
  if (favorite.dataModelTransition != null) {
    yield put(
      DataModelTransitionActionCreators.fetchDataModelTransitionAction(
        favorite.currentShu,
        {
          ...dataModelTransitionDefaultValue().setting,
          ...favorite.dataModelTransition,
          startDate,
          endDate,
          ...(sisTypes ? { sisTypes } : {}),
        }
      )
    );

    // テーブル列の推移項目並び順を変更
    if (favorite.columnForTransitionOrder) {
      yield put(
        ModelTransitionReportsSettingActionCreators.selectModelTransitionReportsColumnsForTransitionAction(
          favorite.columnForTransitionOrder
        )
      );
    }

    // テーブル列の非推移項目並び順を変更
    if (favorite.columnForNonTransitionOrder) {
      yield put(
        ModelTransitionReportsSettingActionCreators.selectModelTransitionReportsColumnsForNonTransitionAction(
          favorite.columnForNonTransitionOrder
        )
      );
    }

    // 機種名フィルタを変更
    yield put(
      selectModelTransitionReportsModelNameFilterAction(
        favorite.modelNameFilter
      )
    );

    // 店舗ドロップダウンを変更
    yield put(
      ModelTransitionReportsSettingActionCreators.selectModelTransitionReportsCurrentHallsAction(
        favorite.currentHalls ?? []
      )
    );

    // ヒートマップ表示の有効無効
    yield put(
      ModelTransitionReportsSettingActionCreators.selectModelTransitionReportsIsHeatmapEnabledAction(
        favorite.isHeatmapEnabled ?? false
      )
    );
  }

  // 検索条件 → エフェクトクリエイター（データ取得）に変換
  const dataModelTransition2ndRow = favorite.dataModelTransition2ndRow;
  if (dataModelTransition2ndRow !== undefined) {
    // Fetchする展開行一覧を生成
    const tasks2ndRow = Object.keys(dataModelTransition2ndRow).map((kiCode) => {
      return put(
        DataModelTransition2ndRowActionCreators.fetchDataModelTransition2ndRowAction(
          kiCode,
          {
            ...dataModelTransition2ndRow[kiCode],
            startDate,
            endDate,
            // 店舗フィルタで選択中の店舗のみの値を取得する
            halls:
              favorite.currentHalls ??
              favorite.dataModelTransition?.halls ??
              [],
          }
        )
      );
    });
    // 全件取得
    yield all(tasks2ndRow);
  }

  // テーブルフィルター項目を取得する
  // 項目一覧を反映する
  yield put(
    ModelTransitionReportsSettingActionCreators.applyModelTransitionReportsTableFilterAction(
      favorite.tableFilterItems ?? []
    )
  );

  // テーブルでチェックされた機種を反映
  yield put(
    ModelTransitionReportsSettingActionCreators.selectModelTransitionCheckedKiListTableAction(
      favorite.checkedKiListTable || {}
    )
  );

  // 推移グラフ（機種比較）を取得
  if (favorite.dataModelTransitionGraph !== undefined) {
    yield put(
      DataModelTransitionGraphActionCreators.fetchDataModelTransitionGraphAction(
        {
          ...favorite.dataModelTransitionGraph.setting,
          startDate,
          endDate,
        }
      )
    );
    // グラフの機種選択を適用
    yield put(
      DataModelTransitionGraphActionCreators.changeDataModelTransitionGraphKiAction(
        favorite.dataModelTransitionGraph.selectKi
      )
    );
  } else {
    yield put(
      DataModelTransitionGraphActionCreators.renewDataModelTransitionGraphAction()
    );
  }
}

/**
 * お気に入りに保存するデータの生成
 */
function* setPageSetting() {
  // 現在の機種別推移の設定すべて
  const pageSetting: ModelTransitionReportsSettingState = yield select(
    modelTransitionReportsSettingSelector
  );

  // 現在の機種別推移テーブルの検索条件
  const dataModelTransition:
    | DataModelTransitionParams
    | undefined = yield select(dataModelTransitionSettingsSelector);
  // 現在の機種別推移テーブルの展開行の検索条件
  const dataModelTransition2ndRow:
    | {
        [key: string]: DataModelTransitionParams;
      }
    | undefined = yield select(dataModelTransition2ndRowSettingsSelector);
  // 種別選択前の時は動作しない
  if (pageSetting.currentShu === undefined) return;

  // 機種名フィルタを取得
  const modelNameFilter: string = yield select(
    modelTransitionReportsModelNameFilterSelector
  );

  // テーブルフィルターで選択中の項目
  const tableFilterSelectedItems: string[] | undefined = yield select(
    modelTransitionReportsTableFilterSelector
  );

  // テーブルでチェックされた機種の種別ごとのテーブルを取得
  const checkedKiListTable: { [shuCode: string]: string[] } = yield select(
    modelTransitionReportsCheckedKiListTableSelector
  );

  // 現在の推移グラフ（機種比較）の検索条件
  const dataModelTransitionGraphParams:
    | DataModelTransitionGraphParams
    | undefined = yield select(dataModelTransitionGraphSettingSelector);
  // 現在の推移グラフ（機種比較）の選択機種
  const selectKi: string[] = yield select(
    dataModelTransitionGraphSelectedKiListSelector
  );

  // 店舗フィルタで選択中の項目
  const currentHalls: string[] = yield select(
    modelTransitionReportsSettingCurrentHallsSelector
  );

  // ヒートマップの有効無効
  const isHeatmapEnabled: boolean = yield select(
    modelTransitionReportsSettingIsHeatmapEnabledSelector
  );

  const currentField: string = yield select(
    modelTransitionCurrentFieldSelector
  );

  const result: FavoriteItem['pageSetting'] = {
    modelTransition: {
      searchCondition: pageSetting.searchCondition,
      selectedShu: pageSetting.selectedShu,
      selectedSis: pageSetting.selectedSis,
      currentShu: pageSetting.currentShu,
      selectedDateRangeParams: pageSetting.selectedDateRangeParams,
      ...(dataModelTransition ? { dataModelTransition } : {}),
      ...(dataModelTransition2ndRow ? { dataModelTransition2ndRow } : {}),
      kiList: pageSetting.kiList ?? [],
      columnForTransitionOrder: pageSetting?.columnsForTransitionOrder ?? [],
      columnForNonTransitionOrder:
        pageSetting?.columnsForNonTransitionOrder ?? [],
      modelNameFilter,
      tableFilterItems: tableFilterSelectedItems || [],
      checkedKiListTable: checkedKiListTable || {},
      ...(dataModelTransitionGraphParams
        ? {
            dataModelTransitionGraph: {
              setting: dataModelTransitionGraphParams,
              selectKi,
            },
          }
        : {}),
      currentHalls,
      isHeatmapEnabled,
      selectedCurrentFieldCode: currentField,
    },
  };

  return result;
}

/**
 * お気に入り上書き保存時の処理
 */
function* saveFavoriteSaga(action: SaveModelTransitionReportsFavorite) {
  // 選択中のお気に入りID
  const selectedFavoriteId: number | undefined = yield select(
    modelTransitionReportsSelectedFavoriteSelector
  );
  // 選択中のお気に入り
  const selectedFavorite: FavoriteItem = yield select(
    modelTransitionReportsSelectedFavoriteDataSelector
  );
  const pageSetting: FavoriteItem['pageSetting'] = yield call(setPageSetting);

  // デフォルトの時は動作しない
  if (selectedFavoriteId === undefined) return;

  // お気に入りのデータを生成
  if (action.payload.mode === 'memo') {
    // お気に入りを上書き
    yield put(
      SettingsFavoritesActionCreators.patchSettingsFavoritesAction(
        selectedFavoriteId,
        {
          ...selectedFavorite,
          memo: action.payload.memo,
        }
      )
    );
  } else {
    // お気に入りを上書き
    yield put(
      SettingsFavoritesActionCreators.patchSettingsFavoritesAction(
        selectedFavoriteId,
        {
          name: action.payload.name,
          isShared: action.payload.isShared,
          pageSetting,
          memo: action.payload.memo,
          privatelySharedUsers: action.payload.sharedUser,
        }
      )
    );
  }
}

/**
 * お気に入り新規保存時の処理
 */
function* saveAsFavoriteSaga(action: SaveModelTransitionReportsFavorite) {
  const pageSetting: FavoriteItem['pageSetting'] = yield call(setPageSetting);

  // お気に入りを新規登録
  yield put(
    SettingsFavoritesActionCreators.postSettingsFavoritesAction([
      {
        name: action.payload.name,
        isShared: action.payload.isShared,
        pageName: '機種別推移',
        pageSetting,
        memo: action.payload.memo,
        privatelySharedUsers: action.payload.sharedUser,
      },
    ])
  );
}

/**
 * 機種別推移の全てのデータを破棄する
 */
function* initModelTransitionReportsDataSaga() {
  // 展開行
  yield put(
    DataModelTransition2ndRowActionCreators.renewDataModelTransition2ndRowAction()
  );
}

/**
 * 検索ボタン押下時に行う処理
 */
function* searchActionSaga(action: SearchModelTransitionReportsAction) {
  // 機種別推移のデータを初期化する
  yield fork(initModelTransitionReportsDataSaga);

  // 現在の検索条件項目を取得
  const settingsOptions: SearchCondition = yield select(
    settingsOptionsModelTransitionSearchConditionSelector
  );

  // 現在選択中の種別
  const currentShu: ShuOption | undefined = yield select(
    modelTransitionReportsSelectedCurrentShuSelector
  );

  // すべての種別・種別グループ一覧
  const allShuOption: ShuOption[] = getShuOption(settingsOptions);
  // 現在選択可能な種別一覧
  const shuList =
    action.payload.selectedShu.length > 0
      ? action.payload.selectedShu
      : allShuOption;
  // currentShuが選択可能な種別一覧に含まれていない場合先頭を選択
  const checkedCurrentShu = checkCurrentShu(shuList, currentShu);

  // currentShuがselectedShu（選択可能な種別一覧）にない場合適切な種別を選択する
  if (
    currentShu !== undefined &&
    currentShu.type !== 'none' &&
    checkedCurrentShu !== undefined &&
    checkedCurrentShu.code !== currentShu.code
  ) {
    // currentShu（選択中の種別）を更新する
    yield put(
      ModelTransitionReportsSettingActionCreators.selectModelTransitionReportsCurrentShuAction(
        checkedCurrentShu
      )
    );
  }

  // selectedShu（選択中の種別一覧）を更新する
  yield put(
    ModelTransitionReportsSettingActionCreators.selectModelTransitionReportsShuAction(
      action.payload.selectedShu
    )
  );

  // 必要に応じて検索パラメータを加工する
  const modifyParams = queryParameterToSearchParams(
    checkedCurrentShu,
    action.payload.params
  );

  const dateType = action.payload.selectedDateType;
  const dateRange = action.payload.selectedDateRange;

  // dateType・dateRange（選択中の日付タイプ・期間）を更新する
  yield put(
    ModelTransitionReportsSettingActionCreators.selectModelTransitionReportsDateRangeParamsAction(
      dateType,
      dateRange
    )
  );
  // searchCondition(選択中の検索条件）を更新する
  yield put(
    ModelTransitionReportsSettingActionCreators.selectModelTransitionReportsSearchConditionAction(
      modifyParams
    )
  );

  // フィルターされた機種リストの情報があれば削除
  yield put(
    ModelTransitionReportsSettingActionCreators.selectModelTransitionReportsKiListAction(
      []
    )
  );

  // 選択中のSISリストに反映
  yield put(
    ModelTransitionReportsSettingActionCreators.selectModelTransitionReportsSisListAction(
      []
    )
  );

  // データ取得
  yield put(
    DataModelTransitionActionCreators.fetchDataModelTransitionAction(
      checkedCurrentShu,
      modifyParams
    )
  );
}

/**
 * 検索ボタン押下時、新しい検索条件でデータを取得する
 */
function* handleSearchSaga() {
  yield takeEvery(
    ModelTransitionReportsSettingActionTypes.SEARCH_MODEL_TRANSITION_REPORTS,
    searchActionSaga
  );
}

/**
 * リセットボタン押下時、デフォルトの検索条件または選択中のお気に入りでデータを取得する
 */
function* resetSearchActionSaga() {
  // 現在選択中のお気に入りを取得する
  const favoriteId: number | undefined = yield select(
    modelTransitionReportsSelectedFavoriteSelector
  );

  // お気に入りが選択されている場合はお気に入り適用
  if (favoriteId !== undefined) {
    yield put(
      ModelTransitionReportsSettingActionCreators.changeModelTransitionReportsFavoriteAction(
        favoriteId
      )
    );
    return;
  }

  // 機種別推移のデータを初期化する
  yield fork(initModelTransitionReportsDataSaga);

  // デフォルトが選択されている場合は検索条件を初期化
  yield put(
    ModelTransitionReportsSettingActionCreators.resetModelTransitionReportsSearchConditionAction()
  );
  // 選択中の種別をリセットする
  yield put(
    ModelTransitionReportsSettingActionCreators.resetModelTransitionReportsShuAction()
  );
  // 選択中の期間をリセットする
  yield put(
    ModelTransitionReportsSettingActionCreators.resetModelTransitionReportsDateRangeParamsAction()
  );
  // フィルターされた機種リストの情報があれば削除
  yield put(
    ModelTransitionReportsSettingActionCreators.selectModelTransitionReportsKiListAction(
      []
    )
  );
  // フィルターされた機種リストの情報があれば削除
  yield put(
    ModelTransitionReportsSettingActionCreators.selectModelTransitionReportsSisListAction(
      []
    )
  );
  yield put(
    ModelTransitionReportsSettingActionCreators.selectModelTransitionCurrentFieldAction(
      'all'
    )
  );

  // 現在の検索条件を取得
  const searchCondition: DataModelTransitionParams = yield select(
    modelTransitionReportsSearchConditionSelector
  );
  // 現在の種別を取得
  const currentShu: ShuOption = yield select(
    modelTransitionReportsSelectedCurrentShuSelector
  );
  // 現在の期間を取得
  const dateRangeParams: ModelTransitionReportsSettingDateRangeParams = yield select(
    modelTransitionReportsSelectedDateRangeParamsSelector
  );
  // 表示項目の選択肢データを取得
  const settingsOptions: SettingsOptionsModelTransition['fields'] = yield select(
    settingsOptionsModelTransitionFieldsSelector
  );

  // 必要に応じて検索パラメータを加工する
  let modifyParams = searchCondition;

  // 期間指定がカスタムの場合は現在の検索パラメータの値を送る
  if (dateRangeParams.dateRange !== 'カスタム') {
    // 指定された期間から日付を算出する
    const date = calcStartEndDate(
      dateRangeParams.dateType,
      dateRangeParams.dateRange
    );

    const startDate = format(date.startDate, 'yyyy-MM-dd');
    const endDate = format(date.endDate, 'yyyy-MM-dd');
    modifyParams = { ...modifyParams, startDate, endDate };
  }

  // 非推移項目のデフォルト項目を設定
  if (!isExistNonTransitiveField(searchCondition)) {
    modifyParams = {
      ...modifyParams,
      nonTransitiveFields: settingsOptions.nonTransition
        .filter((field) => field.isDefault)
        .map((field) => field.code),
    };
  }

  // セルの並び順をリセットしてデフォルト順にする
  yield put(selectModelTransitionReportsColumnsForTransitionAction([]));
  yield put(selectModelTransitionReportsColumnsForNonTransitionAction([]));

  // 検索条件を更新
  yield put(
    ModelTransitionReportsSettingActionCreators.selectModelTransitionReportsSearchConditionAction(
      modifyParams
    )
  );

  // 機種名フィルタをクリア
  yield put(selectModelTransitionReportsModelNameFilterAction(''));

  // チェックされた機種をクリア
  yield put(selectModelTransitionCheckedKiListTableAction({}));

  // データ取得
  yield put(
    DataModelTransitionActionCreators.fetchDataModelTransitionAction(
      currentShu,
      modifyParams
    )
  );
}

/**
 * リセットボタン押下時機種別推移の全てのデータを破棄する
 */
function* handleResetSaga() {
  yield takeEvery(
    ModelTransitionReportsSettingActionTypes.SEARCH_RESET_MODEL_TRANSITION_REPORTS,
    resetSearchActionSaga
  );
}

/**
 * 変更後の種別でデータを取得する
 */
function* changeCurrentShuSaga(
  action: ChangeModelTransitionReportsCurrentShuAction
) {
  // 種別が選択されていない場合は処理を中断
  if (action.payload.shu === undefined) return;

  // 機種別推移のデータを初期化する
  yield fork(initModelTransitionReportsDataSaga);

  // 現在の検索条件を取得
  const searchCondition: DataModelTransitionParams = yield select(
    modelTransitionReportsSearchConditionSelector
  );

  // フィルターされた機種リストの情報があれば削除
  yield put(
    ModelTransitionReportsSettingActionCreators.selectModelTransitionReportsKiListAction(
      []
    )
  ); // フィルターされた機種リストの情報があれば削除
  yield put(
    ModelTransitionReportsSettingActionCreators.selectModelTransitionReportsSisListAction(
      []
    )
  );

  // 表示項目の選択肢データを取得
  const settingsOptions: SettingsOptionsModelTransition['fields'] = yield select(
    settingsOptionsModelTransitionFieldsSelector
  );

  // 新しい検索条件
  const newSearchCondition = {
    ...searchCondition,
    // 初回取得時で週次、月次以外が選択されている場合はデフォルトでDK-SISを有効にする
    ...(searchCondition.dateType !== 'weekly'
      ? { sisFieldTypes: ['result'] }
      : {}),
    // 非推移項目のデフォルト項目を設定
    ...(!isExistNonTransitiveField(searchCondition)
      ? {
          nonTransitiveFields: settingsOptions.nonTransition
            .filter((field) => field.isDefault)
            .map((field) => field.code),
        }
      : {}),
  };

  // 検索条件を更新
  yield put(
    ModelTransitionReportsSettingActionCreators.selectModelTransitionReportsSearchConditionAction(
      newSearchCondition
    )
  );

  // 店舗フィルタで選択中の項目
  const currentHalls: string[] = yield select(
    modelTransitionReportsSettingCurrentHallsSelector
  );

  // 変更後の種別でデータを取得する
  yield put(
    DataModelTransitionActionCreators.fetchDataModelTransitionAction(
      action.payload.shu,
      {
        ...newSearchCondition,
        halls: currentHalls.length === 0 ? searchCondition.halls : currentHalls,
      }
    )
  );
}

/**
 * 選択中の種別変更時、データを取得する
 */
function* handleChangeCurrentShuSaga() {
  yield takeEvery(
    ModelTransitionReportsSettingActionTypes.CHANGE_MODEL_TRANSITION_REPORTS_CURRENT_SHU,
    changeCurrentShuSaga
  );
}

/**
 * 表示項目変更時データを取得する
 */
function* searchFieldsSaga(action: SearchModelTransitionReportsFieldsAction) {
  // 現在の機種別推移のデータを取得
  const modelTransition: DataModelTransition = yield select(
    dataModelTransitionSelector
  );
  // 非推移項目が空のときは、メインフィールドのみを指定する
  const nonTransitiveFields =
    action.payload.nonTransitiveFields.length === 0
      ? [modelTransition.setting.mainField]
      : action.payload.nonTransitiveFields;
  const params = {
    nonTransitiveFields,
    transitiveFields: action.payload.transitiveFields,
  };

  // 項目絞込で選択されている項目
  const selectedCurrentFieldCode: string = yield select(
    modelTransitionCurrentFieldSelector
  );

  // 表示項目に、項目絞込で選択されている項目が存在するか確認
  const hasCurrentFieldCode = params.transitiveFields.some(
    (field) => field === selectedCurrentFieldCode
  );

  if (!hasCurrentFieldCode) {
    yield put(
      ModelTransitionReportsSettingActionCreators.selectModelTransitionCurrentFieldAction(
        'all'
      )
    );
  }

  yield fork(searchSearchConditonSaga, params);
}

/**
 * テーブル固有の検索条件を変更してデータを取得する
 * @param params 変更後の検索条件
 */
function* searchSearchConditonSaga(params: DataModelTransitionParams) {
  // 機種別推移のデータを初期化する
  yield fork(initModelTransitionReportsDataSaga);

  // 現在の検索条件を取得
  const searchCondition: DataModelTransitionParams = yield select(
    modelTransitionReportsSearchConditionSelector
  );
  // 現在の種別を取得
  const currentShu: ShuOption = yield select(
    modelTransitionReportsSelectedCurrentShuSelector
  );

  // 店舗フィルタで選択中の項目
  const currentHalls: string[] = yield select(
    modelTransitionReportsSettingCurrentHallsSelector
  );

  // 新しい検索条件
  const newSearchCondition = {
    ...searchCondition,
    ...params,
    // 週次が DK-SIS に対応していないので、指定されていた場合は DK-SIS を OFF にする
    ...(searchCondition.dateType === 'weekly'
      ? { sisFieldTypes: undefined }
      : {}),
  };
  // 検索条件を更新
  yield put(
    ModelTransitionReportsSettingActionCreators.selectModelTransitionReportsSearchConditionAction(
      newSearchCondition
    )
  );

  // データ取得
  yield put(
    DataModelTransitionActionCreators.fetchDataModelTransitionAction(
      currentShu,
      {
        ...newSearchCondition,
        halls: currentHalls.length === 0 ? searchCondition.halls : currentHalls,
      }
    )
  );

  const graphSearchCondition:
    | DataModelTransitionGraphParams
    | undefined = yield select(dataModelTransitionGraphSettingSelector);

  // グラフデータ取得
  if (graphSearchCondition) {
    yield put(
      DataModelTransitionGraphActionCreators.fetchDataModelTransitionGraphAction(
        {
          ...graphSearchCondition,
          startDate: newSearchCondition.startDate,
          endDate: newSearchCondition.endDate,
        }
      )
    );
  }
}

/**
 * 非推移項目ソート実行時データを取得する
 */
function* searchSortForHeaderSaga(
  action: SearchModelTransitionReportsSortForHeaderAction
) {
  const params = {
    sortForHeader: action.payload.sort,
    order: action.payload.order,
  };
  yield fork(searchSearchConditonSaga, params);
}

/**
 * 推移項目ソート実行時データを取得する
 */
function* searchSortForTransitiveFieldSaga(
  action: SearchModelTransitionReportsSortForTransitiveFieldAction
) {
  // 現在の機種別推移のデータを取得
  const modelTransition: DataModelTransition = yield select(
    dataModelTransitionSelector
  );
  // 推移項目であるsortForTransitiveFieldを選択したとき、
  // sortForHeaderが日付項目でない場合にはデフォルト値として初日/週に変更する
  const sortForHeader = !modelTransition.data.dates
    .map((date) => date.code)
    .includes(modelTransition.setting.sortForHeader)
    ? modelTransition.data.dates.at(0)?.code
    : modelTransition.setting.sortForHeader;

  const params = {
    sortForHeader,
    sortForTransitiveField: action.payload.sort,
    order: action.payload.order,
  };

  yield fork(searchSearchConditonSaga, params);
}

/**
 * マーキング変更時データを取得する
 */
function* searchMarkingSaga(action: SearchModelTransitionReportsMarkingAction) {
  yield fork(searchSearchConditonSaga, {
    marking:
      action.payload.markingOption.code ===
      MODEL_REPORT_MARKING_CONDITIONS.at(0)?.code
        ? undefined
        : action.payload.markingOption.code,
    isFiltering: action.payload.isFiltering,
  });
}

/**
 * テーブル固有の検索条件変更検知時の処理
 */
function* handleTableSearchSaga() {
  // 非推移項目ソート実行時
  yield takeEvery(
    ModelTransitionReportsSettingActionTypes.SEARCH_MODEL_TRANSITION_REPORTS_SORT_FOR_HEADER,
    searchSortForHeaderSaga
  );

  // 推移項目ソート実行時
  yield takeEvery(
    ModelTransitionReportsSettingActionTypes.SEARCH_MODEL_TRANSITION_REPORTS_SORT_FOR_TRANSITIVE_FIELD,
    searchSortForTransitiveFieldSaga
  );

  // マーキング実行時
  yield takeEvery(
    ModelTransitionReportsSettingActionTypes.SEARCH_MODEL_TRANSITION_REPORTS_MARKING,
    searchMarkingSaga
  );

  // 表示項目変更時
  yield takeEvery(
    ModelTransitionReportsSettingActionTypes.SEARCH_MODEL_TRANSITION_REPORTS_FIELDS,
    searchFieldsSaga
  );
}

/**
 * 表示項目の並び順を変更する
 */
export function* changeColumnsOrderSaga() {
  const fieldsForTransition: Column[] = yield select(
    dataModelTransitionColumnsForTransitionSelector
  );
  const fieldsForNonTransition: Column[] = yield select(
    dataModelTransitionColumnsForNonTransitionSelector
  );

  // テーブル列の並び順
  const columnsForTransitionOrder: string[] = yield select(
    modelTransitionReportsColumnsForTransitionOrderSelector
  );
  const columnsForNonTransitionOrder: string[] = yield select(
    modelTransitionReportsColumnsForNonTransitionOrderSelector
  );

  // MEMO: 推移項目の DK-SIS 項目の存在チェック
  const existTransitionDkSis = columnsForTransitionOrder
    .join('_')
    .indexOf('_sis');

  const orderedForTransitionCode = [...fieldsForTransition]
    .sort((a, b) => {
      // DK-SIS項目を新規で追加する場合は強制で末尾に配置する
      if (existTransitionDkSis === -1 && (b.isSisField || a.isSisField)) {
        return 1;
      }
      return columnsForTransitionOrder.indexOf(b.code) >
        columnsForTransitionOrder.indexOf(a.code)
        ? -1
        : 1;
    })
    .map((column) => column.code);

  // MEMO: 非推移項目の DK-SIS 項目の存在チェック
  const existNonTransitionDkSis = columnsForNonTransitionOrder
    .join('_')
    .indexOf('_sis');

  const orderedForNonTransitionCode = [...fieldsForNonTransition]
    .sort((a, b) => {
      // 機種名は常に先頭
      if (b.code === 'kiTushoMei') {
        return 1;
      }
      // DK-SIS項目を新規で追加する場合は強制で末尾に配置する
      if (existNonTransitionDkSis === -1 && (b.isSisField || a.isSisField)) {
        return 1;
      }
      return columnsForNonTransitionOrder.indexOf(b.code) >
        columnsForNonTransitionOrder.indexOf(a.code)
        ? -1
        : 1;
    })
    .map((column) => column.code);

  yield put(
    selectModelTransitionReportsColumnsForTransitionAction(
      orderedForTransitionCode
    )
  );

  yield put(
    selectModelTransitionReportsColumnsForNonTransitionAction(
      orderedForNonTransitionCode
    )
  );
}

/**
 * お気に入り関連のActionがDispatchされるのを監視
 */
function* handleFavoriteSaga() {
  // お気に入り選択
  yield takeEvery(
    ModelTransitionReportsSettingActionTypes.CHANGE_MODEL_TRANSITION_REPORTS_FAVORITE,
    applyFavoriteByIdSaga
  );
  // お気に入り上書き保存
  yield takeEvery(
    ModelTransitionReportsSettingActionTypes.SAVE_MODEL_TRANSITION_REPORTS_FAVORITE,
    saveFavoriteSaga
  );
  // お気に入り新規保存
  yield takeEvery(
    ModelTransitionReportsSettingActionTypes.SAVE_AS_MODEL_TRANSITION_REPORTS_FAVORITE,
    saveAsFavoriteSaga
  );
}

// 期間スライドコンポーネントがクリックされた時に検索期間を設定して再検索を実行する
function* searchDateRangeSlideSaga(
  action: SearchModelTransitionReportsDateRangeSlideAction
) {
  const { startDate, endDate } = action.payload;
  yield fork(searchSearchConditonSaga, { startDate, endDate });
}

// 期間スライドコンポーネントがクリックされた時に実行する処理
function* handleDateRangeSlideSaga() {
  yield takeEvery(
    ModelTransitionReportsSettingActionTypes.SEARCH_MODEL_TRANSITION_REPORTS_DATE_RANGE_SLIDE,
    searchDateRangeSlideSaga
  );
}

/**
 * 変更後の店舗でデータを取得する
 */
function* changeCurrentHallsSaga(
  action: ChangeModelTransitionReportsCurrentHallsAction
) {
  // 店舗変更時にグラフを閉じる
  yield put(
    DataModelTransitionGraphActionCreators.renewDataModelTransitionGraphAction()
  );

  // 店舗が選択されていない場合は処理を中断
  if (action.payload.halls === undefined) return;

  yield fork(searchSearchConditonSaga, {});
}

/**
 * 選択中の店舗変更時、データを取得する
 */
function* handleChangeCurrentHallsSaga() {
  yield takeEvery(
    ModelTransitionReportsSettingActionTypes.CHANGE_MODEL_TRANSITION_REPORTS_CURRENT_HALLS,
    changeCurrentHallsSaga
  );
}

/**
 * 画面共有用短縮URL作成の処理
 */
function* createShortenedUrlSaga(
  action: CreateModelTransitionReportsShortenedUrlAction
) {
  const pageSetting: FavoriteItem['pageSetting'] = yield call(setPageSetting);

  const compressed = compressToEncodedURIComponent(
    action.payload.pageName,
    pageSetting ?? {}
  );
  const originalUrl = `${action.payload.locationUrl}?q=${compressed}`;

  yield put(ShortenedUrlActionCreators.postShortenedUrlAction({ originalUrl }));
}

// 画面共有用短縮URL作成の処理
function* handleShortenedUrlSaga() {
  yield takeEvery(
    ModelTransitionReportsSettingActionTypes.CREATE_MODEL_TRANSITION_REPORTS_SHORTENED_URL,
    createShortenedUrlSaga
  );
}

/**
 * 機種別推移の検索フォームに関するタスクを実行する
 */
export function* modelTransitionReportsSettingSaga() {
  yield fork(handleSearchSaga);
  yield fork(handleResetSaga);
  yield fork(handleTableSearchSaga);
  yield fork(handleChangeCurrentShuSaga);
  yield fork(handleFavoriteSaga);
  yield fork(handleDateRangeSlideSaga);
  yield fork(handleChangeCurrentHallsSaga);
  yield fork(handleShortenedUrlSaga);
}
