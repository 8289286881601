import { AxiosResponse } from 'axios';
import { call, fork, put, takeEvery } from 'redux-saga/effects';

import { EventsResponse } from '../domain/settingsEvents/types';

import {
  DataSettingsEventsActionCreators,
  DataSettingsEventsActionTypes,
  FetchDataSettingsEventsAction,
  PostDataSettingsEventsAction,
} from '../redux/server/dataSettingsEvents';
import { UserActionCreators } from '../redux/ui/user';
import { Api, buildConfig } from '../utils/api';
import { handleErrorSaga } from './errorSaga';

function* fetchEventsSaga(api: Api, action: FetchDataSettingsEventsAction) {
  try {
    yield put(
      DataSettingsEventsActionCreators.fetchDataSettingsEventsRequestAction()
    );

    const response: AxiosResponse<EventsResponse> = yield call(
      api.get,
      '/events',
      buildConfig(action.payload.params)
    );

    yield put(
      DataSettingsEventsActionCreators.fetchDataSettingsEventsSuccessAction(
        response.data
      )
    );
  } catch (error: unknown) {
    yield put(DataSettingsEventsActionCreators.renewDataSettingsEventsAction());
    yield fork(handleErrorSaga, error);
  }
}

function* postEventsSaga(api: Api, action: PostDataSettingsEventsAction) {
  try {
    const { body } = action.payload;

    yield call(api.post, `/events`, body, buildConfig());

    // ログイン時は取得状態をreduxに登録する
    if (body.eventType === 'login') {
      yield put(UserActionCreators.setIsLoginAction(true));
    }
  } catch (error: unknown) {
    yield put(DataSettingsEventsActionCreators.renewDataSettingsEventsAction());
    yield fork(handleErrorSaga, error);
  }
}

function* handleFetchEventsSaga(api: Api) {
  yield takeEvery(
    DataSettingsEventsActionTypes.FETCH_DATA_SETTINGS_EVENTS,
    fetchEventsSaga,
    api
  );
}

function* handlePostEventsSaga(api: Api) {
  yield takeEvery(
    DataSettingsEventsActionTypes.POST_DATA_SETTINGS_EVENTS,
    postEventsSaga,
    api
  );
}

export function* dataSettingsEventsSaga(context: { api: Api }) {
  yield fork(handleFetchEventsSaga, context.api);
  yield fork(handlePostEventsSaga, context.api);
}
