import axios, { AxiosRequestConfig } from 'axios';
import { stringify } from 'qs';

const apiHost = process.env.REACT_APP_API_HOST;

export const api = axios.create({
  baseURL: apiHost,
  paramsSerializer: function (params) {
    return stringify(params, { arrayFormat: 'brackets', encode: false });
  },
});

export const mockApi = axios.create({
  baseURL: apiHost,
});

export type Api = typeof api;

export function buildConfig(
  params?: AxiosRequestConfig['params']
): AxiosRequestConfig {
  return {
    params,
  };
}

export function buildDetailConfig(
  token: string,
  config?: AxiosRequestConfig
): AxiosRequestConfig {
  return {
    ...config,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      ...config?.headers,
    },
  };
}
