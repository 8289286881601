import { AxiosHeaders } from 'axios';
import { useEffect } from 'react';

import { api } from '../utils/api';
import { useAuth } from './useAuth';

/**
 * Axiosのinterceptorsを使用してtokenとheaderを付与
 */
export const useInterceptToken = () => {
  const { getAccessTokenSilently, loginWithRedirect } = useAuth();

  useEffect(() => {
    let requestInterceptors: number | undefined;

    const fn = async () => {
      requestInterceptors = api.interceptors.request.use(async (config) => {
        const token = await getAccessTokenSilently();
        config.headers = new AxiosHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          ...config.headers,
        });

        return config;
      });
    };

    fn();

    return () => {
      if (requestInterceptors != null) {
        api.interceptors.request.eject(requestInterceptors);
      }
    };
  }, [getAccessTokenSilently, loginWithRedirect]);
};
