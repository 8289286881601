import {
  DragDropContext,
  DragDropContextProps,
  Draggable,
  DraggableProvidedDraggableProps,
  DropResult,
  Droppable,
} from '@hello-pangea/dnd';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ShareIcon from '@mui/icons-material/Share';
import StarIcon from '@mui/icons-material/Star';
import { SxProps, Theme, TooltipProps } from '@mui/material';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { FC, useCallback, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  useHeaderState,
  useSetHeaderState,
} from '../../../contexts/HeaderContext';

import { FavoriteItem } from '../../../domain/favorites';
import { User } from '../../../domain/user';

import { SettingsFavoritesActionCreators } from '../../../redux/ui/settingsFavorites';
import { userSelector } from '../../../redux/ui/user';
import { favoriteToPath } from '../../../utils/favoriteToPath';
import { getSharedState } from '../../../utils/getSharedState';
import { getSharedUsers } from '../../../utils/getSharedUsers';
import { gaClickEvent } from '../../../utils/googleAnalytics';
import { preloadPageComponent } from '../../../utils/preloadPageComponent';
import { Tooltip } from '../../atoms/Tooltip/Tooltip';
import { FavoriteEditorProps } from '../FavoriteEditor';

type Props = {
  favoriteList?: FavoriteItem[];
  isOpenMenu: boolean;
};

type PresenterProps = {
  isOpenFavoriteMenuChild: boolean;
  toggle: () => void;
  onDragEnd: DragDropContextProps['onDragEnd'];
  userData: User | undefined;
} & Props;

const styles: Record<string, SxProps<Theme>> = {
  listItem: {
    width: 'auto',
    paddingLeft: (theme) => theme.spacing(2),
    paddingTop: '4px',
    paddingBottom: '4px',
    '&:hover .drag': {
      opacity: 0.5,
      transition: 'opacity 0.2s ease-in-out',
    },
  },
  dragIcon: {
    paddingRight: (theme) => theme.spacing(1),
    color: 'text.secondary',
  },
  listItemText: {
    overflow: 'hidden',
    fontSize: '14px',
    textOverflow: 'ellipsis',
  },
  listItemSecondaryText: {
    overflow: 'hidden',
    fontSize: '12px',
    textOverflow: 'ellipsis',
  },
  tooltipTitle: {
    fontSize: '14px',
  },
  tooltipMemo: {
    fontSize: '12px',
    color: 'text.secondary',
  },
};

function FavoriteDetailTooltip(props: TooltipProps) {
  return (
    <Tooltip
      placement="right-start"
      {...props}
      PopperProps={{
        sx: {
          '.MuiTooltip-tooltip': {
            color: 'text.primary',
            backgroundColor: 'common.white',
            padding: '1rem',
            borderRadius: '0.25rem',
            boxShadow: (theme) => theme.shadows[1],
            marginLeft: 0,
            maxWidth: 'calc(100vw - 300px)',
          },
        },
      }}
    />
  );
}

const getItemStyle = (
  isDragging: boolean,
  draggableStyle: DraggableProvidedDraggableProps['style']
) => ({
  ...draggableStyle,
  ...(isDragging && {
    opacity: 0.8,
    border: `1px solid #E2E0DE`,
  }),
});

const GlobalFavoritesNavigationPresenter: FC<PresenterProps> = (props) => {
  const {
    favoriteList,
    isOpenFavoriteMenuChild,
    toggle,
    onDragEnd,
    userData,
    isOpenMenu,
  } = props;

  return (
    <>
      <Tooltip
        title={isOpenMenu ? '' : 'お気に入り'}
        arrow
        placement="right"
        disableTouchListener
      >
        <ListItem
          button
          onClick={() => {
            toggle();
            gaClickEvent('サイドバーお気に入り開閉', {
              event_click_state: isOpenFavoriteMenuChild ? 'OFF' : 'ON',
            });
          }}
          id="sidebar-favorites-button"
          {...(favoriteList &&
            favoriteList.length > 0 && {
              'aria-expanded': isOpenFavoriteMenuChild,
              'aria-controls': 'sidebar-favorites',
            })}
        >
          <ListItemIcon sx={{ minWidth: '40px' }}>
            <StarIcon />
          </ListItemIcon>
          <ListItemText primary="お気に入り" />
          {isOpenFavoriteMenuChild ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      </Tooltip>

      {favoriteList && favoriteList.length > 0 && (
        <Collapse
          in={isOpenFavoriteMenuChild}
          timeout="auto"
          unmountOnExit
          id="sidebar-favorites"
          data-testid="sidebar-favorites"
          aria-labelledby="sidebar-favorites-button"
        >
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={'favoritesTable'} direction="vertical">
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {favoriteList.map((item, index) => {
                    const path = favoriteToPath(item);
                    const sharedState = getSharedState(item);

                    return (
                      <Draggable
                        draggableId={String(item.id)}
                        index={index}
                        key={item.id}
                      >
                        {(provided, snapshot) => (
                          <div
                            key={item.id}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <FavoriteDetailTooltip
                              title={
                                <>
                                  <Typography
                                    variant="body1"
                                    sx={styles.tooltipTitle}
                                  >
                                    {item.name}
                                  </Typography>
                                  {item.memo && (
                                    <Typography
                                      variant="body2"
                                      sx={styles.tooltipMemo}
                                    >
                                      {item.memo}
                                    </Typography>
                                  )}
                                </>
                              }
                              placement="right-start"
                            >
                              <ListItemButton
                                component={Link}
                                to={`${path}?fav_id=${item.id}`}
                                onClick={() => {
                                  gaClickEvent('お気に入り適用', {
                                    event_click_value: 'サイドバー',
                                  });
                                }}
                                sx={styles.listItem}
                                onMouseEnter={() => {
                                  item.pageName &&
                                    preloadPageComponent(item.pageName);
                                }}
                              >
                                <Box sx={styles.dragIcon}>
                                  <DragIndicatorIcon fontSize="small" />
                                </Box>
                                <ListItemText
                                  disableTypography
                                  primary={
                                    <Typography sx={styles.listItemText}>
                                      {item.name}
                                    </Typography>
                                  }
                                  secondary={
                                    <Typography
                                      sx={styles.listItemSecondaryText}
                                      color="textSecondary"
                                    >
                                      {item.pageName}
                                    </Typography>
                                  }
                                />
                                {sharedState === 'all' && (
                                  <Tooltip
                                    title="全体に共有されたお気に入り"
                                    arrow
                                  >
                                    <ShareIcon fontSize="small" />
                                  </Tooltip>
                                )}
                                {sharedState === 'privatelyShared' && (
                                  <Tooltip
                                    title={
                                      <>
                                        共有されたお気に入り
                                        <br />
                                        {getSharedUsers(item, userData?.userId)
                                          .map((item) => item.name)
                                          .join(', ')}
                                      </>
                                    }
                                    arrow
                                  >
                                    <ShareIcon
                                      fontSize="small"
                                      style={{ color: 'gray' }}
                                    />
                                  </Tooltip>
                                )}
                              </ListItemButton>
                            </FavoriteDetailTooltip>
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Collapse>
      )}
    </>
  );
};

export const GlobalFavoritesNavigation: FC<Props> = (props) => {
  const { isOpenMenu, isOpenFavoriteMenuChild } = useHeaderState();
  const setIsOpenMenu = useSetHeaderState();
  const dispatch = useDispatch();

  const userData = useSelector(userSelector, shallowEqual);

  const updateOrder = useCallback<Required<FavoriteEditorProps>['onPatch']>(
    (id, order) => {
      if (id != null && order != null) {
        dispatch(
          SettingsFavoritesActionCreators.patchSettingsFavoritesAction(id, {
            order,
          })
        );
      }
    },
    [dispatch]
  );

  const handleOnDragEnd = useCallback(
    (result: DropResult) => {
      const order = (result.destination?.index ?? result.source.index) + 1;
      updateOrder(Number(result.draggableId), order);
    },
    [updateOrder]
  );

  useEffect(() => {
    if (!isOpenMenu) {
      setIsOpenMenu((prev) => ({ ...prev, isOpenFavoriteMenuChild: false }));
    }
  }, [isOpenMenu, setIsOpenMenu]);

  return (
    <GlobalFavoritesNavigationPresenter
      isOpenFavoriteMenuChild={isOpenFavoriteMenuChild}
      toggle={() => {
        setIsOpenMenu((prev) => ({
          ...prev,
          isOpenMenu: true,
          isOpenFavoriteMenuChild: !prev.isOpenFavoriteMenuChild,
        }));
      }}
      onDragEnd={handleOnDragEnd}
      userData={userData}
      {...props}
    />
  );
};
