import { AxiosResponse } from 'axios';
import { call, fork, put, select, takeEvery } from 'redux-saga/effects';

import { SettingsKiLink } from '../domain/settingsKiLink';

import {
  FetchSettingsKiLinkAction,
  PutSettingsKiLinkAction,
  SettingsKiLinkActionCreators,
  SettingsKiLinkActionTypes,
  settingsKiLinkSelector,
} from '../redux/server/settingsKiLink';
import { Api, buildConfig } from '../utils/api';
import { handleErrorSaga } from './errorSaga';

export function* fetchSettingsKiLinkSaga(
  api: Api,
  action: FetchSettingsKiLinkAction
) {
  try {
    const { query } = action.payload;

    yield put(SettingsKiLinkActionCreators.fetchSettingsKiLinkRequestAction());
    const response: AxiosResponse<SettingsKiLink> = yield call(
      api.get,
      `/settings/kiLink`,
      buildConfig(query)
    );
    const settingsKiLink = response.data;
    yield put(
      SettingsKiLinkActionCreators.fetchSettingsKiLinkSuccessAction(
        settingsKiLink
      )
    );
  } catch (error: unknown) {
    yield put(SettingsKiLinkActionCreators.renewSettingsKiLinkAction());
    yield fork(handleErrorSaga, error);
  }
}

export function* putSettingsKiLinkSaga(
  api: Api,
  action: PutSettingsKiLinkAction
) {
  try {
    const { kiCode, query } = action.payload;

    yield put(SettingsKiLinkActionCreators.putSettingsKiLinkRequestAction());
    yield call(api.put, `/settings/kiLink/${kiCode}`, query, buildConfig());

    // 再取得
    const fetchedSettingsKiLink: SettingsKiLink | undefined = yield select(
      settingsKiLinkSelector
    );
    const response: AxiosResponse<SettingsKiLink> = yield call(
      api.get,
      `/settings/kiLink`,
      buildConfig(fetchedSettingsKiLink?.settings ?? {})
    );
    const settingsKiLink = response.data;
    yield put(
      SettingsKiLinkActionCreators.putSettingsKiLinkSuccessAction(
        settingsKiLink
      )
    );
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    yield put(
      SettingsKiLinkActionCreators.putSettingsKiLinkFailureAction(error)
    );
    yield fork(handleErrorSaga, error);
  }
}

function* handleFetchSettingsKiLinkSaga(api: Api) {
  yield takeEvery(
    SettingsKiLinkActionTypes.FETCH_SETTINGS_KI_LINK,
    fetchSettingsKiLinkSaga,
    api
  );
}

function* handlePutSettingsKiLinkSaga(api: Api) {
  yield takeEvery(
    SettingsKiLinkActionTypes.PUT_SETTINGS_KI_LINK,
    putSettingsKiLinkSaga,
    api
  );
}

export function* settingsKiLinkSagas(context: { api: Api }) {
  yield fork(handleFetchSettingsKiLinkSaga, context.api);
  yield fork(handlePutSettingsKiLinkSaga, context.api);
}
