import { TimelineValue } from './hallReportsSettingMks';
import {
  Column,
  OrderType,
  Row,
  SummaryData,
  SummaryDataElement,
  SummaryDataElementWithRank,
} from './schemas';

/**
 * 店舗レポート商圏実績集計概要のデータの型
 */
export type DataHallMksSummary = {
  setting: {
    excludeToday: boolean;
    halls: string[];
    kadoTimeType: TimelineValue;
    shuGroupIds: string[];
    ymdComparisonList: string[];
    ymdList: string[];
  };
  data: {
    self: {
      name: string;
      senyuRitu: SummaryData<SummaryDataElementWithRank>;
      sijiRitu: SummaryData<SummaryDataElementWithRank>;
      kado: {
        columns: string[];
        items: {
          name: string;
          kyakuSu: {
            original: SummaryDataElementWithRank;
            comparison: SummaryDataElement;
          };
          kadoRitu: {
            original: SummaryDataElementWithRank;
            comparison: SummaryDataElement;
          };
        }[];
      };
    };
    marketArea: {
      name: string;
      kado: {
        name: string;
        kyakuSu: {
          original: SummaryDataElement;
          comparison: SummaryDataElement;
        };
        kadoRitu: {
          original: SummaryDataElement;
          comparison: SummaryDataElement;
        };
      };
    };
  };
};

/**
 * 店舗レポート商圏実績テーブルの検索条件の型
 */
type DataHallMksTableParams = {
  shuGroupIds: string[];
  halls?: string[];
  ymdList?: string[];
  ymdComparisonList?: string[];
  excludeToday?: boolean;
  sort?: string;
  order?: OrderType;
  kadoTimeType: TimelineValue;
};

/**
 * 店舗レポート商圏実績テーブルのデータの型
 */
export type DataHallMksTable = {
  setting: DataHallMksTableParams;
  data: {
    columns: Column[];
    rows: Row[];
  };
};

/**
 * テーブルのデータには単位には「人」を表示する必要はないので削除する
 */
export const removeUnwantedUnitFromRowValues = (rows: Row[]) =>
  rows.map((row) => ({
    ...row,
    data: row.data.map((x) => ({
      ...x,
      value: x.value.replace('人', ''),
    })),
  }));

/**
 * データバーを表示するために各カラムの最大値を求める
 */
export const maxValuesForColumnCodes = (columns: Column[], rows: Row[]) => {
  return columns.reduce<{ [columnCode: string]: number }>(
    (acc, curr, index) => {
      const columnMaxValue = rows
        .filter((row) => !row.data.at(0)?.value.includes('平均'))
        .map((row) => row.data[index])
        .reduce((acc, curr) => {
          const parsed = parseFloat(curr.value.replaceAll(',', ''));
          if (Number.isNaN(parsed)) {
            return acc;
          }
          return parsed > acc ? parsed : acc;
        }, 0);
      return {
        ...acc,
        [curr.code]: columnMaxValue,
      };
    },
    {}
  );
};
