import { createSelector } from 'reselect';

import { settingsOptionsPpmShareDefaultValue } from '../../domain/ppmShare/defaultValue';
import { SettingsOptionsPpmShare } from '../../domain/ppmShare/types';
import { LoadingState } from '../../domain/schemas';

import { RootState } from '../../store';

// Action Types

const FETCH_SETTINGS_OPTIONS_PPM_SHARE = 'FETCH_SETTINGS_OPTIONS_PPM_SHARE' as const;
const FETCH_SETTINGS_OPTIONS_PPM_SHARE_REQUEST = 'FETCH_SETTINGS_OPTIONS_PPM_SHARE_REQUEST' as const;
const FETCH_SETTINGS_OPTIONS_PPM_SHARE_SUCCESS = 'FETCH_SETTINGS_OPTIONS_PPM_SHARE_SUCCESS' as const;
const RENEW_SETTINGS_OPTIONS_PPM_SHARE = 'RENEW_SETTINGS_OPTIONS_PPM_SHARE' as const;

export const SettingsOptionsPpmShareActionTypes = {
  FETCH_SETTINGS_OPTIONS_PPM_SHARE,
  FETCH_SETTINGS_OPTIONS_PPM_SHARE_REQUEST,
  FETCH_SETTINGS_OPTIONS_PPM_SHARE_SUCCESS,
  RENEW_SETTINGS_OPTIONS_PPM_SHARE,
};

// Action Creators

function fetchSettingsOptionsPpmShareAction() {
  return {
    type: FETCH_SETTINGS_OPTIONS_PPM_SHARE,
  };
}

function fetchSettingsOptionsPpmShareRequestAction() {
  return {
    type: FETCH_SETTINGS_OPTIONS_PPM_SHARE_REQUEST,
  };
}

function fetchSettingsOptionsPpmShareSuccessAction(
  settingsPpmShare: SettingsOptionsPpmShare
) {
  return {
    type: FETCH_SETTINGS_OPTIONS_PPM_SHARE_SUCCESS,
    payload: { settingsPpmShare },
  };
}

function renewSettingsOptionsPpmShareAction() {
  return {
    type: RENEW_SETTINGS_OPTIONS_PPM_SHARE,
  };
}

export const SettingsOptionsPpmShareActionCreators = {
  fetchSettingsOptionsPpmShareAction,
  fetchSettingsOptionsPpmShareRequestAction,
  fetchSettingsOptionsPpmShareSuccessAction,
  renewSettingsOptionsPpmShareAction,
};

// Actions

type SettingsOptionsPpmShareAction =
  | ReturnType<typeof fetchSettingsOptionsPpmShareAction>
  | ReturnType<typeof fetchSettingsOptionsPpmShareRequestAction>
  | ReturnType<typeof fetchSettingsOptionsPpmShareSuccessAction>
  | ReturnType<typeof renewSettingsOptionsPpmShareAction>;

// State

type SettingsOptionsPpmShareState = {
  settingsOptionsPpmShare?: SettingsOptionsPpmShare;
  loadingState: LoadingState;
};

const initialState: SettingsOptionsPpmShareState = {
  settingsOptionsPpmShare: undefined,
  loadingState: 'prepare',
};

// Selector

// settingsOptionsを取得
export const settingsOptionsPpmShareSelector = (state: RootState) => {
  const settingsOptions = state.settingsOptionsPpmShare.settingsOptionsPpmShare;
  return settingsOptions
    ? settingsOptions
    : settingsOptionsPpmShareDefaultValue;
};

export const settingsOptionsPpmShareLoadingStateSelector = (
  state: RootState
) => {
  return state.settingsOptionsPpmShare.loadingState;
};

// フィルター用のジャンル一覧を取得する
export const settingsOptionsPpmShareGenresSelector = createSelector(
  settingsOptionsPpmShareSelector,
  (settingsOptionsPpmShare) => {
    // MEMO: コンポーネント側がundefined許容しないため、空の場合空データを返す
    if (settingsOptionsPpmShare === undefined)
      return settingsOptionsPpmShareDefaultValue.genres.ppm;

    return settingsOptionsPpmShare.genres.ppm;
  }
);

// 検索条件を取得
export const settingsOptionsPpmShareSearchConditionSelector = createSelector(
  settingsOptionsPpmShareSelector,
  (settingsOptions) => settingsOptions.searchCondition
);

// 表示項目として表示する項目を取得
export const settingsOptionsPpmShareFieldsSelector = createSelector(
  settingsOptionsPpmShareSelector,
  (settingsOptions) => settingsOptions.fields
);

// 表示項目のFieldsのisNewがtrueのものがあるかどうかを取得する
export const settingsOptionsPpmShareFieldsIsNewSelector = createSelector(
  settingsOptionsPpmShareFieldsSelector,
  (fields) => {
    return fields.ppm.some((item) => item.isNew);
  }
);

// Reducer

export function settingsOptionsPpmShareReducer(
  state = initialState,
  action: SettingsOptionsPpmShareAction
) {
  switch (action.type) {
    case FETCH_SETTINGS_OPTIONS_PPM_SHARE_REQUEST:
      return {
        ...state,
        loadingState: 'loading',
      };
    case FETCH_SETTINGS_OPTIONS_PPM_SHARE_SUCCESS:
      return {
        ...state,
        loadingState: 'loaded',
        settingsOptionsPpmShare: action.payload.settingsPpmShare,
      };
    case RENEW_SETTINGS_OPTIONS_PPM_SHARE:
      return initialState;
    default:
      return state;
  }
}
