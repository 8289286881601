import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';

import { LoadingState } from '../../../domain/schemas';

import { selectPageSettingWithOriginalHashAction } from '../../../redux/ui/favorite';
import { pathToFavoritePageName } from '../../../utils/pathToFavoritePageName';
import { LoadingPage } from '../../pages/LoadingPage';

type Props = {
  s: string;
};

export const ShortenedHashPageSetting: FC<Props> = (props) => {
  const { s } = props;

  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const [loadingState, setLoadingState] = useState<LoadingState | undefined>();

  useEffect(() => {
    // 初回あるいはページが変更されたときloadingStateをprepareに戻す
    setLoadingState('prepare');
  }, [pathname]);

  useEffect(() => {
    if (loadingState === 'prepare') {
      setLoadingState('loading');

      try {
        const pageNameFromPath = pathToFavoritePageName(pathname);

        dispatch(
          selectPageSettingWithOriginalHashAction({
            shortenedHash: s,
            pageName: pageNameFromPath,
          })
        );
      } catch (e) {
        if (e instanceof Error) {
          throw new Error(e.message, { cause: e });
        }
      } finally {
        setLoadingState('loaded');
      }
    }
  }, [dispatch, loadingState, pathname, s]);

  if (loadingState === 'prepare' || loadingState === 'loading') {
    return <LoadingPage />;
  }

  return <Outlet />;
};
