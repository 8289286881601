import { SettingsFavorite } from '../favorites';
import { shareOptions } from './consistent';

export const filterFavorites = (
  favorites: SettingsFavorite | undefined,
  options: { shareFilter: typeof shareOptions[number] }
): SettingsFavorite | undefined => {
  const { shareFilter } = options;

  if (favorites == null) {
    return;
  }

  if (favorites.favorites == null || shareFilter === 'すべて') {
    return favorites;
  }

  return {
    ...favorites,
    favorites: favorites.favorites.filter((favorite) => {
      switch (shareFilter) {
        case '全体共有':
          return favorite.isShared;
        case '一部共有':
          return (
            !favorite.isShared &&
            favorite.privatelySharedUsers &&
            favorite.privatelySharedUsers.length > 0
          );
        case '共有なし':
          return (
            !favorite.isShared &&
            (favorite.privatelySharedUsers == null ||
              favorite.privatelySharedUsers.length === 0)
          );
        default:
          throw new Error('Invalid share filter');
      }
    }),
  };
};
