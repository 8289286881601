import { AxiosResponse } from 'axios';
import { call, fork, put, takeEvery } from 'redux-saga/effects';

import { SettingsPlansDetails } from '../domain/settingsPlansDetails';

import {
  FetchSettingsPlansDetailsAction,
  SettingsPlansDetailsActionCreators,
  SettingsPlansDetailsActionTypes,
} from '../redux/server/settingsPlansDetails';
import { Api, buildConfig } from '../utils/api';
import { handleErrorSaga } from './errorSaga';

export function* fetchSettingsPlansDetailsSaga(
  api: Api,
  action: FetchSettingsPlansDetailsAction
) {
  const { year, month, hallCode } = action.payload.params;
  try {
    yield put(
      SettingsPlansDetailsActionCreators.fetchSettingsPlansDetailsRequestAction()
    );
    const response: AxiosResponse<SettingsPlansDetails> = yield call(
      api.get,
      `/settings/plans/${year}/${month}/${hallCode}`,
      buildConfig()
    );
    const settingsPlans = response.data;
    yield put(
      SettingsPlansDetailsActionCreators.fetchSettingsPlansDetailsSuccessAction(
        settingsPlans
      )
    );
  } catch (error: unknown) {
    yield put(
      SettingsPlansDetailsActionCreators.renewSettingsPlansDetailsACtion()
    );
    yield fork(handleErrorSaga, error);
  }
}

function* handleFetchSettingsPlansDetailsSaga(api: Api) {
  yield takeEvery(
    SettingsPlansDetailsActionTypes.FETCH_SETTINGS_PLANS_DETAILS,
    fetchSettingsPlansDetailsSaga,
    api
  );
}

export function* settingsPlansDetailsSagas(context: { api: Api }) {
  yield fork(handleFetchSettingsPlansDetailsSaga, context.api);
}
