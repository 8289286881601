import { AxiosResponse } from 'axios';
import { call, fork, put, select, takeEvery } from 'redux-saga/effects';

import {
  DataModelTransition,
  DataModelTransitionData,
  DataModelTransitionParams,
} from '../domain/dataModelTransition';
import { MAIN_FIELD_TYPE } from '../domain/schemas';
import { SettingsOptionsModelTransition } from '../domain/settingsOptionsModelTransition';
import { ShuOption } from '../domain/shu';

import {
  DataModelTransition2ndRowActionCreators,
  DataModelTransition2ndRowActionTypes,
  FetchDataModelTransition2ndRowAction,
  SearchDataModelTransition2ndRowAction,
  ToggleDataModelTransition2ndRowAction,
  singleDataModelTransition2ndRowSelector,
} from '../redux/server/dataModelTransition2ndRow';
import { settingsOptionsModelTransitionSelector } from '../redux/server/settingsOptionsModelTransition';
import {
  modelTransitionReportsSearchConditionSelector,
  modelTransitionReportsSelectedCurrentShuSelector,
  modelTransitionReportsSettingCurrentHallsSelector,
} from '../redux/ui/modelTransitionReportsSetting';
import { Api, buildConfig } from '../utils/api';
import { selectShus2HallReportSearchCondition } from '../utils/shu';
import { handleErrorSaga } from './errorSaga';

/**
 * kiCode を元に検索条件を加工する
 * @param kiCode kiList の先頭の機種
 * @param currentShu 選択中の種別の情報
 * @param searchParams 機種別推移の検索条件
 * @param searchCondition 機種別推移の設定項目
 * @returns 加工された検索条件
 */
export const queryParameterToFormConditions = (
  kiCode: string,
  currentShu: ShuOption,
  searchParams: DataModelTransitionParams,
  searchCondition: SettingsOptionsModelTransition
): DataModelTransitionParams => {
  // デフォルトの非推移項目
  const defaultNonTransition = searchCondition.fields.nonTransition
    .filter((value) => value.isDefault)
    .map((value) => value.code);
  // デフォルトの推移項目
  const defaultTransition = searchCondition.fields.transition
    .filter((value) => value.isDefault)
    .map((value) => value.code);

  // 表示項目が指定されていない時は、デフォルトの条件を指定する
  const fields = {
    transitiveFields:
      searchParams.transitiveFields === undefined
        ? defaultTransition
        : searchParams.transitiveFields,
    nonTransitiveFields:
      searchParams.nonTransitiveFields === undefined
        ? defaultNonTransition
        : searchParams.nonTransitiveFields,
  };

  // MEMO: 元の検索条件の非推移項目が空のときは、メインフィールドの kiTushoMei が指定される
  // そのまま送ると展開行の非推移項目がズレてしまうため、該当するデータの際は非推移項目で hlMei を指定する
  if (
    fields.nonTransitiveFields.length === 1 &&
    fields.nonTransitiveFields[0] === MAIN_FIELD_TYPE.KI_TUSHO_MEI
  ) {
    fields.nonTransitiveFields = [MAIN_FIELD_TYPE.HL_MEI];
  }

  // MEMO: 現在選択中の種別を取得
  // テーブルの種別選択で変更した場合は元の検索条件に含まれていないため、改めて加工する
  const selectedShuParams = selectShus2HallReportSearchCondition([currentShu]);

  return {
    ...searchParams,
    // MEMO: 展開行は hlMei を指定
    mainField: MAIN_FIELD_TYPE.HL_MEI,
    // MEMO: kiList を kiCode のみに変更する
    ...{ kiList: [kiCode] },
    // MEMO: ForSijiritu 系のパラメータは検索条件で指定されているものを分母として渡す
    ...{
      kiListForSijiritu: searchParams.kiList,
    },
    // MEMO: テーブルの種別選択を考慮した shuList / shuGroupIds を追加する
    // まずは元の検索条件から一旦 shuList / shuGroupIds を削除
    // 選択中の種別の情報を加える
    ...selectedShuParams,
    // MEMO: 表示項目が指定されていない時は、デフォルトの条件を指定する
    ...fields,
  };
};

/**
 * 機種別推移の展開行を表示する
 * @param action Action
 */
function* searchDataModelTransition2ndRowSaga(
  action: SearchDataModelTransition2ndRowAction
) {
  const kiCode = action.payload.kiCode;

  // APIリクエスト前に必要な情報を取得
  const searchParams: DataModelTransitionParams = yield select(
    modelTransitionReportsSearchConditionSelector
  ); // 現在の検索条件

  // APIリクエスト前に必要な設定項目を取得
  const searchCondition: SettingsOptionsModelTransition = yield select(
    settingsOptionsModelTransitionSelector
  ); // 設定項目

  // APIリクエスト前に必要な選択中の種別を取得
  const currentShu: ShuOption = yield select(
    modelTransitionReportsSelectedCurrentShuSelector
  ); // 選択中の種別

  const currentHalls: string[] = yield select(
    modelTransitionReportsSettingCurrentHallsSelector
  );

  // 検索条件を加工
  const params = queryParameterToFormConditions(
    kiCode,
    currentShu,
    {
      ...searchParams,
      halls: currentHalls.length === 0 ? searchParams.halls : currentHalls,
    },
    searchCondition
  );

  // 展開行を取得する
  yield put(
    DataModelTransition2ndRowActionCreators.fetchDataModelTransition2ndRowAction(
      kiCode,
      params
    )
  );
}

/**
 * 機種別推移の展開行データを取得する
 * @param api AxiosInstance
 * @param action Action
 */
export function* fetchDataModelTransition2ndRowSaga(
  api: Api,
  action: FetchDataModelTransition2ndRowAction
) {
  const kiCode = action.payload.kiCode;
  const params = action.payload.params;

  try {
    yield put(
      DataModelTransition2ndRowActionCreators.fetchDataModelTransition2ndRowRequestAction(
        kiCode,
        params
      )
    );

    const response: AxiosResponse<DataModelTransition> = yield call(
      api.get,
      '/data/kiTransition',
      buildConfig(params)
    );

    yield put(
      DataModelTransition2ndRowActionCreators.fetchDataModelTransition2ndRowSuccessAction(
        kiCode,
        response.data
      )
    );
  } catch (error: unknown) {
    yield put(
      DataModelTransition2ndRowActionCreators.renewDataModelTransition2ndRowAction()
    );
    yield fork(handleErrorSaga, error);
  }
}

/**
 * 指定したQueryParameterと一致する展開行を表示・非表示する
 * @param action Action
 */
function* toggleDataModelTransition2ndRowSaga(
  action: ToggleDataModelTransition2ndRowAction
) {
  const kiCode = action.payload.kiCode;

  if (!kiCode) {
    throw new Error('kiCode が指定されていません。');
  }

  // queryParameterをもとに展開行を取得
  const now2ndRow: DataModelTransitionData | undefined = yield select(
    singleDataModelTransition2ndRowSelector(kiCode)
  );

  if (now2ndRow === undefined) {
    // 未取得の場合データを取得して表示する
    yield put(
      DataModelTransition2ndRowActionCreators.searchDataModelTransition2ndRowAction(
        kiCode
      )
    );
  } else {
    // 取得済みの場合はデータを削除する
    yield put(
      DataModelTransition2ndRowActionCreators.hideDataModelTransition2ndRowAction(
        kiCode
      )
    );
  }
}

/**
 * SEARCH_DATA_MODEL_TRANSITION_2NDROWがDispatchされた時の処理
 */
function* handleSearchDataModelTransition2ndRowSaga() {
  yield takeEvery(
    DataModelTransition2ndRowActionTypes.SEARCH_DATA_MODEL_TRANSITION_2NDROW,
    searchDataModelTransition2ndRowSaga
  );
}

/**
 * FETCH_DATA_MODEL_TRANSITION_2NDROWがDispatchされた時の処理
 */
function* handleFetchDataModelTransition2ndRowSaga(api: Api) {
  yield takeEvery(
    DataModelTransition2ndRowActionTypes.FETCH_DATA_MODEL_TRANSITION_2NDROW,
    fetchDataModelTransition2ndRowSaga,
    api
  );
}

/**
 * TOGGLE_DATA_MODEL_TRANSITION_2NDROWがDispatchされた時の処理
 */
function* handleToggleDataModelTransition2ndRowSaga() {
  yield takeEvery(
    DataModelTransition2ndRowActionTypes.TOGGLE_DATA_MODEL_TRANSITION_2NDROW,
    toggleDataModelTransition2ndRowSaga
  );
}

export function* dataModelTransition2ndRowSagas(context: { api: Api }) {
  yield fork(handleSearchDataModelTransition2ndRowSaga);
  yield fork(handleFetchDataModelTransition2ndRowSaga, context.api);
  yield fork(handleToggleDataModelTransition2ndRowSaga); // 展開行を表示・非表示
}
