// この関数がTooltipのラッパーとなっており、この関数でのみ読込みさせています
// eslint-disable-next-line no-restricted-imports
import TooltipOriginal, { TooltipProps } from '@mui/material/Tooltip';
import { FC } from 'react';

export type { TooltipProps };

/**
 * ツールチップのラッパーコンポーネント
 *
 * テーブル内でツールチップを表示する際にはこのコンポーネントを使用してください。
 * ツールチップがボタンに干渉して押せなくなる問題を避けるために、disableInteractiveを設定しています。
 * 意図的にツールチップの表示を残したい場合は、disableInteractiveをfalseに指定してください。
 */
export const Tooltip: FC<TooltipProps> = (props) => {
  return (
    <TooltipOriginal
      {...props}
      disableInteractive={props.disableInteractive ?? true}
    />
  );
};
