import { PlanProgressTransitionReportsSettingState } from '../../redux/ui/planProgressTransitionReportsSetting';
import { DataPlanProgressTransitionData } from '../dataPlanProgressTransition';

/**
 * rowsからisShowRowがfalseの行を除去する
 */
export const filterShowRows = (
  rows: DataPlanProgressTransitionData['rows'],
  isShowRow: PlanProgressTransitionReportsSettingState['isShowRow']
) => {
  const removeHiddenRowsDates = [
    ...(isShowRow.previousYearSameMonth ? [] : ['前年同月', '前年同月比']),
    ...(isShowRow.forecast ? [] : ['着地見込', '着地見込差']),
  ];
  return rows.filter((row) => !removeHiddenRowsDates.includes(row.date));
};
