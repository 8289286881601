import { AxiosResponse } from 'axios';
import { call, fork, put, select, takeEvery } from 'redux-saga/effects';

import { DataKiParams } from '../domain/dataKi';
import { DataKiDai, DataKiDaiParams } from '../domain/dataKiDai';

import { dataKiParamsSelector } from '../redux/server/dataKi';
import {
  DataKiDaiActionCreators,
  DataKiDaiActionTypes,
  FetchDataKiDaiAction,
  SearchDataKiDaiAction,
  SearchDataKiDaiFieldAction,
  SearchDataKiDaiKiAction,
  SearchDataKiDaiMarkingAction,
  SearchDataKiDaiSortAction,
  dataKiDaiParamsSelector,
} from '../redux/server/dataKiDai';
import { DataKiGraphActionCreators } from '../redux/server/dataKiGraph';
import { DataKiKasidamaActionCreators } from '../redux/server/dataKiKasidama';
import { DataKiMksTransitiveGraphActionCreators } from '../redux/server/dataKiMksTransitiveGraph';
import { ModelReportsSettingActionCreators } from '../redux/ui/modelReportsSetting';
import { Api, buildConfig } from '../utils/api';
import { dataKiDaiSearchConditionParams } from '../utils/dataKiDaiSearchConditionParams';
import { validateQuery } from '../utils/validateQuery';
import { handleErrorSaga } from './errorSaga';
import { changeDaiColumnsOrderSaga } from './modelReportsSettingSaga';

/**
 * 指定した検索条件で台別データをAPIから取得する
 * @param api API
 * @param action FetchDataKiDaiAction
 */
export function* fetchDataKiDaiSagas(api: Api, action: FetchDataKiDaiAction) {
  try {
    yield put(DataKiDaiActionCreators.fetchDataKiDaiRequestAction());

    // 他のデータがあれば、同時に表示することがないためクリアする
    yield put(DataKiGraphActionCreators.hideDataKiGraphAction());
    yield put(DataKiKasidamaActionCreators.hideDataKiKasidama());
    yield put(
      DataKiMksTransitiveGraphActionCreators.hideDataKiMksTransitiveGraphAction()
    );

    validateQuery(action.payload.params);

    // 台別データ取得
    const response: AxiosResponse<DataKiDai> = yield call(
      api.get,
      '/data/ki/dai',
      buildConfig(action.payload.params)
    );
    const dataKiDai = response.data;

    // 台別データを登録
    yield put(DataKiDaiActionCreators.fetchDataKiDaiSuccessAction(dataKiDai));

    yield fork(changeDaiColumnsOrderSaga);
  } catch (error: unknown) {
    yield put(DataKiDaiActionCreators.renewDataKiDaiAction());
    yield fork(handleErrorSaga, error);
  }
}

/**
 * FETCH_DATA_KI_DAIをDispatch時の処理
 * @param api API
 */
function* handlFetchDataKiDaiSagas(api: Api) {
  yield takeEvery(
    DataKiDaiActionTypes.FETCH_DATA_KI_DAI,
    fetchDataKiDaiSagas,
    api
  );
}

/**
 * 選択した機種で台別データを再取得
 */
function* searchDataKiDai(action: SearchDataKiDaiAction) {
  // 現在の検索条件
  const daiSearchCondition: DataKiDaiParams | undefined = yield select(
    dataKiDaiParamsSelector
  );

  const searchCondition: DataKiParams = yield select(dataKiParamsSelector);

  // メインテーブル固有のパラメータ（表示項目やマーキング、オーダーなど）以外をマージする
  const mergedParams: DataKiDaiParams = {
    areas: searchCondition.areas,
    dateSuffixes: searchCondition.dateSuffixes,
    dates: searchCondition.dates,
    dayType: searchCondition.dayType,
    days: searchCondition.days,
    excludeToday: searchCondition.excludeToday,
    hallsForDonyuJokyo: searchCondition.hallsForDonyuJokyo,
    isDonyuForDonyuJokyo: searchCondition.isDonyuForDonyuJokyo,
    shuGroupIds: searchCondition.shuGroupIds,
    shuList: searchCondition.shuList,
    ymdList: searchCondition.ymdList,
    ymdComparisonList: searchCondition.ymdComparisonList,
    startDate: searchCondition.startDate,
    endDate: searchCondition.endDate,
    startComparisonDate: searchCondition.startComparisonDate,
    endComparisonDate: searchCondition.endComparisonDate,
    ...daiSearchCondition,
  };

  const fetchedDataKiDaiSearchCondition = dataKiDaiSearchConditionParams(
    action.payload.mainField,
    action.payload.dataKi1stRow,
    searchCondition
  );

  // 選択中の機種行がどの「メーカーID・ホールID」を持つのかを判断するために登録
  if (action.payload.requestParams) {
    yield put(
      ModelReportsSettingActionCreators.changeSelectedDataKiExpandedRowAction(
        action.payload.requestParams.mainFieldId,
        action.payload.requestParams.hallId,
        '3rdRow'
      )
    );
  } else {
    yield put(
      ModelReportsSettingActionCreators.changeSelectedDataKiExpandedRowAction(
        '',
        '',
        '1stRow'
      )
    );
  }

  // 変更後の機種で再取得
  yield put(
    DataKiDaiActionCreators.fetchDataKiDaiAction({
      ...mergedParams,
      halls: action.payload.hall
        ? [action.payload.hall.value]
        : searchCondition.halls,
      ...fetchedDataKiDaiSearchCondition,
    })
  );
}

/**
 * 選択した機種で台別データを再取得
 */
function* searchDataKiDaiKiSagas(action: SearchDataKiDaiKiAction) {
  // 現在の検索条件
  const searchCondition: DataKiDaiParams | undefined = yield select(
    dataKiDaiParamsSelector
  );

  // 変更後の機種で再取得
  yield put(
    DataKiDaiActionCreators.fetchDataKiDaiAction({
      ...searchCondition,
      [action.payload.mainField.name]: [action.payload.mainField.value],
    })
  );
}

/**
 * 選択したソート条件で台別データを再取得
 */
function* searchDataKiDaiSortSagas(action: SearchDataKiDaiSortAction) {
  // 現在の検索条件
  const searchCondition: DataKiDaiParams | undefined = yield select(
    dataKiDaiParamsSelector
  );

  // 変更後のソート条件で再取得
  yield put(
    DataKiDaiActionCreators.fetchDataKiDaiAction({
      ...searchCondition,
      ...action.payload,
    })
  );
}

/**
 * 選択したマーキング条件で台別データを再取得
 */
function* searchDataKiDaiMarkingSagas(action: SearchDataKiDaiMarkingAction) {
  // 現在の検索条件
  const searchCondition: DataKiDaiParams | undefined = yield select(
    dataKiDaiParamsSelector
  );

  // 変更後のマーキング条件で再取得
  yield put(
    DataKiDaiActionCreators.fetchDataKiDaiAction({
      ...searchCondition,
      ...action.payload,
    })
  );
}

/**
 * 選択した表示項目で台別データを再取得
 */
function* searchDataKiDaiFieldSagas(action: SearchDataKiDaiFieldAction) {
  // 現在の検索条件
  const searchCondition: DataKiDaiParams | undefined = yield select(
    dataKiDaiParamsSelector
  );

  // 変更後の表示項目で再取得
  yield put(
    DataKiDaiActionCreators.fetchDataKiDaiAction({
      ...searchCondition,
      fields: action.payload.fields.map((item) => item.code),
    })
  );
}

/**
 * SEARCH_DATA_KI_DAIをDispatch時の処理
 */
function* handlSearchDataKiDaiSagas() {
  yield takeEvery(DataKiDaiActionTypes.SEARCH_DATA_KI_DAI, searchDataKiDai);
}

/**
 * テーブル固有の検索条件変更検知時の処理
 */
function* handleTableSearchSaga() {
  // 機種変更時
  yield takeEvery(
    DataKiDaiActionTypes.SEARCH_DATA_KI_DAI_KI,
    searchDataKiDaiKiSagas
  );

  // ソート実行時
  yield takeEvery(
    DataKiDaiActionTypes.SEARCH_DATA_KI_DAI_SORT,
    searchDataKiDaiSortSagas
  );

  // マーキング実行時
  yield takeEvery(
    DataKiDaiActionTypes.SEARCH_DATA_KI_DAI_MARKING,
    searchDataKiDaiMarkingSagas
  );

  // 表示項目変更時
  yield takeEvery(
    DataKiDaiActionTypes.SEARCH_DATA_KI_DAI_FIELD,
    searchDataKiDaiFieldSagas
  );
}

/**
 * 台別データに関するタスクを実行する
 * @param context APIに関するデータ
 */
export function* dataKiDaiSagas(context: { api: Api }) {
  yield fork(handlFetchDataKiDaiSagas, context.api);
  yield fork(handlSearchDataKiDaiSagas);
  yield fork(handleTableSearchSaga);
}
