import { format } from 'date-fns';

import {
  makeDateFromDateUnitAndRange,
  reportsDateRanges,
  reportsDateUnits,
} from '../../utils/reportsDateRange';
import { DataDai } from './types';
import { UnitDataListSettingDateRangeParams } from './types';

/**
 * [台別データ一覧] デフォルトレスポンスデータを生成する
 */
export const dataDaiDefaultValue = (): {
  setting: DataDai['setting'];
  data: DataDai['data'];
  dateRangeParams: UnitDataListSettingDateRangeParams;
} => {
  const current = new Date();
  const dateUnit = reportsDateUnits[0];
  const dateRange = reportsDateRanges[0]; // MEMO: 期間のデフォルトは'前日'

  // 指定された期間から日付を算出する
  const date = makeDateFromDateUnitAndRange(dateRange, dateUnit, current);
  const ymdList = date.ymdList.map((date) => format(date, 'yyyy-MM-dd'));
  const ymdComparisonList = date.ymdComparisonList.map((date) =>
    format(date, 'yyyy-MM-dd')
  );

  return {
    setting: {
      excludeToday: true,
      order: 'asc' as const,
      ymdList,
      ymdComparisonList,
    },
    data: {
      columns: [],
      rows: [],
    },
    dateRangeParams: {
      dateUnit,
      dateRange,
      isComparison: true,
    },
  };
};
