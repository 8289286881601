import originalSortBy from 'lodash.sortby';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Iteratee<T> = (value: T) => any;

/**
 * lodash.sortbyのラッパー
 *
 * @link https://lodash.com/docs/4.17.15#sortBy
 */
export function sortBy<T>(
  array: T[],
  iteratees: Iteratee<T> | Iteratee<T>[]
): T[] {
  return originalSortBy(array, iteratees);
}
