import { Auth0Provider, Auth0ProviderOptions } from '@auth0/auth0-react';
import { FC, PropsWithChildren } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { useInterceptToken } from '../hooks/useInterceptToken';

import {
  AUTH0_AUDIENCE,
  AUTH0_CALLBACK_URL,
  AUTH0_CLIENT_ID,
  AUTH0_DOMAIN,
} from '../consistents';

const InterceptToken: FC<PropsWithChildren<unknown>> = (props) => {
  const { children } = props;

  useInterceptToken();

  return <>{children}</>;
};

/**
 * Axiosのinterceptorsを使用してtokenとheaderを付与
 *
 * useNavigateを使用しているためrouterより子に配置する必要がある
 */
export const AuthProvider: FC<PropsWithChildren<unknown>> = () => {
  const navigate = useNavigate();

  const onRedirectCallback: Auth0ProviderOptions['onRedirectCallback'] = (
    appState
  ) => {
    navigate(
      appState && appState.redirect_path
        ? appState.redirect_path
        : window.location.pathname
    );
  };

  return (
    <Auth0Provider
      domain={AUTH0_DOMAIN}
      clientId={AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: AUTH0_CALLBACK_URL,
        audience: AUTH0_AUDIENCE,
        scope: 'openid read:current_user offline_access profile',
      }}
      useRefreshTokens={true}
      // リフレッシュトークンが利用できない場合、iframeをバックアップとして使用する
      // これを無効にした場合、リロードするとChromeもSafariと同じように
      // 一度 `/login` にリダイレクトしてから、そのページ内のuseEffectでloginWithRedirectを実行する
      // 参考: https://github.com/auth0/auth0-spa-js/blob/master/FAQ.md#why-am-i-getting-a-missing_refresh_token-error-after-upgrading-to-v2
      useRefreshTokensFallback={true}
      onRedirectCallback={onRedirectCallback}
    >
      <InterceptToken>
        <Outlet />
      </InterceptToken>
    </Auth0Provider>
  );
};
