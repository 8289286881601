import { KiTagListOptions } from '../domain/schemas';

/**
 * 機種タグ設定
 * searchConditionで返却された機種タグの機種リストと選択されたタグに紐づく機種リストのtagId比較、該当機種返却
 * @param kiTagLists
 * @param tagLists
 * @returns string[]
 */
export const retrieveMatchedIdLists = (
  kiTagLists: KiTagListOptions[],
  tagLists: KiTagListOptions[]
): string[] => {
  return kiTagLists
    .filter((kiTagList) =>
      tagLists.some((tagList) => tagList.tagId === kiTagList.tagId)
    )
    .flatMap((matchedItem) => matchedItem.kiList);
};
