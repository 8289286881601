import { endOfWeek, format, startOfWeek } from 'date-fns';

import { pastLimitDate } from './consistent';
import {
  DataSis,
  SettingsOptionsSis,
  SisDateRange,
  SisDateUnit,
} from './types';

/**
 * sisDateRangeTypeからdateRangeUnitに変換する
 */
export const dateRangeTypeToSisDateUnit = (
  dateRangeType: DataSis['setting']['sisDateRangeType']
): SisDateUnit | undefined => {
  if (dateRangeType == null) {
    return undefined;
  }

  return dateRangeType === 'weekly' ? '週' : '日';
};

/**
 * dateRangeUnitからsisDateRangeTypeに変換する
 */
export const sisDateUnitToDateRangeType = (
  dateRangeUnit: SisDateUnit
): NonNullable<DataSis['setting']['sisDateRangeType']> => {
  return dateRangeUnit === '日' ? 'daily' : 'weekly';
};

/**
 * 日が選択されているときのstartDateとendDateを作成する
 */
const makeDailySisDate = (
  target: string | undefined,
  latestBaseDateForDailySisData: string
) => {
  if (target == null) {
    return {
      startDate: latestBaseDateForDailySisData,
      endDate: latestBaseDateForDailySisData,
    };
  }

  if (target < pastLimitDate) {
    return {
      startDate: pastLimitDate,
      endDate: pastLimitDate,
    };
  }

  if (target > latestBaseDateForDailySisData) {
    return {
      startDate: latestBaseDateForDailySisData,
      endDate: latestBaseDateForDailySisData,
    };
  }

  return {
    startDate: target,
    endDate: target,
  };
};

const changeWeeklyDate = (target: string) => {
  const targetDate = new Date(target);

  return {
    startDate: format(
      startOfWeek(targetDate, {
        weekStartsOn: 1,
      }),
      'yyyy-MM-dd'
    ),
    endDate: format(
      endOfWeek(targetDate, {
        weekStartsOn: 1,
      }),
      'yyyy-MM-dd'
    ),
  };
};

/**
 * 週が選択されているときのstartDateとendDateを作成する
 *
 * targetがない場合には最新の日付を基準にする
 */
const makeWeeklySisDate = (
  target: string | undefined,
  latestBaseDateForWeeklySisData: string
) => {
  if (target == null) {
    return changeWeeklyDate(latestBaseDateForWeeklySisData);
  }

  if (target < pastLimitDate) {
    return changeWeeklyDate(pastLimitDate);
  }

  if (target > latestBaseDateForWeeklySisData) {
    return changeWeeklyDate(latestBaseDateForWeeklySisData);
  }

  return changeWeeklyDate(target);
};

/**
 * 特定日を基準としてstartDateとendDateとfutureLimitDateを作成する
 *
 * 週の場合は月曜始まり日曜終わり
 */
export const makeSisDate = (
  target: string | undefined,
  dateUnit: SisDateUnit,
  options: Pick<
    SettingsOptionsSis['searchCondition'],
    'latestBaseDateForDailySisData' | 'latestBaseDateForWeeklySisData'
  >
) => {
  if (dateUnit === '日') {
    return {
      ...makeDailySisDate(target, options.latestBaseDateForDailySisData),
      futureLimitDate: new Date(options.latestBaseDateForDailySisData),
    };
  }

  return {
    ...makeWeeklySisDate(target, options.latestBaseDateForWeeklySisData),
    futureLimitDate: new Date(
      changeWeeklyDate(options.latestBaseDateForWeeklySisData).endDate
    ),
  };
};

/**
 * お気に入り適用時の期間を作成する
 */
export const makeSisFavoriteDate = (
  searchCondition: SettingsOptionsSis['searchCondition'],
  selectedDateRange: SisDateRange,
  sisStartDate: string | undefined
) => {
  if (selectedDateRange === 'カスタム') {
    return sisStartDate;
  } else if (selectedDateRange === '最新日') {
    return searchCondition?.latestBaseDateForDailySisData;
  } else if (selectedDateRange === '最新週') {
    return searchCondition?.latestBaseDateForWeeklySisData;
  }
  return sisStartDate;
};
