import { AxiosResponse } from 'axios';
import { call, fork, put, takeEvery } from 'redux-saga/effects';

import { ModeSettingsOptions } from '../domain/mode/types';

import {
  SettingsOptionsModeActionCreators,
  SettingsOptionsModeActionTypes,
} from '../redux/server/settingsOptionsMode';
import { Api, buildConfig } from '../utils/api';
import { handleErrorSaga } from './errorSaga';

export function* fetchSettingsOptionsModeSaga(api: Api) {
  try {
    yield put(
      SettingsOptionsModeActionCreators.fetchSettingsOptionsModeRequestAction()
    );
    const response: AxiosResponse<ModeSettingsOptions> = yield call(
      api.get,
      '/settings/options/mode',
      buildConfig()
    );

    yield put(
      SettingsOptionsModeActionCreators.fetchSettingsOptionsModeSuccessAction(
        response.data
      )
    );
  } catch (error: unknown) {
    yield put(
      SettingsOptionsModeActionCreators.renewSettingsOptionsModeAction()
    );
    yield fork(handleErrorSaga, error);
  }
}

function* handleFetchSettingsOptionsModeSaga(api: Api) {
  yield takeEvery(
    SettingsOptionsModeActionTypes.FETCH_SETTINGS_OPTIONS_MODE,
    fetchSettingsOptionsModeSaga,
    api
  );
}

export function* settingsOptionsModeSagas(context: { api: Api }) {
  yield fork(handleFetchSettingsOptionsModeSaga, context.api);
}
