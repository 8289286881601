import { AxiosResponse } from 'axios';
import { TakeableChannel } from 'redux-saga';
import {
  ActionChannelEffect,
  actionChannel,
  all,
  call,
  fork,
  put,
  select,
  take,
  takeEvery,
} from 'redux-saga/effects';

import { ChainStoreReportsFavorite } from '../domain/chain/types';
import { ChainReportsFormConditions } from '../domain/chainReportsFormConditions';
import {
  DataChainStoreShu,
  DataChainStoreShuParams,
  DataChainStoreShuSummary,
} from '../domain/dataChainStoreShu';
import { REPORT_MARKING_CONDITIONS } from '../domain/marking';
import { Column } from '../domain/schemas';
import { ChainStoreSearchCondition } from '../domain/settingsOptionsChainStore';
import { ShuOption } from '../domain/shu';

import {
  ChangeDataChainStoreShuColumnsOrderAction,
  DataChainStoreShuActionCreators,
  DataChainStoreShuActionTypes,
  DataChainStoreShuState,
  FetchDataChainStoreShuAction,
  FetchDataChainStoreShuSuccessAction,
  FetchDataChainStoreShuSummaryAction,
  InitDataChainStoreShuAction,
  SearchDataChainStoreShuAction,
  SearchDataChainStoreShuFavoriteAction,
  SearchDataChainStoreShuFieldTypeAction,
  SearchDataChainStoreShuMarkingAction,
  SearchDataChainStoreShuSortAction,
  dataChainStoreShuAllSelector,
  dataChainStoreShuColumnsOrderSelector,
  dataChainStoreShuDataColumnsSelector,
  dataChainStoreShuDataIsExistSelector,
  dataChainStoreShuDataSearchConditionSelector,
  dataChainStoreShuLoadingByShuSelector,
  dataChainStoreShuOrderedData,
  dataChainStoreShuSummaryAllSelector,
} from '../redux/server/dataChainStoreShu';
import { DataChainStoreShuGraphActionCreators } from '../redux/server/dataChainStoreShuGraph';
import { DataChainStoreShuKadoGraphActionCreators } from '../redux/server/dataChainStoreShuKadoGraph';
import { DataChainStoreShuWholeActionCreators } from '../redux/server/dataChainStoreShuWhole';
import { settingsOptionsChainStoreSearchConditionSelector } from '../redux/server/settingsOptionsChainStore';
import {
  ChainStoreSettingActionCreators,
  chainStoreReportsSearchConditionSelector,
  chainStoreReportsSelectedFavoriteSettingSelector,
  chainStoreReportsSelectedShuSelector,
} from '../redux/ui/chainStoreReportsSetting';
import { Api, buildConfig } from '../utils/api';
import { findComparativeSection } from '../utils/findComparativeSection';
import { recalcColumnOrder } from '../utils/orderedCell';
import { changeNameDateUnit } from '../utils/reportsDateRange';
import { getShuOption, selectShu2SearchCondition } from '../utils/shu';
import { searchConditionToDateRangeParams } from './chainStoreReportsSettingSaga';
import { handleErrorSaga } from './errorSaga';

/**
 * チェーン店レポート 種別実績テーブルを取得する
 * @param api AxiosInstance
 * @param action Action
 */
export function* fetchDataChainStoreShuSaga(
  api: Api,
  action: FetchDataChainStoreShuAction
) {
  const { shuCode, params } = action.payload;

  try {
    yield put(
      DataChainStoreShuActionCreators.fetchDataChainStoreShuRequestAction(
        shuCode
      )
    );
    const response: AxiosResponse<DataChainStoreShu> = yield call(
      api.get,
      '/data/chainStore/shu',
      buildConfig(params)
    );
    yield put(
      DataChainStoreShuActionCreators.fetchDataChainStoreShuSuccessAction(
        shuCode,
        response.data
      )
    );
  } catch (error: unknown) {
    yield put(DataChainStoreShuActionCreators.renewDataChainStoreShuAction());
    yield fork(handleErrorSaga, error);
  }
}

function* handleFetchDataChainStoreShuSaga(api: Api) {
  // 表示項目と並び替えのチェック
  yield takeEvery(
    DataChainStoreShuActionTypes.FETCH_DATA_CHAIN_STORE_SHU_SUCCESS,
    columnsOrderCheckSaga
  );

  const chan: ActionChannelEffect = yield actionChannel(
    DataChainStoreShuActionTypes.FETCH_DATA_CHAIN_STORE_SHU
  );
  while (true) {
    const action: FetchDataChainStoreShuAction = yield take(
      (chan as unknown) as TakeableChannel<FetchDataChainStoreShuAction>
    );
    yield call(fetchDataChainStoreShuSaga, api, action);
  }
}

/**
 * チェーン店レポート 種別実績Summaryを取得する
 * @param api AxiosInstance
 * @param action Action
 */
export function* fetchDataChainStoreShuSummarySaga(
  api: Api,
  action: FetchDataChainStoreShuSummaryAction
) {
  const { shuCode, params } = action.payload;

  try {
    yield put(
      DataChainStoreShuActionCreators.fetchDataChainStoreShuSummaryRequestAction(
        shuCode
      )
    );
    const response: AxiosResponse<DataChainStoreShuSummary> = yield call(
      api.get,
      '/data/chainStore/shu/summary',
      buildConfig(params)
    );
    yield put(
      DataChainStoreShuActionCreators.fetchDataChainStoreShuSummarySuccessAction(
        shuCode,
        response.data
      )
    );
  } catch (error: unknown) {
    yield put(DataChainStoreShuActionCreators.renewDataChainStoreShuAction());
    yield fork(handleErrorSaga, error);
  }
}

function* handleFetchDataChainStoreShuSummarySaga(api: Api) {
  const chan: ActionChannelEffect = yield actionChannel(
    DataChainStoreShuActionTypes.FETCH_DATA_CHAIN_STORE_SHU_SUMMARY
  );
  while (true) {
    const action: FetchDataChainStoreShuSummaryAction = yield take(
      (chan as unknown) as TakeableChannel<FetchDataChainStoreShuSummaryAction>
    );
    yield call(fetchDataChainStoreShuSummarySaga, api, action);
  }
}

/**
 * 表示項目が変更されている場合並び替えに反映する
 * @param shuCode 種別コード
 */
function* columnsOrderCheckSaga(action: FetchDataChainStoreShuSuccessAction) {
  const { shuCode } = action.payload;

  const fields: Column[] = yield select(
    dataChainStoreShuDataColumnsSelector(shuCode)
  );

  const fieldCodes = fields.map((field) => field.code);

  // テーブル列の並び順
  let columnsOrder: string[] = yield select(
    dataChainStoreShuColumnsOrderSelector(shuCode)
  );

  if (!columnsOrder || columnsOrder.length === 0) {
    // ソート順が設定されていない場合、初期配置をデフォルトで設定する
    columnsOrder = fields.map((column) => column.code);
  }

  const sorted = [...fieldCodes].sort((a, b) => {
    // 店舗名は常に先頭
    if (b === 'hlMei') {
      return 1;
    }
    return columnsOrder.indexOf(b) > columnsOrder.indexOf(a) ? -1 : 1;
  });

  yield put(
    DataChainStoreShuActionCreators.selectDataChainStoreShuColumnsOrderAction(
      shuCode,
      sorted
    )
  );
}

/**
 * 初回取得の処理（初回取得・リセット時共通）
 * @param shuOption 種別
 */
function* firstFetchDataChainStoreShuSaga(shuOption: ShuOption) {
  const shuCode = shuOption.code;

  // 現在の検索フォームの検索条件
  const searchFormCondition: ChainReportsFormConditions = yield select(
    chainStoreReportsSearchConditionSelector
  );
  // 現在選択中のお気に入りのチェーン店レポートの各種データ
  const chainStoreReportsFavorite: ChainStoreReportsFavorite = yield select(
    chainStoreReportsSelectedFavoriteSettingSelector
  );

  // お気に入りがデフォルトの時
  if (!chainStoreReportsFavorite?.dataChainStoreShu?.setting[shuCode]) {
    // 種別実績テーブルデータ取得
    yield put(
      DataChainStoreShuActionCreators.fetchDataChainStoreShuAction(shuCode, {
        ...searchFormCondition,
        ...selectShu2SearchCondition(shuOption),
      })
    );
    // 種別実績Summaryデータ取得
    yield put(
      DataChainStoreShuActionCreators.fetchDataChainStoreShuSummaryAction(
        shuCode,
        {
          ...searchFormCondition,
          ...selectShu2SearchCondition(shuOption),
        }
      )
    );

    return;
  }

  // 過去のお気に入りを考慮してデフォルト値を設定する
  const comparativeSection = findComparativeSection(
    chainStoreReportsFavorite.selectedDateRangeParams.dateRange,
    chainStoreReportsFavorite?.selectedComparativeSection
  );

  // お気に入りの検索条件を適用
  // MEMO: 種別実績テーブルのお気に入り適用はIntersectionObserverでIntersectingしたときに取得する仕様のため、ここでお気に入り適用している

  // 期間を再計算した検索条件
  const recalcSearchCondition = searchConditionToDateRangeParams(
    chainStoreReportsFavorite.selectedDateRangeParams.dateRange,
    // ボタン名称が古いお気に入りを変換
    changeNameDateUnit(
      chainStoreReportsFavorite.selectedDateRangeParams.dateUnit
    ),
    chainStoreReportsFavorite.selectedDateRangeParams.isComparison,
    chainStoreReportsFavorite.dataChainStoreShu.setting[shuOption.code],
    comparativeSection
  );

  //ymdList対応以前に作成したお気に入りを考慮
  const {
    startDate,
    endDate,
    startComparisonDate,
    endComparisonDate,
    ...rest
  } = chainStoreReportsFavorite.dataChainStoreShu.setting[shuCode];

  const { startDate: startDate2, endDate: endDate2, ...rest2 } =
    chainStoreReportsFavorite?.dataChainStoreShuSummary?.setting[shuCode] ?? {};

  // 種別実績テーブルデータ取得
  yield put(
    DataChainStoreShuActionCreators.fetchDataChainStoreShuAction(shuCode, {
      ...rest,
      ...recalcSearchCondition,
    })
  );

  // 種別実績Summaryデータ取得
  yield put(
    DataChainStoreShuActionCreators.fetchDataChainStoreShuSummaryAction(
      shuCode,
      {
        ...rest2,
        ymdList: recalcSearchCondition.ymdList,
      }
    )
  );
}

/**
 * チェーン店レポート 種別実績初回取得時処理
 */
function* initDataChainStoreShuSaga(action: InitDataChainStoreShuAction) {
  const { shuOption } = action.payload;
  const shuCode = shuOption.code;

  // ローディング状態
  const isLoading: boolean = yield select(
    dataChainStoreShuLoadingByShuSelector(shuCode)
  );
  // データが存在する
  const isExist: boolean = yield select(
    dataChainStoreShuDataIsExistSelector(shuCode)
  );

  // データが存在する・ローディング中・エラー時の時は初回取得を行わない
  if (isExist || isLoading) return;

  // 初回取得
  yield fork(firstFetchDataChainStoreShuSaga, shuOption);
}
/**
 * お気に入りでのデータ取得の処理
 * @param shuOption 種別
 * @param favorite お気に入りデータ
 */
function* favoriteFetchDataChainStoreShuSaga(
  shuOption: ShuOption,
  favorite: ChainStoreReportsFavorite
) {
  const shuCode = shuOption.code;

  // 現在の検索フォームの検索条件
  const searchFormCondition: ChainReportsFormConditions = yield select(
    chainStoreReportsSearchConditionSelector
  );

  // お気に入りがデフォルトの時
  if (!favorite?.dataChainStoreShu?.setting[shuCode]) {
    // 種別実績テーブルデータ取得
    yield put(
      DataChainStoreShuActionCreators.fetchDataChainStoreShuAction(shuCode, {
        ...searchFormCondition,
        ...selectShu2SearchCondition(shuOption),
      })
    );
    // 種別実績Summaryデータ取得
    yield put(
      DataChainStoreShuActionCreators.fetchDataChainStoreShuSummaryAction(
        shuCode,
        {
          ...searchFormCondition,
          ...selectShu2SearchCondition(shuOption),
        }
      )
    );

    return;
  }

  // 過去のお気に入りを考慮してデフォルト値を設定する
  const comparativeSection = findComparativeSection(
    favorite.selectedDateRangeParams.dateRange,
    favorite?.selectedComparativeSection
  );

  // お気に入りの検索条件を適用
  // MEMO: 種別実績テーブルのお気に入り適用はIntersectionObserverでIntersectingしたときに取得する仕様のため、ここでお気に入り適用している

  // 期間を再計算した検索条件
  const recalcSearchCondition = searchConditionToDateRangeParams(
    favorite.selectedDateRangeParams.dateRange,
    // ボタン名称が古いお気に入りを変換
    changeNameDateUnit(favorite.selectedDateRangeParams.dateUnit),
    favorite.selectedDateRangeParams.isComparison,
    favorite.dataChainStoreShu.setting[shuOption.code],
    comparativeSection
  );

  //ymdList対応以前に作成したお気に入りを考慮
  const {
    startDate,
    endDate,
    startComparisonDate,
    endComparisonDate,
    ...rest
  } = favorite.dataChainStoreShu.setting[shuCode];

  const { startDate: startDate2, endDate: endDate2, ...rest2 } =
    favorite?.dataChainStoreShuSummary?.setting[shuCode] ?? {};

  // 種別実績テーブルデータ取得
  yield put(
    DataChainStoreShuActionCreators.fetchDataChainStoreShuAction(shuCode, {
      ...rest,
      ...recalcSearchCondition,
    })
  );

  // 種別実績Summaryデータ取得
  yield put(
    DataChainStoreShuActionCreators.fetchDataChainStoreShuSummaryAction(
      shuCode,
      {
        ...rest2,
        ymdList: recalcSearchCondition.ymdList,
      }
    )
  );
}

/**
 * チェーン店レポート 種別実績 検索ボタン押下時に行う処理
 */
function* searchDataChainStoreShuSaga(action: SearchDataChainStoreShuAction) {
  // 現在のテーブルデータを全て取得する
  const dataChainStoreShu: DataChainStoreShuState['dataChainStoreShu'] = yield select(
    dataChainStoreShuAllSelector
  );
  // 現在のSummaryデータを全て取得する
  const dataChainStoreShuSummary: DataChainStoreShuState['dataChainStoreShuSummary'] = yield select(
    dataChainStoreShuSummaryAllSelector
  );

  // 検索フォームで選択中の種別を取得する
  const selectedShu: ShuOption[] = yield select(
    chainStoreReportsSelectedShuSelector
  );

  // データがあるテーブルのみ検索条件を更新する
  // MEMO: 前回のテーブル固有の検索条件（ソート・表示項目）を引き継ぐためこのようにしている
  // リセットと同じ挙動をすると検索条件を引き継げないため
  const fetchListShu = Object.keys(dataChainStoreShu)
    .filter((key) => !!dataChainStoreShu[key])
    .map((key) => {
      const item = dataChainStoreShu[key];
      // 全選択の場合または選択している種別に含まれる場合はデータを再取得する
      if (!selectedShu.length || selectedShu.some((shu) => shu.code === key)) {
        return put(
          DataChainStoreShuActionCreators.fetchDataChainStoreShuAction(key, {
            // MEMO: 取得済みの検索条件に種別を除いた検索フォームの検索条件で上書きする
            ...(item?.setting ?? {}),
            areas: action.payload.params.areas,
            halls: action.payload.params.halls,
            ymdList: action.payload.params.ymdList,
            ymdComparisonList: action.payload.params.ymdComparisonList,
            excludeToday: action.payload.params.excludeToday,
            containsAreaAverage: action.payload.params.containsAreaAverage,
          })
        );
      }

      // 検索フォーム変更後に種別が含まれない場合はデータを削除する
      return put(
        DataChainStoreShuActionCreators.clearDataChainStoreShuAction(key)
      );
    });

  const fetchListSummary = Object.keys(dataChainStoreShuSummary)
    .filter((key) => !!dataChainStoreShuSummary[key])
    .map((key) => {
      // 全選択の場合または選択している種別に含まれる場合はデータを再取得する
      if (!selectedShu.length || selectedShu.some((shu) => shu.code === key)) {
        const summary = dataChainStoreShuSummary[key];
        return put(
          DataChainStoreShuActionCreators.fetchDataChainStoreShuSummaryAction(
            key,
            {
              // MEMO: 取得済みの検索条件に種別を除いた検索フォームの検索条件で上書きする
              ...(summary?.setting ?? {}),
              areas: action.payload.params.areas,
              halls: action.payload.params.halls,
              ymdList: action.payload.params.ymdList,
              excludeToday: action.payload.params.excludeToday,
            }
          )
        );
      }

      // 検索フォーム変更後に種別が含まれない場合はデータを削除する
      return put(
        DataChainStoreShuActionCreators.clearDataChainStoreShuSummaryAction(key)
      );
    });

  //検索時一旦全てのデータを破棄する、理由は順次リクエストなので下の方の種別グループだとリクエストが飛んだ後の結果なのかこれから飛ぶのかがわからない＝今見えているデータがどちらかユーザーが判別つかないから。テーブルごとにリクエストしている間はローディングを出しておく実装に変更すれば破棄の必要はなくなる。（参照：https://daikoku-cloud.slack.com/archives/C026YMFKL8L/p1681719527726629）
  // ただし日付スライダー利用時などで並び順が保持されなくなるため、カラムの並び順は保持する
  yield put(
    DataChainStoreShuActionCreators.clearDataChainStoreShuActionWithoutColumnOrderByShuCodes(
      selectedShu.map((shu) => shu.code)
    )
  );

  // データ取得
  yield all([...fetchListShu, ...fetchListSummary]);
}

/**
 * チェーン店レポート 種別実績 リセットボタン押下時に行う処理
 */
function* searchDataChainStoreShuResetSaga() {
  // 検索条件項目を取得
  const settingsOptions: ChainStoreSearchCondition = yield select(
    settingsOptionsChainStoreSearchConditionSelector
  );

  // 検索フォームで選択中の種別を取得する
  const selectedShu: ShuOption[] = yield select(
    chainStoreReportsSelectedShuSelector
  );

  // 現在のテーブルデータを全て取得する
  const dataChainStoreShu: DataChainStoreShuState['dataChainStoreShu'] = yield select(
    dataChainStoreShuAllSelector
  );

  // ShuOptionsを取得
  const shuOptions = getShuOption(settingsOptions);

  // Fetch対象のShuOption
  const reFetchList = shuOptions.filter((shuOption) =>
    Object.keys(dataChainStoreShu)
      .filter((key) => dataChainStoreShu[key] !== undefined) // 明示的に破棄しているテーブルは除外
      .includes(shuOption.code)
  );

  // 初回条件で表示中のテーブルを再取得
  const forkEffects = reFetchList.map((shuOption) => {
    // 選択された種別または全選択の場合初回取得を行う
    if (
      !selectedShu.length ||
      selectedShu.some((shu) => shu.code === shuOption.code)
    ) {
      return fork(firstFetchDataChainStoreShuSaga, shuOption);
    }

    // 選択された種別に該当しない種別のテーブルデータを削除
    return put(
      DataChainStoreShuActionCreators.clearDataChainStoreShuAction(
        shuOption.code
      )
    );
  });

  //検索時一旦全てのデータを破棄する、理由は順次リクエストなので下の方の種別グループだとリクエストが飛んだ後の結果なのかこれから飛ぶのかがわからない＝今見えているデータがどちらかユーザーが判別つかないから。テーブルごとにリクエストしている間はローディングを出しておく実装に変更すれば破棄の必要はなくなる。（参照：https://daikoku-cloud.slack.com/archives/C026YMFKL8L/p1681719527726629）
  yield put(DataChainStoreShuActionCreators.clearAllDataChainStoreShuAction());

  yield all(forkEffects);
}

/**
 * チェーン店レポート 種別実績 お気に入り適用時に行う処理
 */
function* searchDataChainStoreShuFavoriteSaga(
  action: SearchDataChainStoreShuFavoriteAction
) {
  // 検索条件項目を取得
  const settingsOptions: ChainStoreSearchCondition = yield select(
    settingsOptionsChainStoreSearchConditionSelector
  );

  // 検索フォームで選択中の種別を取得する
  const selectedShu: ShuOption[] = yield select(
    chainStoreReportsSelectedShuSelector
  );

  // ShuOptionsを取得
  const shuOptions =
    selectedShu.length > 0 ? selectedShu : getShuOption(settingsOptions);

  // テーブルを取得
  const forkEffects = shuOptions.map((shuOption) => {
    return fork(
      favoriteFetchDataChainStoreShuSaga,
      shuOption,
      action.payload.favorite
    );
  });

  yield all(forkEffects);
}

/**
 * 指定したソート条件の種別実績テーブルデータを取得する
 */
function* searchDataChainStoreShuSortSaga(
  action: SearchDataChainStoreShuSortAction
) {
  const { shuOption, sort, order } = action.payload;
  const shuCode = shuOption.code;

  // 現在の検索条件
  const searchCondition: DataChainStoreShuParams = yield select(
    dataChainStoreShuDataSearchConditionSelector(shuCode)
  );

  // 種別実績テーブルを再取得
  yield put(
    DataChainStoreShuActionCreators.fetchDataChainStoreShuAction(shuCode, {
      ...searchCondition,
      sort,
      order,
    })
  );
}

/**
 * 指定したマーキング条件の種別実績テーブルデータを取得する
 */
function* searchDataChainStoreShuMarkingSaga(
  action: SearchDataChainStoreShuMarkingAction
) {
  const { shuOption, markingOption, isFiltering } = action.payload;
  const shuCode = shuOption.code;

  // 現在の検索条件
  const searchCondition: DataChainStoreShuParams = yield select(
    dataChainStoreShuDataSearchConditionSelector(shuCode)
  );

  // 種別実績テーブルを再取得
  yield put(
    DataChainStoreShuActionCreators.fetchDataChainStoreShuAction(shuCode, {
      ...searchCondition,
      marking:
        markingOption.code === REPORT_MARKING_CONDITIONS.at(0)?.code
          ? undefined
          : markingOption.code,
      isFiltering,
    })
  );
}

/**
 * 指定した表示項目の種別実績テーブルデータを取得する
 */
function* searchDataChainStoreShuFieldTypeSaga(
  action: SearchDataChainStoreShuFieldTypeAction
) {
  const { shuOption, fields } = action.payload;
  const shuCode = shuOption.code;

  // 現在の検索条件
  const searchCondition: DataChainStoreShuParams = yield select(
    dataChainStoreShuDataSearchConditionSelector(shuCode)
  );

  // 種別実績テーブルを再取得
  yield put(
    DataChainStoreShuActionCreators.fetchDataChainStoreShuAction(shuCode, {
      ...searchCondition,
      fields: fields.map((field) => field.code),
    })
  );
}

function* toggleDataChainStoreShuContainsAreaAverageSaga() {
  const formCondition: ChainReportsFormConditions = yield select(
    chainStoreReportsSearchConditionSelector
  );
  const updatedFormCondition: ChainReportsFormConditions = {
    ...formCondition,
    containsAreaAverage: !formCondition.containsAreaAverage,
  };

  if (!updatedFormCondition.containsAreaAverage) {
    yield put(
      DataChainStoreShuGraphActionCreators.hideAllDataChainStoreShuGraphAction()
    );
    yield put(
      DataChainStoreShuKadoGraphActionCreators.hideAllDataChainStoreShuKadoGraphAction()
    );
  }

  yield put(
    ChainStoreSettingActionCreators.selectChainStoreReportsContainsAreaAverage(
      !!updatedFormCondition.containsAreaAverage
    )
  );
  yield put(
    DataChainStoreShuWholeActionCreators.searchDataChainStoreShuWholeAction(
      updatedFormCondition
    )
  );
  yield put(
    DataChainStoreShuActionCreators.searchDataChainStoreShuAction(
      updatedFormCondition
    )
  );
}

/**
 * ドラッグ＆ドロップしたセルのIDを元に並び替え情報を登録する
 */
function* changeDataChainStoreShuColumnsOrderSaga(
  action: ChangeDataChainStoreShuColumnsOrderAction
) {
  const tableData: DataChainStoreShu['data'] = yield select(
    dataChainStoreShuOrderedData(action.payload.shuCode)
  );

  const ordered = recalcColumnOrder(
    tableData.columns,
    action.payload.draggedId,
    action.payload.droppedId
  );

  yield put(
    DataChainStoreShuActionCreators.selectDataChainStoreShuColumnsOrderAction(
      action.payload.shuCode,
      ordered
    )
  );
}

/**
 * 検索関連のSaga
 */
function* handleSearchSaga() {
  // 初回取得
  yield takeEvery(
    DataChainStoreShuActionTypes.INIT_DATA_CHAIN_STORE_SHU,
    initDataChainStoreShuSaga
  );
  // 検索時
  yield takeEvery(
    DataChainStoreShuActionTypes.SEARCH_DATA_CHAIN_STORE_SHU,
    searchDataChainStoreShuSaga
  );
  // リセット時
  yield takeEvery(
    DataChainStoreShuActionTypes.SEARCH_DATA_CHAIN_STORE_SHU_RESET,
    searchDataChainStoreShuResetSaga
  );
  // お気に入り時
  yield takeEvery(
    DataChainStoreShuActionTypes.SEARCH_DATA_CHAIN_STORE_SHU_FAVORITE,
    searchDataChainStoreShuFavoriteSaga
  );

  // ソート
  yield takeEvery(
    DataChainStoreShuActionTypes.SEARCH_DATA_CHAIN_STORE_SHU_SORT,
    searchDataChainStoreShuSortSaga
  );
  // マーキング
  yield takeEvery(
    DataChainStoreShuActionTypes.SEARCH_DATA_CHAIN_STORE_SHU_MARKING,
    searchDataChainStoreShuMarkingSaga
  );
  // 表示項目
  yield takeEvery(
    DataChainStoreShuActionTypes.SEARCH_DATA_CHAIN_STORE_SHU_FIELD_TYPE,
    searchDataChainStoreShuFieldTypeSaga
  );
  // 並び替え
  yield takeEvery(
    DataChainStoreShuActionTypes.CHANGE_DATA_CHAIN_STORE_SHU_COLUMNS_ORDER,
    changeDataChainStoreShuColumnsOrderSaga
  );
  // エリア集計
  yield takeEvery(
    DataChainStoreShuActionTypes.TOGGLE_DATA_CHAIN_STORE_SHU_CONTAINS_AREA_AVERAGE,
    toggleDataChainStoreShuContainsAreaAverageSaga
  );
}

/**
 * 種別集計データに関するタスクを実行する
 * @param context AxiosInstance
 */
export function* dataChainStoreShuSagas(context: { api: Api }) {
  yield fork(handleFetchDataChainStoreShuSaga, context.api);
  yield fork(handleFetchDataChainStoreShuSummarySaga, context.api);
  yield fork(handleSearchSaga);
}
