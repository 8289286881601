import { AxiosResponse } from 'axios';
import { call, fork, put, takeLatest } from 'redux-saga/effects';

import { DataHallMksSummary, DataHallMksTable } from '../domain/dataHallMks';
import { GraphData } from '../domain/schemas';

import { DataHallDailyCommentsActionCreators } from '../redux/server/dataHallDailyComments';
import {
  DataHallMksActionCreators,
  DataHallMksActionTypes,
  FetchDataHallMksComparisonGraphRequestAction,
  FetchDataHallMksSummaryRequestAction,
  FetchDataHallMksTableRequestAction,
  FetchDataHallMksTransitionGraphRequestAction,
  fetchDataHallMksComparisonGraphSuccessAction,
  fetchDataHallMksSummarySuccessAction,
  fetchDataHallMksTableSuccessAction,
  fetchDataHallMksTransitionGraphSuccessAction,
} from '../redux/server/dataHallMks';
import { selectMksTableColumnsOrderAction } from '../redux/ui/hallReportsSetting';
import { Api, buildConfig } from '../utils/api';
import { handleErrorSaga } from './errorSaga';

const GRAPH_TYPE_COMPARISON = 'comparison' as const;
const GRAPH_TYPE_TRANSITION = 'transition' as const;
type GraphType = typeof GRAPH_TYPE_COMPARISON | typeof GRAPH_TYPE_TRANSITION;

export function fetchDataHallMksGraphSaga(type: GraphType) {
  return function* (
    api: Api,
    action:
      | FetchDataHallMksComparisonGraphRequestAction
      | FetchDataHallMksTransitionGraphRequestAction
  ) {
    try {
      const response: AxiosResponse<{ data: GraphData }> = yield call(
        api.get,
        type === 'comparison'
          ? '/data/hall/marketArea/graph'
          : '/data/hall/marketArea/transitiveGraph',
        buildConfig(action.payload)
      );

      yield type === 'comparison'
        ? put(fetchDataHallMksComparisonGraphSuccessAction(response.data.data))
        : put(fetchDataHallMksTransitionGraphSuccessAction(response.data.data));
    } catch (error: unknown) {
      yield put(DataHallMksActionCreators.renewDataHallMks());
      yield fork(handleErrorSaga, error);
    }
  };
}

export function* fetchDataHallMksSummarySaga(
  api: Api,
  action: FetchDataHallMksSummaryRequestAction
) {
  try {
    const response: AxiosResponse<DataHallMksSummary> = yield call(
      api.get,
      '/data/hall/marketArea/summary',
      buildConfig(action.payload)
    );

    yield put(fetchDataHallMksSummarySuccessAction(response.data.data));

    if (
      response?.data?.setting?.ymdList &&
      response.data.setting.ymdList.length > 0
    ) {
      yield put(
        DataHallDailyCommentsActionCreators.fetchDataHallDailyCommentsRequestAction(
          response.data.setting.ymdList[0]
        )
      );
    }
  } catch (error: unknown) {
    yield put(DataHallMksActionCreators.renewDataHallMks());
    yield fork(handleErrorSaga, error);
  }
}

export function* fetchDataHallMksTableSaga(
  api: Api,
  action: FetchDataHallMksTableRequestAction
) {
  try {
    const response: AxiosResponse<DataHallMksTable> = yield call(
      api.get,
      '/data/hall/marketArea',
      buildConfig(action.payload)
    );
    yield put(selectMksTableColumnsOrderAction(response.data.data.columns));
    yield put(fetchDataHallMksTableSuccessAction(response.data));
  } catch (error: unknown) {
    yield put(DataHallMksActionCreators.renewDataHallMks());
    yield fork(handleErrorSaga, error);
  }
}

/**
 *
 * FETCH_DATA_HALL_MKS_COMPARISON_GRAPH_REQUESTがDispatchされた時の処理
 */
function* handleChangeDataHallMksComparisonGraphSaga(api: Api) {
  yield takeLatest(
    DataHallMksActionTypes.FETCH_DATA_HALL_MKS_COMPARISON_GRAPH_REQUEST,
    fetchDataHallMksGraphSaga('comparison'),
    api
  );
}

/**
 * FETCH_DATA_HALL_MKS_COMPARISON_GRAPH_REQUESTがDispatchされた時の処理
 */
function* handleChangeDataHallMksTransitionGraphSaga(api: Api) {
  yield takeLatest(
    DataHallMksActionTypes.FETCH_DATA_HALL_MKS_TRANSITION_GRAPH_REQUEST,
    fetchDataHallMksGraphSaga('transition'),
    api
  );
}

/**
 * FETCH_DATA_HALL_MKS_SUMMARY_REQUESTがDispatchされた時の処理
 */
function* handleChangeDataHallMksSummarySaga(api: Api) {
  yield takeLatest(
    DataHallMksActionTypes.FETCH_DATA_HALL_MKS_SUMMARY_REQUEST,
    fetchDataHallMksSummarySaga,
    api
  );
}

/**
 * SEARCH_DATA_HALL_MKS_TABLE_SORTがDispatchされた時の処理
 */
function* handleChangeDataHallMksTableSaga(api: Api) {
  yield takeLatest(
    DataHallMksActionTypes.FETCH_DATA_HALL_MKS_TABLE_REQUEST,
    fetchDataHallMksTableSaga,
    api
  );
}

/**
 * 店舗レポート 商圏実績に関するタスクを実行する
 * @param context AxiosInstance
 */
export function* dataHallMksSaga(context: { api: Api }) {
  yield fork(handleChangeDataHallMksComparisonGraphSaga, context.api);
  yield fork(handleChangeDataHallMksTransitionGraphSaga, context.api);
  yield fork(handleChangeDataHallMksSummarySaga, context.api);
  yield fork(handleChangeDataHallMksTableSaga, context.api);
}
