import { format, isAfter } from 'date-fns';
import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';

import { FavoriteItem } from '../domain/favorites';
import { MODEL_REPORT_MARKING_CONDITIONS } from '../domain/marking';
import { Column, SearchCondition } from '../domain/schemas';
import { ShuOption } from '../domain/shu';
import {
  DataTransitionAfterIntroduction,
  DataTransitionAfterIntroductionGraphParams,
  DataTransitionAfterIntroductionParams,
  SettingsOptionsTransitionAfterIntroduction,
  TransitionAfterIntroductionFavorite,
  TransitionAfterIntroductionReportsSettingDateRangeParams,
} from '../domain/transitionAfterIntroduction/types';

import { SIS_TYPE_UPDATE_DATES } from '../consistents';
import {
  DataTransitionAfterIntroductionActionCreators,
  dataTransitionAfterIntroductionColumnsForNonTransitionSelector,
  dataTransitionAfterIntroductionColumnsForTransitionSelector,
  dataTransitionAfterIntroductionSelector,
  dataTransitionAfterIntroductionSettingsSelector,
} from '../redux/server/dataTransitionAfterIntroduction';
import {
  DataTransitionAfterIntroduction2ndRowActionCreators,
  dataTransitionAfterIntroduction2ndRowSettingsSelector,
} from '../redux/server/dataTransitionAfterIntroduction2ndRow';
import {
  DataTransitionAfterIntroductionKiGraphActionCreators,
  dataTransitionAfterIntroductionKiGraphSelectedKiListSelector,
  dataTransitionAfterIntroductionKiGraphSettingSelector,
} from '../redux/server/dataTransitionAfterIntroductionKiGraph';
import {
  settingsOptionsTransitionAfterIntroductionFieldsSelector,
  settingsOptionsTransitionAfterIntroductionSearchConditionSelector,
} from '../redux/server/settingsOptionsTransitionAfterIntroduction';
import { ShortenedUrlActionCreators } from '../redux/server/shortenedUrl';
import { ErrorActionCreators } from '../redux/ui/error';
import { SettingsFavoritesActionCreators } from '../redux/ui/settingsFavorites';
import {
  ChangeTransitionAfterIntroductionReportsCurrentHallsAction,
  ChangeTransitionAfterIntroductionReportsCurrentShuAction,
  CreateTransitionAfterIntroductionReportsShortenedUrlAction,
  SaveTransitionAfterIntroductionReportsFavorite,
  SearchTransitionAfterIntroductionReportsAction,
  SearchTransitionAfterIntroductionReportsFieldsAction,
  SearchTransitionAfterIntroductionReportsMarkingAction,
  SearchTransitionAfterIntroductionReportsSortForHeaderAction,
  SearchTransitionAfterIntroductionReportsSortForTransitiveFieldAction,
  TransitionAfterIntroductionReportsSettingActionCreators,
  TransitionAfterIntroductionReportsSettingActionTypes,
  TransitionAfterIntroductionReportsSettingState,
  selectTransitionAfterIntroductionCheckedKiAction,
  selectTransitionAfterIntroductionCheckedKiListTableAction,
  selectTransitionAfterIntroductionReportsColumnsForNonTransitionAction,
  selectTransitionAfterIntroductionReportsColumnsForTransitionAction,
  selectTransitionAfterIntroductionReportsModelNameFilterAction,
  transitionAfterIntroductionCurrentFieldSelector,
  transitionAfterIntroductionReportsCheckedKiListSelector,
  transitionAfterIntroductionReportsCheckedKiListTableSelector,
  transitionAfterIntroductionReportsColumnsForNonTransitionOrderSelector,
  transitionAfterIntroductionReportsColumnsForTransitionOrderSelector,
  transitionAfterIntroductionReportsModelNameFilterSelector,
  transitionAfterIntroductionReportsSearchConditionSelector,
  transitionAfterIntroductionReportsSelectedCurrentShuSelector,
  transitionAfterIntroductionReportsSelectedDateRangeParamsSelector,
  transitionAfterIntroductionReportsSelectedFavoriteDataSelector,
  transitionAfterIntroductionReportsSelectedFavoriteIdSelector,
  transitionAfterIntroductionReportsSelectedFavoritePageSettingSelector,
  transitionAfterIntroductionReportsSelectedFavoriteSelector,
  transitionAfterIntroductionReportsSettingCurrentHallsSelector,
  transitionAfterIntroductionReportsSettingIsHeatmapEnabledSelector,
  transitionAfterIntroductionReportsSettingSelector,
  transitionAfterIntroductionReportsTableFilterSelector,
} from '../redux/ui/transitionAfterIntroductionReportsSetting';
import { compressToEncodedURIComponent } from '../utils/compressToEncodedURIComponent';
import { gaFavoriteEvent } from '../utils/googleAnalytics';
import { isValidArea } from '../utils/isValidArea';
import {
  checkCurrentShu,
  getShuOption,
  selectShus2HallReportSearchCondition,
} from '../utils/shu';
import { sortBy } from '../utils/sortBy';
import { sortFields } from '../utils/sortFields';
import { makeDateFromDateRange } from '../utils/transitionAfterIntroductionDateRange';
import { updateSisTypesV1 } from '../utils/updateSisTypes';

/**
 * 選択中の種別の情報を元に検索条件を加工する
 * @param currentShu 選択中の種別の情報
 * @param searchParams 期間推移の検索条件
 * @returns 加工された検索条件
 */
export const queryParameterToSearchParams = (
  currentShu: ShuOption,
  searchParams: DataTransitionAfterIntroductionParams
): DataTransitionAfterIntroductionParams => {
  const selectedShuParams = selectShus2HallReportSearchCondition([currentShu]);
  // 必要に応じて検索パラメータを加工する
  return {
    ...searchParams,
    // 選択中の種別の情報を加える
    ...selectedShuParams,
    // MEMO: ForSijiritu 系のパラメータは検索条件で指定されているものを分母として渡す
    ...{
      kiListForSijiritu: searchParams.kiList,
    },
  };
};

// 検索条件に非推移項目があるかどうか判定する
export const isExistNonTransitiveField = (
  searchCondition: DataTransitionAfterIntroductionParams
) => {
  return searchCondition.nonTransitiveFields !== undefined
    ? searchCondition.nonTransitiveFields.length === 0
      ? false
      : true
    : false;
};

/**
 * お気に入り選択時、データを取得する
 */
function* applyFavoriteByIdSaga() {
  const favoriteItem: FavoriteItem | undefined = yield select(
    transitionAfterIntroductionReportsSelectedFavoriteDataSelector
  );
  // 現在選択中のお気に入りデータを取得
  const favorite:
    | TransitionAfterIntroductionFavorite
    | undefined = yield select(
    transitionAfterIntroductionReportsSelectedFavoritePageSettingSelector
  );

  // デフォルトのお気に入りを選択した場合、リセットと同じ動作を行う
  if (favorite === undefined || favoriteItem === undefined) {
    yield put(
      TransitionAfterIntroductionReportsSettingActionCreators.searchResetTransitionAfterIntroductionReportsAction()
    );
    yield put(
      TransitionAfterIntroductionReportsSettingActionCreators.resetTransitionAfterIntroductionReportsTableFilterAction()
    );
    return;
  }

  const lastUpdateAt = favoriteItem.updatedAt ?? favoriteItem.createdAt;
  if (lastUpdateAt == null) {
    throw new Error('お気に入りの作成日が存在しません');
  }

  // 導入後推移のデータを初期化する
  yield fork(initTransitionAfterIntroductionReportsDataSaga);

  yield fork(applyFavoriteSaga, favorite, lastUpdateAt);
}

/**
 * TransitionAfterIntroductionFavoriteの内容を実際に反映させる
 */
export function* applyFavoriteSaga(
  favorite: TransitionAfterIntroductionFavorite,
  lastUpdatedAt: string
) {
  // 選択中の種別に反映
  yield put(
    TransitionAfterIntroductionReportsSettingActionCreators.selectTransitionAfterIntroductionReportsCurrentShuAction(
      favorite.currentShu
    )
  );
  // 選択中の種別一覧に反映
  yield put(
    TransitionAfterIntroductionReportsSettingActionCreators.selectTransitionAfterIntroductionReportsShuAction(
      favorite.selectedShu
    )
  );
  // 選択中の機種リストに反映
  yield put(
    TransitionAfterIntroductionReportsSettingActionCreators.selectTransitionAfterIntroductionReportsKiListAction(
      favorite.kiList
    )
  );

  // dateType・dateRange（選択中の日付タイプ・期間）を更新する
  yield put(
    TransitionAfterIntroductionReportsSettingActionCreators.selectTransitionAfterIntroductionReportsDateRangeParamsAction(
      favorite.selectedDateRangeParams.dateRange
    )
  );

  yield put(
    TransitionAfterIntroductionReportsSettingActionCreators.selectTransitionAfterIntroductionCurrentFieldAction(
      favorite.selectedCurrentFieldCode ?? 'all'
    )
  );

  // 期間を元にstartDateとendDateを算出
  let date: ReturnType<typeof makeDateFromDateRange> | undefined = undefined;

  const dateRange = favorite.selectedDateRangeParams.dateRange;
  // 期間指定がカスタムの場合は現在の検索パラメータの値を送る
  if (dateRange !== 'カスタム') {
    // 指定された期間から日付を算出する
    date = makeDateFromDateRange(dateRange);
  }

  // 期間を再算出して上書きする
  // dateが算出されていない場合はカスタムと判断し、保持されている検索パラメータの値をそのまま送る
  const startDate =
    date !== undefined
      ? format(date.startDate, 'yyyy-MM-dd')
      : favorite.searchCondition.startDate;
  const endDate =
    date !== undefined
      ? format(date.endDate, 'yyyy-MM-dd')
      : favorite.searchCondition.endDate;

  if (!isValidArea(favorite.searchCondition.areas)) {
    yield put(
      ErrorActionCreators.setError(
        `お気に入りの検索条件に存在しないエリアが指定されています。\n正しいエリアを選択して検索を再度行い、お気に入りに保存してください。`
      )
    );
  }
  // 古いお気に入りのSISタイプの変更

  const sisTypes = isAfter(
    new Date(lastUpdatedAt),
    new Date(SIS_TYPE_UPDATE_DATES.v1)
  )
    ? favorite.searchCondition.sisTypes
    : updateSisTypesV1(favorite.searchCondition.sisTypes);

  // 選択中の検索条件に反映
  yield put(
    TransitionAfterIntroductionReportsSettingActionCreators.selectTransitionAfterIntroductionReportsSearchConditionAction(
      {
        ...favorite.searchCondition,
        startDate,
        endDate,
        ...(sisTypes ? { sisTypes } : {}),
      }
    )
  );

  // 機種名フィルタを変更
  yield put(
    selectTransitionAfterIntroductionReportsModelNameFilterAction(
      favorite.modelNameFilter
    )
  );

  // 店舗選択スライダーの店舗を反映
  yield put(
    TransitionAfterIntroductionReportsSettingActionCreators.selectTransitionAfterIntroductionReportsSelectedHallsAction(
      favorite.selectedHalls ??
        favorite.dataTransitionAfterIntroduction?.halls ??
        []
    )
  );

  // 店舗選択スライダーで選択中の店舗を反映
  yield put(
    TransitionAfterIntroductionReportsSettingActionCreators.selectTransitionAfterIntroductionReportsCurrentHallsAction(
      favorite.currentHalls ??
        favorite.dataTransitionAfterIntroduction?.halls ??
        []
    )
  );

  // 導入後推移テーブルを取得
  if (favorite.dataTransitionAfterIntroduction !== null) {
    yield put(
      DataTransitionAfterIntroductionActionCreators.fetchDataTransitionAfterIntroductionAction(
        favorite.currentShu,
        {
          ...favorite.dataTransitionAfterIntroduction,
          startDate,
          endDate,
          ...(sisTypes ? { sisTypes } : {}),
        },
        favorite.currentHalls ?? favorite.dataTransitionAfterIntroduction?.halls
      )
    );

    // テーブル列の推移項目並び順を変更
    if (favorite.columnForTransitionOrder) {
      yield put(
        TransitionAfterIntroductionReportsSettingActionCreators.selectTransitionAfterIntroductionReportsColumnsForTransitionAction(
          favorite.columnForTransitionOrder
        )
      );
    }

    // テーブル列の非推移項目並び順を変更
    if (favorite.columnForNonTransitionOrder) {
      yield put(
        TransitionAfterIntroductionReportsSettingActionCreators.selectTransitionAfterIntroductionReportsColumnsForNonTransitionAction(
          favorite.columnForNonTransitionOrder
        )
      );
    }

    // ヒートマップ表示の有効無効
    yield put(
      TransitionAfterIntroductionReportsSettingActionCreators.selectTransitionAfterIntroductionReportsIsHeatmapEnabledAction(
        favorite.isHeatmapEnabled ?? false
      )
    );
  }

  // 推移グラフ（機種比較）を取得
  if (favorite.dataTransitionAfterIntroductionKiGraph !== undefined) {
    yield put(
      DataTransitionAfterIntroductionKiGraphActionCreators.fetchDataTransitionAfterIntroductionKiGraphAction(
        {
          ...favorite.dataTransitionAfterIntroductionKiGraph.setting,
          startDate,
          endDate,
        }
      )
    );
    // グラフの機種選択を適用
    yield put(
      DataTransitionAfterIntroductionKiGraphActionCreators.changeDataTransitionAfterIntroductionKiGraphKiAction(
        favorite.dataTransitionAfterIntroductionKiGraph.selectKi
      )
    );
  } else {
    yield put(
      DataTransitionAfterIntroductionKiGraphActionCreators.hideDataTransitionAfterIntroductionKiGraphAction()
    );
  }

  // 検索条件 → エフェクトクリエイター（データ取得）に変換
  const dataTransitionAfterIntroduction2ndRow =
    favorite.dataTransitionAfterIntroduction2ndRow;
  if (dataTransitionAfterIntroduction2ndRow !== undefined) {
    // Fetchする展開行一覧を生成
    const tasks2ndRow = Object.keys(dataTransitionAfterIntroduction2ndRow).map(
      (kiCode) => {
        return put(
          DataTransitionAfterIntroduction2ndRowActionCreators.fetchDataTransitionAfterIntroduction2ndRowAction(
            kiCode,
            {
              ...dataTransitionAfterIntroduction2ndRow[kiCode],
              startDate,
              endDate,
            }
          )
        );
      }
    );
    // 全件取得
    yield all(tasks2ndRow);

    const favoriteId: number | undefined = yield select(
      transitionAfterIntroductionReportsSelectedFavoriteIdSelector
    );
    if (
      favorite.searchCondition.sisFieldTypes &&
      // デフォルトが有効であるため、0出ない場合には有効となる
      favorite.searchCondition.sisFieldTypes.length !== 0 &&
      favoriteId != null
    ) {
      gaFavoriteEvent('DK-SISスイッチ', {
        event_favorite_id: favoriteId,
        event_favorite_page: '導入後推移',
      });
    }
  }
  // テーブルフィルター項目を取得する
  // 項目一覧を反映する
  yield put(
    TransitionAfterIntroductionReportsSettingActionCreators.applyTransitionAfterIntroductionReportsTableFilterAction(
      favorite.tableFilterItems ?? []
    )
  );

  // テーブルでチェックされた機種を反映
  yield put(
    TransitionAfterIntroductionReportsSettingActionCreators.selectTransitionAfterIntroductionCheckedKiListTableAction(
      favorite.checkedKiListTable || {}
    )
  );
}

/**
 * お気に入りに保存するデータの生成
 */
function* setPageSetting() {
  // 現在の導入後推移の設定すべて
  const pageSetting: TransitionAfterIntroductionReportsSettingState = yield select(
    transitionAfterIntroductionReportsSettingSelector
  );
  // 現在の導入後推移テーブルの検索条件
  const dataTransitionAfterIntroduction:
    | DataTransitionAfterIntroductionParams
    | undefined = yield select(dataTransitionAfterIntroductionSettingsSelector);
  // 現在の導入後推移テーブルの展開行の検索条件
  const dataTransitionAfterIntroduction2ndRow:
    | {
        [key: string]: DataTransitionAfterIntroductionParams;
      }
    | undefined = yield select(
    dataTransitionAfterIntroduction2ndRowSettingsSelector
  );
  // 現在の推移グラフ（機種比較）の検索条件
  const dataTransitionAfterIntroductionKiGraph:
    | DataTransitionAfterIntroductionGraphParams
    | undefined = yield select(
    dataTransitionAfterIntroductionKiGraphSettingSelector
  );
  // 現在の推移グラフ（機種比較）の選択機種
  const selectKi: string[] = yield select(
    dataTransitionAfterIntroductionKiGraphSelectedKiListSelector
  );

  // テーブルフィルターで選択中の項目
  const tableFilterSelectedItems: string[] | undefined = yield select(
    transitionAfterIntroductionReportsTableFilterSelector
  );

  // テーブルでチェックされた機種の種別ごとのテーブルを取得
  const checkedKiListTable: { [shuCode: string]: string[] } = yield select(
    transitionAfterIntroductionReportsCheckedKiListTableSelector
  );

  // 種別選択前の時は動作しない
  if (pageSetting.currentShu === undefined) return;

  const modelNameFilter: string = yield select(
    transitionAfterIntroductionReportsModelNameFilterSelector
  );

  // ヒートマップの有効無効
  const isHeatmapEnabled: boolean = yield select(
    transitionAfterIntroductionReportsSettingIsHeatmapEnabledSelector
  );

  const currentField: string = yield select(
    transitionAfterIntroductionCurrentFieldSelector
  );

  const result: FavoriteItem['pageSetting'] = {
    transitionAfterIntroduction: {
      searchCondition: pageSetting.searchCondition,
      selectedShu: pageSetting.selectedShu,
      selectedHalls: pageSetting.selectedHalls,
      currentShu: pageSetting.currentShu,
      currentHalls: pageSetting.currentHalls,
      selectedDateRangeParams: pageSetting.selectedDateRangeParams,
      ...(dataTransitionAfterIntroduction
        ? { dataTransitionAfterIntroduction }
        : {}),
      ...(dataTransitionAfterIntroduction2ndRow
        ? { dataTransitionAfterIntroduction2ndRow }
        : {}),
      ...(dataTransitionAfterIntroductionKiGraph
        ? {
            dataTransitionAfterIntroductionKiGraph: {
              setting: dataTransitionAfterIntroductionKiGraph,
              selectKi,
            },
          }
        : {}),
      kiList: pageSetting?.kiList ?? [],
      columnForTransitionOrder: pageSetting?.columnsForTransitionOrder ?? [],
      columnForNonTransitionOrder:
        pageSetting?.columnsForNonTransitionOrder ?? [],
      modelNameFilter,
      tableFilterItems: tableFilterSelectedItems || [],
      checkedKiListTable: checkedKiListTable || {},
      isHeatmapEnabled,
      selectedCurrentFieldCode: currentField,
    },
  };
  return result;
}

/**
 * お気に入り上書き保存時の処理
 */
function* saveFavoriteSaga(
  action: SaveTransitionAfterIntroductionReportsFavorite
) {
  // 選択中のお気に入りID
  const selectedFavoriteId: number | undefined = yield select(
    transitionAfterIntroductionReportsSelectedFavoriteSelector
  );
  // 選択中のお気に入り
  const selectedFavorite: FavoriteItem = yield select(
    transitionAfterIntroductionReportsSelectedFavoriteDataSelector
  );

  // デフォルトの時は動作しない
  if (selectedFavoriteId === undefined) return;

  const pageSetting: FavoriteItem['pageSetting'] = yield call(setPageSetting);
  if (pageSetting == null) return;

  // お気に入りのデータを生成
  if (action.payload.mode === 'memo') {
    // お気に入りを上書き
    yield put(
      SettingsFavoritesActionCreators.patchSettingsFavoritesAction(
        selectedFavoriteId,
        {
          ...selectedFavorite,
          memo: action.payload.memo,
        }
      )
    );
  } else {
    // お気に入りを上書き
    yield put(
      SettingsFavoritesActionCreators.patchSettingsFavoritesAction(
        selectedFavoriteId,
        {
          name: action.payload.name,
          isShared: action.payload.isShared,
          pageSetting,
          memo: action.payload.memo,
          privatelySharedUsers: action.payload.sharedUser,
        }
      )
    );
  }
}

/**
 * お気に入り新規保存時の処理
 */
function* saveAsFavoriteSaga(
  action: SaveTransitionAfterIntroductionReportsFavorite
) {
  const pageSetting: FavoriteItem['pageSetting'] = yield call(setPageSetting);
  if (pageSetting == null) return;

  // お気に入りを新規登録
  yield put(
    SettingsFavoritesActionCreators.postSettingsFavoritesAction([
      {
        name: action.payload.name,
        isShared: action.payload.isShared,
        pageName: '導入後推移',
        pageSetting,
        memo: action.payload.memo,
        privatelySharedUsers: action.payload.sharedUser,
      },
    ])
  );
}

/**
 * 導入後推移の全てのデータを破棄する
 */
function* initTransitionAfterIntroductionReportsDataSaga() {
  // 展開行
  yield put(
    DataTransitionAfterIntroduction2ndRowActionCreators.renewDataTransitionAfterIntroduction2ndRowAction()
  );
  // グラフ
  yield put(
    DataTransitionAfterIntroductionKiGraphActionCreators.hideDataTransitionAfterIntroductionKiGraphAction()
  );
}

/**
 * 検索ボタン押下時に行う処理
 */
function* searchActionSaga(
  action: SearchTransitionAfterIntroductionReportsAction
) {
  // 導入後推移のデータを初期化する
  yield fork(initTransitionAfterIntroductionReportsDataSaga);

  // デフォルトの値を取得
  const settingsOptions: SearchCondition = yield select(
    settingsOptionsTransitionAfterIntroductionSearchConditionSelector
  );
  // 現在選択中の種別
  const currentShu: ShuOption | undefined = yield select(
    transitionAfterIntroductionReportsSelectedCurrentShuSelector
  );

  // すべての種別・種別グループ一覧
  const allShuOption: ShuOption[] = getShuOption(settingsOptions);
  // 現在選択可能な種別一覧
  const shuList =
    action.payload.selectedShu.length > 0
      ? action.payload.selectedShu
      : allShuOption;
  // currentShuが選択可能な種別一覧に含まれていない場合先頭を選択
  const checkedCurrentShu = checkCurrentShu(shuList, currentShu);

  // currentShuがselectedShu（選択可能な種別一覧）にない場合適切な種別を選択する
  if (
    currentShu !== undefined &&
    currentShu.type !== 'none' &&
    checkedCurrentShu !== undefined &&
    checkedCurrentShu.code !== currentShu.code
  ) {
    // currentShu（選択中の種別）を更新する
    yield put(
      TransitionAfterIntroductionReportsSettingActionCreators.selectTransitionAfterIntroductionReportsCurrentShuAction(
        checkedCurrentShu
      )
    );
  }

  // selectedShu（選択中の種別一覧）を更新する
  yield put(
    TransitionAfterIntroductionReportsSettingActionCreators.selectTransitionAfterIntroductionReportsShuAction(
      action.payload.selectedShu
    )
  );

  // 店舗選択スライダーで選択中の店舗を反映
  yield put(
    TransitionAfterIntroductionReportsSettingActionCreators.selectTransitionAfterIntroductionReportsCurrentHallsAction(
      //検索直後は「全店」を選択
      action.payload.params.halls ?? []
    )
  );

  // 店舗選択スライダーの店舗を反映
  yield put(
    TransitionAfterIntroductionReportsSettingActionCreators.selectTransitionAfterIntroductionReportsSelectedHallsAction(
      action.payload.params.halls ?? []
    )
  );

  // 必要に応じて検索パラメータを加工する
  let modifyParams = queryParameterToSearchParams(
    checkedCurrentShu,
    action.payload.params
  );

  const dateRange = action.payload.selectedDateRange;

  // dateType・dateRange（選択中の日付タイプ・期間）を更新する
  yield put(
    TransitionAfterIntroductionReportsSettingActionCreators.selectTransitionAfterIntroductionReportsDateRangeParamsAction(
      dateRange
    )
  );
  // 期間指定がカスタムの場合は現在の検索パラメータの値を送る
  if (dateRange !== 'カスタム') {
    // 指定された期間から日付を算出する
    const date = makeDateFromDateRange(dateRange);

    const startDate = format(date.startDate, 'yyyy-MM-dd');
    const endDate = format(date.endDate, 'yyyy-MM-dd');
    modifyParams = { ...modifyParams, startDate, endDate };
  }

  // searchCondition(選択中の検索条件）を更新する
  yield put(
    TransitionAfterIntroductionReportsSettingActionCreators.selectTransitionAfterIntroductionReportsSearchConditionAction(
      modifyParams
    )
  );

  // フィルターされた機種リストの情報があれば削除
  yield put(
    TransitionAfterIntroductionReportsSettingActionCreators.selectTransitionAfterIntroductionReportsKiListAction(
      []
    )
  );

  // データ取得
  yield put(
    DataTransitionAfterIntroductionActionCreators.fetchDataTransitionAfterIntroductionAction(
      checkedCurrentShu,
      modifyParams,
      modifyParams.halls
    )
  );

  // 現在チェックのついている機種コード
  const checkedKiList: string[] = yield select(
    transitionAfterIntroductionReportsCheckedKiListSelector
  );

  // 検索条件に含まれる機種かつ現在チェックのついている機種コード
  const updatedCheckedKiList = checkedKiList.filter((item) => {
    return modifyParams.kiList?.includes(item);
  });
  yield put(
    selectTransitionAfterIntroductionCheckedKiAction(updatedCheckedKiList)
  );
}

/**
 * 検索ボタン押下時、新しい検索条件でデータを取得する
 */
function* handleSearchSaga() {
  yield takeEvery(
    TransitionAfterIntroductionReportsSettingActionTypes.SEARCH_TRANSITION_AFTER_INTRODUCTION_REPORTS,
    searchActionSaga
  );
}

/**
 * リセットボタン押下時、デフォルトの検索条件または選択中のお気に入りでデータを取得する
 */
function* resetSearchActionSaga() {
  // 現在選択中のお気に入りを取得する
  const favoriteId: number | undefined = yield select(
    transitionAfterIntroductionReportsSelectedFavoriteSelector
  );

  // お気に入りが選択されている場合はお気に入り適用
  if (favoriteId !== undefined) {
    yield put(
      TransitionAfterIntroductionReportsSettingActionCreators.changeTransitionAfterIntroductionReportsFavoriteAction(
        favoriteId
      )
    );
    return;
  }

  // 導入後推移のデータを初期化する
  yield fork(initTransitionAfterIntroductionReportsDataSaga);

  // デフォルトが選択されている場合は検索条件を初期化
  yield put(
    TransitionAfterIntroductionReportsSettingActionCreators.resetTransitionAfterIntroductionReportsSearchConditionAction()
  );
  // 選択中の種別をリセットする
  yield put(
    TransitionAfterIntroductionReportsSettingActionCreators.resetTransitionAfterIntroductionReportsShuAction()
  );

  // 店舗選択スライダーで選択中の店舗をリセットする
  yield put(
    TransitionAfterIntroductionReportsSettingActionCreators.selectTransitionAfterIntroductionReportsCurrentHallsAction(
      []
    )
  );

  // 店舗選択スライダーの店舗をリセットする
  yield put(
    TransitionAfterIntroductionReportsSettingActionCreators.selectTransitionAfterIntroductionReportsSelectedHallsAction(
      []
    )
  );

  // 選択中の期間をリセットする
  yield put(
    TransitionAfterIntroductionReportsSettingActionCreators.resetTransitionAfterIntroductionReportsDateRangeParamsAction()
  );
  // フィルターされた機種リストの情報があれば削除
  yield put(
    TransitionAfterIntroductionReportsSettingActionCreators.selectTransitionAfterIntroductionReportsKiListAction(
      []
    )
  );
  yield put(
    TransitionAfterIntroductionReportsSettingActionCreators.selectTransitionAfterIntroductionCurrentFieldAction(
      'all'
    )
  );

  // 現在の検索条件を取得
  const searchCondition: DataTransitionAfterIntroductionParams = yield select(
    transitionAfterIntroductionReportsSearchConditionSelector
  );
  // 現在の種別を取得
  const currentShu: ShuOption = yield select(
    transitionAfterIntroductionReportsSelectedCurrentShuSelector
  );
  // 現在の店舗を取得
  const currentHalls: string[] = yield select(
    transitionAfterIntroductionReportsSettingCurrentHallsSelector
  );
  // 現在の期間を取得
  const dateRangeParams: TransitionAfterIntroductionReportsSettingDateRangeParams = yield select(
    transitionAfterIntroductionReportsSelectedDateRangeParamsSelector
  );
  // 表示項目の選択肢データを取得
  const settingsOptions: SettingsOptionsTransitionAfterIntroduction['fields'] = yield select(
    settingsOptionsTransitionAfterIntroductionFieldsSelector
  );

  // 必要に応じて検索パラメータを加工する
  let modifyParams = searchCondition;

  // 期間指定がカスタムの場合は現在の検索パラメータの値を送る
  if (dateRangeParams.dateRange !== 'カスタム') {
    // 指定された期間から日付を算出する
    const date = makeDateFromDateRange(dateRangeParams.dateRange);

    const startDate = format(date.startDate, 'yyyy-MM-dd');
    const endDate = format(date.endDate, 'yyyy-MM-dd');
    modifyParams = { ...modifyParams, startDate, endDate };
  }
  // 週次、月次以外が選択されている場合はデフォルトでDK-SISを有効にする
  if (searchCondition.dateType !== 'weekly') {
    modifyParams = {
      ...modifyParams,
      sisFieldTypes: ['result'],
    };
  }

  // 非推移項目のデフォルト項目を設定
  if (!isExistNonTransitiveField(searchCondition)) {
    modifyParams = {
      ...modifyParams,
      nonTransitiveFields: settingsOptions.nonTransition
        .filter((field) => field.isDefault)
        .map((field) => field.code),
    };
  }

  // セルの並び順をリセットしてデフォルト順にする
  yield put(
    selectTransitionAfterIntroductionReportsColumnsForTransitionAction([])
  );
  yield put(
    selectTransitionAfterIntroductionReportsColumnsForNonTransitionAction([])
  );

  // 検索条件を更新
  yield put(
    TransitionAfterIntroductionReportsSettingActionCreators.selectTransitionAfterIntroductionReportsSearchConditionAction(
      modifyParams
    )
  );

  // 機種名フィルタをクリア
  yield put(selectTransitionAfterIntroductionReportsModelNameFilterAction(''));

  // チェックされた機種をクリア
  yield put(selectTransitionAfterIntroductionCheckedKiListTableAction({}));

  // データ取得
  yield put(
    DataTransitionAfterIntroductionActionCreators.fetchDataTransitionAfterIntroductionAction(
      currentShu,
      modifyParams,
      currentHalls
    )
  );
}

/**
 * リセットボタン押下時導入後推移の全てのデータを破棄する
 */
function* handleResetSaga() {
  yield takeEvery(
    TransitionAfterIntroductionReportsSettingActionTypes.SEARCH_RESET_TRANSITION_AFTER_INTRODUCTION_REPORTS,
    resetSearchActionSaga
  );
}

/**
 * 変更後の種別でデータを取得する
 */
function* changeCurrentShuSaga(
  action: ChangeTransitionAfterIntroductionReportsCurrentShuAction
) {
  // 種別が選択されていない場合は処理を中断
  if (action.payload.shu === undefined) return;

  // 導入後推移のデータを初期化する
  yield fork(initTransitionAfterIntroductionReportsDataSaga);

  // 現在の検索条件を取得
  const searchCondition: DataTransitionAfterIntroductionParams = yield select(
    transitionAfterIntroductionReportsSearchConditionSelector
  );

  // 現在の店舗を取得
  const currentHalls: string[] = yield select(
    transitionAfterIntroductionReportsSettingCurrentHallsSelector
  );

  // フィルターされた機種リストの情報があれば削除
  yield put(
    TransitionAfterIntroductionReportsSettingActionCreators.selectTransitionAfterIntroductionReportsKiListAction(
      []
    )
  );

  // 表示項目の選択肢データを取得
  const settingsOptions: SettingsOptionsTransitionAfterIntroduction['fields'] = yield select(
    settingsOptionsTransitionAfterIntroductionFieldsSelector
  );

  // 新しい検索条件
  const newSearchCondition = {
    ...searchCondition,
    // 非推移項目のデフォルト項目を設定
    ...(!isExistNonTransitiveField(searchCondition)
      ? {
          nonTransitiveFields: settingsOptions.nonTransition
            .filter((field) => field.isDefault)
            .map((field) => field.code),
        }
      : {}),
  };

  // 検索条件を更新
  yield put(
    TransitionAfterIntroductionReportsSettingActionCreators.selectTransitionAfterIntroductionReportsSearchConditionAction(
      newSearchCondition
    )
  );

  // 変更後の種別でデータを取得する
  yield put(
    DataTransitionAfterIntroductionActionCreators.fetchDataTransitionAfterIntroductionAction(
      action.payload.shu,
      newSearchCondition,
      currentHalls
    )
  );
}

/**
 * 選択中の種別変更時、データを取得する
 */
function* handleChangeCurrentShuSaga() {
  yield takeEvery(
    TransitionAfterIntroductionReportsSettingActionTypes.CHANGE_TRANSITION_AFTER_INTRODUCTION_REPORTS_CURRENT_SHU,
    changeCurrentShuSaga
  );
}

/**
 * 変更後の店舗でデータを取得する
 */
function* changeCurrentHallsSaga(
  action: ChangeTransitionAfterIntroductionReportsCurrentHallsAction
) {
  // 店舗が選択されていない場合は処理を中断
  if (action.payload.halls === undefined) return;

  // 導入後推移のデータを初期化する
  yield fork(initTransitionAfterIntroductionReportsDataSaga);

  // 現在の検索条件を取得
  const searchCondition: DataTransitionAfterIntroductionParams = yield select(
    transitionAfterIntroductionReportsSearchConditionSelector
  );

  // 現在の種別を取得
  const currentShu: ShuOption = yield select(
    transitionAfterIntroductionReportsSelectedCurrentShuSelector
  );

  // フィルターされた機種リストの情報があれば削除
  yield put(
    TransitionAfterIntroductionReportsSettingActionCreators.selectTransitionAfterIntroductionReportsKiListAction(
      []
    )
  );

  // 表示項目の選択肢データを取得
  const settingsOptions: SettingsOptionsTransitionAfterIntroduction['fields'] = yield select(
    settingsOptionsTransitionAfterIntroductionFieldsSelector
  );

  // 新しい検索条件
  const newSearchCondition = {
    ...searchCondition,
    // 非推移項目のデフォルト項目を設定
    ...(!isExistNonTransitiveField(searchCondition)
      ? {
          nonTransitiveFields: settingsOptions.nonTransition
            .filter((field) => field.isDefault)
            .map((field) => field.code),
        }
      : {}),
  };

  // 検索条件を更新
  yield put(
    TransitionAfterIntroductionReportsSettingActionCreators.selectTransitionAfterIntroductionReportsSearchConditionAction(
      newSearchCondition
    )
  );

  // 変更後の店舗でデータを取得する
  yield put(
    DataTransitionAfterIntroductionActionCreators.fetchDataTransitionAfterIntroductionAction(
      currentShu,
      newSearchCondition,
      action.payload.halls
    )
  );
}

/**
 * 選択中の店舗変更時、データを取得する
 */
function* handleChangeCurrentHallsSaga() {
  yield takeEvery(
    TransitionAfterIntroductionReportsSettingActionTypes.CHANGE_TRANSITION_AFTER_INTRODUCTION_REPORTS_CURRENT_HALLS,
    changeCurrentHallsSaga
  );
}

/**
 * 表示項目変更時データを取得する
 */
function* searchFieldsSaga(
  action: SearchTransitionAfterIntroductionReportsFieldsAction
) {
  // 現在の導入後推移のデータを取得
  const transitionAfterIntroduction: DataTransitionAfterIntroduction = yield select(
    dataTransitionAfterIntroductionSelector
  );

  // 非推移項目が空のときは、メインフィールドのみを指定する
  const nonTransitiveFields =
    action.payload.nonTransitiveFields.length === 0
      ? [transitionAfterIntroduction.setting.mainField]
      : action.payload.nonTransitiveFields;
  const params = {
    nonTransitiveFields,
    transitiveFields: action.payload.transitiveFields,
  };

  // 項目絞込で選択されている項目
  const selectedCurrentFieldCode: string = yield select(
    transitionAfterIntroductionCurrentFieldSelector
  );

  // 表示項目に、項目絞込で選択されている項目が存在するか確認
  const hasCurrentFieldCode = params.transitiveFields.some(
    (field) => field === selectedCurrentFieldCode
  );

  if (!hasCurrentFieldCode) {
    yield put(
      TransitionAfterIntroductionReportsSettingActionCreators.selectTransitionAfterIntroductionCurrentFieldAction(
        'all'
      )
    );
  }

  yield fork(searchSearchConditonSaga, params);
}

/**
 * テーブル固有の検索条件を変更してデータを取得する
 * @param params 変更後の検索条件
 */
function* searchSearchConditonSaga(
  params: DataTransitionAfterIntroductionParams
) {
  // 導入後推移のデータを初期化する
  yield fork(initTransitionAfterIntroductionReportsDataSaga);

  // 現在の検索条件を取得
  const searchCondition: DataTransitionAfterIntroductionParams = yield select(
    transitionAfterIntroductionReportsSearchConditionSelector
  );
  // 現在の種別を取得
  const currentShu: ShuOption = yield select(
    transitionAfterIntroductionReportsSelectedCurrentShuSelector
  );
  // 現在の店舗を取得
  const currentHalls: string[] = yield select(
    transitionAfterIntroductionReportsSettingCurrentHallsSelector
  );

  // 新しい検索条件
  const newSearchCondition = {
    ...searchCondition,
    ...params,
  };

  // 検索条件を更新
  yield put(
    TransitionAfterIntroductionReportsSettingActionCreators.selectTransitionAfterIntroductionReportsSearchConditionAction(
      newSearchCondition
    )
  );

  // データ取得
  yield put(
    DataTransitionAfterIntroductionActionCreators.fetchDataTransitionAfterIntroductionAction(
      currentShu,
      newSearchCondition,
      currentHalls
    )
  );
}

/**
 * 非推移項目ソート実行時データを取得する
 */
function* searchSortForHeaderSaga(
  action: SearchTransitionAfterIntroductionReportsSortForHeaderAction
) {
  const params = {
    sortForHeader: action.payload.sort,
    order: action.payload.order,
  };
  yield fork(searchSearchConditonSaga, params);
}

/**
 * 推移項目ソート実行時データを取得する
 */
function* searchSortForTransitiveFieldSaga(
  action: SearchTransitionAfterIntroductionReportsSortForTransitiveFieldAction
) {
  // 現在の導入後推移のデータを取得
  const transitionAfterIntroduction: DataTransitionAfterIntroduction = yield select(
    dataTransitionAfterIntroductionSelector
  );
  // 推移項目であるsortForTransitiveFieldを選択したとき、
  // sortForHeaderが日付項目でない場合にはデフォルト値として初日/週に変更する
  const sortForHeader = !transitionAfterIntroduction.data.dates
    .map((date) => date.code)
    .includes(transitionAfterIntroduction.setting.sortForHeader)
    ? transitionAfterIntroduction.data.dates.at(0)?.code
    : transitionAfterIntroduction.setting.sortForHeader;

  const params = {
    sortForHeader,
    sortForTransitiveField: action.payload.sort,
    order: action.payload.order,
  };

  yield fork(searchSearchConditonSaga, params);
}

/**
 * マーキング変更時データを取得する
 */
function* searchMarkingSaga(
  action: SearchTransitionAfterIntroductionReportsMarkingAction
) {
  yield fork(searchSearchConditonSaga, {
    marking:
      action.payload.markingOption.code ===
      MODEL_REPORT_MARKING_CONDITIONS.at(0)?.code
        ? undefined
        : action.payload.markingOption.code,
    isFiltering: action.payload.isFiltering,
  });
}

/**
 * DK-SISを有効にしてデータを取得する
 */
function* searchDataHallKiDkSisOnSaga() {
  // 現在の検索条件を取得
  const searchCondition: DataTransitionAfterIntroductionParams = yield select(
    transitionAfterIntroductionReportsSearchConditionSelector
  );
  // DK-SISのデフォルト条件で種別実績テーブルを再取得する
  yield fork(searchSearchConditonSaga, {
    ...searchCondition,
    sisFieldTypes: ['result'],
  });
}

/**
 * DK-SISを無効にしてデータを取得する
 */
function* searchDataHallKiDkSisOffSaga() {
  // 現在の検索条件を取得
  const searchCondition: DataTransitionAfterIntroductionParams = yield select(
    transitionAfterIntroductionReportsSearchConditionSelector
  );

  // DI-SIS項目の field を取り除く
  const columns: Column[] = yield select(
    transitionAfterIntroductionReportsColumnsForTransitionOrderSelector
  );
  const dkSisColumns = columns
    .filter((column) => column.isSisField)
    .map((column) => column.code);
  const transitiveFields =
    searchCondition.transitiveFields?.filter(
      (field) => !dkSisColumns.includes(field)
    ) ?? [];

  // 全てのDK-SISの検索条件を取り除く
  yield fork(searchSearchConditonSaga, {
    ...searchCondition,
    transitiveFields:
      transitiveFields.length > 0
        ? transitiveFields
        : searchCondition.transitiveFields,
    sisFieldTypes: undefined,
  });
}

/**
 * 機種リストでフィルター時、グラフを閉じる
 */
function* selectKiList() {
  yield put(
    DataTransitionAfterIntroductionKiGraphActionCreators.hideDataTransitionAfterIntroductionKiGraphAction()
  );
}

/**
 * テーブル固有の検索条件変更検知時の処理
 */
function* handleTableSearchSaga() {
  // 非推移項目ソート実行時
  yield takeEvery(
    TransitionAfterIntroductionReportsSettingActionTypes.SEARCH_TRANSITION_AFTER_INTRODUCTION_REPORTS_SORT_FOR_HEADER,
    searchSortForHeaderSaga
  );

  // 推移項目ソート実行時
  yield takeEvery(
    TransitionAfterIntroductionReportsSettingActionTypes.SEARCH_TRANSITION_AFTER_INTRODUCTION_REPORTS_SORT_FOR_TRANSITIVE_FIELD,
    searchSortForTransitiveFieldSaga
  );

  // マーキング実行時
  yield takeEvery(
    TransitionAfterIntroductionReportsSettingActionTypes.SEARCH_TRANSITION_AFTER_INTRODUCTION_REPORTS_MARKING,
    searchMarkingSaga
  );

  // 表示項目変更時
  yield takeEvery(
    TransitionAfterIntroductionReportsSettingActionTypes.SEARCH_TRANSITION_AFTER_INTRODUCTION_REPORTS_FIELDS,
    searchFieldsSaga
  );

  // 選択機種リスト変更時
  yield takeEvery(
    TransitionAfterIntroductionReportsSettingActionTypes.SELECT_TRANSITION_AFTER_INTRODUCTION_REPORTS_KI_LIST,
    selectKiList
  );

  // DK-SISを有効
  yield takeEvery(
    TransitionAfterIntroductionReportsSettingActionTypes.SEARCH_TRANSITION_AFTER_INTRODUCTION_REPORTS_DKSIS_ON,
    searchDataHallKiDkSisOnSaga
  );
  // DK-SISを無効
  yield takeEvery(
    TransitionAfterIntroductionReportsSettingActionTypes.SEARCH_TRANSITION_AFTER_INTRODUCTION_REPORTS_DKSIS_OFF,
    searchDataHallKiDkSisOffSaga
  );
}

/**
 * 表示項目の並び順を変更する
 */
export function* changeColumnsOrderSaga() {
  const fieldsForTransition: Column[] = yield select(
    dataTransitionAfterIntroductionColumnsForTransitionSelector
  );
  const fieldsForNonTransition: Column[] = yield select(
    dataTransitionAfterIntroductionColumnsForNonTransitionSelector
  );

  // テーブル列の並び順
  const columnsForTransitionOrder: string[] = yield select(
    transitionAfterIntroductionReportsColumnsForTransitionOrderSelector
  );
  const columnsForNonTransitionOrder: string[] = yield select(
    transitionAfterIntroductionReportsColumnsForNonTransitionOrderSelector
  );

  const orderedForTransitionCode = sortFields(
    fieldsForTransition,
    columnsForTransitionOrder
  ).map((v) => v.code);

  const orderedForNonTransitionCode = sortBy(fieldsForNonTransition, (item) => {
    const index = columnsForNonTransitionOrder.indexOf(item.code);
    if (index !== -1) {
      return index;
    }
    // 機種名は常に先頭
    if (item.code === 'kiTushoMei') {
      return -2;
    }
    // DK-SIS項目を新規で追加する場合は強制で末尾に配置する
    if (item.isSisField) {
      return undefined;
    }
    return 0;
  }).map((column) => column.code);

  yield put(
    selectTransitionAfterIntroductionReportsColumnsForTransitionAction(
      orderedForTransitionCode
    )
  );

  yield put(
    selectTransitionAfterIntroductionReportsColumnsForNonTransitionAction(
      orderedForNonTransitionCode
    )
  );
}

/**
 * お気に入り関連のActionがDispatchされるのを監視
 */
function* handleFavoriteSaga() {
  // お気に入り選択
  yield takeEvery(
    TransitionAfterIntroductionReportsSettingActionTypes.CHANGE_TRANSITION_AFTER_INTRODUCTION_REPORTS_FAVORITE,
    applyFavoriteByIdSaga
  );
  // お気に入り上書き保存
  yield takeEvery(
    TransitionAfterIntroductionReportsSettingActionTypes.SAVE_TRANSITION_AFTER_INTRODUCTION_REPORTS_FAVORITE,
    saveFavoriteSaga
  );
  // お気に入り新規保存
  yield takeEvery(
    TransitionAfterIntroductionReportsSettingActionTypes.SAVE_AS_TRANSITION_AFTER_INTRODUCTION_REPORTS_FAVORITE,
    saveAsFavoriteSaga
  );
}

/**
 * 画面共有用短縮URL作成の処理
 */
function* createShortenedUrlSaga(
  action: CreateTransitionAfterIntroductionReportsShortenedUrlAction
) {
  const pageSetting: FavoriteItem['pageSetting'] = yield call(setPageSetting);
  if (pageSetting == null) return;

  const compressed = compressToEncodedURIComponent(
    action.payload.pageName,
    pageSetting ?? {}
  );
  const originalUrl = `${action.payload.locationUrl}?q=${compressed}`;

  yield put(ShortenedUrlActionCreators.postShortenedUrlAction({ originalUrl }));
}

// 画面共有用短縮URL作成の処理
function* handleShortenedUrlSaga() {
  yield takeEvery(
    TransitionAfterIntroductionReportsSettingActionTypes.CREATE_TRANSITION_AFTER_INTRODUCTION_REPORTS_SHORTENED_URL,
    createShortenedUrlSaga
  );
}

/**
 * 導入後推移の検索フォームに関するタスクを実行する
 */
export function* transitionAfterIntroductionReportsSettingSaga() {
  yield fork(handleSearchSaga);
  yield fork(handleResetSaga);
  yield fork(handleTableSearchSaga);
  yield fork(handleChangeCurrentShuSaga);
  yield fork(handleChangeCurrentHallsSaga);
  yield fork(handleFavoriteSaga);
  yield fork(handleShortenedUrlSaga);
}
