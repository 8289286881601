type Params<
  T extends {
    halls?: string[];
    areas?: string[];
    hallsForSijiritu?: string[];
    areasForSijiritu?: string[];
  } = {
    halls?: string[];
    areas?: string[];
    hallsForSijiritu?: string[];
    areasForSijiritu?: string[];
  }
> = T;

export const getSelectedHallsAndAreas = (params: Params) => {
  const { halls, areas, hallsForSijiritu, areasForSijiritu } = params;

  const selectedAverage = { type: 'halls' as const, value: 'null' as const };
  const selectedHalls = { type: 'halls' as const, value: params.halls ?? [] };
  const selectedAreas = { type: 'areas' as const, value: params.areas ?? [] };

  // エリア集計OFF、エリアとホールがフォームで選択されユーザーが店舗を押下したとき
  if (
    halls &&
    hallsForSijiritu &&
    areas &&
    areasForSijiritu &&
    halls.length !== hallsForSijiritu.length
  ) {
    return selectedHalls;
  }

  // エリア集計OFF、エリアとホールがフォームで選択されユーザーが店舗を押下したとき
  if (areas && areasForSijiritu && halls && !hallsForSijiritu) {
    return selectedHalls;
  }

  // エリア集計OFF、ホールのみがフォームで選択されユーザーが店舗を押下したとき
  if (halls && hallsForSijiritu && halls.length !== hallsForSijiritu.length) {
    return selectedHalls;
  }

  // エリア集計OFF、フォームで何も選択されておらずユーザーが店舗を押下したとき
  if (halls && !hallsForSijiritu && !areas && !areasForSijiritu) {
    return selectedHalls;
  }

  // エリア集計ON、エリアとホールがフォームで選択されユーザーがエリアを押下したとき
  if (
    halls &&
    hallsForSijiritu &&
    areas &&
    areasForSijiritu &&
    areas.length !== areasForSijiritu.length
  ) {
    return selectedAreas;
  }

  // エリア集計ON、エリアのみがフォームで選択されユーザーがエリアを押下したとき
  if (areas && areasForSijiritu && areas.length !== areasForSijiritu.length) {
    return selectedAreas;
  }

  // エリア集計ON、ホールのみがフォームで選択されユーザーがエリアを押下したとき
  if (halls && hallsForSijiritu && areas && !areasForSijiritu) {
    return selectedAreas;
  }

  // エリア集計ON、フォームで何も選択されておらずユーザーがエリアを押下したとき
  if (areas && !areasForSijiritu && !halls && !hallsForSijiritu) {
    return selectedAreas;
  }

  return selectedAverage;
};
