import { AxiosResponse } from 'axios';
import { call, fork, put, select, takeEvery } from 'redux-saga/effects';

import {
  PlanProgressGraphParams,
  PlanProgressTransitionGraph,
} from '../domain/dataPlanProgressGraph';

import {
  DataPlanProgressTransitionGraphActionCreators,
  DataPlanProgressTransitionGraphActionTypes,
  FetchDataPlanProgressGraphAction,
  SearchDataPlanProgressTransitionGraphFieldsAction,
  dataPlanProgressTransitionGraphSearchConditionSelector,
} from '../redux/server/dataPlanProgressTransitionGraph';
import { Api, buildConfig } from '../utils/api';
import { handleErrorSaga } from './errorSaga';

/**
 * 推移グラフデータ取得処理
 */
function* fetchDataPlanProgressTransitionGraphSaga(
  api: Api,
  action: FetchDataPlanProgressGraphAction
) {
  const { params } = action.payload;
  try {
    yield put(
      DataPlanProgressTransitionGraphActionCreators.fetchDataPlanProgressTransitionGraphRequestAction()
    );
    const response: AxiosResponse<PlanProgressTransitionGraph> = yield call(
      api.get,
      '/data/planProgressTransition/graph',
      buildConfig(params)
    );
    yield put(
      DataPlanProgressTransitionGraphActionCreators.fetchDataPlanProgressTransitionGraphSuccessAction(
        response.data
      )
    );
  } catch (error: unknown) {
    yield put(
      DataPlanProgressTransitionGraphActionCreators.renewDataPlanProgressTransitionGraphAction()
    );
    yield fork(handleErrorSaga, error);
  }
}

/**
 * 指定したグラフ項目の推移グラフデータを再取得する
 */
function* searchPlanProgressTransitionGraphFieldsSaga(
  action: SearchDataPlanProgressTransitionGraphFieldsAction
) {
  const { field } = action.payload;

  // 取得済みのグラフの検索条件
  const graphSearchCondition: PlanProgressGraphParams = yield select(
    dataPlanProgressTransitionGraphSearchConditionSelector()
  );

  yield put(
    DataPlanProgressTransitionGraphActionCreators.fetchDataPlanProgressGraphAction(
      {
        ...graphSearchCondition,
        field: field,
      }
    )
  );
}

function* handleFetchPlanProgressTransitionGraphSaga(api: Api) {
  yield takeEvery(
    DataPlanProgressTransitionGraphActionTypes.FETCH_DATA_PLAN_PROGRESS_TRANSITION_GRAPH,
    fetchDataPlanProgressTransitionGraphSaga,
    api
  );
}

function* handleSearchSaga() {
  yield takeEvery(
    DataPlanProgressTransitionGraphActionTypes.SEARCH_DATA_PLAN_PROGRESS_TRANSITION_GRAPH_FIELDS,
    searchPlanProgressTransitionGraphFieldsSaga
  );
}

export function* dataPlanProgressTransitionGraphSaga(context: { api: Api }) {
  yield fork(handleFetchPlanProgressTransitionGraphSaga, context.api);
  yield fork(handleSearchSaga);
}
