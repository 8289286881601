import { createSelector } from 'reselect';

import { ModeData, ModeDataSearchParams } from '../../domain/mode/types';
import { LoadingState } from '../../domain/schemas';

import { RootState } from '../../store';

/* ---------------------------------------------------------------
 * Action Types
 */

const FETCH_DATA_MODE = 'FETCH_DATA_MODE' as const;
const FETCH_DATA_MODE_REQUEST = 'FETCH_DATA_MODE_REQUEST' as const;
const FETCH_DATA_MODE_SUCCESS = 'FETCH_DATA_MODE_SUCCESS' as const;
const RENEW_DATA_MODE = 'RENEW_DATA_MODE' as const;

export const DataModeActionTypes = {
  FETCH_DATA_MODE,
  FETCH_DATA_MODE_REQUEST,
  FETCH_DATA_MODE_SUCCESS,
  RENEW_DATA_MODE,
};

/* ---------------------------------------------------------------
 * Action Creators
 */

function fetchDataModeAction(params: ModeDataSearchParams) {
  return {
    type: FETCH_DATA_MODE,
    payload: { params },
  };
}

function fetchDataModeRequestAction() {
  return {
    type: FETCH_DATA_MODE_REQUEST,
  };
}

function fetchDataModeSuccessAction(dataMode: ModeData) {
  return {
    type: FETCH_DATA_MODE_SUCCESS,
    payload: { dataMode },
  };
}

function renewDataModeAction() {
  return {
    type: RENEW_DATA_MODE,
  };
}

export const DataModeActionCreators = {
  fetchDataModeAction,
  fetchDataModeRequestAction,
  fetchDataModeSuccessAction,
  renewDataModeAction,
};

/* ---------------------------------------------------------------
 * Actions
 */

export type FetchDataModeAction = ReturnType<typeof fetchDataModeAction>;

type DataModeAction =
  | FetchDataModeAction
  | ReturnType<typeof fetchDataModeRequestAction>
  | ReturnType<typeof fetchDataModeSuccessAction>
  | ReturnType<typeof renewDataModeAction>;

/* ---------------------------------------------------------------
 * State
 */

type DataModeState = {
  loadingState: LoadingState;
  dataMode: ModeData | undefined;
};

const initialState: DataModeState = {
  loadingState: 'prepare',
  dataMode: undefined,
};

const dataModeAllDataSelector = (state: RootState) => {
  return state.dataMode;
};

export const dataModeDataSelector = createSelector(
  dataModeAllDataSelector,
  (data) => data.dataMode
);

export const dataModeLoadingSelector = createSelector(
  dataModeAllDataSelector,
  (data) => data.loadingState
);

/* ---------------------------------------------------------------
 * Reducer
 */

export function dataModeReducer(
  state = initialState,
  action: DataModeAction
): DataModeState {
  switch (action.type) {
    case FETCH_DATA_MODE_REQUEST:
      return {
        ...state,
        loadingState: 'loading',
      };
    case FETCH_DATA_MODE_SUCCESS:
      return {
        ...state,
        loadingState: 'loaded',
        dataMode: action.payload.dataMode,
      };
    case RENEW_DATA_MODE:
      return initialState;
    default:
      return state;
  }
}
