import { InformationData } from '../../domain/information';

// Action Types

const FETCH_INFORMATION = 'FETCH_INFORMATION' as const;
const FETCH_INFORMATION_REQUEST = 'FETCH_INFORMATION_REQUEST' as const;
const FETCH_INFORMATION_SUCCESS = 'FETCH_INFORMATION_SUCCESS' as const;
const RENEW_INFORMATION = 'RENEW_INFORMATION' as const;
const CLEAR_INFORMATION = 'CLEAR_INFORMATION' as const;

export const InformationActionTypes = {
  FETCH_INFORMATION,
  FETCH_INFORMATION_REQUEST,
  FETCH_INFORMATION_SUCCESS,
  RENEW_INFORMATION,
  CLEAR_INFORMATION,
};

// Action Creators

function fetchInformationAction() {
  return {
    type: FETCH_INFORMATION,
  };
}

function fetchInformationRequestAction() {
  return {
    type: FETCH_INFORMATION_REQUEST,
  };
}

function fetchInformationSuccessAction(information: InformationData) {
  return {
    type: FETCH_INFORMATION_SUCCESS,
    payload: { information },
  };
}

function renewInformationAction() {
  return {
    type: RENEW_INFORMATION,
  };
}

function clearInformationAction() {
  return {
    type: CLEAR_INFORMATION,
  };
}

export const InformationActionCreators = {
  fetchInformationAction,
  fetchInformationRequestAction,
  fetchInformationSuccessAction,
  renewInformationAction,
  clearInformationAction,
};

// Actions

type FetchInformationAction = ReturnType<typeof fetchInformationAction>;

type InformationAction =
  | FetchInformationAction
  | ReturnType<typeof fetchInformationRequestAction>
  | ReturnType<typeof fetchInformationSuccessAction>
  | ReturnType<typeof renewInformationAction>
  | ReturnType<typeof clearInformationAction>;

// State

type InformationState = {
  isLoading: boolean;
  information?: InformationData;
};

const initialState: InformationState = {
  isLoading: true,
  information: undefined,
};

// Selector

export function informationSelector(rootState: {
  information: InformationState;
}) {
  return rootState.information.information;
}

// Reducer

export function informationReducer(
  state = initialState,
  action: InformationAction
): InformationState {
  switch (action.type) {
    case FETCH_INFORMATION_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_INFORMATION_SUCCESS:
      return {
        isLoading: false,
        information: action.payload.information,
      };
    case RENEW_INFORMATION:
      return {
        ...state,
        isLoading: false,
      };
    case CLEAR_INFORMATION:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
