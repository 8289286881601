import {
  add,
  eachDayOfInterval,
  endOfMonth,
  isSameDay,
  startOfMonth,
} from 'date-fns';

import { KiComparativeSection } from '../domain/ki/types';

import { ModelDateRange } from './modelDateRange';
import { ReportsDateRange, ReportsDateUnit } from './reportsDateRange';

/**
 * 比較区分に使用するタイプ
 */
export type ComparativeSection =
  | '前日'
  | '前週同曜日'
  | '前週'
  | '前月'
  | '前年同月'
  | 'カスタム';

export const comparativeSectionForDay: ComparativeSection[] = [
  '前日',
  '前週同曜日',
  'カスタム',
];

export const comparativeSectionForWeek: ComparativeSection[] = [
  '前週',
  'カスタム',
];

export const comparativeSectionForMonth: ComparativeSection[] = [
  '前月',
  '前年同月',
  'カスタム',
];

export const comparativeSectionForCustomOnly: ComparativeSection[] = [
  'カスタム',
];

/**
 * dateUnitに応じて比較区分の選択肢を変える
 */
export const makeComparativeSection = (
  selectDateUnit: ReportsDateUnit | ReportsDateRange | ModelDateRange
): ComparativeSection[] => {
  switch (selectDateUnit) {
    case '日':
    case '前日':
    case '当日':
      return comparativeSectionForDay;
    case '週':
    case '前週':
    case '今週':
      return comparativeSectionForWeek;
    case '月':
    case '前月':
    case '今月':
      return comparativeSectionForMonth;
    case '範囲選択':
    case '自由選択':
    case 'カスタム':
      return comparativeSectionForCustomOnly;
  }

  // 過去のお気に入りなどで存在している可能性を考慮しています
  return comparativeSectionForCustomOnly;
};

/**
 * 比較区分に応じて比較期間を変更する
 * @param comparativeSection
 * @param currentDate
 * @param now
 * @param excludeToday
 * @returns
 */
export const makeComparisonDateFromSelectedComparativeSection = (
  comparativeSection: ComparativeSection | KiComparativeSection,
  currentDate: Date,
  now: Date,
  excludeToday: boolean | undefined
): {
  ymdComparisonList: Date[];
} => {
  let startDate = currentDate;
  let endDate = currentDate;
  switch (comparativeSection) {
    case '前日': {
      endDate = add(currentDate, { days: -1 });
      startDate = endDate;
      break;
    }
    case '前週同曜日': {
      endDate = add(currentDate, { days: -7 });
      startDate = endDate;
      break;
    }
    case '前週': {
      const currentMonday = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDay() === 0
          ? currentDate.getDate() - 6
          : currentDate.getDate() - (currentDate.getDay() - 1)
      );

      const thisWeekMonday = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDay() === 0
          ? now.getDate() - 6
          : now.getDate() - (now.getDay() - 1)
      );

      if (isSameDay(currentMonday, thisWeekMonday)) {
        startDate = add(currentMonday, { days: -7 });
        endDate = add(now, {
          days: excludeToday ? (now.getDay() === 1 ? -1 : -8) : -7,
        });
      } else {
        startDate = add(currentMonday, { days: -7 });
        endDate = add(currentMonday, { days: -1 });
      }
      break;
    }
    case '前月': {
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth();

      const lastMonth = add(currentDate, { months: -1 });

      //期間が当月の場合は比較期間の期間範囲を検索期間に合わせる
      if (
        currentYear === now.getFullYear() &&
        currentMonth === now.getMonth() &&
        //前月の日数が当月より少ない、かつ当日日付が前月最終日付以降の場合、比較期間には前月の1日から最終日付までが指定された状態とする
        now.getDate() < endOfMonth(lastMonth).getDate()
      ) {
        startDate = startOfMonth(lastMonth);
        endDate = new Date(
          lastMonth.getFullYear(),
          lastMonth.getMonth(),
          excludeToday && now.getDate() !== 1
            ? now.getDate() - 1
            : now.getDate()
        );
      }
      //当月以外の場合は月全て
      else {
        startDate = startOfMonth(lastMonth);
        endDate = endOfMonth(lastMonth);
      }

      break;
    }
    case '前年同月': {
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth();

      const lastYear = add(currentDate, { years: -1 });

      //期間が当月の場合は比較期間の期間範囲を検索期間に合わせる
      if (
        currentYear === now.getFullYear() &&
        currentMonth === now.getMonth()
      ) {
        startDate = startOfMonth(lastYear);
        endDate = new Date(
          lastYear.getFullYear(),
          lastYear.getMonth(),
          excludeToday && now.getDate() !== 1
            ? now.getDate() - 1
            : now.getDate()
        );
      }
      //当月以外の場合は月全て
      else {
        startDate = startOfMonth(lastYear);
        endDate = endOfMonth(lastYear);
      }
      break;
    }
  }
  const ymdComparisonList = eachDayOfInterval({
    start: startDate,
    end: endDate,
  });

  return { ymdComparisonList };
};
