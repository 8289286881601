import { AxiosResponse } from 'axios';
import { call, fork, put, select, takeEvery } from 'redux-saga/effects';

import { DataTerminalTransitionParams } from '../domain/dataTerminalTransition';
import {
  DataTerminalTransitionGraph,
  DataTerminalTransitionGraphParams,
} from '../domain/dataTerminalTransitionGraph';

import {
  DataTerminalTransitionGraphActionCreators,
  DataTerminalTransitionGraphActionTypes,
  FetchDataTerminalTransitionGraphAction,
  SearchDataTerminalTransitionGraphAction,
  SearchDataTerminalTransitionGraphDateAction,
  SearchDataTerminalTransitionGraphFieldsAction,
  SearchDataTerminalTransitionGraphHallAction,
  dataTerminalTransitionGraphSearchConditionSelector,
} from '../redux/server/dataTerminalTransitionGraph';
import { terminalTransitionReportsSearchConditionSelector } from '../redux/ui/terminalTransitionReportsSetting';
import { Api, buildConfig } from '../utils/api';
import { handleErrorSaga } from './errorSaga';

/**
 * 推移グラフ（店舗比較）データ取得処理
 */
function* fetchDataTerminalTransitionGraphSaga(
  api: Api,
  action: FetchDataTerminalTransitionGraphAction
) {
  const { params } = action.payload;
  try {
    yield put(
      DataTerminalTransitionGraphActionCreators.fetchDataTerminalTransitionGraphRequestAction()
    );
    const response: AxiosResponse<DataTerminalTransitionGraph> = yield call(
      api.get,
      '/data/terminalTransition/graph',
      buildConfig(params)
    );
    yield put(
      DataTerminalTransitionGraphActionCreators.fetchDataTerminalTransitionGraphSuccessAction(
        response.data
      )
    );
  } catch (error: unknown) {
    yield put(
      DataTerminalTransitionGraphActionCreators.renewDataTerminalTransitionGraphAction()
    );
    yield fork(handleErrorSaga, error);
  }
}

/**
 * クリックしたセルの推移グラフデータを取得する
 */
function* searchTerminalTransitionGraphSaga(
  action: SearchDataTerminalTransitionGraphAction
) {
  const { shuGroupId, shuList, halls, field } = action.payload;

  // 検索フォームの検索条件
  const searchCondition: DataTerminalTransitionParams = yield select(
    terminalTransitionReportsSearchConditionSelector
  );

  // 取得済みのグラフの検索条件
  const graphSearchCondition: DataTerminalTransitionGraphParams = yield select(
    dataTerminalTransitionGraphSearchConditionSelector()
  );

  yield put(
    DataTerminalTransitionGraphActionCreators.fetchDataTerminalTransitionGraphAction(
      {
        ...graphSearchCondition,
        ...searchCondition,
        field: field ? field : graphSearchCondition.field,
        shuGroupIds: shuGroupId,
        shuList: shuList,
        halls: halls,
      }
    )
  );
}

/**
 * 指定した店舗の推移グラフ（店舗比較）データを再取得する
 */
function* searchTerminalTransitionGraphHallSaga(
  action: SearchDataTerminalTransitionGraphHallAction
) {
  const { hall } = action.payload;

  // 取得済みのグラフの検索条件
  const graphSearchCondition: DataTerminalTransitionGraphParams = yield select(
    dataTerminalTransitionGraphSearchConditionSelector()
  );

  yield put(
    DataTerminalTransitionGraphActionCreators.fetchDataTerminalTransitionGraphAction(
      {
        ...graphSearchCondition,
        halls: hall,
      }
    )
  );
}

/**
 * 指定したグラフ項目の推移グラフデータを再取得する
 */
function* searchTerminalTransitionGraphFieldsSaga(
  action: SearchDataTerminalTransitionGraphFieldsAction
) {
  const { field } = action.payload;

  // 取得済みのグラフの検索条件
  const graphSearchCondition: DataTerminalTransitionGraphParams = yield select(
    dataTerminalTransitionGraphSearchConditionSelector()
  );

  yield put(
    DataTerminalTransitionGraphActionCreators.fetchDataTerminalTransitionGraphAction(
      {
        ...graphSearchCondition,
        field: field,
      }
    )
  );
}

/**
 * 指定した店舗の推移グラフ（店舗比較）データを再取得する
 */
function* searchTerminalTransitionGraphDateSaga(
  action: SearchDataTerminalTransitionGraphDateAction
) {
  const { startDate, endDate } = action.payload;

  // 取得済みのグラフの検索条件
  const graphSearchCondition: DataTerminalTransitionGraphParams = yield select(
    dataTerminalTransitionGraphSearchConditionSelector()
  );

  yield put(
    DataTerminalTransitionGraphActionCreators.fetchDataTerminalTransitionGraphAction(
      {
        ...graphSearchCondition,
        startDate: startDate,
        endDate: endDate,
      }
    )
  );
}

function* handleFetchTerminalTransitionGraphSaga(api: Api) {
  yield takeEvery(
    DataTerminalTransitionGraphActionTypes.FETCH_DATA_TERMINAL_TRANSITION_GRAPH,
    fetchDataTerminalTransitionGraphSaga,
    api
  );
}

function* handleSearchSaga() {
  yield takeEvery(
    DataTerminalTransitionGraphActionTypes.SEARCH_DATA_TERMINAL_TRANSITION_GRAPH,
    searchTerminalTransitionGraphSaga
  );
  yield takeEvery(
    DataTerminalTransitionGraphActionTypes.SEARCH_DATA_TERMINAL_TRANSITION_GRAPH_HALL,
    searchTerminalTransitionGraphHallSaga
  );
  yield takeEvery(
    DataTerminalTransitionGraphActionTypes.SEARCH_DATA_TERMINAL_TRANSITION_GRAPH_FIELDS,
    searchTerminalTransitionGraphFieldsSaga
  );
  yield takeEvery(
    DataTerminalTransitionGraphActionTypes.SEARCH_DATA_TERMINAL_TRANSITION_GRAPH_DATE,
    searchTerminalTransitionGraphDateSaga
  );
}

export function* dataTerminalTransitionGraphSaga(context: { api: Api }) {
  yield fork(handleFetchTerminalTransitionGraphSaga, context.api);
  yield fork(handleSearchSaga);
}
