import { add, endOfMonth, startOfMonth } from 'date-fns';

import { EventsDateRange } from './types';

/**
 * 操作履歴
 * 検索パラメータに渡す日付データを算出する
 * @param dateRange 期間指定
 * @param currentDate 基準日（未指定の場合は本日）
 */
export const makeDateFromDateRange = (
  dateRange: EventsDateRange,
  currentDate: Date = new Date()
): { startDate: Date; endDate: Date } => {
  switch (dateRange) {
    case '当日': {
      return {
        startDate: currentDate,
        endDate: currentDate,
      };
    }
    case '前月': {
      const lastMonth = add(currentDate, { months: -1 });
      return {
        startDate: startOfMonth(lastMonth),
        endDate: endOfMonth(lastMonth),
      };
    }
    case '今月': {
      return {
        startDate: startOfMonth(currentDate),
        endDate: currentDate,
      };
    }
    case '7日': {
      return {
        startDate: add(currentDate, { days: -6 }),
        endDate: currentDate,
      };
    }
    case '14日': {
      return {
        startDate: add(currentDate, { days: -13 }),
        endDate: currentDate,
      };
    }
    case '30日': {
      return {
        startDate: add(currentDate, { days: -29 }),
        endDate: currentDate,
      };
    }
    default: {
      throw new Error('Invalid date range');
    }
  }
};
