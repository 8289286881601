import { createSelector } from 'reselect';

import { GRAPH_FIELD_TYPE } from '../../domain/dataKiGraph';
import { ClarisApiError } from '../../domain/error';
import {
  DataTransitionAfterIntroductionGraph,
  DataTransitionAfterIntroductionGraphParams,
} from '../../domain/transitionAfterIntroduction/types';

import { RootState } from '../../store';

// Action Types

const FETCH_DATA_TRANSITION_AFTER_INTRODUCTION_GRAPH = 'FETCH_DATA_TRANSITION_AFTER_INTRODUCTION_GRAPH' as const;
const FETCH_DATA_TRANSITION_AFTER_INTRODUCTION_GRAPH_REQUEST = 'FETCH_DATA_TRANSITION_AFTER_INTRODUCTION_GRAPH_REQUEST' as const;
const FETCH_DATA_TRANSITION_AFTER_INTRODUCTION_GRAPH_SUCCESS = 'FETCH_DATA_TRANSITION_AFTER_INTRODUCTION_GRAPH_SUCCESS' as const;
const RENEW_DATA_TRANSITION_AFTER_INTRODUCTION_GRAPH = 'RENEW_DATA_TRANSITION_AFTER_INTRODUCTION_GRAPH' as const;
const SEARCH_DATA_TRANSITION_AFTER_INTRODUCTION_GRAPH = 'SEARCH_DATA_TRANSITION_AFTER_INTRODUCTION_GRAPH' as const;
const CHANGE_DATA_TRANSITION_AFTER_INTRODUCTION_GRAPH_KI = 'CHANGE_DATA_TRANSITION_AFTER_INTRODUCTION_GRAPH_KI' as const;
const CHANGE_DATA_TRANSITION_AFTER_INTRODUCTION_GRAPH_FIELD = 'CHANGE_DATA_TRANSITION_AFTER_INTRODUCTION_GRAPH_FIELD' as const;
const HIDE_DATA_TRANSITION_AFTER_INTRODUCTION_GRAPH = 'HIDE_DATA_TRANSITION_AFTER_INTRODUCTION_GRAPH' as const;

export const DataTransitionAfterIntroductionKiGraphActionTypes = {
  FETCH_DATA_TRANSITION_AFTER_INTRODUCTION_GRAPH,
  FETCH_DATA_TRANSITION_AFTER_INTRODUCTION_GRAPH_REQUEST,
  FETCH_DATA_TRANSITION_AFTER_INTRODUCTION_GRAPH_SUCCESS,
  RENEW_DATA_TRANSITION_AFTER_INTRODUCTION_GRAPH,
  SEARCH_DATA_TRANSITION_AFTER_INTRODUCTION_GRAPH,
  CHANGE_DATA_TRANSITION_AFTER_INTRODUCTION_GRAPH_KI,
  CHANGE_DATA_TRANSITION_AFTER_INTRODUCTION_GRAPH_FIELD,
  HIDE_DATA_TRANSITION_AFTER_INTRODUCTION_GRAPH,
};

// Action Creators

/**
 * 推移グラフ（機種比較）データを取得する
 * @param params 推移グラフ（機種比較）の検索条件
 */
function fetchDataTransitionAfterIntroductionKiGraphAction(
  params: DataTransitionAfterIntroductionGraphParams
) {
  return {
    type: FETCH_DATA_TRANSITION_AFTER_INTRODUCTION_GRAPH,
    payload: { params },
  };
}

/**
 * 推移グラフ（機種比較）データ取得前に実行する
 * @param shuCode 表示先テーブルの種別コード
 */
function fetchDataTransitionAfterIntroductionKiGraphRequestAction() {
  return {
    type: FETCH_DATA_TRANSITION_AFTER_INTRODUCTION_GRAPH_REQUEST,
  };
}

/**
 * 推移グラフ（機種比較）データ取得完了時データを登録する
 * @param graph 取得したグラフデータ
 */
function fetchDataTransitionAfterIntroductionKiGraphSuccessAction(
  graph: DataTransitionAfterIntroductionGraph
) {
  return {
    type: FETCH_DATA_TRANSITION_AFTER_INTRODUCTION_GRAPH_SUCCESS,
    payload: { graph },
  };
}

function renewDataTransitionAfterIntroductionKiGraphAction() {
  return {
    type: RENEW_DATA_TRANSITION_AFTER_INTRODUCTION_GRAPH,
  };
}

/**
 * 選択した機種の推移グラフする
 * @param kiList 表示する機種コード一覧
 */
function searchDataTransitionAfterIntroductionKiGraphAction(kiList: string[]) {
  return {
    type: SEARCH_DATA_TRANSITION_AFTER_INTRODUCTION_GRAPH,
    payload: { kiList },
  };
}

/**
 * 指定した機種コードでグラフを絞り込む（機種選択フォーム用）
 * @param kiList 絞り込む機種コード一覧
 */
function changeDataTransitionAfterIntroductionKiGraphKiAction(
  kiList: string[]
) {
  return {
    type: CHANGE_DATA_TRANSITION_AFTER_INTRODUCTION_GRAPH_KI,
    payload: { kiList },
  };
}

/**
 * 指定したグラフ項目で推移グラフ（機種比較）データを再取得
 * @param field 変更後のグラフ項目
 */
function changeDataTransitionAfterIntroductionKiGraphFieldAction(
  field: string
) {
  return {
    type: CHANGE_DATA_TRANSITION_AFTER_INTRODUCTION_GRAPH_FIELD,
    payload: { field },
  };
}

/**
 * 導入後推移 推移グラフ（機種比較）のデータを破棄する
 */
function hideDataTransitionAfterIntroductionKiGraphAction() {
  return {
    type: HIDE_DATA_TRANSITION_AFTER_INTRODUCTION_GRAPH,
  };
}

export const DataTransitionAfterIntroductionKiGraphActionCreators = {
  fetchDataTransitionAfterIntroductionKiGraphAction,
  fetchDataTransitionAfterIntroductionKiGraphRequestAction,
  fetchDataTransitionAfterIntroductionKiGraphSuccessAction,
  renewDataTransitionAfterIntroductionKiGraphAction,
  searchDataTransitionAfterIntroductionKiGraphAction,
  changeDataTransitionAfterIntroductionKiGraphKiAction,
  changeDataTransitionAfterIntroductionKiGraphFieldAction,
  hideDataTransitionAfterIntroductionKiGraphAction,
};

// Actions

export type FetchDataTransitionAfterIntroductionKiGraphAction = ReturnType<
  typeof fetchDataTransitionAfterIntroductionKiGraphAction
>;
export type SearchDataTransitionAfterIntroductionKiGraphAction = ReturnType<
  typeof searchDataTransitionAfterIntroductionKiGraphAction
>;
type ChangeDataTransitionAfterIntroductionKiGraphKiAction = ReturnType<
  typeof changeDataTransitionAfterIntroductionKiGraphKiAction
>;
export type ChangeDataTransitionAfterIntroductionKiGraphFieldAction = ReturnType<
  typeof changeDataTransitionAfterIntroductionKiGraphFieldAction
>;
type HideDataTransitionAfterIntroductionKiGraphAction = ReturnType<
  typeof hideDataTransitionAfterIntroductionKiGraphAction
>;

type DataTransitionAfterIntroductionKiGraphAction =
  | FetchDataTransitionAfterIntroductionKiGraphAction
  | SearchDataTransitionAfterIntroductionKiGraphAction
  | ChangeDataTransitionAfterIntroductionKiGraphKiAction
  | ChangeDataTransitionAfterIntroductionKiGraphFieldAction
  | HideDataTransitionAfterIntroductionKiGraphAction
  | ReturnType<typeof fetchDataTransitionAfterIntroductionKiGraphRequestAction>
  | ReturnType<typeof fetchDataTransitionAfterIntroductionKiGraphSuccessAction>
  | ReturnType<typeof renewDataTransitionAfterIntroductionKiGraphAction>;

// State

type DataTransitionAfterIntroductionKiGraphState = {
  /**
   * 選択中の機種（機種選択フォームで絞り込んだ機種）
   */
  selectKi: string[];
  /**
   * グラフデータ
   */
  dataTransitionAfterIntroductionKiGraph:
    | DataTransitionAfterIntroductionGraph
    | undefined;
  /**
   * ローディング状態
   */
  isLoading: boolean;
  /**
   * エラー内容
   */
  error: ClarisApiError | undefined;
};

const initialState: DataTransitionAfterIntroductionKiGraphState = {
  selectKi: [],
  dataTransitionAfterIntroductionKiGraph: undefined,
  isLoading: false,
  error: undefined,
};

// Selector

/**
 * グラフデータを取得する
 * @returns グラフデータ
 */
export const dataTransitionAfterIntroductionKiGraphSelector = (
  state: RootState
) =>
  state.dataTransitionAfterIntroductionKiGraph
    .dataTransitionAfterIntroductionKiGraph;

/**
 * グラフデータを取得する
 * @returns グラフデータ
 */
export const dataTransitionAfterIntroductionKiGraphSettingSelector = (
  state: RootState
) =>
  state.dataTransitionAfterIntroductionKiGraph
    .dataTransitionAfterIntroductionKiGraph?.setting;

/**
 * 選択中のグラフ項目を取得する
 * @param shu 種別データ（種別グループ・種別）
 * @returns グラフ項目
 */
export const dataTransitionAfterIntroductionKiGraphSelectedFieldSelector = createSelector(
  dataTransitionAfterIntroductionKiGraphSettingSelector,
  (data) => {
    return data?.field ?? GRAPH_FIELD_TYPE.OUT;
  }
);

/**
 * グラフデータのロード状態を取得する
 * @returns グラフデータ
 */
export const dataTransitionAfterIntroductionKiGraphSettingLoadingSelector = (
  state: RootState
) => state.dataTransitionAfterIntroductionKiGraph.isLoading;

/**
 * 絞り込んでいる機種一覧を取得する
 * @returns 機種一覧
 */
export const dataTransitionAfterIntroductionKiGraphSelectedKiListSelector = (
  state: RootState
) => state.dataTransitionAfterIntroductionKiGraph.selectKi;

// Reducer

export function dataTransitionAfterIntroductionKiGraphReducer(
  state = initialState,
  action: DataTransitionAfterIntroductionKiGraphAction
): DataTransitionAfterIntroductionKiGraphState {
  switch (action.type) {
    case FETCH_DATA_TRANSITION_AFTER_INTRODUCTION_GRAPH_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_DATA_TRANSITION_AFTER_INTRODUCTION_GRAPH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        dataTransitionAfterIntroductionKiGraph: action.payload.graph,
      };

    case RENEW_DATA_TRANSITION_AFTER_INTRODUCTION_GRAPH:
      return initialState;
    case SEARCH_DATA_TRANSITION_AFTER_INTRODUCTION_GRAPH:
      return {
        ...state,
        selectKi: action.payload.kiList,
      };
    case CHANGE_DATA_TRANSITION_AFTER_INTRODUCTION_GRAPH_KI:
      return {
        ...state,
        selectKi: action.payload.kiList,
      };
    case HIDE_DATA_TRANSITION_AFTER_INTRODUCTION_GRAPH:
      return {
        ...state,
        selectKi: [],
        dataTransitionAfterIntroductionKiGraph: undefined,
      };
    default:
      return state;
  }
}
