/**
 * [機種集計] 期間の一覧
 */
export const kiDateRanges = [
  {
    name: '前日',
    comparativeSection: ['前日', '前週同曜日'],
    defaultComparisonSection: '前週同曜日',
  },
  {
    name: '当日',
    comparativeSection: ['前日', '前週同曜日'],
    defaultComparisonSection: '前週同曜日',
  },
  {
    name: '前週',
    comparativeSection: ['前週', '平日/土日祝比較'],
    defaultComparisonSection: '前週',
  },
  {
    name: '今週',
    comparativeSection: ['前週', '平日/土日祝比較'],
    defaultComparisonSection: '前週',
  },
  {
    name: '前月',
    comparativeSection: ['前月', '前年同月', '平日/土日祝比較'],
    defaultComparisonSection: '前月',
  },
  {
    name: '今月',
    comparativeSection: ['前月', '前年同月', '平日/土日祝比較'],
    defaultComparisonSection: '前月',
  },
  {
    name: '過去7日',
    comparativeSection: ['過去7日', '平日/土日祝比較'],
    defaultComparisonSection: '過去7日',
  },
  {
    name: '過去14日',
    comparativeSection: ['過去14日', '平日/土日祝比較'],
    defaultComparisonSection: '過去14日',
  },
  {
    name: '過去28日',
    comparativeSection: ['過去28日', '平日/土日祝比較'],
    defaultComparisonSection: '過去28日',
  },
  {
    name: 'カスタム',
    comparativeSection: ['カスタム'],
    defaultComparisonSection: 'カスタム',
  },
] as const;

/**
 * [機種集計] カレンダー内で使用される選択可能な期間単位の一覧
 */
export const kiDateUnits = [
  {
    name: '範囲選択',
    comparativeSection: ['カスタム', '平日/土日祝比較'],
    defaultComparisonSection: 'カスタム',
  },
  {
    name: '自由選択',
    comparativeSection: ['カスタム', '平日/土日祝比較'],
    defaultComparisonSection: 'カスタム',
  },
] as const;
