import { DataSis } from './types';

export const dataFilteredByKiList = (
  dataSis: DataSis,
  kiListFilter: string[] | undefined
): DataSis => {
  if (kiListFilter == null || kiListFilter.length === 0) {
    return dataSis;
  }

  const rows = dataSis.data.rows.filter((row, i) =>
    row.data.length > 0 && i < 1
      ? true
      : kiListFilter.includes(row.queryParameter.kiList?.at(0) ?? '')
  );

  return {
    ...dataSis,
    data: {
      ...dataSis.data,
      rows,
    },
  };
};
